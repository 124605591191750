//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'QuestionList',
  props: {
    displayType: {
      type: String,
      default: ''
    },
    loadingQuestions: {
      type: Boolean,
      default: false
    },
    // normal = full owner capabilities or readonly = questions can be viewed by participants but not answered
    mode: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
      // isProjectOwner: false
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'breakpoints/desktop',
      isParticipant: 'user/isParticipant',
      isProjectOwner: 'user/isProjectOwner',
      project: 'projects/getActiveProject',
      questionList: 'questions/getQuestions'
    }),
    headerLabel() {
      let label = ''
      if (this.displayType === 'answer' && this.isProjectOwner) {
        label = 'Info'
      } else if (this.displayType === 'answer' && !this.isProjectOwner) {
        label = 'Done'
      } else if (this.displayType === 'detail') {
        label = 'Rqmt'
      }
      return label
    }
  },
  async mounted() {
  },
  methods: {
    ...mapActions({
    })
  }
}
