//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InboxActions',
  props: {
    folder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // TODO: figure out when/how to display read/unread
      display: {
        inbox: ['delete', 'archive'],
        drafts: ['delete'],
        archived: ['delete', 'move'],
        trash: ['delete', 'trash', 'restore'],
        sent: ['delete']
      },
      refreshing: '',
      selectall: false,
      showActions: false,
      showSearch: false
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'breakpoints/desktop',
      messagesSelected: 'messages/getSelectedMessages',
      inboxOptions: 'messages/getInboxOptions',
      listMessageId: 'messages/getListMessageId',
      messageList: 'messages/getMessageList'
    }),
    selectalltext() {
      return this.isMessagesSelected ? 'Deselect All' : 'Select All'
    },
    isMessagesSelected() {
      return this.messagesSelected?.length > 0
    },
    isRefreshing() {
      return this.refreshing !== ''
    }
  },
  methods: {
    ...mapActions({
      addSelectedMessage: 'messages/addSelectedMessageId',
      archiveSelectedMessages: 'messages/archiveSelectedMessages',
      clearSelectedMessages: 'messages/clearSelectedMessages',
      deleteSelectedMessages: 'messages/deleteSelectedMessages',
      emptyTrash: 'messages/emptyTrash',
      fetchMessageList: 'messages/fetchMessagesByFolder',
      moveSelectedMessagesToInbox: 'messages/moveSelectedMessagesToInbox',
      permanentDeleteSelectedMessages: 'messages/permanentDeleteSelectedMessages',
      restoreSelectedMessages: 'messages/restoreSelectedMessages',
      setMessageFormMode: 'messages/setMessageFormMode',
      setMessageSearchTerm: 'messages/setMessageSearchTerm',
      setShowMessageForm: 'messages/setShowMessageForm',
      setMessageListIndex: 'messages/setMessageListIndex'
    }),
    clearSearch(e) {
      this.$refs.search.newValue = ''
      this.searchInbox('')
    },
    async handleAction(action) {
      switch (action) {
        case 'archive':
          this.refreshing = 'archive'
          await this.archiveSelectedMessages()
          this.refreshing = ''
          break
        case 'delete':
          if (this.folder === 'trash') {
            this.refreshing = 'delete'
            await this.permanentDeleteSelectedMessages()
            this.refreshing = ''
            break
          } else {
            this.refreshing = 'delete'
            await this.deleteSelectedMessages()
            this.refreshing = ''
            break
          }
        case 'empty-trash':
          this.refreshing = 'empty'
          await this.emptyTrash()
          this.refreshing = ''
          break
        case 'move':
          this.refreshing = 'move'
          await this.moveSelectedMessagesToInbox()
          this.refreshing = ''
          break
        case 'refresh':
          this.refreshing = 'refresh'
          this.setMessageListIndex({ stay: true })
          await this.fetchMessageList()
          this.refreshing = ''
          break
        case 'restore':
          this.refreshing = 'restore'
          await this.restoreSelectedMessages()
          this.refreshing = ''
          break
        case 'deselectall':
          this.refreshing = 'selectall'
          this.clearSelectedMessages()
          this.refreshing = ''
          break
        case 'selectall':
          this.refreshing = 'selectall'
          this.clearSelectedMessages()
          for (const message of this.messageList) {
            await this.addSelectedMessage(message._id)
          }
          this.refreshing = ''
          break
        default:
          break
      }
    },
    searchInbox(e) {
      this.setMessageSearchTerm(e)
    },
    showButton(btn) {
      return this.display[this.folder].includes(btn)
    }
  }
}
