export const state = () => ({
  faqs: [
    {
      id: 1,
      order: 1,
      question: 'What is an RFI?',
      answer: 'An RFI is a Request for Information. In the practice of corporate site selection, ' +
        'a project owner creates and sends an RFI to economic development and real estate organizations to collect data ' +
        'about the communities, sites and buildings that meet the project requirements. Another term, RFP, Request for ' +
        'Proposal, may also be used to describe the same process.'
    },
    {
      id: 2,
      order: 2,
      question: 'What is an RFI template?',
      answer: 'An RFI template is included for all Lasso users. The template contains a list of questions to collect data from community, site, and building representatives. Custom RFI templates can be created by each user or a request can be made to the Lasso team to purchase a custom template. Please contact the sales team for pricing.'
    },
    {
      id: 3,
      order: 3,
      question: 'What is a user?',
      answer: 'A User is defined as an individual given a unique login account to Lasso.'
    },
    {
      id: 4,
      order: 4,
      question: 'Our state subscribes to ZoomProspector. Is it possible to sync our properties from GIS Planning to Lasso so our communities don’t need to recreate properties?',
      answer: 'Yes! Lasso has a built-in API to receive properties from ZoomProspector for an annual fee of $1500. For details, please contact Stevie Chavez, VP of Regional Sales, at steviechavez@strataplatforms.com.'
    },
    {
      id: 5,
      order: 5,
      question: 'I\'m interested in purchasing Lasso. What is your pricing plan and how do I purchase?',
      answer: 'We offer annual licenses. Please contact us via email or by the contact form above. After pricing and terms are in alignment, you will receive a software license agreement and an invoice.'
    },
    {
      id: 6,
      order: 6,
      question: 'Can I purchase add-ons to obtain additional user seats and RFI distributions?',
      answer: 'Yes, if during the sign up process, or after have begun your license, you determine that additional users or RFI distributions are needed, please contact Customer Success at <a href="mailto:support@lassoedapp.com"><strong>support@lassoedapp.com</strong></a> to discuss your needs, obtain pricing, and manage your license.'
    },
    {
      id: 7,
      order: 7,
      question: 'I\'ve received an email invitation for a Lasso project. What do I do now?',
      answer: 'You have been invited to respond to an RFI from a Project Owner. A Project Owner is collecting data about communities, sites, and buildings. Click on the link provided in the email and navigate to the Projects screen. You will see the project you were invited to. Click on the Participate icon if you would like to respond to the RFI. If you do not plan to respond to the RFI, please click the Decline icon.'
    },
    {
      id: 8,
      order: 8,
      question: 'How do I add properties to a project?',
      answer: 'Login to Lasso and navigate to the Projects list. Click on the Project and select Properties from the left-side navigation.'
    },
    {
      id: 9,
      order: 9,
      question: 'What is LOIS?',
      answer: 'LOIS is a cloud-based database of sites and buildings that are being marketed to domestic and global corporations. LOIS primarily has property listings in the USA. Economic development organizations and real estate representatives may list properties. If your organization would like to store all of its properties in Lasso to be prepared for future RFI submissions, consider purchasing an API data feed from your property database into the LOIS database. Review more at <a href="http://www.locationone.com/" target="=_blank"><strong>http://www.locationone.com/</strong></a> and request a demo to review the software and pricing.'
    },
    {
      id: 10,
      order: 10,
      question: 'Does a Lasso Project Owner or Participant need to be a LOIS user?',
      answer: 'Lasso users do not need to be LOIS users. Lasso and LOIS are integrated and have been designed so that location data populates in both applications.'
    },
    {
      id: 11,
      order: 11,
      question: 'What is a LOIS temporary account',
      answer: 'When a Project Owner invites a Participant to respond to an RFI, Lasso reviews the email address to determine if the Participant is already stored in the Lasso and LOIS database. If the Participant is new, then a temporary LOIS account is created for them to facilitate their response to the specific RFI request. The properties that a Participant adds to the RFI response are not visible to LOIS users. A Participant will need to purchase a LOIS subscription to list their sites for view by all LOIS users.'
    },
    {
      id: 12,
      order: 12,
      question: 'Can a Lasso user be both a Project Owner and a Participant at the same time?',
      answer: 'Yes. For example, a state economic development organization (State EDO) could be a Project Owner to create and distribute an RFI to local communities. The State EDO could also be a Participant to submit sites that meet the requirement of that RFI.'
    },
    {
      id: 13,
      order: 13,
      question: 'Is Lasso being utilized to assemble properties for site selection projects?',
      answer: 'Yes! Since it\'s launch, Lasso has been used by site selectors and corporate decision-makers for more than 30 site selection projects and counting.'
    },
    {
      id: 14,
      order: 14,
      question: 'I logged into Lasso with my LOIS credentials, but I do not see my community listed. How do I fix this?',
      answer: '<p>Before you can respond to an RFI submitted through Lasso, you must be associated with the community that you represent. Once you are logged into Lasso, click the user profile icon in the top right-hand corner of the screen and click "Profile". Scroll down to the "My Communities" section to select the counties and communities that you represent.</p>' +
        '<p>Note - If your organization is a LOIS customer, your communities are synced and managed through LOIS and will not display in the Lasso profile. In this case, for assistance please email Amy White, Head of LOIS Customer Support, at <a href="mailto:locationonehelpdesk@gmail.com"><strong>locationonehelpdesk@gmail.com</strong></a> or contact the LOIS Administrator inside of your organization and provide them with the following instructions below.</p>' +
        '<p>How to Update Communities in LOIS<br>' +
        '<ul>' +
          '<li>Click the "Settings" tab at the top of the screen, then click "Organization Settings". The Organizations List screen will appear.</li>' +
          '<li>Click your Organization Name under the Organization Name column. The Organization screen will appear.</li>' +
          '<li>Click the "Areas" tab on the left side of the screen.</li>' +
          '<li>For States, click the checkbox for the state(s) you wish to add properties to.</li>' +
          '<li>For Counties, click the checkbox for the counties you wish to add properties to.</li>' +
          '<li>For Communities, click the checkbox for the communities you wish to add properties to.</li>' +
        '</ul>' +
        '</p>' +
        '<p>Note - LOIS administrators can only choose to display or hide communities. If you wish to add a community that doesn\'t appear, please contact Amy White for assistance at <a href="mailto:locationonehelpdesk@gmail.com"><strong>locationonehelpdesk@gmail.com</strong></a>.</p>'
    },
    {
      id: 15,
      order: 15,
      question: 'What does the data look like when exported out of Lasso?',
      answer: '<p>Data exported out of Lasso is downloaded into a Microsoft Excel file. All user types (Project Owners, Delegators, and Delegates) can export data.</p>' +
        '<p>Project Owners can export at a Project level and result with all Property data in one Excel sheet, organized by RFI question and in a data separated format, ready for upload to any data analytic software.</p>' +
        '<p>How to Export a Project<br>' +
        '<ul>' +
          '<li>Click the "Projects" tab. The Project List screen will appear.</li>' +
          '<li>Click the "Project Record" you wish to export. The Project Details screen will appear.</li>' +
          '<li>Click "Export Project" in the top-right corner of the Project details. A formatted Excel spreadsheet will be downloaded to your PC, where you can share or edit the data as needed.</li>' +
        '</ul>' +
        '</p>' +
        '<p>All users can export data at the Property level.</p>'
    },
    {
      id: 16,
      order: 16,
      question: 'If a regional team has multiple project managers, is there a way for the entire team to access the property data?',
      answer: '<p>To access a property record that has already been submitted through Lasso, the Delegator or Delegate can click "Select from Existing Properties" to see the same list of existing properties.</p>' +
        '<p>If users are from different organizations, they will not see each other\'s existing properties. If a regional team would like to manage its property records in a regional format, then one of the regional organizations would take the lead. Then, all regional members who reply to RFI submissions can be set up in Lasso as members of that regional organization.</p>'
    },
    {
      id: 17,
      order: 17,
      question: 'Can Delegators prevent their RFI submission to the Project Owner until 100% of the RFI questions have been answered?',
      answer: '<p>Yes. Delegators have final approval on the property submissions from their Delegates. Delegators can contact their Delegates and ask them to complete the submission. Delegators can also provide the data prior to approving the property for submission.</p>' +
        '<p>It is recommended for Delegators and Delegates to mark questions that are not applicable as N/A so that they can achieve the 100% completion rate.</p>'
    },
    {
      id: 18,
      order: 18,
      question: 'Are several people allowed at one time to work an RFI or is there only one delegate that can enter information?',
      answer: '<p>Multiple people can work together, collaboratively, inside of Lasso. Lasso is designed as a multi-level communication platform to mimic how Requests for Information (RFIs) are sent by corporations and site selectors to states and communities.</p>' +
        '<p>Once the Project Owner issues the RFI to a batch of Delegators (typically state or regional economic development organizations) the Delegators can then determine the set of Delegates (typically local city or county economic development organizations) they\'d like to invite to submit properties. A Delegate can the assign specific questions to a Contributor, such as a water or utility representative.</p>' +
        '<p>To submit the property submissions, one Delegate will need to take responsibility for a property submission to ensure all information is complete prior to sending. Then, one Delegator takes responsibility for a batch of property submissions to ensure all are complete prior to submission to the Project Owner.</p>' +
        '<p>If two people want to work simultaneously as one of these roles, their options are to:' +
          '<ul>' +
          '<li>Option 1 - Share Login Credentials<br>Share login credentials with the individuals that will be assisting with the responses.' +
          '<li>Option 2 - Request Excel Export from Project Owner<br>The Project Owner for the project can email a copy of the RFI in an Excel formatted spreadsheet. The spreadsheet can be sent to the individuals that can provide responses to questions. Once the responses have been received, the Delegator or Delegate will need to manually enter the responses for the properties into Lasso.</li>' +
        '</p>'
    },
    {
      id: 19,
      order: 19,
      question: 'As a local EDO, we\'d like to have approval of the properties that are submitted from our community. Is that possible?',
      answer: '<p>No, however, a Delegator (usually at the State level) cannot submit a Delegate\'s (usually the local level) property without approval from the Delegate first. When a Participant (Delegator or Delegate) creates a property, the Participant\'s organization will be the owner of the property. The Delegator typically belongs to a different organization than their Delegates.</p>' +
        '<p>If a Delegator has invited a Delegate to participate in a project, Delegators have the option to submit their organization\'s properties, or the properties their Delegates submit to them from their organization. The Delegate has full authority as to what sites/buildings they choose to submit to the project. A Delegator will only be able to view and submit a Delegate\'s property if the Delegate has first approved and submitted their organization\'s property for consideration.</p>'
    },
    {
      id: 20,
      order: 20,
      question: 'If we represent both a region and communities, are we able to delegate to our partner communities but also respond on behalf of our own communities?',
      answer: '<p>Yes, by default Delegators can submit their regional properties, as well as invite Delegates to submit their local communities. Both Delegators and Delegates have access to the Properties tab within a project, where they can add a new building or site, or select an existing property.</p>' +
        '<p>While both Delegators and Delegates can view, add, and submit their own properties, Delegators also have access to the Delegates tab within the project, allowing them to review and update their Delegate\'s property responses and submit the Delegate\'s properties to the project.</p>'
    },
    {
      id: 21,
      order: 21,
      question: 'Will our information be shared with any 3<sup>rd</sup> party entities?',
      answer: 'No. We take data confidentiality seriously. Please review our Privacy Policy for more information.'
    },
    {
      id: 22,
      order: 22,
      question: 'I see that some questions are marked as "required". Do I have to respond to all required questions and how do I know I\'ve answered them all?',
      answer: '<p>Required Questions are questions the Project Owner has marked that require a response. To submit both your properties and the project, Participants must provide a response to all required question in an RFI.</p>' +
        '<p>How do I know if I\'ve answered all required questions?</p>' +
        '<p>When reviewing the RFI, you will see a list of categories, known as "Primary Categories." If the Project Owner has assigned required questions within the RFI, you will notice some of the Primary Categories are marked with a red "R." A red "R" indicates that there are questions within the Primary Category that require a response to proceed.</p>' +
        '<p>How to View and Respond to Required RFI Questions<br>' +
          '<ul>' +
            '<li>When viewing the Project Details screen, click the "Properties" tab to the left. The "My Project Properties" screen will appear.</li>' +
            '<li>Click the "Property" you wish to provide responses for. The Project Property screen will appear.</li>' +
            '<li>Scroll down the Questions section. You will notice the Categories header with a list of Primary Categories. Primary Categories marked with a red "R" indicates there are required questions assigned to these Categories.</li>' +
            '<li>Click the"Primary Category" marked with a red "R". The Categories List will expand to display Secondary Categories assigned to the Primary Category.</li>' +
            '<li>Click the "Secondary Category" marked with a red "R". The Questions assigned to the Secondary Category will appear. Required questions will be marked with a red "R".</li>' +
            '<li>Click the "Question(s)" marked with a red "R", then enter a response and click "Save". The response will be saved, and the red "X" to the right will update to a green "check", indicating you have responded to the question. Note: You can refer to the "Answered Questions" section above the "Categories" header to track your progress.</li>' +
          '</ul>' +
        '</p>'
    },
    {
      id: 23,
      order: 23,
      question: 'I was invited to participate in a project, but I haven\'t received the invitation email yet. What should I do?',
      answer: '<p>If you are a Project Owner or a Delegator that has invited a Participant to a project, if they have not received an email invitation from Lasso it is most likely due to the following:</p>' +
        '<p>' +
          '<ul>' +
            '<li class="ordered">Email Address - Confirm the email address that was assigned to the Participant is correct. If the email address is incorrect, you can delete the Participant or Delegate and re-invite them to the project with the correct email address.</ol>' +
            '<li class="ordered">Spam/Junk Folder - It is possible your email provider has marked the invitation email as spam. Please check your spam or junk folders for the email. To ensure delivery of future emails from Lasso, it is best practice to mark the email address, <strong>system@lassoedapp.com</strong>, as a "trusted sender" so that any emails sent from Lasso will be directed to your inbox.</ol>' +
          '</ul>' +
        '</p>' +
        '<p>If you have tried the above steps and the Participant is still having trouble locating the invitation email, please email <a href="mailto:support@lassoedapp.com"><strong>support@lassoedapp.com</strong></a> for further assistance.</p>'
    },
    {
      id: 24,
      order: 24,
      question: 'Does Lasso allow you to add sites outside of the USA?',
      answer: '<p>Yes. Lasso offers global support. When adding a new site or building, you can choose to add a site in North America (USA, Canada, Mexico) or outside North America (everywhere else)</p>.'
    },
    {
      id: 25,
      order: 25,
      question: 'What should a participant do that has a text response for a required numeric field? For example, if sale price is a numeric field, but a participant would like to respond with something like, "Sale price is negotiable based on several factors," how should they respond?',
      answer: '<p>If you wish to submit a response beyond the accepted parameters, we recommend contacting the Lasso Project Owner for assistance.</p>'
    },
    {
      id: 26,
      order: 26,
      question: 'We have a site that is served by two power providers and the customer has a choice for which provider they use. How do I allow both providers to respond to the RFI?',
      answer: '<p>Currently, Lasso allows one response per question. We recommend contacting the Lasso Project Owner for assistance.</p>'
    },
    {
      id: 27,
      order: 27,
      question: 'I have tried sharing these questionnaires to utility providers in the past and when asked to verify their email address, it says they are not authorized. I have tried it with about 4 -5 and none could get it to work. Does the delegator need to add them as verified users?',
      answer: '<p>If a third-party contributor is having trouble verifying their email address, we recommend submitting a ticket by emailing support@lassoedapp.com. A representative will respond and escalate the issue to the Lasso development team if needed.</p>'
    },
    {
      id: 28,
      order: 28,
      question: 'Can the state EDO (Delegator) send out questionnaires for additional contributors on a property submission like local EDOs (Delegates)?',
      answer: '<p>Yes. Delegators can add their own properties to submit, as well as send questionnaires just like their Delegates.</p>'
    },
    {
      id: 29,
      order: 29,
      question: 'Do we need a separate login to Lasso if we are a LOIS user?',
      answer: '<p>No. If you have an active LOIS user profile, you can use the same credentials to login to Lasso by clicking the “Login with LOIS” button. You will be redirected to locationone.com to enter your email address and password, and once done will be logged into Lasso.</p>'
    },
    {
      id: 30,
      order: 30,
      question: 'Is there a fee to EDO’s to use this platform?',
      answer: '<p>No. There is no fee for Participants to submit properties to Lasso RFI projects.</p>'
    },
    {
      id: 31,
      order: 31,
      question: 'Can you send a questionnaire to more than one Contributor at the same time? For example, if you have more than one electric or fiber provider?',
      answer: '<p>No. Only one contact can be selected as the recipient per questionnaire. If you need to send the same questionnaire to two contacts, you will need to create a second questionnaire for the second contact.</p>'
    },
    {
      id: 32,
      order: 32,
      question: 'Is the Property Submission Summary report only available to Delegators?',
      answer: '<p>The report is available to all participants, regardless if they are a Delegator or a Delegate. A Delegator’s report will include the properties they and their Delegates submit. A Delegate’s report will only include the properties they submit.</p>'
    },
    {
      id: 33,
      order: 33,
      question: 'We use a regional EDC to submit our sites into LOIS and do not login directly ourselves. Do we need the regional EDC to get registered with Lasso so they can help us tie in the LOIS information?',
      answer: 'No, but a user profile for your LOIS organization will need to be created. For assistance with this, please email support@lassoedapp.com.'
    },
    {
      id: 34,
      order: 34,
      question: 'Can properties be added to Lasso in advance of a site selection project?',
      answer: '<p>Absolutely. We encourage all communities to participate in the GLS Submit Your Site program. This is a great way for your organization to add your properties to Lasso and be RFI-ready by completing a handful of questions as it pertains to your properties. Please visit this link and complete the form on the page to receive an invitation.</p>'
    },
    {
      id: 35,
      order: 35,
      question: 'When using the 3rd party contributor feature, will the contributor be able to see the project details along with their questions?',
      answer: '<p>Yes. As a Participant navigates the Questionnaire Wizard, they will have the option to include the project description and/or project attachments when drafting the questionnaire to be sent to the Contributor.</p>'
    },
    {
      id: 36,
      order: 36,
      question: 'Delegators can see the sites they submitted but not all of the sites submitted to a project, correct?',
      answer: '<p>Correct. If you’re a Delegator in the state of Kentucky with Delegates throughout your state/region, you’ll only be able to see their properties, and not another state’s/group’s Delegators, Delegates, and properties that are participating in the same projects or otherwise.</p>'
    }
  ]
})

export const getters = {
  getFaqs: state => state.faqs
}
