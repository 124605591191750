var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "category-name" },
    [
      _vm.categoryRenameActive &&
      _vm.activeCategory === _vm.category.name &&
      _vm.primaryCategory === _vm.categoryParent
        ? _c(
            "b-col",
            [
              _c("b-input", {
                ref: _vm.secondaryCategoryUniqueId,
                attrs: { readonly: _vm.savingCategory },
                model: {
                  value: _vm.categoryToEdit,
                  callback: function($$v) {
                    _vm.categoryToEdit = $$v
                  },
                  expression: "categoryToEdit"
                }
              })
            ],
            1
          )
        : _c(
            "b-col",
            [
              !_vm.activeCategory
                ? _c("b-icon", {
                    attrs: {
                      icon: "cursor-move",
                      size: "is-small",
                      type: "is-templates"
                    }
                  })
                : _vm._e(),
              _vm._v("\n    " + _vm._s(_vm.category.name) + "\n  ")
            ],
            1
          ),
      _vm._v(" "),
      _vm.activeCategory === _vm.category.name &&
      _vm.primaryCategory === _vm.categoryParent
        ? _c("CategorySortRowActions", {
            attrs: {
              "available-categories-for-primary-move":
                _vm.availableCategoriesForPrimaryMove,
              "category-unique-id": _vm.secondaryCategoryUniqueId,
              category: _vm.category,
              "category-to-edit": _vm.categoryToEdit,
              "category-owner": _vm.categoryOwner,
              "category-type": _vm.categoryType,
              "category-rename-active": _vm.categoryRenameActive,
              "category-parent": _vm.categoryParent,
              level: "secondary"
            },
            on: {
              deselectCategory: function(value) {
                return _vm.cancelCategoryActions(value)
              },
              savingCategory: function(value) {
                return (_vm.savingCategory = value)
              },
              setInvalidCategoryRename: function(value) {
                return _vm.setInvalidCategoryRename(value)
              },
              toggleRenameCategory: function(value) {
                return _vm.toggleRenameCategory(value)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }