import Vue from 'vue';

import {
  AlertPlugin,
  AvatarPlugin,
  BadgePlugin,
  BreadcrumbPlugin,
  ButtonPlugin,
  CalendarPlugin,
  CarouselPlugin,
  CardPlugin,
  CollapsePlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  LayoutPlugin,
  LinkPlugin,
  ModalPlugin,
  NavPlugin,
  NavbarPlugin,
  ProgressPlugin,
  SpinnerPlugin,
  TabsPlugin,
  ToastPlugin,
  TooltipPlugin
} from 'bootstrap-vue';

Vue.use(AlertPlugin);
Vue.use(AvatarPlugin);
Vue.use(BadgePlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(ButtonPlugin);
Vue.use(CalendarPlugin);
Vue.use(CarouselPlugin);
Vue.use(CardPlugin);
Vue.use(CollapsePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(LayoutPlugin);
Vue.use(LinkPlugin);
Vue.use(ModalPlugin);
Vue.use(NavPlugin);
Vue.use(NavbarPlugin);
Vue.use(ProgressPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TabsPlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
