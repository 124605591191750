//
//
//
//
//
//

import { userProjectRoles, userRoles } from '@/server/constants'

const userRolesBoth = userRoles.participant + userRoles.projectowner

export default {
  name: 'NavSecondary',
  data () {
    return {
      projectNavItemsSecondary: [
        { page: 'details', security: userRolesBoth },
        { page: 'questions', security: userRolesBoth },
        { page: 'requirements', security: userRoles.projectowner },
        { page: 'participants', security: userRoles.projectowner },
        { page: 'properties', security: userRolesBoth },
        { page: 'delegates', security: userProjectRoles.delegator }
      ]
    }
  }
}
