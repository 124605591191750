//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'QuickStartModal',
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isProjectOwner: 'user/isProjectOwner',
      isParticipant: 'user/isParticipant'
    }),
    modalHeaderLabel() {
      let label = ''
      if (this.isAdmin) {
        label = 'Quick Start For Administrators'
      } else if (this.isProjectOwner) {
        label = 'Quick Start For Project Owners'
      } else if (this.isParticipant) {
        label = 'Quick Start For Participants'
      }
      return label
    }
  }
}
