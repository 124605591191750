var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "content-section" }, [
    _c("div", { staticClass: "section-header" }, [
      _vm.$slots.header
        ? _c("div", [
            _c("h4", [_vm._t("header")], 2),
            _vm._v(" "),
            _vm.$slots.subtitle ? _c("div", [_vm._t("subtitle")], 2) : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.action
        ? _c("div", { staticClass: "actions" }, [_vm._t("action")], 2)
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "section-inner" },
      [
        _vm.$slots.main
          ? _c("b-row", [_c("b-col", [_vm._t("main")], 2)], 1)
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }