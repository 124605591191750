var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-search",
      attrs: {
        "data-name": "icon-search",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: { x1: "18.43", y1: "17.65", x2: "22.86", y2: "22.08" }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: {
          d: "M20.61,12a8.69,8.69,0,1,1-8.69-8.69A8.65,8.65,0,0,1,20.61,12Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }