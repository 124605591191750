var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-trash-empty",
      attrs: {
        "data-name": "icon-trash-empty",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d:
            "M6.54,8.74V20.07a.86.86,0,0,0,.86.86H18.1a.86.86,0,0,0,.86-.86V8.74Z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d: "M9.54,8.35V7.49a.86.86,0,0,1,.86-.86h4.7a.86.86,0,0,1,.86.86v.86"
        }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "20.66", y1: "8.74", x2: "4.57", y2: "8.74" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "16.36", y1: "11.41", x2: "9.13", y2: "18.64" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "9.15", y1: "11.41", x2: "16.38", y2: "18.64" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }