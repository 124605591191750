var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { staticClass: "question detail" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center" },
            [
              _vm.mode === "normal"
                ? _c("b-icon", {
                    attrs: {
                      icon: "cursor-move",
                      size: "is-small",
                      type: "is-templates"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.mode === "readonly"
                ? _c("span", [
                    _vm.question.required
                      ? _c(
                          "span",
                          { staticClass: "required-badge" },
                          [
                            _c("b-badge", { attrs: { variant: "danger" } }, [
                              _vm._v("R")
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(
                      "\n        " + _vm._s(_vm.question.text) + "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.mode === "normal"
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle",
                          value: _vm.question._id,
                          expression: "question._id"
                        }
                      ]
                    },
                    [
                      _vm.question.required
                        ? _c(
                            "span",
                            { staticClass: "required-badge" },
                            [
                              _c("b-badge", { attrs: { variant: "danger" } }, [
                                _vm._v("R")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(
                        "\n        " + _vm._s(_vm.question.text) + "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "col-info-actions", attrs: { cols: "3" } },
            [
              _c(
                "div",
                {
                  class: [
                    "col-phase",
                    _vm.questionPhaseExceedsPhaseCount ? "phase-exceeds" : ""
                  ]
                },
                [
                  _vm.questionPhaseExceedsPhaseCount
                    ? _c(
                        "span",
                        {
                          directives: [
                            { name: "b-tooltip", rawName: "v-b-tooltip" }
                          ],
                          attrs: { title: _vm.questionPhaseExceedsTooltip }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.question.phase) +
                              "\n        "
                          )
                        ]
                      )
                    : _c("span", [_vm._v(_vm._s(_vm.question.phase))])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-info" },
                [
                  _vm.question.requirement
                    ? _c("b-icon", {
                        attrs: { icon: "check", type: "is-success" }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.question._id,
              expression: "question._id"
            }
          ],
          staticClass: "detail-row"
        },
        [
          _c(
            "b-col",
            [
              _c(
                "b-collapse",
                {
                  staticClass: "detail-container",
                  attrs: { id: _vm.question._id }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "detail-header" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("h5", [_vm._v("Question Details:")]),
                          _vm._v(" "),
                          _vm.mode === "normal"
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "icon-button",
                                  attrs: { variant: "link", size: "sm" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.editQuestion(_vm.question._id)
                                    }
                                  }
                                },
                                [
                                  _c("IconEdit"),
                                  _vm._v(
                                    _vm._s(_vm.isDesktop ? "Edit" : "") +
                                      "\n            "
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mode === "normal"
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "icon-button",
                                  attrs: { variant: "link", size: "sm" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.deleteQuestionConfirm(
                                        _vm.question
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("IconDelete"),
                                  _vm._v(
                                    _vm._s(_vm.isDesktop ? "Delete" : "") +
                                      "\n            "
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _vm.question.tertiaryCategory
                        ? _c(
                            "b-col",
                            { staticClass: "tertiary", attrs: { md: "3" } },
                            [
                              _c("strong", [_vm._v("Tertiary:")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.question.tertiaryCategory) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.question.tag
                        ? _c(
                            "b-col",
                            { staticClass: "tag-wrapper", attrs: { md: "3" } },
                            [
                              _c("strong", [_vm._v("Tag:")]),
                              _vm._v(
                                " " + _vm._s(_vm.question.tag) + "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.question.loisDataField
                        ? _c(
                            "b-col",
                            { staticClass: "lois-field", attrs: { md: "3" } },
                            [
                              _c("strong", [_vm._v("Data Field Type:")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.question.loisDataField) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("b-col", { attrs: { md: "3" } }, [
                        _c("strong", [_vm._v("Answer Type:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.answerTypeLabel(_vm.question.type)) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.question.requirement
                    ? _c(
                        "b-row",
                        { staticClass: "operator" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("strong", [_vm._v("Requirement Operator:")]),
                              _vm._v(" "),
                              _c("RequirementOperatorValue", {
                                attrs: { requirement: _vm.question }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.question.note
                    ? _c(
                        "b-row",
                        { staticClass: "note" },
                        [
                          _c("b-col", [
                            _c("strong", [_vm._v("Note:")]),
                            _vm._v(
                              " " + _vm._s(_vm.question.note) + "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }