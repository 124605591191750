//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { email, required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'Register',
  auth: false,
  data() {
    return {
      registerForm: {
        company: '',
        email: '',
        password: '',
        firstName: '',
        lastName: ''
      },
      errorMsg: '',
      processing: false,
      success: false,
      showResend: false,
      showNoMoreResends: false
    }
  },
  computed: {
    ...mapGetters({
      referredNonAuthUser: 'messages/getReferredNonAuthUser',
      canResend: 'user/getCanResendConfirmationEmail',
      sendCountdown: 'user/getResendIntervalCounter'
    }),
    disableSubmit() {
      return this.$v.registerForm.$invalid
      // return !this.$v.registerForm.$dirty || (this.$v.registerForm.$dirty && this.$v.registerForm.$anyError)
    },
    submitButtonText() {
      return this.processing ? 'Please wait ...' : 'Create Account'
    }
  },
  async mounted() {
    if (this.referredNonAuthUser !== '') {
      this.registerForm.email = this.referredNonAuthUser
      // For participants, let's attempt to retrieve their company info and
      // first and last names here!
      const profile = await this.companyAndName(this.referredNonAuthUser)
      this.registerForm.company = profile.company
      this.registerForm.firstName = profile.firstName
      this.registerForm.lastName = profile.lastName
    }
  },
  methods: {
    ...mapActions({
      register: 'user/registerUser',
      companyAndName: 'user/fetchUserCompanyAndName',
      resetNetworkError: 'global/resetNetworkError',
      resendConfirmationEmail: 'user/resendConfirmationEmail'
    }),
    async resendConfirmation() {
      try {
        await this.resendConfirmationEmail(this.registerForm)
      } catch (e) {
        const message = e.response?.data || e.message
        if (message === 'max sends reached') {
          this.showNoMoreResends = true
          this.showResend = false
        }
      }
    },
    handleLoisLogin() {
      const email = this.referredNonAuthUser
      const redirectUrl = `${window.location.protocol}//${window.location.host}${this.$config.ssoLocalCallbackUri}`
      const url = `${this.$config.ssoAuthenticationUrl}?email=${email}&redirectUrl=${redirectUrl}`
      document.location.href = url
    },
    linkToInbox() {
      this.resetNetworkError()
      this.$router.push('/inbox')
    },
    onCancel() {
      // emit action to go back to login form
      this.$emit('change-form', 'login')
    },
    async onSubmit() {
      this.$v.registerForm.$touch()
      if (this.$v.registerForm.$anyError) {
        return
      }

      try {
        this.errorMsg = ''
        this.processing = true
        await this.register(this.registerForm)
        this.success = true
        this.showResend = true
      } catch (error) {
        this.errorMsg = error.message
        this.processing = false
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.registerForm[name]
      return $dirty ? !$error : null
    }
  },
  validations: {
    registerForm: {
      company: {
        required,
        minLength: minLength(3)
      },

      firstName: {
        required,
        minLength: minLength(3)
      },

      lastName: {
        required,
        minLength: minLength(3)
      },

      email: {
        required,
        email
      },

      password: {
        required,
        minLength: minLength(8),
        // Must contain at least one number and one letter
        hasRequiredChars(value) {
          return /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z#?!@$%^&*-]{8,}$/.test(value)
        }
      }
    }
  }
}
