//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { formatShortDate } from '@/server/utils/utils-date'
import { isAnswered } from '@/server/commonwithclient'

export default {
  name: 'QuestionAnswerHistory',
  props: {
    questionAnswer: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      getFormattedAnswerByQuestion: 'answers/getFormattedAnswerByQuestion'
    }),
    answerList() {
      const list = !this.questionAnswer?.answerUpdates?.length
        ? [this.questionAnswer]
        : [this.questionAnswer, ...this.questionAnswer.answerUpdates]
      return list
    }
  },
  methods: {
    createdBy(answer) {
      return answer.createdBy?.fullName || 'System'
    },
    createdDate(answer) {
      return formatShortDate(answer.created)
    },
    answerValue(answer) {
      const answerVal = this.getFormattedAnswerByQuestion(null, answer)
      return isAnswered(answerVal) ? answerVal : 'No Answer On File'
    }
  }
}
