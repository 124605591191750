import { render, staticRenderFns } from "./AdminHeader.vue?vue&type=template&id=785ea39e&"
import script from "./AdminHeader.vue?vue&type=script&lang=js&"
export * from "./AdminHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {LicensesUnavailableModal: require('/lasso/components/modals/LicensesUnavailableModal.vue').default,IconAddPrimary: require('/lasso/components/icons/IconAddPrimary.vue').default,IconRefresh: require('/lasso/components/icons/IconRefresh.vue').default,IconList: require('/lasso/components/icons/IconList.vue').default,IconEditPrimary: require('/lasso/components/icons/IconEditPrimary.vue').default,IconDetails: require('/lasso/components/icons/IconDetails.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('785ea39e')) {
      api.createRecord('785ea39e', component.options)
    } else {
      api.reload('785ea39e', component.options)
    }
    module.hot.accept("./AdminHeader.vue?vue&type=template&id=785ea39e&", function () {
      api.rerender('785ea39e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/admin/AdminHeader.vue"
export default component.exports