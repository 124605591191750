var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-export-template",
      attrs: {
        "data-name": "icon-export-template",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "21.3", y1: "13.23", x2: "10.71", y2: "13.23" }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "17.24 17.47 21.47 13.23 17.28 9.04" }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d:
            "M19.79,7.69V4.55a.87.87,0,0,0-.86-.86H6.05a.87.87,0,0,0-.86.86V21.26a.87.87,0,0,0,.86.86H18.93a.87.87,0,0,0,.86-.86V19.45"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }