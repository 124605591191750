var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _vm.isDesktop
        ? _c(
            "b-row",
            { staticClass: "page-header-wrapper" },
            [
              _c(
                "b-col",
                { staticClass: "header-search" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("h1", { staticClass: "page-heading" }, [
                            _vm._v("\n            Inbox\n          ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "new",
                              attrs: {
                                squared: "",
                                variant: "link",
                                size: "sm",
                                title: "New Message"
                              },
                              on: {
                                click: function($event) {
                                  _vm.setShowMessageForm(true)
                                  _vm.setMessageFormMode("add")
                                }
                              }
                            },
                            [_c("IconAddPrimary")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              class: { refreshing: _vm.isRefreshing },
                              attrs: {
                                squared: "",
                                variant: "link",
                                size: "sm"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleAction("refresh")
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "refresh-icon" },
                                [
                                  _vm.refreshing === "refresh"
                                    ? _c("b-spinner", { attrs: { medium: "" } })
                                    : _c("IconRefresh")
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "header-actions", attrs: { cols: "9" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "actions-wrapper" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "actions" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "icon-button",
                              attrs: {
                                squared: "",
                                variant: "link",
                                size: "sm"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleAction(
                                    _vm.selectalltext === "Select All"
                                      ? "selectall"
                                      : "deselectall"
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "refresh-icon small" },
                                [
                                  _vm.refreshing === "selectall"
                                    ? _c("b-spinner", { attrs: { small: "" } })
                                    : _c("IconMailSelect")
                                ],
                                1
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.selectalltext) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.showButton("trash")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "icon-button",
                                  class: { refreshing: _vm.isRefreshing },
                                  attrs: {
                                    squared: "",
                                    variant: "link",
                                    size: "sm"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleAction("empty-trash")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "refresh-icon small" },
                                    [
                                      _vm.refreshing === "empty"
                                        ? _c("b-spinner", {
                                            attrs: { small: "" }
                                          })
                                        : _c("IconTrashEmpty")
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "\n            Empty Trash\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showButton("delete")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "icon-button",
                                  class: { refreshing: _vm.isRefreshing },
                                  attrs: {
                                    squared: "",
                                    variant: "link",
                                    size: "sm",
                                    disabled: !_vm.isMessagesSelected
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleAction("delete")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "refresh-icon small" },
                                    [
                                      _vm.refreshing === "delete"
                                        ? _c("b-spinner", {
                                            attrs: { small: "" }
                                          })
                                        : _c("IconMailDelete")
                                    ],
                                    1
                                  ),
                                  _vm._v("\n            Delete\n          ")
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showButton("archive")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "icon-button",
                                  class: { refreshing: _vm.isRefreshing },
                                  attrs: {
                                    squared: "",
                                    variant: "link",
                                    size: "sm",
                                    disabled: !_vm.isMessagesSelected
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleAction("archive")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "refresh-icon small" },
                                    [
                                      _vm.refreshing === "archive"
                                        ? _c("b-spinner", {
                                            attrs: { small: "" }
                                          })
                                        : _c("IconArchive")
                                    ],
                                    1
                                  ),
                                  _vm._v("\n             Archive\n          ")
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showButton("move")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "icon-button",
                                  class: { refreshing: _vm.isRefreshing },
                                  attrs: {
                                    squared: "",
                                    variant: "link",
                                    size: "sm",
                                    disabled: !_vm.isMessagesSelected
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleAction("move")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "refresh-icon small" },
                                    [
                                      _vm.refreshing === "move"
                                        ? _c("b-spinner", {
                                            attrs: { small: "" }
                                          })
                                        : _c("IconMailMove")
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "\n             Move To Inbox\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showButton("restore")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "icon-button",
                                  class: { refreshing: _vm.isRefreshing },
                                  attrs: {
                                    squared: "",
                                    variant: "link",
                                    size: "sm",
                                    disabled: !_vm.isMessagesSelected
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleAction("restore")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "refresh-icon small" },
                                    [
                                      _vm.refreshing === "restore"
                                        ? _c("b-spinner", {
                                            attrs: { small: "" }
                                          })
                                        : _c("IconTrashRestore")
                                    ],
                                    1
                                  ),
                                  _vm._v("\n            Restore\n          ")
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isMessagesSelected
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "icon-button selected",
                                  attrs: {
                                    variant: "link",
                                    squared: "",
                                    size: "sm"
                                  },
                                  on: { click: _vm.clearSelectedMessages }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.messagesSelected.length))
                                  ]),
                                  _vm._v(" Selected \n            "),
                                  _c("b-icon", { attrs: { icon: "close" } })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("b-input", {
                            ref: "search",
                            staticClass: "search-input",
                            attrs: {
                              type: "text",
                              placeholder: "Search",
                              value: _vm.inboxOptions.messageSearchTerm,
                              "icon-right": _vm.inboxOptions.messageSearchTerm
                                ? "magnify-close"
                                : "magnify",
                              "icon-right-clickable": ""
                            },
                            on: {
                              "icon-right-click": _vm.clearSearch,
                              input: _vm.searchInbox
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "b-row",
            { staticClass: "page-header-wrapper mobile" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    { staticClass: "heading-primary" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "new",
                              attrs: {
                                squared: "",
                                variant: "link",
                                size: "sm",
                                title: "New Message"
                              },
                              on: {
                                click: function($event) {
                                  _vm.setShowMessageForm(true)
                                  _vm.setMessageFormMode("add")
                                }
                              }
                            },
                            [_c("IconAddPrimary")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              class: { refreshing: _vm.isRefreshing },
                              attrs: {
                                squared: "",
                                variant: "link",
                                size: "sm"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleAction("refresh")
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "refresh-icon" },
                                [
                                  _vm.refreshing === "refresh"
                                    ? _c("b-spinner", { attrs: { medium: "" } })
                                    : _c("IconRefresh")
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "search",
                              attrs: {
                                squared: "",
                                variant: "link",
                                size: "sm",
                                title: "Search"
                              },
                              on: {
                                click: function($event) {
                                  _vm.showSearch = !_vm.showSearch
                                }
                              }
                            },
                            [_c("IconSearch")],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { staticClass: "btn-edit" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                squared: "",
                                size: "md",
                                variant: "outline-inbox"
                              },
                              on: {
                                click: function($event) {
                                  _vm.showActions = !_vm.showActions
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.showActions
                                      ? "Hide Actions"
                                      : "Show Actions"
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showSearch
                    ? _c(
                        "b-row",
                        { staticClass: "heading-search" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-input", {
                                ref: "search",
                                staticClass: "search-input",
                                attrs: {
                                  type: "text",
                                  placeholder: "Search",
                                  "icon-right": _vm.inboxOptions
                                    .messageSearchTerm
                                    ? "magnify-close"
                                    : "magnify",
                                  "icon-right-clickable": ""
                                },
                                on: {
                                  "icon-right-click": _vm.clearSearch,
                                  input: _vm.searchInbox
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showActions
                    ? _c(
                        "b-row",
                        { staticClass: "heading-secondary" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "icon-button",
                                  attrs: {
                                    squared: "",
                                    variant: "link",
                                    size: "sm"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleAction(
                                        _vm.selectalltext === "Select All"
                                          ? "selectall"
                                          : "deselectall"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "refresh-icon small" },
                                    [
                                      _vm.refreshing === "selectall"
                                        ? _c("b-spinner", {
                                            attrs: { small: "" }
                                          })
                                        : _c("IconMailSelect")
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.selectalltext) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "4" } },
                            [
                              _vm.isMessagesSelected
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "icon-button selected",
                                      attrs: {
                                        variant: "link",
                                        squared: "",
                                        size: "sm"
                                      },
                                      on: { click: _vm.clearSelectedMessages }
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.messagesSelected.length)
                                        )
                                      ]),
                                      _vm._v(" Selected \n            "),
                                      _c("b-icon", { attrs: { icon: "close" } })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  staticClass: "inbox-actions",
                                  attrs: { "mobile-modal": false, right: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "trigger" },
                                      slot: "trigger"
                                    },
                                    [
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "icon-button sort btn-filter",
                                            attrs: { variant: "link" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Actions\n                  "
                                            ),
                                            _c("b-icon", {
                                              attrs: { icon: "chevron-down" }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  !_vm.isMessagesSelected
                                    ? _c("b-dropdown-item", [
                                        _vm._v(
                                          "\n              Select a message to display available actions.\n            "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showButton("trash")
                                    ? _c(
                                        "b-dropdown-item",
                                        { attrs: { focusable: false } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "icon-button",
                                              class: {
                                                refreshing: _vm.isRefreshing
                                              },
                                              attrs: {
                                                squared: "",
                                                variant: "link",
                                                size: "sm"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleAction(
                                                    "empty-trash"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "refresh-icon small"
                                                },
                                                [
                                                  _vm.refreshing === "empty"
                                                    ? _c("b-spinner", {
                                                        attrs: { small: "" }
                                                      })
                                                    : _c("IconTrashEmpty")
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n                Empty Trash\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isMessagesSelected &&
                                  _vm.showButton("delete")
                                    ? _c(
                                        "b-dropdown-item",
                                        { attrs: { focusable: false } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "icon-button",
                                              class: {
                                                refreshing: _vm.isRefreshing
                                              },
                                              attrs: {
                                                squared: "",
                                                variant: "link",
                                                size: "sm"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleAction(
                                                    "delete"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "refresh-icon small"
                                                },
                                                [
                                                  _vm.refreshing === "delete"
                                                    ? _c("b-spinner", {
                                                        attrs: { small: "" }
                                                      })
                                                    : _c("IconMailDelete")
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n                Delete\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isMessagesSelected &&
                                  _vm.showButton("archive")
                                    ? _c(
                                        "b-dropdown-item",
                                        { attrs: { focusable: false } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "icon-button",
                                              class: {
                                                refreshing: _vm.isRefreshing
                                              },
                                              attrs: {
                                                squared: "",
                                                variant: "link",
                                                size: "sm"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleAction(
                                                    "archive"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "refresh-icon small"
                                                },
                                                [
                                                  _vm.refreshing === "archive"
                                                    ? _c("b-spinner", {
                                                        attrs: { small: "" }
                                                      })
                                                    : _c("IconArchive")
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n                 Archive\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isMessagesSelected &&
                                  _vm.showButton("move")
                                    ? _c(
                                        "b-dropdown-item",
                                        { attrs: { focusable: false } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "icon-button",
                                              class: {
                                                refreshing: _vm.isRefreshing
                                              },
                                              attrs: {
                                                squared: "",
                                                variant: "link",
                                                size: "sm"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleAction(
                                                    "move"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "refresh-icon small"
                                                },
                                                [
                                                  _vm.refreshing === "move"
                                                    ? _c("b-spinner", {
                                                        attrs: { small: "" }
                                                      })
                                                    : _c("IconMailMove")
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n                 Move To Inbox\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isMessagesSelected &&
                                  _vm.showButton("restore")
                                    ? _c(
                                        "b-dropdown-item",
                                        { attrs: { focusable: false } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "icon-button",
                                              class: {
                                                refreshing: _vm.isRefreshing
                                              },
                                              attrs: {
                                                squared: "",
                                                variant: "link",
                                                size: "sm"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleAction(
                                                    "restore"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "refresh-icon small"
                                                },
                                                [
                                                  _vm.refreshing === "restore"
                                                    ? _c("b-spinner", {
                                                        attrs: { small: "" }
                                                      })
                                                    : _c("IconTrashRestore")
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n                Restore\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }