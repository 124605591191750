var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "icon-archive",
        "data-name": "icon-archive",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "12.67", y1: "11.92", x2: "12.67", y2: "1.45" }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "8.43 7.86 12.67 12.1 16.86 7.9" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "6.03", y1: "11.35", x2: "1.63", y2: "14.68" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "19.13", y1: "11.25", x2: "23.53", y2: "14.68" }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { d: "M10,4.38H7a.86.86,0,0,0-.86.86v9" }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { d: "M19.05,14.25v-9a.85.85,0,0,0-.86-.86h-2.9" }
      }),
      _vm._v(" "),
      _c("rect", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          x: "1.25",
          y: "14.56",
          width: "22.61",
          height: "7.39",
          rx: "0.86"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }