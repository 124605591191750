//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CategorySortRowActions',
  props: {
    availableCategoriesForPrimaryMove: {
      type: Array,
      default: () => []
    },
    category: {
      type: Object,
      default: () => {}
    },
    categoryParent: {
      type: String,
      default: null
    },
    categoryUniqueId: {
      type: String,
      default: null
    },
    categoryToEdit: {
      type: String,
      default: null
    },
    level: {
      type: String,
      default: null
    },
    categoryOwner: {
      type: String,
      default: null
    },
    categoryType: {
      type: Number,
      default: null
    },
    categoryRenameActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      savingCategory: false
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'breakpoints/desktop',
      primaryCategories: 'questions/getPrimaryCategories',
      primaryCategory: 'questions/getPrimaryCategory',
      secondaryCategory: 'questions/getSecondaryCategory'
    })
  },
  beforeDestroy() {
    this.$emit('toggleRenameCategory', { category: null, categoryUniqueId: null, active: false })
  },
  methods: {
    ...mapActions({
      deleteQuestionsInCategory: 'questions/deleteQuestionsInCategory',
      moveToPrimaryCategory: 'questions/moveToPrimaryCategory',
      movePrimaryUnderOtherPrimaryCategory: 'questions/movePrimaryUnderOtherPrimaryCategory',
      moveUnderOtherPrimaryCategory: 'questions/moveUnderOtherPrimaryCategory',
      renameCategory: 'questions/renameCategory',
      fetchPrimaryCategories: 'questions/fetchPrimaryCategories',
      fetchSecondaryCategories: 'questions/fetchSecondaryCategoriesForSort'
    }),
    async deleteCategoryQuestions(level) {
      const params = {
        owner: this.categoryOwner,
        type: this.categoryType,
        primary: this.primaryCategory,
        secondary: this.secondaryCategory
      }
      this.$root.$emit('loading', true)
      await this.deleteQuestionsInCategory(params)
      this.$root.$emit('refresh-category-tree', { fetchPrimary: level === 'primary', primaryCategory: null, secondaryCategory: null, refreshOriginalTree: true })
      localStorage.removeItem('categoryselections')
    },
    async deleteCategoryQuestionsConfirm(level, category) {
      const { projectid } = this.$route.params
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'Are you sure you want to delete this category ',
          h('strong', `"${category}"? `),
          `${projectid
            ? 'All subcategories (if applicable), questions, and answer data will be deleted and cannot be undone!'
            : 'All subcategories (if applicable) and questions will be deleted and cannot be undone.'
          }`
        ]
      )
      const res = await this.$bvModal.msgBoxConfirm([customMsg], {
        title: 'Confirm Categories and Questions Delete!',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelVariant: 'light',
        cancelTitle: 'NO',
        footerBgVariant: 'dark',
        headerBgVariant: 'danger',
        hideHeaderClose: true,
        centered: true
      })
      if (res) {
        await this.deleteCategoryQuestions(level)
      }
    },
    async displayInvalidCategoryNameMessage(prevName, categoryUniqueId) {
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'Invalid category name! If you want all the questions in this category (and subcategories if any) to be Uncategorized follow these steps:',
          h('br'),
          '1) Click the "Move Under" button',
          h('br'),
          '2) Choose Uncategorized in the dropdown list'
        ]
      )
      await this.$bvModal.msgBoxOk([customMsg], {
        title: 'Invalid Category Rename!',
        okVariant: 'danger',
        okTitle: 'OK',
        footerBgVariant: 'dark',
        headerBgVariant: 'danger',
        hideHeaderClose: true,
        centered: true
      })
      this.$emit('setInvalidCategoryRename', { prevName, categoryUniqueId })
    },
    async moveUnder(level, previousName, newCategory) {
      this.$nextTick(() => {
        this.$root.$emit('loading', true)
      })
      if (level === 'primary') {
        const params = {
          owner: this.categoryOwner,
          type: this.categoryType,
          primaryCategory: previousName,
          newPrimaryCategory: newCategory === 'Uncategorized' ? '' : newCategory
        }
        await this.movePrimaryUnderOtherPrimaryCategory(params)
      } else {
        const params = {
          owner: this.categoryOwner,
          type: this.categoryType,
          primaryCategory: this.categoryParent,
          secondaryCategory: previousName,
          newPrimaryCategory: newCategory === 'Uncategorized' ? '' : newCategory
        }
        await this.moveUnderOtherPrimaryCategory(params)
      }
      this.$root.$emit('refresh-category-tree', { fetchPrimary: true, primaryCategory: newCategory, secondaryCategory: previousName, refreshOriginalTree: true })
    },
    async saveCategoryConfirm(prevName, categoryUniqueId) {
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'This action would delete this category and is not allowed since secondary categories exist. ',
          'Please try again and enter a name to rename this category.'
        ]
      )
      await this.$bvModal.msgBoxOk([customMsg], {
        title: 'Category Delete Not Allowed!',
        okVariant: 'light',
        okTitle: 'OK',
        footerBgVariant: 'dark',
        headerBgVariant: 'warning',
        hideHeaderClose: true,
        centered: true
      })
      this.$emit('setInvalidCategoryRename', { prevName, categoryUniqueId })
    },
    async saveCategoryRename(level, previousName, categoryUniqueId) {
      if (level === 'primary' && this.categoryToEdit === '' && this.category.secondaries.length) {
        // do not allow primary category to be deleted if secondaries exist, display modal error
        this.saveCategoryConfirm(previousName, categoryUniqueId)
      } else if (this.categoryToEdit === 'Uncategorized') {
        // do not allow category to be renamed to Uncategorized
        this.displayInvalidCategoryNameMessage(previousName, categoryUniqueId)
      } else {
        // all other normal category renaming functions
        this.savingCategory = true
        this.$emit('savingCategory', true) // emit to CategorySort to set input to readonly
        const params = {
          owner: this.categoryOwner,
          type: this.categoryType,
          categoryLevel: level,
          previousName,
          newName: this.categoryToEdit
        }
        await this.renameCategory(params)
        this.$root.$emit('update-category-tree', { prevName: previousName, newName: this.categoryToEdit, categoryParent: this.categoryParent, refreshOriginalTree: true })
        this.$emit('toggleRenameCategory', { category: null, categoryUniqueId: null, active: false }) // turn off category renaming
        this.$nextTick(() => {
          this.$emit('savingCategory', false) // emit to CategorySort to turn off readonly input
          this.savingCategory = false // turn off loading for row action buttons
        })
      }
    },
    async setSecondaryAsPrimaryConfirm(secondary) {
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'This action will assign all questions in this category to have a new Primary Category of ',
          h('strong', `"${secondary}"`),
          h('br'),
          h('br'),
          h('p', 'Tertiary Categories that had been assigned, will move up as the Secondary Category for those questions.'),
          h('p', 'Questions with no existing Tertiary Category may need to be reviewed to ensure they are assigned a new Secondary Category.')
        ]
      )
      const res = await this.$bvModal.msgBoxConfirm([customMsg], {
        title: 'Notification to Review Category Assignments!',
        okVariant: 'warning',
        okTitle: 'OK',
        cancelVariant: 'light',
        cancelTitle: 'CANCEL',
        footerBgVariant: 'dark',
        headerBgVariant: 'warning',
        hideHeaderClose: true,
        centered: true
      })
      if (res) {
        await this.setSecondaryAsPrimary(secondary)
      }
    },
    async setSecondaryAsPrimary(category) {
      // this function changes a secondary category into a primary category
      this.$root.$emit('loading', true)
      const params = {
        owner: this.categoryOwner,
        type: this.categoryType,
        secondaryCategory: category
      }
      await this.moveToPrimaryCategory(params)
      this.$root.$emit('refresh-category-tree', { fetchPrimary: true, primaryCategory: category, secondaryCategory: null, refreshOriginalTree: true })
    }
  }
}
