//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { uppercaseFirst } from '@/server/utils/utils-string'

export default {
  name: 'LicensesUnavailableModal',
  props: {
    licenseType: {
      type: String, // expects user or rfi
      default: ''
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isListAdmin: 'user/isListAdmin',
      isOrgAdmin: 'user/isOrgAdmin',
      isSuperAdmin: 'user/isSuperAdmin',
      isProjectOwner: 'user/isProjectOwner',
      user: 'user/user'
    }),
    displaySalesCTA() {
      return (this.isProjectOwner || this.isOrgAdmin) && !(this.isListAdmin || this.isSuperAdmin)
    },
    formattedLicenseType() {
      return uppercaseFirst(this.licenseType)
    }
  },
  methods: {
    ...mapActions({
      setMessageBuyLicenses: 'messages/setMessageBuyLicenses'
    }),
    async contactSales() {
      await this.setMessageBuyLicenses({ type: this.licenseType, org: this.user?.organization?.name })
      this.$router.push({ name: 'inbox', params: { new: true } })
    }
  }
}
