//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import readableError from '@/filters/readable-errors'

export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: () => {}
    },
    stack: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    displayedErrorMessage() {
      // typical if we have an error communicating with server
      if (this.error.info?.response?.data?.message) {
        return this.error.info.response.data.message
      } else if (this.error.response?.data?.message) {
        return this.error.response.data.message
      } else if (this.error.info?.response?.data) {
        return this.error.info.response.data
      } else if (this.error.response?.data) {
        return this.error.response.data
      } else if (this.error.info?.message) {
        return this.error.info.message
      } else if (this.error.message) {
        return this.error.message
      }
      // this searches for any semblence of 'message' in the error
      const message = this.getMessage(this.error)
      if (message) {
        return message
      }
      return String(this.error)
    },
    displayedErrorMethod() {
      return this.error?.method
    },
    displayedErrorStatus() {
      return this.error?.info?.status
    },
    friendlyError() {
      return readableError(this.displayedErrorMessage)
    },
    showStackTrace() {
      return this.$config.environment !== 'production'
    }
  },
  methods: {
    resetError() {
      this.$store.commit('global/resetNetworkError')
    },
    getMessage(obj) {
      let result = null
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          result = this.getMessage(obj[i])
          if (result) {
            break
          }
        }
      } else {
        for (const prop in obj) {
          if (prop === 'message') {
            return obj.message
          }
          if (obj[prop] instanceof Object || Array.isArray(obj[prop])) {
            result = this.getMessage(obj[prop])
            if (result) {
              break
            }
          }
        }
      }
      return result
    }
  }
}
