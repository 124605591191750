var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template" },
    [
      _vm.loading ? _c("LoadingOverlay") : _vm._e(),
      _vm._v(" "),
      _c(
        "b-alert",
        {
          attrs: {
            variant: "danger",
            fade: "",
            show: _vm.errorMsg !== null,
            dismissible: ""
          },
          on: {
            dismissed: function($event) {
              _vm.errorMsg = null
            }
          }
        },
        [
          _c(
            "strong",
            [
              _c(
                "b-avatar",
                { attrs: { size: "sm" } },
                [
                  _c("b-icon", {
                    attrs: { icon: "exclamation-thick", size: "is-small" }
                  })
                ],
                1
              ),
              _vm._v("\n      Import Error:\n    ")
            ],
            1
          ),
          _vm._v(" " + _vm._s(_vm.errorMsg) + "\n  ")
        ]
      ),
      _vm._v(" "),
      _vm.template
        ? _c(
            "Section",
            { staticClass: "template" },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v("\n      " + _vm._s(_vm.template.name) + " \n      "),
                  !_vm.template.user
                    ? _c("b-badge", [_vm._v("\n        S\n      ")])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("template", { slot: "subtitle" }, [
                _vm._v("\n      " + _vm._s(_vm.template.description) + "\n    ")
              ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "action" },
                [
                  _vm.showEditImportButtons
                    ? _c(
                        "b-upload",
                        {
                          staticClass: "template-import",
                          on: {
                            input: function($event) {
                              return _vm.importTemplate(_vm.template)
                            }
                          },
                          model: {
                            value: _vm.file,
                            callback: function($$v) {
                              _vm.file = $$v
                            },
                            expression: "file"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn icon-button btn-light btn-sm rounded-0",
                              attrs: { title: "Import Template" }
                            },
                            [
                              _c("IconImport"),
                              _vm._v(" "),
                              _vm.isDesktop
                                ? _c("span", [_vm._v("Import")])
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "icon-button",
                      attrs: {
                        variant: "light",
                        size: "sm",
                        squared: "",
                        title: "Export Template"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.exportTemplate(_vm.template)
                        }
                      }
                    },
                    [
                      _c("IconExport"),
                      _vm._v(" "),
                      _vm.isDesktop ? _c("span", [_vm._v("Export")]) : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showEditImportButtons
                    ? _c(
                        "b-button",
                        {
                          staticClass: "icon-button",
                          attrs: {
                            variant: "light",
                            size: "sm",
                            squared: "",
                            title: "Add Question"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.addTemplateQuestion(_vm.template)
                            }
                          }
                        },
                        [
                          _c("IconAddTemplateQuestion"),
                          _vm._v(" "),
                          _vm.isDesktop
                            ? _c("span", [_vm._v("Add Question")])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("template", { slot: "main" }, [_c("CategoriesQuestions")], 1)
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }