//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from 'lodash-es'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TemplateLayout',
  data () {
    return {
      pageTheme: ''
    }
  },
  computed: {
    ...mapGetters({
      currentTheme: 'global/pageTheme',
      error: 'global/networkError',
      stack: 'global/networkErrorStack',
      isDesktop: 'breakpoints/desktop',
      isMobile: 'breakpoints/mobile',
      navOpen: 'global/navOpen',
      template: 'templates/getActiveTemplate'
    }),
    breadcrumbLabel() {
      const routes = ['templates-templateid-add-question', 'templates']
      return routes.includes(this.$route.name) && this.template?.name ? 'Template:' : ''
    },
    breadcrumbName() {
      const routes = ['templates-templateid-add-question', 'templates']
      return routes.includes(this.$route.name) && this.template?.name ? this.template.name : 'Template List'
    },
    pageBreadcrumbs() {
      return this.pageMeta?.breadcrumbs || []
    },
    pageName() {
      return this.pageMeta?.name || 'Templates'
    },
    pageMeta() {
      const { questionid, templateid } = this.$route.params
      const pages = [
        {
          name: 'Templates',
          routeName: 'templates',
          breadcrumbs: []
        },
        {
          name: 'Add Template',
          routeName: 'templates-add',
          breadcrumbs: [
            {
              text: 'Templates',
              href: '/templates'
            },
            {
              text: 'Add Template',
              href: '/templates/add',
              active: true
            }
          ]
        },
        {
          name: 'Edit Template',
          routeName: 'templates-templateid-edit',
          breadcrumbs: [
            {
              text: 'Templates',
              href: '/templates'
            },
            {
              text: `${this.template?.name || ''}`,
              href: `/templates/${templateid}`
            },
            {
              text: 'Edit Template',
              href: `/templates/${templateid}/edit`,
              active: true
            }
          ]
        },
        {
          name: 'Template Questions',
          routeName: 'templates-templateid',
          breadcrumbs: [
            {
              text: 'Templates',
              href: '/templates'
            },
            {
              text: 'Template Questions',
              href: `/templates/${templateid}`,
              active: true
            }
          ]
        },
        {
          name: 'Add Template Question',
          routeName: 'templates-templateid-add-question',
          breadcrumbs: [
            {
              text: 'Templates',
              href: '/templates/'
            },
            {
              text: `${this.template?.name || ''}`,
              href: `/templates/${templateid}`
            },
            {
              text: 'Add Template Question',
              href: `/templates/${templateid}/add-question`,
              active: true
            }
          ]
        },
        {
          name: 'Edit Template Question',
          routeName: 'templates-templateid-question-questionid-edit-question',
          breadcrumbs: [
            {
              text: 'Templates',
              href: '/templates/'
            },
            {
              text: `${this.template?.name || ''}`,
              href: `/templates/${templateid}`
            },
            {
              text: 'Edit Template Question',
              href: `/templates/${templateid}/question/${questionid}edit-question`,
              active: true
            }
          ]
        }
      ]
      const meta = pages.filter(p => p.routeName === this.$route.name)
      return meta[0]
    },
    showError() {
      return this.error && this.error?.info?.status !== 401
    }
  },
  watch: {
    $route: {
      deep: true,
      async handler(newVal) {
        if (newVal.params.templateid) {
          this.setPageTheme()
          await this.fetchTemplateById(newVal.params.templateid)
        }
      }
    }
  },
  async mounted () {
    this.setPageTheme()
    this.watchBreakpoints()
    const { templateid } = this.$route.params
    if (isEmpty(this.template) && templateid) {
      await this.fetchTemplateById(templateid)
    }
  },
  methods: {
    ...mapActions({
      fetchTemplateById: 'templates/fetchTemplateById',
      setTheme: 'global/setPageTheme',
      toggleMenu: 'global/toggleMenu',
      watchBreakpoints: 'breakpoints/watch'
    }),
    setPageTheme () {
      const theme = this.$route.path.match(/dashboard|inbox|projects|reports|templates/gi)
      if (theme) {
        this.pageTheme = `theme-${theme[0]}`
        this.setTheme(theme[0])
      } else {
        this.pageTheme = 'theme-dashboard'
        this.setTheme('dashboard')
      }
    }
  }
}
