import { render, staticRenderFns } from "./CategorySort.vue?vue&type=template&id=22aefd59&"
import script from "./CategorySort.vue?vue&type=script&lang=js&"
export * from "./CategorySort.vue?vue&type=script&lang=js&"
import style0 from "./CategorySort.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {CategorySortRowActions: require('/lasso/components/questions/CategorySortRowActions.vue').default,CategorySortSecondary: require('/lasso/components/questions/CategorySortSecondary.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22aefd59')) {
      api.createRecord('22aefd59', component.options)
    } else {
      api.reload('22aefd59', component.options)
    }
    module.hot.accept("./CategorySort.vue?vue&type=template&id=22aefd59&", function () {
      api.rerender('22aefd59', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/questions/CategorySort.vue"
export default component.exports