//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AuthModal',
  auth: false,
  props: {
    formToLoad: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeForm: ''
    }
  },
  computed: {
    ...mapGetters({
      referredNonAuthUser: 'messages/getReferredNonAuthUser'
    }),
    modalTitle() {
      let title = ''
      if (this.activeForm === 'login') {
        title = 'Log In'
      } else if (this.activeForm === 'register') {
        title = 'User Registration'
      } else if (this.activeForm === 'reset') {
        title = 'Reset Password'
      }
      // return this.activeForm.charAt(0).toUpperCase() + this.activeForm.slice(1)
      return title
    }
  },
  watch: {
    formToLoad(newVal) {
      this.activeForm = newVal
    }
  },
  mounted() {
    // when modal close is triggered, reset modal back to login
    // this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
    //   this.activeForm = 'login'
    // })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (this.formToLoad) {
        this.activeForm = this.formToLoad
      }
    })
  },
  // updated() {
  //   if (this.formToLoad === 'register') {
  //     this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
  //       this.activeForm = 'register'
  //     })
  //   }
  // },
  methods: {
    ...mapActions({
      ssoLogin: 'user/login'
    }),
    handleFormChange(e) {
      if (e === 'login') {
        this.activeForm = 'login'
      } else if (e === 'register') {
        this.activeForm = 'register'
      } else if (e === 'reset') {
        this.activeForm = 'reset'
      }
    },
    handleSSOLogin(e) {
      if (e === 'lois') {
        const email = this.referredNonAuthUser
        const redirectUrl = `${window.location.protocol}//${window.location.host}${this.$config.ssoLocalCallbackUri}`
        const url = `${this.$config.ssoAuthenticationUrl}?email=${email}&redirectUrl=${redirectUrl}`
        document.location.href = url
      } else {
        this.ssoLogin({ orgId: e })
      }
    }
  }
}
