//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      email: '',
      password: ''
    }
  }
}
