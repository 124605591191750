var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "form-register" },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            [
              _vm.success
                ? _c(
                    "b-card",
                    [
                      _c("b-alert", { attrs: { show: "" } }, [
                        _vm.referredNonAuthUser === ""
                          ? _c("h3", [
                              _vm._v(
                                "\n            Thanks for registering! Please check your email to confirm your account.\n            "
                              ),
                              _vm.showResend
                                ? _c("div", { staticClass: "resend-panel" }, [
                                    _vm._v("\n              Click "),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.resendConfirmation(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("here")]
                                    ),
                                    _vm._v(
                                      " to resend confirmation email.\n              "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.canResend,
                                            expression: "!canResend"
                                          }
                                        ],
                                        staticClass: "resend-panel-countdown"
                                      },
                                      [
                                        _vm._v(
                                          "\n                Message can be resent in " +
                                            _vm._s(_vm.sendCountdown) +
                                            " seconds.\n              "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showNoMoreResends
                                ? _c("div", { staticClass: "resend-panel" }, [
                                    _vm._v(
                                      "\n              Max confirmation email sends have been reached,"
                                    ),
                                    _c("br"),
                                    _vm._v("\n              please contact "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          target: "_new",
                                          href: "mailto:support@lassoedapp.com"
                                        }
                                      },
                                      [_vm._v("support@lassoedapp.com")]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _c("h3", [
                              _vm._v(
                                "\n            Thanks for registering! You can now read and reply to all your messages in your Inbox."
                              ),
                              _c("br"),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.linkToInbox()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Go To Inbox\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                      ])
                    ],
                    1
                  )
                : _c(
                    "b-form",
                    {
                      staticClass: "form-wrapper rounded-lg mb-3",
                      attrs: { novalidate: "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.onSubmit($event)
                        }
                      }
                    },
                    [
                      _c("b-alert", {
                        attrs: {
                          show: !!_vm.errorMsg,
                          variant: "danger",
                          fade: "",
                          dismissable: ""
                        },
                        domProps: { innerHTML: _vm._s(_vm.errorMsg) }
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-company",
                            "label-for": "input-company"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-company",
                              state: _vm.validateState("company"),
                              type: "text",
                              required: "",
                              placeholder: "Company/Organization"
                            },
                            model: {
                              value: _vm.$v.registerForm.company.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.registerForm.company,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.registerForm.company.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-company-live-feedback" } },
                            [
                              _vm._v(
                                "\n            This is a required field and must be at least 3 characters.\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-firstname",
                            "label-for": "input-firstname"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-firstname",
                              state: _vm.validateState("firstName"),
                              type: "text",
                              required: "",
                              placeholder: "First Name"
                            },
                            model: {
                              value: _vm.$v.registerForm.firstName.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.registerForm.firstName,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.registerForm.firstName.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-firstname-live-feedback" } },
                            [
                              _vm._v(
                                "\n            This is a required field and must be at least 3 characters.\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-lastname",
                            "label-for": "input-lastname"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-lastname",
                              state: _vm.validateState("lastName"),
                              type: "text",
                              required: "",
                              placeholder: "Last Name"
                            },
                            model: {
                              value: _vm.$v.registerForm.lastName.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.registerForm.lastName,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.registerForm.lastName.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-lastname-live-feedback" } },
                            [
                              _vm._v(
                                "\n            This is a required field and must be at least 3 characters.\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-email",
                            "label-for": "input-email"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-email",
                              state: _vm.validateState("email"),
                              autocomplete: "off",
                              type: "email",
                              pattern:
                                "^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$",
                              required: "",
                              placeholder: "Email Address",
                              title:
                                "Email format should look similar to john.doe@google.com",
                              readonly: _vm.referredNonAuthUser !== ""
                            },
                            model: {
                              value: _vm.$v.registerForm.email.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.registerForm.email,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.registerForm.email.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-email-live-feedback" } },
                            [
                              _vm._v(
                                "\n            Email is required and should be formatted like john.doe@google.com.\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-password",
                            "label-for": "input-password"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-password",
                              state: _vm.validateState("password"),
                              autocomplete: "off",
                              type: "password",
                              required: "",
                              placeholder: "Password",
                              title:
                                "Password must contain at least one letter, at least one number, and be at least 8 charaters."
                            },
                            model: {
                              value: _vm.$v.registerForm.password.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.registerForm.password,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.registerForm.password.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-password-live-feedback" } },
                            [
                              _vm._v(
                                "\n            Password must contain at least one letter, at least one number, and be at least 8 characters.\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "action-primary",
                          attrs: {
                            type: "submit",
                            disabled: _vm.disableSubmit,
                            variant: "marketing",
                            block: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.submitButtonText) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "action-primary",
                          attrs: {
                            type: "button",
                            variant: "marketing",
                            block: ""
                          },
                          on: { click: _vm.handleLoisLogin }
                        },
                        [_vm._v("\n          Log In with LOIS\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "action-secondary",
                          attrs: {
                            type: "button",
                            variant: "templates",
                            block: ""
                          },
                          on: { click: _vm.onCancel }
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "link-login" },
                        [
                          _vm._v(
                            "\n          Already have an account?\n          "
                          ),
                          _c(
                            "b-link",
                            {
                              attrs: { href: "#" },
                              on: { click: _vm.onCancel }
                            },
                            [_vm._v("\n            Log In\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }