var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "icon-linkedin",
        "data-name": "icon-linkedin",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 40 40"
      }
    },
    [
      _c("path", {
        staticStyle: { opacity: "0.5" },
        attrs: {
          d:
            "M19.94,38.65A18.18,18.18,0,1,1,38.11,20.47,18.2,18.2,0,0,1,19.94,38.65Zm0-33.86A15.69,15.69,0,1,0,35.63,20.47,15.7,15.7,0,0,0,19.94,4.79Z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: { "fill-rule": "evenodd", opacity: "0.5" },
        attrs: {
          d:
            "M13.67,10.15a2.08,2.08,0,0,1,2.17,2.17,2.09,2.09,0,0,1-2.19,2.18h0a2.09,2.09,0,0,1-2.15-2.18A2.09,2.09,0,0,1,13.67,10.15ZM25.38,16c2.56,0,4.57,1.81,4.57,5.7v7H25.87v-6.5c0-1.7-.64-2.86-2.05-2.86a2.1,2.1,0,0,0-1.95,1.54,3.23,3.23,0,0,0-.09,1v6.8h-4s.05-11.51,0-12.56h4v2A3.67,3.67,0,0,1,25.38,16Zm-13.44.12h3.6V28.63h-3.6Z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: { fill: "#fff" },
        attrs: {
          d:
            "M19.94,37.65A18.18,18.18,0,1,1,38.11,19.47,18.2,18.2,0,0,1,19.94,37.65Zm0-33.86A15.69,15.69,0,1,0,35.63,19.47,15.7,15.7,0,0,0,19.94,3.79Z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: { fill: "#fff", "fill-rule": "evenodd" },
        attrs: {
          d:
            "M13.67,9.15a2.08,2.08,0,0,1,2.17,2.17,2.09,2.09,0,0,1-2.19,2.18h0a2.09,2.09,0,0,1-2.15-2.18A2.09,2.09,0,0,1,13.67,9.15ZM25.38,15c2.56,0,4.57,1.81,4.57,5.7v7H25.87v-6.5c0-1.7-.64-2.86-2.05-2.86a2.1,2.1,0,0,0-1.95,1.54,3.23,3.23,0,0,0-.09,1v6.8h-4s.05-11.51,0-12.56h4v2A3.67,3.67,0,0,1,25.38,15Zm-13.44.12h3.6V27.63h-3.6Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }