//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'QuestionAnswerItemParticipant',
  props: {
    projectInReview: {
      type: Boolean,
      default: false
    },
    question: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      getAnswerByQuestion: 'answers/getAnswerByQuestion',
      getIsQuestionAnswered: 'answers/getIsQuestionAnswered',
      questionsSelected: 'questions/getQuestionsSelected',
      questionsSelectActive: 'questions/getQuestionsSelectActive'
    }),
    questionAnswer() {
      return this.getAnswerByQuestion(this.question._id)
    },
    questionIsAnswered() {
      return this.getIsQuestionAnswered(this.question._id)
    },
    requirementIsMet() {
      const requirement = this.question.requirement
      const reqMet = this.questionAnswer.requirementMet
      const reqIsMet = (requirement && reqMet) || (requirement && !reqMet && this.questionAnswer.override)
      return reqIsMet
    },
    questionSelected: {
      get() {
        return Boolean(this.questionsSelected.find(q => q._id === this.question._id))
      },
      set(value) {
        if (value) {
          this.setQuestionsSelectedAdd({ _id: this.question._id, text: this.question.text })
        } else {
          this.setQuestionsSelectedDelete({ _id: this.question._id, text: this.question.text })
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setQuestionsSelectedAdd: 'questions/setQuestionsSelectedAdd',
      setQuestionsSelectedDelete: 'questions/setQuestionsSelectedDelete'
    })
  }
}
