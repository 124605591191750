var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "icon-delete",
        "data-name": "icon-delete",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d:
            "M19.3,5.55V3.73a.87.87,0,0,0-.86-.86H5.57a.87.87,0,0,0-.86.86v16.7a.86.86,0,0,0,.86.86H18.44a.86.86,0,0,0,.86-.86v-2"
        }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "21.47", y1: "7.49", x2: "12.54", y2: "16.42" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "12.57", y1: "7.49", x2: "21.5", y2: "16.42" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }