var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "breadcrumb-wrapper" },
    [
      _vm.title
        ? _c("b-col", { staticClass: "page-title", attrs: { md: "6" } }, [
            _c("h2", [_vm._v(_vm._s(_vm.label) + " " + _vm._s(_vm.title))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-col",
        { staticClass: "crumbs" },
        _vm._l(_vm.items, function(crumb) {
          return _c(
            "div",
            { key: crumb.text, staticClass: "crumb" },
            [
              !crumb.active
                ? _c("nuxt-link", { attrs: { to: crumb.href } }, [
                    _vm._v("\n        " + _vm._s(crumb.text) + "\n      ")
                  ])
                : _c("span", [_vm._v(_vm._s(crumb.text))])
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }