var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "question-list",
      class: {
        owner: _vm.isProjectOwner,
        participant: _vm.isParticipant,
        "display-answer": _vm.displayType === "answer",
        "display-detail": _vm.displayType === "detail",
        readonly: _vm.mode === "readonly"
      }
    },
    [
      _vm.questionList.length
        ? _c(
            "b-row",
            { staticClass: "question-header" },
            [
              _c("b-col", [
                _c("h5", { staticClass: "d-flex align-items-center" }, [
                  _c("span", [_vm._v("Question")]),
                  _vm._v(" \n        "),
                  _vm.displayType === "detail" && _vm.mode === "normal"
                    ? _c("span", [
                        _vm._v("\n          (drag to re-order)\n        ")
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass: "col-info-actions",
                  attrs: {
                    cols: _vm.mode === "normal" && _vm.isParticipant ? 2 : 3
                  }
                },
                [
                  _vm.isProjectOwner || _vm.mode === "readonly"
                    ? _c("div", { staticClass: "col-phase" }, [
                        _c("h5", [_vm._v("\n          Phase\n        ")])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-info" }, [
                    _c("h5", [
                      _vm._v(
                        "\n          " + _vm._s(_vm.headerLabel) + "\n        "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loadingQuestions ? _c("LoadingOverlay") : _vm._e(),
      _vm._v(" "),
      _vm.displayType === "answer"
        ? _c("QuestionAnswerView")
        : _c("QuestionDetailView", { attrs: { mode: _vm.mode } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }