export const state = () => ({
  attachmentId: ''
})

export const getters = {
}

export const actions = {

  async addAttachment({ dispatch }, { file }) {
    try {
      const data = new FormData()
      data.append('attachment', file)
      const attachment = await this.$axios.$post('/attachment', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if (!attachment) {
        const e = new Error('No attachment data')
        e.response = 'No attachment data'
        throw e
      }
      return attachment
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'attachments/addAttachment', info: err },
        { root: true })
    }
  },

  async markAttachmentAsSeen({ dispatch }, { attachmentId }) {
    try {
      const { result } = await this.$axios.$put(`/attachment/update/unseen/${attachmentId}`)
      if (result === 'ok') {
        return true
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'attachments/markAttachmentAsSeen', info: err },
        { root: true })
    }
  },

  async deleteAttachment({ dispatch }, fileid) {
    try {
      const deleteRes = await this.$axios.$delete(`/attachment/${fileid}`)
      return deleteRes
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'attachments/deleteAttachment', info: err },
        { root: true })
    }
  }
}

export const mutations = {
}
