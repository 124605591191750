import { propertyStatus } from '../server/constants'

// search properties are properties returned from LOIS
// and the participant properties are ones that have been
// added to the participant's project
export const state = () => ({
  // actively selected participant property
  activeParticipantProperty: {},
  activeParticipantPropertyId: '',

  ownerProperties: [],
  ownerPropertyCount: 0,
  ownerPropertyOptions: {
    search: '',
    page: 0,
    limit: 50
  },
  ownerPropertySearchCriteria: {
    organizations: {},
    participants: {},
    statuses: {},
    states: {}
  },

  delegatorProperties: [],
  delegatorPropertyCount: 0,
  delegatorPropertyOptions: {
    search: '',
    page: 0,
    limit: 50
  },
  delegatorPropertySearchCriteria: {
    organizations: {},
    participants: {},
    statuses: {},
    states: {}
  },

  participantProperties: [],
  participantPropertyCount: 0,
  participantPropertyOptions: {
    search: '',
    page: 0,
    limit: 50
  },
  participantPropertySearchCriteria: {
    organizations: {},
    participants: {},
    statuses: {},
    states: {}
  },

  // actively selected participant search property
  activeParticipantSearchProperty: {},
  activeParticipantSearchPropertyId: '',
  participantSearchProperties: [],
  participantSearchPropertyCount: 0,
  participantSearchPropertyOptions: {
    search: '',
    limit: 50
  },
  participantSearchPropertyMessage: ''
})

export const getters = {
  // Owner properties mapped to current project
  getOwnerProperties: state => state.ownerProperties,
  getOwnerPropertyCount: state => state.ownerPropertyCount,
  getOwnerPropertyOrganizations: state => state.ownerPropertySearchCriteria.organizations,
  getOwnerPropertyParticipants: state => state.ownerPropertySearchCriteria.participants,
  getOwnerPropertyStatuses: state => state.ownerPropertySearchCriteria.statuses,
  getOwnerPropertyStates: state => state.ownerPropertySearchCriteria.states,

  // Delegator properties mapped to current project
  getDelegatorProperties: state => state.delegatorProperties,
  getDelegatorPropertyCount: state => state.delegatorPropertyCount,
  getDelegatorPropertyOrganizations: state => state.delegatorPropertySearchCriteria.organizations,
  getDelegatorPropertyParticipants: state => state.delegatorPropertySearchCriteria.participants,
  getDelegatorPropertyStatuses: state => state.delegatorPropertySearchCriteria.statuses,
  getDelegatorPropertyStates: state => state.delegatorPropertySearchCriteria.states,

  // Participant properties mapped to current project
  getParticipantProperties: state => state.participantProperties,
  getParticipantPropertyCount: state => state.participantPropertyCount,
  getParticipantPropertyOrganizations: state => state.participantPropertySearchCriteria.organizations,
  getParticipantPropertyParticipants: state => state.participantPropertySearchCriteria.participants,
  getParticipantPropertyStatuses: state => state.participantPropertySearchCriteria.statuses,
  getParticipantPropertyStates: state => state.participantPropertySearchCriteria.states,

  participantPropertiesAllFinished: (state) => {
    let finished = false
    if (state.participantProperties?.length) {
      const completeStatuses = [propertyStatus.finished, propertyStatus.eliminated]
      finished = state.participantProperties?.filter(p => completeStatuses.includes(p.status))
      return state.participantProperties.length === finished.length
    }
    return finished
  },

  getParticipantSearchProperties: state => state.participantSearchProperties,
  getParticipantSearchPropertyCount: state => state.participantSearchPropertyCount,
  getParticipantSearchPropertyOptions: state => state.participantSearchPropertyOptions,
  getParticipantSearchPropertyMessage: state => state.participantSearchPropertyMessage,

  getActiveParticipantProperty: state => state.activeParticipantProperty,

  getPropertyIsEliminated: state => state.activeParticipantProperty.status === propertyStatus.eliminated,
  getPropertyIsNew: state => state.activeParticipantProperty.status === propertyStatus.new,
  getPropertyIsSubmitted: state => state.activeParticipantProperty.status === propertyStatus.finished,
  getPropertyIsWorking: state => state.activeParticipantProperty.status === propertyStatus.working
}

export const actions = {
  async addPropertyToProject({ dispatch },
    { projectId, participantId, propertyDetail }
  ) {
    try {
      const { property = null } = await this.$axios.$post(`/property/${projectId}`,
        { participantId, propertyDetail }
      )
      return property
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/addPropertyToProject', info: err },
        { root: true })
    }
  },

  async deleteProperty({ dispatch },
    { projectId, propertyId }
  ) {
    try {
      const res = await this.$axios.$delete(`/property/${projectId}/${propertyId}`)
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/deleteProperty', info: err },
        { root: true })
    }
  },

  /**
   * Retrieves the list of properties for a project owner from all participants
   * @param {*} projectId, the project identifier
   * @param {*} full, indicates whether to pull back LOIS property data as well
   * @param {*} filterBy, name = 0, states = 1, organizations = 2, participants = 3, statuses = 4
   * @param {*} filterByValues, an array of values to filter by
   * @returns true if successful, false otherwise
   */
  async fetchOwnerProperties(
    { state, commit, dispatch },
    { projectId, full = false, filterBy, filterByValues, sortBy, ascdesc = true }) {
    const { limit, page, search } = state.ownerPropertyOptions
    const skip = page * limit
    const postData = {
      limit,
      skip,
      search,
      full: full ? 1 : 0,
      filterBy,
      filterByValues,
      sortBy,
      ascdesc: ascdesc ? 'desc' : 'asc'
    }
    try {
      const { properties, count, criteria } =
        await this.$axios.$post(
          `/projectowner/properties/${projectId}`,
          postData
        )
      if (!properties) {
        throw new Error('Failed to fetch owner properties')
      }
      commit('setOwnerProperties', properties)
      commit('setOwnerPropertyCount', count)
      commit('setOwnerPropertySearchCriteria', criteria)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/fetchOwnerProperties', info: err },
        { root: true })
    }
  },

  // fetches ALL properties submitted by participants for a delegator
  async fetchDelegatorParticipantProperties(
    { state, commit, dispatch },
    { projectId, full = false, filterBy, filterByValues, sortBy, ascdesc = true }) {
    const { limit, page, search } = state.ownerPropertyOptions
    const skip = page * limit
    const postData = {
      limit,
      skip,
      search,
      full: full ? 1 : 0,
      filterBy,
      filterByValues,
      sortBy,
      ascdesc: ascdesc ? 'desc' : 'asc'
    }
    try {
      const { properties, count, criteria } =
        await this.$axios.$post(
          `/delegator/properties/${projectId}`,
          postData
        )
      commit('setDelegatorProperties', properties)
      commit('setDelegatorPropertyCount', count)
      commit('setDelegatorPropertySearchCriteria', criteria)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/fetchDelegatorProperties', info: err },
        { root: true })
    }
  },

  // fetches the list of properties for a participant, either for the participant
  // OR for an owner OR for a delegator
  async fetchParticipantProperties(
    { state, commit, dispatch },
    { projectId, participantId, full = false, filterBy, filterByValues, sortBy, ascdesc = true }) {
    if (!participantId || !projectId) {
      return false
    }
    const { limit, page, search } = state.participantPropertyOptions
    const skip = page * limit
    const postData = {
      limit,
      skip,
      search,
      full: full ? 1 : 0,
      filterBy,
      filterByValues,
      sortBy,
      ascdesc: ascdesc ? 'desc' : 'asc'
    }
    try {
      const { properties, count, criteria } =
        await this.$axios.$post(
          `/properties/${projectId}/${participantId}`,
          postData
        )
      commit('setParticipantProperties', properties)
      commit('participants/setProjectParticipantPropertyCount', count, { root: true })
      commit('setParticipantPropertySearchCriteria', criteria)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/fetchParticipantProperties', info: err },
        { root: true })
    }
  },

  async fetchOwnerPropertiesForReport({ commit, dispatch }, { projectId }) {
    try {
      const { properties, count } = await this.$axios.$get(`/po/properties/${projectId}`)
      commit('setOwnerProperties', properties)
      commit('setOwnerPropertyCount', count)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/fetchOwnerPropertiesForReport', info: err },
        { root: true })
    }
  },

  async fetchDelegatorPropertiesForReport({ commit, dispatch }, { projectId, k }) {
    try {
      const { properties, count } = await this.$axios.$get(`/del/properties/${projectId}?k=${k}`)
      commit('setDelegatorProperties', properties)
      commit('setDelegatorPropertyCount', count)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/fetchDelegatorPropertiesForReport', info: err },
        { root: true })
    }
  },

  async fetchParticipantPropertiesForReport({ commit, dispatch }, { projectId, k }) {
    try {
      const { properties, count } = await this.$axios.$get(`/par/properties/${projectId}?k=${k}`)
      commit('setParticipantProperties', properties)
      commit('setParticipantPropertyCount', count)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/fetchParticipantPropertiesForReport', info: err },
        { root: true })
    }
  },

  async fetchParticipantProjectProperty({ commit, dispatch }, { projectId, propertyId }) {
    try {
      const property = await this.$axios.$get(`/property/${projectId}/${propertyId}`)
      commit('setActiveParticipantProperty', property)
      return property
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/fetchParticipantProjectProperty', info: err },
        { root: true })
    }
  },

  async fetchParticipantPropertyById({ commit, dispatch }, { projectId, propertyId, ptype }) {
    try {
      const property = await this.$axios.$get(`/property/${projectId}/${ptype}/${propertyId}`)
      commit('setActiveParticipantProperty', property)
      return property
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/fetchParticipantPropertyById', info: err },
        { root: true })
    }
  },

  // This is not currently used, but may be in the future, so leaving it for now.
  async isPropertyEditable({ dispatch }, { propertyId }) {
    try {
      const results = await this.$axios.$get(`/lois/property/editable/${propertyId}`)
      return results
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/isPropertyEditable', info: err },
        { root: true }
      )
    }
  },

  async findParticipantProperty({ dispatch },
    { projectId, participantId, _id = null }
  ) {
    try {
      if (!_id) {
        throw new Error('_id was not specified')
      }
      const { property } = await this.$axios.$get(`/lois/properties/${projectId}/${participantId}/${_id}`)
      return property
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/findParticipantProperty', info: err },
        { root: true })
    }
  },

  async searchParticipantProperties({ dispatch, commit, state },
    { projectId, participantId, _id = null }
  ) {
    const {
      limit,
      search
    } = state.participantSearchPropertyOptions
    const params = [
      limit ? `limit=${limit}` : '',
      search ? `search=${search}` : ''
    ]
    try {
      const results = !_id
        ? await this.$axios
          .$get(`/lois/properties/${projectId}/${participantId}?` + params.filter(p => p).join('&'))
        : await this.$axios
          .$get(`/lois/properties/${projectId}/${participantId}/${_id}?` + params.filter(p => p).join('&'))
      if (_id) {
        return results.properties
      }
      if (results.loisOrganizationId && results.message) {
        commit('setParticipantSearchPropertyMessage', results.message)
        return false
      } else if (!results.count && results.message) {
        commit('setParticipantSearchPropertyMessage', results.message)
        return false
      }
      commit('setParticipantSearchProperties', results.properties)
      commit('setParticipantSearchPropertyCount', results.count)
      commit('setParticipantSearchPropertyMessage', '')
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/searchParticipantProperties', info: err },
        { root: true })
    }
  },

  setActivePropertyAnswerCountResult({ commit }, { counts }) {
    commit('setActivePropertyAnswerCounts', counts)
  },

  async setActivePropertyAnswerCounts({ commit, dispatch }, { propertyId }) {
    try {
      const counts = await this.$axios.$get(`/property/${propertyId}/answer/counts`)
      commit('setActivePropertyAnswerCounts', counts)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/setActivePropertyAnswerCounts', info: err },
        { root: true }
      )
    }
  },

  async syncPropertyAnswersWithLois({ dispatch }, { projectId, propertyId, includeRequirements = false }) {
    try {
      const res = await this.$axios.$put(`/property/answersync/${projectId}/${propertyId}/${includeRequirements}`, {})
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/syncPropertyAnswersWithLois', info: err },
        { root: true }
      )
    }
  },

  async syncPropertyRequirements({ commit, dispatch }, { projectId, propertyId }) {
    try {
      const res = await this.$axios.$put(`/property/reqsync/${projectId}/${propertyId}`, {})
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/syncPropertyRequirements', info: err },
        { root: true }
      )
    }
  },

  removeParticipantSearchPropertyAfterAdd({ commit }, id) {
    commit('removeParticipantSearchPropertyAfterAdd', id)
  },

  resetAllPropertyLists({ commit }) {
    commit('setOwnerProperties', [])
    commit('setOwnerPropertyCount', 0)
    commit('setOwnerPropertySearchCriteria', {})
    commit('setDelegatorPropertySearchCriteria', {})
    commit('setParticipantPropertySearchCriteria', {})
    commit('setDelegatorProperties', [])
    commit('setDelegatorPropertyCount', 0)
    commit('setParticipantProperties', [])
    commit('participants/setProjectParticipantPropertyCount', 0, { root: true })
  },

  resetParticipantProperties({ commit }) {
    commit('setParticipantSearchProperties', [])
    commit('setParticipantSearchPropertyCount', 0)
  },

  setActiveParticipantSearchProperty({ commit }, property) {
    commit('setActiveParticipantSearchProperty', property)
    commit('setActiveParticipantSearchPropertyId', property._id)
  },

  setActiveParticipantProperty({ commit }, property) {
    commit('setActiveParticipantProperty', property)
    commit('setActiveParticipantPropertyId', property._id)
  },

  setActiveParticipantPropertyId({ commit }, propertyId) {
    commit('setActiveParticipantPropertyId', propertyId)
  },

  setActiveParticipantPropertyAnswers({ commit }, answerid) {
    commit('setActiveParticipantPropertyAnswers', answerid)
  },

  setActiveParticipantPropertyAnswersDelete({ commit }, answerid) {
    commit('setActiveParticipantPropertyAnswersDelete', answerid)
  },

  // set search term for property select search
  setParticipantSearchPropertySearch({ commit }, search) {
    commit('setParticipantSearchPropertySearch', search)
  },

  // set page for property select search
  setParticipantSearchPropertyPage({ commit }, page) {
    commit('setParticipantSearchPropertyPage', page)
  },

  // set limit for property select search
  setParticipantSearchPropertyLimit({ commit }, limit) {
    commit('setParticipantSearchPropertyLimit', limit)
  },

  async updateProperty({ dispatch },
    { projectId, propertyId, propertyDetail }
  ) {
    try {
      const property = await this.$axios.$put(`/property/${projectId}/${propertyId}`,
        { propertyDetail }
      )
      return property
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/updateProperty', info: err },
        { root: true })
    }
  },

  async updatePropertyShowInLois({ dispatch }, { projectId, propertyId, showInLois }) {
    try {
      const { result } = await this.$axios.$put(
        `/property/showinlois/${projectId}/${propertyId}`,
        { showInLois }
      )
      return result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/updatePropertyShowInLois', info: err },
        { root: true })
    }
  },

  async updatePropertySubmitYourSite({ dispatch }, { projectId, propertyId, submitYourSite }) {
    try {
      const { result } = await this.$axios.$put(
        `/property/submityoursite/${projectId}/${propertyId}`,
        { submitYourSite }
      )
      return result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'properties/updatePropertySubmitYourSite', info: err },
        { root: true })
    }
  }
}

export const mutations = {
  init(state) {
    state.activeParticipantProperty = {}
    state.activeParticipantPropertyId = ''

    // Owner properties start
    state.ownerProperties = []
    state.ownerPropertyCount = 0
    state.ownerPropertyOptions = {
      search: '',
      skip: 0,
      limit: 50
    }
    state.ownerPropertySearchCriteria = {
      organizations: {},
      participants: {},
      statuses: {},
      states: {}
    }
    // Owner properties end

    // Delegator properties start
    state.delegatorProperties = []
    state.delegatorPropertyCount = 0
    state.delegatorPropertyOptions = {
      search: '',
      skip: 0,
      limit: 50
    }
    state.delegatorPropertySearchCriteria = {
      organizations: {},
      participants: {},
      statuses: {},
      states: {}
    }
    // Delegator properties end

    // Participant properties start
    state.participantProperties = []
    state.participantPropertyOptions = {
      search: '',
      skip: 0,
      limit: 50
    }
    state.participantPropertySearchCriteria = {
      organizations: {},
      participants: {},
      statuses: {},
      states: {}
    }
    // Participant properties end

    state.activeParticipantSearchProperty = {}
    state.activeParticipantSearchPropertyId = ''
    state.participantSearchProperties = []
    state.participantSearchPropertyCount = 0
    state.participantSearchPropertyOptions = {
      search: '',
      skip: 0,
      limit: 50
    }
  },
  removeParticipantSearchPropertyAfterAdd(state, id) {
    const addedIdx = state.participantSearchProperties.findIndex(p => p._id === id)
    state.participantSearchProperties.splice(addedIdx, 1)
    state.participantSearchPropertyCount--
  },

  setOwnerProperties(state, properties) {
    state.ownerProperties = properties
  },
  setOwnerPropertyCount(state, count) {
    state.ownerPropertyCount = count
  },
  setOwnerPropertySearch(state, search) {
    state.ownerPropertyOptions.search = search
  },
  setOwnerPropertyPage(state, page) {
    state.ownerPropertyOptions.page = page
  },
  setOwnerPropertyLimit(state, limit) {
    state.ownerPropertyOptions.limit = limit
  },
  setOwnerPropertySearchCriteria(state, criteria) {
    state.ownerPropertySearchCriteria.organizations = criteria.organizations || {}
    state.ownerPropertySearchCriteria.participants = criteria.participants || {}
    state.ownerPropertySearchCriteria.statuses = criteria.statuses || {}
    state.ownerPropertySearchCriteria.states = criteria.states || {}
  },

  setDelegatorProperties(state, properties) {
    state.delegatorProperties = properties
  },
  setDelegatorPropertyCount(state, count) {
    state.delegatorPropertyCount = count
  },
  setDelegatorPropertySearch(state, search) {
    state.delegatorPropertyOptions.search = search
  },
  setDelegatorPropertyPage(state, page) {
    state.delegatorPropertyOptions.page = page
  },
  setDelegatorPropertyLimit(state, limit) {
    state.delegatorPropertyOptions.limit = limit
  },
  setDelegatorPropertySearchCriteria(state, criteria) {
    state.delegatorPropertySearchCriteria.organizations = criteria.organizations || {}
    state.delegatorPropertySearchCriteria.participants = criteria.participants || {}
    state.delegatorPropertySearchCriteria.statuses = criteria.statuses || {}
    state.delegatorPropertySearchCriteria.states = criteria.states || {}
  },

  setParticipantProperties(state, properties) {
    state.participantProperties = properties
  },
  setParticipantPropertyCount(state, count) {
    state.participantPropertyCount = count
  },
  setParticipantPropertySearch(state, search) {
    state.participantPropertyOptions.search = search
  },
  setParticipantPropertyPage(state, page) {
    state.participantPropertyOptions.page = page
  },
  setParticipantPropertyLimit(state, limit) {
    state.participantPropertyOptions.limit = limit
  },
  setParticipantPropertySearchCriteria(state, criteria) {
    state.participantPropertySearchCriteria.organizations = criteria.organizations || {}
    state.participantPropertySearchCriteria.participants = criteria.participants || {}
    state.participantPropertySearchCriteria.statuses = criteria.statuses || {}
    state.participantPropertySearchCriteria.states = criteria.states || {}
  },

  setActiveParticipantProperty(state, property) {
    state.activeParticipantProperty = property
  },
  setActiveParticipantPropertyId(state, propertyId) {
    state.activeParticipantPropertyId = propertyId
  },
  setActiveParticipantPropertyAnswers(state, answerid) {
    state.activeParticipantProperty.answers.push(answerid)
  },
  setActiveParticipantPropertyAnswersDelete(state, answerid) {
    const deletedIdx = state.activeParticipantProperty.answers.findIndex(a => String(a._id) === String(answerid))
    state.activeParticipantProperty.answers.splice(deletedIdx, 1)
  },
  setActiveParticipantSearchProperty(state, property) {
    state.activeParticipantSearchProperty = property
  },
  setActiveParticipantSearchPropertyId(state, propertyId) {
    state.activeParticipantSearchPropertyId = propertyId
  },

  setParticipantSearchProperties(state, properties) {
    state.participantSearchProperties = properties
  },
  setParticipantSearchPropertyCount(state, count) {
    state.participantSearchPropertyCount = count
  },
  setParticipantSearchPropertySearch(state, search) {
    state.participantSearchPropertyOptions.search = search
  },
  setParticipantSearchPropertyPage(state, page) {
    state.participantSearchPropertyOptions.page = page
  },
  setParticipantSearchPropertyLimit(state, limit) {
    state.participantSearchPropertyOptions.limit = limit
  },
  setParticipantSearchPropertyMessage(state, message) {
    state.participantSearchPropertyMessage = message
  },

  setActivePropertyAnswerCounts(state, {
    questionsAnswered,
    requiredQuestionsAnswered,
    phaseQuestionsAnswered,
    phaseRequiredQuestionsAnswered
  }) {
    state.activeParticipantProperty.questionsAnswered = questionsAnswered
    state.activeParticipantProperty.requiredQuestionsAnswered = requiredQuestionsAnswered
    state.activeParticipantProperty.phaseQuestionsAnswered = phaseQuestionsAnswered
    state.activeParticipantProperty.phaseRequiredQuestionsAnswered = phaseRequiredQuestionsAnswered
  }
}
