export const userRoles = {
  newUser: 0,
  participant: 1,
  projectowner: 2,
  admin: 4,
  orgAdmin: 64,
  listAdmin: 128,
  projectownerinventory: 256,
  collaborator: 512,
  nobody: 1024
}

export const userRoleNames = [
  'newUser',
  'participant',
  'projectowner',
  '',
  'admin'
]

export const userProjectRoles = {
  delegate: 8,
  delegator: 16,
  organization: 32
}

export const folderEnum = {
  drafts: 0,
  inbox: 1,
  sent: 2,
  archived: 3,
  trash: 4
}

export const eventActorType = {
  unknown: 0,
  participant: 1,
  projectowner: 2,
  impersonatedParticipant: 3,
  imperonatedOwner: 4,
  admin: 5,
  system: 6
}

export const participantStatus = {
  // added to project
  invited: 0,
  // accepted participation in project
  working: 1,
  // delegate says completed but needs delegator approval
  delegatorCompleted: 2,
  // delegate says completed with no delegator OR
  // delegator signed off on completion
  delegateCompleted: 3,
  // project owner has marked participant eliminated from project
  eliminated: 4
}

export const approverStatus = {
  // approver not interested in monitoring subordinates
  // or delegator was eliminated from project
  notapproving: -1,
  // working
  working: 0,
  // approved
  approved: 1
}

export const approverStatusName = [
  'notapproving',
  'working',
  'approved'
]

export const participantStatusName = [
  'invited',
  'working',
  'delegatorCompleted',
  'delegateCompleted',
  'eliminated'
]

export const projectStatus = {
  new: 0,
  active: 1,
  review: 2,
  closed: 3,
  archived: 4,
  paused: 5
}

export const projectStatusName = [
  'new',
  'active',
  'review',
  'closed',
  'archived',
  'paused'
]

export const propertyType = {
  site: 0,
  building: 1
}

export const loisLassoPropertyTypes = {
  site: 0,
  building: 1,
  sites: 0,
  buildings: 1
}

export const propertyTypeName = [
  'site',
  'building'
]

export const propertyStatus = {
  // property just added to participant project
  new: 0,
  // participant has answered a question about property
  working: 1,
  // participant has marked property completed
  finished: 2,
  // project owner has marked property eliminated
  eliminated: 3
}

export const propertyStatusname = [
  'new',
  'working',
  'finished',
  'eliminated'
]

export const questionType = {
  text: 0,
  number: 1,
  choice: 2
}

export const questionTypeName = [
  'text',
  'number',
  'choice'
]

export const questionOwnerType = {
  // project owner's template id
  template: 1,
  // assigned to a project id
  project: 2
}

export const questionOwnerTypeName = [
  'system',
  'template',
  'project'
]

export const requirementOperator = {
  greaterThan: 0,
  greaterThanOrEqual: 1,
  equal: 2,
  notEqual: 3,
  lessThanOrEqual: 4,
  lessThan: 5,
  between: 6,
  oneOf: 7,
  allOf: 8,
  noneOf: 9
}

export const requirementOperatorName = [
  '>',
  '>=',
  '==',
  '!=',
  '<=',
  '<',
  'Between',
  'One Of',
  'All Of',
  'None Of'
]

export const loisPropertyTypes = {
  bln: 0,
  int: 1,
  str: 2,
  flt: 3,
  ary: 4,
  cur: 5,
  dte: 6
}

export const loisPropertyTypeNames = [
  'bln',
  'int',
  'str',
  'flt',
  'ary',
  'cur',
  'dte'
]

export const propertyAnswerStartColumn = 16

export const propertyQuestionInfo = [
  {
    secondaryCategory: 'Name and Address',
    tertiaryCategory: [
      'Name',
      'Address',
      'City',
      'Country',
      'State',
      'Postal Code',
      'Latitude',
      'Longitude'
    ],
    text: [
      'Facility Name',
      'Street Address',
      'City',
      'Country',
      'State',
      'Postal Code',
      'Latitude',
      'Longitude'
    ]
  },
  {
    secondaryCategory: 'Contact Details',
    tertiaryCategory: [
      'Contact Name',
      'Contact Organization',
      'Contact Email',
      'Contact Phone',
      'Contact Address',
      'Contact City',
      'Contact State',
      'Contact Postal Code'
    ],
    text: [
      'Contact Name',
      'Organization',
      'Contact Email',
      'Contact Phone',
      'Contact Address',
      'Contact City',
      'Contact State',
      'Contact Postal Code'
    ]
  }
]

export const questionnaireStatus = {
  draft: 0,
  sent: 1,
  working: 2,
  finished: 3
}

export const questionnaireStatusNames = [
  'Draft',
  'Sent',
  'Working',
  'Finished'
]

export const maxProjectPhases = 10000

export const isAdmin = (role) => {
  return userRoles.admin & role
}

export const isSuperAdmin = (role) => {
  return userRoles.admin & role &&
       !(userRoles.orgAdmin & role) &&
       !(userRoles.listAdmin & role)
}

export const isOrgAdmin = (role) => {
  return userRoles.orgAdmin & role &&
         userRoles.admin & role
}

export const isListAdmin = (role) => {
  return userRoles.listAdmin & role &&
         userRoles.admin & role
}

export const isProjectOwner = (role) => {
  return userRoles.projectowner & role
}

export const isParticipant = (role) => {
  return userRoles.participant & role
}
