var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category" },
    [
      _vm.savingCategory ? _c("LoadingOverlay") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "category-item primary",
          class: { active: _vm.category === _vm.activeCategory },
          on: {
            click: function($event) {
              !_vm.edit && _vm.setSelectedPrimaryCategory(_vm.category)
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.edit && _vm.activeCategory === _vm.category,
                  expression: "edit && activeCategory === category"
                }
              ],
              staticClass: "edit-category"
            },
            [
              _c(
                "b-field",
                [
                  _c("b-input", {
                    ref: _vm.primaryCategoryUniqueId,
                    attrs: {
                      id: _vm.primaryCategoryUniqueId,
                      "data-catedit": "primary",
                      autocomplete: "off",
                      readonly: _vm.reassignPrimaryToPrimaryCategory
                    },
                    model: {
                      value: _vm.categoryToEdit,
                      callback: function($$v) {
                        _vm.categoryToEdit = $$v
                      },
                      expression: "categoryToEdit"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.reassignPrimaryToPrimaryCategory
                ? _c(
                    "b-field",
                    { attrs: { label: "Re-Assign To Primary Category:" } },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: { expanded: "" },
                          model: {
                            value: _vm.newPrimaryCategory,
                            callback: function($$v) {
                              _vm.newPrimaryCategory = $$v
                            },
                            expression: "newPrimaryCategory"
                          }
                        },
                        _vm._l(_vm.availableCategories, function(primary) {
                          return _c(
                            "option",
                            { key: primary, domProps: { value: primary } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(primary) +
                                  "\n          "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  ref: "btn-" + _vm.primaryCategoryUniqueId,
                  staticClass: "icon-button",
                  attrs: { variant: "light", size: "sm", title: "Update" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.saveCategory("primary", _vm.category)
                    }
                  }
                },
                [
                  _c("b-icon", { attrs: { icon: "check", type: "is-success" } })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "icon-button",
                  attrs: { variant: "light", size: "sm", title: "Cancel" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.$emit("cancel-edit")
                      _vm.reassignPrimaryToPrimaryCategory = false
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "close", type: "is-danger" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-dropdown",
                {
                  staticClass: "dropdown-primary",
                  attrs: { text: "More", right: "" }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "trigger" }, slot: "trigger" },
                    [
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "icon-button",
                            attrs: { variant: "light", size: "sm" }
                          },
                          [
                            _c("b-icon", { attrs: { icon: "dots-horizontal" } })
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    { on: { click: _vm.reassignPrimaryToPrimary } },
                    [_vm._v("\n          Move To Primary Category\n        ")]
                  ),
                  _vm._v(" "),
                  _c("b-dropdown-divider"),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item-button",
                    {
                      attrs: {
                        "button-class": "icon-button",
                        variant: "danger"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.deleteCategoryQuestionsConfirm("primary")
                        }
                      }
                    },
                    [
                      _c("IconTrash"),
                      _vm._v(" Delete This Category And Questions\n        ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.edit || _vm.activeCategory !== _vm.category,
                  expression: "!edit || activeCategory !== category"
                }
              ],
              staticClass: "category-name"
            },
            [
              _vm._v("\n      " + _vm._s(_vm.category) + "\n      "),
              _vm.displayCategoryHasRequired &&
              _vm.categoryHasRequired({ primary: _vm.category })
                ? _c(
                    "b-badge",
                    {
                      staticClass: "required-badge",
                      attrs: { variant: "danger", title: "Required" }
                    },
                    [_vm._v("\n        R\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("not-viewed", {
                attrs: {
                  visible: !!_vm.categoryUnseen({ primary: _vm.category })
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.category === _vm.primaryCategory && _vm.secondaryCategories.length
        ? _c(
            "div",
            { staticClass: "secondary-categories" },
            [
              _c(
                "transition-group",
                { attrs: { name: "category" } },
                _vm._l(_vm.secondaryCategories, function(secondary, idx) {
                  return _c(
                    "div",
                    {
                      key: secondary,
                      staticClass: "category-item secondary",
                      class: { active: secondary === _vm.activeCategory },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          !_vm.edit &&
                            _vm.setSelectedSecondaryCategory(secondary, idx)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.edit && _vm.activeCategory === secondary,
                              expression: "edit && activeCategory === secondary"
                            }
                          ],
                          staticClass: "edit-category"
                        },
                        [
                          _c(
                            "b-field",
                            [
                              _c("b-input", {
                                ref: _vm.secondaryCategoryUniqueId(
                                  secondary,
                                  idx
                                ),
                                refInFor: true,
                                attrs: {
                                  id: _vm.secondaryCategoryUniqueId(
                                    secondary,
                                    idx
                                  ),
                                  "data-catedit": "secondary",
                                  autocomplete: "off",
                                  readonly: _vm.reassignPrimaryCategory
                                },
                                model: {
                                  value: _vm.categoryToEdit,
                                  callback: function($$v) {
                                    _vm.categoryToEdit = $$v
                                  },
                                  expression: "categoryToEdit"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.reassignPrimaryCategory
                            ? _c(
                                "b-field",
                                {
                                  attrs: {
                                    label: "Re-Assign To Primary Category:"
                                  }
                                },
                                [
                                  _c(
                                    "b-select",
                                    {
                                      attrs: { expanded: "" },
                                      model: {
                                        value: _vm.newPrimaryCategory,
                                        callback: function($$v) {
                                          _vm.newPrimaryCategory = $$v
                                        },
                                        expression: "newPrimaryCategory"
                                      }
                                    },
                                    _vm._l(_vm.availableCategories, function(
                                      primary
                                    ) {
                                      return _c(
                                        "option",
                                        {
                                          key: primary,
                                          domProps: { value: primary }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(primary) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              ref:
                                "btn-" +
                                _vm.secondaryCategoryUniqueId(secondary, idx),
                              refInFor: true,
                              staticClass: "icon-button",
                              attrs: {
                                id:
                                  "btn-" +
                                  _vm.secondaryCategoryUniqueId(secondary, idx),
                                variant: "light",
                                size: "sm",
                                title: "Update"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.saveCategory(
                                    "secondary",
                                    secondary
                                  )
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "check", type: "is-success" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "icon-button",
                              attrs: {
                                variant: "light",
                                size: "sm",
                                title: "Cancel"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  _vm.$emit("cancel-edit")
                                  _vm.reassignPrimaryCategory = false
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "close", type: "is-danger" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "dropdown-secondary",
                              attrs: { text: "More", right: "" }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "trigger" }, slot: "trigger" },
                                [
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "icon-button",
                                        attrs: { variant: "light", size: "sm" }
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "dots-horizontal" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item-button",
                                {
                                  staticClass: "btn-categories",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.setSecondaryAsPrimaryConfirm(
                                        secondary
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Set As Primary Category\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  staticClass: "btn-categories",
                                  on: { click: _vm.reassignPrimary }
                                },
                                [
                                  _vm._v(
                                    "\n              Move To Different Primary Category\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("b-dropdown-divider"),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item-button",
                                {
                                  attrs: {
                                    "button-class": "icon-button",
                                    variant: "danger"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.deleteCategoryQuestionsConfirm(
                                        "secondary"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("IconTrash"),
                                  _vm._v(
                                    " Delete This Category And Questions\n            "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.edit || _vm.activeCategory !== secondary,
                              expression:
                                "!edit || activeCategory !== secondary"
                            }
                          ],
                          staticClass: "category-name"
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(secondary) + "\n          "
                          ),
                          _vm.displayCategoryHasRequired &&
                          _vm.categoryHasRequired({
                            primary: _vm.category,
                            secondary: secondary
                          })
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "required-badge",
                                  attrs: {
                                    variant: "danger",
                                    title: "Required"
                                  }
                                },
                                [_vm._v("\n            R\n          ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("not-viewed", {
                            attrs: {
                              visible: !!_vm.categoryUnseen({
                                primary: _vm.category,
                                secondary: secondary
                              })
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }