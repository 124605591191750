export const state = () => ({

})

export const getters = {

}

export const actions = {
  async removeImageFromProperty({ commit, dispatch }, { imageId, fileName, propertyId }) {
    try {
      if (!imageId && !fileName) {
        throw new Error('imageId and fileName must be specified.')
      }
      const { result } = await this.$axios.$delete(
        `/images/${propertyId}/${imageId}/${fileName}`
      )
      return result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'images/removeImageFromProperty', info: err },
        { root: true }
      )
    }
  },
  async setImageToPrimary({ dispatch }, { imageId, fileName, propertyId }) {
    try {
      if (!imageId && !fileName) {
        throw new Error('imageId and fileName must be specified.')
      }
      const { result } = await this.$axios.$put(
        `/images/primary/${propertyId}/${imageId}`, { fileName }
      )
      return result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'images/setImageToPrimary', info: err },
        { root: true }
      )
    }
  },
  async updateImageDescription({ dispatch }, { descriptionTx, imageId, fileName, propertyId }) {
    try {
      if (!imageId && !fileName) {
        throw new Error('imageId and fileName must be specified.')
      }
      const { result } = await this.$axios.$put(
        `/images/${propertyId}`, { imageId, descriptionTx, fileName }
      )
      return result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'images/updateImageDescription', info: err },
        { root: true }
      )
    }
  },
  async uploadImage({ dispatch }, { file, propertyId }) {
    try {
      const form = new FormData()
      form.append('image', file)
      const { result } = await this.$axios.$post(
        `/images/${propertyId}`, form,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      return result === 'ok' || result
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'images/uploadImage', info: err },
        { root: true }
      )
    }
  }
}

export const mutations = {

}
