import { render, staticRenderFns } from "./CategoriesQuestions.vue?vue&type=template&id=ab7c27f8&"
import script from "./CategoriesQuestions.vue?vue&type=script&lang=js&"
export * from "./CategoriesQuestions.vue?vue&type=script&lang=js&"
import style0 from "./CategoriesQuestions.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {ImportTemplateQuestionsModal: require('/lasso/components/questions/ImportTemplateQuestionsModal.vue').default,LoadingOverlay: require('/lasso/components/LoadingOverlay.vue').default,Template: require('/lasso/components/templates/Template.vue').default,CategoriesManager: require('/lasso/components/questions/CategoriesManager.vue').default,Category: require('/lasso/components/questions/Category.vue').default,QuestionList: require('/lasso/components/questions/QuestionList.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab7c27f8')) {
      api.createRecord('ab7c27f8', component.options)
    } else {
      api.reload('ab7c27f8', component.options)
    }
    module.hot.accept("./CategoriesQuestions.vue?vue&type=template&id=ab7c27f8&", function () {
      api.rerender('ab7c27f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/questions/CategoriesQuestions.vue"
export default component.exports