var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrap-outer", class: { mobile: _vm.isMobile } },
    [
      _c("QuickStartModal"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "page-wrap",
          class: { mobile: _vm.isMobile, "nav-open": _vm.navOpen }
        },
        [
          _vm.navOpen
            ? _c("div", {
                staticClass: "backdrop",
                on: {
                  click: function($event) {
                    return _vm.toggleMenu([])
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobile
            ? _c("NavMobile", { attrs: { "nav-open": _vm.navOpen } })
            : _vm._e(),
          _vm._v(" "),
          _vm.isDesktop
            ? _c("Header", { attrs: { "layout-type": "default" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.showError
            ? _c("Error", { attrs: { error: _vm.error, stack: _vm.stack } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "projects", class: _vm.pageTheme },
            [
              _vm.isMobile
                ? _c("PageHeader", { attrs: { name: _vm.pageName } })
                : _vm._e(),
              _vm._v(" "),
              _c("ProjectsHeader", { attrs: { "page-name": _vm.pageName } }),
              _vm._v(" "),
              _c(
                "b-container",
                {
                  staticClass: "content-container",
                  attrs: { fluid: !_vm.isDesktop }
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            { staticClass: "breadcrumbs-title" },
                            [
                              !_vm.showFullPageLayout
                                ? _c(
                                    "b-col",
                                    { staticClass: "breadcrumbs" },
                                    [
                                      _c("Breadcrumb", {
                                        attrs: {
                                          items: _vm.pageBreadcrumbs,
                                          label: "Project:",
                                          title: _vm.project.name
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            { staticClass: "page-content-wrapper" },
                            [
                              _vm.isDesktop && !_vm.showFullPageLayout
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "page-subnav",
                                      attrs: { cols: "2" }
                                    },
                                    [_c("NavSecondary")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                {
                                  staticClass: "page-content",
                                  attrs: {
                                    cols:
                                      !_vm.isDesktop || _vm.showFullPageLayout
                                        ? 12
                                        : 10
                                  }
                                },
                                [_c("nuxt")],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }