var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    {
      staticClass: "section-list-item",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.showLoading ? _c("LoadingOverlay") : _vm._e(),
      _vm._v(" "),
      _vm.$slots.main
        ? _c("b-col", { staticClass: "section-list-body" }, [_vm._t("main")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.actions
        ? _c(
            "b-col",
            { staticClass: "section-list-actions", attrs: { cols: "1" } },
            [_vm._t("actions")],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }