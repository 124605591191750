//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Categories',
  props: {
    category: {
      type: String,
      default: ''
    },
    edit: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      categoryToEdit: '',
      route: {
        projectid: null,
        questionid: null,
        templateid: null
      },
      questionOwner: null,
      questionType: null,
      newPrimaryCategory: '',
      reassignPrimaryCategory: false,
      reassignPrimaryToPrimaryCategory: false,
      savingCategory: false,
      secondaryCategoriesFound: false,
      secondaryIndex: 0,
      primaryElem: null,
      secondaryElem: null
    }
  },
  computed: {
    ...mapGetters({
      activeCategory: 'questions/getActiveCategory',
      activeProjectPhase: 'projects/getActiveProjectPhase',
      isMobile: 'breakpoints/mobile',
      isProjectOwner: 'user/isProjectOwner',
      questionList: 'questions/getQuestions',
      primaryCategory: 'questions/getPrimaryCategory',
      secondaryCategory: 'questions/getSecondaryCategory',
      primaryCategories: 'questions/getPrimaryCategories',
      unseenPrimaryCategories: 'questions/getUnseenPrimaryCategories',
      secondaryCategories: 'questions/getSecondaryCategories',
      unseenSecondaryCategories: 'questions/getUnseenSecondaryCategories',
      requiredCategories: 'questions/getRequiredCategories',
      template: 'templates/getActiveTemplate'
    }),
    activeWithinPropertyDetail() {
      const { propertyid } = this.$route.params
      return propertyid !== undefined
    },
    availableCategories() {
      return this.primaryCategories.filter(p => p !== this.category)
    },
    categoryHasRequired() {
      return ({ primary, secondary }) => {
        /**
         * Not sure why I added this condition for participants, but it causes an error
         * so I'm commenting it out for now
         *
        if (!this.isProjectOwner) {
          return false
        }
        */
        if (!secondary) {
          return this.requiredCategories[primary]
        }
        return this.requiredCategories[primary] ? this.requiredCategories[primary][secondary] : false
      }
    },
    categoryUnseen() {
      return ({ primary, secondary }) => {
        if (!this.isProjectOwner) {
          return false
        }
        if (!secondary) {
          return this.unseenPrimaryCategories.some(pc => pc === primary)
        }
        return this.unseenPrimaryCategories.some(pc => pc === primary) &&
          this.unseenSecondaryCategories.some(sc => sc === secondary)
      }
    },
    displayCategoryHasRequired() {
      return this.$route.name !== 'projects-projectid-questions'
    },
    primaryCategoryUniqueId() {
      const categoryFlattened = this.category.replace(' ', '-').toLowerCase()
      return `${categoryFlattened}_${this.index.toString()}`
    }
  },
  watch: {
    activeCategory(newVal) {
      this.categoryToEdit = newVal
    },
    edit: {
      handler(newVal, oldVal) {
        if (newVal && this.activeCategory === this.primaryCategory) {
          if (this.secondaryElem) {
            this.secondaryElem.removeEventListener('keydown', this.handleKeys)
            this.secondaryElem = null
          }
          this.primaryElem = document.getElementById(this.primaryCategoryUniqueId)
          this.primaryElem.addEventListener('keydown', this.handleKeys)
          this.$refs[this.primaryCategoryUniqueId].focus()
        } else if (newVal &&
          this.category === this.primaryCategory &&
          this.activeCategory === this.secondaryCategories[this.secondaryIndex]) {
          if (this.primaryElem) {
            this.primaryElem.removeEventListener('keydown', this.handleKeys)
            this.primaryElem = null
          }
          const secCatId = this.secondaryCategoryUniqueId(this.activeCategory, this.secondaryIndex)
          this.secondaryElem = document.getElementById(secCatId)
          this.secondaryElem.addEventListener('keydown', this.handleKeys)
          this.$nextTick(() => {
            this.secondaryElem.focus()
          })
        } else {
          if (this.primaryElem) {
            this.primaryElem.removeEventListener('keydown', this.handleKeys)
            this.primaryElem = null
          }
          if (this.secondaryElem) {
            this.secondaryElem.removeEventListener('keydown', this.handleKeys)
            this.secondaryElem = null
          }
        }
      }
    }
  },
  mounted() {
    this.route = { ...this.$route.params }
    this.questionOwner = this.route.templateid ? this.route.templateid : this.route.projectid
    this.questionType = this.route.templateid ? 1 : 2
    this.categoryToEdit = this.activeCategory
  },
  methods: {
    ...mapActions({
      deleteQuestionsInCategory: 'questions/deleteQuestionsInCategory',
      fetchQuestions: 'questions/fetchQuestions',
      moveToPrimaryCategory: 'questions/moveToPrimaryCategory',
      movePrimaryUnderOtherPrimaryCategory: 'questions/movePrimaryUnderOtherPrimaryCategory',
      moveUnderOtherPrimaryCategory: 'questions/moveUnderOtherPrimaryCategory',
      renameCategory: 'questions/renameCategory',
      fetchPrimaryCategories: 'questions/fetchPrimaryCategories',
      fetchSecondaryCategories: 'questions/fetchAllSecondaryCategories',
      setActiveCategory: 'questions/setActiveCategory',
      setPrimaryCategory: 'questions/setPrimaryCategory',
      setSecondaryCategory: 'questions/setSecondaryCategory'
    }),
    async deleteCategoryQuestions(level) {
      const params = {
        owner: this.questionOwner,
        type: this.questionType,
        primary: this.primaryCategory,
        secondary: this.secondaryCategory
      }
      await this.deleteQuestionsInCategory(params)
      this.$emit('cancel-edit')
      if (level === 'secondary') {
        await this.setSelectedPrimaryCategory(this.primaryCategory)
        if (!this.questionList.length && !this.secondaryCategories.length) {
          await this.fetchPrimaryCategories({ owner: this.questionOwner, type: this.questionType, viewPhase: this.activeWithinPropertyDetail })
          localStorage.removeItem('categoryselections')
        }
      } else {
        await this.fetchPrimaryCategories({ owner: this.questionOwner, type: this.questionType, viewPhase: this.activeWithinPropertyDetail })
        localStorage.removeItem('categoryselections')
      }
      this.$emit('loading-questions', false)
    },
    async deleteCategoryQuestionsConfirm(level) {
      const { projectid } = this.$route.params
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'Are you sure you want to delete this category ',
          h('strong', `"${this.categoryToEdit}"? `),
          `${projectid
            ? 'All subcategories (if applicable), questions, and answer data will be deleted and cannot be undone!'
            : 'All subcategories (if applicable) and questions will be deleted and cannot be undone.'
          }`
        ]
      )
      const res = await this.$bvModal.msgBoxConfirm([customMsg], {
        title: 'Confirm Categories and Questions Delete!',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelVariant: 'light',
        cancelTitle: 'NO',
        footerBgVariant: 'dark',
        headerBgVariant: 'warning',
        hideHeaderClose: true,
        centered: true
      })
      if (res) {
        await this.deleteCategoryQuestions(level)
      }
    },
    async displayInvalidCategoryNameMessage(prevName) {
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'Invalid category name! If you want all the questions in this category (and subcategories if any) to be Uncategorized follow these steps:',
          h('br'),
          '1) Click the . . . button',
          h('br'),
          '2) Choose "Move to Primary Category"',
          h('br'),
          '3) Choose Uncategorized in the dropdown list and Save'
        ]
      )
      await this.$bvModal.msgBoxOk([customMsg], {
        title: 'Invalid Category Rename!',
        okVariant: 'danger',
        okTitle: 'OK',
        footerBgVariant: 'dark',
        headerBgVariant: 'danger',
        hideHeaderClose: true,
        centered: true
      })
      this.categoryToEdit = prevName
    },
    handleKeys(e) {
      if (e.keyCode === 13) {
        const { value } = e.target
        const level = e.target.dataset.catedit
        const previousName = e.target.id
        if (previousName) {
          const ref = this.$refs[`btn-${previousName}`]
          if (level === 'secondary') {
            if (previousName !== value) {
              const el = `btn-${previousName}`
              if (ref) {
                document.getElementById(el).click()
              }
            }
          } else if (level === 'primary' && ref) {
            this.$refs[`btn-${previousName}`].click()
          }
        }
      }
    },
    async moveSecondaryToNewPrimary(previousName) {
      this.savingCategory = true
      const newCategory = this.newPrimaryCategory === 'Uncategorized' ? '' : this.newPrimaryCategory
      const params = {
        owner: this.questionOwner,
        type: this.questionType,
        primaryCategory: this.primaryCategory,
        secondaryCategory: this.categoryToEdit,
        newPrimaryCategory: newCategory
      }
      this.$emit('cancel-edit')
      await this.moveUnderOtherPrimaryCategory(params)
      await this.fetchPrimaryCategories({ owner: this.questionOwner, type: this.questionType, viewPhase: this.activeWithinPropertyDetail })
      await this.setSelectedPrimaryCategory(this.newPrimaryCategory)
      if (this.newPrimaryCategory !== 'Uncategorized') {
        const idx = this.secondaryCategories.findIndex(s => s === previousName)
        await this.setSelectedSecondaryCategory(previousName, idx)
      }
      this.reassignPrimaryCategory = false
      this.newPrimaryCategory = ''
      this.savingCategory = false
    },
    async movePrimaryToNewPrimary(previousName) {
      this.savingCategory = true
      const newCategory = this.newPrimaryCategory === 'Uncategorized' ? '' : this.newPrimaryCategory
      const params = {
        owner: this.questionOwner,
        type: this.questionType,
        primaryCategory: previousName,
        newPrimaryCategory: newCategory
      }
      this.$emit('cancel-edit')
      await this.movePrimaryUnderOtherPrimaryCategory(params)
      await this.fetchPrimaryCategories({ owner: this.questionOwner, type: this.questionType, viewPhase: this.activeWithinPropertyDetail })
      await this.setSelectedPrimaryCategory(this.newPrimaryCategory)
      if (this.newPrimaryCategory !== 'Uncategorized') {
        const idx = this.secondaryCategories.findIndex(s => s === previousName)
        await this.setSelectedSecondaryCategory(previousName, idx)
      }
      this.reassignPrimaryToPrimaryCategory = false
      this.newPrimaryCategory = ''
      this.savingCategory = false
    },
    reassignPrimary() {
      this.reassignPrimaryCategory = true
    },
    reassignPrimaryToPrimary() {
      this.reassignPrimaryToPrimaryCategory = true
    },
    async saveCategoryConfirm(prevName) {
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'This action would delete this category and is not allowed since secondary categories exist. ',
          'Please try again and enter a name to rename this category.'
        ]
      )
      await this.$bvModal.msgBoxOk([customMsg], {
        title: 'Category Delete Not Allowed!',
        okVariant: 'light',
        okTitle: 'OK',
        footerBgVariant: 'dark',
        headerBgVariant: 'warning',
        hideHeaderClose: true,
        centered: true
      })
      this.categoryToEdit = prevName
    },
    async saveCategory(level, previousName) {
      if (this.reassignPrimaryCategory) {
        // re-assign a secondary to a new primary category
        await this.moveSecondaryToNewPrimary(previousName)
      } else if (this.reassignPrimaryToPrimaryCategory) {
        // re-assign a primary as a secondary under a different primary category
        await this.movePrimaryToNewPrimary(previousName)
      } else if (this.categoryToEdit === 'Uncategorized') {
        // do not allow category to be renamed to Uncategorized
        this.displayInvalidCategoryNameMessage(previousName)
      } else if (level === 'primary' && this.categoryToEdit === '' && this.secondaryCategories.length) {
        // do not allow primary category to be deleted if secondaries exist, display modal error
        this.saveCategoryConfirm(previousName)
      } else {
        // all other normal category renaming functions
        this.savingCategory = true
        const params = {
          owner: this.questionOwner,
          type: this.questionType,
          categoryLevel: level,
          previousName,
          newName: this.categoryToEdit
        }
        this.$emit('cancel-edit')
        const renamingToEmptyString = this.categoryToEdit === ''
        await this.renameCategory(params)
        if (level === 'primary') {
          if (renamingToEmptyString) {
            // renaming primary to empty string moves all questions to Uncategorized, so call this with Uncategorized to fetch questions
            await this.setSelectedPrimaryCategory('Uncategorized')
          } else {
            const sessionObj = {}
            sessionObj[this.questionOwner] = {}
            sessionObj[this.questionOwner].primaryCategory = this.categoryToEdit
            localStorage.setItem('categoryselections', JSON.stringify(sessionObj))
          }
        } else if (level === 'secondary') {
          if (renamingToEmptyString) {
            // renaming secondary to empty string moves all questions to the parent, so call this with current primary to fetch questions
            await this.setSelectedPrimaryCategory(this.primaryCategory)
          } else {
            const sessionObj = JSON.parse(localStorage.getItem('categoryselections'))
            sessionObj[this.questionOwner].secondaryCategory = this.categoryToEdit
            localStorage.setItem('categoryselections', JSON.stringify(sessionObj))
          }
        }
        this.savingCategory = false
      }
    },
    secondaryCategoryUniqueId(secondary, idx) {
      const categoryFlattened = secondary.replace(' ', '-').toLowerCase()
      return `${categoryFlattened}_${this.index.toString()}_${idx}`
    },
    async setSecondaryAsPrimaryConfirm(secondary) {
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'This action will assign all questions in this category to have a new Primary Category of ',
          h('strong', `"${secondary}" `),
          h('br'),
          h('br'),
          h('p', 'Tertiary Categories that had been assigned, will move up as the Secondary Category for those questions.'),
          h('p', 'Questions with no existing Tertiary Category may need to be reviewed to ensure they are assigned a new Secondary Category.')
        ]
      )
      const res = await this.$bvModal.msgBoxConfirm([customMsg], {
        title: 'Notification to Review Category Assignments!',
        okVariant: 'warning',
        okTitle: 'OK',
        cancelVariant: 'light',
        cancelTitle: 'CANCEL',
        footerBgVariant: 'dark',
        headerBgVariant: 'warning',
        hideHeaderClose: true,
        centered: true
      })
      if (res) {
        await this.setSecondaryAsPrimary(secondary)
      }
    },
    async setSecondaryAsPrimary(category) {
      // this function changes a secondary category into a primary category
      this.savingCategory = true
      const params = {
        owner: this.questionOwner,
        type: this.questionType,
        secondaryCategory: category
      }
      await this.moveToPrimaryCategory(params)
      await this.fetchPrimaryCategories({ owner: this.questionOwner, type: this.questionType, viewPhase: this.activeWithinPropertyDetail })
      await this.setSelectedPrimaryCategory(category)
      this.$emit('cancel-edit')
      this.savingCategory = false
    },
    async setSelectedPrimaryCategory(category) {
      this.setActiveCategory(category)
      this.setPrimaryCategory(category)
      this.$emit('loading-questions', true)
      await this.fetchQuestions({ owner: this.questionOwner, type: this.questionType, viewPhase: this.activeWithinPropertyDetail })
      if (this.isMobile) {
        const el = document.querySelector('#question-list')
        el.scrollIntoView()
        // window.scrollTo(el.offsetLeft, el.offsetTop + 125)
      }
      const res = await this.fetchSecondaryCategories({ owner: this.questionOwner, type: this.questionType, viewPhase: this.activeWithinPropertyDetail })
      if (res) {
        this.secondaryCategoriesFound = true
      }
      this.$emit('loading-questions', false)
      const sessionObj = {}
      sessionObj[this.questionOwner] = {}
      sessionObj[this.questionOwner].primaryCategory = category
      localStorage.setItem('categoryselections', JSON.stringify(sessionObj))
    },
    async setSelectedSecondaryCategory(category, idx) {
      this.setActiveCategory(category)
      this.secondaryIndex = idx
      this.setSecondaryCategory(category)
      this.$emit('loading-questions', true)
      await this.fetchQuestions({ owner: this.questionOwner, type: this.questionType, viewPhase: this.activeWithinPropertyDetail })
      this.$emit('loading-questions', false)
      if (this.isMobile) {
        const el = document.querySelector('#question-list')
        el.scrollIntoView()
        // window.scrollTo(el.offsetLeft, el.offsetTop + 25)
      }
      const sessionObj = JSON.parse(localStorage.getItem('categoryselections'))
      sessionObj[this.questionOwner].secondaryCategory = category
      localStorage.setItem('categoryselections', JSON.stringify(sessionObj))
    }
  }
}
