var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { staticClass: "question answer participant-view" },
    [
      _c(
        "b-row",
        { staticClass: "answer-row" },
        [
          _c(
            "b-col",
            { staticClass: "col-text" },
            [
              _vm.questionsSelectActive && !_vm.requirementIsMet
                ? _c("b-checkbox", {
                    staticClass: "question-select-cb",
                    attrs: { size: "is-small" },
                    model: {
                      value: _vm.questionSelected,
                      callback: function($$v) {
                        _vm.questionSelected = $$v
                      },
                      expression: "questionSelected"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: _vm.question._id,
                      expression: "question._id"
                    }
                  ],
                  staticClass: "question-text",
                  class: { "is-requirement": _vm.question.requirement },
                  attrs: { disabled: _vm.question.requirement }
                },
                [
                  _vm.question.required && !_vm.question.requirement
                    ? _c(
                        "span",
                        { staticClass: "required-badge" },
                        [
                          _c("b-badge", { attrs: { variant: "danger" } }, [
                            _vm._v("R")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.question.requirement
                    ? _c(
                        "span",
                        { staticClass: "requirement-badge" },
                        [
                          _c("b-badge", { attrs: { variant: "danger" } }, [
                            _vm._v("Rqmt")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v("\n        " + _vm._s(_vm.question.text) + "\n      ")
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "col-info-actions", attrs: { cols: "2" } },
            [
              _c(
                "div",
                { staticClass: "col-info" },
                [
                  _vm.questionIsAnswered
                    ? _c("b-icon", {
                        attrs: { icon: "check", type: "is-success" }
                      })
                    : _c("b-icon", {
                        attrs: { icon: "close", type: "is-danger" }
                      })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "detail-row" },
        [
          _c(
            "b-col",
            [
              !_vm.question.requirement
                ? _c(
                    "b-collapse",
                    {
                      staticClass: "detail-container",
                      attrs: { id: _vm.question._id }
                    },
                    [
                      _c("QuestionAnswerForm", {
                        attrs: {
                          "project-in-review": _vm.projectInReview,
                          question: _vm.question
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }