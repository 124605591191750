//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from 'lodash-es'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProjectLayout',
  data () {
    return {
      pageTheme: ''
    }
  },
  computed: {
    ...mapGetters({
      currentTheme: 'global/pageTheme',
      error: 'global/networkError',
      stack: 'global/networkErrorStack',
      isDesktop: 'breakpoints/desktop',
      isMobile: 'breakpoints/mobile',
      navOpen: 'global/navOpen',
      project: 'projects/getActiveProject'
    }),
    pageBreadcrumbs() {
      return this.pageMeta?.breadcrumbs || []
    },
    pageName() {
      return this.pageMeta?.name || 'Project'
    },
    pageMeta() {
      const { delegateid, projectid, participantid, propertyid, questionid } = this.$route.params
      const pages = [
        {
          name: 'Details',
          routeName: 'projects-projectid-details',
          breadcrumbs: [
          ]
        },
        {
          name: 'Delegates',
          routeName: 'projects-projectid-delegates',
          breadcrumbs: [
          ]
        },
        {
          name: 'Delegate Detail',
          routeName: 'projects-projectid-delegates-delegateid',
          breadcrumbs: [
            {
              text: 'Delegates',
              href: `/projects/${projectid}/delegates/`
            },
            {
              text: 'Delegate Detail',
              href: `/projects/${projectid}/delegates/${delegateid}`,
              active: true
            }
          ]
        },
        {
          name: 'Add Delegate',
          routeName: 'projects-projectid-delegates-add',
          breadcrumbs: [
            {
              text: 'Delegates',
              href: `/projects/${projectid}/delegates/`
            },
            {
              text: 'Add Delegate',
              href: `/projects/${projectid}/delegates/add`,
              active: true
            }
          ]
        },
        {
          name: 'Edit Delegate',
          routeName: 'projects-projectid-delegates-delegateid-edit',
          breadcrumbs: [
            {
              text: 'Delegates',
              href: `/projects/${projectid}/delegates/`
            },
            {
              text: 'Delegate Detail',
              href: `/projects/${projectid}/delegates/${delegateid}`
            },
            {
              text: 'Edit Delegate',
              href: `/projects/${projectid}/delegates/${delegateid}edit`,
              active: true
            }
          ]
        },
        {
          name: 'Add Question',
          routeName: 'projects-projectid-questions-add',
          breadcrumbs: [
            {
              text: 'Questions',
              href: `/projects/${projectid}/questions/`
            },
            {
              text: 'Add Question',
              href: `/projects/${projectid}/questions/add`,
              active: true
            }
          ]
        },
        {
          name: 'Edit Question',
          routeName: 'projects-projectid-questions-questionid-edit-question',
          breadcrumbs: [
            {
              text: 'Questions',
              href: `/projects/${projectid}/questions/`
            },
            {
              text: 'Edit Question',
              href: `/projects/${projectid}/questions/${questionid}/edit-question`,
              active: true
            }
          ]
        },
        {
          name: 'Questions',
          routeName: 'projects-projectid-questions',
          breadcrumbs: [
          ]
        },
        {
          name: 'Add Participant',
          routeName: 'projects-projectid-participants-add',
          breadcrumbs: [
            {
              text: 'Participants',
              href: `/projects/${projectid}/participants/`
            },
            {
              text: 'Add Participant',
              href: `/projects/${projectid}/participants/add`,
              active: true
            }
          ]
        },
        {
          name: 'Participants',
          routeName: 'projects-projectid-participants',
          breadcrumbs: [
          ]
        },
        {
          name: 'Participant Detail',
          routeName: 'projects-projectid-participants-participantid',
          breadcrumbs: [
            {
              text: 'Participants',
              href: `/projects/${projectid}/participants/`
            },
            {
              text: 'Participant Detail',
              href: `/projects/${projectid}/participants/${participantid}`,
              active: true
            }
          ]
        },
        {
          name: 'Edit Participant',
          routeName: 'projects-projectid-participants-participantid-edit',
          breadcrumbs: [
            {
              text: 'Participants',
              href: `/projects/${projectid}/participants/`
            },
            {
              text: 'Participant Detail',
              href: `/projects/${projectid}/participants/${participantid}`
            },
            {
              text: 'Edit Participant',
              href: `/projects/${projectid}/participants/${participantid}/edit`,
              active: true
            }
          ]
        },
        {
          name: 'Add Project',
          routeName: 'projects-add',
          breadcrumbs: [
          ]
        },
        {
          name: 'Edit Project',
          routeName: 'projects-projectid-edit',
          breadcrumbs: [
          ]
        },
        {
          name: 'Projects',
          routeName: 'projects',
          breadcrumbs: [
          ]
        },
        {
          name: 'Properties',
          routeName: 'projects-projectid-properties',
          breadcrumbs: [
          ]
        },
        {
          name: 'Property Detail',
          routeName: 'projects-projectid-properties-propertyid',
          breadcrumbs: [
            {
              text: 'Properties',
              href: `/projects/${projectid}/properties/`
            },
            {
              text: 'Property Detail',
              href: `/projects/${projectid}/properties/${propertyid}`,
              active: true
            }
          ]
        },
        {
          name: 'Select Properties',
          routeName: 'projects-projectid-properties-add',
          breadcrumbs: [
            {
              text: 'Properties',
              href: `/projects/${projectid}/properties/`
            },
            {
              text: 'Select Properties',
              href: `/projects/${projectid}/properties/add`,
              active: true
            }
          ]
        },
        {
          name: 'Requirements',
          routeName: 'projects-projectid-requirements',
          breadcrumbs: [
          ]
        }
      ]
      const meta = pages.filter(p => p.routeName === this.$route.name)
      return meta[0]
    },
    showFullPageLayout() {
      return ['projects', 'projects-add', 'projects-projectid-edit'].includes(this.$route.name)
    },
    showError() {
      return this.error && this.error?.info?.status !== 401
    }
  },
  watch: {
    async $route(newVal) {
      if (newVal.params.projectid) {
        this.setPageTheme()
        if (!['projects', 'projects-add'].includes(this.$route.name)) {
          await this.setProjectId()
        }
      }
    }
  },
  async mounted () {
    this.setPageTheme()
    this.watchBreakpoints()
    if (!['projects', 'projects-add'].includes(this.$route.name)) {
      await this.setProjectId()
    }
  },
  methods: {
    ...mapActions({
      setActiveProjectId: 'projects/setActiveProjectId',
      setTheme: 'global/setPageTheme',
      startLoading: 'global/startLoading',
      stopLoading: 'global/stopLoading',
      toggleMenu: 'global/toggleMenu',
      watchBreakpoints: 'breakpoints/watch'
    }),
    async setProjectId() {
      if (isEmpty(this.project)) {
        this.startLoading()
        await this.setActiveProjectId(this.$route.params.projectid)
        this.stopLoading()
      }
    },
    setPageTheme () {
      const theme = this.$route.path.match(/dashboard|inbox|projects|reports|templates/gi)
      if (theme) {
        this.pageTheme = `theme-${theme[0]}`
        this.setTheme(theme[0])
      } else {
        this.pageTheme = 'theme-dashboard'
        this.setTheme('dashboard')
      }
    }
  }
}
