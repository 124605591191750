//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from 'lodash-es'

export default {
  name: 'ReadEmailModal',
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    messageAvailable() {
      return !isEmpty(this.message)
    }
  },
  methods: {
    handleRegisterClick() {
      this.$bvModal.hide('modal-read-email')
      this.$bvModal.show('modal-auth')
    }
  }
}
