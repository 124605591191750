var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-mail-move",
      attrs: {
        "data-name": "icon-mail-move",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "12.7", y1: "12.96", x2: "12.7", y2: "2.49" }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "8.47 8.9 12.7 13.13 16.89 8.94" }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "1.66 14.72 6.06 11.39 7.59 11.39" }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "23.56 14.72 19.17 11.29 17.73 11.29" }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d:
            "M23,14.59h-6v2.23h-9V14.59H2.14a.87.87,0,0,0-.86.86v5.67a.87.87,0,0,0,.86.86H23a.86.86,0,0,0,.86-.86V15.45A.86.86,0,0,0,23,14.59Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }