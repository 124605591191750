var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { class: { "sticky-top": _vm.isMobile }, attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        {
          class: {
            mobile: _vm.isMobile,
            "page-header": _vm.isMobile,
            "page-header-wrapper": !_vm.isMobile
          }
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: _vm.isMobile } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("h1", { staticClass: "page-heading" }, [
                      _vm._v(
                        "\n            " + _vm._s(_vm.name) + "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.isMobile
                    ? _c(
                        "b-col",
                        { staticClass: "nav-toggle", attrs: { cols: "1" } },
                        [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.toggleMenu([])
                                }
                              }
                            },
                            [_c("IconHamburger")],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }