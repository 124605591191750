var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "main-footer" },
    [
      _vm.isMarketingTheme
        ? _c(
            "b-container",
            { staticClass: "top-footer", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "logo-phone" },
                        [
                          _c("b-link", { attrs: { href: "/" } }, [
                            _c("img", {
                              staticClass: "logo-lg",
                              attrs: {
                                src: require("assets/images/LassoLogoWhite.png"),
                                alt: "Lasso Logo"
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mailto:sales@strataplatforms.com"
                                }
                              },
                              [_vm._v("sales@strataplatforms.com")]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-col", { staticClass: "address" }, [
                        _vm._v("\n          21 E Washington St"),
                        _c("br"),
                        _vm._v("\n          Greenville, SC 29601"),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "mailto:sales@strataplatforms.com" }
                          },
                          [_vm._v("Email Us")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-container",
        { staticClass: "bottom-footer", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _vm.isDesktop || _vm.isMarketingTheme
                ? _c(
                    "b-row",
                    [
                      _vm.isMarketingTheme
                        ? _c(
                            "b-col",
                            { staticClass: "social", attrs: { md: "4" } },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass: "linkedin",
                                  attrs: {
                                    href:
                                      "https://www.linkedin.com/showcase/lasso-data-collection-app",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c("IconLinkedIn", {
                                    attrs: { title: "LinkedIn" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "b-col",
                            { staticClass: "logo-phone", attrs: { md: "4" } },
                            [
                              _c("b-link", { attrs: { href: "/" } }, [
                                _c("img", {
                                  staticClass: "logo",
                                  attrs: {
                                    src: require("assets/images/LassoLogoWhiteTM-NoTagline.png"),
                                    alt: "Lasso Logo"
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "social-wrapper" }, [
                                _c("span", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "mailto:sales@strataplatforms.com"
                                      }
                                    },
                                    [_vm._v("sales@strataplatforms.com")]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "social" },
                                  [
                                    _c(
                                      "b-link",
                                      {
                                        staticClass: "linkedin",
                                        attrs: {
                                          href:
                                            "https://www.linkedin.com/showcase/lasso-data-collection-app",
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c("IconLinkedIn", {
                                          attrs: { title: "LinkedIn" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { staticClass: "copyright-links", attrs: { md: "4" } },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "b-link",
                                {
                                  attrs: {
                                    href: "http://www.strataplatforms.com/",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              ©2024 Strata Platforms. All Rights Reserved.\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "links-container" },
                            [
                              _c("b-nav-item", { attrs: { to: "/privacy" } }, [
                                _vm._v("\n              Privacy\n            ")
                              ]),
                              _vm._v(" "),
                              _c("b-nav-item", { attrs: { to: "/terms" } }, [
                                _vm._v("\n              Terms\n            ")
                              ]),
                              _vm._v(" "),
                              _c("b-nav-item", { attrs: { to: "/support" } }, [
                                _vm._v("\n              Support\n            ")
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loggedInUser && !_vm.isDesktop && !_vm.isMarketingTheme
                ? _c(
                    "b-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c("NavItems", {
                            staticClass: "small",
                            attrs: {
                              items: _vm.selectedNav,
                              "display-type": _vm.navStyle
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }