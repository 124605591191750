var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "form-reset" },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            [
              _vm.success
                ? _c(
                    "b-card",
                    [
                      _c("b-alert", { attrs: { show: "" } }, [
                        _c("h3", [
                          _vm._v(
                            "\n            Please check your email for a password reset link.\n          "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _c(
                    "b-form",
                    {
                      staticClass: "form-wrapper rounded-lg mb-3",
                      attrs: { novalidate: "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.onSubmit($event)
                        }
                      }
                    },
                    [
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            show: !!_vm.errorMsg,
                            variant: "danger",
                            fade: "",
                            dismissable: ""
                          }
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errorMsg) +
                                "\n          "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "input-group-email",
                            "label-for": "input-email"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-email",
                              state: _vm.validateState("email"),
                              type: "email",
                              required: "",
                              placeholder: "Email Address"
                            },
                            model: {
                              value: _vm.$v.resetForm.email.$model,
                              callback: function($$v) {
                                _vm.$set(_vm.$v.resetForm.email, "$model", $$v)
                              },
                              expression: "$v.resetForm.email.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-email-live-feedback" } },
                            [
                              _vm._v(
                                "\n            Email is required and should be formatted like john.doe@google.com.\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "action-primary",
                          attrs: {
                            type: "submit",
                            disabled: _vm.disableSubmit,
                            variant: "marketing",
                            block: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.submitButtonText) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "action-secondary",
                          attrs: {
                            type: "button",
                            variant: "templates",
                            block: ""
                          },
                          on: { click: _vm.onCancel }
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }