//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { email, required } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  auth: false,
  data() {
    return {
      emailReferralSuccess: false,
      loginForm: {
        email: '',
        password: ''
      },
      errorMsg: '',
      processing: false,
      showResend: false,
      showNoMoreResends: false,
      emailNotConfirmed: 'Email address not confirmed, please check your Inbox and spam folder.',
      ssoLogin: null
    }
  },
  computed: {
    ...mapGetters({
      activeMessageId: 'messages/getListMessageId',
      loggedInUser: 'user/loggedInUser',
      referralMessageId: 'messages/getReferralMessageId',
      settingLandingPage: 'user/getUserSettingLandingPage',
      canResend: 'user/getCanResendConfirmationEmail',
      sendCountdown: 'user/getResendIntervalCounter',
      oauth2Providers: 'organizations/getOAuth2Orgs'
    }),
    disableSubmit() {
      return !this.$v.loginForm.$dirty || (this.$v.loginForm.$dirty && this.$v.loginForm.$anyError)
    },
    submitButtonText() {
      return this.processing ? 'Please wait ...' : 'Log In'
    },
    ssoProviders() {
      const p = [{ value: 'lois', label: 'LOIS' }]
      const ps = this.oauth2Providers
      if (ps && ps.length > 0) {
        ps.forEach((org) => {
          p.push({ value: org._id, label: org.name })
        })
      }
      return p
    }
  },
  async mounted() {
    await this.fetchOAuth2Orgs()
  },
  methods: {
    ...mapActions({
      getMessageIdFromEmailReferralId: 'messages/getMessageIdFromEmailReferralId',
      login: 'user/login',
      setListMessageId: 'messages/setListMessageId',
      setReferralMessageId: 'messages/setReferralMessageId',
      resendConfirmationEmail: 'user/resendConfirmationEmail',
      fetchOAuth2Orgs: 'organizations/fetchOAuth2Orgs'
    }),
    async resendConfirmation() {
      try {
        await this.resendConfirmationEmail(this.loginForm)
      } catch (e) {
        const message = e.response?.data || e.message
        if (message === 'max sends reached') {
          this.showNoMoreResends = true
          this.showResend = false
        }
      }
    },
    async onLogin() {
      this.$v.loginForm.$touch()
      if (this.$v.loginForm.$anyError) {
        return
      }

      try {
        this.errorMsg = ''
        this.processing = true
        await this.login(this.loginForm)
        if (this.referralMessageId !== '') {
          try {
            const message = await this.getMessageIdFromEmailReferralId(this.referralMessageId)
            this.emailReferralSuccess = true
            this.setListMessageId(message._id)
          } catch (e) {
            this.setReferralMessageId('')
          }
        }
        if (this.loggedInUser) {
          this.processing = false
          // hide login modal and check if user was referred by email and redirect to inbox if so
          this.$bvModal.hide('modal-auth')
          if (this.emailReferralSuccess) {
            this.setReferralMessageId('')
            this.$router.push('/inbox')
          } else if (sessionStorage.getItem('redirectUrl')) {
            const path = sessionStorage.getItem('redirectUrl')
            sessionStorage.removeItem('redirectUrl')
            this.$router.push(path)
          } else {
            this.$router.push(this.settingLandingPage)
          }
        }
      } catch (e) {
        this.processing = false
        if (e.response && e.response.data) {
          this.errorMsg = e.response.data
        } else {
          this.errorMsg = e.message
        }
        if (this.errorMsg === this.emailNotConfirmed) {
          this.showResend = true
        }
      }
    },
    resetForm() {
      // TODO: decide if we want to empty out fields when error alert dismissed
      // if so, how do we reset vuelidate status so validation does trigger until user completes fields again
      // this.loginForm.email = ''
      // this.loginForm.password = ''
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.loginForm[name]
      return $dirty ? !$error : null
    }
  },
  validations: {
    loginForm: {
      email: {
        required,
        email
      },

      password: {
        required
      }
    }
  }
}
