var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-import-template-questions",
        "header-bg-variant": "projects",
        scrollable: ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function() {
            return [_vm._v("\n    Import Template Questions\n  ")]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  attrs: { type: "reset", variant: "templates" },
                  on: {
                    click: function($event) {
                      _vm.$bvModal.hide("modal-import-template-questions")
                      _vm.selectedTemplateId = null
                      _vm.selectedTemplateHighestAssignedPhase = null
                    }
                  }
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _vm._v(" "),
              _vm.displayImportAlert
                ? _c(
                    "b-button",
                    {
                      attrs: { type: "reset", variant: "projects" },
                      on: {
                        click: function($event) {
                          return _vm.selectTemplate(_vm.selectedTemplateId)
                        }
                      }
                    },
                    [_vm._v("\n      Continue\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.displayImportAlert
                ? _c(
                    "b-button",
                    {
                      attrs: { type: "reset", variant: "inbox" },
                      on: {
                        click: function($event) {
                          return _vm.selectTemplate(
                            _vm.selectedTemplateId,
                            true
                          )
                        }
                      }
                    },
                    [_vm._v("\n      Continue With Update\n    ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _vm.loadingTemplates ? _c("LoadingOverlay") : _vm._e(),
          _vm._v(" "),
          _c("h4", [
            _vm._v(
              _vm._s(
                _vm.displayImportAlert ? "Choose An Option" : "Template List"
              )
            )
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          !_vm.displayImportAlert
            ? _c(
                "div",
                { staticClass: "template-list" },
                _vm._l(_vm.templates, function(template) {
                  return _c(
                    "SectionListItem",
                    { key: template._id, staticClass: "template-list-item" },
                    [
                      _c("template", { slot: "main" }, [
                        _c(
                          "h6",
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(template.name) +
                                " \n            "
                            ),
                            !template.user
                              ? _c("b-badge", [
                                  _vm._v("\n              System\n            ")
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(template.description) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "template",
                        { slot: "actions" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "icon-button",
                              attrs: {
                                variant: "link",
                                size: "sm",
                                squared: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.selectTemplateConfirm(template._id)
                                }
                              }
                            },
                            [_c("IconImport"), _vm._v(" Import\n          ")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                }),
                1
              )
            : _c(
                "div",
                { staticClass: "action-notification" },
                [
                  _c(
                    "b-alert",
                    { attrs: { show: "", variant: "danger" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "icon-button",
                          attrs: { variant: "primary", size: "sm" },
                          on: {
                            click: function($event) {
                              _vm.selectedTemplateId = null
                              _vm.selectedTemplateHighestAssignedPhase = null
                            }
                          }
                        },
                        [
                          _c("b-icon", { attrs: { icon: "arrow-left" } }),
                          _vm._v(" Choose A Different Template\n        ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _c(
                            "b-avatar",
                            { attrs: { size: "sm" } },
                            [
                              _c("b-icon", {
                                attrs: { icon: "exclamation", size: "is-small" }
                              })
                            ],
                            1
                          ),
                          _vm._v(
                            "\n          You have selected a template that contains questions assigned to phases not available in the current project. You have 3 options:\n        "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", [
                          _c("strong", [_vm._v("Cancel")]),
                          _vm._v(
                            " and update your project to use " +
                              _vm._s(_vm.selectedTemplateHighestAssignedPhase) +
                              " phases"
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("strong", [_vm._v("Continue")]),
                          _vm._v(
                            " the import, then manually update any questions highlighted in red to a valid phase"
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("strong", [_vm._v("Continue With Update")]),
                          _vm._v(
                            " will import and automatically update questions with an invalid phase to the highest phase in the project"
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }