import { cloneDeep } from 'lodash-es'

export const state = () => ({
  activeTemplate: {},
  templates: [],
  templatesCount: 0,
  templateRecsRetrieved: 0,
  templateOptions: {
    filter: 'all',
    sort: '',
    limit: '100',
    skip: '',
    search: '',
    page: 0
  }
})

export const getters = {
  getActiveTemplate: state => state.activeTemplate,
  getActiveTemplateId: state => state.activeTemplate?._id,
  getActiveTemplatePhaseCount: state => state.activeTemplate?.phaseCount,
  getTemplates: state => state.templates,
  getTemplatesCount: state => state.templatesCount,
  getTemplateOptions: state => state.templateOptions
}

export const actions = {

  // add template
  async addTemplate({ dispatch }, template) {
    try {
      const res = await this.$axios.$post('/template', template)
      return res
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'templates/addTemplate', info: err },
        { root: true })
    }
  },

  async applyTemplateToProject({ dispatch }, { templateId, projectId, updatePhase = false }) {
    try {
      const res = await this.$axios.$post(`/template/project/${templateId}`, { projectId, updatePhase })
      if (res) {
        await dispatch('projects/setActiveProjectId', projectId, { root: true })
      }
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'templates/applyTemplateToProject', info: err },
        { root: true })
    }
  },

  // list templates
  async fetchTemplateList({ commit, state, dispatch }) {
    const {
      filter,
      sort,
      limit,
      page,
      search
    } = state.templateOptions
    const skip = page * limit
    const params = [
      filter ? `filter=${filter}` : '',
      sort ? `sortby=${sort}` : '',
      limit ? `limit=${limit}` : '',
      skip ? `skip=${skip}` : '',
      search ? `search=${search}` : ''
    ]
    try {
      const res = await this.$axios.$get('/templates?' + params.filter(p => p).join('&'))
      commit('setTemplates', res.templates)
      commit('setTemplatesCount', res.count)
      return res
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'templates/fetchTemplateList', info: err },
        { root: true })
    }
  },

  // fetch template by id
  async fetchTemplateById({ commit, dispatch }, templateId) {
    try {
      const template = await this.$axios.$get(`/template/${templateId}`)
      commit('setActiveTemplate', template)
      return template
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'templates/fetchTemplateById', info: err },
        { root: true })
    }
  },

  async cloneActiveTemplate({ dispatch, state }) {
    try {
      const template = cloneDeep(state.activeTemplate)
      const name = `${template.name} (Copy)`
      const clone = await this.$axios.$post(`/template/clone/${template._id}`, { name })
      return clone
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'templates/cloneActiveTemplate', info: err },
        { root: true })
    }
  },

  async updateTemplateById({ dispatch }, { template, id }) {
    try {
      const updatedTemplate = await this.$axios.$put(`/template/${id}`, template)
      return updatedTemplate
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'templates/updateTemplateById', info: err },
        { root: true })
    }
  },

  async deleteTemplateById({ dispatch }, id) {
    try {
      const res = await this.$axios.$delete(`/template/${id}`)
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'templates/deleteTemplateById', info: err },
        { root: true })
    }
  },

  async getHighestPhaseForTemplateQuestions({ dispatch }, id) {
    try {
      const res = await this.$axios.$get(`/template/max/phase/${encodeURIComponent(id)}`)
      return res.maxPhase
    } catch (err) {
      return dispatch(
        'global/setNetworkErr',
        { method: 'templates/getHighestPhaseForTemplateQuestions', info: err },
        { root: true })
    }
  },

  setActiveTemplate({ commit }, template) {
    commit('setActiveTemplate', template)
  },

  setTemplateListFilter({ commit }, filter) {
    commit('setTemplateListFilter', filter)
  },

  setTemplateListSort({ commit }, order) {
    commit('setTemplateListSort', order)
  },

  setTemplateListLimit({ commit }, limit) {
    commit('setTemplateListLimit', limit)
  },

  setTemplateListSkip({ commit }, skip) {
    commit('setTemplateListSkip', skip)
  },

  incrementTemplateListPage({ commit }) {
    commit('setTemplateListPage')
  },

  setTemplateListSearchTerm({ commit }, search) {
    commit('setTemplateListSearchTerm', search)
  }
}

export const mutations = {

  init(state) {
    state.activeTemplate = {}
    state.templates = []
    state.templatesCount = 0
    state.templateRecsRetrieved = 0
    state.templateOptions = {
      filter: 'all',
      sort: '',
      limit: '100',
      skip: '',
      search: '',
      page: 0
    }
  },

  setActiveTemplate(state, template) {
    state.activeTemplate = template
  },

  setTemplates(state, templates) {
    if (state.templateOptions.page > 0) {
      const newRecsRetrieved = state.templateRecsRetrieved + templates.length
      if (newRecsRetrieved <= state.templatesCount) {
        state.templates.push(...templates)
        state.templateRecsRetrieved = newRecsRetrieved
      }
    } else {
      state.templates = templates
      state.templateRecsRetrieved = templates.length
    }
  },

  setTemplatesCount(state, count) {
    state.templatesCount = count
  },

  setTemplateListFilter(state, filter) {
    state.templateOptions.filter = filter
  },

  setTemplateListSort(state, sort) {
    state.templateOptions.page = 0
    state.templateOptions.sort = sort
  },

  setTemplateListLimit(state, limit) {
    state.templateOptions.limit = limit
  },

  setTemplateListSkip(state, skip) {
    state.templateOptions.skip = skip
  },

  setTemplateListPage(state, page) {
    if (page || page === 0) {
      state.templateOptions.page = page
    } else {
      const incPage = state.templateOptions.page + 1
      const skippedRecs = incPage * state.templateOptions.limit
      if (state.templatesCount && skippedRecs < state.templatesCount) {
        state.templateOptions.page++
      }
    }
  },

  setTemplateListSearchTerm(state, search) {
    state.templateOptions.page = 0
    state.templateOptions.search = search
  }
}
