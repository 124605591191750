var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-license-unavailable",
        "header-bg-variant": "projects",
        scrollable: ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function() {
            return [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.formattedLicenseType) +
                  " License Unavailable\n  "
              )
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function() {
            return [
              _vm.displaySalesCTA
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "projects" },
                      on: {
                        click: function($event) {
                          return _vm.contactSales()
                        }
                      }
                    },
                    [_vm._v("\n      Contact Sales\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "templates" },
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-license-unavailable")
                    }
                  }
                },
                [_vm._v("\n      Close\n    ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c("div", [
        _c("p", [
          _vm._v(
            "All " +
              _vm._s(_vm.formattedLicenseType) +
              " licenses for your Organization have been consumed."
          )
        ]),
        _vm._v(" "),
        _vm.displaySalesCTA
          ? _c("p", [
              _vm._v(
                "\n      Contact the Sales team to purchase additional licenses.\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.licenseType === "user" && (_vm.isListAdmin || _vm.isSuperAdmin)
          ? _c("div", [
              _c("p", [
                _vm._v("You cannot save this user record until you either:")
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [_vm._v("Remove the organization from this user.")]),
                _vm._v(" -OR-\n        "),
                _c("li", [
                  _vm._v(
                    "Remove the Project Owner role from this user (Participants only role does not consume user license)."
                  )
                ]),
                _vm._v(" -OR-\n        "),
                _c("li", [
                  _vm._v(
                    "Increase the User license count for the Organization you are adding the user to."
                  )
                ]),
                _vm._v("  -OR-\n        "),
                _c("li", [
                  _vm._v(
                    "Choose a different organization that has available licenses."
                  )
                ])
              ])
            ])
          : _vm.licenseType === "rfi" && (_vm.isListAdmin || _vm.isSuperAdmin)
          ? _c("div", [
              _c("p", [
                _vm._v(
                  "\n        Users belonging to this organization will not be allowed to create Projects until additional licenses\n        are purchased and the Project limit is updated in the Organization admin page.\n      "
                )
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }