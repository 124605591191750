import { uppercaseFirst } from '@/server/utils/utils-string'
import { isAnswered, validLoisValue } from '@/server/commonwithclient'

export const state = () => ({
  activeAnswer: {},
  answers: [],
  answerCount: 0
})

export const getters = {
  getAnswers: state => state.answers,
  getActiveAnswer: state => state.activeAnswer,
  getActiveAnswerIndex: state => state.activeAnswerIndex,
  getAnswerCount: state => state.answerCount,
  getAnswerByQuestion: state => id => state.answers.find(a => String(a.question) === id) || {},
  getLastAnswerByQuestion: state => id => state.answers.reduce((acc, a) => {
    if (String(a.question) === id) {
      acc = !a.answerUpdates?.length ? a : a.answerUpdates[a.answerUpdates.length - 1]
    }
    return acc
  }, {}),
  getIsQuestionAnswered: (state, getters) => (id) => {
    const answer = getters.getFormattedAnswerByQuestion(id)
    const isQuestionAnswered = isAnswered(answer)
    return isQuestionAnswered
  },
  getFormattedAnswerByQuestion: (state, getters) => (id, answerParam = null) => {
    const answer = id ? getters.getLastAnswerByQuestion(id) : answerParam
    if (!Object.keys(answer).length) {
      return null
    }
    const answerVal = isAnswered(answer.value)
      ? answer.value
      : answer.display
    if (isAnswered(answerVal)) {
      return isNaN(answerVal) ? uppercaseFirst(answerVal) : answerVal
    }
    const isValidLoisAnswer = validLoisValue(answer.loisValue)
    return Array.isArray(isValidLoisAnswer)
      ? isValidLoisAnswer.join(', ')
      : isValidLoisAnswer
  }
}

export const actions = {
  async addAnswer({ commit, dispatch }, { projectId, propertyId, questionId, answerData }) {
    try {
      const { result, answer, counts } = await this.$axios.$post(`/answer/${projectId}/${propertyId}/${questionId}`, { answerData })
      if (result === 'ok') {
        commit('addAnswer', answer)
        dispatch('properties/setActivePropertyAnswerCountResult', { counts }, { root: true })
        dispatch('properties/setActiveParticipantPropertyAnswers', answer._id, { root: true })
        return answer._id
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/addAnswer', info: err },
        { root: true })
    }
  },

  async addQuestionnaireAnswer({ commit, dispatch }, { questionnaireId, questionId, answerData }) {
    try {
      const { result, answer } = await this.$axios.$post(`/answer/questionnaire/${questionnaireId}/${questionId}`, { answerData })
      if (result === 'ok') {
        commit('addAnswer', answer)
        return answer._id
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/addQuestionnaireAnswer', info: err },
        { root: true })
    }
  },

  async deleteAnswer({ commit, dispatch }, { projectId, answerId, propertyId, questionId }) {
    try {
      if (answerId) {
        const { result, counts } = await this.$axios.$delete(`/answer/${projectId}/${answerId}`)
        if (result === 'ok') {
          commit('deleteAnswer', answerId)
          dispatch('properties/setActivePropertyAnswerCountResult', { counts }, { root: true })
          dispatch('properties/setActiveParticipantPropertyAnswersDelete', answerId, { root: true })
        }
        return result === 'ok'
      }
      commit('deleteUnsavedAnswer', { propertyId, questionId })
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/deleteAnswer', info: err },
        { root: true })
    }
  },

  async deleteQuestionnaireAnswer({ commit, dispatch }, { questionnaireId, propertyId, answerId, questionId }) {
    try {
      if (answerId) {
        const { result } = await this.$axios.$delete(`/answer/questionnaire/${questionnaireId}/${answerId}`)
        if (result === 'ok') {
          commit('deleteAnswer', answerId)
        }
        return result === 'ok'
      }
      commit('deleteUnsavedAnswer', { propertyId, questionId })
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/deleteQuestionnaireAnswer', info: err },
        { root: true })
    }
  },

  initializeAnswers({ commit, state }) {
    if (state.answers.length) {
      commit('init')
    }
  },

  async fetchAnswersForProperty({ commit, dispatch }, { projectId, propertyId }) {
    try {
      const { answers, count } = await this.$axios.$get(`/answers/${projectId}/${propertyId}`)
      commit('setAnswers', answers)
      commit('setAnswerCount', count)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/fetchAnswersForProperty', info: err },
        { root: true })
    }
  },

  async fetchAnswersForQuestionnaire({ commit, dispatch }, { questionnaireId }) {
    try {
      const { answers } = await this.$axios.$get(`/answers/questionnaire/${questionnaireId}`)
      commit('setAnswers', answers)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/fetchAnswersForQuestionnaire', info: err },
        { root: true })
    }
  },

  async fetchQuestionnaireAnswerById({ dispatch }, { questionnaireId, answerId }) {
    try {
      const answer = await this.$axios.$get(`/answer/questionnaire/${questionnaireId}/${answerId}`)
      if (answer) {
        return answer
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/fetchQuestionnaireAnswerById', info: err },
        { root: true })
    }
  },

  setActiveAnswer({ commit, state }, { propertyId, questionId }) {
    const pId = String(propertyId)
    const qId = String(questionId)
    let answer = state.answers?.find(
      a => String(a.property) === String(pId) && String(a.question) === String(qId)
    )
    if (!answer) {
      answer = {
        property: pId,
        question: qId,
        override: false
      }
      commit('updateActiveAnswer', answer)
      return answer
    }
    commit('setActiveAnswer', answer)
    return answer
  },

  updateActiveAnswer({ commit }, answer) {
    commit('updateActiveAnswer', answer)
  },

  async updateAnswer({ commit, dispatch }, { projectId, propertyId, answer: ans }) {
    try {
      const { result = null, answer, counts } = await this.$axios
        .$put(`/answer/${projectId}/${propertyId}/${ans._id}`, { answer: ans })
      if (result === 'ok') {
        commit('updateAnswer', answer)
        dispatch('properties/setActivePropertyAnswerCountResult', { counts }, { root: true })
        dispatch('properties/setActiveParticipantPropertyAnswersDelete', answer._id, { root: true })
        return true
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/updateAnswer', info: err },
        { root: true })
    }
  },

  async updateQuestionnaireAnswer({ commit, dispatch }, { questionnaireId, answer: ans }) {
    try {
      const { result = null, answer } = await this.$axios
        .$put(`/answer/questionnaire/${questionnaireId}/${ans._id}`, { answer: ans })
      if (result === 'ok') {
        commit('updateAnswer', answer)
        return true
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/updateQuestionnaireAnswer', info: err },
        { root: true })
    }
  },

  async addAnswerUpdate({ commit, dispatch }, { projectId, propertyId, answer: ans }) {
    try {
      const { result = null, answer, counts } = await this.$axios
        .$put(`/answer/update/${projectId}/${propertyId}/${ans._id}`, { answer: ans })
      if (result === 'ok') {
        commit('updateAnswer', answer)
        dispatch('properties/setActivePropertyAnswerCountResult', { counts }, { root: true })
        dispatch('properties/setActiveParticipantPropertyAnswersDelete', answer._id, { root: true })
        return true
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/addAnswerUpdate', info: err },
        { root: true })
    }
  },

  async recallAnswer({ dispatch }, { projectId, propertyId, questionTag, questionId }) {
    try {
      const { result = null, answersRecalled = 0, counts } = await this.$axios
        .$post(`/answer/recall/${projectId}/${propertyId}`, { questionTag, questionId })
      if (result === 'ok') {
        dispatch('properties/setActivePropertyAnswerCountResult', { counts }, { root: true })
      }
      return answersRecalled
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/recallAnswer', info: err },
        { root: true })
    }
  },

  async recallAnswers({ dispatch }, { projectId, propertyId }) {
    try {
      const { result = null, answersRecalled = 0, counts } = await this.$axios
        .$post(`/answers/recall/${projectId}/${propertyId}`)
      if (result === 'ok') {
        dispatch('properties/setActivePropertyAnswerCountResult', { counts }, { root: true })
        await dispatch('fetchAnswersForProperty', { projectId, propertyId })
      }
      return answersRecalled
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/recallAnswers', info: err },
        { root: true })
    }
  },

  async updateAnswers({ state, dispatch }, { projectId, propertyId }) {
    try {
      const answers = state.answers
      const { added, updated, answerIds = [], counts } = await this.$axios.$put(`/answers/${projectId}/${propertyId}`, { answers })
      if (added || updated) {
        dispatch('properties/setActivePropertyAnswerCountResult', { counts }, { root: true })
        return answerIds
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'answers/updateAnswers', info: err },
        { root: true })
    }
  }
}

export const mutations = {
  addAnswer(state, answer) {
    state.answers.push(answer)
    state.answerCount++
  },

  deleteAnswer(state, answerid) {
    const deletedIdx = state.answers.findIndex(a => String(a._id) === String(answerid))
    state.answers.splice(deletedIdx, 1)
    state.answerCount--
  },

  deleteUnsavedAnswer(state, { propertyId, questionId }) {
    const delIdx = state.answers.findIndex(a => (
      String(a.question) === String(questionId) &&
      String(a.property) === String(propertyId))
    )
    if (delIdx > -1) {
      state.answers.splice(delIdx, 1)
      state.answerCount--
    }
  },

  init(state) {
    state.answers = []
    state.activeAnswer = {}
    state.answerCount = 0
  },

  setActiveAnswer(state, answer) {
    state.activeAnswer = answer
  },

  setAnswers(state, answers) {
    state.answers = [...(answers || [])]
    state.answerCount = state.answers.length
  },

  setAnswerCount(state, count) {
    state.answerCount = count
  },

  // updating the answers array without updating the activeAnswer
  updateAnswer(state, answer) {
    const updatedIdx = state.answers.findIndex(a => (
      String(a.question) === String(answer.question) &&
      String(a.property) === String(answer.property))
    )
    if (updatedIdx > -1) {
      state.answers.splice(updatedIdx, 1, answer)
    } else {
      state.answers.push(answer)
      state.answerCount++
    }
  },

  // when updating the active answer, make sure to pass in a NEW object to be
  // inserted into the array and set to the active answer, so it's swap is detected
  // by watchers in the rest of the app
  updateActiveAnswer(state, answer) {
    const updatedIdx = state.answers.findIndex(a => (
      String(a.question) === String(answer.question) &&
      String(a.property) === String(answer.property))
    )
    if (updatedIdx > -1) {
      state.answers.splice(updatedIdx, 1, answer)
      state.activeAnswer = state.answers[updatedIdx]
    } else {
      state.answers.push(answer)
      state.activeAnswer = state.answers[state.answers.length - 1]
      state.answerCount++
    }
  }
}
