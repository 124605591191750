//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { requirementOperatorName } from '@/server/constants'

export default {
  name: 'RequirementOperatorValue',
  props: {
    requirement: {
      type: Object,
      default: () => {}
    },
    display: {
      type: [Object, Array],
      default: () => {}
    }
  },
  data() {
    return {
      reqValues: []
    }
  },
  computed: {
    requirementOperator() {
      return requirementOperatorName[this.requirement.operator]
    },
    valuesDisplay() {
      if (this.display && this.reqValues && this.reqValues.length) {
        const displayValues = this.reqValues.map(v => `${v}`)
        // .map(v => `${v} = ${this.findKey(v)}`)
        if (displayValues && displayValues.length) {
          return displayValues.join(', ')
        }
      }
      return this.requirement.values?.filter(Boolean).join(', ')
    }
  },
  mounted() {
    this.reqValues = [...this.requirement.values]
  },
  methods: {
    findKey(val) {
      for (const [key, value] of Object.entries(this.display)) {
        if (val === value) {
          return key
        }
      }
      return ''
    }
  }
}
