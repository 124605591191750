/**
 * Exposes the current CSS @media breakpoint to JavaScript.
 *
 * Your components should use the `mapGetters` vuex utility to understand the current breakpoint.
 *
 * There is a getter for each media breakpoint. Map only the ones you need.
 *
 * Example:
 *
 * <template>
 *   <div>
 *     <p v-if="mobile">Your Browser is in Mobile Layout!</p>
 *     <p v-if="desktop">Your Browser is in Desktop Layout!</p>
 *     <div class="my-thingy" :class="{'my-thingy--desktop': desktop}">
 *       <p>...Imagine more complex HTML inside .my-thingy</p>
 *     </div>
 *   </div>
 * </template>
 * <script>
 *   import { mapGetters } from 'vuex';
 *
 *   export default {
 *     // ...
 *     computed: {
 *       ...mapGetters({
 *         mobile: types.breakpoint.mobile,
 *         desktop: types.breakpoint.desktop
 *       })
 *     }
 *   }
 * </script>
 */

import { debounce } from 'lodash-es'

// import breakpoints from '../assets/css/_variables.scss'
// import breakpoints from '@/assets/styles/exports/.scss'
// const bpDesktop = parseInt(breakpoints.desktop)

const bpDesktop = 992
// const bpDesktop = 768

export const state = () => ({
  currentHeight: null,
  currentWidth: null
})

export const getters = {
  desktop: ({ currentWidth }) => {
    return currentWidth > bpDesktop
  },
  mobile: ({ currentWidth }) => {
    return currentWidth <= bpDesktop
  }
}

export const actions = {
  watch ({ commit }) {
    commit('watch')
    window.onresize = debounce(() => {
      commit('watch')
    }, 100)
  }
}

export const mutations = {
  watch (state) {
    state.currentHeight = getBrowserHeight()
    state.currentWidth = getBrowserWidth()
  }
}

function getBrowserHeight () {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
}

function getBrowserWidth () {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
}
