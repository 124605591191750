import { render, staticRenderFns } from "./IconTrashEmpty.vue?vue&type=template&id=65487c3e&"
var script = {}
import style0 from "./IconTrashEmpty.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65487c3e')) {
      api.createRecord('65487c3e', component.options)
    } else {
      api.reload('65487c3e', component.options)
    }
    module.hot.accept("./IconTrashEmpty.vue?vue&type=template&id=65487c3e&", function () {
      api.rerender('65487c3e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/icons/IconTrashEmpty.vue"
export default component.exports