//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { userRoles } from '@/server/constants'

const userRolesAll = userRoles.participant + userRoles.projectowner + userRoles.admin

export default {
  name: 'NavMobile',
  data () {
    return {
      mobileNavItemsLoggedIn: [
        { page: 'dashboard', security: userRolesAll },
        { page: 'inbox', security: userRolesAll },
        { page: 'projects', security: userRolesAll },
        // { page: 'questionnaires', security: userRoles.participant },
        { page: 'templates', security: userRoles.projectowner },
        { page: 'reports', security: userRoles.projectowner },
        { page: 'admin', security: userRoles.admin }
      ]
    }
  },
  computed: {
    ...mapGetters({
      fullName: 'user/fullName',
      impersonationUserId: 'user/getImpersonationUserId',
      isCollaborator: 'user/isCollaborator',
      isAdmin: 'user/isAdmin',
      isReallyASuperAdmin: 'user/isReallyASuperAdmin',
      loggedInUser: 'user/loggedInUser',
      navOpen: 'global/navOpen',
      user: 'user/user'
    }),
    showStopImpersonation() {
      return (this.impersonationUserId && !this.isCollaborator) || (this.isAdmin || this.isReallyASuperAdmin)
    },
    userEmail() {
      return this.user?.email
    }
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
      logoutImpersonation: 'user/logoutImpersonatedUser',
      toggleMenu: 'global/toggleMenu'
    }),
    getOffsetTop(el) {
      let offsetTop = 0
      while (el) {
        offsetTop += el.offsetTop
        el = el.offsetParent
      }
      return offsetTop
    },
    goToLink(url) {
      window.open(url, '_blank')
    },
    goToSection(section) {
      this.toggleMenu([])
      if (this.$route.name !== 'index') {
        this.$router.push('/')
      } else {
        this.$nextTick(() => {
          const el = document.getElementById(section)
          const offset = '70'
          window.scrollTo(el.offsetLeft, this.getOffsetTop(el) - offset)
        })
      }
    },
    async logOutImpersonatedUser() {
      this.$router.push('/projects')
      await new Promise((resolve) => {
        setTimeout(async () => {
          await this.logoutImpersonation()
          // It might be better to redirect to the /admin or /dashboard
          // and support a imp=logout or something like this
          this.$router.push('/admin')
          return resolve()
        }, 200)
      })
    },
    async logMeOut() {
      await this.logout()
    },
    openAuthModal(modalType) {
      this.toggleMenu([])
      this.$emit('handleLoginRegisterClick', modalType)
      this.$bvModal.show('modal-auth')
    },
    requestDemo() {
      this.toggleMenu([])
      this.$router.push({ name: 'support', params: { referral: 'demo' } })
    }
  }
}
