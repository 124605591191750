//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Breadcrumb',
  props: {
    label: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      crumbs: []
    }
  },
  computed: {
  },
  methods: {
    updateCrumbs() {
      const breadcrumbs = []
      const path = this.$route.path.split('/')
      let urlPath = '/'
      if (path[0] === '') {
        path.shift()
      }
      if (path[path.length - 1] === '') {
        path.pop()
      }
      path.forEach((p, idx) => {
        urlPath += path[idx] + '/'
        breadcrumbs.push(Object.assign({}, { name: p, url: urlPath }))
      })
      this.crumbs = breadcrumbs
    }
  }
}
