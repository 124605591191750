var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "error" },
    [
      _c(
        "b-col",
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", fade: "", show: "", dismissible: "" },
              on: { dismissed: _vm.resetError }
            },
            [
              _c("div", [
                _c("strong", [_vm._v(_vm._s(_vm.friendlyError))]),
                _c("br"),
                _vm._v(" "),
                _c("strong", [_vm._v("Code:")]),
                _vm._v(" " + _vm._s(_vm.displayedErrorStatus) + " | "),
                _c("strong", [_vm._v("Method:")]),
                _vm._v(" " + _vm._s(_vm.displayedErrorMethod) + " | "),
                _c("strong", [_vm._v("Message:")]),
                _vm._v(" " + _vm._s(_vm.displayedErrorMessage) + "\n        "),
                _c("br"),
                _vm._v(" "),
                _vm.showStackTrace
                  ? _c("strong", [_vm._v("Stack Trace:")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showStackTrace
                  ? _c("div", { staticClass: "error-stack-trace" }, [
                      _c("pre", [_vm._v(_vm._s(_vm.stack))])
                    ])
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }