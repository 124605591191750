export const state = () => ({
  zpSyncStatus: '',
  zpSyncError: '',
  zpSyncServerStatus: '',
  zpInterval: null,
  zpStarting: 0
})

export const getters = {
  getZpSyncStatus: state => state.zpSyncStatus,
  getZpSyncError: state => state.zpSyncError,
  getZpSyncServerStatus: state => state.zpSyncServerStatus,
  getZpInterval: state => state.zpInterval,
  getZpStarting: state => state.zpStarting
}

export const actions = {

  // Event: implemented in _projectid/details in finishProject() function
  // Verify: came back with 204 and not finished on the submitted properties

  // When any participant marks their project (RFP) completed
  async participantProjectStatusCompleted({ dispatch }, { participantId }) {
    try {
      const res = await this.$axios.$post(
        `/events/participant/${participantId}/completed`
      )
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/participantProjectStatusCompleted', info: err },
        { root: true }
      )
    }
  },

  // When a delegator marks a participant's project status approved/completed
  async delegatorParticipantProjectStatusCompleted({ dispatch }, { participantId }) {
    try {
      const res = await this.$axios.$post(
        `/events/participant/${participantId}/completed`
      )
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/delegatorParticipantProjectStatusCompleted', info: err },
        { root: true }
      )
    }
  },

  // Event: implemented in acceptProject() of ProjectListItem
  // Verify: original function called participants/setProjectParticipationForParticipant

  // When a participant accepts an invitation to a project
  async participantProjectStatusWorking({ dispatch }, { participantId }) {
    try {
      const res = await this.$axios.$put(
        `/events/participant/${participantId}/status/working`
      )
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/participantProjectStatusWorking', info: err },
        { root: true }
      )
    }
  },

  // Event: implemented in rejectProject() of ProjectListItem
  // Verify: original function called participants/setProjectParticipationForParticipant

  // When a participant rejects an invitation to a project
  async participantProjectStatusRejected({ dispatch }, { participantId }) {
    try {
      const res = await this.$axios.$put(
        `/events/participant/${participantId}/status/rejected`
      )
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/participantProjectStatusRejected', info: err },
        { root: true }
      )
    }
  },

  // When a participant changes the status of a property to finished
  async participantPropertyStatusFinished({ dispatch }, { participantId, propertyId }) {
    try {
      const res = await this.$axios.$put(
        `/events/participant/${participantId}/property/finished/${propertyId}`
      )
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/participantPropertyStatusFinished', info: err },
        { root: true }
      )
    }
  },

  // We have to track and notify the project owner if a participant changes the answer
  // to a question during the project review process
  async participantEditedQuestionDuringProjectReview({ dispatch }, { participantId, propertyId, questionId }) {
    try {
      const res = await this.$axios.$put(
        `/events/participant/${participantId}/edited/question/${questionId}`,
        { propertyId }
      )
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/participantEditedQuestionDuringProjectReview', info: err },
        { root: true }
      )
    }
  },

  async projectOwnerProjectPhaseIncrement({ dispatch }, { projectId }) {
    try {
      const res = await this.$axios.$put(
        `/events/projectowner/project/phase/increment/${projectId}`
      )
      return { success: res.result === 'ok', newPhase: res.newPhase }
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/projectOwnerProjectPhaseIncrement', info: err },
        { root: true }
      )
    }
  },

  // Event: implemented in _propertyid/index page in the eliminateProperty() function
  // When a project owner or delegator eliminates a property during the working phase
  // of the project
  async participantPropertyStatusEliminated({ dispatch }, { propertyId }) {
    try {
      const res = await this.$axios.$put(
        `/events/participant/property/eliminated/${propertyId}`
      )
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/participantPropertyStatusEliminated', info: err },
        { root: true }
      )
    }
  },

  // When a project owner or delegator eliminates a participant/delegate from a project
  async participantProjectStatusEliminated({ dispatch }, { participantId }) {
    try {
      const res = await this.$axios.$put(
        `/events/participant/${participantId}/status/eliminated`
      )
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/participantProjectStatusEliminated', info: err },
        { root: true }
      )
    }
  },

  // Event: implemented in AddEditProject in multiple functions: activateProject(), pauseProject(), reactivateProject(), reviewProject(), closeProject()
  // Verify: original function called projects/updateProjectStatus (note: this is still used for archive)

  // Status parameter should be set to 'active', 'paused', 'reactivate', 'review', or 'closed'
  async projectOwnerPropertyStatusChange({ dispatch }, { projectId, status }) {
    try {
      const res = await this.$axios.$put(
        `/events/projectowner/project/${status}/${projectId}`
      )
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/projectOwnerPropertyStatusChange', info: err },
        { root: true }
      )
    }
  },

  async supportRequest({ dispatch }, { user, token, reason, description }) {
    try {
      const res = await this.$axios.$post('/support/request', { user, token, reason, description })
      if (res.result === 'ok') {
        return true
      }
      return res.data
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/supportRequest', info: err },
        { root: true }
      )
    }
  },

  async resendInvitation({ dispatch }, { participantId, projectId }) {
    try {
      const res = await this.$axios.$put(`/events/projectowner/${participantId}/resend/invite/${projectId}`)
      if (res.result === 'ok') {
        return true
      }
      return res.data
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/resendInvite', info: err },
        { root: true }
      )
    }
  },

  async syncZpiProperties({ dispatch, commit }, { loisOrganizationId, clientId }) {
    try {
      commit('setZpSyncStatus', null)
      commit('setZpSyncError', null)
      commit('setZpInterval', null)
      const res = await this.$axios.$post('/events/sync/zp/properties', { loisOrganizationId })
      commit('setZpSyncServerStatus', res.results)
      if (res.results === 'ok' || res.results === 'continue') {
        commit('setZpStarting', 1)
        const hInterval = setInterval(() => dispatch('syncZpiStatusCheck', { loisOrganizationId }), 5000)
        commit('setZpSyncStatus', 'Import started, updating every 5 seconds...')
        commit('setZpSyncError', null)
        commit('setZpInterval', hInterval)
      } else {
        commit('setZpSyncStatus', 'An error occurred. Please try again later...')
        commit('setZpSyncError', res.message)
      }
      return res
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/syncZoomProspectorProperties', info: err },
        { root: true }
      )
    }
  },

  async syncZpiStatusCheck({ dispatch, commit, state }, { loisOrganizationId }) {
    function format({ message, progress }) {
      const firstLetter = message.charAt(0).toUpperCase()
      const remains = message.slice(1)
      return `${firstLetter}${remains}:  ${progress}% complete`
    }
    // We wait 10 seconds before clearing the status so the user can see the
    // message. However, if we don't stop execution, we have a null reply
    function clearZpiStatus(resolve) {
      setTimeout(() => {
        dispatch('clearZpiSyncStatus')
        return resolve()
      }, 10000)
    }
    try {
      const { log = { status: 'noop' } } = await this.$axios.$get(`/events/sync/zp/status/${loisOrganizationId}`)
      commit('setZpSyncServerStatus', log.status)
      if (log.status === 'finished') {
        if (state.zpStarting > 0 && state.zpStarting < 10) {
          commit('setZpStarting', state.zpStarting + 1)
          return log
        }
        clearInterval(state.zpInterval)
        commit('setZpSyncStatus', format(log))
        commit('setZpSyncError', null)
        await new Promise(clearZpiStatus)
      } else if (log.status === 'error' || log.status === 'crashed') {
        if (state.zpStarting > 0 && state.zpStarting < 10) {
          commit('setZpStarting', state.zpStarting + 1)
          return log
        }
        clearInterval(state.zpInterval)
        commit('setZpSyncStatus', 'An error occurred. Please try again later...')
        commit('setZpSyncError', log.message)
        await new Promise(clearZpiStatus)
      } else if (log.status === 'noop') {
        commit('setZpSyncStatus', 'Import started, updating every 5 seconds...')
      } else {
        commit('setZpSyncStatus', format(log))
        commit('setZpSyncError', null)
      }
      commit('setZpStarting', 0)
      return log
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'events/syncZpiStatusCheck', info: err },
        { root: true }
      )
    }
  },

  clearZpiSyncStatus({ commit }) {
    commit('setZpSyncStatus', null)
    commit('setZpSyncError', null)
    commit('setZpSyncServerStatus', '')
  }
}

export const mutations = {
  setZpSyncStatus(state, status) {
    state.zpSyncStatus = status
  },
  setZpInterval(state, interval) {
    state.zpInterval = interval
  },
  setZpSyncError(state, error) {
    state.zpSyncError = error
  },
  setZpSyncServerStatus(state, status) {
    state.zpSyncServerStatus = status
  },
  setZpStarting(state, starting) {
    state.zpStarting = starting
  }
}
