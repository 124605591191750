import { projectStatus, projectStatusName } from '@/server/constants'

export const state = () => ({
  activeProject: {},
  activeProjectId: null,
  activeParticipantId: '',
  activeProjectDelegators: [],
  delegatorDelegates: [],
  projectList: [],
  projectListCount: 0,
  projectRecsRetrieved: 0,
  projectOptions: {
    filter: '',
    sort: '',
    limit: '10',
    skip: '',
    search: '',
    searchField: '',
    page: 0
  },
  participantUserList: [],
  participantOrgList: []
})

export const getters = {
  getActiveProjectId: state => state.activeProjectId,
  getActiveProject: state => state.activeProject,
  getActiveProjectDelegators: state => state.activeProjectDelegators,
  getActiveProjectPhase: state => state.activeProject?.activePhase,
  getActiveProjectPhaseCount: state => state.activeProject?.phaseCount,
  getFieldSort: state => state.projectOptions.sort,
  getDelegatorDelegates: state => state.delegatorDelegates,
  getProjectList: state => state.projectList,
  getProjectListCount: state => state.projectListCount,
  getProjectListFilter: state => state.projectOptions.filter,
  getProjectOptions: state => state.projectOptions,
  getProjectStatusName: () => code => projectStatusName[code],
  // getProjectIsActive: (state) => {
  //   return [projectStatus.active, projectStatus.review].includes(state.activeProject?.status)
  // },
  // this getter allows param for instances when active project not set
  getProjectIsActive: state => (status) => {
    if (status) {
      return [projectStatus.active, projectStatus.review].includes(status)
    } else if (state.activeProject?.status) {
      return [projectStatus.active, projectStatus.review].includes(state.activeProject?.status)
    } else {
      return false
    }
  },
  getParticipantUserList: state => state.participantUserList,
  getParticipantOrgList: state => state.participantOrgList
}

export const actions = {

  // add project
  async addProject({ commit, dispatch }, project) {
    try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
      const newProject = await this.$axios.$post('/project', { project, tz })
      if (newProject) {
        commit('setActiveProjectId', newProject._id)
        commit('setActiveProject', newProject)
        return newProject
      }
      return null
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/addProject', info: err },
        { root: true })
    }
  },

  // copy project
  async copyProject({ dispatch }, { projectId, copyAttachments }) {
    try {
      const projectRes = await this.$axios.$post(`/project/clone/${projectId}`, { copyAttachments })
      return projectRes.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/copyProject', info: err },
        { root: true })
    }
  },

  // delete project
  async deleteProject({ dispatch }, id) {
    try {
      const projectRes = await this.$axios.$delete(`/project/${id}`)
      return projectRes.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/deleteProject', info: err },
        { root: true })
    }
  },

  async fetchProjectByIdForReport({ commit, dispatch }, id) {
    try {
      const project = await this.$axios.$get(`/project/for/report/${id}`)
      commit('setActiveProjectId', project._id)
      commit('setActiveProject', project)
      return project
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/fetchProjectByIdForReport', info: err },
        { root: true })
    }
  },

  async fetchProjectById({ dispatch }, id) {
    try {
      const project = await this.$axios.$get(`/project/${id}`)
      return project
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/fetchProjectById', info: err },
        { root: true })
    }
  },

  async fetchProjectList({ state, commit, dispatch }) {
    const {
      filter,
      sort,
      limit,
      page,
      search,
      searchField
    } = state.projectOptions
    const skip = page * limit
    const params = [
      filter || filter === 0 ? `filter=${filter}` : '',
      sort ? `sortby=${sort}` : '',
      limit ? `limit=${limit}` : '',
      skip ? `skip=${skip}` : '',
      search ? `search=${search}` : '',
      searchField ? `searchField=${searchField}` : 'searchField=name'
    ]
    try {
      const { projects, count } = await this.$axios.$get('/projects?' + params.filter(p => p).join('&'))
      commit('setProjectList', projects)
      commit('setProjectListCount', count)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/fetchProjectList', info: err },
        { root: true })
    }
  },

  async fetchActiveProjectDelegators({ commit, dispatch }, { projectId, participantId = null }) {
    try {
      let query = ''
      if (participantId) {
        query = `?participantId=${participantId}`
      }
      const { delegators } = await this.$axios.$get(`/project/delegators/by/participant/${projectId}${query}`)
      commit('setActiveProjectDelegators', delegators)
      return delegators
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/fetchActiveProjectDelegators', info: err },
        { root: true })
    }
  },

  async fetchProjectQuestionCounts({ commit, dispatch }, { projectId }) {
    try {
      const counts = await this.$axios.$get(
        `/project/${projectId}/question/counts`
      )
      commit('setProjectQuestionCounts', counts)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/fetchProjectQuestioncounts', info: err },
        { root: true }
      )
    }
  },

  async fetchProjectPropertyCount({ commit, dispatch }, { projectId }) {
    try {
      const count = await this.$axios.$get(
        `/project/${projectId}/property/count`
      )
      commit('setProjectPropertyCount', count)
      return count
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/fetchProjectProopertyCount', info: err },
        { root: true }
      )
    }
  },

  setActiveProject({ commit }, project) {
    commit('setActiveProject', project)
    commit('setActiveProjectId', project._id)
  },

  async setActiveProjectId({ commit, dispatch }, id) {
    try {
      let project
      if (id) {
        project = await dispatch('fetchProjectById', id)
      } else {
        id = null
        project = {}
      }
      commit('setActiveProjectId', id)
      commit('setActiveProject', project)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/setActiveProjectId call to fetchProjectById', info: err },
        { root: true })
    }
  },

  setProjectListFilter({ commit }, filter) {
    commit('setProjectListFilter', filter)
  },

  setProjectListSort({ commit }, order) {
    commit('setProjectListSort', order)
  },

  setProjectListLimit({ commit }, limit) {
    commit('setProjectListLimit', limit)
  },

  setProjectListSkip({ commit }, skip) {
    commit('setProjectListSkip', skip)
  },

  incrementProjectListPage({ commit }) {
    commit('setProjectListPage')
  },

  setProjectListSearchTerm({ commit }, search) {
    commit('setProjectListSearchTerm', search)
  },

  setProjectListSearchField({ commit }, searchField) {
    commit('setProjectListSearchField', searchField)
  },

  // update project will not update the status
  async updateProjectById({ state, commit, dispatch }, { project, id }) {
    try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
      const projectRes = await this.$axios.$put(`/project/${id}`, { project, tz })
      if (projectRes.result === 'ok') {
        await dispatch('setActiveProjectId', id)
        return true
      }
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/updateProjectById', info: err },
        { root: true })
    }
  },

  // this should be allowed by actual project owners, please use the status
  // string value and not it's numerical value
  async updateProjectStatus({ state, commit, dispatch }, { status, projectId }) {
    try {
      const statusRes = await this.$axios.$post(`/projectstatus/${status}`, { projectId })
      if (statusRes.result === 'ok') {
        return true
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'projects/updateProjectStatus', info: err },
        { root: true }
      )
    }
  }
}

export const mutations = {

  init(state) {
    state.activeProject = {}
    state.activeProjectId = null
    state.delegatorDelegates = []
    state.activeProjectDelegators = []
    state.projectList = []
    state.projectListCount = 0
    state.projectRecsRetrieved = 0
    state.projectOptions = {
      filter: '',
      sort: '',
      limit: '10',
      skip: '',
      search: '',
      searchField: '',
      page: 0
    }
    state.participantUserList = []
    state.participantOrgList = []
  },

  setActiveProject(state, project) {
    state.activeProject = project
  },

  setActiveProjectId(state, id) {
    state.activeProjectId = id
  },

  setActiveProjectQuestionsCount(state, count) {
    state.activeProject.questionCount = count
  },

  setActiveProjectDelegators(state, delegators) {
    state.activeProjectDelegators = delegators
  },

  setProjectList(state, projects) {
    if (state.projectOptions.page > 0) {
      const newRecsRetrieved = state.projectRecsRetrieved + projects.length
      if (newRecsRetrieved <= state.projectListCount) {
        state.projectList.push(...projects)
        state.projectRecsRetrieved = newRecsRetrieved
      }
    } else {
      state.projectList = projects
      state.projectRecsRetrieved = projects.length
    }
  },

  setProjectListCount(state, count) {
    state.projectListCount = count
  },

  setProjectListFilter(state, filter) {
    state.projectOptions.filter = filter
  },

  setProjectListSort(state, sort) {
    state.projectOptions.page = 0
    state.projectOptions.sort = sort
  },

  setProjectListLimit(state, limit) {
    state.projectOptions.limit = limit
  },

  setProjectListSkip(state, skip) {
    state.projectOptions.skip = skip
  },

  setProjectListPage(state, page) {
    if (page || page === 0) {
      state.projectOptions.page = page
    } else {
      const incPage = state.projectOptions.page + 1
      const skippedRecs = incPage * state.projectOptions.limit
      if (state.projectListCount && skippedRecs < state.projectListCount) {
        state.projectOptions.page++
      }
    }
  },

  setProjectListSearchTerm(state, search) {
    state.projectOptions.page = 0
    state.projectOptions.search = search
  },

  setProjectListSearchField(state, searchField) {
    state.projectOptions.page = 0
    state.projectOptions.searchField = searchField
  },

  setProjectQuestionCounts(state, { questionCount, requirementCount, requiredCount }) {
    state.activeProject.questionCount = questionCount
    state.activeProject.requirementCount = requirementCount
    state.activeProject.requiredCount = requiredCount
  },

  setProjectPropertyCount(state, count) {
    state.activeProject.propertyCount = count
  }
}
