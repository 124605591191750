var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    {
      class: [
        "question",
        "answer",
        "owner-view",
        _vm.questionOpen ? "active" : ""
      ]
    },
    [
      _c(
        "b-row",
        { staticClass: "answered-question-row" },
        [
          _c(
            "b-col",
            {
              class: _vm.answerUnseen ? "clickable" : "",
              on: { click: _vm.markRecordAsSeen }
            },
            [
              _c("div", { staticClass: "question-text owner" }, [
                _vm.question.required
                  ? _c(
                      "div",
                      { staticClass: "required-badge" },
                      [
                        _c(
                          "b-badge",
                          { attrs: { variant: "danger", title: "Required" } },
                          [_vm._v("\n            R\n          ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.question.requirement
                  ? _c(
                      "div",
                      { staticClass: "requirement-badge" },
                      [
                        _c(
                          "b-badge",
                          {
                            attrs: {
                              variant: "danger",
                              title: "Project Requirement"
                            }
                          },
                          [_vm._v("\n            Rqmt\n          ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.question.text))])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "answered-question" },
                [
                  _c("not-viewed", { attrs: { visible: !!_vm.answerUnseen } }),
                  _vm._v(" "),
                  _vm.questionIsAnswered
                    ? _c("span", { staticClass: "question-answered" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.formattedAnswer) +
                            "\n        "
                        )
                      ])
                    : _c("span", { staticClass: "question-not-answered" }, [
                        _vm._v("\n          Not Answered\n        ")
                      ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "col-info-actions", attrs: { cols: "3" } },
            [
              _c("div", { staticClass: "col-phase" }, [
                _vm._v("\n        " + _vm._s(_vm.question.phase) + "\n      ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-info" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "icon-button",
                      attrs: { variant: "light", size: "sm" },
                      on: { click: _vm.toggleQuestion }
                    },
                    [
                      !_vm.questionOpen
                        ? _c("b-icon", { attrs: { icon: "dots-horizontal" } })
                        : _c("b-icon", { attrs: { icon: "close" } })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "detail-row" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-collapse",
                {
                  staticClass: "detail-container",
                  attrs: { id: _vm.question._id },
                  model: {
                    value: _vm.questionOpen,
                    callback: function($$v) {
                      _vm.questionOpen = $$v
                    },
                    expression: "questionOpen"
                  }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "detail-section question-detail" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "h4",
                            {
                              attrs: { role: "tab" },
                              on: {
                                click: function($event) {
                                  _vm.detailOpen = !_vm.detailOpen
                                }
                              }
                            },
                            [
                              _c("div", [_vm._v("Question Details")]),
                              _vm._v(" "),
                              !_vm.detailOpen
                                ? _c("b-icon", {
                                    attrs: { icon: "chevron-right" }
                                  })
                                : _c("b-icon", {
                                    attrs: { icon: "chevron-down" }
                                  })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-collapse",
                            {
                              attrs: { role: "tablist" },
                              model: {
                                value: _vm.detailOpen,
                                callback: function($$v) {
                                  _vm.detailOpen = $$v
                                },
                                expression: "detailOpen"
                              }
                            },
                            [
                              _vm.question.tertiaryCategory
                                ? _c("div", [
                                    _c("strong", [_vm._v("Tertiary:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.question.tertiaryCategory) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.question.tag
                                ? _c("div", [
                                    _c("strong", [_vm._v("Tag:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.question.tag) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.question.loisDataField
                                ? _c("div", [
                                    _c("strong", [_vm._v("Data Field Type:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.question.loisDataField) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.answerTypeLabel
                                ? _c("div", [
                                    _c("strong", [_vm._v("Answer Type:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.answerTypeLabel) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.question.requirement
                                ? _c(
                                    "div",
                                    [
                                      _c("strong", [
                                        _vm._v("Requirement Operator:")
                                      ]),
                                      _vm._v(" "),
                                      _c("RequirementOperatorValue", {
                                        attrs: { requirement: _vm.question }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.question.note
                                ? _c("div", [
                                    _c("strong", [_vm._v("Note:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.question.note) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.displayAnswerHistory
                    ? _c(
                        "b-row",
                        { staticClass: "detail-section answer-history" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "h4",
                                {
                                  attrs: { role: "tab" },
                                  on: {
                                    click: function($event) {
                                      _vm.historyOpen = !_vm.historyOpen
                                    }
                                  }
                                },
                                [
                                  _c("strong", [_vm._v("Answer History")]),
                                  _vm._v(" "),
                                  !_vm.historyOpen
                                    ? _c("b-icon", {
                                        attrs: { icon: "chevron-right" }
                                      })
                                    : _c("b-icon", {
                                        attrs: { icon: "chevron-down" }
                                      })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                {
                                  attrs: { role: "tablist" },
                                  model: {
                                    value: _vm.historyOpen,
                                    callback: function($$v) {
                                      _vm.historyOpen = $$v
                                    },
                                    expression: "historyOpen"
                                  }
                                },
                                [
                                  _c("QuestionAnswerHistory", {
                                    attrs: {
                                      "question-answer": _vm.questionAnswer
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.propertyIsEliminated && !_vm.question.requirement
                    ? _c(
                        "b-row",
                        { staticClass: "detail-section answer-update" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "h4",
                                {
                                  attrs: { role: "tab" },
                                  on: {
                                    click: function($event) {
                                      _vm.updateOpen = !_vm.updateOpen
                                    }
                                  }
                                },
                                [
                                  _c("strong", [_vm._v("Add/Update Answer")]),
                                  _vm._v(" "),
                                  !_vm.updateOpen
                                    ? _c("b-icon", {
                                        attrs: { icon: "chevron-right" }
                                      })
                                    : _c("b-icon", {
                                        attrs: { icon: "chevron-down" }
                                      })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                {
                                  staticClass: "question answer-form",
                                  attrs: { role: "tablist" },
                                  model: {
                                    value: _vm.updateOpen,
                                    callback: function($$v) {
                                      _vm.updateOpen = $$v
                                    },
                                    expression: "updateOpen"
                                  }
                                },
                                [
                                  _c("QuestionAnswerForm", {
                                    attrs: {
                                      "project-in-review": _vm.projectInReview,
                                      question: _vm.question
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }