import countriesWithStates from '@/server/utils/countriesList'

export const state = () => ({
  countries: [],
  activeCountry: {},
  states: [],
  activeState: {},
  counties: [],
  activeCounty: {},
  communities: [],
  activeCommunity: {},
  communityRecs: []
})

export const getters = {
  getCountries: state => state.countries,
  getStates: state => state.states,
  getCounties: state => state.counties,
  getCommunities: state => state.communities,
  getActiveCountry: state => state.activeCountry,
  getActiveState: state => state.activeState,
  getActiveCounty: state => state.activeCounty,
  getActiveCommunity: state => state.activeCommunity,
  getCommunityRecs: state => state.communityRecs
}

export const actions = {
  async addCommunity({ state, commit, dispatch }) {
    try {
      if (!state.activeState || !state.activeCounty || !state.activeCommunity) {
        return false
      }
      const params = {
        communityId: state.activeCommunity._id,
        communityName: state.activeCommunity.basicInfo.about.name,
        communityNumber: state.activeCommunity.community_id,
        stateName: state.activeState.basicInfo.about.name,
        stateAbbrev: state.activeState.basicInfo.about.state,
        countyName: state.activeCounty.basicInfo.about.name,
        countyId: state.activeCounty._id,
        countyNumber: state.activeCounty.county_id
      }
      const communities = await this.$axios.$post('/lois/community', params)
      commit('setCommunityRecords', communities)
      commit('user/setUserCommunities', communities, { root: true })
      commit('removeCommunity', params.communityNumber)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'communities/addCommunity', info: err },
        { root: true })
    }
  },
  async fetchCommunityRecords({ commit, dispatch }) {
    try {
      const communities = await this.$axios.$get('/lois/communities')
      commit('setCommunityRecords', communities)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'communities/fetchCommunityRecords', info: err },
        { root: true })
    }
  },
  // TODO: this is the function used to search for participants, do we need any changes here?
  // communities are not tied to countries, so maybe it can stay as is since we just return all
  // unless a state and/or county is selected to narrow things down
  async fetchCommunitiesForParticipantLookup({ state, commit, dispatch }, params) {
    try {
      const { stateName = '', countyName = '', communityName = '' } = params || {}
      if (!state.activeCounty && !stateName && !countyName && !communityName) {
        return false
      }
      // const countyid = state.activeCounty?._id || 0
      // const communities = await this.$axios.$get(`/lois/communities/forparticipants/${countyid}`, {
      const communities = await this.$axios.$get('/lois/communities/forparticipants/0', {
        params: {
          stateName,
          countyName,
          communityName
        }
      })
      commit('setCommunities', communities)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'communities/fetchCommunitiesForParticipantLookup', info: err },
        { root: true })
    }
  },
  async fetchCommunities({ state, commit, dispatch }, params) {
    try {
      const { stateName = '', countyName = '', communityName = '' } = params || {}
      if (!state.activeCounty && !stateName && !countyName && !communityName) {
        return false
      }
      const countyid = state.activeCounty?._id || 0
      const communities = await this.$axios.$get(`/lois/communities/${countyid}`, {
        params: {
          stateName,
          countyName,
          communityName
        }
      })
      commit('setCommunities', communities)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'communities/fetchCommunities', info: err },
        { root: true })
    }
  },
  async fetchCounties({ state, commit, dispatch }, params) {
    try {
      const { stateName = '', countyName = '' } = params || {}
      if ((!state.activeState || !state.activeState.basicInfo) && !stateName && !countyName) {
        return false
      }
      const activeState = state.activeState?.basicInfo?.about?.state || 'na'
      const counties = await this.$axios.$get(`/lois/counties/${activeState}`, {
        params: {
          stateName,
          countyName
        }
      })
      commit('setCounties', counties)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'communities/fetchCounties', info: err },
        { root: true })
    }
  },
  fetchCountries({ commit, dispatch }, params) {
    try {
      const { countryName = '' } = params || {}

      let countries = []
      if (countryName) {
        countries = countriesWithStates.filter(country => country.name.toLowerCase().includes(countryName.toLowerCase()))
      } else {
        countries = countriesWithStates.map(country => country)
      }

      commit('setCountries', countries)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'communities/fetchCountries', info: err },
        { root: true })
    }
  },
  async fetchStates({ commit, dispatch }, params) {
    try {
      const { stateName = '' } = params || {}
      const states = await this.$axios.$get('/lois/states', {
        params: {
          stateName
        }
      })
      commit('setStates', states)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'communities/fetchStates', info: err },
        { root: true })
    }
  },
  async removeCommunityRecord({ state, commit, dispatch }, { recId }) {
    try {
      const communities = await this.$axios.$delete(`/lois/community/${recId}`)
      commit('setCommunityRecords', communities)
      if (state.activeCounty?._id) {
        await dispatch('fetchCommunities')
      }
      commit('user/setUserCommunities', communities, { root: true })
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'communities/removeCommunityRecord', info: err },
        { root: true })
    }
  },
  setActiveCommunity({ commit }, { community }) {
    commit('setActiveCommunity', community)
  },
  setActiveCounty({ commit }, { county }) {
    commit('setActiveCounty', county)
  },
  setActiveCountry({ commit }, { country }) {
    commit('setActiveCountry', country)
  },
  setActiveState({ commit }, { state }) {
    commit('setActiveState', state)
  },
  resetCounties({ commit }) {
    commit('setCounties', [])
  },
  resetCommunities({ commit }) {
    commit('setCommunities', [])
  }
}

export const mutations = {
  init(state) {
    state.countries = []
    state.states = []
    state.counties = []
    state.communities = []
  },
  removeCommunity(state, communityNumber) {
    const index = state.communities.findIndex(c => c.community_id === communityNumber)
    state.communities.splice(index, 1)
  },
  setCommunityRecords(state, communities) {
    state.communityRecs = communities
  },
  setActiveCommunity(state, community) {
    state.activeCommunity = community
  },
  setActiveCounty(state, county) {
    state.activeCounty = county
  },
  setActiveCountry(state, activeCountry) {
    state.activeCountry = activeCountry
  },
  setActiveState(state, activeState) {
    state.activeState = activeState
  },
  setCommunities(state, communities) {
    state.communities = communities
  },
  setCounties(state, counties) {
    state.counties = counties
  },
  setStates(state, states) {
    state.states = states
  },
  setCountries(state, countries) {
    state.countries = countries
  }
}
