var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-list",
      attrs: {
        "data-name": "icon-list",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: { x1: "22.14", y1: "5.42", x2: "7.49", y2: "5.42" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: { x1: "22.14", y1: "12.6", x2: "7.49", y2: "12.6" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: { x1: "22.14", y1: "19.78", x2: "7.49", y2: "19.78" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: { x1: "3.41", y1: "5.42", x2: "2.76", y2: "5.42" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: { x1: "3.41", y1: "12.6", x2: "2.76", y2: "12.6" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: { x1: "3.41", y1: "19.78", x2: "2.76", y2: "19.78" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }