import { questionOwnerType } from '@/server/constants'

export const state = () => ({
  activeQuestion: {},
  questions: [],
  requirements: [],
  activeQuestionId: '',
  activeCategory: '',
  lastQuestionDeleted: false,
  primaryCategory: null,
  primaryCategories: [],
  secondaryCategory: null,
  secondaryCategories: [],
  tags: [],
  tertiaryCategories: [],
  questionsSelect: {
    project: {},
    property: {},
    questionsSelectActive: false,
    questionsSelected: []
  },
  requiredCategories: {},
  unseenCategories: [],
  unseenPrimaryCategories: [],
  unseenSecondaryCategories: [],
  unseenQuestions: []
})

export const getters = {
  getActiveCategory: state => state.activeCategory,
  getActiveQuestion: state => state.activeQuestion,
  getQuestions: state => state.questions,
  getActiveQuestionId: state => state.activeQuestionId,
  getLastQuestionDeleted: state => state.lastQuestionDeleted,
  getPrimaryCategory: state => state.primaryCategory,
  getPrimaryCategories: state => state.primaryCategories,
  getRequirements: state => state.requirements,
  getSecondaryCategory: state => state.secondaryCategory,
  getSecondaryCategories: state => state.secondaryCategories,
  getTags: state => state.tags,
  getTertiaryCategories: state => state.tertiaryCategories,
  getQuestionsSelect: state => state.questionsSelect,
  getQuestionsSelectProject: state => state.questionsSelect.project,
  getQuestionsSelectProperty: state => state.questionsSelect.property,
  getQuestionsSelectActive: state => state.questionsSelect.questionsSelectActive,
  getQuestionsSelected: state => state.questionsSelect.questionsSelected,
  getRequiredCategories: state => state.requiredCategories,
  getUnseenCategories: state => state.unseenCategories,
  getUnseenPrimaryCategories: state => state.unseenPrimaryCategories,
  getUnseenSecondaryCategories: state => state.unseenSecondaryCategories,
  getUnseenQuestions: state => state.unseenQuestions,
  getUnseenResponsesUpdated: state => state.unseenQuestions.length
}

export const actions = {

  async addQuestion({ commit, dispatch }, { question }) {
    try {
      const res = await this.$axios.$post('/question/', { question })
      if (res) {
        commit('setActiveQuestion', res.question)
        if (question.ownerType === questionOwnerType.project) {
          await dispatch('projects/fetchProjectQuestionCounts',
            { projectId: question.owner },
            { root: true }
          )
        }
      }
      return res
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/addQuestion', info: err },
        { root: true }
      )
    }
  },

  async checkTag({ dispatch }, { tag, owner, type }) {
    try {
      const res = await this.$axios.$get(`/question/check/tag/${owner}/${type}/${tag}`)
      if (res.result === 'ok') {
        return true
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/checkTag', info: err },
        { root: true }
      )
    }
  },

  async deleteQuestion({ state, commit, dispatch }, questionId) {
    try {
      const res = await this.$axios.$delete(`/question/${questionId}`)
      let question
      question = state.questions.find(q => String(q._id) === String(questionId))
      if (!question) {
        question = state.requirements.find(q => String(q._id) === String(questionId))
        if (question) {
          commit('updateRequirementListAfterDelete', questionId)
        }
      } else {
        commit('updateQuestionListAfterDelete', questionId)
      }
      if (!question) {
        return res.result === 'ok'
      }
      const { ownerType = questionOwnerType.project, owner } = question
      commit('updateQuestionListAfterDelete', questionId)
      if (ownerType === questionOwnerType.project) {
        await dispatch('projects/fetchProjectQuestionCounts',
          { projectId: owner },
          { root: true })
      }
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/deleteQuestion', info: err },
        { root: true })
    }
  },

  async deleteQuestions({ commit, dispatch }, { owner, type }) {
    try {
      const res = await this.$axios.$delete(`/questions/${owner}/${type}`)
      if (res.result === 'ok') {
        commit('init')
        return true
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/deleteQuestions', info: err },
        { root: true })
    }
  },

  async deleteQuestionsInCategory({ dispatch }, { owner, type, primary, secondary }) {
    try {
      let path = `/${primary}`
      if (secondary) {
        path += `/${secondary}`
      }
      const res = await this.$axios.$delete(`/questions/${owner}/${type}${path}`)
      if (res) {
        if (!res.projectId) {
          res.projectId = this.$route?.params.projectid
        }
        if (res.projectId) {
          await dispatch('projects/fetchProjectQuestionCounts',
            { projectId: res.projectId },
            { root: true }
          )
        }
      }
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/deleteQuestionsInCategory', info: err },
        { root: true })
    }
  },

  async fetchRequiredCategories({ commit, dispatch }, { owner, propertyId, viewPhase = false }) {
    try {
      const required = await this.$axios
        .$get(`/required/question/categories/${owner}/${propertyId}?viewPhase=${viewPhase}`)
      if (required) {
        commit('setRequiredCategories', required)
      }
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchRequiredCategories', info: err },
        { root: true })
    }
  },

  async fetchCategoriesWithUnseenAnswers({ commit, dispatch }, { owner, propertyId }) {
    try {
      const categories = await this.$axios
        .$get(`/unseen/question/categories/${owner}/${propertyId}`)
      if (categories) {
        commit('setUnseenCategories', categories)
      }
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchCategoriesWithUnseenAnswers', info: err },
        { root: true })
    }
  },

  async searchPrimaryCategories({ commit, dispatch }, { owner, type, primary, viewPhase = false }) {
    try {
      const qparts = []
      if (primary) {
        qparts.push(`primary=${encodeURIComponent(primary)}`)
      }
      qparts.push(`viewPhase=${viewPhase}`)
      const qs = qparts.join('&')
      const categories = await this.$axios
        .$get(`/categories/primary/search/${owner}/${type}?${qs}`)
      commit('setPrimaryCategories', categories)
      return categories
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/searchPrimaryCategories', info: err },
        { root: true })
    }
  },

  async fetchPrimaryCategories({ commit, dispatch }, { owner, type, viewPhase = false }) {
    try {
      const categories = await this.$axios
        .$get(`/categories/primary/${owner}/${type}?viewPhase=${viewPhase}`)
      commit('setPrimaryCategories', categories)
      return categories
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchPrimaryCategories', info: err },
        { root: true })
    }
  },

  async markQuestionAnswerAsSeen({ state, commit, dispatch }, { questionId, answerId }) {
    try {
      const answer = await this.$axios.$put(`/question/answer/seen/${questionId}/${answerId}`)
      const unseenCategories = state.unseenCategories.reduce((acc, uc) => {
        const secondaryCategories = Object.entries(uc.secondaryCategories)
          .reduce((ac, [key, val]) => {
            const questions = val.filter(qId => String(qId) !== String(questionId))
            if (questions.length) {
              ac[key] = questions
            }
            return ac
          }, {})
        if (Object.keys(secondaryCategories).length) {
          acc.push({
            primaryCategory: uc.primaryCategory,
            secondaryCategories
          })
        }
        return acc
      }, [])
      commit('setUnseenCategories', unseenCategories)
      return answer
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/markQuestionAnswerAsSeen', info: err },
        { root: true }
      )
    }
  },

  async searchSecondaryCategories({ state, commit, dispatch }, { owner, type, secondary, viewPhase = false }) {
    try {
      const qparts = []
      if (secondary) {
        qparts.push(`secondary=${encodeURIComponent(secondary)}`)
      }
      qparts.push(`viewPhase=${viewPhase}`)
      const qs = qparts.join('&')
      const primary = encodeURIComponent(state.primaryCategory)
      const categories = await this.$axios
        .$get(`/categories/secondary/search/${owner}/${type}/${primary}?${qs}`)
      commit('setSecondaryCategories', categories)
      return categories
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchSecondaryCategories', info: err },
        { root: true })
    }
  },

  async fetchAllSecondaryCategoriesForSort(
    { dispatch },
    { owner, type, primary, viewPhase = false }
  ) {
    try {
      const qparts = []
      if (primary) {
        qparts.push(`primary=${encodeURIComponent(primary)}`)
      }
      qparts.push(`viewPhase=${viewPhase}`)
      const query = qparts.join('&')
      const categories = await this.$axios
        .$get(`/categories/secondary/${owner}/${type}?${query}`)
      return categories
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchAllSecondaryCategoriesForSort', info: err },
        { root: true }
      )
    }
  },

  async fetchAllSecondaryCategories({ state, commit, dispatch }, { owner, type, secondary, viewPhase = false }) {
    try {
      const qparts = []
      if (state.primaryCategory) {
        qparts.push(`primary=${encodeURIComponent(state.primaryCategory)}`)
      }
      if (secondary) {
        qparts.push(`secondary=${encodeURIComponent(secondary)}`)
      }
      qparts.push(`viewPhase=${viewPhase}`)
      const qs = qparts.join('&')
      const categories = await this.$axios
        .$get(`/categories/secondary/${owner}/${type}?${qs}`)
      commit('setSecondaryCategories', categories)
      return categories
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchSecondaryCategories', info: err },
        { root: true })
    }
  },

  // these should be all teritary categories under the curent primary and secondary levels
  async searchTertiaryCategories({ state, commit, dispatch }, { owner, type, tertiary, viewPhase = false }) {
    try {
      const qparts = []
      if (tertiary) {
        qparts.push(`?tertiary=${encodeURIComponent(tertiary)}`)
      }
      qparts.push(`viewPhase=${viewPhase}`)
      const qs = qparts.join('&')
      const primary = encodeURIComponent(state.primaryCategory)
      const secondary = encodeURIComponent(state.secondaryCategory)
      const categories = await this.$axios
        .$get(`/categories/tertiary/search/${owner}/${type}/${primary}/${secondary}?${qs}`)
      commit('setTertiaryCategories', categories)
      return categories
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchTertiaryCategories', info: err },
        { root: true })
    }
  },

  // these are all tertiary categories
  async fetchAllTertiaryCategories({ state, commit, dispatch }, { owner, type, tertiary }) {
    try {
      const qparts = []
      if (state.primaryCategory) {
        qparts.push(`?primary=${encodeURIComponent(state.primaryCategory)}`)
      }
      if (state.secondaryCategory) {
        qparts.push(`?secondary=${encodeURIComponent(state.secondaryCategory)}`)
      }
      if (tertiary) {
        qparts.push(`?tertiary=${encodeURIComponent(tertiary)}`)
      }
      const qs = qparts.join('&')
      const categories = await this.$axios
        .$get(`/categories/tertiary/${owner}/${type}?${qs}`)
      commit('setTertiaryCategories', categories)
      return categories
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchTertiaryCategories', info: err },
        { root: true })
    }
  },

  async searchTags({ commit, dispatch }, { owner, type, tag }) {
    try {
      let qs = ''
      if (tag) {
        qs = `?tag=${tag}`
      }
      const tags = await this.$axios.$get(`/tags/${owner}/${type}${qs}`)
      commit('setTags', tags)
      return tags
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/searchTags', info: err },
        { root: true })
    }
  },

  async movePrimaryUnderOtherPrimaryCategory({ dispatch }, {
    owner,
    type,
    primaryCategory,
    newPrimaryCategory
  }) {
    try {
      const res = await this.$axios
        .$put(`/category/move/primary/${primaryCategory}`, {
          owner,
          ownerType: type,
          newPrimaryCategory
        })
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/movePrimaryUnderOtherPrimaryCategory', info: err },
        { root: true })
    }
  },

  async moveUnderOtherPrimaryCategory({ dispatch }, {
    owner,
    type,
    primaryCategory,
    secondaryCategory,
    newPrimaryCategory
  }) {
    try {
      const res = await this.$axios
        .$put(`/category/primary/move/${secondaryCategory}`, {
          owner,
          ownerType: type,
          primaryCategory,
          newPrimaryCategory
        })
      return res.return === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/moveUnderOtherPrimaryCategory', info: err },
        { root: true })
    }
  },

  async moveToPrimaryCategory({ dispatch }, { owner, type, secondaryCategory }) {
    try {
      const res = await this.$axios.$put(`/category/move/${secondaryCategory}`, {
        owner,
        ownerType: type
      })
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/moveToPrimaryCategory', info: err },
        { root: true })
    }
  },

  async renameCategory({ commit, dispatch }, { owner, type, categoryLevel, previousName, newName }) {
    try {
      const res = await this.$axios.$put(`/category/${categoryLevel}`, {
        owner,
        ownerType: type,
        previousName,
        newName
      })
      if (res.result === 'ok') {
        if (categoryLevel === 'primary') {
          commit('renamePrimaryCategory', { previousName, newName })
        } else {
          commit('renameSecondaryCategory', { previousName, newName })
        }
        return true
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/renameCategory', info: err },
        { root: true })
    }
  },

  async fetchQuestionById({ dispatch, commit }, questionId) {
    try {
      const question = await this.$axios.$get(`/question/${questionId}`)
      commit('setActiveQuestion', question)
      commit('setActiveQuestionId', question._id)
      return question
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchQuestionById', info: err },
        { root: true })
    }
  },

  async fetchQuestions({ state, commit, dispatch }, { owner, type, viewPhase = false }) {
    try {
      const qparts = []
      if (state.primaryCategory) {
        const pc = (state.primaryCategory === 'Uncategorized') ? '' : state.primaryCategory
        qparts.push(`primary=${encodeURIComponent(pc)}`)
      }
      if (state.secondaryCategory) {
        qparts.push(`secondary=${encodeURIComponent(state.secondaryCategory)}`)
      }
      qparts.push(`viewPhase=${viewPhase}`)
      const qs = qparts.join('&')
      const questions = await this.$axios.$get(`/questions/${owner}/${type}?${qs}`)
      commit('setQuestions', questions)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchQuestions', info: err },
        { root: true })
    }
  },

  async fetchRequirements({ dispatch, commit }, { owner, type, viewPhase = false }) {
    try {
      const qs = `viewPhase=${viewPhase}`
      const requirements = await this.$axios.$get(`/requirements/${owner}/${type}?${qs}`)
      commit('setRequirements', requirements)
      return requirements
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchRequirements', info: err },
        { root: true })
    }
  },

  async fetchAllQuestionsForSelection({ dispatch, commit }, { owner, type, viewPhase = false }) {
    try {
      const qs = `viewPhase=${viewPhase}`
      const questions = await this.$axios.$get(`/questions/selection/${owner}/${type}?${qs}`)
      commit('setQuestionsSelected', questions)
      return questions
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/fetchAllQuestionsForSelection', info: err },
        { root: true })
    }
  },

  setActiveQuestion({ commit }, question) {
    commit('setActiveQuestion', question)
  },

  setActiveQuestionId({ commit }, questionId) {
    commit('setActiveQuestionId', questionId)
  },

  setPrimaryCategory({ commit }, category) {
    commit('setPrimaryCategory', category)
  },

  setSecondaryCategory({ commit }, category) {
    commit('setSecondaryCategory', category)
  },

  setActiveCategory({ commit }, category) {
    commit('setActiveCategory', category)
  },

  setLastQuestionDeleted({ commit }, status) {
    commit('setLastQuestionDeleted', status)
  },

  setQuestionsSelect({ commit }, options) {
    commit('setQuestionsSelect', options)
  },

  setQuestionsSelectProject({ commit }, project) {
    commit('setQuestionsSelectProject', project)
  },

  setQuestionsSelectProperty({ commit }, property) {
    commit('setQuestionsSelectProperty', property)
  },

  setQuestionsSelectActive({ commit }, status) {
    commit('setQuestionsSelectActive', status)
  },

  setQuestionsSelected({ commit }, questions) {
    commit('setQuestionsSelected', questions)
  },

  setQuestionsSelectedAdd({ commit }, question) {
    commit('setQuestionsSelectedAdd', question)
  },

  setQuestionsSelectedDelete({ commit }, question) {
    commit('setQuestionsSelectedDelete', question)
  },

  async updateQuestionById({ dispatch }, { question }) {
    try {
      const res = await this.$axios
        .$put(`/question/${question._id}`, { question })
      if (question.ownerType === questionOwnerType.project) {
        await dispatch('projects/fetchProjectQuestionCounts',
          { projectId: question.owner },
          { root: true }
        )
      }
      return res
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/updateQuestionById', info: err },
        { root: true }
      )
    }
  },

  // The order parameter here needs to be a JS array of objects matching the
  // format:
  //
  //   order: [
  //     {
  //       name: "<primary category name>",
  //       secondaries: [
  //         {
  //           name: "<secondary category name>"
  //         },
  //         {
  //           name: "<secondary category name>"
  //         }
  //       ]
  //     },
  //     {
  //       ...
  //     }
  //   ]
  //
  async updateCategoriesOrder({ dispatch }, { owner, type, order }) {
    try {
      const result = await this.$axios
        .$put(`/categories/order/${owner}/${type}`, { order })
      return result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/updateCategoryOrder', info: err },
        { root: true })
    }
  },

  // This is used to update drag-n-drop list items
  updateQuestionList({ dispatch, commit }, questionList) {
    try {
      const questionIds = questionList.map(q => q._id)
      this.$axios.$put('/questions', { questionIds })
      commit('updateQuestionList', questionList)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questions/updateQuestionList', info: err },
        { root: true }
      )
    }
  }
}

export const mutations = {

  init(state) {
    state.activeQuestion = {}
    state.questions = []
    state.requirements = []
    state.activeQuestionId = ''
    state.activeCategory = ''
    state.lastQuestionDeleted = false
    state.primaryCategory = null
    state.primaryCategories = []
    state.secondaryCategory = null
    state.secondaryCategories = []
    state.tags = []
    state.tertiaryCategories = []
    state.questionsSelect.project = {}
    state.questionsSelect.property = {}
    state.questionsSelect.questionsSelectActive = false
    state.questionsSelect.questionsSelected = []
  },

  renamePrimaryCategory(state, data) {
    const index = state.primaryCategories.findIndex(pc => pc === data.previousName)
    if (data.newName) {
      state.primaryCategories[index] = data.newName
      state.primaryCategory = data.newName
      state.activeCategory = data.newName
    } else {
      state.primaryCategories.splice(index, 1)
      const lastIndex = state.primaryCategories.length - 1
      state.primaryCategory = state.primaryCategories[lastIndex]
      state.activeCategory = state.primaryCategories[lastIndex]
    }
  },

  renameSecondaryCategory(state, data) {
    const index = state.secondaryCategories.findIndex(sc => sc === data.previousName)
    if (data.newName) {
      state.secondaryCategories[index] = data.newName
      state.secondaryCategory = data.newName
      state.activeCategory = data.newName
    } else {
      state.secondaryCategories.splice(index, 1)
      state.activeCategory = state.primaryCategory
      state.secondaryCategory = null
    }
  },

  setActiveCategory(state, category) {
    state.activeCategory = category
  },

  setActiveQuestion(state, question) {
    state.activeQuestion = question
  },

  setLastQuestionDeleted(state, status) {
    state.lastQuestionDeleted = status
  },

  setQuestions(state, questions) {
    state.questions = questions
  },

  setActiveQuestionId(state, questionId) {
    state.activeQuestionId = questionId
  },

  setPrimaryCategory(state, category) {
    state.secondaryCategory = null
    state.secondaryCategories = []
    state.primaryCategory = category
  },

  setPrimaryCategories(state, categories) {
    state.activeQuestion = {}
    state.questions = []
    state.tertiaryCategories = []
    state.secondaryCategory = null
    state.activeQuestionId = ''
    state.primaryCategory = null
    state.primaryCategories = categories
    state.primaryCategories.push('Uncategorized')
  },

  setRequirements(state, requirements) {
    state.requirements = requirements
  },

  setSecondaryCategory(state, category) {
    state.secondaryCategory = category
  },

  setSecondaryCategories(state, categories) {
    state.secondaryCategory = null
    state.tertiaryCategories = []
    state.secondaryCategories = categories
  },

  setTags(state, tags) {
    state.tags = tags
  },

  setTertiaryCategories(state, categories) {
    state.tertiaryCategories = categories
  },

  setQuestionsSelect(state, options) {
    state.questionsSelect.project = options.project
    state.questionsSelect.property = options.property
    state.questionsSelect.questionsSelectActive = options.status
    state.questionsSelect.questionsSelected = options.questions
  },

  setQuestionsSelectProject(state, project) {
    state.questionsSelect.project = project
  },

  setQuestionsSelectProperty(state, property) {
    state.questionsSelect.property = property
  },

  setQuestionsSelectActive(state, status) {
    state.questionsSelect.questionsSelectActive = status
  },

  setQuestionsSelected(state, questions) {
    state.questionsSelect.questionsSelected = questions
  },

  setQuestionsSelectedAdd(state, question) {
    state.questionsSelect.questionsSelected.push(question)
  },

  setQuestionsSelectedDelete(state, question) {
    const newState = state.questionsSelect.questionsSelected.filter(q => q._id !== question._id)
    state.questionsSelect.questionsSelected = newState
  },

  setRequiredCategories(state, required) {
    state.requiredCategories = required
  },

  setUnseenCategories(state, categories) {
    state.unseenCategories = categories
    state.unseenPrimaryCategories = categories.map(cat => cat.primaryCategory)
    state.unseenSecondaryCategories = categories
      .reduce((acc, cat) => (acc.push(...Object.keys(cat.secondaryCategories)) && acc), [])
    state.unseenQuestions = categories
      .reduce((acc, cat) => (acc.push(...(Object.values(cat.secondaryCategories)
        .reduce((ac, val) => (acc.push(...val) && ac), []))) && acc), [])
  },

  updateQuestionListAfterDelete(state, questionid) {
    const newList = state.questions.filter(q => q._id !== questionid)
    state.questions = newList
  },

  updateRequirementListAfterDelete(state, questionid) {
    const newList = state.requirements.filter(q => q._id !== questionid)
    state.requirements = newList
  },

  updateQuestionList(state, questions) {
    state.questions = questions
  }
}
