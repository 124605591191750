import { render, staticRenderFns } from "./default.vue?vue&type=template&id=314f53c6&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {QuickStartModal: require('/lasso/components/support/QuickStartModal.vue').default,AuthModal: require('/lasso/components/user/AuthModal.vue').default,NavMobile: require('/lasso/components/NavMobile.vue').default,Header: require('/lasso/components/Header.vue').default,Error: require('/lasso/components/Error.vue').default,PageHeader: require('/lasso/components/PageHeader.vue').default,InboxActions: require('/lasso/components/inbox/InboxActions.vue').default,Breadcrumb: require('/lasso/components/Breadcrumb.vue').default,Footer: require('/lasso/components/Footer.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('314f53c6')) {
      api.createRecord('314f53c6', component.options)
    } else {
      api.reload('314f53c6', component.options)
    }
    module.hot.accept("./default.vue?vue&type=template&id=314f53c6&", function () {
      api.rerender('314f53c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "layouts/default.vue"
export default component.exports