//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ImportTemplateQuestionsModal',
  props: {
    projectId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      loadingTemplates: false,
      selectedTemplateId: null,
      selectedTemplateHighestAssignedPhase: null
    }
  },
  computed: {
    ...mapGetters({
      projectPhaseCount: 'projects/getActiveProjectPhaseCount',
      templates: 'templates/getTemplates'
    }),
    displayImportAlert() {
      return this.selectedTemplateId
    }
  },
  async mounted() {
    this.loadingTemplates = true
    await this.fetchTemplateList()
    this.loadingTemplates = false
  },
  methods: {
    ...mapActions({
      applyTemplateToProject: 'templates/applyTemplateToProject',
      fetchTemplateList: 'templates/fetchTemplateList',
      fetchHighestAssignedPhase: 'templates/getHighestPhaseForTemplateQuestions'
    }),
    async selectTemplateConfirm(id) {
      const highestAssignedPhase = await this.fetchHighestAssignedPhase(id)
      if (highestAssignedPhase > this.projectPhaseCount) {
        this.selectedTemplateId = id
        this.selectedTemplateHighestAssignedPhase = highestAssignedPhase
        return
      }
      await this.selectTemplate(id)
    },
    async selectTemplate(id, updatePhase = false) {
      this.loadingTemplates = true
      await this.applyTemplateToProject({ projectId: this.projectId, templateId: id, updatePhase })
      this.selectedTemplateId = null
      this.selectedTemplateHighestAssignedPhase = null
      this.$emit('imported')
      this.loadingTemplates = false
      this.$bvModal.hide('modal-import-template-questions')
    }
  }
}
