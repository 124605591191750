var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("LicensesUnavailableModal", { attrs: { "license-type": "user" } }),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "page-header-wrapper" },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _vm.isDesktop
                    ? _c(
                        "b-col",
                        { staticClass: "header-search", attrs: { md: "7" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h1", { staticClass: "page-heading" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.pageName) +
                                      "\n              "
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showHeaderActions
                    ? _c(
                        "b-col",
                        { staticClass: "header-actions" },
                        [
                          _c(
                            "b-row",
                            { staticClass: "actions-wrapper" },
                            [
                              _c(
                                "b-col",
                                { staticClass: "actions" },
                                [
                                  _vm.showButton("new-user")
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "new",
                                          attrs: {
                                            squared: "",
                                            variant: "link",
                                            size: "sm",
                                            title: "Add User"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleAction(
                                                "new-user"
                                              )
                                            }
                                          }
                                        },
                                        [_c("IconAddPrimary")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showButton("new-organization")
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "new",
                                          attrs: {
                                            squared: "",
                                            variant: "link",
                                            size: "sm",
                                            title: "Add Organization"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleAction(
                                                "new-organization"
                                              )
                                            }
                                          }
                                        },
                                        [_c("IconAddPrimary")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showButton("refresh")
                                    ? _c(
                                        "b-button",
                                        {
                                          class: {
                                            refreshing: _vm.isRefreshing
                                          },
                                          attrs: {
                                            squared: "",
                                            variant: "link",
                                            size: "sm",
                                            title: "Refresh"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleAction("refresh")
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "refresh-icon" },
                                            [
                                              _vm.refreshing === "refresh"
                                                ? _c("b-spinner", {
                                                    attrs: { medium: "" }
                                                  })
                                                : _c("IconRefresh")
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showButton("list")
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            squared: "",
                                            variant: "link",
                                            size: "sm",
                                            title: "User List"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleAction("list")
                                            }
                                          }
                                        },
                                        [_c("IconList")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showButton("organization-list")
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            squared: "",
                                            variant: "link",
                                            size: "sm",
                                            title: "Organization List"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleAction(
                                                "organization-list"
                                              )
                                            }
                                          }
                                        },
                                        [_c("IconList")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showButton("edit-user")
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            squared: "",
                                            variant: "link",
                                            size: "sm",
                                            title: "Edit User"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleAction(
                                                "edit-user"
                                              )
                                            }
                                          }
                                        },
                                        [_c("IconEditPrimary")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showButton("details")
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            squared: "",
                                            variant: "link",
                                            size: "sm",
                                            title: "User Details"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleAction("details")
                                            }
                                          }
                                        },
                                        [_c("IconDetails")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showButton("organization-details")
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            squared: "",
                                            variant: "link",
                                            size: "sm",
                                            title: "Organization Details"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleAction(
                                                "organization-details"
                                              )
                                            }
                                          }
                                        },
                                        [_c("IconDetails")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showButton("search")
                                    ? _c("b-input", {
                                        ref: "search",
                                        staticClass: "search-input",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Search Users",
                                          value: _vm.adminOptions.search,
                                          "icon-right": _vm.adminOptions.search
                                            ? "magnify-close"
                                            : "magnify",
                                          "icon-right-clickable": ""
                                        },
                                        on: {
                                          "icon-right-click": _vm.clearSearch,
                                          input: _vm.searchUsers
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }