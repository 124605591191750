import _ from 'lodash-es'
import { requirementOperator } from '@/server/constants'

export default (context, inject) => {
  inject('evalreq', ({
    req: {
      operator,
      value,
      values,
      minimum,
      maximum
    },
    newValue
  }) => {
    const floatValue = (val) => {
      const v = parseFloat(val)
      return !isNaN(v) ? v : undefined
    }
    const arrayVal = (val) => {
      if (Array.isArray(val)) { return val }
      if (_.isObject(val)) { return _.values(val) }
      return val ? String(val).replace(/,\s+/gi, ',').split(',') : ''
    }
    // Convert the MongoDB value to what Lasso is looking for
    let propVal = newValue
    if (_.isFinite(newValue)) {
      propVal = floatValue(newValue)
    }
    // If the property value is undefined, then we return false
    if (propVal === undefined) {
      return false
    }
    switch (operator) {
      case requirementOperator.greaterThan:
        return propVal > parseInt(value)
      case requirementOperator.greaterThanOrEqual:
        return propVal >= parseInt(value)
      case requirementOperator.equal:
        return propVal === value
      case requirementOperator.notEqual:
        return propVal !== value
      case requirementOperator.lessThanOrEqual:
        return propVal <= parseInt(value)
      case requirementOperator.lessThan:
        return propVal < parseInt(value)
      case requirementOperator.between:
        return propVal <= parseFloat(maximum) &&
               propVal >= parseFloat(minimum)
      case requirementOperator.oneOf:
        propVal = arrayVal(propVal)
        if (Array.isArray(propVal)) {
          return values.some(v => propVal.find(p => v.match(new RegExp(p, 'i'))))
        }
        return values.some(v1 => v1.match(new RegExp(propVal, 'i')))
      case requirementOperator.allOf:
        propVal = arrayVal(propVal)
        if (Array.isArray(propVal)) {
          return values.every(v => propVal.find(p => v.match(new RegExp(p, 'i'))))
        }
        return values.length === 1 && values.match(new RegExp(propVal, 'i'))
      case requirementOperator.noneOf:
        propVal = arrayVal(propVal)
        if (Array.isArray(propVal)) {
          return !values.some(v => propVal.find(p => v.match(new RegExp(p, 'i'))))
        }
        return values.find(vl => vl === propVal) === undefined
      default:
        break
    }
    return false
  })
}
