//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
// import { debounce } from 'lodash-es'
import { userRoles } from '@/server/constants'

export default {
  name: 'TemplatesHeader',
  props: {
    pageName: {
      type: String,
      default: 'Templates'
    }
  },
  data() {
    return {
      // selectedTemplate: '',
      btnDisplayConfig: {
        templates: ['search', 'refresh', 'new-template'], // removed filter
        template: ['list', 'edit-template'],
        templatesAdd: ['list'],
        templatesEdit: ['list', 'active-template'],
        templatesAddQuestion: ['list', 'active-template'],
        templatesEditQuestion: ['list', 'active-template']
      },
      refreshing: ''
    }
  },
  computed: {
    ...mapGetters({
      activeTemplate: 'templates/getActiveTemplate',
      activeTemplateId: 'templates/getActiveTemplateId',
      isDesktop: 'breakpoints/desktop',
      templates: 'templates/getTemplates',
      templateOptions: 'templates/getTemplateOptions',
      userRole: 'user/getUserRole',
      isAdmin: 'user/isAdmin'
    }),
    // filteredTemplates() {
    //   return this.templates.filter((option) => {
    //     const regexp = new RegExp(`${this.selectedTemplate}`, 'i')
    //     return regexp.test(option.name)
    //   })
    // },
    isSystemTemplate() {
      return !this.activeTemplate.user && !this.activeTemplate.org
    },
    showEditTemplate() {
      return this.isSystemTemplate ? this.isAdmin : this.isProjectOwner
    },
    isRefreshing() {
      return this.refreshing !== ''
    },
    isProjectOwner() {
      return this.userRole & userRoles.projectowner
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.showButton()
      }
    }
  },
  created() {
    // this.searchTemplates = debounce(this.searchTemplates, 500)
  },
  methods: {
    ...mapActions({
      fetchTemplateList: 'templates/fetchTemplateList',
      setActiveTemplate: 'templates/setActiveTemplate',
      setSearchTerm: 'templates/setTemplateListSearchTerm',
      startLoading: 'global/startLoading',
      stopLoading: 'global/stopLoading'
    }),
    // setSelectedTemplate(template) {
    //   this.setActiveTemplate(template)
    //   this.$router.push(`/templates/${template._id}`)
    // },
    clearSearch(e) {
      this.$refs.search.newValue = ''
      this.searchTemplates('')
    },
    async handleAction(action) {
      const path = this.activeTemplateId ? this.activeTemplateId : this.$route.params.templateid
      switch (action) {
        case 'details':
          this.$router.push('/templates')
          break
        case 'active-template':
          this.$router.push(`/templates/${path}`)
          break
        case 'edit-template':
          this.$router.push(`/templates/${path}/edit`)
          break
        case 'list':
          // this.setActiveTemplate(null)
          // this.selectedTemplate = ''
          this.$router.push({ name: 'templates' })
          break
        case 'new-question':
          this.$router.push(`/templates/${path}/question/add`)
          break
        case 'new-template':
          // this.setActiveTemplate({})
          this.$router.push({ name: 'templates-add' })
          break
        case 'refresh':
          this.refreshing = 'refresh'
          this.startLoading()
          await this.fetchTemplateList()
          this.refreshing = ''
          this.stopLoading()
          break
        default:
          break
      }
    },
    searchTemplates(e) {
      this.setSearchTerm(e)
    },
    showButton(btn) {
      switch (this.$route.name) {
        case 'templates':
          return this.btnDisplayConfig.templates.includes(btn)

        case 'templates-add':
          return this.btnDisplayConfig.templatesAdd.includes(btn)

        case 'templates-templateid':
          return this.btnDisplayConfig.template.includes(btn)

        case 'templates-templateid-edit':
          return this.btnDisplayConfig.templatesEdit.includes(btn)

        case 'templates-templateid-add-question':
          return this.btnDisplayConfig.templatesAddQuestion.includes(btn)

        case 'templates-templateid-question-questionid-edit-question':
          return this.btnDisplayConfig.templatesEditQuestion.includes(btn)

        default:
          return false
      }
    }
  }
}
