const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['loismapviewer'] = require('../middleware/loismapviewer.js')
middleware['loismapviewer'] = middleware['loismapviewer'].default || middleware['loismapviewer']

middleware['participant'] = require('../middleware/participant.js')
middleware['participant'] = middleware['participant'].default || middleware['participant']

middleware['projectowner'] = require('../middleware/projectowner.js')
middleware['projectowner'] = middleware['projectowner'].default || middleware['projectowner']

export default middleware
