var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-auth",
        "hide-footer": "",
        "header-class": "auth-modal-header",
        size: "md"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "link" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("IconClose")],
                1
              ),
              _vm._v(" "),
              _c("img", {
                staticClass: "logo",
                attrs: { src: require("assets/images/lasso-logo-default.png") }
              }),
              _vm._v(" "),
              _c(
                "h4",
                { class: _vm.activeForm === "login" ? "login-title" : "" },
                [_vm._v("\n      " + _vm._s(_vm.modalTitle) + "\n    ")]
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.activeForm === "login"
            ? _c("Login", {
                on: {
                  "change-form": _vm.handleFormChange,
                  ssologin: _vm.handleSSOLogin
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeForm === "register"
            ? _c("Register", {
                attrs: { "header-bg-variant": "projects" },
                on: { "change-form": _vm.handleFormChange }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.activeForm === "reset"
            ? _c("Reset", {
                attrs: { "header-bg-variant": "projects" },
                on: { "change-form": _vm.handleFormChange }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }