//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'LoadingOverlay',
  props: {
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      theme: 'global/pageTheme'
    })
  }
}
