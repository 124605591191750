import { render, staticRenderFns } from "./QuestionAnswerItemOwner.vue?vue&type=template&id=466242ef&"
import script from "./QuestionAnswerItemOwner.vue?vue&type=script&lang=js&"
export * from "./QuestionAnswerItemOwner.vue?vue&type=script&lang=js&"
import style0 from "./QuestionAnswerItemOwner.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {NotViewed: require('/lasso/components/NotViewed.vue').default,RequirementOperatorValue: require('/lasso/components/requirements/RequirementOperatorValue.vue').default,QuestionAnswerHistory: require('/lasso/components/questions/QuestionAnswerHistory.vue').default,QuestionAnswerForm: require('/lasso/components/questions/QuestionAnswerForm.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('466242ef')) {
      api.createRecord('466242ef', component.options)
    } else {
      api.reload('466242ef', component.options)
    }
    module.hot.accept("./QuestionAnswerItemOwner.vue?vue&type=template&id=466242ef&", function () {
      api.rerender('466242ef', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/questions/QuestionAnswerItemOwner.vue"
export default component.exports