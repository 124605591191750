export default (error) => {
  // return a friendly message instead of the api error
  let readableError
  switch (error) {
    case 'messageId not found':
      readableError = 'Sorry, there was a problem with that message, please try again.'
      break
    case 'Multipart: Boundary not found':
      readableError = 'Attachment could not be uploaded, please try again.'
      break
    case 'unauthorized access':
      readableError = 'Sorry you are not authorized to perform the request.'
      break
    case 'participant already exists':
    case 'participant in project':
      readableError = 'This user is already a participant of this project. Please try a different user.'
      break
    case 'reCAPTCHA token missing':
      readableError = 'Please click on the reCAPTCHA checkbox to regenerate your login token.'
      break
    default:
      readableError = 'Sorry, an error occurred processing your request.'
      break
  }
  return readableError
}
