var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-select-property-large",
      attrs: {
        "data-name": "icon-select-property",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "20.93 19.54 20.93 20.54 19.93 20.54" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10",
          "stroke-dasharray": "2.1372740268707275,2.1372740268707275"
        },
        attrs: { x1: "17.8", y1: "20.54", x2: "6.04", y2: "20.54" }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "4.97 20.54 3.97 20.54 3.97 19.54" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10",
          "stroke-dasharray": "1.9796137809753418,1.9796137809753418"
        },
        attrs: { x1: "3.97", y1: "17.56", x2: "3.97", y2: "6.68" }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "3.97 5.69 3.97 4.69 4.97 4.69" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10",
          "stroke-dasharray": "2.1372740268707275,2.1372740268707275"
        },
        attrs: { x1: "7.11", y1: "4.69", x2: "18.87", y2: "4.69" }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "19.93 4.69 20.93 4.69 20.93 5.69" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10",
          "stroke-dasharray": "1.9796137809753418,1.9796137809753418"
        },
        attrs: { x1: "20.93", y1: "7.67", x2: "20.93", y2: "18.55" }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "17.38 9.68 11.78 15.29 9.17 12.68" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }