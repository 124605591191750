import { render, staticRenderFns } from "./templates-layout.vue?vue&type=template&id=10e7eadc&"
import script from "./templates-layout.vue?vue&type=script&lang=js&"
export * from "./templates-layout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {QuickStartModal: require('/lasso/components/support/QuickStartModal.vue').default,NavMobile: require('/lasso/components/NavMobile.vue').default,Header: require('/lasso/components/Header.vue').default,Error: require('/lasso/components/Error.vue').default,PageHeader: require('/lasso/components/PageHeader.vue').default,TemplatesHeader: require('/lasso/components/templates/TemplatesHeader.vue').default,Breadcrumb: require('/lasso/components/Breadcrumb.vue').default,Footer: require('/lasso/components/Footer.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10e7eadc')) {
      api.createRecord('10e7eadc', component.options)
    } else {
      api.reload('10e7eadc', component.options)
    }
    module.hot.accept("./templates-layout.vue?vue&type=template&id=10e7eadc&", function () {
      api.rerender('10e7eadc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "layouts/templates-layout.vue"
export default component.exports