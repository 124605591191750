//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { cloneDeep, isEqual } from 'lodash-es'

export default {
  name: 'CategoriesManager',
  components: {
    draggable
  },
  data() {
    return {
      route: {
        projectid: null,
        questionid: null,
        templateid: null
      },
      categoryOwner: null,
      categoryType: null,
      categoriesList: [],
      categoriesListOriginalOrder: [],
      loading: false,
      showSecondaryCategories: true
    }
  },
  computed: {
    ...mapGetters({
      activeCategory: 'questions/getActiveCategory',
      primaryCategories: 'questions/getPrimaryCategories',
      project: 'projects/getActiveProject',
      template: 'templates/getActiveTemplate',
      isOrgAdmin: 'user/isOrgAdmin',
      isListAdmin: 'user/isListAdmin',
      isSuperAdmin: 'user/isSuperAdmin'
    }),
    originalSortHasChanged() {
      const primaryOrderChanged = !isEqual(this.categoriesListOriginalOrder, this.categoriesList)
      const secondaryOrderChanged = this.categoriesListOriginalOrder.some((origOrder, idx) => {
        const findIdxNewSort = this.categoriesList.findIndex(newOrder => newOrder.name === origOrder.name)
        return !isEqual(this.categoriesListOriginalOrder[idx], this.categoriesList[findIdxNewSort])
      })
      return primaryOrderChanged || secondaryOrderChanged
    },
    primariesWithoutUncategorized() {
      return this.categoriesList.filter(primary => primary.name !== 'Uncategorized')
    },
    showDeleteAllCategoriesButton() {
      return this.isOrgAdmin || this.isListAdmin || this.isSuperAdmin
    }
  },
  watch: {
    showSecondaryCategories() {
      this.setActiveCategory(null)
      this.setPrimaryCategory(null)
      this.setSecondaryCategory(null)
    }
  },
  async created() {
    await this.$nuxt.$on('refresh-category-tree', async ({ fetchPrimary = false, primaryCategory = null, secondaryCategory = null, refreshOriginalTree = false }) => {
      await this.buildPrimarySecondaryCategoryTree(fetchPrimary, primaryCategory, secondaryCategory, refreshOriginalTree)
    })
    this.$nuxt.$on('update-category-tree', (updatedPayload) => {
      this.updatePrimarySecondaryCategoryTree(updatedPayload)
    })
    this.$nuxt.$on('loading', (status) => {
      this.loading = status
    })
  },
  async mounted() {
    this.route = { ...this.$route.params }
    this.categoryOwner = this.route.templateid ? this.route.templateid : this.route.projectid
    this.categoryType = this.route.templateid ? 1 : 2
    await this.setActiveCategory(null)
    await this.buildPrimarySecondaryCategoryTree()
    this.categoriesListOriginalOrder = cloneDeep(this.categoriesList)
  },
  beforeDestroy() {
    this.$nuxt.$off('refresh-category-tree')
    this.$nuxt.$off('update-category-tree')
    this.$nuxt.$off('loading')
    this.setActiveCategory(null)
    this.setPrimaryCategory(null)
    this.setSecondaryCategory(null)
    localStorage.removeItem('categoryselections')
  },
  methods: {
    ...mapActions({
      deleteQuestions: 'questions/deleteQuestions',
      fetchPrimaryCategories: 'questions/fetchPrimaryCategories',
      fetchSecondaryCategories: 'questions/fetchAllSecondaryCategoriesForSort',
      setActiveCategory: 'questions/setActiveCategory',
      setPrimaryCategory: 'questions/setPrimaryCategory',
      setSecondaryCategory: 'questions/setSecondaryCategory',
      updateCategoriesOrder: 'questions/updateCategoriesOrder'
    }),
    async buildPrimarySecondaryCategoryTree(fetchPrimary = false, primaryCategory = null, secondaryCategory = null, refreshOriginalTree = false) {
      // building a full tree of all primary categories and their secondaries
      this.loading = true
      if (fetchPrimary) {
        await this.fetchPrimaryCategories({ owner: this.categoryOwner, type: this.categoryType })
      }
      this.categoriesList = await Promise.all(this.primaryCategories.map(async (primaryCategory) => {
        const secondaryCategories = await this.fetchSecondaryCategories({ owner: this.categoryOwner, type: this.categoryType, primary: primaryCategory })
        return {
          name: primaryCategory,
          secondaries: [...secondaryCategories.map((secondaryCategory) => {
            return {
              name: secondaryCategory
            }
          })]
        }
      }))
      await this.setPrimaryCategory(primaryCategory)
      await this.setSecondaryCategory(secondaryCategory)
      if (refreshOriginalTree) {
        this.categoriesListOriginalOrder = cloneDeep(this.categoriesList)
      }
      this.loading = false
    },
    updatePrimarySecondaryCategoryTree({ prevName, newName, categoryParent = null, refreshOriginalTree = false }) {
      // this function renames a category in local state without having to do a full rebuild of the tree
      let updatedCategoriesList = [...this.categoriesList]
      if (categoryParent) {
        // secondary category being updated
        const primaryCategoryIdx = this.categoriesList.findIndex(category => category.name === categoryParent)
        const categoryToUpdateIdx = this.categoriesList[primaryCategoryIdx].secondaries.findIndex(category => category.name === prevName)
        if (newName === '') {
          const updatedSecondaries = updatedCategoriesList[primaryCategoryIdx].secondaries.filter((category, idx) => idx !== categoryToUpdateIdx)
          updatedCategoriesList[primaryCategoryIdx].secondaries = updatedSecondaries
        } else {
          updatedCategoriesList[primaryCategoryIdx].secondaries[categoryToUpdateIdx].name = newName
        }
      } else {
        // primary category being updated
        const categoryToUpdateIdx = this.categoriesList.findIndex(category => category.name === prevName)
        if (newName === '') {
          updatedCategoriesList = this.categoriesList.filter((category, idx) => idx !== categoryToUpdateIdx)
        } else {
          updatedCategoriesList[categoryToUpdateIdx].name = newName
        }
      }
      this.categoriesList = updatedCategoriesList
      if (refreshOriginalTree) {
        this.categoriesListOriginalOrder = cloneDeep(this.categoriesList)
      }
    },
    async deleteAllQuestions() {
      this.loading = true
      await this.deleteQuestions({ owner: this.categoryOwner, type: this.categoryType })
      this.categoriesList = []
      localStorage.removeItem('categoryselections')
      this.loading = false
      this.$emit('cancelSort')
    },
    async deleteAllQuestionsConfirm() {
      const { projectid, templateid } = this.$route.params
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'Are you sure you want to delete ALL categories and questions for ',
          h('strong', `"${templateid ? this.template.name : this.project.name}"? `),
          `${projectid ? 'This action removes all answered questions for this project and cannot be undone!' : ''}`
        ]
      )
      const res = await this.$bvModal.msgBoxConfirm([customMsg], {
        title: 'Confirm Categories and Questions Delete!',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelVariant: 'light',
        cancelTitle: 'NO',
        footerBgVariant: 'dark',
        headerBgVariant: 'danger',
        hideHeaderClose: true,
        centered: true
      })
      if (res) {
        await this.deleteAllQuestions()
      }
    },
    resetSort() {
      this.categoriesList = cloneDeep(this.categoriesListOriginalOrder)
    },
    async saveCategoryOrder() {
      this.loading = true
      await this.updateCategoriesOrder({ owner: this.categoryOwner, type: this.categoryType, order: this.primariesWithoutUncategorized })
      await this.fetchPrimaryCategories({ owner: this.categoryOwner, type: this.categoryType })
      this.categoriesListOriginalOrder = cloneDeep(this.categoriesList)
      this.successNotification('updated')
      this.loading = false
    },
    successNotification (action) {
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'Category Sort was ',
          h('strong', `${action} `)
        ]
      )
      this.$bvToast.toast([customMsg], {
        autoHideDelay: 2000,
        noCloseButton: true,
        solid: true,
        toaster: 'b-toaster-top-right',
        title: 'Success',
        variant: 'success'
      })
    }
  }
}
