//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { email, required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'Reset',
  auth: false,
  data() {
    return {
      resetForm: {
        email: ''
      },
      errorMsg: '',
      processing: false,
      success: false
    }
  },
  computed: {
    disableSubmit() {
      return !this.$v.resetForm.$dirty || (this.$v.resetForm.$dirty && this.$v.resetForm.$anyError)
    },
    submitButtonText() {
      return this.processing ? 'Please wait ...' : 'Reset Password'
    }
  },
  methods: {
    ...mapActions({
      reset: 'user/resetPassword'
    }),
    onCancel() {
      // emit action to go back to login form
      this.$emit('change-form', 'login')
    },
    async onSubmit() {
      this.$v.resetForm.$touch()
      if (this.$v.resetForm.$anyError) {
        return
      }

      try {
        this.errorMsg = ''
        this.processing = true
        await this.reset(this.resetForm)
        this.success = true
      } catch (error) {
        this.errorMsg = error
        this.processing = false
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.resetForm[name]
      return $dirty ? !$error : null
    }
  },
  validations: {
    resetForm: {
      email: {
        required,
        email
      }
    }
  }
}
