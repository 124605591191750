var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "icon-close",
        "data-name": "icon-close",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("path", {
        staticStyle: { opacity: "0.5" },
        attrs: {
          d:
            "M20.88,19.66l-6.07-6.07,5.86-5.86a1.42,1.42,0,0,0,0-2,1.41,1.41,0,0,0-2,0L12.82,11.6,7,5.73a1.41,1.41,0,0,0-2,2l5.87,5.87L4.75,19.66a1.41,1.41,0,0,0,0,2,1.37,1.37,0,0,0,1,.42,1.41,1.41,0,0,0,1-.42l6.08-6.07,6.07,6.07a1.4,1.4,0,0,0,1,.41,1.44,1.44,0,0,0,1-.41A1.41,1.41,0,0,0,20.88,19.66Z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: { fill: "#fff" },
        attrs: {
          d:
            "M20.88,18.66l-6.07-6.07,5.86-5.86a1.42,1.42,0,0,0,0-2,1.41,1.41,0,0,0-2,0L12.82,10.6,7,4.73a1.41,1.41,0,0,0-2,2l5.87,5.87L4.75,18.66a1.41,1.41,0,0,0,0,2,1.37,1.37,0,0,0,1,.42,1.41,1.41,0,0,0,1-.42l6.08-6.07,6.07,6.07a1.4,1.4,0,0,0,1,.41,1.44,1.44,0,0,0,1-.41A1.41,1.41,0,0,0,20.88,18.66Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }