export const state = () => ({
  participantQuestionnaires: [],
  activeQuestionnaire: {},
  questionnaireInProgress: {
    _id: null,
    name: '',
    projectId: null,
    propertyId: null,
    body: '',
    subject: '',
    targetedUser: null,
    questions: []
  }
})

export const getters = {
  getParticipantQuestionnaires: state => state.participantQuestionnaires,
  getActiveQuestionnaire: state => state.activeQuestionnaire,
  getQuestionnaireInProgress: state => state.questionnaireInProgress
}

export const actions = {
  // targetedUser = { _id, email, firstName, lastName, company, address, city, state, zip, phone }
  // - new users will not have an _id, but other fields ARE required
  // - existing user _id is required only
  // - inviteSubject, inviteMessage are not required
  // - questions ARE required
  async addQuestionnaire({ dispatch }, { targetedUser, participantId, projectId, propertyId, name, inviteSubject, inviteMessage, showProjectDescription, attachments, questions }) {
    try {
      const { questionnaire } = await this.$axios.$post(`/questionnaire/${participantId}/${projectId}/${propertyId}`,
        { targetedUser, name, inviteSubject, inviteMessage, showProjectDescription, attachments, questions })
      return questionnaire
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questionnaires/addQuestionnaire', info: err },
        { root: true })
    }
  },

  async deleteQuestionnaire({ commit, dispatch }, { participantId, projectId, propertyId, questionnaireId }) {
    try {
      const { result } = await this.$axios.$delete(`/questionnaire/${participantId}/${projectId}/${propertyId}/${questionnaireId}`)
      // We need to remove the questionnaire from the participantQuestionnaire list
      if (result === 'ok') {
        commit('removeParticipantQuestionnaire', questionnaireId)
        return true
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questionnaires/deleteQuestionnaire', info: err },
        { root: true })
    }
  },

  async fetchQuestionnaireById({ commit, dispatch }, { questionnaireId }) {
    try {
      const { questionnaire } = await this.$axios.$get(`/questionnaire/${questionnaireId}`)
      if (!Array.isArray(questionnaire.answers)) {
        questionnaire.answers = []
      }
      commit('setActiveQuestionnaire', questionnaire)
      commit('answers/setAnswers', questionnaire.answers, { root: true })
      return questionnaire
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questionnaires/fetchQuestionnaireById', info: err },
        { root: true })
    }
  },

  async fetchQuestionnairesForParticipant({ commit, dispatch }, { participantId, projectId, propertyId }) {
    try {
      const { questionnaires } = await this.$axios.$get(`/questionnaires/${participantId}/${projectId}/${propertyId}`)
      commit('setParticipantQuestionnaires', questionnaires)
      return questionnaires
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questionnaires/fetchQuestionnairesForParticipant', info: err },
        { root: true })
    }
  },

  // TODO: possibly will need this in the future - this was intended to fetch all questionnaires across projects/properties
  // async fetchAllQuestionnairesForParticipant({ commit, dispatch }, { participantId }) {
  //   try {
  //     const { questionnaires } = await this.$axios.$get(`/questionnaires/${participantId}`)
  //     commit('setParticipantQuestionnaires', questionnaires)
  //     return questionnaires
  //   } catch (err) {
  //     return dispatch(
  //       'global/setNetworkError',
  //       { method: 'questionnaires/fetchQuestionnairesForParticipant', info: err },
  //       { root: true })
  //   }
  // },

  async sendQuestionnaire({ dispatch }, { participantId, projectId, propertyId, questionnaireId }) {
    try {
      const { result } = await this.$axios.$put(
        `/questionnaire/send/${participantId}/${projectId}/${propertyId}`,
        { questionnaireId })
      return result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questionnaires/sendQuestionnaire', info: err },
        { root: true })
    }
  },

  async resendQuestionnaire({ dispatch }, { participantId, projectId, propertyId, questionnaireId }) {
    try {
      const { result } = await this.$axios.$put(
        `/questionnaire/resend/${participantId}/${projectId}/${propertyId}`,
        { questionnaireId })
      return result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questionnaires/resendQuestionnaire', info: err },
        { root: true })
    }
  },

  async updateQuestionnaire({ commit, dispatch }, { participantId, projectId, propertyId, questionnaireId, targetedUser, inviteSubject, inviteMessage, showProjectDescription, attachments, questions, answers, status, name }) {
    try {
      const { result, questionnaire } = await this.$axios.$put(`/questionnaire/${participantId}/${projectId}/${propertyId}/${questionnaireId}`,
        { targetedUser, inviteSubject, inviteMessage, showProjectDescription, attachments, questions, answers, status, name })
      if (result === 'ok') {
        commit('updateQuestionnaire', questionnaire)
      }
      return questionnaire
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questionnaires/updateQuestionnaire', info: err },
        { root: true })
    }
  },

  async updateQuestionnaireStatusAsCollaborator({ commit, dispatch }, { questionnaireId, status }) {
    try {
      const { result, questionnaire } = await this.$axios.$put(`/questionnaire/status/${questionnaireId}`,
        { status })
      if (result === 'ok') {
        commit('updateQuestionnaire', questionnaire)
      }
      return questionnaire
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questionnaires/updateQuestionnaire', info: err },
        { root: true })
    }
  },

  async verifyQuestionnaire({ dispatch }, { questionnaireId }) {
    try {
      const { result } = await this.$axios.$get(`/questionnaire/verify/${questionnaireId}`)
      return result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'questionnaires/verifyQuestionnaire', info: err },
        { root: true })
    }
  },

  setActiveQuestionnaire({ commit }, questionnaire) {
    commit('setActiveQuestionnaire', questionnaire)
  },

  setQuestionnaireInProgress({ commit }, { _id, name, projectId, propertyId, body, subject, teaser, targetedUser, showProjectDescription, attachments, questions }) {
    commit('setQuestionnaireInProgress', { _id, name, projectId, propertyId, body, subject, teaser, targetedUser, showProjectDescription, attachments, questions })
  },

  setQuestionnaireInProgressName({ commit }, name) {
    commit('setQuestionnaireInProgressName', name)
  },

  setQuestionnaireInProgressBody({ commit }, body) {
    commit('setQuestionnaireInProgressBody', body)
  },

  setQuestionnaireInProgressSubject({ commit }, subject) {
    commit('setQuestionnaireInProgressSubject', subject)
  },

  setQuestionnaireInProgressQuestions({ commit }, questions = []) {
    commit('setQuestionnaireInProgressQuestions', questions)
  },

  setQuestionnaireInProgressRecipient({ commit }, targetedUser = {}) {
    commit('setQuestionnaireInProgressRecipient', targetedUser)
  },

  setQuestionnaireInProgressAttachments({ commit }, attachments = []) {
    commit('setQuestionnaireInProgressAttachments', attachments)
  },

  setQuestionnaireInProgressShowProjectDescription({ commit }, showProjectDescription) {
    commit('setQuestionnaireInProgressShowProjectDescription', showProjectDescription)
  }
}

export const mutations = {
  init(state) {
    state.activeQuestionnaire = {}
    state.questionnaireInProgress = {
      _id: null,
      name: '',
      projectId: null,
      propertyId: null,
      body: '',
      subject: '',
      targetedUser: null,
      questions: []
    }
  },

  setParticipantQuestionnaires(state, questionnaires) {
    state.participantQuestionnaires = questionnaires
  },

  // this will be used when accessing Questionnaire Detail page
  setActiveQuestionnaire(state, questionnaire) {
    state.activeQuestionnaire = questionnaire
  },

  // this is used in the questionnaire wizard name field
  setQuestionnaireInProgressName(state, name) {
    state.questionnaireInProgress.name = name
  },

  // this is used in the questionnaire wizard body field
  setQuestionnaireInProgressBody(state, body) {
    state.questionnaireInProgress.body = body
  },

  // this is used in the questionnaire wizard subject field
  setQuestionnaireInProgressSubject(state, subject) {
    state.questionnaireInProgress.subject = subject
  },

  // this is used in the questionnaire wizard questions field
  setQuestionnaireInProgressQuestions(state, questions = []) {
    questions.forEach((question) => {
      const questionInStore = state.questionnaireInProgress.questions.find(questionInProgress => questionInProgress._id === question._id)
      if (questionInStore) {
        // question exists, delete from store
        const newQuestions = state.questionnaireInProgress.questions.filter(questionInProgress => questionInProgress._id !== questionInStore._id)
        state.questionnaireInProgress.questions = newQuestions
      } else {
        // question doesn't exist, add it
        state.questionnaireInProgress.questions.push(question)
      }
    })
  },

  // this is used in the questionnaire wizard recipient field
  setQuestionnaireInProgressRecipient(state, targetedUser) {
    state.questionnaireInProgress.targetedUser = targetedUser
  },

  setQuestionnaireInProgress(state, { _id = null, name = '', projectId = null, propertyId = null, body = '', subject = '', targetedUser = null, showProjectDescription = false, attachments = [], questions = '' }) {
    state.questionnaireInProgress = {
      _id,
      name,
      projectId,
      propertyId,
      body,
      subject,
      targetedUser,
      showProjectDescription,
      attachments,
      questions
    }
  },

  setQuestionnaireInProgressAttachments(state, attachments) {
    state.questionInProgress.attachments = attachments
  },

  setQuestionnaireInProgressShowProjectDescription(state, showProjectDescription) {
    state.questionInProgress.showProjectDescription = showProjectDescription
  },

  removeParticipantQuestionnaire(state, questionnaireId) {
    const idx = state.participantQuestionnaires.findIndex(pq => String(pq._id) === String(questionnaireId))
    state.participantQuestionnaires.splice(idx, 1)
  },

  updateQuestionnaire(state, questionnaire) {
    const idx = state.participantQuestionnaires.findIndex(pq => String(pq._id) === String(questionnaire._id))
    state.participantQuestionnaires[idx] = questionnaire
  }
}

// TODO: these are api calls Derrick setup that will need to be hooked up and tested

// GET /questionnaire/:questionnaireId => retrieves individual questionnaire, will need to add security here
// GET /questionnaires/:projectId/:propertyId => retrieves a list of questionnaires associated with a property.

// POST /c/:projectId/:propertyId => adds new questionnaire and sends invite

// PUT /questionnaire/:projectId/:propertyId => edit/update questionnaire

// PUT /questionnaire/:projectId/:propertyId/resend => resends/updates invite and sends

// DELETE /questionnaire/:projectId/:propertyId/:questionnaireId => deletes the questionnaire
