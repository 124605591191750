import { render, staticRenderFns } from "./Template.vue?vue&type=template&id=0851e9aa&"
import script from "./Template.vue?vue&type=script&lang=js&"
export * from "./Template.vue?vue&type=script&lang=js&"
import style0 from "./Template.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {LoadingOverlay: require('/lasso/components/LoadingOverlay.vue').default,Template: require('/lasso/components/templates/Template.vue').default,IconImport: require('/lasso/components/icons/IconImport.vue').default,IconExport: require('/lasso/components/icons/IconExport.vue').default,IconAddTemplateQuestion: require('/lasso/components/icons/IconAddTemplateQuestion.vue').default,CategoriesQuestions: require('/lasso/components/questions/CategoriesQuestions.vue').default,Section: require('/lasso/components/wrappers/Section.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0851e9aa')) {
      api.createRecord('0851e9aa', component.options)
    } else {
      api.reload('0851e9aa', component.options)
    }
    module.hot.accept("./Template.vue?vue&type=template&id=0851e9aa&", function () {
      api.rerender('0851e9aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/templates/Template.vue"
export default component.exports