var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "requirement-operator-value" }, [
    _vm.requirementOperator
      ? _c("div", { staticClass: "operator" }, [
          _vm._v("\n    " + _vm._s(_vm.requirementOperator) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.requirement.minimum && _vm.requirement.maximum
      ? _c("div", { staticClass: "min-max" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.requirement.minimum) +
              " and " +
              _vm._s(_vm.requirement.maximum) +
              "\n  "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.requirement.minimum && !_vm.requirement.maximum
      ? _c("div", { staticClass: "min" }, [
          _vm._v("\n    " + _vm._s(_vm.requirement.minimum) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.requirement.maximum && !_vm.requirement.minimum
      ? _c("div", { staticClass: "max" }, [
          _vm._v("\n    " + _vm._s(_vm.requirement.maximum) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.requirement.value
      ? _c("div", { staticClass: "value" }, [
          _vm._v("\n    " + _vm._s(_vm.requirement.value) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.valuesDisplay
      ? _c("div", { staticClass: "values" }, [
          _vm._v("\n    " + _vm._s(_vm.valuesDisplay) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }