const countriesWithStates = [
  {
    country_cd: 'AF',
    name: 'Afghanistan',
    country_id: 4,
    states: [
      {
        state_cd_full: 'AF-BAL',
        name: 'Balkh',
        state_cd: 'BAL'
      },
      {
        state_cd_full: 'AF-BAM',
        name: 'Bamyan',
        state_cd: 'BAM'
      },
      {
        state_cd_full: 'AF-BGL',
        name: 'Baghlan',
        state_cd: 'BGL'
      },
      {
        state_cd_full: 'AF-FYB',
        name: 'Faryab',
        state_cd: 'FYB'
      },
      {
        state_cd_full: 'AF-HEL',
        name: 'Helmand',
        state_cd: 'HEL'
      },
      {
        state_cd_full: 'AF-HER',
        name: 'Herat',
        state_cd: 'HER'
      },
      {
        state_cd_full: 'AF-KAB',
        name: 'Kabul',
        state_cd: 'KAB'
      },
      {
        state_cd_full: 'AF-KAN',
        name: 'Kandahar',
        state_cd: 'KAN'
      },
      {
        state_cd_full: 'AF-KDZ',
        name: 'Kunduz',
        state_cd: 'KDZ'
      },
      {
        state_cd_full: 'AF-KHO',
        name: 'Khost',
        state_cd: 'KHO'
      },
      {
        state_cd_full: 'AF-LOG',
        name: 'Logar',
        state_cd: 'LOG'
      },
      {
        state_cd_full: 'AF-NAN',
        name: 'Nangarhar',
        state_cd: 'NAN'
      },
      {
        state_cd_full: 'AF-NIM',
        name: 'Nimroz',
        state_cd: 'NIM'
      },
      {
        state_cd_full: 'AF-PAR',
        name: 'Parwan',
        state_cd: 'PAR'
      },
      {
        state_cd_full: 'AF-PIA',
        name: 'Paktiya',
        state_cd: 'PIA'
      },
      {
        state_cd_full: 'AF-PKA',
        name: 'Paktika',
        state_cd: 'PKA'
      },
      {
        state_cd_full: 'AF-TAK',
        name: 'Takhar',
        state_cd: 'TAK'
      },
      {
        state_cd_full: 'AF-URU',
        name: 'Uruzgan',
        state_cd: 'URU'
      }
    ]
  },
  {
    country_cd: 'AX',
    name: 'Aland Islands',
    country_id: 248,
    states: [
      {
        state_cd_full: 'AX-01',
        name: 'Saltvik',
        country_id: '248',
        state_cd: '01'
      },
      {
        state_cd_full: 'AX-11',
        name: 'Mariehamn',
        country_id: '248',
        state_cd: '11'
      },
      {
        state_cd_full: 'AX-21',
        name: 'Jomala',
        country_id: '248',
        state_cd: '21'
      },
      {
        state_cd_full: 'AX-31',
        name: 'Hammarland',
        country_id: '248',
        state_cd: '31'
      },
      {
        state_cd_full: 'AX-41',
        name: 'Finstroem',
        country_id: '248',
        state_cd: '41'
      },
      {
        state_cd_full: 'AX-51',
        name: 'Eckeroe',
        country_id: '248',
        state_cd: '51'
      }
    ]
  },
  {
    country_cd: 'AL',
    name: 'Albania',
    country_id: 8,
    states: [
      {
        state_cd_full: 'AL-01',
        name: 'Berat',
        state_cd: '01'
      },
      {
        state_cd_full: 'AL-02',
        name: 'Durres',
        state_cd: '02'
      },
      {
        state_cd_full: 'AL-03',
        name: 'Elbasan',
        state_cd: '03'
      },
      {
        state_cd_full: 'AL-04',
        name: 'Fier',
        state_cd: '04'
      },
      {
        state_cd_full: 'AL-05',
        name: 'Gjirokaster',
        state_cd: '05'
      },
      {
        state_cd_full: 'AL-06',
        name: 'Korce',
        state_cd: '06'
      },
      {
        state_cd_full: 'AL-07',
        name: 'Kukes',
        state_cd: '07'
      },
      {
        state_cd_full: 'AL-08',
        name: 'Lezhe',
        state_cd: '08'
      },
      {
        state_cd_full: 'AL-09',
        name: 'Diber',
        state_cd: '09'
      },
      {
        state_cd_full: 'AL-10',
        name: 'Shkoder',
        state_cd: '10'
      },
      {
        state_cd_full: 'AL-11',
        name: 'Tirane',
        state_cd: '11'
      },
      {
        state_cd_full: 'AL-12',
        name: 'Vlore',
        state_cd: '12'
      }
    ]
  },
  {
    country_cd: 'DZ',
    name: 'Algeria',
    country_id: 12,
    states: [
      {
        state_cd_full: 'DZ-01',
        name: 'Adrar',
        country_id: '12',
        state_cd: '01'
      },
      {
        state_cd_full: 'DZ-02',
        name: 'Chlef',
        country_id: '12',
        state_cd: '02'
      },
      {
        state_cd_full: 'DZ-03',
        name: 'Laghouat',
        country_id: '12',
        state_cd: '03'
      },
      {
        state_cd_full: 'DZ-04',
        name: 'Oum el Bouaghi',
        country_id: '12',
        state_cd: '04'
      },
      {
        state_cd_full: 'DZ-05',
        name: 'Batna',
        country_id: '12',
        state_cd: '05'
      },
      {
        state_cd_full: 'DZ-06',
        name: 'Bejaia',
        country_id: '12',
        state_cd: '06'
      },
      {
        state_cd_full: 'DZ-07',
        name: 'Biskra',
        country_id: '12',
        state_cd: '07'
      },
      {
        state_cd_full: 'DZ-08',
        name: 'Bechar',
        country_id: '12',
        state_cd: '08'
      },
      {
        state_cd_full: 'DZ-09',
        name: 'Blida',
        country_id: '12',
        state_cd: '09'
      },
      {
        state_cd_full: 'DZ-10',
        name: 'Bouira',
        country_id: '12',
        state_cd: '10'
      },
      {
        state_cd_full: 'DZ-11',
        name: 'Tamanrasset',
        country_id: '12',
        state_cd: '11'
      },
      {
        state_cd_full: 'DZ-12',
        name: 'Tebessa',
        country_id: '12',
        state_cd: '12'
      },
      {
        state_cd_full: 'DZ-13',
        name: 'Tlemcen',
        country_id: '12',
        state_cd: '13'
      },
      {
        state_cd_full: 'DZ-14',
        name: 'Tiaret',
        country_id: '12',
        state_cd: '14'
      },
      {
        state_cd_full: 'DZ-15',
        name: 'Tizi Ouzou',
        country_id: '12',
        state_cd: '15'
      },
      {
        state_cd_full: 'DZ-16',
        name: 'Alger',
        country_id: '12',
        state_cd: '16'
      },
      {
        state_cd_full: 'DZ-17',
        name: 'Djelfa',
        country_id: '12',
        state_cd: '17'
      },
      {
        state_cd_full: 'DZ-18',
        name: 'Jijel',
        country_id: '12',
        state_cd: '18'
      },
      {
        state_cd_full: 'DZ-19',
        name: 'Setif',
        country_id: '12',
        state_cd: '19'
      },
      {
        state_cd_full: 'DZ-20',
        name: 'Saida',
        country_id: '12',
        state_cd: '20'
      },
      {
        state_cd_full: 'DZ-21',
        name: 'Skikda',
        country_id: '12',
        state_cd: '21'
      },
      {
        state_cd_full: 'DZ-22',
        name: 'Sidi Bel Abbes',
        country_id: '12',
        state_cd: '22'
      },
      {
        state_cd_full: 'DZ-23',
        name: 'Annaba',
        country_id: '12',
        state_cd: '23'
      },
      {
        state_cd_full: 'DZ-24',
        name: 'Guelma',
        country_id: '12',
        state_cd: '24'
      },
      {
        state_cd_full: 'DZ-25',
        name: 'Constantine',
        country_id: '12',
        state_cd: '25'
      },
      {
        state_cd_full: 'DZ-26',
        name: 'Medea',
        country_id: '12',
        state_cd: '26'
      },
      {
        state_cd_full: 'DZ-27',
        name: 'Mostaganem',
        country_id: '12',
        state_cd: '27'
      },
      {
        state_cd_full: 'DZ-28',
        name: "M'sila",
        country_id: '12',
        state_cd: '28'
      },
      {
        state_cd_full: 'DZ-29',
        name: 'Mascara',
        country_id: '12',
        state_cd: '29'
      },
      {
        state_cd_full: 'DZ-30',
        name: 'Ouargla',
        country_id: '12',
        state_cd: '30'
      },
      {
        state_cd_full: 'DZ-31',
        name: 'Oran',
        country_id: '12',
        state_cd: '31'
      },
      {
        state_cd_full: 'DZ-32',
        name: 'El Bayadh',
        country_id: '12',
        state_cd: '32'
      },
      {
        state_cd_full: 'DZ-33',
        name: 'Illizi',
        country_id: '12',
        state_cd: '33'
      },
      {
        state_cd_full: 'DZ-34',
        name: 'Bordj Bou Arreridj',
        country_id: '12',
        state_cd: '34'
      },
      {
        state_cd_full: 'DZ-35',
        name: 'Boumerdes',
        country_id: '12',
        state_cd: '35'
      },
      {
        state_cd_full: 'DZ-36',
        name: 'El Tarf',
        country_id: '12',
        state_cd: '36'
      },
      {
        state_cd_full: 'DZ-37',
        name: 'Tindouf',
        country_id: '12',
        state_cd: '37'
      },
      {
        state_cd_full: 'DZ-38',
        name: 'Tissemsilt',
        country_id: '12',
        state_cd: '38'
      },
      {
        state_cd_full: 'DZ-39',
        name: 'El Oued',
        country_id: '12',
        state_cd: '39'
      },
      {
        state_cd_full: 'DZ-40',
        name: 'Khenchela',
        country_id: '12',
        state_cd: '40'
      },
      {
        state_cd_full: 'DZ-41',
        name: 'Souk Ahras',
        country_id: '12',
        state_cd: '41'
      },
      {
        state_cd_full: 'DZ-42',
        name: 'Tipaza',
        country_id: '12',
        state_cd: '42'
      },
      {
        state_cd_full: 'DZ-43',
        name: 'Mila',
        country_id: '12',
        state_cd: '43'
      },
      {
        state_cd_full: 'DZ-44',
        name: 'Ain Defla',
        country_id: '12',
        state_cd: '44'
      },
      {
        state_cd_full: 'DZ-45',
        name: 'Naama',
        country_id: '12',
        state_cd: '45'
      },
      {
        state_cd_full: 'DZ-46',
        name: 'Ain Temouchent',
        country_id: '12',
        state_cd: '46'
      },
      {
        state_cd_full: 'DZ-47',
        name: 'Ghardaia',
        country_id: '12',
        state_cd: '47'
      },
      {
        state_cd_full: 'DZ-48',
        name: 'Relizane',
        country_id: '12',
        state_cd: '48'
      }
    ]
  },
  {
    country_cd: 'AS',
    name: 'American Samoa',
    country_id: 16,
    states: [
      {
        state_cd_full: 'AS-01',
        name: 'Western District',
        country_id: '16',
        state_cd: '01'
      },
      {
        state_cd_full: 'AS-11',
        name: 'Eastern District',
        country_id: '16',
        state_cd: '11'
      }
    ]
  },
  {
    country_cd: 'AD',
    name: 'Andorra',
    country_id: 20,
    states: [
      {
        state_cd_full: 'AD-02',
        name: 'Canillo',
        country_id: '20',
        state_cd: '02'
      },
      {
        state_cd_full: 'AD-03',
        name: 'Encamp',
        country_id: '20',
        state_cd: '03'
      },
      {
        state_cd_full: 'AD-04',
        name: 'La Massana',
        country_id: '20',
        state_cd: '04'
      },
      {
        state_cd_full: 'AD-05',
        name: 'Ordino',
        country_id: '20',
        state_cd: '05'
      },
      {
        state_cd_full: 'AD-06',
        name: 'Sant Julia de Loria',
        country_id: '20',
        state_cd: '06'
      },
      {
        state_cd_full: 'AD-07',
        name: 'Andorra la Vella',
        country_id: '20',
        state_cd: '07'
      },
      {
        state_cd_full: 'AD-08',
        name: 'Escaldes-Engordany',
        country_id: '20',
        state_cd: '08'
      }
    ]
  },
  {
    country_cd: 'AO',
    name: 'Angola',
    country_id: 24,
    states: [
      {
        state_cd_full: 'AO-BGO',
        name: 'Bengo',
        country_id: '24',
        state_cd: 'BGO'
      },
      {
        state_cd_full: 'AO-BGU',
        name: 'Benguela',
        country_id: '24',
        state_cd: 'BGU'
      },
      {
        state_cd_full: 'AO-BIE',
        name: 'Bie',
        country_id: '24',
        state_cd: 'BIE'
      },
      {
        state_cd_full: 'AO-CAB',
        name: 'Cabinda',
        country_id: '24',
        state_cd: 'CAB'
      },
      {
        state_cd_full: 'AO-CCU',
        name: 'Kuando Kubango',
        country_id: '24',
        state_cd: 'CCU'
      },
      {
        state_cd_full: 'AO-CNN',
        name: 'Cunene',
        country_id: '24',
        state_cd: 'CNN'
      },
      {
        state_cd_full: 'AO-CNO',
        name: 'Kwanza Norte',
        country_id: '24',
        state_cd: 'CNO'
      },
      {
        state_cd_full: 'AO-CUS',
        name: 'Kwanza Sul',
        country_id: '24',
        state_cd: 'CUS'
      },
      {
        state_cd_full: 'AO-HUA',
        name: 'Huambo',
        country_id: '24',
        state_cd: 'HUA'
      },
      {
        state_cd_full: 'AO-HUI',
        name: 'Huila',
        country_id: '24',
        state_cd: 'HUI'
      },
      {
        state_cd_full: 'AO-LNO',
        name: 'Lunda Norte',
        country_id: '24',
        state_cd: 'LNO'
      },
      {
        state_cd_full: 'AO-LSU',
        name: 'Lunda Sul',
        country_id: '24',
        state_cd: 'LSU'
      },
      {
        state_cd_full: 'AO-LUA',
        name: 'Luanda',
        country_id: '24',
        state_cd: 'LUA'
      },
      {
        state_cd_full: 'AO-MAL',
        name: 'Malange',
        country_id: '24',
        state_cd: 'MAL'
      },
      {
        state_cd_full: 'AO-MOX',
        name: 'Moxico',
        country_id: '24',
        state_cd: 'MOX'
      },
      {
        state_cd_full: 'AO-NAM',
        name: 'Namibe',
        country_id: '24',
        state_cd: 'NAM'
      },
      {
        state_cd_full: 'AO-UIG',
        name: 'Uige',
        country_id: '24',
        state_cd: 'UIG'
      },
      {
        state_cd_full: 'AO-ZAI',
        name: 'Zaire',
        country_id: '24',
        state_cd: 'ZAI'
      }
    ]
  },
  {
    country_cd: 'AI',
    name: 'Anguilla',
    country_id: 660,
    states: [
      {
        state_cd_full: 'AI-01',
        name: 'Anguilla',
        country_id: '660',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'AQ',
    name: 'Antarctica',
    country_id: 10,
    states: [
      {
        state_cd_full: 'AQ-01',
        name: 'Antartica',
        country_id: '10',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'AG',
    name: 'Antigua and Barbuda',
    country_id: 28,
    states: [
      {
        state_cd_full: 'AG-03',
        name: 'Saint George',
        country_id: '28',
        state_cd: '03'
      },
      {
        state_cd_full: 'AG-04',
        name: 'Saint John',
        country_id: '28',
        state_cd: '04'
      },
      {
        state_cd_full: 'AG-05',
        name: 'Saint Mary',
        country_id: '28',
        state_cd: '05'
      },
      {
        state_cd_full: 'AG-06',
        name: 'Saint Paul',
        country_id: '28',
        state_cd: '06'
      },
      {
        state_cd_full: 'AG-07',
        name: 'Saint Peter',
        country_id: '28',
        state_cd: '07'
      },
      {
        state_cd_full: 'AG-08',
        name: 'Saint Philip',
        country_id: '28',
        state_cd: '08'
      },
      {
        state_cd_full: 'AG-11',
        name: 'Redonda',
        country_id: '28',
        state_cd: '11'
      }
    ]
  },
  {
    country_cd: 'AR',
    name: 'Argentina',
    country_id: 32,
    states: [
      {
        state_cd_full: 'AR-A',
        name: 'Salta',
        country_id: '32',
        state_cd: 'A'
      },
      {
        state_cd_full: 'AR-B',
        name: 'Buenos Aires',
        country_id: '32',
        state_cd: 'B'
      },
      {
        state_cd_full: 'AR-C',
        name: 'Ciudad Autonoma de Buenos Aires',
        country_id: '32',
        state_cd: 'C'
      },
      {
        state_cd_full: 'AR-D',
        name: 'San Luis',
        country_id: '32',
        state_cd: 'D'
      },
      {
        state_cd_full: 'AR-E',
        name: 'Entre Rios',
        country_id: '32',
        state_cd: 'E'
      },
      {
        state_cd_full: 'AR-F',
        name: 'La Rioja',
        country_id: '32',
        state_cd: 'F'
      },
      {
        state_cd_full: 'AR-G',
        name: 'Santiago del Estero',
        country_id: '32',
        state_cd: 'G'
      },
      {
        state_cd_full: 'AR-H',
        name: 'Chaco',
        country_id: '32',
        state_cd: 'H'
      },
      {
        state_cd_full: 'AR-J',
        name: 'San Juan',
        country_id: '32',
        state_cd: 'J'
      },
      {
        state_cd_full: 'AR-K',
        name: 'Catamarca',
        country_id: '32',
        state_cd: 'K'
      },
      {
        state_cd_full: 'AR-L',
        name: 'La Pampa',
        country_id: '32',
        state_cd: 'L'
      },
      {
        state_cd_full: 'AR-M',
        name: 'Mendoza',
        country_id: '32',
        state_cd: 'M'
      },
      {
        state_cd_full: 'AR-N',
        name: 'Misiones',
        country_id: '32',
        state_cd: 'N'
      },
      {
        state_cd_full: 'AR-P',
        name: 'Formosa',
        country_id: '32',
        state_cd: 'P'
      },
      {
        state_cd_full: 'AR-Q',
        name: 'Neuquen',
        country_id: '32',
        state_cd: 'Q'
      },
      {
        state_cd_full: 'AR-R',
        name: 'Rio Negro',
        country_id: '32',
        state_cd: 'R'
      },
      {
        state_cd_full: 'AR-S',
        name: 'Santa Fe',
        country_id: '32',
        state_cd: 'S'
      },
      {
        state_cd_full: 'AR-T',
        name: 'Tucuman',
        country_id: '32',
        state_cd: 'T'
      },
      {
        state_cd_full: 'AR-U',
        name: 'Chubut',
        country_id: '32',
        state_cd: 'U'
      },
      {
        state_cd_full: 'AR-V',
        name: 'Tierra del Fuego',
        country_id: '32',
        state_cd: 'V'
      },
      {
        state_cd_full: 'AR-W',
        name: 'Corrientes',
        country_id: '32',
        state_cd: 'W'
      },
      {
        state_cd_full: 'AR-X',
        name: 'Cordoba',
        country_id: '32',
        state_cd: 'X'
      },
      {
        state_cd_full: 'AR-Y',
        name: 'Jujuy',
        country_id: '32',
        state_cd: 'Y'
      },
      {
        state_cd_full: 'AR-Z',
        name: 'Santa Cruz',
        country_id: '32',
        state_cd: 'Z'
      }
    ]
  },
  {
    country_cd: 'AM',
    name: 'Armenia',
    country_id: 51,
    states: [
      {
        state_cd_full: 'AM-AG',
        name: 'Aragacotn',
        country_id: '51',
        state_cd: 'AG'
      },
      {
        state_cd_full: 'AM-AR',
        name: 'Ararat',
        country_id: '51',
        state_cd: 'AR'
      },
      {
        state_cd_full: 'AM-AV',
        name: 'Armavir',
        country_id: '51',
        state_cd: 'AV'
      },
      {
        state_cd_full: 'AM-ER',
        name: 'Erevan',
        country_id: '51',
        state_cd: 'ER'
      },
      {
        state_cd_full: 'AM-GR',
        name: "Gegark'unik'",
        country_id: '51',
        state_cd: 'GR'
      },
      {
        state_cd_full: 'AM-KT',
        name: "Kotayk'",
        country_id: '51',
        state_cd: 'KT'
      },
      {
        state_cd_full: 'AM-LO',
        name: 'Lori',
        country_id: '51',
        state_cd: 'LO'
      },
      {
        state_cd_full: 'AM-SH',
        name: 'Sirak',
        country_id: '51',
        state_cd: 'SH'
      },
      {
        state_cd_full: 'AM-SU',
        name: "Syunik'",
        country_id: '51',
        state_cd: 'SU'
      },
      {
        state_cd_full: 'AM-TV',
        name: 'Tavus',
        country_id: '51',
        state_cd: 'TV'
      },
      {
        state_cd_full: 'AM-VD',
        name: 'Vayoc Jor',
        country_id: '51',
        state_cd: 'VD'
      }
    ]
  },
  {
    country_cd: 'AW',
    name: 'Aruba',
    country_id: 533,
    states: [
      {
        state_cd_full: 'AW-01',
        name: 'Aruba',
        country_id: '533',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'AU',
    name: 'Australia',
    country_id: 36,
    states: [
      {
        state_cd_full: 'AU-ACT',
        name: 'Australian Capital Territory',
        country_id: '36',
        state_cd: 'ACT'
      },
      {
        state_cd_full: 'AU-NSW',
        name: 'New South Wales',
        country_id: '36',
        state_cd: 'NSW'
      },
      {
        state_cd_full: 'AU-NT',
        name: 'Northern Territory',
        country_id: '36',
        state_cd: 'NT'
      },
      {
        state_cd_full: 'AU-QLD',
        name: 'Queensland',
        country_id: '36',
        state_cd: 'QLD'
      },
      {
        state_cd_full: 'AU-SA',
        name: 'South Australia',
        country_id: '36',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'AU-TAS',
        name: 'Tasmania',
        country_id: '36',
        state_cd: 'TAS'
      },
      {
        state_cd_full: 'AU-VIC',
        name: 'Victoria',
        country_id: '36',
        state_cd: 'VIC'
      },
      {
        state_cd_full: 'AU-WA',
        name: 'Western Australia',
        country_id: '36',
        state_cd: 'WA'
      }
    ]
  },
  {
    country_cd: 'AT',
    name: 'Austria',
    country_id: 40,
    states: [
      {
        state_cd_full: 'AT-1',
        name: 'Burgenland',
        country_id: '40',
        state_cd: '1'
      },
      {
        state_cd_full: 'AT-2',
        name: 'Karnten',
        country_id: '40',
        state_cd: '2'
      },
      {
        state_cd_full: 'AT-3',
        name: 'Niederosterreich',
        country_id: '40',
        state_cd: '3'
      },
      {
        state_cd_full: 'AT-4',
        name: 'Oberosterreich',
        country_id: '40',
        state_cd: '4'
      },
      {
        state_cd_full: 'AT-5',
        name: 'Salzburg',
        country_id: '40',
        state_cd: '5'
      },
      {
        state_cd_full: 'AT-6',
        name: 'Steiermark',
        country_id: '40',
        state_cd: '6'
      },
      {
        state_cd_full: 'AT-7',
        name: 'Tirol',
        country_id: '40',
        state_cd: '7'
      },
      {
        state_cd_full: 'AT-8',
        name: 'Vorarlberg',
        country_id: '40',
        state_cd: '8'
      },
      {
        state_cd_full: 'AT-9',
        name: 'Wien',
        country_id: '40',
        state_cd: '9'
      }
    ]
  },
  {
    country_cd: 'AZ',
    name: 'Azerbaijan',
    country_id: 31,
    states: [
      {
        state_cd_full: 'AZ-ABS',
        name: 'Abseron',
        country_id: '31',
        state_cd: 'ABS'
      },
      {
        state_cd_full: 'AZ-AGA',
        name: 'Agstafa',
        country_id: '31',
        state_cd: 'AGA'
      },
      {
        state_cd_full: 'AZ-AGC',
        name: 'Agcabadi',
        country_id: '31',
        state_cd: 'AGC'
      },
      {
        state_cd_full: 'AZ-AGS',
        name: 'Agdas',
        country_id: '31',
        state_cd: 'AGS'
      },
      {
        state_cd_full: 'AZ-AGU',
        name: 'Agsu',
        country_id: '31',
        state_cd: 'AGU'
      },
      {
        state_cd_full: 'AZ-BA',
        name: 'Baki',
        country_id: '31',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'AZ-BAL',
        name: 'Balakan',
        country_id: '31',
        state_cd: 'BAL'
      },
      {
        state_cd_full: 'AZ-BAR',
        name: 'Barda',
        country_id: '31',
        state_cd: 'BAR'
      },
      {
        state_cd_full: 'AZ-BEY',
        name: 'Beylaqan',
        country_id: '31',
        state_cd: 'BEY'
      },
      {
        state_cd_full: 'AZ-CAL',
        name: 'Calilabad',
        country_id: '31',
        state_cd: 'CAL'
      },
      {
        state_cd_full: 'AZ-GA',
        name: 'Ganca',
        country_id: '31',
        state_cd: 'GA'
      },
      {
        state_cd_full: 'AZ-GAD',
        name: 'Gadabay',
        country_id: '31',
        state_cd: 'GAD'
      },
      {
        state_cd_full: 'AZ-GOR',
        name: 'Goranboy',
        country_id: '31',
        state_cd: 'GOR'
      },
      {
        state_cd_full: 'AZ-GOY',
        name: 'Goycay',
        country_id: '31',
        state_cd: 'GOY'
      },
      {
        state_cd_full: 'AZ-GYG',
        name: 'Goygol',
        country_id: '31',
        state_cd: 'GYG'
      },
      {
        state_cd_full: 'AZ-HAC',
        name: 'Haciqabul',
        country_id: '31',
        state_cd: 'HAC'
      },
      {
        state_cd_full: 'AZ-IMI',
        name: 'Imisli',
        country_id: '31',
        state_cd: 'IMI'
      },
      {
        state_cd_full: 'AZ-KUR',
        name: 'Kurdamir',
        country_id: '31',
        state_cd: 'KUR'
      },
      {
        state_cd_full: 'AZ-LA',
        name: 'Lankaran',
        country_id: '31',
        state_cd: 'LA'
      },
      {
        state_cd_full: 'AZ-MAS',
        name: 'Masalli',
        country_id: '31',
        state_cd: 'MAS'
      },
      {
        state_cd_full: 'AZ-MI',
        name: 'Mingacevir',
        country_id: '31',
        state_cd: 'MI'
      },
      {
        state_cd_full: 'AZ-NA',
        name: 'Naftalan',
        country_id: '31',
        state_cd: 'NA'
      },
      {
        state_cd_full: 'AZ-NEF',
        name: 'Neftcala',
        country_id: '31',
        state_cd: 'NEF'
      },
      {
        state_cd_full: 'AZ-NX',
        name: 'Naxcivan',
        country_id: '31',
        state_cd: 'NX'
      },
      {
        state_cd_full: 'AZ-OGU',
        name: 'Oguz',
        country_id: '31',
        state_cd: 'OGU'
      },
      {
        state_cd_full: 'AZ-QAB',
        name: 'Qabala',
        country_id: '31',
        state_cd: 'QAB'
      },
      {
        state_cd_full: 'AZ-QAX',
        name: 'Qax',
        country_id: '31',
        state_cd: 'QAX'
      },
      {
        state_cd_full: 'AZ-QAZ',
        name: 'Qazax',
        country_id: '31',
        state_cd: 'QAZ'
      },
      {
        state_cd_full: 'AZ-QBA',
        name: 'Quba',
        country_id: '31',
        state_cd: 'QBA'
      },
      {
        state_cd_full: 'AZ-QUS',
        name: 'Qusar',
        country_id: '31',
        state_cd: 'QUS'
      },
      {
        state_cd_full: 'AZ-SAB',
        name: 'Sabirabad',
        country_id: '31',
        state_cd: 'SAB'
      },
      {
        state_cd_full: 'AZ-SAK',
        name: 'Saki',
        country_id: '31',
        state_cd: 'SAK'
      },
      {
        state_cd_full: 'AZ-SAL',
        name: 'Salyan',
        country_id: '31',
        state_cd: 'SAL'
      },
      {
        state_cd_full: 'AZ-SIY',
        name: 'Siyazan',
        country_id: '31',
        state_cd: 'SIY'
      },
      {
        state_cd_full: 'AZ-SKR',
        name: 'Samkir',
        country_id: '31',
        state_cd: 'SKR'
      },
      {
        state_cd_full: 'AZ-SM',
        name: 'Sumqayit',
        country_id: '31',
        state_cd: 'SM'
      },
      {
        state_cd_full: 'AZ-SMI',
        name: 'Samaxi',
        country_id: '31',
        state_cd: 'SMI'
      },
      {
        state_cd_full: 'AZ-SMX',
        name: 'Samux',
        country_id: '31',
        state_cd: 'SMX'
      },
      {
        state_cd_full: 'AZ-SR',
        name: 'Sirvan',
        country_id: '31',
        state_cd: 'SR'
      },
      {
        state_cd_full: 'AZ-TOV',
        name: 'Tovuz',
        country_id: '31',
        state_cd: 'TOV'
      },
      {
        state_cd_full: 'AZ-XAC',
        name: 'Xacmaz',
        country_id: '31',
        state_cd: 'XAC'
      },
      {
        state_cd_full: 'AZ-XIZ',
        name: 'Xizi',
        country_id: '31',
        state_cd: 'XIZ'
      },
      {
        state_cd_full: 'AZ-YAR',
        name: 'Yardimli',
        country_id: '31',
        state_cd: 'YAR'
      },
      {
        state_cd_full: 'AZ-YEV',
        name: 'Yevlax',
        country_id: '31',
        state_cd: 'YEV'
      },
      {
        state_cd_full: 'AZ-ZAQ',
        name: 'Zaqatala',
        country_id: '31',
        state_cd: 'ZAQ'
      },
      {
        state_cd_full: 'AZ-ZAR',
        name: 'Zardab',
        country_id: '31',
        state_cd: 'ZAR'
      }
    ]
  },
  {
    country_cd: 'BS',
    name: 'Bahamas',
    country_id: 44,
    states: [
      {
        state_cd_full: 'BS-11',
        name: 'New Providence',
        country_id: '44',
        state_cd: '11'
      },
      {
        state_cd_full: 'BS-CS',
        name: 'Central Andros',
        country_id: '44',
        state_cd: 'CS'
      },
      {
        state_cd_full: 'BS-FP',
        name: 'City of Freeport',
        country_id: '44',
        state_cd: 'FP'
      },
      {
        state_cd_full: 'BS-HI',
        name: 'Harbour Island',
        country_id: '44',
        state_cd: 'HI'
      },
      {
        state_cd_full: 'BS-HT',
        name: 'Hope Town',
        country_id: '44',
        state_cd: 'HT'
      },
      {
        state_cd_full: 'BS-LI',
        name: 'Long Island',
        country_id: '44',
        state_cd: 'LI'
      },
      {
        state_cd_full: 'BS-SE',
        name: 'South Eleuthera',
        country_id: '44',
        state_cd: 'SE'
      }
    ]
  },
  {
    country_cd: 'BH',
    name: 'Bahrain',
    country_id: 48,
    states: [
      {
        state_cd_full: 'BH-13',
        name: "Al 'Asimah",
        country_id: '48',
        state_cd: '13'
      },
      {
        state_cd_full: 'BH-14',
        name: 'Al Janubiyah',
        country_id: '48',
        state_cd: '14'
      },
      {
        state_cd_full: 'BH-15',
        name: 'Al Muharraq',
        country_id: '48',
        state_cd: '15'
      },
      {
        state_cd_full: 'BH-17',
        name: 'Ash Shamaliyah',
        country_id: '48',
        state_cd: '17'
      }
    ]
  },
  {
    country_cd: 'BD',
    name: 'Bangladesh',
    country_id: 50,
    states: [
      {
        state_cd_full: 'BD-06',
        name: 'Barisal',
        country_id: '50',
        state_cd: '06'
      },
      {
        state_cd_full: 'BD-10',
        name: 'Chittagong',
        country_id: '50',
        state_cd: '10'
      },
      {
        state_cd_full: 'BD-13',
        name: 'Dhaka',
        country_id: '50',
        state_cd: '13'
      },
      {
        state_cd_full: 'BD-27',
        name: 'Khulna',
        country_id: '50',
        state_cd: '27'
      },
      {
        state_cd_full: 'BD-54',
        name: 'Rajshahi',
        country_id: '50',
        state_cd: '54'
      },
      {
        state_cd_full: 'BD-55',
        name: 'Rangpur',
        country_id: '50',
        state_cd: '55'
      },
      {
        state_cd_full: 'BD-60',
        name: 'Sylhet',
        country_id: '50',
        state_cd: '60'
      }
    ]
  },
  {
    country_cd: 'BB',
    name: 'Barbados',
    country_id: 52,
    states: [
      {
        state_cd_full: 'BB-01',
        name: 'Christ Church',
        country_id: '52',
        state_cd: '01'
      },
      {
        state_cd_full: 'BB-02',
        name: 'Saint Andrew',
        country_id: '52',
        state_cd: '02'
      },
      {
        state_cd_full: 'BB-03',
        name: 'Saint George',
        country_id: '52',
        state_cd: '03'
      },
      {
        state_cd_full: 'BB-04',
        name: 'Saint James',
        country_id: '52',
        state_cd: '04'
      },
      {
        state_cd_full: 'BB-05',
        name: 'Saint John',
        country_id: '52',
        state_cd: '05'
      },
      {
        state_cd_full: 'BB-06',
        name: 'Saint Joseph',
        country_id: '52',
        state_cd: '06'
      },
      {
        state_cd_full: 'BB-07',
        name: 'Saint Lucy',
        country_id: '52',
        state_cd: '07'
      },
      {
        state_cd_full: 'BB-08',
        name: 'Saint Michael',
        country_id: '52',
        state_cd: '08'
      },
      {
        state_cd_full: 'BB-09',
        name: 'Saint Peter',
        country_id: '52',
        state_cd: '09'
      },
      {
        state_cd_full: 'BB-10',
        name: 'Saint Philip',
        country_id: '52',
        state_cd: '10'
      },
      {
        state_cd_full: 'BB-11',
        name: 'Saint Thomas',
        country_id: '52',
        state_cd: '11'
      }
    ]
  },
  {
    country_cd: 'BY',
    name: 'Belarus',
    country_id: 112,
    states: [
      {
        state_cd_full: 'BY-BR',
        name: "Brestskaya voblasts'",
        country_id: '112',
        state_cd: 'BR'
      },
      {
        state_cd_full: 'BY-HM',
        name: 'Horad Minsk',
        country_id: '112',
        state_cd: 'HM'
      },
      {
        state_cd_full: 'BY-HO',
        name: "Homyel'skaya voblasts'",
        country_id: '112',
        state_cd: 'HO'
      },
      {
        state_cd_full: 'BY-HR',
        name: "Hrodzenskaya voblasts'",
        country_id: '112',
        state_cd: 'HR'
      },
      {
        state_cd_full: 'BY-MA',
        name: "Mahilyowskaya voblasts'",
        country_id: '112',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'BY-MI',
        name: "Minskaya voblasts'",
        country_id: '112',
        state_cd: 'MI'
      },
      {
        state_cd_full: 'BY-VI',
        name: "Vitsyebskaya voblasts'",
        country_id: '112',
        state_cd: 'VI'
      }
    ]
  },
  {
    country_cd: 'BE',
    name: 'Belgium',
    country_id: 56,
    states: [
      {
        state_cd_full: 'BE-BRU',
        name: 'Brussels Hoofdstedelijk Gewest',
        country_id: '56',
        state_cd: 'BRU'
      },
      {
        state_cd_full: 'BE-VAN',
        name: 'Antwerpen',
        country_id: '56',
        state_cd: 'VAN'
      },
      {
        state_cd_full: 'BE-VBR',
        name: 'Vlaams-Brabant',
        country_id: '56',
        state_cd: 'VBR'
      },
      {
        state_cd_full: 'BE-VLI',
        name: 'Limburg',
        country_id: '56',
        state_cd: 'VLI'
      },
      {
        state_cd_full: 'BE-VOV',
        name: 'Oost-Vlaanderen',
        country_id: '56',
        state_cd: 'VOV'
      },
      {
        state_cd_full: 'BE-VWV',
        name: 'West-Vlaanderen',
        country_id: '56',
        state_cd: 'VWV'
      },
      {
        state_cd_full: 'BE-WBR',
        name: 'Brabant wallon',
        country_id: '56',
        state_cd: 'WBR'
      },
      {
        state_cd_full: 'BE-WHT',
        name: 'Hainaut',
        country_id: '56',
        state_cd: 'WHT'
      },
      {
        state_cd_full: 'BE-WLG',
        name: 'Liege',
        country_id: '56',
        state_cd: 'WLG'
      },
      {
        state_cd_full: 'BE-WLX',
        name: 'Luxembourg',
        country_id: '56',
        state_cd: 'WLX'
      },
      {
        state_cd_full: 'BE-WNA',
        name: 'Namur',
        country_id: '56',
        state_cd: 'WNA'
      }
    ]
  },
  {
    country_cd: 'BZ',
    name: 'Belize',
    country_id: 84,
    states: [
      {
        state_cd_full: 'BZ-BZ',
        name: 'Belize',
        country_id: '84',
        state_cd: 'BZ'
      },
      {
        state_cd_full: 'BZ-CY',
        name: 'Cayo',
        country_id: '84',
        state_cd: 'CY'
      },
      {
        state_cd_full: 'BZ-CZL',
        name: 'Corozal',
        country_id: '84',
        state_cd: 'CZL'
      },
      {
        state_cd_full: 'BZ-OW',
        name: 'Orange Walk',
        country_id: '84',
        state_cd: 'OW'
      },
      {
        state_cd_full: 'BZ-SC',
        name: 'Stann Creek',
        country_id: '84',
        state_cd: 'SC'
      },
      {
        state_cd_full: 'BZ-TOL',
        name: 'Toledo',
        country_id: '84',
        state_cd: 'TOL'
      }
    ]
  },
  {
    country_cd: 'BJ',
    name: 'Benin',
    country_id: 204,
    states: [
      {
        state_cd_full: 'BJ-AK',
        name: 'Atacora',
        country_id: '204',
        state_cd: 'AK'
      },
      {
        state_cd_full: 'BJ-AL',
        name: 'Alibori',
        country_id: '204',
        state_cd: 'AL'
      },
      {
        state_cd_full: 'BJ-AQ',
        name: 'Atlantique',
        country_id: '204',
        state_cd: 'AQ'
      },
      {
        state_cd_full: 'BJ-BO',
        name: 'Borgou',
        country_id: '204',
        state_cd: 'BO'
      },
      {
        state_cd_full: 'BJ-DO',
        name: 'Donga',
        country_id: '204',
        state_cd: 'DO'
      },
      {
        state_cd_full: 'BJ-LI',
        name: 'Littoral',
        country_id: '204',
        state_cd: 'LI'
      },
      {
        state_cd_full: 'BJ-MO',
        name: 'Mono',
        country_id: '204',
        state_cd: 'MO'
      },
      {
        state_cd_full: 'BJ-OU',
        name: 'Oueme',
        country_id: '204',
        state_cd: 'OU'
      },
      {
        state_cd_full: 'BJ-PL',
        name: 'Plateau',
        country_id: '204',
        state_cd: 'PL'
      },
      {
        state_cd_full: 'BJ-ZO',
        name: 'Zou',
        country_id: '204',
        state_cd: 'ZO'
      }
    ]
  },
  {
    country_cd: 'BM',
    name: 'Bermuda',
    country_id: 60,
    states: [
      {
        state_cd_full: 'BM-01',
        name: 'Saint George',
        country_id: '60',
        state_cd: '01'
      },
      {
        state_cd_full: 'BM-11',
        name: 'Hamilton',
        country_id: '60',
        state_cd: '11'
      }
    ]
  },
  {
    country_cd: 'BT',
    name: 'Bhutan',
    country_id: 64,
    states: [
      {
        state_cd_full: 'BT-11',
        name: 'Paro',
        country_id: '64',
        state_cd: '11'
      },
      {
        state_cd_full: 'BT-12',
        name: 'Chhukha',
        country_id: '64',
        state_cd: '12'
      },
      {
        state_cd_full: 'BT-14',
        name: 'Samtse',
        country_id: '64',
        state_cd: '14'
      },
      {
        state_cd_full: 'BT-15',
        name: 'Thimphu',
        country_id: '64',
        state_cd: '15'
      },
      {
        state_cd_full: 'BT-21',
        name: 'Tsirang',
        country_id: '64',
        state_cd: '21'
      },
      {
        state_cd_full: 'BT-23',
        name: 'Punakha',
        country_id: '64',
        state_cd: '23'
      },
      {
        state_cd_full: 'BT-24',
        name: 'Wangdue Phodrang',
        country_id: '64',
        state_cd: '24'
      },
      {
        state_cd_full: 'BT-32',
        name: 'Trongsa',
        country_id: '64',
        state_cd: '32'
      },
      {
        state_cd_full: 'BT-33',
        name: 'Bumthang',
        country_id: '64',
        state_cd: '33'
      },
      {
        state_cd_full: 'BT-41',
        name: 'Trashigang',
        country_id: '64',
        state_cd: '41'
      },
      {
        state_cd_full: 'BT-42',
        name: 'Monggar',
        country_id: '64',
        state_cd: '42'
      },
      {
        state_cd_full: 'BT-43',
        name: 'Pemagatshel',
        country_id: '64',
        state_cd: '43'
      },
      {
        state_cd_full: 'BT-44',
        name: 'Lhuentse',
        country_id: '64',
        state_cd: '44'
      },
      {
        state_cd_full: 'BT-45',
        name: 'Samdrup Jongkhar',
        country_id: '64',
        state_cd: '45'
      },
      {
        state_cd_full: 'BT-GA',
        name: 'Gasa',
        country_id: '64',
        state_cd: 'GA'
      },
      {
        state_cd_full: 'BT-TY',
        name: 'Trashi Yangtse',
        country_id: '64',
        state_cd: 'TY'
      }
    ]
  },
  {
    country_cd: 'BO',
    name: 'Bolivia (Plurinational State of)',
    country_id: 68,
    states: [
      {
        state_cd_full: 'BO-B',
        name: 'El Beni',
        country_id: '68',
        state_cd: 'B'
      },
      {
        state_cd_full: 'BO-C',
        name: 'Cochabamba',
        country_id: '68',
        state_cd: 'C'
      },
      {
        state_cd_full: 'BO-H',
        name: 'Chuquisaca',
        country_id: '68',
        state_cd: 'H'
      },
      {
        state_cd_full: 'BO-L',
        name: 'La Paz',
        country_id: '68',
        state_cd: 'L'
      },
      {
        state_cd_full: 'BO-N',
        name: 'Pando',
        country_id: '68',
        state_cd: 'N'
      },
      {
        state_cd_full: 'BO-O',
        name: 'Oruro',
        country_id: '68',
        state_cd: 'O'
      },
      {
        state_cd_full: 'BO-P',
        name: 'Potosi',
        country_id: '68',
        state_cd: 'P'
      },
      {
        state_cd_full: 'BO-S',
        name: 'Santa Cruz',
        country_id: '68',
        state_cd: 'S'
      },
      {
        state_cd_full: 'BO-T',
        name: 'Tarija',
        country_id: '68',
        state_cd: 'T'
      }
    ]
  },
  {
    country_cd: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
    country_id: 535,
    states: [
      {
        state_cd_full: 'BQ-BO',
        name: 'Bonaire',
        country_id: '535',
        state_cd: 'BO'
      },
      {
        state_cd_full: 'BQ-SA',
        name: 'Saba',
        country_id: '535',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'BQ-SE',
        name: 'Sint Eustatius',
        country_id: '535',
        state_cd: 'SE'
      }
    ]
  },
  {
    country_cd: 'BA',
    name: 'Bosnia and Herzegovina',
    country_id: 70,
    states: [
      {
        state_cd_full: 'BA-BIH',
        name: 'Federacija Bosne i Hercegovine',
        country_id: '70',
        state_cd: 'BIH'
      },
      {
        state_cd_full: 'BA-BRC',
        name: 'Brcko distrikt',
        country_id: '70',
        state_cd: 'BRC'
      },
      {
        state_cd_full: 'BA-SRP',
        name: 'Republika Srpska',
        country_id: '70',
        state_cd: 'SRP'
      }
    ]
  },
  {
    country_cd: 'BW',
    name: 'Botswana',
    country_id: 72,
    states: [
      {
        state_cd_full: 'BW-CE',
        name: 'Central',
        country_id: '72',
        state_cd: 'CE'
      },
      {
        state_cd_full: 'BW-KL',
        name: 'Kgatleng',
        country_id: '72',
        state_cd: 'KL'
      },
      {
        state_cd_full: 'BW-KW',
        name: 'Kweneng',
        country_id: '72',
        state_cd: 'KW'
      },
      {
        state_cd_full: 'BW-NE',
        name: 'North East',
        country_id: '72',
        state_cd: 'NE'
      },
      {
        state_cd_full: 'BW-NW',
        name: 'North West',
        country_id: '72',
        state_cd: 'NW'
      },
      {
        state_cd_full: 'BW-SE',
        name: 'South East',
        country_id: '72',
        state_cd: 'SE'
      },
      {
        state_cd_full: 'BW-SO',
        name: 'Southern',
        country_id: '72',
        state_cd: 'SO'
      }
    ]
  },
  {
    country_cd: 'BV',
    name: 'Bouvet Island',
    country_id: 74,
    states: [
      {
        state_cd_full: 'BV-01',
        name: 'Bouvet Island',
        country_id: '74',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'BR',
    name: 'Brazil',
    country_id: 76,
    states: [
      {
        state_cd_full: 'BR-AC',
        name: 'Acre',
        country_id: '76',
        state_cd: 'AC'
      },
      {
        state_cd_full: 'BR-AL',
        name: 'Alagoas',
        country_id: '76',
        state_cd: 'AL'
      },
      {
        state_cd_full: 'BR-AM',
        name: 'Amazonas',
        country_id: '76',
        state_cd: 'AM'
      },
      {
        state_cd_full: 'BR-AP',
        name: 'Amapa',
        country_id: '76',
        state_cd: 'AP'
      },
      {
        state_cd_full: 'BR-BA',
        name: 'Bahia',
        country_id: '76',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'BR-CE',
        name: 'Ceara',
        country_id: '76',
        state_cd: 'CE'
      },
      {
        state_cd_full: 'BR-DF',
        name: 'Distrito Federal',
        country_id: '76',
        state_cd: 'DF'
      },
      {
        state_cd_full: 'BR-ES',
        name: 'Espirito Santo',
        country_id: '76',
        state_cd: 'ES'
      },
      {
        state_cd_full: 'BR-GO',
        name: 'Goias',
        country_id: '76',
        state_cd: 'GO'
      },
      {
        state_cd_full: 'BR-MA',
        name: 'Maranhao',
        country_id: '76',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'BR-MG',
        name: 'Minas Gerais',
        country_id: '76',
        state_cd: 'MG'
      },
      {
        state_cd_full: 'BR-MS',
        name: 'Mato Grosso do Sul',
        country_id: '76',
        state_cd: 'MS'
      },
      {
        state_cd_full: 'BR-MT',
        name: 'Mato Grosso',
        country_id: '76',
        state_cd: 'MT'
      },
      {
        state_cd_full: 'BR-PA',
        name: 'Para',
        country_id: '76',
        state_cd: 'PA'
      },
      {
        state_cd_full: 'BR-PB',
        name: 'Paraiba',
        country_id: '76',
        state_cd: 'PB'
      },
      {
        state_cd_full: 'BR-PE',
        name: 'Pernambuco',
        country_id: '76',
        state_cd: 'PE'
      },
      {
        state_cd_full: 'BR-PI',
        name: 'Piaui',
        country_id: '76',
        state_cd: 'PI'
      },
      {
        state_cd_full: 'BR-PR',
        name: 'Parana',
        country_id: '76',
        state_cd: 'PR'
      },
      {
        state_cd_full: 'BR-RJ',
        name: 'Rio de Janeiro',
        country_id: '76',
        state_cd: 'RJ'
      },
      {
        state_cd_full: 'BR-RN',
        name: 'Rio Grande do Norte',
        country_id: '76',
        state_cd: 'RN'
      },
      {
        state_cd_full: 'BR-RO',
        name: 'Rondonia',
        country_id: '76',
        state_cd: 'RO'
      },
      {
        state_cd_full: 'BR-RR',
        name: 'Roraima',
        country_id: '76',
        state_cd: 'RR'
      },
      {
        state_cd_full: 'BR-RS',
        name: 'Rio Grande do Sul',
        country_id: '76',
        state_cd: 'RS'
      },
      {
        state_cd_full: 'BR-SC',
        name: 'Santa Catarina',
        country_id: '76',
        state_cd: 'SC'
      },
      {
        state_cd_full: 'BR-SE',
        name: 'Sergipe',
        country_id: '76',
        state_cd: 'SE'
      },
      {
        state_cd_full: 'BR-SP',
        name: 'Sao Paulo',
        country_id: '76',
        state_cd: 'SP'
      },
      {
        state_cd_full: 'BR-TO',
        name: 'Tocantins',
        country_id: '76',
        state_cd: 'TO'
      }
    ]
  },
  {
    country_cd: 'IO',
    name: 'British Indian Ocean Territory',
    country_id: 86,
    states: [
      {
        state_cd_full: 'IO-01',
        name: 'British Indian Ocean Territory',
        country_id: '86',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'BN',
    name: 'Brunei Darussalam',
    country_id: 96,
    states: [
      {
        state_cd_full: 'BN-BE',
        name: 'Belait',
        country_id: '96',
        state_cd: 'BE'
      },
      {
        state_cd_full: 'BN-BM',
        name: 'Brunei-Muara',
        country_id: '96',
        state_cd: 'BM'
      },
      {
        state_cd_full: 'BN-TE',
        name: 'Temburong',
        country_id: '96',
        state_cd: 'TE'
      },
      {
        state_cd_full: 'BN-TU',
        name: 'Tutong',
        country_id: '96',
        state_cd: 'TU'
      }
    ]
  },
  {
    country_cd: 'BG',
    name: 'Bulgaria',
    country_id: 100,
    states: [
      {
        state_cd_full: 'BG-01',
        name: 'Blagoevgrad',
        country_id: '100',
        state_cd: '01'
      },
      {
        state_cd_full: 'BG-02',
        name: 'Burgas',
        country_id: '100',
        state_cd: '02'
      },
      {
        state_cd_full: 'BG-03',
        name: 'Varna',
        country_id: '100',
        state_cd: '03'
      },
      {
        state_cd_full: 'BG-04',
        name: 'Veliko Tarnovo',
        country_id: '100',
        state_cd: '04'
      },
      {
        state_cd_full: 'BG-05',
        name: 'Vidin',
        country_id: '100',
        state_cd: '05'
      },
      {
        state_cd_full: 'BG-06',
        name: 'Vratsa',
        country_id: '100',
        state_cd: '06'
      },
      {
        state_cd_full: 'BG-07',
        name: 'Gabrovo',
        country_id: '100',
        state_cd: '07'
      },
      {
        state_cd_full: 'BG-08',
        name: 'Dobrich',
        country_id: '100',
        state_cd: '08'
      },
      {
        state_cd_full: 'BG-09',
        name: 'Kardzhali',
        country_id: '100',
        state_cd: '09'
      },
      {
        state_cd_full: 'BG-10',
        name: 'Kyustendil',
        country_id: '100',
        state_cd: '10'
      },
      {
        state_cd_full: 'BG-11',
        name: 'Lovech',
        country_id: '100',
        state_cd: '11'
      },
      {
        state_cd_full: 'BG-12',
        name: 'Montana',
        country_id: '100',
        state_cd: '12'
      },
      {
        state_cd_full: 'BG-13',
        name: 'Pazardzhik',
        country_id: '100',
        state_cd: '13'
      },
      {
        state_cd_full: 'BG-14',
        name: 'Pernik',
        country_id: '100',
        state_cd: '14'
      },
      {
        state_cd_full: 'BG-15',
        name: 'Pleven',
        country_id: '100',
        state_cd: '15'
      },
      {
        state_cd_full: 'BG-16',
        name: 'Plovdiv',
        country_id: '100',
        state_cd: '16'
      },
      {
        state_cd_full: 'BG-17',
        name: 'Razgrad',
        country_id: '100',
        state_cd: '17'
      },
      {
        state_cd_full: 'BG-18',
        name: 'Ruse',
        country_id: '100',
        state_cd: '18'
      },
      {
        state_cd_full: 'BG-19',
        name: 'Silistra',
        country_id: '100',
        state_cd: '19'
      },
      {
        state_cd_full: 'BG-20',
        name: 'Sliven',
        country_id: '100',
        state_cd: '20'
      },
      {
        state_cd_full: 'BG-21',
        name: 'Smolyan',
        country_id: '100',
        state_cd: '21'
      },
      {
        state_cd_full: 'BG-22',
        name: 'Sofia (stolitsa)',
        country_id: '100',
        state_cd: '22'
      },
      {
        state_cd_full: 'BG-23',
        name: 'Sofia',
        country_id: '100',
        state_cd: '23'
      },
      {
        state_cd_full: 'BG-24',
        name: 'Stara Zagora',
        country_id: '100',
        state_cd: '24'
      },
      {
        state_cd_full: 'BG-25',
        name: 'Targovishte',
        country_id: '100',
        state_cd: '25'
      },
      {
        state_cd_full: 'BG-26',
        name: 'Haskovo',
        country_id: '100',
        state_cd: '26'
      },
      {
        state_cd_full: 'BG-27',
        name: 'Shumen',
        country_id: '100',
        state_cd: '27'
      },
      {
        state_cd_full: 'BG-28',
        name: 'Yambol',
        country_id: '100',
        state_cd: '28'
      }
    ]
  },
  {
    country_cd: 'BF',
    name: 'Burkina Faso',
    country_id: 854,
    states: [
      {
        state_cd_full: 'BF-BLG',
        name: 'Boulgou',
        country_id: '854',
        state_cd: 'BLG'
      },
      {
        state_cd_full: 'BF-BLK',
        name: 'Boulkiemde',
        country_id: '854',
        state_cd: 'BLK'
      },
      {
        state_cd_full: 'BF-COM',
        name: 'Comoe',
        country_id: '854',
        state_cd: 'COM'
      },
      {
        state_cd_full: 'BF-HOU',
        name: 'Houet',
        country_id: '854',
        state_cd: 'HOU'
      },
      {
        state_cd_full: 'BF-KAD',
        name: 'Kadiogo',
        country_id: '854',
        state_cd: 'KAD'
      },
      {
        state_cd_full: 'BF-KOW',
        name: 'Kourweogo',
        country_id: '854',
        state_cd: 'KOW'
      },
      {
        state_cd_full: 'BF-LER',
        name: 'Leraba',
        country_id: '854',
        state_cd: 'LER'
      },
      {
        state_cd_full: 'BF-MOU',
        name: 'Mouhoun',
        country_id: '854',
        state_cd: 'MOU'
      },
      {
        state_cd_full: 'BF-NAO',
        name: 'Nahouri',
        country_id: '854',
        state_cd: 'NAO'
      },
      {
        state_cd_full: 'BF-PON',
        name: 'Poni',
        country_id: '854',
        state_cd: 'PON'
      },
      {
        state_cd_full: 'BF-SMT',
        name: 'Sanmatenga',
        country_id: '854',
        state_cd: 'SMT'
      },
      {
        state_cd_full: 'BF-TUI',
        name: 'Tuy',
        country_id: '854',
        state_cd: 'TUI'
      },
      {
        state_cd_full: 'BF-YAT',
        name: 'Yatenga',
        country_id: '854',
        state_cd: 'YAT'
      },
      {
        state_cd_full: 'BF-ZOU',
        name: 'Zoundweogo',
        country_id: '854',
        state_cd: 'ZOU'
      }
    ]
  },
  {
    country_cd: 'BI',
    name: 'Burundi',
    country_id: 108,
    states: [
      {
        state_cd_full: 'BI-BB',
        name: 'Bubanza',
        country_id: '108',
        state_cd: 'BB'
      },
      {
        state_cd_full: 'BI-BM',
        name: 'Bujumbura Mairie',
        country_id: '108',
        state_cd: 'BM'
      },
      {
        state_cd_full: 'BI-CI',
        name: 'Cibitoke',
        country_id: '108',
        state_cd: 'CI'
      },
      {
        state_cd_full: 'BI-GI',
        name: 'Gitega',
        country_id: '108',
        state_cd: 'GI'
      },
      {
        state_cd_full: 'BI-KI',
        name: 'Kirundo',
        country_id: '108',
        state_cd: 'KI'
      },
      {
        state_cd_full: 'BI-MA',
        name: 'Makamba',
        country_id: '108',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'BI-MW',
        name: 'Mwaro',
        country_id: '108',
        state_cd: 'MW'
      },
      {
        state_cd_full: 'BI-MY',
        name: 'Muyinga',
        country_id: '108',
        state_cd: 'MY'
      },
      {
        state_cd_full: 'BI-NG',
        name: 'Ngozi',
        country_id: '108',
        state_cd: 'NG'
      },
      {
        state_cd_full: 'BI-RT',
        name: 'Rutana',
        country_id: '108',
        state_cd: 'RT'
      },
      {
        state_cd_full: 'BI-RY',
        name: 'Ruyigi',
        country_id: '108',
        state_cd: 'RY'
      }
    ]
  },
  {
    country_cd: 'CV',
    name: 'Cabo Verde',
    country_id: 132,
    states: [
      {
        state_cd_full: 'CV-BV',
        name: 'Boa Vista',
        country_id: '132',
        state_cd: 'BV'
      },
      {
        state_cd_full: 'CV-CA',
        name: 'Santa Catarina',
        country_id: '132',
        state_cd: 'CA'
      },
      {
        state_cd_full: 'CV-PN',
        name: 'Porto Novo',
        country_id: '132',
        state_cd: 'PN'
      },
      {
        state_cd_full: 'CV-PR',
        name: 'Praia',
        country_id: '132',
        state_cd: 'PR'
      },
      {
        state_cd_full: 'CV-SF',
        name: 'Sao Filipe',
        country_id: '132',
        state_cd: 'SF'
      },
      {
        state_cd_full: 'CV-SL',
        name: 'Sal',
        country_id: '132',
        state_cd: 'SL'
      },
      {
        state_cd_full: 'CV-SV',
        name: 'Sao Vicente',
        country_id: '132',
        state_cd: 'SV'
      }
    ]
  },
  {
    country_cd: 'KH',
    name: 'Cambodia',
    country_id: 116,
    states: [
      {
        state_cd_full: 'KH-1',
        name: 'Banteay Mean Chey',
        country_id: '116',
        state_cd: '1'
      },
      {
        state_cd_full: 'KH-10',
        name: 'Kracheh',
        country_id: '116',
        state_cd: '10'
      },
      {
        state_cd_full: 'KH-11',
        name: 'Mondol Kiri',
        country_id: '116',
        state_cd: '11'
      },
      {
        state_cd_full: 'KH-12',
        name: 'Phnom Penh',
        country_id: '116',
        state_cd: '12'
      },
      {
        state_cd_full: 'KH-14',
        name: 'Prey Veaeng',
        country_id: '116',
        state_cd: '14'
      },
      {
        state_cd_full: 'KH-15',
        name: 'Pousaat',
        country_id: '116',
        state_cd: '15'
      },
      {
        state_cd_full: 'KH-16',
        name: 'Rotanak Kiri',
        country_id: '116',
        state_cd: '16'
      },
      {
        state_cd_full: 'KH-17',
        name: 'Siem Reab',
        country_id: '116',
        state_cd: '17'
      },
      {
        state_cd_full: 'KH-18',
        name: 'Krong Preah Sihanouk',
        country_id: '116',
        state_cd: '18'
      },
      {
        state_cd_full: 'KH-2',
        name: 'Baat Dambang',
        country_id: '116',
        state_cd: '2'
      },
      {
        state_cd_full: 'KH-20',
        name: 'Svaay Rieng',
        country_id: '116',
        state_cd: '20'
      },
      {
        state_cd_full: 'KH-21',
        name: 'Taakaev',
        country_id: '116',
        state_cd: '21'
      },
      {
        state_cd_full: 'KH-22',
        name: 'Otdar Mean Chey',
        country_id: '116',
        state_cd: '22'
      },
      {
        state_cd_full: 'KH-23',
        name: 'Krong Kaeb',
        country_id: '116',
        state_cd: '23'
      },
      {
        state_cd_full: 'KH-24',
        name: 'Krong Pailin',
        country_id: '116',
        state_cd: '24'
      },
      {
        state_cd_full: 'KH-3',
        name: 'Kampong Chaam',
        country_id: '116',
        state_cd: '3'
      },
      {
        state_cd_full: 'KH-4',
        name: 'Kampong Chhnang',
        country_id: '116',
        state_cd: '4'
      },
      {
        state_cd_full: 'KH-5',
        name: 'Kampong Spueu',
        country_id: '116',
        state_cd: '5'
      },
      {
        state_cd_full: 'KH-6',
        name: 'Kampong Thum',
        country_id: '116',
        state_cd: '6'
      },
      {
        state_cd_full: 'KH-7',
        name: 'Kampot',
        country_id: '116',
        state_cd: '7'
      },
      {
        state_cd_full: 'KH-8',
        name: 'Kandaal',
        country_id: '116',
        state_cd: '8'
      }
    ]
  },
  {
    country_cd: 'CM',
    name: 'Cameroon',
    country_id: 120,
    states: [
      {
        state_cd_full: 'CM-AD',
        name: 'Adamaoua',
        country_id: '120',
        state_cd: 'AD'
      },
      {
        state_cd_full: 'CM-CE',
        name: 'Centre',
        country_id: '120',
        state_cd: 'CE'
      },
      {
        state_cd_full: 'CM-EN',
        name: 'Extreme-Nord',
        country_id: '120',
        state_cd: 'EN'
      },
      {
        state_cd_full: 'CM-ES',
        name: 'Est',
        country_id: '120',
        state_cd: 'ES'
      },
      {
        state_cd_full: 'CM-LT',
        name: 'Littoral',
        country_id: '120',
        state_cd: 'LT'
      },
      {
        state_cd_full: 'CM-NO',
        name: 'Nord',
        country_id: '120',
        state_cd: 'NO'
      },
      {
        state_cd_full: 'CM-NW',
        name: 'Nord-Ouest',
        country_id: '120',
        state_cd: 'NW'
      },
      {
        state_cd_full: 'CM-OU',
        name: 'Ouest',
        country_id: '120',
        state_cd: 'OU'
      },
      {
        state_cd_full: 'CM-SU',
        name: 'Sud',
        country_id: '120',
        state_cd: 'SU'
      },
      {
        state_cd_full: 'CM-SW',
        name: 'Sud-Ouest',
        country_id: '120',
        state_cd: 'SW'
      }
    ]
  },
  {
    country_cd: 'CA',
    name: 'Canada',
    country_id: 124,
    states: [
      {
        state_cd_full: 'CA-AB',
        name: 'Alberta',
        country_id: '124',
        state_cd: 'AB'
      },
      {
        state_cd_full: 'CA-BC',
        name: 'British Columbia',
        country_id: '124',
        state_cd: 'BC'
      },
      {
        state_cd_full: 'CA-MB',
        name: 'Manitoba',
        country_id: '124',
        state_cd: 'MB'
      },
      {
        state_cd_full: 'CA-NB',
        name: 'New Brunswick',
        country_id: '124',
        state_cd: 'NB'
      },
      {
        state_cd_full: 'CA-NL',
        name: 'Newfoundland and Labrador',
        country_id: '124',
        state_cd: 'NL'
      },
      {
        state_cd_full: 'CA-NS',
        name: 'Nova Scotia',
        country_id: '124',
        state_cd: 'NS'
      },
      {
        state_cd_full: 'CA-NT',
        name: 'Northwest Territories',
        country_id: '124',
        state_cd: 'NT'
      },
      {
        state_cd_full: 'CA-NU',
        name: 'Nunavut',
        country_id: '124',
        state_cd: 'NU'
      },
      {
        state_cd_full: 'CA-ON',
        name: 'Ontario',
        country_id: '124',
        state_cd: 'ON'
      },
      {
        state_cd_full: 'CA-PE',
        name: 'Prince Edward Island',
        country_id: '124',
        state_cd: 'PE'
      },
      {
        state_cd_full: 'CA-QC',
        name: 'Quebec',
        country_id: '124',
        state_cd: 'QC'
      },
      {
        state_cd_full: 'CA-SK',
        name: 'Saskatchewan',
        country_id: '124',
        state_cd: 'SK'
      },
      {
        state_cd_full: 'CA-YT',
        name: 'Yukon',
        country_id: '124',
        state_cd: 'YT'
      }
    ]
  },
  {
    country_cd: 'KY',
    name: 'Cayman Islands',
    country_id: 136,
    states: [
      {
        state_cd_full: 'KY-01',
        name: 'Cayman Islands',
        country_id: '136',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'CF',
    name: 'Central African Republic',
    country_id: 140,
    states: [
      {
        state_cd_full: 'CF-AC',
        name: 'Ouham',
        country_id: '140',
        state_cd: 'AC'
      },
      {
        state_cd_full: 'CF-BGF',
        name: 'Bangui',
        country_id: '140',
        state_cd: 'BGF'
      },
      {
        state_cd_full: 'CF-HK',
        name: 'Haute-Kotto',
        country_id: '140',
        state_cd: 'HK'
      },
      {
        state_cd_full: 'CF-NM',
        name: 'Nana-Mambere',
        country_id: '140',
        state_cd: 'NM'
      },
      {
        state_cd_full: 'CF-OP',
        name: 'Ouham-Pende',
        country_id: '140',
        state_cd: 'OP'
      }
    ]
  },
  {
    country_cd: 'TD',
    name: 'Chad',
    country_id: 148,
    states: [
      {
        state_cd_full: 'TD-GR',
        name: 'Guera',
        country_id: '148',
        state_cd: 'GR'
      },
      {
        state_cd_full: 'TD-HL',
        name: 'Hadjer Lamis',
        country_id: '148',
        state_cd: 'HL'
      },
      {
        state_cd_full: 'TD-LO',
        name: 'Logone-Occidental',
        country_id: '148',
        state_cd: 'LO'
      },
      {
        state_cd_full: 'TD-ME',
        name: 'Mayo-Kebbi-Est',
        country_id: '148',
        state_cd: 'ME'
      },
      {
        state_cd_full: 'TD-ND',
        name: 'Ville de Ndjamena',
        country_id: '148',
        state_cd: 'ND'
      },
      {
        state_cd_full: 'TD-OD',
        name: 'Ouaddai',
        country_id: '148',
        state_cd: 'OD'
      },
      {
        state_cd_full: 'TD-TI',
        name: 'Tibesti',
        country_id: '148',
        state_cd: 'TI'
      }
    ]
  },
  {
    country_cd: 'CL',
    name: 'Chile',
    country_id: 152,
    states: [
      {
        state_cd_full: 'CL-AI',
        name: 'Aisen del General Carlos Ibanez del Campo',
        country_id: '152',
        state_cd: 'AI'
      },
      {
        state_cd_full: 'CL-AN',
        name: 'Antofagasta',
        country_id: '152',
        state_cd: 'AN'
      },
      {
        state_cd_full: 'CL-AP',
        name: 'Arica y Parinacota',
        country_id: '152',
        state_cd: 'AP'
      },
      {
        state_cd_full: 'CL-AR',
        name: 'La Araucania',
        country_id: '152',
        state_cd: 'AR'
      },
      {
        state_cd_full: 'CL-AT',
        name: 'Atacama',
        country_id: '152',
        state_cd: 'AT'
      },
      {
        state_cd_full: 'CL-BI',
        name: 'Biobio',
        country_id: '152',
        state_cd: 'BI'
      },
      {
        state_cd_full: 'CL-CO',
        name: 'Coquimbo',
        country_id: '152',
        state_cd: 'CO'
      },
      {
        state_cd_full: 'CL-LI',
        name: "Libertador General Bernardo O'Higgins",
        country_id: '152',
        state_cd: 'LI'
      },
      {
        state_cd_full: 'CL-LL',
        name: 'Los Lagos',
        country_id: '152',
        state_cd: 'LL'
      },
      {
        state_cd_full: 'CL-LR',
        name: 'Los Rios',
        country_id: '152',
        state_cd: 'LR'
      },
      {
        state_cd_full: 'CL-MA',
        name: 'Magallanes',
        country_id: '152',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'CL-ML',
        name: 'Maule',
        country_id: '152',
        state_cd: 'ML'
      },
      {
        state_cd_full: 'CL-RM',
        name: 'Region Metropolitana de Santiago',
        country_id: '152',
        state_cd: 'RM'
      },
      {
        state_cd_full: 'CL-TA',
        name: 'Tarapaca',
        country_id: '152',
        state_cd: 'TA'
      },
      {
        state_cd_full: 'CL-VS',
        name: 'Valparaiso',
        country_id: '152',
        state_cd: 'VS'
      }
    ]
  },
  {
    country_cd: 'CN',
    name: 'China',
    country_id: 156,
    states: [
      {
        state_cd_full: 'CN-AH',
        name: 'Anhui',
        country_id: '156',
        state_cd: 'AH'
      },
      {
        state_cd_full: 'CN-BJ',
        name: 'Beijing',
        country_id: '156',
        state_cd: 'BJ'
      },
      {
        state_cd_full: 'CN-CQ',
        name: 'Chongqing',
        country_id: '156',
        state_cd: 'CQ'
      },
      {
        state_cd_full: 'CN-FJ',
        name: 'Fujian',
        country_id: '156',
        state_cd: 'FJ'
      },
      {
        state_cd_full: 'CN-GD',
        name: 'Guangdong',
        country_id: '156',
        state_cd: 'GD'
      },
      {
        state_cd_full: 'CN-GS',
        name: 'Gansu',
        country_id: '156',
        state_cd: 'GS'
      },
      {
        state_cd_full: 'CN-GX',
        name: 'Guangxi',
        country_id: '156',
        state_cd: 'GX'
      },
      {
        state_cd_full: 'CN-GZ',
        name: 'Guizhou',
        country_id: '156',
        state_cd: 'GZ'
      },
      {
        state_cd_full: 'CN-HA',
        name: 'Henan',
        country_id: '156',
        state_cd: 'HA'
      },
      {
        state_cd_full: 'CN-HB',
        name: 'Hubei',
        country_id: '156',
        state_cd: 'HB'
      },
      {
        state_cd_full: 'CN-HE',
        name: 'Hebei',
        country_id: '156',
        state_cd: 'HE'
      },
      {
        state_cd_full: 'CN-HI',
        name: 'Hainan',
        country_id: '156',
        state_cd: 'HI'
      },
      {
        state_cd_full: 'CN-HL',
        name: 'Heilongjiang',
        country_id: '156',
        state_cd: 'HL'
      },
      {
        state_cd_full: 'CN-HN',
        name: 'Hunan',
        country_id: '156',
        state_cd: 'HN'
      },
      {
        state_cd_full: 'CN-JL',
        name: 'Jilin',
        country_id: '156',
        state_cd: 'JL'
      },
      {
        state_cd_full: 'CN-JS',
        name: 'Jiangsu',
        country_id: '156',
        state_cd: 'JS'
      },
      {
        state_cd_full: 'CN-JX',
        name: 'Jiangxi',
        country_id: '156',
        state_cd: 'JX'
      },
      {
        state_cd_full: 'CN-LN',
        name: 'Liaoning',
        country_id: '156',
        state_cd: 'LN'
      },
      {
        state_cd_full: 'CN-NM',
        name: 'Nei Mongol',
        country_id: '156',
        state_cd: 'NM'
      },
      {
        state_cd_full: 'CN-NX',
        name: 'Ningxia',
        country_id: '156',
        state_cd: 'NX'
      },
      {
        state_cd_full: 'CN-QH',
        name: 'Qinghai',
        country_id: '156',
        state_cd: 'QH'
      },
      {
        state_cd_full: 'CN-SC',
        name: 'Sichuan',
        country_id: '156',
        state_cd: 'SC'
      },
      {
        state_cd_full: 'CN-SD',
        name: 'Shandong',
        country_id: '156',
        state_cd: 'SD'
      },
      {
        state_cd_full: 'CN-SH',
        name: 'Shanghai',
        country_id: '156',
        state_cd: 'SH'
      },
      {
        state_cd_full: 'CN-SN',
        name: 'Shaanxi',
        country_id: '156',
        state_cd: 'SN'
      },
      {
        state_cd_full: 'CN-SX',
        name: 'Shanxi',
        country_id: '156',
        state_cd: 'SX'
      },
      {
        state_cd_full: 'CN-TJ',
        name: 'Tianjin',
        country_id: '156',
        state_cd: 'TJ'
      },
      {
        state_cd_full: 'CN-XJ',
        name: 'Xinjiang',
        country_id: '156',
        state_cd: 'XJ'
      },
      {
        state_cd_full: 'CN-XZ',
        name: 'Xizang',
        country_id: '156',
        state_cd: 'XZ'
      },
      {
        state_cd_full: 'CN-YN',
        name: 'Yunnan',
        country_id: '156',
        state_cd: 'YN'
      },
      {
        state_cd_full: 'CN-ZJ',
        name: 'Zhejiang',
        country_id: '156',
        state_cd: 'ZJ'
      }
    ]
  },
  {
    country_cd: 'CX',
    name: 'Christmas Island',
    country_id: 162,
    states: [
      {
        state_cd_full: 'CX-01',
        name: 'Christmas Island',
        country_id: '162',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'CC',
    name: 'Cocos (Keeling) Islands',
    country_id: 166,
    states: [
      {
        state_cd_full: 'CC-01',
        name: 'Cocos Islands',
        country_id: '166',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'CO',
    name: 'Colombia',
    country_id: 170,
    states: [
      {
        state_cd_full: 'CO-AMA',
        name: 'Amazonas',
        country_id: '170',
        state_cd: 'AMA'
      },
      {
        state_cd_full: 'CO-ANT',
        name: 'Antioquia',
        country_id: '170',
        state_cd: 'ANT'
      },
      {
        state_cd_full: 'CO-ARA',
        name: 'Arauca',
        country_id: '170',
        state_cd: 'ARA'
      },
      {
        state_cd_full: 'CO-ATL',
        name: 'Atlantico',
        country_id: '170',
        state_cd: 'ATL'
      },
      {
        state_cd_full: 'CO-BOL',
        name: 'Bolivar',
        country_id: '170',
        state_cd: 'BOL'
      },
      {
        state_cd_full: 'CO-BOY',
        name: 'Boyaca',
        country_id: '170',
        state_cd: 'BOY'
      },
      {
        state_cd_full: 'CO-CAL',
        name: 'Caldas',
        country_id: '170',
        state_cd: 'CAL'
      },
      {
        state_cd_full: 'CO-CAQ',
        name: 'Caqueta',
        country_id: '170',
        state_cd: 'CAQ'
      },
      {
        state_cd_full: 'CO-CAS',
        name: 'Casanare',
        country_id: '170',
        state_cd: 'CAS'
      },
      {
        state_cd_full: 'CO-CAU',
        name: 'Cauca',
        country_id: '170',
        state_cd: 'CAU'
      },
      {
        state_cd_full: 'CO-CES',
        name: 'Cesar',
        country_id: '170',
        state_cd: 'CES'
      },
      {
        state_cd_full: 'CO-CHO',
        name: 'Choco',
        country_id: '170',
        state_cd: 'CHO'
      },
      {
        state_cd_full: 'CO-COR',
        name: 'Cordoba',
        country_id: '170',
        state_cd: 'COR'
      },
      {
        state_cd_full: 'CO-CUN',
        name: 'Cundinamarca',
        country_id: '170',
        state_cd: 'CUN'
      },
      {
        state_cd_full: 'CO-DC',
        name: 'Distrito Capital de Bogota',
        country_id: '170',
        state_cd: 'DC'
      },
      {
        state_cd_full: 'CO-GUA',
        name: 'Guainia',
        country_id: '170',
        state_cd: 'GUA'
      },
      {
        state_cd_full: 'CO-GUV',
        name: 'Guaviare',
        country_id: '170',
        state_cd: 'GUV'
      },
      {
        state_cd_full: 'CO-HUI',
        name: 'Huila',
        country_id: '170',
        state_cd: 'HUI'
      },
      {
        state_cd_full: 'CO-LAG',
        name: 'La Guajira',
        country_id: '170',
        state_cd: 'LAG'
      },
      {
        state_cd_full: 'CO-MAG',
        name: 'Magdalena',
        country_id: '170',
        state_cd: 'MAG'
      },
      {
        state_cd_full: 'CO-MET',
        name: 'Meta',
        country_id: '170',
        state_cd: 'MET'
      },
      {
        state_cd_full: 'CO-NAR',
        name: 'Narino',
        country_id: '170',
        state_cd: 'NAR'
      },
      {
        state_cd_full: 'CO-NSA',
        name: 'Norte de Santander',
        country_id: '170',
        state_cd: 'NSA'
      },
      {
        state_cd_full: 'CO-PUT',
        name: 'Putumayo',
        country_id: '170',
        state_cd: 'PUT'
      },
      {
        state_cd_full: 'CO-QUI',
        name: 'Quindio',
        country_id: '170',
        state_cd: 'QUI'
      },
      {
        state_cd_full: 'CO-RIS',
        name: 'Risaralda',
        country_id: '170',
        state_cd: 'RIS'
      },
      {
        state_cd_full: 'CO-SAN',
        name: 'Santander',
        country_id: '170',
        state_cd: 'SAN'
      },
      {
        state_cd_full: 'CO-SAP',
        name: 'San Andres, Providencia y Santa Catalina',
        country_id: '170',
        state_cd: 'SAP'
      },
      {
        state_cd_full: 'CO-SUC',
        name: 'Sucre',
        country_id: '170',
        state_cd: 'SUC'
      },
      {
        state_cd_full: 'CO-TOL',
        name: 'Tolima',
        country_id: '170',
        state_cd: 'TOL'
      },
      {
        state_cd_full: 'CO-VAC',
        name: 'Valle del Cauca',
        country_id: '170',
        state_cd: 'VAC'
      },
      {
        state_cd_full: 'CO-VAU',
        name: 'Vaupes',
        country_id: '170',
        state_cd: 'VAU'
      },
      {
        state_cd_full: 'CO-VID',
        name: 'Vichada',
        country_id: '170',
        state_cd: 'VID'
      }
    ]
  },
  {
    country_cd: 'KM',
    name: 'Comoros',
    country_id: 174,
    states: [
      {
        state_cd_full: 'KM-A',
        name: 'Anjouan',
        country_id: '174',
        state_cd: 'A'
      },
      {
        state_cd_full: 'KM-G',
        name: 'Grande Comore',
        country_id: '174',
        state_cd: 'G'
      }
    ]
  },
  {
    country_cd: 'CG',
    name: 'Congo',
    country_id: 178,
    states: [
      {
        state_cd_full: 'CG-13',
        name: 'Sangha',
        country_id: '178',
        state_cd: '13'
      },
      {
        state_cd_full: 'CG-15',
        name: 'Cuvette-Ouest',
        country_id: '178',
        state_cd: '15'
      },
      {
        state_cd_full: 'CG-16',
        name: 'Pointe-Noire',
        country_id: '178',
        state_cd: '16'
      },
      {
        state_cd_full: 'CG-8',
        name: 'Cuvette',
        country_id: '178',
        state_cd: '8'
      },
      {
        state_cd_full: 'CG-BZV',
        name: 'Brazzaville',
        country_id: '178',
        state_cd: 'BZV'
      }
    ]
  },
  {
    country_cd: 'CD',
    name: 'Congo, Democratic Republic of the',
    country_id: 180,
    states: [
      {
        state_cd_full: 'CD-BC',
        name: 'Kongo Central',
        country_id: '180',
        state_cd: 'BC'
      },
      {
        state_cd_full: 'CD-EQ',
        name: 'Equateur',
        country_id: '180',
        state_cd: 'EQ'
      },
      {
        state_cd_full: 'CD-HK',
        name: 'Haut-Katanga',
        country_id: '180',
        state_cd: 'HK'
      },
      {
        state_cd_full: 'CD-HU',
        name: 'Haut-Uele',
        country_id: '180',
        state_cd: 'HU'
      },
      {
        state_cd_full: 'CD-IT',
        name: 'Ituri',
        country_id: '180',
        state_cd: 'IT'
      },
      {
        state_cd_full: 'CD-KC',
        name: 'Kasai Central',
        country_id: '180',
        state_cd: 'KC'
      },
      {
        state_cd_full: 'CD-KE',
        name: 'Kasai Oriental',
        country_id: '180',
        state_cd: 'KE'
      },
      {
        state_cd_full: 'CD-KL',
        name: 'Kwilu',
        country_id: '180',
        state_cd: 'KL'
      },
      {
        state_cd_full: 'CD-KN',
        name: 'Kinshasa',
        country_id: '180',
        state_cd: 'KN'
      },
      {
        state_cd_full: 'CD-LU',
        name: 'Lualaba',
        country_id: '180',
        state_cd: 'LU'
      },
      {
        state_cd_full: 'CD-MA',
        name: 'Maniema',
        country_id: '180',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'CD-NK',
        name: 'Nord-Kivu',
        country_id: '180',
        state_cd: 'NK'
      },
      {
        state_cd_full: 'CD-NU',
        name: 'Nord-Ubangi',
        country_id: '180',
        state_cd: 'NU'
      },
      {
        state_cd_full: 'CD-SA',
        name: 'Sankuru',
        country_id: '180',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'CD-SK',
        name: 'Sud-Kivu',
        country_id: '180',
        state_cd: 'SK'
      },
      {
        state_cd_full: 'CD-SU',
        name: 'Sud-Ubangi',
        country_id: '180',
        state_cd: 'SU'
      },
      {
        state_cd_full: 'CD-TA',
        name: 'Tanganyika',
        country_id: '180',
        state_cd: 'TA'
      },
      {
        state_cd_full: 'CD-TO',
        name: 'Tshopo',
        country_id: '180',
        state_cd: 'TO'
      },
      {
        state_cd_full: 'CD-TU',
        name: 'Tshuapa',
        country_id: '180',
        state_cd: 'TU'
      }
    ]
  },
  {
    country_cd: 'CK',
    name: 'Cook Islands',
    country_id: 184,
    states: [
      {
        state_cd_full: 'CK-01',
        name: 'Cook Islands',
        country_id: '184',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'CR',
    name: 'Costa Rica',
    country_id: 188,
    states: [
      {
        state_cd_full: 'CR-A',
        name: 'Alajuela',
        country_id: '188',
        state_cd: 'A'
      },
      {
        state_cd_full: 'CR-C',
        name: 'Cartago',
        country_id: '188',
        state_cd: 'C'
      },
      {
        state_cd_full: 'CR-G',
        name: 'Guanacaste',
        country_id: '188',
        state_cd: 'G'
      },
      {
        state_cd_full: 'CR-H',
        name: 'Heredia',
        country_id: '188',
        state_cd: 'H'
      },
      {
        state_cd_full: 'CR-L',
        name: 'Limon',
        country_id: '188',
        state_cd: 'L'
      },
      {
        state_cd_full: 'CR-P',
        name: 'Puntarenas',
        country_id: '188',
        state_cd: 'P'
      },
      {
        state_cd_full: 'CR-SJ',
        name: 'San Jose',
        country_id: '188',
        state_cd: 'SJ'
      }
    ]
  },
  {
    country_cd: 'HR',
    name: 'Croatia',
    country_id: 191,
    states: [
      {
        state_cd_full: 'HR-01',
        name: 'Zagrebacka zupanija',
        country_id: '191',
        state_cd: '01'
      },
      {
        state_cd_full: 'HR-02',
        name: 'Krapinsko-zagorska zupanija',
        country_id: '191',
        state_cd: '02'
      },
      {
        state_cd_full: 'HR-03',
        name: 'Sisacko-moslavacka zupanija',
        country_id: '191',
        state_cd: '03'
      },
      {
        state_cd_full: 'HR-04',
        name: 'Karlovacka zupanija',
        country_id: '191',
        state_cd: '04'
      },
      {
        state_cd_full: 'HR-05',
        name: 'Varazdinska zupanija',
        country_id: '191',
        state_cd: '05'
      },
      {
        state_cd_full: 'HR-06',
        name: 'Koprivnicko-krizevacka zupanija',
        country_id: '191',
        state_cd: '06'
      },
      {
        state_cd_full: 'HR-07',
        name: 'Bjelovarsko-bilogorska zupanija',
        country_id: '191',
        state_cd: '07'
      },
      {
        state_cd_full: 'HR-08',
        name: 'Primorsko-goranska zupanija',
        country_id: '191',
        state_cd: '08'
      },
      {
        state_cd_full: 'HR-09',
        name: 'Licko-senjska zupanija',
        country_id: '191',
        state_cd: '09'
      },
      {
        state_cd_full: 'HR-10',
        name: 'Viroviticko-podravska zupanija',
        country_id: '191',
        state_cd: '10'
      },
      {
        state_cd_full: 'HR-11',
        name: 'Pozesko-slavonska zupanija',
        country_id: '191',
        state_cd: '11'
      },
      {
        state_cd_full: 'HR-12',
        name: 'Brodsko-posavska zupanija',
        country_id: '191',
        state_cd: '12'
      },
      {
        state_cd_full: 'HR-13',
        name: 'Zadarska zupanija',
        country_id: '191',
        state_cd: '13'
      },
      {
        state_cd_full: 'HR-14',
        name: 'Osjecko-baranjska zupanija',
        country_id: '191',
        state_cd: '14'
      },
      {
        state_cd_full: 'HR-15',
        name: 'Sibensko-kninska zupanija',
        country_id: '191',
        state_cd: '15'
      },
      {
        state_cd_full: 'HR-16',
        name: 'Vukovarsko-srijemska zupanija',
        country_id: '191',
        state_cd: '16'
      },
      {
        state_cd_full: 'HR-17',
        name: 'Splitsko-dalmatinska zupanija',
        country_id: '191',
        state_cd: '17'
      },
      {
        state_cd_full: 'HR-18',
        name: 'Istarska zupanija',
        country_id: '191',
        state_cd: '18'
      },
      {
        state_cd_full: 'HR-19',
        name: 'Dubrovacko-neretvanska zupanija',
        country_id: '191',
        state_cd: '19'
      },
      {
        state_cd_full: 'HR-20',
        name: 'Medimurska zupanija',
        country_id: '191',
        state_cd: '20'
      },
      {
        state_cd_full: 'HR-21',
        name: 'Grad Zagreb',
        country_id: '191',
        state_cd: '21'
      }
    ]
  },
  {
    country_cd: 'CU',
    name: 'Cuba',
    country_id: 192,
    states: [
      {
        state_cd_full: 'CU-01',
        name: 'Pinar del Rio',
        country_id: '192',
        state_cd: '01'
      },
      {
        state_cd_full: 'CU-03',
        name: 'La Habana',
        country_id: '192',
        state_cd: '03'
      },
      {
        state_cd_full: 'CU-04',
        name: 'Matanzas',
        country_id: '192',
        state_cd: '04'
      },
      {
        state_cd_full: 'CU-05',
        name: 'Villa Clara',
        country_id: '192',
        state_cd: '05'
      },
      {
        state_cd_full: 'CU-06',
        name: 'Cienfuegos',
        country_id: '192',
        state_cd: '06'
      },
      {
        state_cd_full: 'CU-07',
        name: 'Sancti Spiritus',
        country_id: '192',
        state_cd: '07'
      },
      {
        state_cd_full: 'CU-08',
        name: 'Ciego de Avila',
        country_id: '192',
        state_cd: '08'
      },
      {
        state_cd_full: 'CU-09',
        name: 'Camaguey',
        country_id: '192',
        state_cd: '09'
      },
      {
        state_cd_full: 'CU-10',
        name: 'Las Tunas',
        country_id: '192',
        state_cd: '10'
      },
      {
        state_cd_full: 'CU-11',
        name: 'Holguin',
        country_id: '192',
        state_cd: '11'
      },
      {
        state_cd_full: 'CU-12',
        name: 'Granma',
        country_id: '192',
        state_cd: '12'
      },
      {
        state_cd_full: 'CU-13',
        name: 'Santiago de Cuba',
        country_id: '192',
        state_cd: '13'
      },
      {
        state_cd_full: 'CU-14',
        name: 'Guantanamo',
        country_id: '192',
        state_cd: '14'
      },
      {
        state_cd_full: 'CU-15',
        name: 'Artemisa',
        country_id: '192',
        state_cd: '15'
      },
      {
        state_cd_full: 'CU-16',
        name: 'Mayabeque',
        country_id: '192',
        state_cd: '16'
      }
    ]
  },
  {
    country_cd: 'CW',
    name: 'Curaçao',
    country_id: 531,
    states: [
      {
        state_cd_full: 'CW-01',
        name: 'Curacao',
        country_id: '531',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'CY',
    name: 'Cyprus',
    country_id: 196,
    states: [
      {
        state_cd_full: 'CY-01',
        name: 'Lefkosia',
        country_id: '196',
        state_cd: '01'
      },
      {
        state_cd_full: 'CY-02',
        name: 'Lemesos',
        country_id: '196',
        state_cd: '02'
      },
      {
        state_cd_full: 'CY-03',
        name: 'Larnaka',
        country_id: '196',
        state_cd: '03'
      },
      {
        state_cd_full: 'CY-04',
        name: 'Ammochostos',
        country_id: '196',
        state_cd: '04'
      },
      {
        state_cd_full: 'CY-05',
        name: 'Pafos',
        country_id: '196',
        state_cd: '05'
      },
      {
        state_cd_full: 'CY-06',
        name: 'Keryneia',
        country_id: '196',
        state_cd: '06'
      }
    ]
  },
  {
    country_cd: 'CZ',
    name: 'Czechia',
    country_id: 203,
    states: [
      {
        state_cd_full: 'CZ-10',
        name: 'Praha, Hlavni mesto',
        country_id: '203',
        state_cd: '10'
      },
      {
        state_cd_full: 'CZ-20',
        name: 'Stredocesky kraj',
        country_id: '203',
        state_cd: '20'
      },
      {
        state_cd_full: 'CZ-31',
        name: 'Jihocesky kraj',
        country_id: '203',
        state_cd: '31'
      },
      {
        state_cd_full: 'CZ-32',
        name: 'Plzensky kraj',
        country_id: '203',
        state_cd: '32'
      },
      {
        state_cd_full: 'CZ-41',
        name: 'Karlovarsky kraj',
        country_id: '203',
        state_cd: '41'
      },
      {
        state_cd_full: 'CZ-42',
        name: 'Ustecky kraj',
        country_id: '203',
        state_cd: '42'
      },
      {
        state_cd_full: 'CZ-51',
        name: 'Liberecky kraj',
        country_id: '203',
        state_cd: '51'
      },
      {
        state_cd_full: 'CZ-52',
        name: 'Kralovehradecky kraj',
        country_id: '203',
        state_cd: '52'
      },
      {
        state_cd_full: 'CZ-53',
        name: 'Pardubicky kraj',
        country_id: '203',
        state_cd: '53'
      },
      {
        state_cd_full: 'CZ-63',
        name: 'Kraj Vysocina',
        country_id: '203',
        state_cd: '63'
      },
      {
        state_cd_full: 'CZ-64',
        name: 'Jihomoravsky kraj',
        country_id: '203',
        state_cd: '64'
      },
      {
        state_cd_full: 'CZ-71',
        name: 'Olomoucky kraj',
        country_id: '203',
        state_cd: '71'
      },
      {
        state_cd_full: 'CZ-72',
        name: 'Zlinsky kraj',
        country_id: '203',
        state_cd: '72'
      },
      {
        state_cd_full: 'CZ-80',
        name: 'Moravskoslezsky kraj',
        country_id: '203',
        state_cd: '80'
      }
    ]
  },
  {
    country_cd: 'CI',
    name: "Côte d'Ivoire",
    country_id: 384,
    states: [
      {
        state_cd_full: 'CI-AB',
        name: 'Abidjan',
        country_id: '384',
        state_cd: 'AB'
      },
      {
        state_cd_full: 'CI-BS',
        name: 'Bas-Sassandra',
        country_id: '384',
        state_cd: 'BS'
      },
      {
        state_cd_full: 'CI-CM',
        name: 'Comoe',
        country_id: '384',
        state_cd: 'CM'
      },
      {
        state_cd_full: 'CI-DN',
        name: 'Denguele',
        country_id: '384',
        state_cd: 'DN'
      },
      {
        state_cd_full: 'CI-GD',
        name: 'Goh-Djiboua',
        country_id: '384',
        state_cd: 'GD'
      },
      {
        state_cd_full: 'CI-LC',
        name: 'Lacs',
        country_id: '384',
        state_cd: 'LC'
      },
      {
        state_cd_full: 'CI-LG',
        name: 'Lagunes',
        country_id: '384',
        state_cd: 'LG'
      },
      {
        state_cd_full: 'CI-MG',
        name: 'Montagnes',
        country_id: '384',
        state_cd: 'MG'
      },
      {
        state_cd_full: 'CI-SM',
        name: 'Sassandra-Marahoue',
        country_id: '384',
        state_cd: 'SM'
      },
      {
        state_cd_full: 'CI-SV',
        name: 'Savanes',
        country_id: '384',
        state_cd: 'SV'
      },
      {
        state_cd_full: 'CI-VB',
        name: 'Vallee du Bandama',
        country_id: '384',
        state_cd: 'VB'
      },
      {
        state_cd_full: 'CI-WR',
        name: 'Woroba',
        country_id: '384',
        state_cd: 'WR'
      },
      {
        state_cd_full: 'CI-YM',
        name: 'Yamoussoukro',
        country_id: '384',
        state_cd: 'YM'
      },
      {
        state_cd_full: 'CI-ZZ',
        name: 'Zanzan',
        country_id: '384',
        state_cd: 'ZZ'
      }
    ]
  },
  {
    country_cd: 'DK',
    name: 'Denmark',
    country_id: 208,
    states: [
      {
        state_cd_full: 'DK-81',
        name: 'Nordjylland',
        country_id: '208',
        state_cd: '81'
      },
      {
        state_cd_full: 'DK-82',
        name: 'Midtjylland',
        country_id: '208',
        state_cd: '82'
      },
      {
        state_cd_full: 'DK-83',
        name: 'Syddanmark',
        country_id: '208',
        state_cd: '83'
      },
      {
        state_cd_full: 'DK-84',
        name: 'Hovedstaden',
        country_id: '208',
        state_cd: '84'
      },
      {
        state_cd_full: 'DK-85',
        name: 'Sjaelland',
        country_id: '208',
        state_cd: '85'
      }
    ]
  },
  {
    country_cd: 'DJ',
    name: 'Djibouti',
    country_id: 262,
    states: [
      {
        state_cd_full: 'DJ-DJ',
        name: 'Djibouti',
        country_id: '262',
        state_cd: 'DJ'
      }
    ]
  },
  {
    country_cd: 'DM',
    name: 'Dominica',
    country_id: 212,
    states: [
      {
        state_cd_full: 'DM-02',
        name: 'Saint Andrew',
        country_id: '212',
        state_cd: '02'
      },
      {
        state_cd_full: 'DM-04',
        name: 'Saint George',
        country_id: '212',
        state_cd: '04'
      },
      {
        state_cd_full: 'DM-05',
        name: 'Saint John',
        country_id: '212',
        state_cd: '05'
      },
      {
        state_cd_full: 'DM-08',
        name: 'Saint Mark',
        country_id: '212',
        state_cd: '08'
      },
      {
        state_cd_full: 'DM-10',
        name: 'Saint Paul',
        country_id: '212',
        state_cd: '10'
      },
      {
        state_cd_full: 'DM-11',
        name: 'Saint Peter',
        country_id: '212',
        state_cd: '11'
      }
    ]
  },
  {
    country_cd: 'DO',
    name: 'Dominican Republic',
    country_id: 214,
    states: [
      {
        state_cd_full: 'DO-01',
        name: 'Distrito Nacional (Santo Domingo)',
        country_id: '214',
        state_cd: '01'
      },
      {
        state_cd_full: 'DO-02',
        name: 'Azua',
        country_id: '214',
        state_cd: '02'
      },
      {
        state_cd_full: 'DO-03',
        name: 'Baoruco',
        country_id: '214',
        state_cd: '03'
      },
      {
        state_cd_full: 'DO-04',
        name: 'Barahona',
        country_id: '214',
        state_cd: '04'
      },
      {
        state_cd_full: 'DO-05',
        name: 'Dajabon',
        country_id: '214',
        state_cd: '05'
      },
      {
        state_cd_full: 'DO-06',
        name: 'Duarte',
        country_id: '214',
        state_cd: '06'
      },
      {
        state_cd_full: 'DO-08',
        name: 'El Seibo',
        country_id: '214',
        state_cd: '08'
      },
      {
        state_cd_full: 'DO-09',
        name: 'Espaillat',
        country_id: '214',
        state_cd: '09'
      },
      {
        state_cd_full: 'DO-10',
        name: 'Independencia',
        country_id: '214',
        state_cd: '10'
      },
      {
        state_cd_full: 'DO-11',
        name: 'La Altagracia',
        country_id: '214',
        state_cd: '11'
      },
      {
        state_cd_full: 'DO-12',
        name: 'La Romana',
        country_id: '214',
        state_cd: '12'
      },
      {
        state_cd_full: 'DO-13',
        name: 'La Vega',
        country_id: '214',
        state_cd: '13'
      },
      {
        state_cd_full: 'DO-14',
        name: 'Maria Trinidad Sanchez',
        country_id: '214',
        state_cd: '14'
      },
      {
        state_cd_full: 'DO-15',
        name: 'Monte Cristi',
        country_id: '214',
        state_cd: '15'
      },
      {
        state_cd_full: 'DO-16',
        name: 'Pedernales',
        country_id: '214',
        state_cd: '16'
      },
      {
        state_cd_full: 'DO-17',
        name: 'Peravia',
        country_id: '214',
        state_cd: '17'
      },
      {
        state_cd_full: 'DO-18',
        name: 'Puerto Plata',
        country_id: '214',
        state_cd: '18'
      },
      {
        state_cd_full: 'DO-19',
        name: 'Hermanas Mirabal',
        country_id: '214',
        state_cd: '19'
      },
      {
        state_cd_full: 'DO-20',
        name: 'Samana',
        country_id: '214',
        state_cd: '20'
      },
      {
        state_cd_full: 'DO-21',
        name: 'San Cristobal',
        country_id: '214',
        state_cd: '21'
      },
      {
        state_cd_full: 'DO-22',
        name: 'San Juan',
        country_id: '214',
        state_cd: '22'
      },
      {
        state_cd_full: 'DO-23',
        name: 'San Pedro de Macoris',
        country_id: '214',
        state_cd: '23'
      },
      {
        state_cd_full: 'DO-24',
        name: 'Sanchez Ramirez',
        country_id: '214',
        state_cd: '24'
      },
      {
        state_cd_full: 'DO-25',
        name: 'Santiago',
        country_id: '214',
        state_cd: '25'
      },
      {
        state_cd_full: 'DO-26',
        name: 'Santiago Rodriguez',
        country_id: '214',
        state_cd: '26'
      },
      {
        state_cd_full: 'DO-27',
        name: 'Valverde',
        country_id: '214',
        state_cd: '27'
      },
      {
        state_cd_full: 'DO-28',
        name: 'Monsenor Nouel',
        country_id: '214',
        state_cd: '28'
      },
      {
        state_cd_full: 'DO-29',
        name: 'Monte Plata',
        country_id: '214',
        state_cd: '29'
      },
      {
        state_cd_full: 'DO-30',
        name: 'Hato Mayor',
        country_id: '214',
        state_cd: '30'
      },
      {
        state_cd_full: 'DO-31',
        name: 'San Jose de Ocoa',
        country_id: '214',
        state_cd: '31'
      }
    ]
  },
  {
    country_cd: 'EC',
    name: 'Ecuador',
    country_id: 218,
    states: [
      {
        state_cd_full: 'EC-A',
        name: 'Azuay',
        country_id: '218',
        state_cd: 'A'
      },
      {
        state_cd_full: 'EC-B',
        name: 'Bolivar',
        country_id: '218',
        state_cd: 'B'
      },
      {
        state_cd_full: 'EC-C',
        name: 'Carchi',
        country_id: '218',
        state_cd: 'C'
      },
      {
        state_cd_full: 'EC-D',
        name: 'Orellana',
        country_id: '218',
        state_cd: 'D'
      },
      {
        state_cd_full: 'EC-E',
        name: 'Esmeraldas',
        country_id: '218',
        state_cd: 'E'
      },
      {
        state_cd_full: 'EC-F',
        name: 'Canar',
        country_id: '218',
        state_cd: 'F'
      },
      {
        state_cd_full: 'EC-G',
        name: 'Guayas',
        country_id: '218',
        state_cd: 'G'
      },
      {
        state_cd_full: 'EC-H',
        name: 'Chimborazo',
        country_id: '218',
        state_cd: 'H'
      },
      {
        state_cd_full: 'EC-I',
        name: 'Imbabura',
        country_id: '218',
        state_cd: 'I'
      },
      {
        state_cd_full: 'EC-L',
        name: 'Loja',
        country_id: '218',
        state_cd: 'L'
      },
      {
        state_cd_full: 'EC-M',
        name: 'Manabi',
        country_id: '218',
        state_cd: 'M'
      },
      {
        state_cd_full: 'EC-N',
        name: 'Napo',
        country_id: '218',
        state_cd: 'N'
      },
      {
        state_cd_full: 'EC-O',
        name: 'El Oro',
        country_id: '218',
        state_cd: 'O'
      },
      {
        state_cd_full: 'EC-P',
        name: 'Pichincha',
        country_id: '218',
        state_cd: 'P'
      },
      {
        state_cd_full: 'EC-R',
        name: 'Los Rios',
        country_id: '218',
        state_cd: 'R'
      },
      {
        state_cd_full: 'EC-S',
        name: 'Morona Santiago',
        country_id: '218',
        state_cd: 'S'
      },
      {
        state_cd_full: 'EC-SD',
        name: 'Santo Domingo de los Tsachilas',
        country_id: '218',
        state_cd: 'SD'
      },
      {
        state_cd_full: 'EC-SE',
        name: 'Santa Elena',
        country_id: '218',
        state_cd: 'SE'
      },
      {
        state_cd_full: 'EC-T',
        name: 'Tungurahua',
        country_id: '218',
        state_cd: 'T'
      },
      {
        state_cd_full: 'EC-U',
        name: 'Sucumbios',
        country_id: '218',
        state_cd: 'U'
      },
      {
        state_cd_full: 'EC-W',
        name: 'Galapagos',
        country_id: '218',
        state_cd: 'W'
      },
      {
        state_cd_full: 'EC-X',
        name: 'Cotopaxi',
        country_id: '218',
        state_cd: 'X'
      },
      {
        state_cd_full: 'EC-Y',
        name: 'Pastaza',
        country_id: '218',
        state_cd: 'Y'
      },
      {
        state_cd_full: 'EC-Z',
        name: 'Zamora Chinchipe',
        country_id: '218',
        state_cd: 'Z'
      }
    ]
  },
  {
    country_cd: 'EG',
    name: 'Egypt',
    country_id: 818,
    states: [
      {
        state_cd_full: 'EG-ALX',
        name: 'Al Iskandariyah',
        country_id: '818',
        state_cd: 'ALX'
      },
      {
        state_cd_full: 'EG-ASN',
        name: 'Aswan',
        country_id: '818',
        state_cd: 'ASN'
      },
      {
        state_cd_full: 'EG-AST',
        name: 'Asyut',
        country_id: '818',
        state_cd: 'AST'
      },
      {
        state_cd_full: 'EG-BA',
        name: 'Al Bahr al Ahmar',
        country_id: '818',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'EG-BH',
        name: 'Al Buhayrah',
        country_id: '818',
        state_cd: 'BH'
      },
      {
        state_cd_full: 'EG-BNS',
        name: 'Bani Suwayf',
        country_id: '818',
        state_cd: 'BNS'
      },
      {
        state_cd_full: 'EG-C',
        name: 'Al Qahirah',
        country_id: '818',
        state_cd: 'C'
      },
      {
        state_cd_full: 'EG-DK',
        name: 'Ad Daqahliyah',
        country_id: '818',
        state_cd: 'DK'
      },
      {
        state_cd_full: 'EG-DT',
        name: 'Dumyat',
        country_id: '818',
        state_cd: 'DT'
      },
      {
        state_cd_full: 'EG-FYM',
        name: 'Al Fayyum',
        country_id: '818',
        state_cd: 'FYM'
      },
      {
        state_cd_full: 'EG-GH',
        name: 'Al Gharbiyah',
        country_id: '818',
        state_cd: 'GH'
      },
      {
        state_cd_full: 'EG-GZ',
        name: 'Al Jizah',
        country_id: '818',
        state_cd: 'GZ'
      },
      {
        state_cd_full: 'EG-IS',
        name: "Al Isma'iliyah",
        country_id: '818',
        state_cd: 'IS'
      },
      {
        state_cd_full: 'EG-JS',
        name: "Janub Sina'",
        country_id: '818',
        state_cd: 'JS'
      },
      {
        state_cd_full: 'EG-KB',
        name: 'Al Qalyubiyah',
        country_id: '818',
        state_cd: 'KB'
      },
      {
        state_cd_full: 'EG-KFS',
        name: 'Kafr ash Shaykh',
        country_id: '818',
        state_cd: 'KFS'
      },
      {
        state_cd_full: 'EG-KN',
        name: 'Qina',
        country_id: '818',
        state_cd: 'KN'
      },
      {
        state_cd_full: 'EG-LX',
        name: 'Al Uqsur',
        country_id: '818',
        state_cd: 'LX'
      },
      {
        state_cd_full: 'EG-MN',
        name: 'Al Minya',
        country_id: '818',
        state_cd: 'MN'
      },
      {
        state_cd_full: 'EG-MNF',
        name: 'Al Minufiyah',
        country_id: '818',
        state_cd: 'MNF'
      },
      {
        state_cd_full: 'EG-MT',
        name: 'Matruh',
        country_id: '818',
        state_cd: 'MT'
      },
      {
        state_cd_full: 'EG-PTS',
        name: "Bur Sa'id",
        country_id: '818',
        state_cd: 'PTS'
      },
      {
        state_cd_full: 'EG-SHG',
        name: 'Suhaj',
        country_id: '818',
        state_cd: 'SHG'
      },
      {
        state_cd_full: 'EG-SHR',
        name: 'Ash Sharqiyah',
        country_id: '818',
        state_cd: 'SHR'
      },
      {
        state_cd_full: 'EG-SIN',
        name: "Shamal Sina'",
        country_id: '818',
        state_cd: 'SIN'
      },
      {
        state_cd_full: 'EG-SUZ',
        name: 'As Suways',
        country_id: '818',
        state_cd: 'SUZ'
      },
      {
        state_cd_full: 'EG-WAD',
        name: 'Al Wadi al Jadid',
        country_id: '818',
        state_cd: 'WAD'
      }
    ]
  },
  {
    country_cd: 'SV',
    name: 'El Salvador',
    country_id: 222,
    states: [
      {
        state_cd_full: 'SV-AH',
        name: 'Ahuachapan',
        country_id: '222',
        state_cd: 'AH'
      },
      {
        state_cd_full: 'SV-CA',
        name: 'Cabanas',
        country_id: '222',
        state_cd: 'CA'
      },
      {
        state_cd_full: 'SV-CH',
        name: 'Chalatenango',
        country_id: '222',
        state_cd: 'CH'
      },
      {
        state_cd_full: 'SV-CU',
        name: 'Cuscatlan',
        country_id: '222',
        state_cd: 'CU'
      },
      {
        state_cd_full: 'SV-LI',
        name: 'La Libertad',
        country_id: '222',
        state_cd: 'LI'
      },
      {
        state_cd_full: 'SV-MO',
        name: 'Morazan',
        country_id: '222',
        state_cd: 'MO'
      },
      {
        state_cd_full: 'SV-PA',
        name: 'La Paz',
        country_id: '222',
        state_cd: 'PA'
      },
      {
        state_cd_full: 'SV-SA',
        name: 'Santa Ana',
        country_id: '222',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'SV-SM',
        name: 'San Miguel',
        country_id: '222',
        state_cd: 'SM'
      },
      {
        state_cd_full: 'SV-SO',
        name: 'Sonsonate',
        country_id: '222',
        state_cd: 'SO'
      },
      {
        state_cd_full: 'SV-SS',
        name: 'San Salvador',
        country_id: '222',
        state_cd: 'SS'
      },
      {
        state_cd_full: 'SV-SV',
        name: 'San Vicente',
        country_id: '222',
        state_cd: 'SV'
      },
      {
        state_cd_full: 'SV-UN',
        name: 'La Union',
        country_id: '222',
        state_cd: 'UN'
      },
      {
        state_cd_full: 'SV-US',
        name: 'Usulutan',
        country_id: '222',
        state_cd: 'US'
      }
    ]
  },
  {
    country_cd: 'GQ',
    name: 'Equatorial Guinea',
    country_id: 226,
    states: [
      {
        state_cd_full: 'GQ-BN',
        name: 'Bioko Norte',
        country_id: '226',
        state_cd: 'BN'
      },
      {
        state_cd_full: 'GQ-BS',
        name: 'Bioko Sur',
        country_id: '226',
        state_cd: 'BS'
      },
      {
        state_cd_full: 'GQ-LI',
        name: 'Litoral',
        country_id: '226',
        state_cd: 'LI'
      },
      {
        state_cd_full: 'GQ-WN',
        name: 'Wele-Nzas',
        country_id: '226',
        state_cd: 'WN'
      }
    ]
  },
  {
    country_cd: 'ER',
    name: 'Eritrea',
    country_id: 232,
    states: [
      {
        state_cd_full: 'ER-MA',
        name: 'Al Awsat',
        country_id: '232',
        state_cd: 'MA'
      }
    ]
  },
  {
    country_cd: 'EE',
    name: 'Estonia',
    country_id: 233,
    states: [
      {
        state_cd_full: 'EE-37',
        name: 'Harjumaa',
        country_id: '233',
        state_cd: '37'
      },
      {
        state_cd_full: 'EE-39',
        name: 'Hiiumaa',
        country_id: '233',
        state_cd: '39'
      },
      {
        state_cd_full: 'EE-44',
        name: 'Ida-Virumaa',
        country_id: '233',
        state_cd: '44'
      },
      {
        state_cd_full: 'EE-49',
        name: 'Jogevamaa',
        country_id: '233',
        state_cd: '49'
      },
      {
        state_cd_full: 'EE-51',
        name: 'Jarvamaa',
        country_id: '233',
        state_cd: '51'
      },
      {
        state_cd_full: 'EE-57',
        name: 'Laanemaa',
        country_id: '233',
        state_cd: '57'
      },
      {
        state_cd_full: 'EE-59',
        name: 'Laane-Virumaa',
        country_id: '233',
        state_cd: '59'
      },
      {
        state_cd_full: 'EE-65',
        name: 'Polvamaa',
        country_id: '233',
        state_cd: '65'
      },
      {
        state_cd_full: 'EE-67',
        name: 'Parnumaa',
        country_id: '233',
        state_cd: '67'
      },
      {
        state_cd_full: 'EE-70',
        name: 'Raplamaa',
        country_id: '233',
        state_cd: '70'
      },
      {
        state_cd_full: 'EE-74',
        name: 'Saaremaa',
        country_id: '233',
        state_cd: '74'
      },
      {
        state_cd_full: 'EE-78',
        name: 'Tartumaa',
        country_id: '233',
        state_cd: '78'
      },
      {
        state_cd_full: 'EE-82',
        name: 'Valgamaa',
        country_id: '233',
        state_cd: '82'
      },
      {
        state_cd_full: 'EE-84',
        name: 'Viljandimaa',
        country_id: '233',
        state_cd: '84'
      },
      {
        state_cd_full: 'EE-86',
        name: 'Vorumaa',
        country_id: '233',
        state_cd: '86'
      }
    ]
  },
  {
    country_cd: 'SZ',
    name: 'Eswatini',
    country_id: 748,
    states: [
      {
        state_cd_full: 'SZ-HH',
        name: 'Hhohho',
        country_id: '748',
        state_cd: 'HH'
      },
      {
        state_cd_full: 'SZ-LU',
        name: 'Lubombo',
        country_id: '748',
        state_cd: 'LU'
      },
      {
        state_cd_full: 'SZ-MA',
        name: 'Manzini',
        country_id: '748',
        state_cd: 'MA'
      }
    ]
  },
  {
    country_cd: 'ET',
    name: 'Ethiopia',
    country_id: 231,
    states: [
      {
        state_cd_full: 'ET-AA',
        name: 'Adis Abeba',
        country_id: '231',
        state_cd: 'AA'
      },
      {
        state_cd_full: 'ET-AF',
        name: 'Afar',
        country_id: '231',
        state_cd: 'AF'
      },
      {
        state_cd_full: 'ET-AM',
        name: 'Amara',
        country_id: '231',
        state_cd: 'AM'
      },
      {
        state_cd_full: 'ET-BE',
        name: 'Binshangul Gumuz',
        country_id: '231',
        state_cd: 'BE'
      },
      {
        state_cd_full: 'ET-DD',
        name: 'Dire Dawa',
        country_id: '231',
        state_cd: 'DD'
      },
      {
        state_cd_full: 'ET-HA',
        name: 'Hareri Hizb',
        country_id: '231',
        state_cd: 'HA'
      },
      {
        state_cd_full: 'ET-OR',
        name: 'Oromiya',
        country_id: '231',
        state_cd: 'OR'
      },
      {
        state_cd_full: 'ET-SN',
        name: 'YeDebub Biheroch Bihereseboch na Hizboch',
        country_id: '231',
        state_cd: 'SN'
      },
      {
        state_cd_full: 'ET-SO',
        name: 'Sumale',
        country_id: '231',
        state_cd: 'SO'
      },
      {
        state_cd_full: 'ET-TI',
        name: 'Tigray',
        country_id: '231',
        state_cd: 'TI'
      }
    ]
  },
  {
    country_cd: 'FK',
    name: 'Falkland Islands (Malvinas)',
    country_id: 238,
    states: [
      {
        state_cd_full: 'FK-01',
        name: 'Falkland Islands (Malvinas)',
        country_id: '238',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'FO',
    name: 'Faroe Islands',
    country_id: 234,
    states: [
      {
        state_cd_full: 'FO-01',
        name: 'Vagar',
        country_id: '234',
        state_cd: '01'
      },
      {
        state_cd_full: 'FO-11',
        name: 'Suduroy',
        country_id: '234',
        state_cd: '11'
      },
      {
        state_cd_full: 'FO-21',
        name: 'Streymoy',
        country_id: '234',
        state_cd: '21'
      },
      {
        state_cd_full: 'FO-31',
        name: 'Nordoyar',
        country_id: '234',
        state_cd: '31'
      },
      {
        state_cd_full: 'FO-41',
        name: 'Eysturoy',
        country_id: '234',
        state_cd: '41'
      }
    ]
  },
  {
    country_cd: 'FJ',
    name: 'Fiji',
    country_id: 242,
    states: [
      {
        state_cd_full: 'FJ-C',
        name: 'Central',
        country_id: '242',
        state_cd: 'C'
      },
      {
        state_cd_full: 'FJ-E',
        name: 'Eastern',
        country_id: '242',
        state_cd: 'E'
      },
      {
        state_cd_full: 'FJ-N',
        name: 'Northern',
        country_id: '242',
        state_cd: 'N'
      },
      {
        state_cd_full: 'FJ-W',
        name: 'Western',
        country_id: '242',
        state_cd: 'W'
      }
    ]
  },
  {
    country_cd: 'FI',
    name: 'Finland',
    country_id: 246,
    states: [
      {
        state_cd_full: 'FI-02',
        name: 'Etela-Karjala',
        country_id: '246',
        state_cd: '02'
      },
      {
        state_cd_full: 'FI-03',
        name: 'Etela-Pohjanmaa',
        country_id: '246',
        state_cd: '03'
      },
      {
        state_cd_full: 'FI-04',
        name: 'Etela-Savo',
        country_id: '246',
        state_cd: '04'
      },
      {
        state_cd_full: 'FI-05',
        name: 'Kainuu',
        country_id: '246',
        state_cd: '05'
      },
      {
        state_cd_full: 'FI-06',
        name: 'Kanta-Hame',
        country_id: '246',
        state_cd: '06'
      },
      {
        state_cd_full: 'FI-07',
        name: 'Keski-Pohjanmaa',
        country_id: '246',
        state_cd: '07'
      },
      {
        state_cd_full: 'FI-08',
        name: 'Keski-Suomi',
        country_id: '246',
        state_cd: '08'
      },
      {
        state_cd_full: 'FI-09',
        name: 'Kymenlaakso',
        country_id: '246',
        state_cd: '09'
      },
      {
        state_cd_full: 'FI-10',
        name: 'Lappi',
        country_id: '246',
        state_cd: '10'
      },
      {
        state_cd_full: 'FI-11',
        name: 'Pirkanmaa',
        country_id: '246',
        state_cd: '11'
      },
      {
        state_cd_full: 'FI-12',
        name: 'Pohjanmaa',
        country_id: '246',
        state_cd: '12'
      },
      {
        state_cd_full: 'FI-13',
        name: 'Pohjois-Karjala',
        country_id: '246',
        state_cd: '13'
      },
      {
        state_cd_full: 'FI-14',
        name: 'Pohjois-Pohjanmaa',
        country_id: '246',
        state_cd: '14'
      },
      {
        state_cd_full: 'FI-15',
        name: 'Pohjois-Savo',
        country_id: '246',
        state_cd: '15'
      },
      {
        state_cd_full: 'FI-16',
        name: 'Paijat-Hame',
        country_id: '246',
        state_cd: '16'
      },
      {
        state_cd_full: 'FI-17',
        name: 'Satakunta',
        country_id: '246',
        state_cd: '17'
      },
      {
        state_cd_full: 'FI-18',
        name: 'Uusimaa',
        country_id: '246',
        state_cd: '18'
      },
      {
        state_cd_full: 'FI-19',
        name: 'Varsinais-Suomi',
        country_id: '246',
        state_cd: '19'
      }
    ]
  },
  {
    country_cd: 'FR',
    name: 'France',
    country_id: 250,
    states: [
      {
        state_cd_full: 'FR-ARA',
        name: 'Auvergne-Rhone-Alpes',
        country_id: '250',
        state_cd: 'ARA'
      },
      {
        state_cd_full: 'FR-BFC',
        name: 'Bourgogne-Franche-Comte',
        country_id: '250',
        state_cd: 'BFC'
      },
      {
        state_cd_full: 'FR-BRE',
        name: 'Bretagne',
        country_id: '250',
        state_cd: 'BRE'
      },
      {
        state_cd_full: 'FR-COR',
        name: 'Corse',
        country_id: '250',
        state_cd: 'COR'
      },
      {
        state_cd_full: 'FR-CVL',
        name: 'Centre-Val de Loire',
        country_id: '250',
        state_cd: 'CVL'
      },
      {
        state_cd_full: 'FR-GES',
        name: 'Grand-Est',
        country_id: '250',
        state_cd: 'GES'
      },
      {
        state_cd_full: 'FR-HDF',
        name: 'Hauts-de-France',
        country_id: '250',
        state_cd: 'HDF'
      },
      {
        state_cd_full: 'FR-IDF',
        name: 'Ile-de-France',
        country_id: '250',
        state_cd: 'IDF'
      },
      {
        state_cd_full: 'FR-NAQ',
        name: 'Nouvelle-Aquitaine',
        country_id: '250',
        state_cd: 'NAQ'
      },
      {
        state_cd_full: 'FR-NOR',
        name: 'Normandie',
        country_id: '250',
        state_cd: 'NOR'
      },
      {
        state_cd_full: 'FR-OCC',
        name: 'Occitanie',
        country_id: '250',
        state_cd: 'OCC'
      },
      {
        state_cd_full: 'FR-PAC',
        name: "Provence-Alpes-Cote-d'Azur",
        country_id: '250',
        state_cd: 'PAC'
      },
      {
        state_cd_full: 'FR-PDL',
        name: 'Pays-de-la-Loire',
        country_id: '250',
        state_cd: 'PDL'
      }
    ]
  },
  {
    country_cd: 'GF',
    name: 'French Guiana',
    country_id: 254,
    states: [
      {
        state_cd_full: 'GF-01',
        name: 'Guyane',
        country_id: '254',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'PF',
    name: 'French Polynesia',
    country_id: 258,
    states: [
      {
        state_cd_full: 'PF-01',
        name: 'Iles Tuamotu-Gambier',
        country_id: '258',
        state_cd: '01'
      },
      {
        state_cd_full: 'PF-11',
        name: 'Iles Sous-le-Vent',
        country_id: '258',
        state_cd: '11'
      },
      {
        state_cd_full: 'PF-21',
        name: 'Iles Marquises',
        country_id: '258',
        state_cd: '21'
      },
      {
        state_cd_full: 'PF-31',
        name: 'Iles du Vent',
        country_id: '258',
        state_cd: '31'
      }
    ]
  },
  {
    country_cd: 'TF',
    name: 'French Southern Territories',
    country_id: 260,
    states: [
      {
        state_cd_full: 'TF-01',
        name: 'French Southern Territories',
        country_id: '260',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'GA',
    name: 'Gabon',
    country_id: 266,
    states: [
      {
        state_cd_full: 'GA-1',
        name: 'Estuaire',
        country_id: '266',
        state_cd: '1'
      },
      {
        state_cd_full: 'GA-2',
        name: 'Haut-Ogooue',
        country_id: '266',
        state_cd: '2'
      },
      {
        state_cd_full: 'GA-3',
        name: 'Moyen-Ogooue',
        country_id: '266',
        state_cd: '3'
      },
      {
        state_cd_full: 'GA-4',
        name: 'Ngounie',
        country_id: '266',
        state_cd: '4'
      },
      {
        state_cd_full: 'GA-6',
        name: 'Ogooue-Ivindo',
        country_id: '266',
        state_cd: '6'
      },
      {
        state_cd_full: 'GA-7',
        name: 'Ogooue-Lolo',
        country_id: '266',
        state_cd: '7'
      },
      {
        state_cd_full: 'GA-8',
        name: 'Ogooue-Maritime',
        country_id: '266',
        state_cd: '8'
      },
      {
        state_cd_full: 'GA-9',
        name: 'Woleu-Ntem',
        country_id: '266',
        state_cd: '9'
      }
    ]
  },
  {
    country_cd: 'GM',
    name: 'Gambia',
    country_id: 270,
    states: [
      {
        state_cd_full: 'GM-B',
        name: 'Banjul',
        country_id: '270',
        state_cd: 'B'
      },
      {
        state_cd_full: 'GM-L',
        name: 'Lower River',
        country_id: '270',
        state_cd: 'L'
      },
      {
        state_cd_full: 'GM-M',
        name: 'Central River',
        country_id: '270',
        state_cd: 'M'
      },
      {
        state_cd_full: 'GM-N',
        name: 'North Bank',
        country_id: '270',
        state_cd: 'N'
      },
      {
        state_cd_full: 'GM-U',
        name: 'Upper River',
        country_id: '270',
        state_cd: 'U'
      },
      {
        state_cd_full: 'GM-W',
        name: 'Western',
        country_id: '270',
        state_cd: 'W'
      }
    ]
  },
  {
    country_cd: 'GE',
    name: 'Georgia',
    country_id: 268,
    states: [
      {
        state_cd_full: 'GE-AB',
        name: 'Abkhazia',
        country_id: '268',
        state_cd: 'AB'
      },
      {
        state_cd_full: 'GE-AJ',
        name: 'Ajaria',
        country_id: '268',
        state_cd: 'AJ'
      },
      {
        state_cd_full: 'GE-GU',
        name: 'Guria',
        country_id: '268',
        state_cd: 'GU'
      },
      {
        state_cd_full: 'GE-IM',
        name: 'Imereti',
        country_id: '268',
        state_cd: 'IM'
      },
      {
        state_cd_full: 'GE-KA',
        name: "K'akheti",
        country_id: '268',
        state_cd: 'KA'
      },
      {
        state_cd_full: 'GE-KK',
        name: 'Kvemo Kartli',
        country_id: '268',
        state_cd: 'KK'
      },
      {
        state_cd_full: 'GE-MM',
        name: 'Mtskheta-Mtianeti',
        country_id: '268',
        state_cd: 'MM'
      },
      {
        state_cd_full: 'GE-RL',
        name: "Rach'a-Lechkhumi-Kvemo Svaneti",
        country_id: '268',
        state_cd: 'RL'
      },
      {
        state_cd_full: 'GE-SJ',
        name: 'Samtskhe-Javakheti',
        country_id: '268',
        state_cd: 'SJ'
      },
      {
        state_cd_full: 'GE-SK',
        name: 'Shida Kartli',
        country_id: '268',
        state_cd: 'SK'
      },
      {
        state_cd_full: 'GE-SZ',
        name: 'Samegrelo-Zemo Svaneti',
        country_id: '268',
        state_cd: 'SZ'
      },
      {
        state_cd_full: 'GE-TB',
        name: 'Tbilisi',
        country_id: '268',
        state_cd: 'TB'
      }
    ]
  },
  {
    country_cd: 'DE',
    name: 'Germany',
    country_id: 276,
    states: [
      {
        state_cd_full: 'DE-BB',
        name: 'Brandenburg',
        country_id: '276',
        state_cd: 'BB'
      },
      {
        state_cd_full: 'DE-BE',
        name: 'Berlin',
        country_id: '276',
        state_cd: 'BE'
      },
      {
        state_cd_full: 'DE-BW',
        name: 'Baden-Wurttemberg',
        country_id: '276',
        state_cd: 'BW'
      },
      {
        state_cd_full: 'DE-BY',
        name: 'Bayern',
        country_id: '276',
        state_cd: 'BY'
      },
      {
        state_cd_full: 'DE-HB',
        name: 'Bremen',
        country_id: '276',
        state_cd: 'HB'
      },
      {
        state_cd_full: 'DE-HE',
        name: 'Hessen',
        country_id: '276',
        state_cd: 'HE'
      },
      {
        state_cd_full: 'DE-HH',
        name: 'Hamburg',
        country_id: '276',
        state_cd: 'HH'
      },
      {
        state_cd_full: 'DE-MV',
        name: 'Mecklenburg-Vorpommern',
        country_id: '276',
        state_cd: 'MV'
      },
      {
        state_cd_full: 'DE-NI',
        name: 'Niedersachsen',
        country_id: '276',
        state_cd: 'NI'
      },
      {
        state_cd_full: 'DE-NW',
        name: 'Nordrhein-Westfalen',
        country_id: '276',
        state_cd: 'NW'
      },
      {
        state_cd_full: 'DE-RP',
        name: 'Rheinland-Pfalz',
        country_id: '276',
        state_cd: 'RP'
      },
      {
        state_cd_full: 'DE-SH',
        name: 'Schleswig-Holstein',
        country_id: '276',
        state_cd: 'SH'
      },
      {
        state_cd_full: 'DE-SL',
        name: 'Saarland',
        country_id: '276',
        state_cd: 'SL'
      },
      {
        state_cd_full: 'DE-SN',
        name: 'Sachsen',
        country_id: '276',
        state_cd: 'SN'
      },
      {
        state_cd_full: 'DE-ST',
        name: 'Sachsen-Anhalt',
        country_id: '276',
        state_cd: 'ST'
      },
      {
        state_cd_full: 'DE-TH',
        name: 'Thuringen',
        country_id: '276',
        state_cd: 'TH'
      }
    ]
  },
  {
    country_cd: 'GH',
    name: 'Ghana',
    country_id: 288,
    states: [
      {
        state_cd_full: 'GH-AA',
        name: 'Greater Accra',
        country_id: '288',
        state_cd: 'AA'
      },
      {
        state_cd_full: 'GH-AH',
        name: 'Ashanti',
        country_id: '288',
        state_cd: 'AH'
      },
      {
        state_cd_full: 'GH-BA',
        name: 'Brong-Ahafo',
        country_id: '288',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'GH-CP',
        name: 'Central',
        country_id: '288',
        state_cd: 'CP'
      },
      {
        state_cd_full: 'GH-EP',
        name: 'Eastern',
        country_id: '288',
        state_cd: 'EP'
      },
      {
        state_cd_full: 'GH-NP',
        name: 'Northern',
        country_id: '288',
        state_cd: 'NP'
      },
      {
        state_cd_full: 'GH-TV',
        name: 'Volta',
        country_id: '288',
        state_cd: 'TV'
      },
      {
        state_cd_full: 'GH-UE',
        name: 'Upper East',
        country_id: '288',
        state_cd: 'UE'
      },
      {
        state_cd_full: 'GH-WP',
        name: 'Western',
        country_id: '288',
        state_cd: 'WP'
      }
    ]
  },
  {
    country_cd: 'GI',
    name: 'Gibraltar',
    country_id: 292,
    states: [
      {
        state_cd_full: 'GI-01',
        name: 'Gibraltar',
        country_id: '292',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'GR',
    name: 'Greece',
    country_id: 300,
    states: [
      {
        state_cd_full: 'GR-69',
        name: 'Agion Oros',
        country_id: '300',
        state_cd: '69'
      },
      {
        state_cd_full: 'GR-A',
        name: 'Anatoliki Makedonia kai Thraki',
        country_id: '300',
        state_cd: 'A'
      },
      {
        state_cd_full: 'GR-B',
        name: 'Kentriki Makedonia',
        country_id: '300',
        state_cd: 'B'
      },
      {
        state_cd_full: 'GR-C',
        name: 'Dytiki Makedonia',
        country_id: '300',
        state_cd: 'C'
      },
      {
        state_cd_full: 'GR-D',
        name: 'Ipeiros',
        country_id: '300',
        state_cd: 'D'
      },
      {
        state_cd_full: 'GR-E',
        name: 'Thessalia',
        country_id: '300',
        state_cd: 'E'
      },
      {
        state_cd_full: 'GR-F',
        name: 'Ionia Nisia',
        country_id: '300',
        state_cd: 'F'
      },
      {
        state_cd_full: 'GR-G',
        name: 'Dytiki Ellada',
        country_id: '300',
        state_cd: 'G'
      },
      {
        state_cd_full: 'GR-H',
        name: 'Sterea Ellada',
        country_id: '300',
        state_cd: 'H'
      },
      {
        state_cd_full: 'GR-I',
        name: 'Attiki',
        country_id: '300',
        state_cd: 'I'
      },
      {
        state_cd_full: 'GR-J',
        name: 'Peloponnisos',
        country_id: '300',
        state_cd: 'J'
      },
      {
        state_cd_full: 'GR-K',
        name: 'Voreio Aigaio',
        country_id: '300',
        state_cd: 'K'
      },
      {
        state_cd_full: 'GR-L',
        name: 'Notio Aigaio',
        country_id: '300',
        state_cd: 'L'
      },
      {
        state_cd_full: 'GR-M',
        name: 'Kriti',
        country_id: '300',
        state_cd: 'M'
      }
    ]
  },
  {
    country_cd: 'GL',
    name: 'Greenland',
    country_id: 304,
    states: [
      {
        state_cd_full: 'GL-21',
        name: 'Kommune Qeqertalik',
        country_id: '304',
        state_cd: '21'
      },
      {
        state_cd_full: 'GL-41',
        name: 'Avannaata Kommunia',
        country_id: '304',
        state_cd: '41'
      },
      {
        state_cd_full: 'GL-KU',
        name: 'Kommune Kujalleq',
        country_id: '304',
        state_cd: 'KU'
      },
      {
        state_cd_full: 'GL-QE',
        name: 'Qeqqata Kommunia',
        country_id: '304',
        state_cd: 'QE'
      },
      {
        state_cd_full: 'GL-SM',
        name: 'Kommuneqarfik Sermersooq',
        country_id: '304',
        state_cd: 'SM'
      }
    ]
  },
  {
    country_cd: 'GD',
    name: 'Grenada',
    country_id: 308,
    states: [
      {
        state_cd_full: 'GD-01',
        name: 'Saint Andrew',
        country_id: '308',
        state_cd: '01'
      },
      {
        state_cd_full: 'GD-02',
        name: 'Saint David',
        country_id: '308',
        state_cd: '02'
      },
      {
        state_cd_full: 'GD-03',
        name: 'Saint George',
        country_id: '308',
        state_cd: '03'
      },
      {
        state_cd_full: 'GD-04',
        name: 'Saint John',
        country_id: '308',
        state_cd: '04'
      },
      {
        state_cd_full: 'GD-05',
        name: 'Saint Mark',
        country_id: '308',
        state_cd: '05'
      },
      {
        state_cd_full: 'GD-06',
        name: 'Saint Patrick',
        country_id: '308',
        state_cd: '06'
      },
      {
        state_cd_full: 'GD-10',
        name: 'Southern Grenadine Islands',
        country_id: '308',
        state_cd: '10'
      }
    ]
  },
  {
    country_cd: 'GP',
    name: 'Guadeloupe',
    country_id: 312,
    states: [
      {
        state_cd_full: 'GP-01',
        name: 'Guadeloupe',
        country_id: '312',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'GU',
    name: 'Guam',
    country_id: 316,
    states: [
      {
        state_cd_full: 'GU-01',
        name: 'Yona',
        country_id: '316',
        state_cd: '01'
      },
      {
        state_cd_full: 'GU-101',
        name: 'Dededo',
        country_id: '316',
        state_cd: '101'
      },
      {
        state_cd_full: 'GU-11',
        name: 'Yigo',
        country_id: '316',
        state_cd: '11'
      },
      {
        state_cd_full: 'GU-111',
        name: 'Chalan Pago-Ordot',
        country_id: '316',
        state_cd: '111'
      },
      {
        state_cd_full: 'GU-121',
        name: 'Barrigada',
        country_id: '316',
        state_cd: '121'
      },
      {
        state_cd_full: 'GU-131',
        name: 'Asan-Maina',
        country_id: '316',
        state_cd: '131'
      },
      {
        state_cd_full: 'GU-141',
        name: 'Agat',
        country_id: '316',
        state_cd: '141'
      },
      {
        state_cd_full: 'GU-21',
        name: 'Tamuning-Tumon-Harmon',
        country_id: '316',
        state_cd: '21'
      },
      {
        state_cd_full: 'GU-31',
        name: 'Talofofo',
        country_id: '316',
        state_cd: '31'
      },
      {
        state_cd_full: 'GU-41',
        name: 'Sinajana',
        country_id: '316',
        state_cd: '41'
      },
      {
        state_cd_full: 'GU-51',
        name: 'Santa Rita',
        country_id: '316',
        state_cd: '51'
      },
      {
        state_cd_full: 'GU-61',
        name: 'Piti',
        country_id: '316',
        state_cd: '61'
      },
      {
        state_cd_full: 'GU-71',
        name: 'Mongmong-Toto-Maite',
        country_id: '316',
        state_cd: '71'
      },
      {
        state_cd_full: 'GU-81',
        name: 'Mangilao',
        country_id: '316',
        state_cd: '81'
      },
      {
        state_cd_full: 'GU-91',
        name: 'Hagatna',
        country_id: '316',
        state_cd: '91'
      }
    ]
  },
  {
    country_cd: 'GT',
    name: 'Guatemala',
    country_id: 320,
    states: [
      {
        state_cd_full: 'GT-AV',
        name: 'Alta Verapaz',
        country_id: '320',
        state_cd: 'AV'
      },
      {
        state_cd_full: 'GT-BV',
        name: 'Baja Verapaz',
        country_id: '320',
        state_cd: 'BV'
      },
      {
        state_cd_full: 'GT-CM',
        name: 'Chimaltenango',
        country_id: '320',
        state_cd: 'CM'
      },
      {
        state_cd_full: 'GT-CQ',
        name: 'Chiquimula',
        country_id: '320',
        state_cd: 'CQ'
      },
      {
        state_cd_full: 'GT-ES',
        name: 'Escuintla',
        country_id: '320',
        state_cd: 'ES'
      },
      {
        state_cd_full: 'GT-GU',
        name: 'Guatemala',
        country_id: '320',
        state_cd: 'GU'
      },
      {
        state_cd_full: 'GT-HU',
        name: 'Huehuetenango',
        country_id: '320',
        state_cd: 'HU'
      },
      {
        state_cd_full: 'GT-IZ',
        name: 'Izabal',
        country_id: '320',
        state_cd: 'IZ'
      },
      {
        state_cd_full: 'GT-JA',
        name: 'Jalapa',
        country_id: '320',
        state_cd: 'JA'
      },
      {
        state_cd_full: 'GT-JU',
        name: 'Jutiapa',
        country_id: '320',
        state_cd: 'JU'
      },
      {
        state_cd_full: 'GT-PE',
        name: 'Peten',
        country_id: '320',
        state_cd: 'PE'
      },
      {
        state_cd_full: 'GT-PR',
        name: 'El Progreso',
        country_id: '320',
        state_cd: 'PR'
      },
      {
        state_cd_full: 'GT-QC',
        name: 'Quiche',
        country_id: '320',
        state_cd: 'QC'
      },
      {
        state_cd_full: 'GT-QZ',
        name: 'Quetzaltenango',
        country_id: '320',
        state_cd: 'QZ'
      },
      {
        state_cd_full: 'GT-RE',
        name: 'Retalhuleu',
        country_id: '320',
        state_cd: 'RE'
      },
      {
        state_cd_full: 'GT-SA',
        name: 'Sacatepequez',
        country_id: '320',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'GT-SM',
        name: 'San Marcos',
        country_id: '320',
        state_cd: 'SM'
      },
      {
        state_cd_full: 'GT-SO',
        name: 'Solola',
        country_id: '320',
        state_cd: 'SO'
      },
      {
        state_cd_full: 'GT-SR',
        name: 'Santa Rosa',
        country_id: '320',
        state_cd: 'SR'
      },
      {
        state_cd_full: 'GT-SU',
        name: 'Suchitepequez',
        country_id: '320',
        state_cd: 'SU'
      },
      {
        state_cd_full: 'GT-TO',
        name: 'Totonicapan',
        country_id: '320',
        state_cd: 'TO'
      },
      {
        state_cd_full: 'GT-ZA',
        name: 'Zacapa',
        country_id: '320',
        state_cd: 'ZA'
      }
    ]
  },
  {
    country_cd: 'GG',
    name: 'Guernsey',
    country_id: 831,
    states: [
      {
        state_cd_full: 'GG-01',
        name: 'Guernsey',
        country_id: '831',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'GN',
    name: 'Guinea',
    country_id: 324,
    states: [
      {
        state_cd_full: 'GN-B',
        name: 'Boke',
        country_id: '324',
        state_cd: 'B'
      },
      {
        state_cd_full: 'GN-BF',
        name: 'Boffa',
        country_id: '324',
        state_cd: 'BF'
      },
      {
        state_cd_full: 'GN-C',
        name: 'Conakry',
        country_id: '324',
        state_cd: 'C'
      },
      {
        state_cd_full: 'GN-CO',
        name: 'Coyah',
        country_id: '324',
        state_cd: 'CO'
      },
      {
        state_cd_full: 'GN-DB',
        name: 'Dabola',
        country_id: '324',
        state_cd: 'DB'
      },
      {
        state_cd_full: 'GN-DL',
        name: 'Dalaba',
        country_id: '324',
        state_cd: 'DL'
      },
      {
        state_cd_full: 'GN-DU',
        name: 'Dubreka',
        country_id: '324',
        state_cd: 'DU'
      },
      {
        state_cd_full: 'GN-GU',
        name: 'Guekedou',
        country_id: '324',
        state_cd: 'GU'
      },
      {
        state_cd_full: 'GN-K',
        name: 'Kankan',
        country_id: '324',
        state_cd: 'K'
      },
      {
        state_cd_full: 'GN-KS',
        name: 'Kissidougou',
        country_id: '324',
        state_cd: 'KS'
      },
      {
        state_cd_full: 'GN-L',
        name: 'Labe',
        country_id: '324',
        state_cd: 'L'
      },
      {
        state_cd_full: 'GN-MC',
        name: 'Macenta',
        country_id: '324',
        state_cd: 'MC'
      },
      {
        state_cd_full: 'GN-N',
        name: 'Nzerekore',
        country_id: '324',
        state_cd: 'N'
      },
      {
        state_cd_full: 'GN-PI',
        name: 'Pita',
        country_id: '324',
        state_cd: 'PI'
      },
      {
        state_cd_full: 'GN-SI',
        name: 'Siguiri',
        country_id: '324',
        state_cd: 'SI'
      }
    ]
  },
  {
    country_cd: 'GW',
    name: 'Guinea-Bissau',
    country_id: 624,
    states: [
      {
        state_cd_full: 'GW-BS',
        name: 'Bissau',
        country_id: '624',
        state_cd: 'BS'
      },
      {
        state_cd_full: 'GW-GA',
        name: 'Gabu',
        country_id: '624',
        state_cd: 'GA'
      }
    ]
  },
  {
    country_cd: 'GY',
    name: 'Guyana',
    country_id: 328,
    states: [
      {
        state_cd_full: 'GY-DE',
        name: 'Demerara-Mahaica',
        country_id: '328',
        state_cd: 'DE'
      },
      {
        state_cd_full: 'GY-EB',
        name: 'East Berbice-Corentyne',
        country_id: '328',
        state_cd: 'EB'
      },
      {
        state_cd_full: 'GY-ES',
        name: 'Essequibo Islands-West Demerara',
        country_id: '328',
        state_cd: 'ES'
      },
      {
        state_cd_full: 'GY-MA',
        name: 'Mahaica-Berbice',
        country_id: '328',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'GY-PM',
        name: 'Pomeroon-Supenaam',
        country_id: '328',
        state_cd: 'PM'
      },
      {
        state_cd_full: 'GY-UD',
        name: 'Upper Demerara-Berbice',
        country_id: '328',
        state_cd: 'UD'
      }
    ]
  },
  {
    country_cd: 'HT',
    name: 'Haiti',
    country_id: 332,
    states: [
      {
        state_cd_full: 'HT-AR',
        name: 'Artibonite',
        country_id: '332',
        state_cd: 'AR'
      },
      {
        state_cd_full: 'HT-CE',
        name: 'Centre',
        country_id: '332',
        state_cd: 'CE'
      },
      {
        state_cd_full: 'HT-GA',
        name: "Grande'Anse",
        country_id: '332',
        state_cd: 'GA'
      },
      {
        state_cd_full: 'HT-ND',
        name: 'Nord',
        country_id: '332',
        state_cd: 'ND'
      },
      {
        state_cd_full: 'HT-NE',
        name: 'Nord-Est',
        country_id: '332',
        state_cd: 'NE'
      },
      {
        state_cd_full: 'HT-NI',
        name: 'Nippes',
        country_id: '332',
        state_cd: 'NI'
      },
      {
        state_cd_full: 'HT-OU',
        name: 'Ouest',
        country_id: '332',
        state_cd: 'OU'
      },
      {
        state_cd_full: 'HT-SD',
        name: 'Sud',
        country_id: '332',
        state_cd: 'SD'
      },
      {
        state_cd_full: 'HT-SE',
        name: 'Sud-Est',
        country_id: '332',
        state_cd: 'SE'
      }
    ]
  },
  {
    country_cd: 'HM',
    name: 'Heard Island and McDonald Islands',
    country_id: 334,
    states: [
      {
        state_cd_full: 'HM-01',
        name: 'Heard Island and McDonald Islands',
        country_id: '334',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'VA',
    name: 'Holy See',
    country_id: 336,
    states: [
      {
        state_cd_full: 'VA-01',
        name: 'Vatican City',
        country_id: '336',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'HN',
    name: 'Honduras',
    country_id: 340,
    states: [
      {
        state_cd_full: 'HN-AT',
        name: 'Atlantida',
        country_id: '340',
        state_cd: 'AT'
      },
      {
        state_cd_full: 'HN-CH',
        name: 'Choluteca',
        country_id: '340',
        state_cd: 'CH'
      },
      {
        state_cd_full: 'HN-CL',
        name: 'Colon',
        country_id: '340',
        state_cd: 'CL'
      },
      {
        state_cd_full: 'HN-CM',
        name: 'Comayagua',
        country_id: '340',
        state_cd: 'CM'
      },
      {
        state_cd_full: 'HN-CP',
        name: 'Copan',
        country_id: '340',
        state_cd: 'CP'
      },
      {
        state_cd_full: 'HN-CR',
        name: 'Cortes',
        country_id: '340',
        state_cd: 'CR'
      },
      {
        state_cd_full: 'HN-EP',
        name: 'El Paraiso',
        country_id: '340',
        state_cd: 'EP'
      },
      {
        state_cd_full: 'HN-FM',
        name: 'Francisco Morazan',
        country_id: '340',
        state_cd: 'FM'
      },
      {
        state_cd_full: 'HN-IB',
        name: 'Islas de la Bahia',
        country_id: '340',
        state_cd: 'IB'
      },
      {
        state_cd_full: 'HN-IN',
        name: 'Intibuca',
        country_id: '340',
        state_cd: 'IN'
      },
      {
        state_cd_full: 'HN-LE',
        name: 'Lempira',
        country_id: '340',
        state_cd: 'LE'
      },
      {
        state_cd_full: 'HN-LP',
        name: 'La Paz',
        country_id: '340',
        state_cd: 'LP'
      },
      {
        state_cd_full: 'HN-OC',
        name: 'Ocotepeque',
        country_id: '340',
        state_cd: 'OC'
      },
      {
        state_cd_full: 'HN-OL',
        name: 'Olancho',
        country_id: '340',
        state_cd: 'OL'
      },
      {
        state_cd_full: 'HN-SB',
        name: 'Santa Barbara',
        country_id: '340',
        state_cd: 'SB'
      },
      {
        state_cd_full: 'HN-VA',
        name: 'Valle',
        country_id: '340',
        state_cd: 'VA'
      },
      {
        state_cd_full: 'HN-YO',
        name: 'Yoro',
        country_id: '340',
        state_cd: 'YO'
      }
    ]
  },
  {
    country_cd: 'HK',
    name: 'Hong Kong',
    country_id: 344,
    states: [
      {
        state_cd_full: 'HK-01',
        name: 'Hong Kong',
        country_id: '344',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'HU',
    name: 'Hungary',
    country_id: 348,
    states: [
      {
        state_cd_full: 'HU-BA',
        name: 'Baranya',
        country_id: '348',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'HU-BE',
        name: 'Bekes',
        country_id: '348',
        state_cd: 'BE'
      },
      {
        state_cd_full: 'HU-BK',
        name: 'Bacs-Kiskun',
        country_id: '348',
        state_cd: 'BK'
      },
      {
        state_cd_full: 'HU-BU',
        name: 'Budapest',
        country_id: '348',
        state_cd: 'BU'
      },
      {
        state_cd_full: 'HU-BZ',
        name: 'Borsod-Abauj-Zemplen',
        country_id: '348',
        state_cd: 'BZ'
      },
      {
        state_cd_full: 'HU-CS',
        name: 'Csongrad',
        country_id: '348',
        state_cd: 'CS'
      },
      {
        state_cd_full: 'HU-FE',
        name: 'Fejer',
        country_id: '348',
        state_cd: 'FE'
      },
      {
        state_cd_full: 'HU-GS',
        name: 'Gyor-Moson-Sopron',
        country_id: '348',
        state_cd: 'GS'
      },
      {
        state_cd_full: 'HU-HB',
        name: 'Hajdu-Bihar',
        country_id: '348',
        state_cd: 'HB'
      },
      {
        state_cd_full: 'HU-HE',
        name: 'Heves',
        country_id: '348',
        state_cd: 'HE'
      },
      {
        state_cd_full: 'HU-JN',
        name: 'Jasz-Nagykun-Szolnok',
        country_id: '348',
        state_cd: 'JN'
      },
      {
        state_cd_full: 'HU-KE',
        name: 'Komarom-Esztergom',
        country_id: '348',
        state_cd: 'KE'
      },
      {
        state_cd_full: 'HU-NO',
        name: 'Nograd',
        country_id: '348',
        state_cd: 'NO'
      },
      {
        state_cd_full: 'HU-PE',
        name: 'Pest',
        country_id: '348',
        state_cd: 'PE'
      },
      {
        state_cd_full: 'HU-SO',
        name: 'Somogy',
        country_id: '348',
        state_cd: 'SO'
      },
      {
        state_cd_full: 'HU-SZ',
        name: 'Szabolcs-Szatmar-Bereg',
        country_id: '348',
        state_cd: 'SZ'
      },
      {
        state_cd_full: 'HU-TO',
        name: 'Tolna',
        country_id: '348',
        state_cd: 'TO'
      },
      {
        state_cd_full: 'HU-VA',
        name: 'Vas',
        country_id: '348',
        state_cd: 'VA'
      },
      {
        state_cd_full: 'HU-VE',
        name: 'Veszprem',
        country_id: '348',
        state_cd: 'VE'
      },
      {
        state_cd_full: 'HU-ZA',
        name: 'Zala',
        country_id: '348',
        state_cd: 'ZA'
      }
    ]
  },
  {
    country_cd: 'IS',
    name: 'Iceland',
    country_id: 352,
    states: [
      {
        state_cd_full: 'IS-1',
        name: 'Hofudborgarsvaedi',
        country_id: '352',
        state_cd: '1'
      },
      {
        state_cd_full: 'IS-2',
        name: 'Sudurnes',
        country_id: '352',
        state_cd: '2'
      },
      {
        state_cd_full: 'IS-3',
        name: 'Vesturland',
        country_id: '352',
        state_cd: '3'
      },
      {
        state_cd_full: 'IS-4',
        name: 'Vestfirdir',
        country_id: '352',
        state_cd: '4'
      },
      {
        state_cd_full: 'IS-5',
        name: 'Nordurland vestra',
        country_id: '352',
        state_cd: '5'
      },
      {
        state_cd_full: 'IS-6',
        name: 'Nordurland eystra',
        country_id: '352',
        state_cd: '6'
      },
      {
        state_cd_full: 'IS-7',
        name: 'Austurland',
        country_id: '352',
        state_cd: '7'
      },
      {
        state_cd_full: 'IS-8',
        name: 'Sudurland',
        country_id: '352',
        state_cd: '8'
      }
    ]
  },
  {
    country_cd: 'IN',
    name: 'India',
    country_id: 356,
    states: [
      {
        state_cd_full: 'IN-AN',
        name: 'Andaman and Nicobar Islands',
        country_id: '356',
        state_cd: 'AN'
      },
      {
        state_cd_full: 'IN-AP',
        name: 'Andhra Pradesh',
        country_id: '356',
        state_cd: 'AP'
      },
      {
        state_cd_full: 'IN-AR',
        name: 'Arunachal Pradesh',
        country_id: '356',
        state_cd: 'AR'
      },
      {
        state_cd_full: 'IN-AS',
        name: 'Assam',
        country_id: '356',
        state_cd: 'AS'
      },
      {
        state_cd_full: 'IN-BR',
        name: 'Bihar',
        country_id: '356',
        state_cd: 'BR'
      },
      {
        state_cd_full: 'IN-CH',
        name: 'Chandigarh',
        country_id: '356',
        state_cd: 'CH'
      },
      {
        state_cd_full: 'IN-CT',
        name: 'Chhattisgarh',
        country_id: '356',
        state_cd: 'CT'
      },
      {
        state_cd_full: 'IN-DD',
        name: 'Daman and Diu',
        country_id: '356',
        state_cd: 'DD'
      },
      {
        state_cd_full: 'IN-DL',
        name: 'Delhi',
        country_id: '356',
        state_cd: 'DL'
      },
      {
        state_cd_full: 'IN-DN',
        name: 'Dadra and Nagar Haveli',
        country_id: '356',
        state_cd: 'DN'
      },
      {
        state_cd_full: 'IN-GA',
        name: 'Goa',
        country_id: '356',
        state_cd: 'GA'
      },
      {
        state_cd_full: 'IN-GJ',
        name: 'Gujarat',
        country_id: '356',
        state_cd: 'GJ'
      },
      {
        state_cd_full: 'IN-HP',
        name: 'Himachal Pradesh',
        country_id: '356',
        state_cd: 'HP'
      },
      {
        state_cd_full: 'IN-HR',
        name: 'Haryana',
        country_id: '356',
        state_cd: 'HR'
      },
      {
        state_cd_full: 'IN-JH',
        name: 'Jharkhand',
        country_id: '356',
        state_cd: 'JH'
      },
      {
        state_cd_full: 'IN-JK',
        name: 'Jammu and Kashmir',
        country_id: '356',
        state_cd: 'JK'
      },
      {
        state_cd_full: 'IN-KA',
        name: 'Karnataka',
        country_id: '356',
        state_cd: 'KA'
      },
      {
        state_cd_full: 'IN-KL',
        name: 'Kerala',
        country_id: '356',
        state_cd: 'KL'
      },
      {
        state_cd_full: 'IN-LD',
        name: 'Lakshadweep',
        country_id: '356',
        state_cd: 'LD'
      },
      {
        state_cd_full: 'IN-MH',
        name: 'Maharashtra',
        country_id: '356',
        state_cd: 'MH'
      },
      {
        state_cd_full: 'IN-ML',
        name: 'Meghalaya',
        country_id: '356',
        state_cd: 'ML'
      },
      {
        state_cd_full: 'IN-MN',
        name: 'Manipur',
        country_id: '356',
        state_cd: 'MN'
      },
      {
        state_cd_full: 'IN-MP',
        name: 'Madhya Pradesh',
        country_id: '356',
        state_cd: 'MP'
      },
      {
        state_cd_full: 'IN-MZ',
        name: 'Mizoram',
        country_id: '356',
        state_cd: 'MZ'
      },
      {
        state_cd_full: 'IN-NL',
        name: 'Nagaland',
        country_id: '356',
        state_cd: 'NL'
      },
      {
        state_cd_full: 'IN-OR',
        name: 'Odisha',
        country_id: '356',
        state_cd: 'OR'
      },
      {
        state_cd_full: 'IN-PB',
        name: 'Punjab',
        country_id: '356',
        state_cd: 'PB'
      },
      {
        state_cd_full: 'IN-PY',
        name: 'Puducherry',
        country_id: '356',
        state_cd: 'PY'
      },
      {
        state_cd_full: 'IN-RJ',
        name: 'Rajasthan',
        country_id: '356',
        state_cd: 'RJ'
      },
      {
        state_cd_full: 'IN-SK',
        name: 'Sikkim',
        country_id: '356',
        state_cd: 'SK'
      },
      {
        state_cd_full: 'IN-TG',
        name: 'Telangana',
        country_id: '356',
        state_cd: 'TG'
      },
      {
        state_cd_full: 'IN-TN',
        name: 'Tamil Nadu',
        country_id: '356',
        state_cd: 'TN'
      },
      {
        state_cd_full: 'IN-TR',
        name: 'Tripura',
        country_id: '356',
        state_cd: 'TR'
      },
      {
        state_cd_full: 'IN-UP',
        name: 'Uttar Pradesh',
        country_id: '356',
        state_cd: 'UP'
      },
      {
        state_cd_full: 'IN-UT',
        name: 'Uttarakhand',
        country_id: '356',
        state_cd: 'UT'
      },
      {
        state_cd_full: 'IN-WB',
        name: 'West Bengal',
        country_id: '356',
        state_cd: 'WB'
      }
    ]
  },
  {
    country_cd: 'ID',
    name: 'Indonesia',
    country_id: 360,
    states: [
      {
        state_cd_full: 'ID-AC',
        name: 'Aceh',
        country_id: '360',
        state_cd: 'AC'
      },
      {
        state_cd_full: 'ID-BA',
        name: 'Bali',
        country_id: '360',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'ID-BB',
        name: 'Kepulauan Bangka Belitung',
        country_id: '360',
        state_cd: 'BB'
      },
      {
        state_cd_full: 'ID-BE',
        name: 'Bengkulu',
        country_id: '360',
        state_cd: 'BE'
      },
      {
        state_cd_full: 'ID-BT',
        name: 'Banten',
        country_id: '360',
        state_cd: 'BT'
      },
      {
        state_cd_full: 'ID-GO',
        name: 'Gorontalo',
        country_id: '360',
        state_cd: 'GO'
      },
      {
        state_cd_full: 'ID-JA',
        name: 'Jambi',
        country_id: '360',
        state_cd: 'JA'
      },
      {
        state_cd_full: 'ID-JB',
        name: 'Jawa Barat',
        country_id: '360',
        state_cd: 'JB'
      },
      {
        state_cd_full: 'ID-JI',
        name: 'Jawa Timur',
        country_id: '360',
        state_cd: 'JI'
      },
      {
        state_cd_full: 'ID-JK',
        name: 'Jakarta Raya',
        country_id: '360',
        state_cd: 'JK'
      },
      {
        state_cd_full: 'ID-JT',
        name: 'Jawa Tengah',
        country_id: '360',
        state_cd: 'JT'
      },
      {
        state_cd_full: 'ID-KB',
        name: 'Kalimantan Barat',
        country_id: '360',
        state_cd: 'KB'
      },
      {
        state_cd_full: 'ID-KI',
        name: 'Kalimantan Timur',
        country_id: '360',
        state_cd: 'KI'
      },
      {
        state_cd_full: 'ID-KR',
        name: 'Kepulauan Riau',
        country_id: '360',
        state_cd: 'KR'
      },
      {
        state_cd_full: 'ID-KS',
        name: 'Kalimantan Selatan',
        country_id: '360',
        state_cd: 'KS'
      },
      {
        state_cd_full: 'ID-KT',
        name: 'Kalimantan Tengah',
        country_id: '360',
        state_cd: 'KT'
      },
      {
        state_cd_full: 'ID-KU',
        name: 'Kalimantan Utara',
        country_id: '360',
        state_cd: 'KU'
      },
      {
        state_cd_full: 'ID-LA',
        name: 'Lampung',
        country_id: '360',
        state_cd: 'LA'
      },
      {
        state_cd_full: 'ID-ML',
        name: 'Maluku',
        country_id: '360',
        state_cd: 'ML'
      },
      {
        state_cd_full: 'ID-MU',
        name: 'Maluku Utara',
        country_id: '360',
        state_cd: 'MU'
      },
      {
        state_cd_full: 'ID-NB',
        name: 'Nusa Tenggara Barat',
        country_id: '360',
        state_cd: 'NB'
      },
      {
        state_cd_full: 'ID-NT',
        name: 'Nusa Tenggara Timur',
        country_id: '360',
        state_cd: 'NT'
      },
      {
        state_cd_full: 'ID-PB',
        name: 'Papua Barat',
        country_id: '360',
        state_cd: 'PB'
      },
      {
        state_cd_full: 'ID-PP',
        name: 'Papua',
        country_id: '360',
        state_cd: 'PP'
      },
      {
        state_cd_full: 'ID-RI',
        name: 'Riau',
        country_id: '360',
        state_cd: 'RI'
      },
      {
        state_cd_full: 'ID-SA',
        name: 'Sulawesi Utara',
        country_id: '360',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'ID-SB',
        name: 'Sumatera Barat',
        country_id: '360',
        state_cd: 'SB'
      },
      {
        state_cd_full: 'ID-SG',
        name: 'Sulawesi Tenggara',
        country_id: '360',
        state_cd: 'SG'
      },
      {
        state_cd_full: 'ID-SN',
        name: 'Sulawesi Selatan',
        country_id: '360',
        state_cd: 'SN'
      },
      {
        state_cd_full: 'ID-SR',
        name: 'Sulawesi Barat',
        country_id: '360',
        state_cd: 'SR'
      },
      {
        state_cd_full: 'ID-SS',
        name: 'Sumatera Selatan',
        country_id: '360',
        state_cd: 'SS'
      },
      {
        state_cd_full: 'ID-ST',
        name: 'Sulawesi Tengah',
        country_id: '360',
        state_cd: 'ST'
      },
      {
        state_cd_full: 'ID-SU',
        name: 'Sumatera Utara',
        country_id: '360',
        state_cd: 'SU'
      },
      {
        state_cd_full: 'ID-YO',
        name: 'Yogyakarta',
        country_id: '360',
        state_cd: 'YO'
      }
    ]
  },
  {
    country_cd: 'IR',
    name: 'Iran (Islamic Republic of)',
    country_id: 364,
    states: [
      {
        state_cd_full: 'IR-01',
        name: 'Azarbayjan-e Sharqi',
        country_id: '364',
        state_cd: '01'
      },
      {
        state_cd_full: 'IR-02',
        name: 'Azarbayjan-e Gharbi',
        country_id: '364',
        state_cd: '02'
      },
      {
        state_cd_full: 'IR-03',
        name: 'Ardabil',
        country_id: '364',
        state_cd: '03'
      },
      {
        state_cd_full: 'IR-04',
        name: 'Esfahan',
        country_id: '364',
        state_cd: '04'
      },
      {
        state_cd_full: 'IR-05',
        name: 'Ilam',
        country_id: '364',
        state_cd: '05'
      },
      {
        state_cd_full: 'IR-06',
        name: 'Bushehr',
        country_id: '364',
        state_cd: '06'
      },
      {
        state_cd_full: 'IR-07',
        name: 'Tehran',
        country_id: '364',
        state_cd: '07'
      },
      {
        state_cd_full: 'IR-08',
        name: 'Chahar Mahal va Bakhtiari',
        country_id: '364',
        state_cd: '08'
      },
      {
        state_cd_full: 'IR-10',
        name: 'Khuzestan',
        country_id: '364',
        state_cd: '10'
      },
      {
        state_cd_full: 'IR-11',
        name: 'Zanjan',
        country_id: '364',
        state_cd: '11'
      },
      {
        state_cd_full: 'IR-12',
        name: 'Semnan',
        country_id: '364',
        state_cd: '12'
      },
      {
        state_cd_full: 'IR-13',
        name: 'Sistan va Baluchestan',
        country_id: '364',
        state_cd: '13'
      },
      {
        state_cd_full: 'IR-14',
        name: 'Fars',
        country_id: '364',
        state_cd: '14'
      },
      {
        state_cd_full: 'IR-15',
        name: 'Kerman',
        country_id: '364',
        state_cd: '15'
      },
      {
        state_cd_full: 'IR-16',
        name: 'Kordestan',
        country_id: '364',
        state_cd: '16'
      },
      {
        state_cd_full: 'IR-17',
        name: 'Kermanshah',
        country_id: '364',
        state_cd: '17'
      },
      {
        state_cd_full: 'IR-18',
        name: 'Kohgiluyeh va Bowyer Ahmad',
        country_id: '364',
        state_cd: '18'
      },
      {
        state_cd_full: 'IR-19',
        name: 'Gilan',
        country_id: '364',
        state_cd: '19'
      },
      {
        state_cd_full: 'IR-20',
        name: 'Lorestan',
        country_id: '364',
        state_cd: '20'
      },
      {
        state_cd_full: 'IR-21',
        name: 'Mazandaran',
        country_id: '364',
        state_cd: '21'
      },
      {
        state_cd_full: 'IR-22',
        name: 'Markazi',
        country_id: '364',
        state_cd: '22'
      },
      {
        state_cd_full: 'IR-23',
        name: 'Hormozgan',
        country_id: '364',
        state_cd: '23'
      },
      {
        state_cd_full: 'IR-24',
        name: 'Hamadan',
        country_id: '364',
        state_cd: '24'
      },
      {
        state_cd_full: 'IR-25',
        name: 'Yazd',
        country_id: '364',
        state_cd: '25'
      },
      {
        state_cd_full: 'IR-26',
        name: 'Qom',
        country_id: '364',
        state_cd: '26'
      },
      {
        state_cd_full: 'IR-27',
        name: 'Golestan',
        country_id: '364',
        state_cd: '27'
      },
      {
        state_cd_full: 'IR-28',
        name: 'Qazvin',
        country_id: '364',
        state_cd: '28'
      },
      {
        state_cd_full: 'IR-29',
        name: 'Khorasan-e Jonubi',
        country_id: '364',
        state_cd: '29'
      },
      {
        state_cd_full: 'IR-30',
        name: 'Khorasan-e Razavi',
        country_id: '364',
        state_cd: '30'
      },
      {
        state_cd_full: 'IR-31',
        name: 'Khorasan-e Shomali',
        country_id: '364',
        state_cd: '31'
      },
      {
        state_cd_full: 'IR-32',
        name: 'Alborz',
        country_id: '364',
        state_cd: '32'
      }
    ]
  },
  {
    country_cd: 'IQ',
    name: 'Iraq',
    country_id: 368,
    states: [
      {
        state_cd_full: 'IQ-AN',
        name: 'Al Anbar',
        country_id: '368',
        state_cd: 'AN'
      },
      {
        state_cd_full: 'IQ-AR',
        name: 'Arbil',
        country_id: '368',
        state_cd: 'AR'
      },
      {
        state_cd_full: 'IQ-BA',
        name: 'Al Basrah',
        country_id: '368',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'IQ-BB',
        name: 'Babil',
        country_id: '368',
        state_cd: 'BB'
      },
      {
        state_cd_full: 'IQ-BG',
        name: 'Baghdad',
        country_id: '368',
        state_cd: 'BG'
      },
      {
        state_cd_full: 'IQ-DA',
        name: 'Dahuk',
        country_id: '368',
        state_cd: 'DA'
      },
      {
        state_cd_full: 'IQ-DI',
        name: 'Diyala',
        country_id: '368',
        state_cd: 'DI'
      },
      {
        state_cd_full: 'IQ-DQ',
        name: 'Dhi Qar',
        country_id: '368',
        state_cd: 'DQ'
      },
      {
        state_cd_full: 'IQ-KA',
        name: "Karbala'",
        country_id: '368',
        state_cd: 'KA'
      },
      {
        state_cd_full: 'IQ-KI',
        name: 'Kirkuk',
        country_id: '368',
        state_cd: 'KI'
      },
      {
        state_cd_full: 'IQ-MA',
        name: 'Maysan',
        country_id: '368',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'IQ-MU',
        name: 'Al Muthanna',
        country_id: '368',
        state_cd: 'MU'
      },
      {
        state_cd_full: 'IQ-NA',
        name: 'An Najaf',
        country_id: '368',
        state_cd: 'NA'
      },
      {
        state_cd_full: 'IQ-NI',
        name: 'Ninawa',
        country_id: '368',
        state_cd: 'NI'
      },
      {
        state_cd_full: 'IQ-QA',
        name: 'Al Qadisiyah',
        country_id: '368',
        state_cd: 'QA'
      },
      {
        state_cd_full: 'IQ-SD',
        name: 'Salah ad Din',
        country_id: '368',
        state_cd: 'SD'
      },
      {
        state_cd_full: 'IQ-SU',
        name: 'As Sulaymaniyah',
        country_id: '368',
        state_cd: 'SU'
      },
      {
        state_cd_full: 'IQ-WA',
        name: 'Wasit',
        country_id: '368',
        state_cd: 'WA'
      }
    ]
  },
  {
    country_cd: 'IE',
    name: 'Ireland',
    country_id: 372,
    states: [
      {
        state_cd_full: 'IE-CE',
        name: 'Clare',
        country_id: '372',
        state_cd: 'CE'
      },
      {
        state_cd_full: 'IE-CN',
        name: 'Cavan',
        country_id: '372',
        state_cd: 'CN'
      },
      {
        state_cd_full: 'IE-CO',
        name: 'Cork',
        country_id: '372',
        state_cd: 'CO'
      },
      {
        state_cd_full: 'IE-CW',
        name: 'Carlow',
        country_id: '372',
        state_cd: 'CW'
      },
      {
        state_cd_full: 'IE-D',
        name: 'Dublin',
        country_id: '372',
        state_cd: 'D'
      },
      {
        state_cd_full: 'IE-DL',
        name: 'Donegal',
        country_id: '372',
        state_cd: 'DL'
      },
      {
        state_cd_full: 'IE-G',
        name: 'Galway',
        country_id: '372',
        state_cd: 'G'
      },
      {
        state_cd_full: 'IE-KE',
        name: 'Kildare',
        country_id: '372',
        state_cd: 'KE'
      },
      {
        state_cd_full: 'IE-KK',
        name: 'Kilkenny',
        country_id: '372',
        state_cd: 'KK'
      },
      {
        state_cd_full: 'IE-KY',
        name: 'Kerry',
        country_id: '372',
        state_cd: 'KY'
      },
      {
        state_cd_full: 'IE-LD',
        name: 'Longford',
        country_id: '372',
        state_cd: 'LD'
      },
      {
        state_cd_full: 'IE-LH',
        name: 'Louth',
        country_id: '372',
        state_cd: 'LH'
      },
      {
        state_cd_full: 'IE-LK',
        name: 'Limerick',
        country_id: '372',
        state_cd: 'LK'
      },
      {
        state_cd_full: 'IE-LM',
        name: 'Leitrim',
        country_id: '372',
        state_cd: 'LM'
      },
      {
        state_cd_full: 'IE-LS',
        name: 'Laois',
        country_id: '372',
        state_cd: 'LS'
      },
      {
        state_cd_full: 'IE-MH',
        name: 'Meath',
        country_id: '372',
        state_cd: 'MH'
      },
      {
        state_cd_full: 'IE-MN',
        name: 'Monaghan',
        country_id: '372',
        state_cd: 'MN'
      },
      {
        state_cd_full: 'IE-MO',
        name: 'Mayo',
        country_id: '372',
        state_cd: 'MO'
      },
      {
        state_cd_full: 'IE-OY',
        name: 'Offaly',
        country_id: '372',
        state_cd: 'OY'
      },
      {
        state_cd_full: 'IE-RN',
        name: 'Roscommon',
        country_id: '372',
        state_cd: 'RN'
      },
      {
        state_cd_full: 'IE-SO',
        name: 'Sligo',
        country_id: '372',
        state_cd: 'SO'
      },
      {
        state_cd_full: 'IE-TA',
        name: 'Tipperary',
        country_id: '372',
        state_cd: 'TA'
      },
      {
        state_cd_full: 'IE-WD',
        name: 'Waterford',
        country_id: '372',
        state_cd: 'WD'
      },
      {
        state_cd_full: 'IE-WH',
        name: 'Westmeath',
        country_id: '372',
        state_cd: 'WH'
      },
      {
        state_cd_full: 'IE-WW',
        name: 'Wicklow',
        country_id: '372',
        state_cd: 'WW'
      },
      {
        state_cd_full: 'IE-WX',
        name: 'Wexford',
        country_id: '372',
        state_cd: 'WX'
      }
    ]
  },
  {
    country_cd: 'IM',
    name: 'Isle of Man',
    country_id: 833,
    states: [
      {
        state_cd_full: 'IM-01',
        name: 'Isle of Man',
        country_id: '833',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'IL',
    name: 'Israel',
    country_id: 376,
    states: [
      {
        state_cd_full: 'IL-D',
        name: 'HaDarom',
        country_id: '376',
        state_cd: 'D'
      },
      {
        state_cd_full: 'IL-HA',
        name: 'Hefa',
        country_id: '376',
        state_cd: 'HA'
      },
      {
        state_cd_full: 'IL-JM',
        name: 'Yerushalayim',
        country_id: '376',
        state_cd: 'JM'
      },
      {
        state_cd_full: 'IL-M',
        name: 'HaMerkaz',
        country_id: '376',
        state_cd: 'M'
      },
      {
        state_cd_full: 'IL-TA',
        name: 'Tel Aviv',
        country_id: '376',
        state_cd: 'TA'
      },
      {
        state_cd_full: 'IL-Z',
        name: 'HaTsafon',
        country_id: '376',
        state_cd: 'Z'
      }
    ]
  },
  {
    country_cd: 'IT',
    name: 'Italy',
    country_id: 380,
    states: [
      {
        state_cd_full: 'IT-21',
        name: 'Piemonte',
        country_id: '380',
        state_cd: '21'
      },
      {
        state_cd_full: 'IT-23',
        name: "Valle d'Aosta",
        country_id: '380',
        state_cd: '23'
      },
      {
        state_cd_full: 'IT-25',
        name: 'Lombardia',
        country_id: '380',
        state_cd: '25'
      },
      {
        state_cd_full: 'IT-32',
        name: 'Trentino-Alto Adige',
        country_id: '380',
        state_cd: '32'
      },
      {
        state_cd_full: 'IT-34',
        name: 'Veneto',
        country_id: '380',
        state_cd: '34'
      },
      {
        state_cd_full: 'IT-36',
        name: 'Friuli-Venezia Giulia',
        country_id: '380',
        state_cd: '36'
      },
      {
        state_cd_full: 'IT-42',
        name: 'Liguria',
        country_id: '380',
        state_cd: '42'
      },
      {
        state_cd_full: 'IT-45',
        name: 'Emilia-Romagna',
        country_id: '380',
        state_cd: '45'
      },
      {
        state_cd_full: 'IT-52',
        name: 'Toscana',
        country_id: '380',
        state_cd: '52'
      },
      {
        state_cd_full: 'IT-55',
        name: 'Umbria',
        country_id: '380',
        state_cd: '55'
      },
      {
        state_cd_full: 'IT-57',
        name: 'Marche',
        country_id: '380',
        state_cd: '57'
      },
      {
        state_cd_full: 'IT-62',
        name: 'Lazio',
        country_id: '380',
        state_cd: '62'
      },
      {
        state_cd_full: 'IT-65',
        name: 'Abruzzo',
        country_id: '380',
        state_cd: '65'
      },
      {
        state_cd_full: 'IT-67',
        name: 'Molise',
        country_id: '380',
        state_cd: '67'
      },
      {
        state_cd_full: 'IT-72',
        name: 'Campania',
        country_id: '380',
        state_cd: '72'
      },
      {
        state_cd_full: 'IT-75',
        name: 'Puglia',
        country_id: '380',
        state_cd: '75'
      },
      {
        state_cd_full: 'IT-77',
        name: 'Basilicata',
        country_id: '380',
        state_cd: '77'
      },
      {
        state_cd_full: 'IT-78',
        name: 'Calabria',
        country_id: '380',
        state_cd: '78'
      },
      {
        state_cd_full: 'IT-82',
        name: 'Sicilia',
        country_id: '380',
        state_cd: '82'
      },
      {
        state_cd_full: 'IT-88',
        name: 'Sardegna',
        country_id: '380',
        state_cd: '88'
      }
    ]
  },
  {
    country_cd: 'JM',
    name: 'Jamaica',
    country_id: 388,
    states: [
      {
        state_cd_full: 'JM-01',
        name: 'Kingston',
        country_id: '388',
        state_cd: '01'
      },
      {
        state_cd_full: 'JM-02',
        name: 'Saint Andrew',
        country_id: '388',
        state_cd: '02'
      },
      {
        state_cd_full: 'JM-03',
        name: 'Saint Thomas',
        country_id: '388',
        state_cd: '03'
      },
      {
        state_cd_full: 'JM-04',
        name: 'Portland',
        country_id: '388',
        state_cd: '04'
      },
      {
        state_cd_full: 'JM-05',
        name: 'Saint Mary',
        country_id: '388',
        state_cd: '05'
      },
      {
        state_cd_full: 'JM-06',
        name: 'Saint Ann',
        country_id: '388',
        state_cd: '06'
      },
      {
        state_cd_full: 'JM-07',
        name: 'Trelawny',
        country_id: '388',
        state_cd: '07'
      },
      {
        state_cd_full: 'JM-08',
        name: 'Saint James',
        country_id: '388',
        state_cd: '08'
      },
      {
        state_cd_full: 'JM-09',
        name: 'Hanover',
        country_id: '388',
        state_cd: '09'
      },
      {
        state_cd_full: 'JM-10',
        name: 'Westmoreland',
        country_id: '388',
        state_cd: '10'
      },
      {
        state_cd_full: 'JM-11',
        name: 'Saint Elizabeth',
        country_id: '388',
        state_cd: '11'
      },
      {
        state_cd_full: 'JM-12',
        name: 'Manchester',
        country_id: '388',
        state_cd: '12'
      },
      {
        state_cd_full: 'JM-13',
        name: 'Clarendon',
        country_id: '388',
        state_cd: '13'
      },
      {
        state_cd_full: 'JM-14',
        name: 'Saint Catherine',
        country_id: '388',
        state_cd: '14'
      }
    ]
  },
  {
    country_cd: 'JP',
    name: 'Japan',
    country_id: 392,
    states: [
      {
        state_cd_full: 'JP-01',
        name: 'Hokkaido',
        country_id: '392',
        state_cd: '01'
      },
      {
        state_cd_full: 'JP-02',
        name: 'Aomori',
        country_id: '392',
        state_cd: '02'
      },
      {
        state_cd_full: 'JP-03',
        name: 'Iwate',
        country_id: '392',
        state_cd: '03'
      },
      {
        state_cd_full: 'JP-04',
        name: 'Miyagi',
        country_id: '392',
        state_cd: '04'
      },
      {
        state_cd_full: 'JP-05',
        name: 'Akita',
        country_id: '392',
        state_cd: '05'
      },
      {
        state_cd_full: 'JP-06',
        name: 'Yamagata',
        country_id: '392',
        state_cd: '06'
      },
      {
        state_cd_full: 'JP-07',
        name: 'Fukushima',
        country_id: '392',
        state_cd: '07'
      },
      {
        state_cd_full: 'JP-08',
        name: 'Ibaraki',
        country_id: '392',
        state_cd: '08'
      },
      {
        state_cd_full: 'JP-09',
        name: 'Tochigi',
        country_id: '392',
        state_cd: '09'
      },
      {
        state_cd_full: 'JP-10',
        name: 'Gunma',
        country_id: '392',
        state_cd: '10'
      },
      {
        state_cd_full: 'JP-11',
        name: 'Saitama',
        country_id: '392',
        state_cd: '11'
      },
      {
        state_cd_full: 'JP-12',
        name: 'Chiba',
        country_id: '392',
        state_cd: '12'
      },
      {
        state_cd_full: 'JP-13',
        name: 'Tokyo',
        country_id: '392',
        state_cd: '13'
      },
      {
        state_cd_full: 'JP-14',
        name: 'Kanagawa',
        country_id: '392',
        state_cd: '14'
      },
      {
        state_cd_full: 'JP-15',
        name: 'Niigata',
        country_id: '392',
        state_cd: '15'
      },
      {
        state_cd_full: 'JP-16',
        name: 'Toyama',
        country_id: '392',
        state_cd: '16'
      },
      {
        state_cd_full: 'JP-17',
        name: 'Ishikawa',
        country_id: '392',
        state_cd: '17'
      },
      {
        state_cd_full: 'JP-18',
        name: 'Fukui',
        country_id: '392',
        state_cd: '18'
      },
      {
        state_cd_full: 'JP-19',
        name: 'Yamanashi',
        country_id: '392',
        state_cd: '19'
      },
      {
        state_cd_full: 'JP-20',
        name: 'Nagano',
        country_id: '392',
        state_cd: '20'
      },
      {
        state_cd_full: 'JP-21',
        name: 'Gifu',
        country_id: '392',
        state_cd: '21'
      },
      {
        state_cd_full: 'JP-22',
        name: 'Shizuoka',
        country_id: '392',
        state_cd: '22'
      },
      {
        state_cd_full: 'JP-23',
        name: 'Aichi',
        country_id: '392',
        state_cd: '23'
      },
      {
        state_cd_full: 'JP-24',
        name: 'Mie',
        country_id: '392',
        state_cd: '24'
      },
      {
        state_cd_full: 'JP-25',
        name: 'Shiga',
        country_id: '392',
        state_cd: '25'
      },
      {
        state_cd_full: 'JP-26',
        name: 'Kyoto',
        country_id: '392',
        state_cd: '26'
      },
      {
        state_cd_full: 'JP-27',
        name: 'Osaka',
        country_id: '392',
        state_cd: '27'
      },
      {
        state_cd_full: 'JP-28',
        name: 'Hyogo',
        country_id: '392',
        state_cd: '28'
      },
      {
        state_cd_full: 'JP-29',
        name: 'Nara',
        country_id: '392',
        state_cd: '29'
      },
      {
        state_cd_full: 'JP-30',
        name: 'Wakayama',
        country_id: '392',
        state_cd: '30'
      },
      {
        state_cd_full: 'JP-31',
        name: 'Tottori',
        country_id: '392',
        state_cd: '31'
      },
      {
        state_cd_full: 'JP-32',
        name: 'Shimane',
        country_id: '392',
        state_cd: '32'
      },
      {
        state_cd_full: 'JP-33',
        name: 'Okayama',
        country_id: '392',
        state_cd: '33'
      },
      {
        state_cd_full: 'JP-34',
        name: 'Hiroshima',
        country_id: '392',
        state_cd: '34'
      },
      {
        state_cd_full: 'JP-35',
        name: 'Yamaguchi',
        country_id: '392',
        state_cd: '35'
      },
      {
        state_cd_full: 'JP-36',
        name: 'Tokushima',
        country_id: '392',
        state_cd: '36'
      },
      {
        state_cd_full: 'JP-37',
        name: 'Kagawa',
        country_id: '392',
        state_cd: '37'
      },
      {
        state_cd_full: 'JP-38',
        name: 'Ehime',
        country_id: '392',
        state_cd: '38'
      },
      {
        state_cd_full: 'JP-39',
        name: 'Kochi',
        country_id: '392',
        state_cd: '39'
      },
      {
        state_cd_full: 'JP-40',
        name: 'Fukuoka',
        country_id: '392',
        state_cd: '40'
      },
      {
        state_cd_full: 'JP-41',
        name: 'Saga',
        country_id: '392',
        state_cd: '41'
      },
      {
        state_cd_full: 'JP-42',
        name: 'Nagasaki',
        country_id: '392',
        state_cd: '42'
      },
      {
        state_cd_full: 'JP-43',
        name: 'Kumamoto',
        country_id: '392',
        state_cd: '43'
      },
      {
        state_cd_full: 'JP-44',
        name: 'Oita',
        country_id: '392',
        state_cd: '44'
      },
      {
        state_cd_full: 'JP-45',
        name: 'Miyazaki',
        country_id: '392',
        state_cd: '45'
      },
      {
        state_cd_full: 'JP-46',
        name: 'Kagoshima',
        country_id: '392',
        state_cd: '46'
      },
      {
        state_cd_full: 'JP-47',
        name: 'Okinawa',
        country_id: '392',
        state_cd: '47'
      }
    ]
  },
  {
    country_cd: 'JE',
    name: 'Jersey',
    country_id: 832,
    states: [
      {
        state_cd_full: 'JE-01',
        name: 'Jersey',
        country_id: '832',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'JO',
    name: 'Jordan',
    country_id: 400,
    states: [
      {
        state_cd_full: 'JO-AJ',
        name: "'Ajlun",
        country_id: '400',
        state_cd: 'AJ'
      },
      {
        state_cd_full: 'JO-AM',
        name: "Al 'Asimah",
        country_id: '400',
        state_cd: 'AM'
      },
      {
        state_cd_full: 'JO-AQ',
        name: "Al 'Aqabah",
        country_id: '400',
        state_cd: 'AQ'
      },
      {
        state_cd_full: 'JO-AT',
        name: 'At Tafilah',
        country_id: '400',
        state_cd: 'AT'
      },
      {
        state_cd_full: 'JO-AZ',
        name: "Az Zarqa'",
        country_id: '400',
        state_cd: 'AZ'
      },
      {
        state_cd_full: 'JO-BA',
        name: "Al Balqa'",
        country_id: '400',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'JO-IR',
        name: 'Irbid',
        country_id: '400',
        state_cd: 'IR'
      },
      {
        state_cd_full: 'JO-JA',
        name: 'Jarash',
        country_id: '400',
        state_cd: 'JA'
      },
      {
        state_cd_full: 'JO-KA',
        name: 'Al Karak',
        country_id: '400',
        state_cd: 'KA'
      },
      {
        state_cd_full: 'JO-MA',
        name: 'Al Mafraq',
        country_id: '400',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'JO-MD',
        name: 'Madaba',
        country_id: '400',
        state_cd: 'MD'
      },
      {
        state_cd_full: 'JO-MN',
        name: "Ma'an",
        country_id: '400',
        state_cd: 'MN'
      }
    ]
  },
  {
    country_cd: 'KZ',
    name: 'Kazakhstan',
    country_id: 398,
    states: [
      {
        state_cd_full: 'KZ-AKM',
        name: 'Aqmola oblysy',
        country_id: '398',
        state_cd: 'AKM'
      },
      {
        state_cd_full: 'KZ-AKT',
        name: 'Aqtobe oblysy',
        country_id: '398',
        state_cd: 'AKT'
      },
      {
        state_cd_full: 'KZ-ALA',
        name: 'Almaty',
        country_id: '398',
        state_cd: 'ALA'
      },
      {
        state_cd_full: 'KZ-ALM',
        name: 'Almaty oblysy',
        country_id: '398',
        state_cd: 'ALM'
      },
      {
        state_cd_full: 'KZ-AST',
        name: 'Astana',
        country_id: '398',
        state_cd: 'AST'
      },
      {
        state_cd_full: 'KZ-ATY',
        name: 'Atyrau oblysy',
        country_id: '398',
        state_cd: 'ATY'
      },
      {
        state_cd_full: 'KZ-BAY',
        name: 'Bayqongyr',
        country_id: '398',
        state_cd: 'BAY'
      },
      {
        state_cd_full: 'KZ-KAR',
        name: 'Qaraghandy oblysy',
        country_id: '398',
        state_cd: 'KAR'
      },
      {
        state_cd_full: 'KZ-KUS',
        name: 'Qostanay oblysy',
        country_id: '398',
        state_cd: 'KUS'
      },
      {
        state_cd_full: 'KZ-KZY',
        name: 'Qyzylorda oblysy',
        country_id: '398',
        state_cd: 'KZY'
      },
      {
        state_cd_full: 'KZ-MAN',
        name: 'Mangghystau oblysy',
        country_id: '398',
        state_cd: 'MAN'
      },
      {
        state_cd_full: 'KZ-PAV',
        name: 'Pavlodar oblysy',
        country_id: '398',
        state_cd: 'PAV'
      },
      {
        state_cd_full: 'KZ-SEV',
        name: 'Soltustik Qazaqstan oblysy',
        country_id: '398',
        state_cd: 'SEV'
      },
      {
        state_cd_full: 'KZ-VOS',
        name: 'Shyghys Qazaqstan oblysy',
        country_id: '398',
        state_cd: 'VOS'
      },
      {
        state_cd_full: 'KZ-YUZ',
        name: 'Ongtustik Qazaqstan oblysy',
        country_id: '398',
        state_cd: 'YUZ'
      },
      {
        state_cd_full: 'KZ-ZAP',
        name: 'Batys Qazaqstan oblysy',
        country_id: '398',
        state_cd: 'ZAP'
      },
      {
        state_cd_full: 'KZ-ZHA',
        name: 'Zhambyl oblysy',
        country_id: '398',
        state_cd: 'ZHA'
      }
    ]
  },
  {
    country_cd: 'KE',
    name: 'Kenya',
    country_id: 404,
    states: [
      {
        state_cd_full: 'KE-01',
        name: 'Baringo',
        country_id: '404',
        state_cd: '01'
      },
      {
        state_cd_full: 'KE-02',
        name: 'Bomet',
        country_id: '404',
        state_cd: '02'
      },
      {
        state_cd_full: 'KE-03',
        name: 'Bungoma',
        country_id: '404',
        state_cd: '03'
      },
      {
        state_cd_full: 'KE-04',
        name: 'Busia',
        country_id: '404',
        state_cd: '04'
      },
      {
        state_cd_full: 'KE-05',
        name: 'Elgeyo/Marakwet',
        country_id: '404',
        state_cd: '05'
      },
      {
        state_cd_full: 'KE-06',
        name: 'Embu',
        country_id: '404',
        state_cd: '06'
      },
      {
        state_cd_full: 'KE-07',
        name: 'Garissa',
        country_id: '404',
        state_cd: '07'
      },
      {
        state_cd_full: 'KE-08',
        name: 'Homa Bay',
        country_id: '404',
        state_cd: '08'
      },
      {
        state_cd_full: 'KE-09',
        name: 'Isiolo',
        country_id: '404',
        state_cd: '09'
      },
      {
        state_cd_full: 'KE-10',
        name: 'Kajiado',
        country_id: '404',
        state_cd: '10'
      },
      {
        state_cd_full: 'KE-11',
        name: 'Kakamega',
        country_id: '404',
        state_cd: '11'
      },
      {
        state_cd_full: 'KE-12',
        name: 'Kericho',
        country_id: '404',
        state_cd: '12'
      },
      {
        state_cd_full: 'KE-13',
        name: 'Kiambu',
        country_id: '404',
        state_cd: '13'
      },
      {
        state_cd_full: 'KE-14',
        name: 'Kilifi',
        country_id: '404',
        state_cd: '14'
      },
      {
        state_cd_full: 'KE-15',
        name: 'Kirinyaga',
        country_id: '404',
        state_cd: '15'
      },
      {
        state_cd_full: 'KE-16',
        name: 'Kisii',
        country_id: '404',
        state_cd: '16'
      },
      {
        state_cd_full: 'KE-17',
        name: 'Kisumu',
        country_id: '404',
        state_cd: '17'
      },
      {
        state_cd_full: 'KE-18',
        name: 'Kitui',
        country_id: '404',
        state_cd: '18'
      },
      {
        state_cd_full: 'KE-19',
        name: 'Kwale',
        country_id: '404',
        state_cd: '19'
      },
      {
        state_cd_full: 'KE-20',
        name: 'Laikipia',
        country_id: '404',
        state_cd: '20'
      },
      {
        state_cd_full: 'KE-21',
        name: 'Lamu',
        country_id: '404',
        state_cd: '21'
      },
      {
        state_cd_full: 'KE-22',
        name: 'Machakos',
        country_id: '404',
        state_cd: '22'
      },
      {
        state_cd_full: 'KE-23',
        name: 'Makueni',
        country_id: '404',
        state_cd: '23'
      },
      {
        state_cd_full: 'KE-24',
        name: 'Mandera',
        country_id: '404',
        state_cd: '24'
      },
      {
        state_cd_full: 'KE-25',
        name: 'Marsabit',
        country_id: '404',
        state_cd: '25'
      },
      {
        state_cd_full: 'KE-26',
        name: 'Meru',
        country_id: '404',
        state_cd: '26'
      },
      {
        state_cd_full: 'KE-27',
        name: 'Migori',
        country_id: '404',
        state_cd: '27'
      },
      {
        state_cd_full: 'KE-28',
        name: 'Mombasa',
        country_id: '404',
        state_cd: '28'
      },
      {
        state_cd_full: 'KE-29',
        name: "Murang'a",
        country_id: '404',
        state_cd: '29'
      },
      {
        state_cd_full: 'KE-30',
        name: 'Nairobi City',
        country_id: '404',
        state_cd: '30'
      },
      {
        state_cd_full: 'KE-31',
        name: 'Nakuru',
        country_id: '404',
        state_cd: '31'
      },
      {
        state_cd_full: 'KE-32',
        name: 'Nandi',
        country_id: '404',
        state_cd: '32'
      },
      {
        state_cd_full: 'KE-33',
        name: 'Narok',
        country_id: '404',
        state_cd: '33'
      },
      {
        state_cd_full: 'KE-34',
        name: 'Nyamira',
        country_id: '404',
        state_cd: '34'
      },
      {
        state_cd_full: 'KE-35',
        name: 'Nyandarua',
        country_id: '404',
        state_cd: '35'
      },
      {
        state_cd_full: 'KE-36',
        name: 'Nyeri',
        country_id: '404',
        state_cd: '36'
      },
      {
        state_cd_full: 'KE-38',
        name: 'Siaya',
        country_id: '404',
        state_cd: '38'
      },
      {
        state_cd_full: 'KE-39',
        name: 'Taita/Taveta',
        country_id: '404',
        state_cd: '39'
      },
      {
        state_cd_full: 'KE-41',
        name: 'Tharaka-Nithi',
        country_id: '404',
        state_cd: '41'
      },
      {
        state_cd_full: 'KE-42',
        name: 'Trans Nzoia',
        country_id: '404',
        state_cd: '42'
      },
      {
        state_cd_full: 'KE-43',
        name: 'Turkana',
        country_id: '404',
        state_cd: '43'
      },
      {
        state_cd_full: 'KE-44',
        name: 'Uasin Gishu',
        country_id: '404',
        state_cd: '44'
      },
      {
        state_cd_full: 'KE-46',
        name: 'Wajir',
        country_id: '404',
        state_cd: '46'
      }
    ]
  },
  {
    country_cd: 'KI',
    name: 'Kiribati',
    country_id: 296,
    states: [
      {
        state_cd_full: 'KI-G',
        name: 'Gilbert Islands',
        country_id: '296',
        state_cd: 'G'
      },
      {
        state_cd_full: 'KI-L',
        name: 'Line Islands',
        country_id: '296',
        state_cd: 'L'
      }
    ]
  },
  {
    country_cd: 'KP',
    name: "Korea (Democratic People's Republic of)",
    country_id: 408,
    states: [
      {
        state_cd_full: 'KP-01',
        name: "P'yongyang",
        country_id: '408',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'KR',
    name: 'Korea, Republic of',
    country_id: 410,
    states: [
      {
        state_cd_full: 'KR-11',
        name: 'Seoul-teukbyeolsi',
        country_id: '410',
        state_cd: '11'
      },
      {
        state_cd_full: 'KR-26',
        name: 'Busan-gwangyeoksi',
        country_id: '410',
        state_cd: '26'
      },
      {
        state_cd_full: 'KR-27',
        name: 'Daegu-gwangyeoksi',
        country_id: '410',
        state_cd: '27'
      },
      {
        state_cd_full: 'KR-28',
        name: 'Incheon-gwangyeoksi',
        country_id: '410',
        state_cd: '28'
      },
      {
        state_cd_full: 'KR-29',
        name: 'Gwangju-gwangyeoksi',
        country_id: '410',
        state_cd: '29'
      },
      {
        state_cd_full: 'KR-30',
        name: 'Daejeon-gwangyeoksi',
        country_id: '410',
        state_cd: '30'
      },
      {
        state_cd_full: 'KR-31',
        name: 'Ulsan-gwangyeoksi',
        country_id: '410',
        state_cd: '31'
      },
      {
        state_cd_full: 'KR-41',
        name: 'Gyeonggi-do',
        country_id: '410',
        state_cd: '41'
      },
      {
        state_cd_full: 'KR-42',
        name: 'Gangwon-do',
        country_id: '410',
        state_cd: '42'
      },
      {
        state_cd_full: 'KR-43',
        name: 'Chungcheongbuk-do',
        country_id: '410',
        state_cd: '43'
      },
      {
        state_cd_full: 'KR-44',
        name: 'Chungcheongnam-do',
        country_id: '410',
        state_cd: '44'
      },
      {
        state_cd_full: 'KR-45',
        name: 'Jeollabuk-do',
        country_id: '410',
        state_cd: '45'
      },
      {
        state_cd_full: 'KR-46',
        name: 'Jeollanam-do',
        country_id: '410',
        state_cd: '46'
      },
      {
        state_cd_full: 'KR-47',
        name: 'Gyeongsangbuk-do',
        country_id: '410',
        state_cd: '47'
      },
      {
        state_cd_full: 'KR-48',
        name: 'Gyeongsangnam-do',
        country_id: '410',
        state_cd: '48'
      },
      {
        state_cd_full: 'KR-49',
        name: 'Jeju-teukbyeoljachido',
        country_id: '410',
        state_cd: '49'
      }
    ]
  },
  {
    country_cd: 'KW',
    name: 'Kuwait',
    country_id: 414,
    states: [
      {
        state_cd_full: 'KW-AH',
        name: 'Al Ahmadi',
        country_id: '414',
        state_cd: 'AH'
      },
      {
        state_cd_full: 'KW-FA',
        name: 'Al Farwaniyah',
        country_id: '414',
        state_cd: 'FA'
      },
      {
        state_cd_full: 'KW-HA',
        name: 'Hawalli',
        country_id: '414',
        state_cd: 'HA'
      },
      {
        state_cd_full: 'KW-JA',
        name: "Al Jahra'",
        country_id: '414',
        state_cd: 'JA'
      },
      {
        state_cd_full: 'KW-KU',
        name: "Al 'Asimah",
        country_id: '414',
        state_cd: 'KU'
      },
      {
        state_cd_full: 'KW-MU',
        name: 'Mubarak al Kabir',
        country_id: '414',
        state_cd: 'MU'
      }
    ]
  },
  {
    country_cd: 'KG',
    name: 'Kyrgyzstan',
    country_id: 417,
    states: [
      {
        state_cd_full: 'KG-B',
        name: 'Batken',
        country_id: '417',
        state_cd: 'B'
      },
      {
        state_cd_full: 'KG-C',
        name: 'Chuy',
        country_id: '417',
        state_cd: 'C'
      },
      {
        state_cd_full: 'KG-GB',
        name: 'Bishkek',
        country_id: '417',
        state_cd: 'GB'
      },
      {
        state_cd_full: 'KG-GO',
        name: 'Osh',
        country_id: '417',
        state_cd: 'GO'
      },
      {
        state_cd_full: 'KG-J',
        name: 'Jalal-Abad',
        country_id: '417',
        state_cd: 'J'
      },
      {
        state_cd_full: 'KG-N',
        name: 'Naryn',
        country_id: '417',
        state_cd: 'N'
      },
      {
        state_cd_full: 'KG-T',
        name: 'Talas',
        country_id: '417',
        state_cd: 'T'
      },
      {
        state_cd_full: 'KG-Y',
        name: 'Ysyk-Kol',
        country_id: '417',
        state_cd: 'Y'
      }
    ]
  },
  {
    country_cd: 'LA',
    name: "Lao People's Democratic Republic",
    country_id: 418,
    states: [
      {
        state_cd_full: 'LA-AT',
        name: 'Attapu',
        country_id: '418',
        state_cd: 'AT'
      },
      {
        state_cd_full: 'LA-CH',
        name: 'Champasak',
        country_id: '418',
        state_cd: 'CH'
      },
      {
        state_cd_full: 'LA-HO',
        name: 'Houaphan',
        country_id: '418',
        state_cd: 'HO'
      },
      {
        state_cd_full: 'LA-KH',
        name: 'Khammouan',
        country_id: '418',
        state_cd: 'KH'
      },
      {
        state_cd_full: 'LA-LM',
        name: 'Louang Namtha',
        country_id: '418',
        state_cd: 'LM'
      },
      {
        state_cd_full: 'LA-LP',
        name: 'Louangphabang',
        country_id: '418',
        state_cd: 'LP'
      },
      {
        state_cd_full: 'LA-OU',
        name: 'Oudomxai',
        country_id: '418',
        state_cd: 'OU'
      },
      {
        state_cd_full: 'LA-SV',
        name: 'Savannakhet',
        country_id: '418',
        state_cd: 'SV'
      },
      {
        state_cd_full: 'LA-VI',
        name: 'Viangchan',
        country_id: '418',
        state_cd: 'VI'
      },
      {
        state_cd_full: 'LA-XA',
        name: 'Xaignabouli',
        country_id: '418',
        state_cd: 'XA'
      },
      {
        state_cd_full: 'LA-XE',
        name: 'Xekong',
        country_id: '418',
        state_cd: 'XE'
      },
      {
        state_cd_full: 'LA-XI',
        name: 'Xiangkhouang',
        country_id: '418',
        state_cd: 'XI'
      }
    ]
  },
  {
    country_cd: 'LV',
    name: 'Latvia',
    country_id: 428,
    states: [
      {
        state_cd_full: 'LV-001',
        name: 'Aglonas novads',
        country_id: '428',
        state_cd: '001'
      },
      {
        state_cd_full: 'LV-002',
        name: 'Aizkraukles novads',
        country_id: '428',
        state_cd: '002'
      },
      {
        state_cd_full: 'LV-003',
        name: 'Aizputes novads',
        country_id: '428',
        state_cd: '003'
      },
      {
        state_cd_full: 'LV-005',
        name: 'Alojas novads',
        country_id: '428',
        state_cd: '005'
      },
      {
        state_cd_full: 'LV-007',
        name: 'Aluksnes novads',
        country_id: '428',
        state_cd: '007'
      },
      {
        state_cd_full: 'LV-011',
        name: 'Adazu novads',
        country_id: '428',
        state_cd: '011'
      },
      {
        state_cd_full: 'LV-012',
        name: 'Babites novads',
        country_id: '428',
        state_cd: '012'
      },
      {
        state_cd_full: 'LV-013',
        name: 'Baldones novads',
        country_id: '428',
        state_cd: '013'
      },
      {
        state_cd_full: 'LV-014',
        name: 'Baltinavas novads',
        country_id: '428',
        state_cd: '014'
      },
      {
        state_cd_full: 'LV-015',
        name: 'Balvu novads',
        country_id: '428',
        state_cd: '015'
      },
      {
        state_cd_full: 'LV-016',
        name: 'Bauskas novads',
        country_id: '428',
        state_cd: '016'
      },
      {
        state_cd_full: 'LV-017',
        name: 'Beverinas novads',
        country_id: '428',
        state_cd: '017'
      },
      {
        state_cd_full: 'LV-018',
        name: 'Brocenu novads',
        country_id: '428',
        state_cd: '018'
      },
      {
        state_cd_full: 'LV-020',
        name: 'Carnikavas novads',
        country_id: '428',
        state_cd: '020'
      },
      {
        state_cd_full: 'LV-021',
        name: 'Cesvaines novads',
        country_id: '428',
        state_cd: '021'
      },
      {
        state_cd_full: 'LV-022',
        name: 'Cesu novads',
        country_id: '428',
        state_cd: '022'
      },
      {
        state_cd_full: 'LV-023',
        name: 'Ciblas novads',
        country_id: '428',
        state_cd: '023'
      },
      {
        state_cd_full: 'LV-025',
        name: 'Daugavpils novads',
        country_id: '428',
        state_cd: '025'
      },
      {
        state_cd_full: 'LV-026',
        name: 'Dobeles novads',
        country_id: '428',
        state_cd: '026'
      },
      {
        state_cd_full: 'LV-027',
        name: 'Dundagas novads',
        country_id: '428',
        state_cd: '027'
      },
      {
        state_cd_full: 'LV-030',
        name: 'Erglu novads',
        country_id: '428',
        state_cd: '030'
      },
      {
        state_cd_full: 'LV-033',
        name: 'Gulbenes novads',
        country_id: '428',
        state_cd: '033'
      },
      {
        state_cd_full: 'LV-034',
        name: 'Iecavas novads',
        country_id: '428',
        state_cd: '034'
      },
      {
        state_cd_full: 'LV-035',
        name: 'Ikskiles novads',
        country_id: '428',
        state_cd: '035'
      },
      {
        state_cd_full: 'LV-037',
        name: 'Incukalna novads',
        country_id: '428',
        state_cd: '037'
      },
      {
        state_cd_full: 'LV-038',
        name: 'Jaunjelgavas novads',
        country_id: '428',
        state_cd: '038'
      },
      {
        state_cd_full: 'LV-039',
        name: 'Jaunpiebalgas novads',
        country_id: '428',
        state_cd: '039'
      },
      {
        state_cd_full: 'LV-040',
        name: 'Jaunpils novads',
        country_id: '428',
        state_cd: '040'
      },
      {
        state_cd_full: 'LV-041',
        name: 'Jelgavas novads',
        country_id: '428',
        state_cd: '041'
      },
      {
        state_cd_full: 'LV-042',
        name: 'Jekabpils novads',
        country_id: '428',
        state_cd: '042'
      },
      {
        state_cd_full: 'LV-046',
        name: 'Kokneses novads',
        country_id: '428',
        state_cd: '046'
      },
      {
        state_cd_full: 'LV-047',
        name: 'Kraslavas novads',
        country_id: '428',
        state_cd: '047'
      },
      {
        state_cd_full: 'LV-050',
        name: 'Kuldigas novads',
        country_id: '428',
        state_cd: '050'
      },
      {
        state_cd_full: 'LV-052',
        name: 'Kekavas novads',
        country_id: '428',
        state_cd: '052'
      },
      {
        state_cd_full: 'LV-053',
        name: 'Lielvardes novads',
        country_id: '428',
        state_cd: '053'
      },
      {
        state_cd_full: 'LV-054',
        name: 'Limbazu novads',
        country_id: '428',
        state_cd: '054'
      },
      {
        state_cd_full: 'LV-056',
        name: 'Livanu novads',
        country_id: '428',
        state_cd: '056'
      },
      {
        state_cd_full: 'LV-057',
        name: 'Lubanas novads',
        country_id: '428',
        state_cd: '057'
      },
      {
        state_cd_full: 'LV-058',
        name: 'Ludzas novads',
        country_id: '428',
        state_cd: '058'
      },
      {
        state_cd_full: 'LV-059',
        name: 'Madonas novads',
        country_id: '428',
        state_cd: '059'
      },
      {
        state_cd_full: 'LV-061',
        name: 'Malpils novads',
        country_id: '428',
        state_cd: '061'
      },
      {
        state_cd_full: 'LV-064',
        name: 'Nauksenu novads',
        country_id: '428',
        state_cd: '064'
      },
      {
        state_cd_full: 'LV-067',
        name: 'Ogres novads',
        country_id: '428',
        state_cd: '067'
      },
      {
        state_cd_full: 'LV-068',
        name: 'Olaines novads',
        country_id: '428',
        state_cd: '068'
      },
      {
        state_cd_full: 'LV-069',
        name: 'Ozolnieku novads',
        country_id: '428',
        state_cd: '069'
      },
      {
        state_cd_full: 'LV-073',
        name: 'Preilu novads',
        country_id: '428',
        state_cd: '073'
      },
      {
        state_cd_full: 'LV-077',
        name: 'Rezeknes novads',
        country_id: '428',
        state_cd: '077'
      },
      {
        state_cd_full: 'LV-078',
        name: 'Riebinu novads',
        country_id: '428',
        state_cd: '078'
      },
      {
        state_cd_full: 'LV-079',
        name: 'Rojas novads',
        country_id: '428',
        state_cd: '079'
      },
      {
        state_cd_full: 'LV-080',
        name: 'Ropazu novads',
        country_id: '428',
        state_cd: '080'
      },
      {
        state_cd_full: 'LV-082',
        name: 'Rugaju novads',
        country_id: '428',
        state_cd: '082'
      },
      {
        state_cd_full: 'LV-083',
        name: 'Rundales novads',
        country_id: '428',
        state_cd: '083'
      },
      {
        state_cd_full: 'LV-086',
        name: 'Salacgrivas novads',
        country_id: '428',
        state_cd: '086'
      },
      {
        state_cd_full: 'LV-087',
        name: 'Salaspils novads',
        country_id: '428',
        state_cd: '087'
      },
      {
        state_cd_full: 'LV-088',
        name: 'Saldus novads',
        country_id: '428',
        state_cd: '088'
      },
      {
        state_cd_full: 'LV-089',
        name: 'Saulkrastu novads',
        country_id: '428',
        state_cd: '089'
      },
      {
        state_cd_full: 'LV-090',
        name: 'Sejas novads',
        country_id: '428',
        state_cd: '090'
      },
      {
        state_cd_full: 'LV-091',
        name: 'Siguldas novads',
        country_id: '428',
        state_cd: '091'
      },
      {
        state_cd_full: 'LV-093',
        name: 'Skrundas novads',
        country_id: '428',
        state_cd: '093'
      },
      {
        state_cd_full: 'LV-095',
        name: 'Stopinu novads',
        country_id: '428',
        state_cd: '095'
      },
      {
        state_cd_full: 'LV-097',
        name: 'Talsu novads',
        country_id: '428',
        state_cd: '097'
      },
      {
        state_cd_full: 'LV-099',
        name: 'Tukuma novads',
        country_id: '428',
        state_cd: '099'
      },
      {
        state_cd_full: 'LV-101',
        name: 'Valkas novads',
        country_id: '428',
        state_cd: '101'
      },
      {
        state_cd_full: 'LV-105',
        name: 'Vecumnieku novads',
        country_id: '428',
        state_cd: '105'
      },
      {
        state_cd_full: 'LV-106',
        name: 'Ventspils novads',
        country_id: '428',
        state_cd: '106'
      },
      {
        state_cd_full: 'LV-JEL',
        name: 'Jelgava',
        country_id: '428',
        state_cd: 'JEL'
      },
      {
        state_cd_full: 'LV-JUR',
        name: 'Jurmala',
        country_id: '428',
        state_cd: 'JUR'
      },
      {
        state_cd_full: 'LV-LPX',
        name: 'Liepaja',
        country_id: '428',
        state_cd: 'LPX'
      },
      {
        state_cd_full: 'LV-RIX',
        name: 'Riga',
        country_id: '428',
        state_cd: 'RIX'
      },
      {
        state_cd_full: 'LV-VMR',
        name: 'Valmiera',
        country_id: '428',
        state_cd: 'VMR'
      }
    ]
  },
  {
    country_cd: 'LB',
    name: 'Lebanon',
    country_id: 422,
    states: [
      {
        state_cd_full: 'LB-AK',
        name: 'Aakkar',
        country_id: '422',
        state_cd: 'AK'
      },
      {
        state_cd_full: 'LB-AS',
        name: 'Liban-Nord',
        country_id: '422',
        state_cd: 'AS'
      },
      {
        state_cd_full: 'LB-BA',
        name: 'Beyrouth',
        country_id: '422',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'LB-BH',
        name: 'Baalbek-Hermel',
        country_id: '422',
        state_cd: 'BH'
      },
      {
        state_cd_full: 'LB-BI',
        name: 'Beqaa',
        country_id: '422',
        state_cd: 'BI'
      },
      {
        state_cd_full: 'LB-JA',
        name: 'Liban-Sud',
        country_id: '422',
        state_cd: 'JA'
      },
      {
        state_cd_full: 'LB-JL',
        name: 'Mont-Liban',
        country_id: '422',
        state_cd: 'JL'
      },
      {
        state_cd_full: 'LB-NA',
        name: 'Nabatiye',
        country_id: '422',
        state_cd: 'NA'
      }
    ]
  },
  {
    country_cd: 'LS',
    name: 'Lesotho',
    country_id: 426,
    states: [
      {
        state_cd_full: 'LS-A',
        name: 'Maseru',
        country_id: '426',
        state_cd: 'A'
      },
      {
        state_cd_full: 'LS-B',
        name: 'Butha-Buthe',
        country_id: '426',
        state_cd: 'B'
      },
      {
        state_cd_full: 'LS-C',
        name: 'Leribe',
        country_id: '426',
        state_cd: 'C'
      },
      {
        state_cd_full: 'LS-D',
        name: 'Berea',
        country_id: '426',
        state_cd: 'D'
      },
      {
        state_cd_full: 'LS-F',
        name: "Mohale's Hoek",
        country_id: '426',
        state_cd: 'F'
      },
      {
        state_cd_full: 'LS-G',
        name: 'Quthing',
        country_id: '426',
        state_cd: 'G'
      }
    ]
  },
  {
    country_cd: 'LR',
    name: 'Liberia',
    country_id: 430,
    states: [
      {
        state_cd_full: 'LR-GB',
        name: 'Grand Bassa',
        country_id: '430',
        state_cd: 'GB'
      },
      {
        state_cd_full: 'LR-GG',
        name: 'Grand Gedeh',
        country_id: '430',
        state_cd: 'GG'
      },
      {
        state_cd_full: 'LR-MG',
        name: 'Margibi',
        country_id: '430',
        state_cd: 'MG'
      },
      {
        state_cd_full: 'LR-MO',
        name: 'Montserrado',
        country_id: '430',
        state_cd: 'MO'
      },
      {
        state_cd_full: 'LR-MY',
        name: 'Maryland',
        country_id: '430',
        state_cd: 'MY'
      },
      {
        state_cd_full: 'LR-NI',
        name: 'Nimba',
        country_id: '430',
        state_cd: 'NI'
      },
      {
        state_cd_full: 'LR-RG',
        name: 'River Gee',
        country_id: '430',
        state_cd: 'RG'
      },
      {
        state_cd_full: 'LR-SI',
        name: 'Sinoe',
        country_id: '430',
        state_cd: 'SI'
      }
    ]
  },
  {
    country_cd: 'LY',
    name: 'Libya',
    country_id: 434,
    states: [
      {
        state_cd_full: 'LY-BA',
        name: 'Banghazi',
        country_id: '434',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'LY-BU',
        name: 'Al Butnan',
        country_id: '434',
        state_cd: 'BU'
      },
      {
        state_cd_full: 'LY-DR',
        name: 'Darnah',
        country_id: '434',
        state_cd: 'DR'
      },
      {
        state_cd_full: 'LY-JA',
        name: 'Al Jabal al Akhdar',
        country_id: '434',
        state_cd: 'JA'
      },
      {
        state_cd_full: 'LY-JG',
        name: 'Al Jabal al Gharbi',
        country_id: '434',
        state_cd: 'JG'
      },
      {
        state_cd_full: 'LY-JU',
        name: 'Al Jufrah',
        country_id: '434',
        state_cd: 'JU'
      },
      {
        state_cd_full: 'LY-KF',
        name: 'Al Kufrah',
        country_id: '434',
        state_cd: 'KF'
      },
      {
        state_cd_full: 'LY-MB',
        name: 'Al Marqab',
        country_id: '434',
        state_cd: 'MB'
      },
      {
        state_cd_full: 'LY-MI',
        name: 'Misratah',
        country_id: '434',
        state_cd: 'MI'
      },
      {
        state_cd_full: 'LY-MJ',
        name: 'Al Marj',
        country_id: '434',
        state_cd: 'MJ'
      },
      {
        state_cd_full: 'LY-MQ',
        name: 'Murzuq',
        country_id: '434',
        state_cd: 'MQ'
      },
      {
        state_cd_full: 'LY-NL',
        name: 'Nalut',
        country_id: '434',
        state_cd: 'NL'
      },
      {
        state_cd_full: 'LY-NQ',
        name: 'An Nuqat al Khams',
        country_id: '434',
        state_cd: 'NQ'
      },
      {
        state_cd_full: 'LY-SB',
        name: 'Sabha',
        country_id: '434',
        state_cd: 'SB'
      },
      {
        state_cd_full: 'LY-SR',
        name: 'Surt',
        country_id: '434',
        state_cd: 'SR'
      },
      {
        state_cd_full: 'LY-TB',
        name: 'Tarabulus',
        country_id: '434',
        state_cd: 'TB'
      },
      {
        state_cd_full: 'LY-WA',
        name: 'Al Wahat',
        country_id: '434',
        state_cd: 'WA'
      },
      {
        state_cd_full: 'LY-WS',
        name: "Wadi ash Shati'",
        country_id: '434',
        state_cd: 'WS'
      },
      {
        state_cd_full: 'LY-ZA',
        name: 'Az Zawiyah',
        country_id: '434',
        state_cd: 'ZA'
      }
    ]
  },
  {
    country_cd: 'LI',
    name: 'Liechtenstein',
    country_id: 438,
    states: [
      {
        state_cd_full: 'LI-01',
        name: 'Balzers',
        country_id: '438',
        state_cd: '01'
      },
      {
        state_cd_full: 'LI-02',
        name: 'Eschen',
        country_id: '438',
        state_cd: '02'
      },
      {
        state_cd_full: 'LI-03',
        name: 'Gamprin',
        country_id: '438',
        state_cd: '03'
      },
      {
        state_cd_full: 'LI-04',
        name: 'Mauren',
        country_id: '438',
        state_cd: '04'
      },
      {
        state_cd_full: 'LI-05',
        name: 'Planken',
        country_id: '438',
        state_cd: '05'
      },
      {
        state_cd_full: 'LI-06',
        name: 'Ruggell',
        country_id: '438',
        state_cd: '06'
      },
      {
        state_cd_full: 'LI-07',
        name: 'Schaan',
        country_id: '438',
        state_cd: '07'
      },
      {
        state_cd_full: 'LI-08',
        name: 'Schellenberg',
        country_id: '438',
        state_cd: '08'
      },
      {
        state_cd_full: 'LI-09',
        name: 'Triesen',
        country_id: '438',
        state_cd: '09'
      },
      {
        state_cd_full: 'LI-10',
        name: 'Triesenberg',
        country_id: '438',
        state_cd: '10'
      },
      {
        state_cd_full: 'LI-11',
        name: 'Vaduz',
        country_id: '438',
        state_cd: '11'
      }
    ]
  },
  {
    country_cd: 'LT',
    name: 'Lithuania',
    country_id: 440,
    states: [
      {
        state_cd_full: 'LT-AL',
        name: 'Alytaus apskritis',
        country_id: '440',
        state_cd: 'AL'
      },
      {
        state_cd_full: 'LT-KL',
        name: 'Klaipedos apskritis',
        country_id: '440',
        state_cd: 'KL'
      },
      {
        state_cd_full: 'LT-KU',
        name: 'Kauno apskritis',
        country_id: '440',
        state_cd: 'KU'
      },
      {
        state_cd_full: 'LT-MR',
        name: 'Marijampoles apskritis',
        country_id: '440',
        state_cd: 'MR'
      },
      {
        state_cd_full: 'LT-PN',
        name: 'Panevezio apskritis',
        country_id: '440',
        state_cd: 'PN'
      },
      {
        state_cd_full: 'LT-SA',
        name: 'Siauliu apskritis',
        country_id: '440',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'LT-TA',
        name: 'Taurages apskritis',
        country_id: '440',
        state_cd: 'TA'
      },
      {
        state_cd_full: 'LT-TE',
        name: 'Telsiu apskritis',
        country_id: '440',
        state_cd: 'TE'
      },
      {
        state_cd_full: 'LT-UT',
        name: 'Utenos apskritis',
        country_id: '440',
        state_cd: 'UT'
      },
      {
        state_cd_full: 'LT-VL',
        name: 'Vilniaus apskritis',
        country_id: '440',
        state_cd: 'VL'
      }
    ]
  },
  {
    country_cd: 'LU',
    name: 'Luxembourg',
    country_id: 442,
    states: [
      {
        state_cd_full: 'LU-DI',
        name: 'Diekirch',
        country_id: '442',
        state_cd: 'DI'
      },
      {
        state_cd_full: 'LU-GR',
        name: 'Grevenmacher',
        country_id: '442',
        state_cd: 'GR'
      },
      {
        state_cd_full: 'LU-LU',
        name: 'Luxembourg',
        country_id: '442',
        state_cd: 'LU'
      }
    ]
  },
  {
    country_cd: 'MO',
    name: 'Macao',
    country_id: 446,
    states: [
      {
        state_cd_full: 'MO-01',
        name: 'Macao',
        country_id: '446',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'MG',
    name: 'Madagascar',
    country_id: 450,
    states: [
      {
        state_cd_full: 'MG-A',
        name: 'Toamasina',
        country_id: '450',
        state_cd: 'A'
      },
      {
        state_cd_full: 'MG-D',
        name: 'Antsiranana',
        country_id: '450',
        state_cd: 'D'
      },
      {
        state_cd_full: 'MG-F',
        name: 'Fianarantsoa',
        country_id: '450',
        state_cd: 'F'
      },
      {
        state_cd_full: 'MG-M',
        name: 'Mahajanga',
        country_id: '450',
        state_cd: 'M'
      },
      {
        state_cd_full: 'MG-T',
        name: 'Antananarivo',
        country_id: '450',
        state_cd: 'T'
      },
      {
        state_cd_full: 'MG-U',
        name: 'Toliara',
        country_id: '450',
        state_cd: 'U'
      }
    ]
  },
  {
    country_cd: 'MW',
    name: 'Malawi',
    country_id: 454,
    states: [
      {
        state_cd_full: 'MW-BA',
        name: 'Balaka',
        country_id: '454',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'MW-BL',
        name: 'Blantyre',
        country_id: '454',
        state_cd: 'BL'
      },
      {
        state_cd_full: 'MW-DO',
        name: 'Dowa',
        country_id: '454',
        state_cd: 'DO'
      },
      {
        state_cd_full: 'MW-LI',
        name: 'Lilongwe',
        country_id: '454',
        state_cd: 'LI'
      },
      {
        state_cd_full: 'MW-MG',
        name: 'Mangochi',
        country_id: '454',
        state_cd: 'MG'
      },
      {
        state_cd_full: 'MW-MH',
        name: 'Machinga',
        country_id: '454',
        state_cd: 'MH'
      },
      {
        state_cd_full: 'MW-MZ',
        name: 'Mzimba',
        country_id: '454',
        state_cd: 'MZ'
      },
      {
        state_cd_full: 'MW-NI',
        name: 'Ntchisi',
        country_id: '454',
        state_cd: 'NI'
      },
      {
        state_cd_full: 'MW-SA',
        name: 'Salima',
        country_id: '454',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'MW-ZO',
        name: 'Zomba',
        country_id: '454',
        state_cd: 'ZO'
      }
    ]
  },
  {
    country_cd: 'MY',
    name: 'Malaysia',
    country_id: 458,
    states: [
      {
        state_cd_full: 'MY-01',
        name: 'Johor',
        country_id: '458',
        state_cd: '01'
      },
      {
        state_cd_full: 'MY-02',
        name: 'Kedah',
        country_id: '458',
        state_cd: '02'
      },
      {
        state_cd_full: 'MY-03',
        name: 'Kelantan',
        country_id: '458',
        state_cd: '03'
      },
      {
        state_cd_full: 'MY-04',
        name: 'Melaka',
        country_id: '458',
        state_cd: '04'
      },
      {
        state_cd_full: 'MY-05',
        name: 'Negeri Sembilan',
        country_id: '458',
        state_cd: '05'
      },
      {
        state_cd_full: 'MY-06',
        name: 'Pahang',
        country_id: '458',
        state_cd: '06'
      },
      {
        state_cd_full: 'MY-07',
        name: 'Pulau Pinang',
        country_id: '458',
        state_cd: '07'
      },
      {
        state_cd_full: 'MY-08',
        name: 'Perak',
        country_id: '458',
        state_cd: '08'
      },
      {
        state_cd_full: 'MY-09',
        name: 'Perlis',
        country_id: '458',
        state_cd: '09'
      },
      {
        state_cd_full: 'MY-10',
        name: 'Selangor',
        country_id: '458',
        state_cd: '10'
      },
      {
        state_cd_full: 'MY-11',
        name: 'Terengganu',
        country_id: '458',
        state_cd: '11'
      },
      {
        state_cd_full: 'MY-12',
        name: 'Sabah',
        country_id: '458',
        state_cd: '12'
      },
      {
        state_cd_full: 'MY-13',
        name: 'Sarawak',
        country_id: '458',
        state_cd: '13'
      },
      {
        state_cd_full: 'MY-14',
        name: 'Wilayah Persekutuan Kuala Lumpur',
        country_id: '458',
        state_cd: '14'
      },
      {
        state_cd_full: 'MY-15',
        name: 'Wilayah Persekutuan Labuan',
        country_id: '458',
        state_cd: '15'
      },
      {
        state_cd_full: 'MY-16',
        name: 'Wilayah Persekutuan Putrajaya',
        country_id: '458',
        state_cd: '16'
      }
    ]
  },
  {
    country_cd: 'MV',
    name: 'Maldives',
    country_id: 462,
    states: [
      {
        state_cd_full: 'MV-01',
        name: 'Seenu',
        country_id: '462',
        state_cd: '01'
      },
      {
        state_cd_full: 'MV-02',
        name: 'Alifu Alifu',
        country_id: '462',
        state_cd: '02'
      },
      {
        state_cd_full: 'MV-04',
        name: 'Vaavu',
        country_id: '462',
        state_cd: '04'
      },
      {
        state_cd_full: 'MV-05',
        name: 'Laamu',
        country_id: '462',
        state_cd: '05'
      },
      {
        state_cd_full: 'MV-12',
        name: 'Meemu',
        country_id: '462',
        state_cd: '12'
      },
      {
        state_cd_full: 'MV-13',
        name: 'Raa',
        country_id: '462',
        state_cd: '13'
      },
      {
        state_cd_full: 'MV-20',
        name: 'Baa',
        country_id: '462',
        state_cd: '20'
      },
      {
        state_cd_full: 'MV-23',
        name: 'Haa Dhaalu',
        country_id: '462',
        state_cd: '23'
      },
      {
        state_cd_full: 'MV-25',
        name: 'Noonu',
        country_id: '462',
        state_cd: '25'
      },
      {
        state_cd_full: 'MV-26',
        name: 'Kaafu',
        country_id: '462',
        state_cd: '26'
      },
      {
        state_cd_full: 'MV-28',
        name: 'Gaafu Dhaalu',
        country_id: '462',
        state_cd: '28'
      },
      {
        state_cd_full: 'MV-MLE',
        name: 'Maale',
        country_id: '462',
        state_cd: 'MLE'
      }
    ]
  },
  {
    country_cd: 'ML',
    name: 'Mali',
    country_id: 466,
    states: [
      {
        state_cd_full: 'ML-1',
        name: 'Kayes',
        country_id: '466',
        state_cd: '1'
      },
      {
        state_cd_full: 'ML-2',
        name: 'Koulikoro',
        country_id: '466',
        state_cd: '2'
      },
      {
        state_cd_full: 'ML-3',
        name: 'Sikasso',
        country_id: '466',
        state_cd: '3'
      },
      {
        state_cd_full: 'ML-4',
        name: 'Segou',
        country_id: '466',
        state_cd: '4'
      },
      {
        state_cd_full: 'ML-5',
        name: 'Mopti',
        country_id: '466',
        state_cd: '5'
      },
      {
        state_cd_full: 'ML-6',
        name: 'Tombouctou',
        country_id: '466',
        state_cd: '6'
      },
      {
        state_cd_full: 'ML-7',
        name: 'Gao',
        country_id: '466',
        state_cd: '7'
      },
      {
        state_cd_full: 'ML-8',
        name: 'Kidal',
        country_id: '466',
        state_cd: '8'
      },
      {
        state_cd_full: 'ML-BKO',
        name: 'Bamako',
        country_id: '466',
        state_cd: 'BKO'
      }
    ]
  },
  {
    country_cd: 'MT',
    name: 'Malta',
    country_id: 470,
    states: [
      {
        state_cd_full: 'MT-01',
        name: 'Attard',
        country_id: '470',
        state_cd: '01'
      },
      {
        state_cd_full: 'MT-02',
        name: 'Balzan',
        country_id: '470',
        state_cd: '02'
      },
      {
        state_cd_full: 'MT-03',
        name: 'Birgu',
        country_id: '470',
        state_cd: '03'
      },
      {
        state_cd_full: 'MT-04',
        name: 'Birkirkara',
        country_id: '470',
        state_cd: '04'
      },
      {
        state_cd_full: 'MT-05',
        name: 'Birzebbuga',
        country_id: '470',
        state_cd: '05'
      },
      {
        state_cd_full: 'MT-06',
        name: 'Bormla',
        country_id: '470',
        state_cd: '06'
      },
      {
        state_cd_full: 'MT-07',
        name: 'Dingli',
        country_id: '470',
        state_cd: '07'
      },
      {
        state_cd_full: 'MT-08',
        name: 'Fgura',
        country_id: '470',
        state_cd: '08'
      },
      {
        state_cd_full: 'MT-09',
        name: 'Floriana',
        country_id: '470',
        state_cd: '09'
      },
      {
        state_cd_full: 'MT-10',
        name: 'Fontana',
        country_id: '470',
        state_cd: '10'
      },
      {
        state_cd_full: 'MT-11',
        name: 'Gudja',
        country_id: '470',
        state_cd: '11'
      },
      {
        state_cd_full: 'MT-12',
        name: 'Gzira',
        country_id: '470',
        state_cd: '12'
      },
      {
        state_cd_full: 'MT-13',
        name: 'Ghajnsielem',
        country_id: '470',
        state_cd: '13'
      },
      {
        state_cd_full: 'MT-14',
        name: 'Gharb',
        country_id: '470',
        state_cd: '14'
      },
      {
        state_cd_full: 'MT-15',
        name: 'Gharghur',
        country_id: '470',
        state_cd: '15'
      },
      {
        state_cd_full: 'MT-17',
        name: 'Ghaxaq',
        country_id: '470',
        state_cd: '17'
      },
      {
        state_cd_full: 'MT-18',
        name: 'Hamrun',
        country_id: '470',
        state_cd: '18'
      },
      {
        state_cd_full: 'MT-19',
        name: 'Iklin',
        country_id: '470',
        state_cd: '19'
      },
      {
        state_cd_full: 'MT-20',
        name: 'Isla',
        country_id: '470',
        state_cd: '20'
      },
      {
        state_cd_full: 'MT-21',
        name: 'Kalkara',
        country_id: '470',
        state_cd: '21'
      },
      {
        state_cd_full: 'MT-22',
        name: 'Kercem',
        country_id: '470',
        state_cd: '22'
      },
      {
        state_cd_full: 'MT-23',
        name: 'Kirkop',
        country_id: '470',
        state_cd: '23'
      },
      {
        state_cd_full: 'MT-24',
        name: 'Lija',
        country_id: '470',
        state_cd: '24'
      },
      {
        state_cd_full: 'MT-25',
        name: 'Luqa',
        country_id: '470',
        state_cd: '25'
      },
      {
        state_cd_full: 'MT-26',
        name: 'Marsa',
        country_id: '470',
        state_cd: '26'
      },
      {
        state_cd_full: 'MT-27',
        name: 'Marsaskala',
        country_id: '470',
        state_cd: '27'
      },
      {
        state_cd_full: 'MT-28',
        name: 'Marsaxlokk',
        country_id: '470',
        state_cd: '28'
      },
      {
        state_cd_full: 'MT-29',
        name: 'Mdina',
        country_id: '470',
        state_cd: '29'
      },
      {
        state_cd_full: 'MT-30',
        name: 'Mellieha',
        country_id: '470',
        state_cd: '30'
      },
      {
        state_cd_full: 'MT-31',
        name: 'Mgarr',
        country_id: '470',
        state_cd: '31'
      },
      {
        state_cd_full: 'MT-32',
        name: 'Mosta',
        country_id: '470',
        state_cd: '32'
      },
      {
        state_cd_full: 'MT-33',
        name: 'Mqabba',
        country_id: '470',
        state_cd: '33'
      },
      {
        state_cd_full: 'MT-34',
        name: 'Msida',
        country_id: '470',
        state_cd: '34'
      },
      {
        state_cd_full: 'MT-35',
        name: 'Mtarfa',
        country_id: '470',
        state_cd: '35'
      },
      {
        state_cd_full: 'MT-36',
        name: 'Munxar',
        country_id: '470',
        state_cd: '36'
      },
      {
        state_cd_full: 'MT-37',
        name: 'Nadur',
        country_id: '470',
        state_cd: '37'
      },
      {
        state_cd_full: 'MT-38',
        name: 'Naxxar',
        country_id: '470',
        state_cd: '38'
      },
      {
        state_cd_full: 'MT-39',
        name: 'Paola',
        country_id: '470',
        state_cd: '39'
      },
      {
        state_cd_full: 'MT-40',
        name: 'Pembroke',
        country_id: '470',
        state_cd: '40'
      },
      {
        state_cd_full: 'MT-41',
        name: 'Pieta',
        country_id: '470',
        state_cd: '41'
      },
      {
        state_cd_full: 'MT-42',
        name: 'Qala',
        country_id: '470',
        state_cd: '42'
      },
      {
        state_cd_full: 'MT-43',
        name: 'Qormi',
        country_id: '470',
        state_cd: '43'
      },
      {
        state_cd_full: 'MT-44',
        name: 'Qrendi',
        country_id: '470',
        state_cd: '44'
      },
      {
        state_cd_full: 'MT-45',
        name: 'Rabat Gozo',
        country_id: '470',
        state_cd: '45'
      },
      {
        state_cd_full: 'MT-46',
        name: 'Rabat Malta',
        country_id: '470',
        state_cd: '46'
      },
      {
        state_cd_full: 'MT-47',
        name: 'Safi',
        country_id: '470',
        state_cd: '47'
      },
      {
        state_cd_full: 'MT-48',
        name: "Saint Julian's",
        country_id: '470',
        state_cd: '48'
      },
      {
        state_cd_full: 'MT-49',
        name: 'Saint John',
        country_id: '470',
        state_cd: '49'
      },
      {
        state_cd_full: 'MT-51',
        name: "Saint Paul's Bay",
        country_id: '470',
        state_cd: '51'
      },
      {
        state_cd_full: 'MT-52',
        name: 'Sannat',
        country_id: '470',
        state_cd: '52'
      },
      {
        state_cd_full: 'MT-53',
        name: "Saint Lucia's",
        country_id: '470',
        state_cd: '53'
      },
      {
        state_cd_full: 'MT-54',
        name: 'Santa Venera',
        country_id: '470',
        state_cd: '54'
      },
      {
        state_cd_full: 'MT-55',
        name: 'Siggiewi',
        country_id: '470',
        state_cd: '55'
      },
      {
        state_cd_full: 'MT-56',
        name: 'Sliema',
        country_id: '470',
        state_cd: '56'
      },
      {
        state_cd_full: 'MT-57',
        name: 'Swieqi',
        country_id: '470',
        state_cd: '57'
      },
      {
        state_cd_full: 'MT-58',
        name: "Ta' Xbiex",
        country_id: '470',
        state_cd: '58'
      },
      {
        state_cd_full: 'MT-59',
        name: 'Tarxien',
        country_id: '470',
        state_cd: '59'
      },
      {
        state_cd_full: 'MT-60',
        name: 'Valletta',
        country_id: '470',
        state_cd: '60'
      },
      {
        state_cd_full: 'MT-61',
        name: 'Xaghra',
        country_id: '470',
        state_cd: '61'
      },
      {
        state_cd_full: 'MT-62',
        name: 'Xewkija',
        country_id: '470',
        state_cd: '62'
      },
      {
        state_cd_full: 'MT-63',
        name: 'Xghajra',
        country_id: '470',
        state_cd: '63'
      },
      {
        state_cd_full: 'MT-64',
        name: 'Zabbar',
        country_id: '470',
        state_cd: '64'
      },
      {
        state_cd_full: 'MT-65',
        name: 'Zebbug Gozo',
        country_id: '470',
        state_cd: '65'
      },
      {
        state_cd_full: 'MT-67',
        name: 'Zejtun',
        country_id: '470',
        state_cd: '67'
      },
      {
        state_cd_full: 'MT-68',
        name: 'Zurrieq',
        country_id: '470',
        state_cd: '68'
      }
    ]
  },
  {
    country_cd: 'MH',
    name: 'Marshall Islands',
    country_id: 584,
    states: [
      {
        state_cd_full: 'MH-KWA',
        name: 'Kwajalein',
        country_id: '584',
        state_cd: 'KWA'
      },
      {
        state_cd_full: 'MH-MAJ',
        name: 'Majuro',
        country_id: '584',
        state_cd: 'MAJ'
      }
    ]
  },
  {
    country_cd: 'MQ',
    name: 'Martinique',
    country_id: 474,
    states: [
      {
        state_cd_full: 'MQ-01',
        name: 'Martinique',
        country_id: '474',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'MR',
    name: 'Mauritania',
    country_id: 478,
    states: [
      {
        state_cd_full: 'MR-08',
        name: 'Dakhlet Nouadhibou',
        country_id: '478',
        state_cd: '08'
      },
      {
        state_cd_full: 'MR-10',
        name: 'Guidimaka',
        country_id: '478',
        state_cd: '10'
      },
      {
        state_cd_full: 'MR-11',
        name: 'Tiris Zemmour',
        country_id: '478',
        state_cd: '11'
      },
      {
        state_cd_full: 'MR-14',
        name: 'Nouakchott Nord',
        country_id: '478',
        state_cd: '14'
      }
    ]
  },
  {
    country_cd: 'MU',
    name: 'Mauritius',
    country_id: 480,
    states: [
      {
        state_cd_full: 'MU-BL',
        name: 'Black River',
        country_id: '480',
        state_cd: 'BL'
      },
      {
        state_cd_full: 'MU-FL',
        name: 'Flacq',
        country_id: '480',
        state_cd: 'FL'
      },
      {
        state_cd_full: 'MU-GP',
        name: 'Grand Port',
        country_id: '480',
        state_cd: 'GP'
      },
      {
        state_cd_full: 'MU-MO',
        name: 'Moka',
        country_id: '480',
        state_cd: 'MO'
      },
      {
        state_cd_full: 'MU-PA',
        name: 'Pamplemousses',
        country_id: '480',
        state_cd: 'PA'
      },
      {
        state_cd_full: 'MU-PL',
        name: 'Port Louis',
        country_id: '480',
        state_cd: 'PL'
      },
      {
        state_cd_full: 'MU-PW',
        name: 'Plaines Wilhems',
        country_id: '480',
        state_cd: 'PW'
      },
      {
        state_cd_full: 'MU-RO',
        name: 'Rodrigues Islands',
        country_id: '480',
        state_cd: 'RO'
      },
      {
        state_cd_full: 'MU-RR',
        name: 'Riviere du Rempart',
        country_id: '480',
        state_cd: 'RR'
      },
      {
        state_cd_full: 'MU-SA',
        name: 'Savanne',
        country_id: '480',
        state_cd: 'SA'
      }
    ]
  },
  {
    country_cd: 'YT',
    name: 'Mayotte',
    country_id: 175,
    states: [
      {
        state_cd_full: 'YT-01',
        name: 'Pamandzi',
        country_id: '175',
        state_cd: '01'
      },
      {
        state_cd_full: 'YT-11',
        name: 'Ouangani',
        country_id: '175',
        state_cd: '11'
      },
      {
        state_cd_full: 'YT-21',
        name: 'Mtsamboro',
        country_id: '175',
        state_cd: '21'
      },
      {
        state_cd_full: 'YT-31',
        name: 'Mamoudzou',
        country_id: '175',
        state_cd: '31'
      }
    ]
  },
  {
    country_cd: 'MX',
    name: 'Mexico',
    country_id: 484,
    states: [
      {
        state_cd_full: 'MX-AGU',
        name: 'Aguascalientes',
        country_id: '484',
        state_cd: 'AGU'
      },
      {
        state_cd_full: 'MX-BCN',
        name: 'Baja California',
        country_id: '484',
        state_cd: 'BCN'
      },
      {
        state_cd_full: 'MX-BCS',
        name: 'Baja California Sur',
        country_id: '484',
        state_cd: 'BCS'
      },
      {
        state_cd_full: 'MX-CAM',
        name: 'Campeche',
        country_id: '484',
        state_cd: 'CAM'
      },
      {
        state_cd_full: 'MX-CHH',
        name: 'Chihuahua',
        country_id: '484',
        state_cd: 'CHH'
      },
      {
        state_cd_full: 'MX-CHP',
        name: 'Chiapas',
        country_id: '484',
        state_cd: 'CHP'
      },
      {
        state_cd_full: 'MX-CMX',
        name: 'Ciudad de Mexico',
        country_id: '484',
        state_cd: 'CMX'
      },
      {
        state_cd_full: 'MX-COA',
        name: 'Coahuila de Zaragoza',
        country_id: '484',
        state_cd: 'COA'
      },
      {
        state_cd_full: 'MX-COL',
        name: 'Colima',
        country_id: '484',
        state_cd: 'COL'
      },
      {
        state_cd_full: 'MX-DUR',
        name: 'Durango',
        country_id: '484',
        state_cd: 'DUR'
      },
      {
        state_cd_full: 'MX-GRO',
        name: 'Guerrero',
        country_id: '484',
        state_cd: 'GRO'
      },
      {
        state_cd_full: 'MX-GUA',
        name: 'Guanajuato',
        country_id: '484',
        state_cd: 'GUA'
      },
      {
        state_cd_full: 'MX-HID',
        name: 'Hidalgo',
        country_id: '484',
        state_cd: 'HID'
      },
      {
        state_cd_full: 'MX-JAL',
        name: 'Jalisco',
        country_id: '484',
        state_cd: 'JAL'
      },
      {
        state_cd_full: 'MX-MEX',
        name: 'Mexico',
        country_id: '484',
        state_cd: 'MEX'
      },
      {
        state_cd_full: 'MX-MIC',
        name: 'Michoacan de Ocampo',
        country_id: '484',
        state_cd: 'MIC'
      },
      {
        state_cd_full: 'MX-MOR',
        name: 'Morelos',
        country_id: '484',
        state_cd: 'MOR'
      },
      {
        state_cd_full: 'MX-NAY',
        name: 'Nayarit',
        country_id: '484',
        state_cd: 'NAY'
      },
      {
        state_cd_full: 'MX-NLE',
        name: 'Nuevo Leon',
        country_id: '484',
        state_cd: 'NLE'
      },
      {
        state_cd_full: 'MX-OAX',
        name: 'Oaxaca',
        country_id: '484',
        state_cd: 'OAX'
      },
      {
        state_cd_full: 'MX-PUE',
        name: 'Puebla',
        country_id: '484',
        state_cd: 'PUE'
      },
      {
        state_cd_full: 'MX-QUE',
        name: 'Queretaro',
        country_id: '484',
        state_cd: 'QUE'
      },
      {
        state_cd_full: 'MX-ROO',
        name: 'Quintana Roo',
        country_id: '484',
        state_cd: 'ROO'
      },
      {
        state_cd_full: 'MX-SIN',
        name: 'Sinaloa',
        country_id: '484',
        state_cd: 'SIN'
      },
      {
        state_cd_full: 'MX-SLP',
        name: 'San Luis Potosi',
        country_id: '484',
        state_cd: 'SLP'
      },
      {
        state_cd_full: 'MX-SON',
        name: 'Sonora',
        country_id: '484',
        state_cd: 'SON'
      },
      {
        state_cd_full: 'MX-TAB',
        name: 'Tabasco',
        country_id: '484',
        state_cd: 'TAB'
      },
      {
        state_cd_full: 'MX-TAM',
        name: 'Tamaulipas',
        country_id: '484',
        state_cd: 'TAM'
      },
      {
        state_cd_full: 'MX-TLA',
        name: 'Tlaxcala',
        country_id: '484',
        state_cd: 'TLA'
      },
      {
        state_cd_full: 'MX-VER',
        name: 'Veracruz de Ignacio de la Llave',
        country_id: '484',
        state_cd: 'VER'
      },
      {
        state_cd_full: 'MX-YUC',
        name: 'Yucatan',
        country_id: '484',
        state_cd: 'YUC'
      },
      {
        state_cd_full: 'MX-ZAC',
        name: 'Zacatecas',
        country_id: '484',
        state_cd: 'ZAC'
      }
    ]
  },
  {
    country_cd: 'FM',
    name: 'Micronesia (Federated States of)',
    country_id: 583,
    states: [
      {
        state_cd_full: 'FM-KSA',
        name: 'Kosrae',
        country_id: '583',
        state_cd: 'KSA'
      },
      {
        state_cd_full: 'FM-PNI',
        name: 'Pohnpei',
        country_id: '583',
        state_cd: 'PNI'
      },
      {
        state_cd_full: 'FM-TRK',
        name: 'Chuuk',
        country_id: '583',
        state_cd: 'TRK'
      },
      {
        state_cd_full: 'FM-YAP',
        name: 'Yap',
        country_id: '583',
        state_cd: 'YAP'
      }
    ]
  },
  {
    country_cd: 'MD',
    name: 'Moldova, Republic of',
    country_id: 498,
    states: [
      {
        state_cd_full: 'MD-AN',
        name: 'Anenii Noi',
        country_id: '498',
        state_cd: 'AN'
      },
      {
        state_cd_full: 'MD-BA',
        name: 'Balti',
        country_id: '498',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'MD-BD',
        name: 'Bender',
        country_id: '498',
        state_cd: 'BD'
      },
      {
        state_cd_full: 'MD-BR',
        name: 'Briceni',
        country_id: '498',
        state_cd: 'BR'
      },
      {
        state_cd_full: 'MD-BS',
        name: 'Basarabeasca',
        country_id: '498',
        state_cd: 'BS'
      },
      {
        state_cd_full: 'MD-CA',
        name: 'Cahul',
        country_id: '498',
        state_cd: 'CA'
      },
      {
        state_cd_full: 'MD-CL',
        name: 'Calarasi',
        country_id: '498',
        state_cd: 'CL'
      },
      {
        state_cd_full: 'MD-CM',
        name: 'Cimislia',
        country_id: '498',
        state_cd: 'CM'
      },
      {
        state_cd_full: 'MD-CR',
        name: 'Criuleni',
        country_id: '498',
        state_cd: 'CR'
      },
      {
        state_cd_full: 'MD-CS',
        name: 'Causeni',
        country_id: '498',
        state_cd: 'CS'
      },
      {
        state_cd_full: 'MD-CT',
        name: 'Cantemir',
        country_id: '498',
        state_cd: 'CT'
      },
      {
        state_cd_full: 'MD-CU',
        name: 'Chisinau',
        country_id: '498',
        state_cd: 'CU'
      },
      {
        state_cd_full: 'MD-DO',
        name: 'Donduseni',
        country_id: '498',
        state_cd: 'DO'
      },
      {
        state_cd_full: 'MD-DR',
        name: 'Drochia',
        country_id: '498',
        state_cd: 'DR'
      },
      {
        state_cd_full: 'MD-DU',
        name: 'Dubasari',
        country_id: '498',
        state_cd: 'DU'
      },
      {
        state_cd_full: 'MD-ED',
        name: 'Edinet',
        country_id: '498',
        state_cd: 'ED'
      },
      {
        state_cd_full: 'MD-FA',
        name: 'Falesti',
        country_id: '498',
        state_cd: 'FA'
      },
      {
        state_cd_full: 'MD-FL',
        name: 'Floresti',
        country_id: '498',
        state_cd: 'FL'
      },
      {
        state_cd_full: 'MD-GA',
        name: 'Gagauzia, Unitatea teritoriala autonoma',
        country_id: '498',
        state_cd: 'GA'
      },
      {
        state_cd_full: 'MD-GL',
        name: 'Glodeni',
        country_id: '498',
        state_cd: 'GL'
      },
      {
        state_cd_full: 'MD-HI',
        name: 'Hincesti',
        country_id: '498',
        state_cd: 'HI'
      },
      {
        state_cd_full: 'MD-IA',
        name: 'Ialoveni',
        country_id: '498',
        state_cd: 'IA'
      },
      {
        state_cd_full: 'MD-LE',
        name: 'Leova',
        country_id: '498',
        state_cd: 'LE'
      },
      {
        state_cd_full: 'MD-NI',
        name: 'Nisporeni',
        country_id: '498',
        state_cd: 'NI'
      },
      {
        state_cd_full: 'MD-OC',
        name: 'Ocnita',
        country_id: '498',
        state_cd: 'OC'
      },
      {
        state_cd_full: 'MD-OR',
        name: 'Orhei',
        country_id: '498',
        state_cd: 'OR'
      },
      {
        state_cd_full: 'MD-RE',
        name: 'Rezina',
        country_id: '498',
        state_cd: 'RE'
      },
      {
        state_cd_full: 'MD-RI',
        name: 'Riscani',
        country_id: '498',
        state_cd: 'RI'
      },
      {
        state_cd_full: 'MD-SD',
        name: 'Soldanesti',
        country_id: '498',
        state_cd: 'SD'
      },
      {
        state_cd_full: 'MD-SI',
        name: 'Singerei',
        country_id: '498',
        state_cd: 'SI'
      },
      {
        state_cd_full: 'MD-SN',
        name: 'Stinga Nistrului, unitatea teritoriala din',
        country_id: '498',
        state_cd: 'SN'
      },
      {
        state_cd_full: 'MD-SO',
        name: 'Soroca',
        country_id: '498',
        state_cd: 'SO'
      },
      {
        state_cd_full: 'MD-ST',
        name: 'Straseni',
        country_id: '498',
        state_cd: 'ST'
      },
      {
        state_cd_full: 'MD-SV',
        name: 'Stefan Voda',
        country_id: '498',
        state_cd: 'SV'
      },
      {
        state_cd_full: 'MD-TA',
        name: 'Taraclia',
        country_id: '498',
        state_cd: 'TA'
      },
      {
        state_cd_full: 'MD-TE',
        name: 'Telenesti',
        country_id: '498',
        state_cd: 'TE'
      },
      {
        state_cd_full: 'MD-UN',
        name: 'Ungheni',
        country_id: '498',
        state_cd: 'UN'
      }
    ]
  },
  {
    country_cd: 'MC',
    name: 'Monaco',
    country_id: 492,
    states: [
      {
        state_cd_full: 'MC-CO',
        name: 'La Condamine',
        country_id: '492',
        state_cd: 'CO'
      },
      {
        state_cd_full: 'MC-MC',
        name: 'Monte-Carlo',
        country_id: '492',
        state_cd: 'MC'
      },
      {
        state_cd_full: 'MC-MO',
        name: 'Monaco-Ville',
        country_id: '492',
        state_cd: 'MO'
      },
      {
        state_cd_full: 'MC-SR',
        name: 'Saint-Roman',
        country_id: '492',
        state_cd: 'SR'
      }
    ]
  },
  {
    country_cd: 'MN',
    name: 'Mongolia',
    country_id: 496,
    states: [
      {
        state_cd_full: 'MN-035',
        name: 'Orhon',
        country_id: '496',
        state_cd: '035'
      },
      {
        state_cd_full: 'MN-037',
        name: 'Darhan uul',
        country_id: '496',
        state_cd: '037'
      },
      {
        state_cd_full: 'MN-043',
        name: 'Hovd',
        country_id: '496',
        state_cd: '043'
      },
      {
        state_cd_full: 'MN-047',
        name: 'Tov',
        country_id: '496',
        state_cd: '047'
      },
      {
        state_cd_full: 'MN-049',
        name: 'Selenge',
        country_id: '496',
        state_cd: '049'
      },
      {
        state_cd_full: 'MN-053',
        name: 'Omnogovi',
        country_id: '496',
        state_cd: '053'
      },
      {
        state_cd_full: 'MN-055',
        name: 'Ovorhangay',
        country_id: '496',
        state_cd: '055'
      },
      {
        state_cd_full: 'MN-063',
        name: 'Dornogovi',
        country_id: '496',
        state_cd: '063'
      },
      {
        state_cd_full: 'MN-065',
        name: 'Govi-Altay',
        country_id: '496',
        state_cd: '065'
      },
      {
        state_cd_full: 'MN-067',
        name: 'Bulgan',
        country_id: '496',
        state_cd: '067'
      },
      {
        state_cd_full: 'MN-071',
        name: 'Bayan-Olgiy',
        country_id: '496',
        state_cd: '071'
      },
      {
        state_cd_full: 'MN-1',
        name: 'Ulaanbaatar',
        country_id: '496',
        state_cd: '1'
      }
    ]
  },
  {
    country_cd: 'ME',
    name: 'Montenegro',
    country_id: 499,
    states: [
      {
        state_cd_full: 'ME-02',
        name: 'Bar',
        country_id: '499',
        state_cd: '02'
      },
      {
        state_cd_full: 'ME-03',
        name: 'Berane',
        country_id: '499',
        state_cd: '03'
      },
      {
        state_cd_full: 'ME-04',
        name: 'Bijelo Polje',
        country_id: '499',
        state_cd: '04'
      },
      {
        state_cd_full: 'ME-05',
        name: 'Budva',
        country_id: '499',
        state_cd: '05'
      },
      {
        state_cd_full: 'ME-06',
        name: 'Cetinje',
        country_id: '499',
        state_cd: '06'
      },
      {
        state_cd_full: 'ME-07',
        name: 'Danilovgrad',
        country_id: '499',
        state_cd: '07'
      },
      {
        state_cd_full: 'ME-08',
        name: 'Herceg-Novi',
        country_id: '499',
        state_cd: '08'
      },
      {
        state_cd_full: 'ME-09',
        name: 'Kolasin',
        country_id: '499',
        state_cd: '09'
      },
      {
        state_cd_full: 'ME-10',
        name: 'Kotor',
        country_id: '499',
        state_cd: '10'
      },
      {
        state_cd_full: 'ME-12',
        name: 'Niksic',
        country_id: '499',
        state_cd: '12'
      },
      {
        state_cd_full: 'ME-13',
        name: 'Plav',
        country_id: '499',
        state_cd: '13'
      },
      {
        state_cd_full: 'ME-14',
        name: 'Pljevlja',
        country_id: '499',
        state_cd: '14'
      },
      {
        state_cd_full: 'ME-15',
        name: 'Pluzine',
        country_id: '499',
        state_cd: '15'
      },
      {
        state_cd_full: 'ME-16',
        name: 'Podgorica',
        country_id: '499',
        state_cd: '16'
      },
      {
        state_cd_full: 'ME-17',
        name: 'Rozaje',
        country_id: '499',
        state_cd: '17'
      },
      {
        state_cd_full: 'ME-19',
        name: 'Tivat',
        country_id: '499',
        state_cd: '19'
      },
      {
        state_cd_full: 'ME-20',
        name: 'Ulcinj',
        country_id: '499',
        state_cd: '20'
      },
      {
        state_cd_full: 'ME-21',
        name: 'Zabljak',
        country_id: '499',
        state_cd: '21'
      }
    ]
  },
  {
    country_cd: 'MS',
    name: 'Montserrat',
    country_id: 500,
    states: [
      {
        state_cd_full: 'MS-01',
        name: 'Saint Peter',
        country_id: '500',
        state_cd: '01'
      },
      {
        state_cd_full: 'MS-11',
        name: 'Saint Anthony',
        country_id: '500',
        state_cd: '11'
      }
    ]
  },
  {
    country_cd: 'MA',
    name: 'Morocco',
    country_id: 504,
    states: [
      {
        state_cd_full: 'MA-01',
        name: 'Tanger-Tetouan-Al Hoceima',
        country_id: '504',
        state_cd: '01'
      },
      {
        state_cd_full: 'MA-02',
        name: "L'Oriental",
        country_id: '504',
        state_cd: '02'
      },
      {
        state_cd_full: 'MA-03',
        name: 'Fes- Meknes',
        country_id: '504',
        state_cd: '03'
      },
      {
        state_cd_full: 'MA-04',
        name: 'Rabat-Sale-Kenitra',
        country_id: '504',
        state_cd: '04'
      },
      {
        state_cd_full: 'MA-05',
        name: 'Beni-Mellal-Khenifra',
        country_id: '504',
        state_cd: '05'
      },
      {
        state_cd_full: 'MA-06',
        name: 'Casablanca-Settat',
        country_id: '504',
        state_cd: '06'
      },
      {
        state_cd_full: 'MA-07',
        name: 'Marrakech-Safi',
        country_id: '504',
        state_cd: '07'
      },
      {
        state_cd_full: 'MA-08',
        name: 'Draa-Tafilalet',
        country_id: '504',
        state_cd: '08'
      },
      {
        state_cd_full: 'MA-09',
        name: 'Souss-Massa',
        country_id: '504',
        state_cd: '09'
      },
      {
        state_cd_full: 'MA-10',
        name: 'Guelmim-Oued Noun (EH-partial)',
        country_id: '504',
        state_cd: '10'
      },
      {
        state_cd_full: 'MA-11',
        name: 'Laayoune-Sakia El Hamra (EH-partial)',
        country_id: '504',
        state_cd: '11'
      }
    ]
  },
  {
    country_cd: 'MZ',
    name: 'Mozambique',
    country_id: 508,
    states: [
      {
        state_cd_full: 'MZ-A',
        name: 'Niassa',
        country_id: '508',
        state_cd: 'A'
      },
      {
        state_cd_full: 'MZ-B',
        name: 'Manica',
        country_id: '508',
        state_cd: 'B'
      },
      {
        state_cd_full: 'MZ-G',
        name: 'Gaza',
        country_id: '508',
        state_cd: 'G'
      },
      {
        state_cd_full: 'MZ-I',
        name: 'Inhambane',
        country_id: '508',
        state_cd: 'I'
      },
      {
        state_cd_full: 'MZ-L',
        name: 'Maputo',
        country_id: '508',
        state_cd: 'L'
      },
      {
        state_cd_full: 'MZ-N',
        name: 'Nampula',
        country_id: '508',
        state_cd: 'N'
      },
      {
        state_cd_full: 'MZ-P',
        name: 'Cabo Delgado',
        country_id: '508',
        state_cd: 'P'
      },
      {
        state_cd_full: 'MZ-Q',
        name: 'Zambezia',
        country_id: '508',
        state_cd: 'Q'
      },
      {
        state_cd_full: 'MZ-S',
        name: 'Sofala',
        country_id: '508',
        state_cd: 'S'
      },
      {
        state_cd_full: 'MZ-T',
        name: 'Tete',
        country_id: '508',
        state_cd: 'T'
      }
    ]
  },
  {
    country_cd: 'MM',
    name: 'Myanmar',
    country_id: 104,
    states: [
      {
        state_cd_full: 'MM-01',
        name: 'Sagaing',
        country_id: '104',
        state_cd: '01'
      },
      {
        state_cd_full: 'MM-02',
        name: 'Bago',
        country_id: '104',
        state_cd: '02'
      },
      {
        state_cd_full: 'MM-03',
        name: 'Magway',
        country_id: '104',
        state_cd: '03'
      },
      {
        state_cd_full: 'MM-04',
        name: 'Mandalay',
        country_id: '104',
        state_cd: '04'
      },
      {
        state_cd_full: 'MM-05',
        name: 'Tanintharyi',
        country_id: '104',
        state_cd: '05'
      },
      {
        state_cd_full: 'MM-06',
        name: 'Yangon',
        country_id: '104',
        state_cd: '06'
      },
      {
        state_cd_full: 'MM-07',
        name: 'Ayeyarwady',
        country_id: '104',
        state_cd: '07'
      },
      {
        state_cd_full: 'MM-11',
        name: 'Kachin',
        country_id: '104',
        state_cd: '11'
      },
      {
        state_cd_full: 'MM-15',
        name: 'Mon',
        country_id: '104',
        state_cd: '15'
      },
      {
        state_cd_full: 'MM-17',
        name: 'Shan',
        country_id: '104',
        state_cd: '17'
      },
      {
        state_cd_full: 'MM-18',
        name: 'Nay Pyi Taw',
        country_id: '104',
        state_cd: '18'
      }
    ]
  },
  {
    country_cd: 'NA',
    name: 'Namibia',
    country_id: 516,
    states: [
      {
        state_cd_full: 'NA-CA',
        name: 'Zambezi',
        country_id: '516',
        state_cd: 'CA'
      },
      {
        state_cd_full: 'NA-ER',
        name: 'Erongo',
        country_id: '516',
        state_cd: 'ER'
      },
      {
        state_cd_full: 'NA-HA',
        name: 'Hardap',
        country_id: '516',
        state_cd: 'HA'
      },
      {
        state_cd_full: 'NA-KA',
        name: 'Karas',
        country_id: '516',
        state_cd: 'KA'
      },
      {
        state_cd_full: 'NA-KE',
        name: 'Kavango East',
        country_id: '516',
        state_cd: 'KE'
      },
      {
        state_cd_full: 'NA-KH',
        name: 'Khomas',
        country_id: '516',
        state_cd: 'KH'
      },
      {
        state_cd_full: 'NA-KU',
        name: 'Kunene',
        country_id: '516',
        state_cd: 'KU'
      },
      {
        state_cd_full: 'NA-OD',
        name: 'Otjozondjupa',
        country_id: '516',
        state_cd: 'OD'
      },
      {
        state_cd_full: 'NA-OH',
        name: 'Omaheke',
        country_id: '516',
        state_cd: 'OH'
      },
      {
        state_cd_full: 'NA-ON',
        name: 'Oshana',
        country_id: '516',
        state_cd: 'ON'
      },
      {
        state_cd_full: 'NA-OS',
        name: 'Omusati',
        country_id: '516',
        state_cd: 'OS'
      },
      {
        state_cd_full: 'NA-OT',
        name: 'Oshikoto',
        country_id: '516',
        state_cd: 'OT'
      },
      {
        state_cd_full: 'NA-OW',
        name: 'Ohangwena',
        country_id: '516',
        state_cd: 'OW'
      }
    ]
  },
  {
    country_cd: 'NR',
    name: 'Nauru',
    country_id: 520,
    states: [
      {
        state_cd_full: 'NR-14',
        name: 'Yaren',
        country_id: '520',
        state_cd: '14'
      }
    ]
  },
  {
    country_cd: 'NP',
    name: 'Nepal',
    country_id: 524,
    states: [
      {
        state_cd_full: 'NP-BA',
        name: 'Bagmati',
        country_id: '524',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'NP-BH',
        name: 'Bheri',
        country_id: '524',
        state_cd: 'BH'
      },
      {
        state_cd_full: 'NP-DH',
        name: 'Dhawalagiri',
        country_id: '524',
        state_cd: 'DH'
      },
      {
        state_cd_full: 'NP-GA',
        name: 'Gandaki',
        country_id: '524',
        state_cd: 'GA'
      },
      {
        state_cd_full: 'NP-JA',
        name: 'Janakpur',
        country_id: '524',
        state_cd: 'JA'
      },
      {
        state_cd_full: 'NP-KO',
        name: 'Kosi',
        country_id: '524',
        state_cd: 'KO'
      },
      {
        state_cd_full: 'NP-LU',
        name: 'Lumbini',
        country_id: '524',
        state_cd: 'LU'
      },
      {
        state_cd_full: 'NP-MA',
        name: 'Mahakali',
        country_id: '524',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'NP-ME',
        name: 'Mechi',
        country_id: '524',
        state_cd: 'ME'
      },
      {
        state_cd_full: 'NP-NA',
        name: 'Narayani',
        country_id: '524',
        state_cd: 'NA'
      },
      {
        state_cd_full: 'NP-RA',
        name: 'Rapti',
        country_id: '524',
        state_cd: 'RA'
      },
      {
        state_cd_full: 'NP-SA',
        name: 'Sagarmatha',
        country_id: '524',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'NP-SE',
        name: 'Seti',
        country_id: '524',
        state_cd: 'SE'
      }
    ]
  },
  {
    country_cd: 'NL',
    name: 'Netherlands',
    country_id: 528,
    states: [
      {
        state_cd_full: 'NL-DR',
        name: 'Drenthe',
        country_id: '528',
        state_cd: 'DR'
      },
      {
        state_cd_full: 'NL-FL',
        name: 'Flevoland',
        country_id: '528',
        state_cd: 'FL'
      },
      {
        state_cd_full: 'NL-FR',
        name: 'Fryslan',
        country_id: '528',
        state_cd: 'FR'
      },
      {
        state_cd_full: 'NL-GE',
        name: 'Gelderland',
        country_id: '528',
        state_cd: 'GE'
      },
      {
        state_cd_full: 'NL-GR',
        name: 'Groningen',
        country_id: '528',
        state_cd: 'GR'
      },
      {
        state_cd_full: 'NL-LI',
        name: 'Limburg',
        country_id: '528',
        state_cd: 'LI'
      },
      {
        state_cd_full: 'NL-NB',
        name: 'Noord-Brabant',
        country_id: '528',
        state_cd: 'NB'
      },
      {
        state_cd_full: 'NL-NH',
        name: 'Noord-Holland',
        country_id: '528',
        state_cd: 'NH'
      },
      {
        state_cd_full: 'NL-OV',
        name: 'Overijssel',
        country_id: '528',
        state_cd: 'OV'
      },
      {
        state_cd_full: 'NL-UT',
        name: 'Utrecht',
        country_id: '528',
        state_cd: 'UT'
      },
      {
        state_cd_full: 'NL-ZE',
        name: 'Zeeland',
        country_id: '528',
        state_cd: 'ZE'
      },
      {
        state_cd_full: 'NL-ZH',
        name: 'Zuid-Holland',
        country_id: '528',
        state_cd: 'ZH'
      }
    ]
  },
  {
    country_cd: 'NC',
    name: 'New Caledonia',
    country_id: 540,
    states: [
      {
        state_cd_full: 'NC-01',
        name: 'Province Sud',
        country_id: '540',
        state_cd: '01'
      },
      {
        state_cd_full: 'NC-11',
        name: 'Province Nord',
        country_id: '540',
        state_cd: '11'
      }
    ]
  },
  {
    country_cd: 'NZ',
    name: 'New Zealand',
    country_id: 554,
    states: [
      {
        state_cd_full: 'NZ-AUK',
        name: 'Auckland',
        country_id: '554',
        state_cd: 'AUK'
      },
      {
        state_cd_full: 'NZ-BOP',
        name: 'Bay of Plenty',
        country_id: '554',
        state_cd: 'BOP'
      },
      {
        state_cd_full: 'NZ-CAN',
        name: 'Canterbury',
        country_id: '554',
        state_cd: 'CAN'
      },
      {
        state_cd_full: 'NZ-CIT',
        name: 'Chatham Islands Territory',
        country_id: '554',
        state_cd: 'CIT'
      },
      {
        state_cd_full: 'NZ-GIS',
        name: 'Gisborne',
        country_id: '554',
        state_cd: 'GIS'
      },
      {
        state_cd_full: 'NZ-HKB',
        name: "Hawke's Bay",
        country_id: '554',
        state_cd: 'HKB'
      },
      {
        state_cd_full: 'NZ-MBH',
        name: 'Marlborough',
        country_id: '554',
        state_cd: 'MBH'
      },
      {
        state_cd_full: 'NZ-MWT',
        name: 'Manawatu-Wanganui',
        country_id: '554',
        state_cd: 'MWT'
      },
      {
        state_cd_full: 'NZ-NSN',
        name: 'Nelson',
        country_id: '554',
        state_cd: 'NSN'
      },
      {
        state_cd_full: 'NZ-NTL',
        name: 'Northland',
        country_id: '554',
        state_cd: 'NTL'
      },
      {
        state_cd_full: 'NZ-OTA',
        name: 'Otago',
        country_id: '554',
        state_cd: 'OTA'
      },
      {
        state_cd_full: 'NZ-STL',
        name: 'Southland',
        country_id: '554',
        state_cd: 'STL'
      },
      {
        state_cd_full: 'NZ-TAS',
        name: 'Tasman',
        country_id: '554',
        state_cd: 'TAS'
      },
      {
        state_cd_full: 'NZ-TKI',
        name: 'Taranaki',
        country_id: '554',
        state_cd: 'TKI'
      },
      {
        state_cd_full: 'NZ-WGN',
        name: 'Wellington',
        country_id: '554',
        state_cd: 'WGN'
      },
      {
        state_cd_full: 'NZ-WKO',
        name: 'Waikato',
        country_id: '554',
        state_cd: 'WKO'
      },
      {
        state_cd_full: 'NZ-WTC',
        name: 'West Coast',
        country_id: '554',
        state_cd: 'WTC'
      }
    ]
  },
  {
    country_cd: 'NI',
    name: 'Nicaragua',
    country_id: 558,
    states: [
      {
        state_cd_full: 'NI-AS',
        name: 'Costa Caribe Sur',
        country_id: '558',
        state_cd: 'AS'
      },
      {
        state_cd_full: 'NI-BO',
        name: 'Boaco',
        country_id: '558',
        state_cd: 'BO'
      },
      {
        state_cd_full: 'NI-CA',
        name: 'Carazo',
        country_id: '558',
        state_cd: 'CA'
      },
      {
        state_cd_full: 'NI-CI',
        name: 'Chinandega',
        country_id: '558',
        state_cd: 'CI'
      },
      {
        state_cd_full: 'NI-CO',
        name: 'Chontales',
        country_id: '558',
        state_cd: 'CO'
      },
      {
        state_cd_full: 'NI-ES',
        name: 'Esteli',
        country_id: '558',
        state_cd: 'ES'
      },
      {
        state_cd_full: 'NI-GR',
        name: 'Granada',
        country_id: '558',
        state_cd: 'GR'
      },
      {
        state_cd_full: 'NI-JI',
        name: 'Jinotega',
        country_id: '558',
        state_cd: 'JI'
      },
      {
        state_cd_full: 'NI-LE',
        name: 'Leon',
        country_id: '558',
        state_cd: 'LE'
      },
      {
        state_cd_full: 'NI-MD',
        name: 'Madriz',
        country_id: '558',
        state_cd: 'MD'
      },
      {
        state_cd_full: 'NI-MN',
        name: 'Managua',
        country_id: '558',
        state_cd: 'MN'
      },
      {
        state_cd_full: 'NI-MS',
        name: 'Masaya',
        country_id: '558',
        state_cd: 'MS'
      },
      {
        state_cd_full: 'NI-MT',
        name: 'Matagalpa',
        country_id: '558',
        state_cd: 'MT'
      },
      {
        state_cd_full: 'NI-NS',
        name: 'Nueva Segovia',
        country_id: '558',
        state_cd: 'NS'
      },
      {
        state_cd_full: 'NI-RI',
        name: 'Rivas',
        country_id: '558',
        state_cd: 'RI'
      },
      {
        state_cd_full: 'NI-SJ',
        name: 'Rio San Juan',
        country_id: '558',
        state_cd: 'SJ'
      }
    ]
  },
  {
    country_cd: 'NE',
    name: 'Niger',
    country_id: 562,
    states: [
      {
        state_cd_full: 'NE-1',
        name: 'Agadez',
        country_id: '562',
        state_cd: '1'
      },
      {
        state_cd_full: 'NE-2',
        name: 'Diffa',
        country_id: '562',
        state_cd: '2'
      },
      {
        state_cd_full: 'NE-3',
        name: 'Dosso',
        country_id: '562',
        state_cd: '3'
      },
      {
        state_cd_full: 'NE-4',
        name: 'Maradi',
        country_id: '562',
        state_cd: '4'
      },
      {
        state_cd_full: 'NE-5',
        name: 'Tahoua',
        country_id: '562',
        state_cd: '5'
      },
      {
        state_cd_full: 'NE-7',
        name: 'Zinder',
        country_id: '562',
        state_cd: '7'
      },
      {
        state_cd_full: 'NE-8',
        name: 'Niamey',
        country_id: '562',
        state_cd: '8'
      }
    ]
  },
  {
    country_cd: 'NG',
    name: 'Nigeria',
    country_id: 566,
    states: [
      {
        state_cd_full: 'NG-AB',
        name: 'Abia',
        country_id: '566',
        state_cd: 'AB'
      },
      {
        state_cd_full: 'NG-AD',
        name: 'Adamawa',
        country_id: '566',
        state_cd: 'AD'
      },
      {
        state_cd_full: 'NG-AK',
        name: 'Akwa Ibom',
        country_id: '566',
        state_cd: 'AK'
      },
      {
        state_cd_full: 'NG-AN',
        name: 'Anambra',
        country_id: '566',
        state_cd: 'AN'
      },
      {
        state_cd_full: 'NG-BA',
        name: 'Bauchi',
        country_id: '566',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'NG-BE',
        name: 'Benue',
        country_id: '566',
        state_cd: 'BE'
      },
      {
        state_cd_full: 'NG-BO',
        name: 'Borno',
        country_id: '566',
        state_cd: 'BO'
      },
      {
        state_cd_full: 'NG-BY',
        name: 'Bayelsa',
        country_id: '566',
        state_cd: 'BY'
      },
      {
        state_cd_full: 'NG-CR',
        name: 'Cross River',
        country_id: '566',
        state_cd: 'CR'
      },
      {
        state_cd_full: 'NG-DE',
        name: 'Delta',
        country_id: '566',
        state_cd: 'DE'
      },
      {
        state_cd_full: 'NG-EB',
        name: 'Ebonyi',
        country_id: '566',
        state_cd: 'EB'
      },
      {
        state_cd_full: 'NG-ED',
        name: 'Edo',
        country_id: '566',
        state_cd: 'ED'
      },
      {
        state_cd_full: 'NG-EK',
        name: 'Ekiti',
        country_id: '566',
        state_cd: 'EK'
      },
      {
        state_cd_full: 'NG-EN',
        name: 'Enugu',
        country_id: '566',
        state_cd: 'EN'
      },
      {
        state_cd_full: 'NG-FC',
        name: 'Abuja Federal Capital Territory',
        country_id: '566',
        state_cd: 'FC'
      },
      {
        state_cd_full: 'NG-GO',
        name: 'Gombe',
        country_id: '566',
        state_cd: 'GO'
      },
      {
        state_cd_full: 'NG-IM',
        name: 'Imo',
        country_id: '566',
        state_cd: 'IM'
      },
      {
        state_cd_full: 'NG-JI',
        name: 'Jigawa',
        country_id: '566',
        state_cd: 'JI'
      },
      {
        state_cd_full: 'NG-KD',
        name: 'Kaduna',
        country_id: '566',
        state_cd: 'KD'
      },
      {
        state_cd_full: 'NG-KE',
        name: 'Kebbi',
        country_id: '566',
        state_cd: 'KE'
      },
      {
        state_cd_full: 'NG-KN',
        name: 'Kano',
        country_id: '566',
        state_cd: 'KN'
      },
      {
        state_cd_full: 'NG-KO',
        name: 'Kogi',
        country_id: '566',
        state_cd: 'KO'
      },
      {
        state_cd_full: 'NG-KT',
        name: 'Katsina',
        country_id: '566',
        state_cd: 'KT'
      },
      {
        state_cd_full: 'NG-KW',
        name: 'Kwara',
        country_id: '566',
        state_cd: 'KW'
      },
      {
        state_cd_full: 'NG-LA',
        name: 'Lagos',
        country_id: '566',
        state_cd: 'LA'
      },
      {
        state_cd_full: 'NG-NA',
        name: 'Nasarawa',
        country_id: '566',
        state_cd: 'NA'
      },
      {
        state_cd_full: 'NG-NI',
        name: 'Niger',
        country_id: '566',
        state_cd: 'NI'
      },
      {
        state_cd_full: 'NG-OG',
        name: 'Ogun',
        country_id: '566',
        state_cd: 'OG'
      },
      {
        state_cd_full: 'NG-ON',
        name: 'Ondo',
        country_id: '566',
        state_cd: 'ON'
      },
      {
        state_cd_full: 'NG-OS',
        name: 'Osun',
        country_id: '566',
        state_cd: 'OS'
      },
      {
        state_cd_full: 'NG-OY',
        name: 'Oyo',
        country_id: '566',
        state_cd: 'OY'
      },
      {
        state_cd_full: 'NG-PL',
        name: 'Plateau',
        country_id: '566',
        state_cd: 'PL'
      },
      {
        state_cd_full: 'NG-RI',
        name: 'Rivers',
        country_id: '566',
        state_cd: 'RI'
      },
      {
        state_cd_full: 'NG-SO',
        name: 'Sokoto',
        country_id: '566',
        state_cd: 'SO'
      },
      {
        state_cd_full: 'NG-TA',
        name: 'Taraba',
        country_id: '566',
        state_cd: 'TA'
      },
      {
        state_cd_full: 'NG-YO',
        name: 'Yobe',
        country_id: '566',
        state_cd: 'YO'
      },
      {
        state_cd_full: 'NG-ZA',
        name: 'Zamfara',
        country_id: '566',
        state_cd: 'ZA'
      }
    ]
  },
  {
    country_cd: 'NU',
    name: 'Niue',
    country_id: 570,
    states: [
      {
        state_cd_full: 'NU-01',
        name: 'Niue',
        country_id: '570',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'NF',
    name: 'Norfolk Island',
    country_id: 574,
    states: [
      {
        state_cd_full: 'NF-01',
        name: 'Norfolk Island',
        country_id: '574',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'MK',
    name: 'North Macedonia',
    country_id: 807,
    states: [
      {
        state_cd_full: 'MK-03',
        name: 'Berovo',
        country_id: '807',
        state_cd: '03'
      },
      {
        state_cd_full: 'MK-04',
        name: 'Bitola',
        country_id: '807',
        state_cd: '04'
      },
      {
        state_cd_full: 'MK-05',
        name: 'Bogdanci',
        country_id: '807',
        state_cd: '05'
      },
      {
        state_cd_full: 'MK-06',
        name: 'Bogovinje',
        country_id: '807',
        state_cd: '06'
      },
      {
        state_cd_full: 'MK-07',
        name: 'Bosilovo',
        country_id: '807',
        state_cd: '07'
      },
      {
        state_cd_full: 'MK-08',
        name: 'Brvenica',
        country_id: '807',
        state_cd: '08'
      },
      {
        state_cd_full: 'MK-10',
        name: 'Valandovo',
        country_id: '807',
        state_cd: '10'
      },
      {
        state_cd_full: 'MK-12',
        name: 'Vevcani',
        country_id: '807',
        state_cd: '12'
      },
      {
        state_cd_full: 'MK-13',
        name: 'Veles',
        country_id: '807',
        state_cd: '13'
      },
      {
        state_cd_full: 'MK-14',
        name: 'Vinica',
        country_id: '807',
        state_cd: '14'
      },
      {
        state_cd_full: 'MK-16',
        name: 'Vrapciste',
        country_id: '807',
        state_cd: '16'
      },
      {
        state_cd_full: 'MK-18',
        name: 'Gevgelija',
        country_id: '807',
        state_cd: '18'
      },
      {
        state_cd_full: 'MK-19',
        name: 'Gostivar',
        country_id: '807',
        state_cd: '19'
      },
      {
        state_cd_full: 'MK-21',
        name: 'Debar',
        country_id: '807',
        state_cd: '21'
      },
      {
        state_cd_full: 'MK-23',
        name: 'Delcevo',
        country_id: '807',
        state_cd: '23'
      },
      {
        state_cd_full: 'MK-24',
        name: 'Demir Kapija',
        country_id: '807',
        state_cd: '24'
      },
      {
        state_cd_full: 'MK-25',
        name: 'Demir Hisar',
        country_id: '807',
        state_cd: '25'
      },
      {
        state_cd_full: 'MK-26',
        name: 'Dojran',
        country_id: '807',
        state_cd: '26'
      },
      {
        state_cd_full: 'MK-27',
        name: 'Dolneni',
        country_id: '807',
        state_cd: '27'
      },
      {
        state_cd_full: 'MK-30',
        name: 'Zelino',
        country_id: '807',
        state_cd: '30'
      },
      {
        state_cd_full: 'MK-32',
        name: 'Zelenikovo',
        country_id: '807',
        state_cd: '32'
      },
      {
        state_cd_full: 'MK-34',
        name: 'Ilinden',
        country_id: '807',
        state_cd: '34'
      },
      {
        state_cd_full: 'MK-35',
        name: 'Jegunovce',
        country_id: '807',
        state_cd: '35'
      },
      {
        state_cd_full: 'MK-36',
        name: 'Kavadarci',
        country_id: '807',
        state_cd: '36'
      },
      {
        state_cd_full: 'MK-37',
        name: 'Karbinci',
        country_id: '807',
        state_cd: '37'
      },
      {
        state_cd_full: 'MK-40',
        name: 'Kicevo',
        country_id: '807',
        state_cd: '40'
      },
      {
        state_cd_full: 'MK-42',
        name: 'Kocani',
        country_id: '807',
        state_cd: '42'
      },
      {
        state_cd_full: 'MK-43',
        name: 'Kratovo',
        country_id: '807',
        state_cd: '43'
      },
      {
        state_cd_full: 'MK-44',
        name: 'Kriva Palanka',
        country_id: '807',
        state_cd: '44'
      },
      {
        state_cd_full: 'MK-45',
        name: 'Krivogastani',
        country_id: '807',
        state_cd: '45'
      },
      {
        state_cd_full: 'MK-46',
        name: 'Krusevo',
        country_id: '807',
        state_cd: '46'
      },
      {
        state_cd_full: 'MK-47',
        name: 'Kumanovo',
        country_id: '807',
        state_cd: '47'
      },
      {
        state_cd_full: 'MK-48',
        name: 'Lipkovo',
        country_id: '807',
        state_cd: '48'
      },
      {
        state_cd_full: 'MK-49',
        name: 'Lozovo',
        country_id: '807',
        state_cd: '49'
      },
      {
        state_cd_full: 'MK-51',
        name: 'Makedonska Kamenica',
        country_id: '807',
        state_cd: '51'
      },
      {
        state_cd_full: 'MK-52',
        name: 'Makedonski Brod',
        country_id: '807',
        state_cd: '52'
      },
      {
        state_cd_full: 'MK-54',
        name: 'Negotino',
        country_id: '807',
        state_cd: '54'
      },
      {
        state_cd_full: 'MK-56',
        name: 'Novo Selo',
        country_id: '807',
        state_cd: '56'
      },
      {
        state_cd_full: 'MK-58',
        name: 'Ohrid',
        country_id: '807',
        state_cd: '58'
      },
      {
        state_cd_full: 'MK-59',
        name: 'Petrovec',
        country_id: '807',
        state_cd: '59'
      },
      {
        state_cd_full: 'MK-60',
        name: 'Pehcevo',
        country_id: '807',
        state_cd: '60'
      },
      {
        state_cd_full: 'MK-61',
        name: 'Plasnica',
        country_id: '807',
        state_cd: '61'
      },
      {
        state_cd_full: 'MK-62',
        name: 'Prilep',
        country_id: '807',
        state_cd: '62'
      },
      {
        state_cd_full: 'MK-63',
        name: 'Probistip',
        country_id: '807',
        state_cd: '63'
      },
      {
        state_cd_full: 'MK-64',
        name: 'Radovis',
        country_id: '807',
        state_cd: '64'
      },
      {
        state_cd_full: 'MK-65',
        name: 'Rankovce',
        country_id: '807',
        state_cd: '65'
      },
      {
        state_cd_full: 'MK-66',
        name: 'Resen',
        country_id: '807',
        state_cd: '66'
      },
      {
        state_cd_full: 'MK-67',
        name: 'Rosoman',
        country_id: '807',
        state_cd: '67'
      },
      {
        state_cd_full: 'MK-69',
        name: 'Sveti Nikole',
        country_id: '807',
        state_cd: '69'
      },
      {
        state_cd_full: 'MK-70',
        name: 'Sopiste',
        country_id: '807',
        state_cd: '70'
      },
      {
        state_cd_full: 'MK-71',
        name: 'Staro Nagoricane',
        country_id: '807',
        state_cd: '71'
      },
      {
        state_cd_full: 'MK-72',
        name: 'Struga',
        country_id: '807',
        state_cd: '72'
      },
      {
        state_cd_full: 'MK-73',
        name: 'Strumica',
        country_id: '807',
        state_cd: '73'
      },
      {
        state_cd_full: 'MK-74',
        name: 'Studenicani',
        country_id: '807',
        state_cd: '74'
      },
      {
        state_cd_full: 'MK-75',
        name: 'Tearce',
        country_id: '807',
        state_cd: '75'
      },
      {
        state_cd_full: 'MK-76',
        name: 'Tetovo',
        country_id: '807',
        state_cd: '76'
      },
      {
        state_cd_full: 'MK-78',
        name: 'Centar Zupa',
        country_id: '807',
        state_cd: '78'
      },
      {
        state_cd_full: 'MK-81',
        name: 'Cesinovo-Oblesevo',
        country_id: '807',
        state_cd: '81'
      },
      {
        state_cd_full: 'MK-82',
        name: 'Cucer Sandevo',
        country_id: '807',
        state_cd: '82'
      },
      {
        state_cd_full: 'MK-83',
        name: 'Stip',
        country_id: '807',
        state_cd: '83'
      },
      {
        state_cd_full: 'MK-85',
        name: 'Skopje',
        country_id: '807',
        state_cd: '85'
      }
    ]
  },
  {
    country_cd: 'MP',
    name: 'Northern Mariana Islands',
    country_id: 580,
    states: [
      {
        state_cd_full: 'MP-01',
        name: 'Northern Mariana Islands',
        country_id: '580',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'NO',
    name: 'Norway',
    country_id: 578,
    states: [
      {
        state_cd_full: 'NO-01',
        name: 'Ostfold',
        country_id: '578',
        state_cd: '01'
      },
      {
        state_cd_full: 'NO-02',
        name: 'Akershus',
        country_id: '578',
        state_cd: '02'
      },
      {
        state_cd_full: 'NO-03',
        name: 'Oslo',
        country_id: '578',
        state_cd: '03'
      },
      {
        state_cd_full: 'NO-04',
        name: 'Hedmark',
        country_id: '578',
        state_cd: '04'
      },
      {
        state_cd_full: 'NO-05',
        name: 'Oppland',
        country_id: '578',
        state_cd: '05'
      },
      {
        state_cd_full: 'NO-06',
        name: 'Buskerud',
        country_id: '578',
        state_cd: '06'
      },
      {
        state_cd_full: 'NO-07',
        name: 'Vestfold',
        country_id: '578',
        state_cd: '07'
      },
      {
        state_cd_full: 'NO-08',
        name: 'Telemark',
        country_id: '578',
        state_cd: '08'
      },
      {
        state_cd_full: 'NO-09',
        name: 'Aust-Agder',
        country_id: '578',
        state_cd: '09'
      },
      {
        state_cd_full: 'NO-10',
        name: 'Vest-Agder',
        country_id: '578',
        state_cd: '10'
      },
      {
        state_cd_full: 'NO-11',
        name: 'Rogaland',
        country_id: '578',
        state_cd: '11'
      },
      {
        state_cd_full: 'NO-12',
        name: 'Hordaland',
        country_id: '578',
        state_cd: '12'
      },
      {
        state_cd_full: 'NO-14',
        name: 'Sogn og Fjordane',
        country_id: '578',
        state_cd: '14'
      },
      {
        state_cd_full: 'NO-15',
        name: 'More og Romsdal',
        country_id: '578',
        state_cd: '15'
      },
      {
        state_cd_full: 'NO-16',
        name: 'Sor-Trondelag',
        country_id: '578',
        state_cd: '16'
      },
      {
        state_cd_full: 'NO-17',
        name: 'Nord-Trondelag',
        country_id: '578',
        state_cd: '17'
      },
      {
        state_cd_full: 'NO-18',
        name: 'Nordland',
        country_id: '578',
        state_cd: '18'
      },
      {
        state_cd_full: 'NO-19',
        name: 'Troms',
        country_id: '578',
        state_cd: '19'
      },
      {
        state_cd_full: 'NO-20',
        name: 'Finnmark',
        country_id: '578',
        state_cd: '20'
      }
    ]
  },
  {
    country_cd: 'OM',
    name: 'Oman',
    country_id: 512,
    states: [
      {
        state_cd_full: 'OM-BJ',
        name: 'Janub al Batinah',
        country_id: '512',
        state_cd: 'BJ'
      },
      {
        state_cd_full: 'OM-BS',
        name: 'Shamal al Batinah',
        country_id: '512',
        state_cd: 'BS'
      },
      {
        state_cd_full: 'OM-BU',
        name: 'Al Buraymi',
        country_id: '512',
        state_cd: 'BU'
      },
      {
        state_cd_full: 'OM-DA',
        name: 'Ad Dakhiliyah',
        country_id: '512',
        state_cd: 'DA'
      },
      {
        state_cd_full: 'OM-MA',
        name: 'Masqat',
        country_id: '512',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'OM-MU',
        name: 'Musandam',
        country_id: '512',
        state_cd: 'MU'
      },
      {
        state_cd_full: 'OM-SJ',
        name: 'Janub ash Sharqiyah',
        country_id: '512',
        state_cd: 'SJ'
      },
      {
        state_cd_full: 'OM-SS',
        name: 'Shamal ash Sharqiyah',
        country_id: '512',
        state_cd: 'SS'
      },
      {
        state_cd_full: 'OM-WU',
        name: 'Al Wusta',
        country_id: '512',
        state_cd: 'WU'
      },
      {
        state_cd_full: 'OM-ZA',
        name: 'Az Zahirah',
        country_id: '512',
        state_cd: 'ZA'
      },
      {
        state_cd_full: 'OM-ZU',
        name: 'Zufar',
        country_id: '512',
        state_cd: 'ZU'
      }
    ]
  },
  {
    country_cd: 'PK',
    name: 'Pakistan',
    country_id: 586,
    states: [
      {
        state_cd_full: 'PK-BA',
        name: 'Balochistan',
        country_id: '586',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'PK-GB',
        name: 'Gilgit-Baltistan',
        country_id: '586',
        state_cd: 'GB'
      },
      {
        state_cd_full: 'PK-IS',
        name: 'Islamabad',
        country_id: '586',
        state_cd: 'IS'
      },
      {
        state_cd_full: 'PK-JK',
        name: 'Azad Jammu and Kashmir',
        country_id: '586',
        state_cd: 'JK'
      },
      {
        state_cd_full: 'PK-KP',
        name: 'Khyber Pakhtunkhwa',
        country_id: '586',
        state_cd: 'KP'
      },
      {
        state_cd_full: 'PK-PB',
        name: 'Punjab',
        country_id: '586',
        state_cd: 'PB'
      },
      {
        state_cd_full: 'PK-SD',
        name: 'Sindh',
        country_id: '586',
        state_cd: 'SD'
      },
      {
        state_cd_full: 'PK-TA',
        name: 'Federally Administered Tribal Areas',
        country_id: '586',
        state_cd: 'TA'
      }
    ]
  },
  {
    country_cd: 'PW',
    name: 'Palau',
    country_id: 585,
    states: [
      {
        state_cd_full: 'PW-004',
        name: 'Airai',
        country_id: '585',
        state_cd: '004'
      },
      {
        state_cd_full: 'PW-100',
        name: 'Kayangel',
        country_id: '585',
        state_cd: '100'
      },
      {
        state_cd_full: 'PW-150',
        name: 'Koror',
        country_id: '585',
        state_cd: '150'
      },
      {
        state_cd_full: 'PW-212',
        name: 'Melekeok',
        country_id: '585',
        state_cd: '212'
      },
      {
        state_cd_full: 'PW-222',
        name: 'Ngardmau',
        country_id: '585',
        state_cd: '222'
      }
    ]
  },
  {
    country_cd: 'PS',
    name: 'Palestine, State of',
    country_id: 275,
    states: [
      {
        state_cd_full: 'PS-BTH',
        name: 'Bethlehem',
        country_id: '275',
        state_cd: 'BTH'
      },
      {
        state_cd_full: 'PS-GZA',
        name: 'Gaza',
        country_id: '275',
        state_cd: 'GZA'
      },
      {
        state_cd_full: 'PS-HBN',
        name: 'Hebron',
        country_id: '275',
        state_cd: 'HBN'
      },
      {
        state_cd_full: 'PS-JEM',
        name: 'Jerusalem',
        country_id: '275',
        state_cd: 'JEM'
      },
      {
        state_cd_full: 'PS-JEN',
        name: 'Jenin',
        country_id: '275',
        state_cd: 'JEN'
      },
      {
        state_cd_full: 'PS-JRH',
        name: 'Jericho and Al Aghwar',
        country_id: '275',
        state_cd: 'JRH'
      },
      {
        state_cd_full: 'PS-NBS',
        name: 'Nablus',
        country_id: '275',
        state_cd: 'NBS'
      },
      {
        state_cd_full: 'PS-QQA',
        name: 'Qalqilya',
        country_id: '275',
        state_cd: 'QQA'
      },
      {
        state_cd_full: 'PS-RBH',
        name: 'Ramallah',
        country_id: '275',
        state_cd: 'RBH'
      },
      {
        state_cd_full: 'PS-SLT',
        name: 'Salfit',
        country_id: '275',
        state_cd: 'SLT'
      },
      {
        state_cd_full: 'PS-TBS',
        name: 'Tubas',
        country_id: '275',
        state_cd: 'TBS'
      },
      {
        state_cd_full: 'PS-TKM',
        name: 'Tulkarm',
        country_id: '275',
        state_cd: 'TKM'
      }
    ]
  },
  {
    country_cd: 'PA',
    name: 'Panama',
    country_id: 591,
    states: [
      {
        state_cd_full: 'PA-1',
        name: 'Bocas del Toro',
        country_id: '591',
        state_cd: '1'
      },
      {
        state_cd_full: 'PA-2',
        name: 'Cocle',
        country_id: '591',
        state_cd: '2'
      },
      {
        state_cd_full: 'PA-3',
        name: 'Colon',
        country_id: '591',
        state_cd: '3'
      },
      {
        state_cd_full: 'PA-4',
        name: 'Chiriqui',
        country_id: '591',
        state_cd: '4'
      },
      {
        state_cd_full: 'PA-5',
        name: 'Darien',
        country_id: '591',
        state_cd: '5'
      },
      {
        state_cd_full: 'PA-6',
        name: 'Herrera',
        country_id: '591',
        state_cd: '6'
      },
      {
        state_cd_full: 'PA-7',
        name: 'Los Santos',
        country_id: '591',
        state_cd: '7'
      },
      {
        state_cd_full: 'PA-8',
        name: 'Panama',
        country_id: '591',
        state_cd: '8'
      },
      {
        state_cd_full: 'PA-9',
        name: 'Veraguas',
        country_id: '591',
        state_cd: '9'
      },
      {
        state_cd_full: 'PA-NB',
        name: 'Ngobe-Bugle',
        country_id: '591',
        state_cd: 'NB'
      }
    ]
  },
  {
    country_cd: 'PG',
    name: 'Papua New Guinea',
    country_id: 598,
    states: [
      {
        state_cd_full: 'PG-CPM',
        name: 'Central',
        country_id: '598',
        state_cd: 'CPM'
      },
      {
        state_cd_full: 'PG-EBR',
        name: 'East New Britain',
        country_id: '598',
        state_cd: 'EBR'
      },
      {
        state_cd_full: 'PG-EHG',
        name: 'Eastern Highlands',
        country_id: '598',
        state_cd: 'EHG'
      },
      {
        state_cd_full: 'PG-MPL',
        name: 'Morobe',
        country_id: '598',
        state_cd: 'MPL'
      },
      {
        state_cd_full: 'PG-MPM',
        name: 'Madang',
        country_id: '598',
        state_cd: 'MPM'
      },
      {
        state_cd_full: 'PG-MRL',
        name: 'Manus',
        country_id: '598',
        state_cd: 'MRL'
      },
      {
        state_cd_full: 'PG-NCD',
        name: 'National Capital District (Port Moresby)',
        country_id: '598',
        state_cd: 'NCD'
      },
      {
        state_cd_full: 'PG-NIK',
        name: 'New Ireland',
        country_id: '598',
        state_cd: 'NIK'
      },
      {
        state_cd_full: 'PG-SHM',
        name: 'Southern Highlands',
        country_id: '598',
        state_cd: 'SHM'
      },
      {
        state_cd_full: 'PG-WBK',
        name: 'West New Britain',
        country_id: '598',
        state_cd: 'WBK'
      },
      {
        state_cd_full: 'PG-WHM',
        name: 'Western Highlands',
        country_id: '598',
        state_cd: 'WHM'
      }
    ]
  },
  {
    country_cd: 'PY',
    name: 'Paraguay',
    country_id: 600,
    states: [
      {
        state_cd_full: 'PY-1',
        name: 'Concepcion',
        country_id: '600',
        state_cd: '1'
      },
      {
        state_cd_full: 'PY-10',
        name: 'Alto Parana',
        country_id: '600',
        state_cd: '10'
      },
      {
        state_cd_full: 'PY-11',
        name: 'Central',
        country_id: '600',
        state_cd: '11'
      },
      {
        state_cd_full: 'PY-12',
        name: 'Neembucu',
        country_id: '600',
        state_cd: '12'
      },
      {
        state_cd_full: 'PY-13',
        name: 'Amambay',
        country_id: '600',
        state_cd: '13'
      },
      {
        state_cd_full: 'PY-14',
        name: 'Canindeyu',
        country_id: '600',
        state_cd: '14'
      },
      {
        state_cd_full: 'PY-15',
        name: 'Presidente Hayes',
        country_id: '600',
        state_cd: '15'
      },
      {
        state_cd_full: 'PY-16',
        name: 'Alto Paraguay',
        country_id: '600',
        state_cd: '16'
      },
      {
        state_cd_full: 'PY-19',
        name: 'Boqueron',
        country_id: '600',
        state_cd: '19'
      },
      {
        state_cd_full: 'PY-2',
        name: 'San Pedro',
        country_id: '600',
        state_cd: '2'
      },
      {
        state_cd_full: 'PY-3',
        name: 'Cordillera',
        country_id: '600',
        state_cd: '3'
      },
      {
        state_cd_full: 'PY-4',
        name: 'Guaira',
        country_id: '600',
        state_cd: '4'
      },
      {
        state_cd_full: 'PY-5',
        name: 'Caaguazu',
        country_id: '600',
        state_cd: '5'
      },
      {
        state_cd_full: 'PY-6',
        name: 'Caazapa',
        country_id: '600',
        state_cd: '6'
      },
      {
        state_cd_full: 'PY-7',
        name: 'Itapua',
        country_id: '600',
        state_cd: '7'
      },
      {
        state_cd_full: 'PY-8',
        name: 'Misiones',
        country_id: '600',
        state_cd: '8'
      },
      {
        state_cd_full: 'PY-9',
        name: 'Paraguari',
        country_id: '600',
        state_cd: '9'
      },
      {
        state_cd_full: 'PY-ASU',
        name: 'Asuncion',
        country_id: '600',
        state_cd: 'ASU'
      }
    ]
  },
  {
    country_cd: 'PE',
    name: 'Peru',
    country_id: 604,
    states: [
      {
        state_cd_full: 'PE-AMA',
        name: 'Amazonas',
        country_id: '604',
        state_cd: 'AMA'
      },
      {
        state_cd_full: 'PE-ANC',
        name: 'Ancash',
        country_id: '604',
        state_cd: 'ANC'
      },
      {
        state_cd_full: 'PE-APU',
        name: 'Apurimac',
        country_id: '604',
        state_cd: 'APU'
      },
      {
        state_cd_full: 'PE-ARE',
        name: 'Arequipa',
        country_id: '604',
        state_cd: 'ARE'
      },
      {
        state_cd_full: 'PE-AYA',
        name: 'Ayacucho',
        country_id: '604',
        state_cd: 'AYA'
      },
      {
        state_cd_full: 'PE-CAJ',
        name: 'Cajamarca',
        country_id: '604',
        state_cd: 'CAJ'
      },
      {
        state_cd_full: 'PE-CAL',
        name: 'El Callao',
        country_id: '604',
        state_cd: 'CAL'
      },
      {
        state_cd_full: 'PE-CUS',
        name: 'Cusco',
        country_id: '604',
        state_cd: 'CUS'
      },
      {
        state_cd_full: 'PE-HUC',
        name: 'Huanuco',
        country_id: '604',
        state_cd: 'HUC'
      },
      {
        state_cd_full: 'PE-HUV',
        name: 'Huancavelica',
        country_id: '604',
        state_cd: 'HUV'
      },
      {
        state_cd_full: 'PE-ICA',
        name: 'Ica',
        country_id: '604',
        state_cd: 'ICA'
      },
      {
        state_cd_full: 'PE-JUN',
        name: 'Junin',
        country_id: '604',
        state_cd: 'JUN'
      },
      {
        state_cd_full: 'PE-LAL',
        name: 'La Libertad',
        country_id: '604',
        state_cd: 'LAL'
      },
      {
        state_cd_full: 'PE-LAM',
        name: 'Lambayeque',
        country_id: '604',
        state_cd: 'LAM'
      },
      {
        state_cd_full: 'PE-LIM',
        name: 'Lima',
        country_id: '604',
        state_cd: 'LIM'
      },
      {
        state_cd_full: 'PE-LOR',
        name: 'Loreto',
        country_id: '604',
        state_cd: 'LOR'
      },
      {
        state_cd_full: 'PE-MDD',
        name: 'Madre de Dios',
        country_id: '604',
        state_cd: 'MDD'
      },
      {
        state_cd_full: 'PE-MOQ',
        name: 'Moquegua',
        country_id: '604',
        state_cd: 'MOQ'
      },
      {
        state_cd_full: 'PE-PAS',
        name: 'Pasco',
        country_id: '604',
        state_cd: 'PAS'
      },
      {
        state_cd_full: 'PE-PIU',
        name: 'Piura',
        country_id: '604',
        state_cd: 'PIU'
      },
      {
        state_cd_full: 'PE-PUN',
        name: 'Puno',
        country_id: '604',
        state_cd: 'PUN'
      },
      {
        state_cd_full: 'PE-SAM',
        name: 'San Martin',
        country_id: '604',
        state_cd: 'SAM'
      },
      {
        state_cd_full: 'PE-TAC',
        name: 'Tacna',
        country_id: '604',
        state_cd: 'TAC'
      },
      {
        state_cd_full: 'PE-TUM',
        name: 'Tumbes',
        country_id: '604',
        state_cd: 'TUM'
      },
      {
        state_cd_full: 'PE-UCA',
        name: 'Ucayali',
        country_id: '604',
        state_cd: 'UCA'
      }
    ]
  },
  {
    country_cd: 'PH',
    name: 'Philippines',
    country_id: 608,
    states: [
      {
        state_cd_full: 'PH-00',
        name: 'National Capital Region',
        country_id: '608',
        state_cd: '00'
      },
      {
        state_cd_full: 'PH-ABR',
        name: 'Abra',
        country_id: '608',
        state_cd: 'ABR'
      },
      {
        state_cd_full: 'PH-AGN',
        name: 'Agusan del Norte',
        country_id: '608',
        state_cd: 'AGN'
      },
      {
        state_cd_full: 'PH-AGS',
        name: 'Agusan del Sur',
        country_id: '608',
        state_cd: 'AGS'
      },
      {
        state_cd_full: 'PH-AKL',
        name: 'Aklan',
        country_id: '608',
        state_cd: 'AKL'
      },
      {
        state_cd_full: 'PH-ALB',
        name: 'Albay',
        country_id: '608',
        state_cd: 'ALB'
      },
      {
        state_cd_full: 'PH-ANT',
        name: 'Antique',
        country_id: '608',
        state_cd: 'ANT'
      },
      {
        state_cd_full: 'PH-APA',
        name: 'Apayao',
        country_id: '608',
        state_cd: 'APA'
      },
      {
        state_cd_full: 'PH-AUR',
        name: 'Aurora',
        country_id: '608',
        state_cd: 'AUR'
      },
      {
        state_cd_full: 'PH-BAN',
        name: 'Bataan',
        country_id: '608',
        state_cd: 'BAN'
      },
      {
        state_cd_full: 'PH-BAS',
        name: 'Basilan',
        country_id: '608',
        state_cd: 'BAS'
      },
      {
        state_cd_full: 'PH-BEN',
        name: 'Benguet',
        country_id: '608',
        state_cd: 'BEN'
      },
      {
        state_cd_full: 'PH-BIL',
        name: 'Biliran',
        country_id: '608',
        state_cd: 'BIL'
      },
      {
        state_cd_full: 'PH-BOH',
        name: 'Bohol',
        country_id: '608',
        state_cd: 'BOH'
      },
      {
        state_cd_full: 'PH-BTG',
        name: 'Batangas',
        country_id: '608',
        state_cd: 'BTG'
      },
      {
        state_cd_full: 'PH-BTN',
        name: 'Batanes',
        country_id: '608',
        state_cd: 'BTN'
      },
      {
        state_cd_full: 'PH-BUK',
        name: 'Bukidnon',
        country_id: '608',
        state_cd: 'BUK'
      },
      {
        state_cd_full: 'PH-BUL',
        name: 'Bulacan',
        country_id: '608',
        state_cd: 'BUL'
      },
      {
        state_cd_full: 'PH-CAG',
        name: 'Cagayan',
        country_id: '608',
        state_cd: 'CAG'
      },
      {
        state_cd_full: 'PH-CAM',
        name: 'Camiguin',
        country_id: '608',
        state_cd: 'CAM'
      },
      {
        state_cd_full: 'PH-CAN',
        name: 'Camarines Norte',
        country_id: '608',
        state_cd: 'CAN'
      },
      {
        state_cd_full: 'PH-CAP',
        name: 'Capiz',
        country_id: '608',
        state_cd: 'CAP'
      },
      {
        state_cd_full: 'PH-CAS',
        name: 'Camarines Sur',
        country_id: '608',
        state_cd: 'CAS'
      },
      {
        state_cd_full: 'PH-CAT',
        name: 'Catanduanes',
        country_id: '608',
        state_cd: 'CAT'
      },
      {
        state_cd_full: 'PH-CAV',
        name: 'Cavite',
        country_id: '608',
        state_cd: 'CAV'
      },
      {
        state_cd_full: 'PH-CEB',
        name: 'Cebu',
        country_id: '608',
        state_cd: 'CEB'
      },
      {
        state_cd_full: 'PH-COM',
        name: 'Compostela Valley',
        country_id: '608',
        state_cd: 'COM'
      },
      {
        state_cd_full: 'PH-DAO',
        name: 'Davao Oriental',
        country_id: '608',
        state_cd: 'DAO'
      },
      {
        state_cd_full: 'PH-DAS',
        name: 'Davao del Sur',
        country_id: '608',
        state_cd: 'DAS'
      },
      {
        state_cd_full: 'PH-DAV',
        name: 'Davao del Norte',
        country_id: '608',
        state_cd: 'DAV'
      },
      {
        state_cd_full: 'PH-DIN',
        name: 'Dinagat Islands',
        country_id: '608',
        state_cd: 'DIN'
      },
      {
        state_cd_full: 'PH-EAS',
        name: 'Eastern Samar',
        country_id: '608',
        state_cd: 'EAS'
      },
      {
        state_cd_full: 'PH-GUI',
        name: 'Guimaras',
        country_id: '608',
        state_cd: 'GUI'
      },
      {
        state_cd_full: 'PH-IFU',
        name: 'Ifugao',
        country_id: '608',
        state_cd: 'IFU'
      },
      {
        state_cd_full: 'PH-ILI',
        name: 'Iloilo',
        country_id: '608',
        state_cd: 'ILI'
      },
      {
        state_cd_full: 'PH-ILN',
        name: 'Ilocos Norte',
        country_id: '608',
        state_cd: 'ILN'
      },
      {
        state_cd_full: 'PH-ILS',
        name: 'Ilocos Sur',
        country_id: '608',
        state_cd: 'ILS'
      },
      {
        state_cd_full: 'PH-ISA',
        name: 'Isabela',
        country_id: '608',
        state_cd: 'ISA'
      },
      {
        state_cd_full: 'PH-LAG',
        name: 'Laguna',
        country_id: '608',
        state_cd: 'LAG'
      },
      {
        state_cd_full: 'PH-LAN',
        name: 'Lanao del Norte',
        country_id: '608',
        state_cd: 'LAN'
      },
      {
        state_cd_full: 'PH-LAS',
        name: 'Lanao del Sur',
        country_id: '608',
        state_cd: 'LAS'
      },
      {
        state_cd_full: 'PH-LEY',
        name: 'Leyte',
        country_id: '608',
        state_cd: 'LEY'
      },
      {
        state_cd_full: 'PH-LUN',
        name: 'La Union',
        country_id: '608',
        state_cd: 'LUN'
      },
      {
        state_cd_full: 'PH-MAD',
        name: 'Marinduque',
        country_id: '608',
        state_cd: 'MAD'
      },
      {
        state_cd_full: 'PH-MAG',
        name: 'Maguindanao',
        country_id: '608',
        state_cd: 'MAG'
      },
      {
        state_cd_full: 'PH-MAS',
        name: 'Masbate',
        country_id: '608',
        state_cd: 'MAS'
      },
      {
        state_cd_full: 'PH-MDC',
        name: 'Mindoro Occidental',
        country_id: '608',
        state_cd: 'MDC'
      },
      {
        state_cd_full: 'PH-MDR',
        name: 'Mindoro Oriental',
        country_id: '608',
        state_cd: 'MDR'
      },
      {
        state_cd_full: 'PH-MOU',
        name: 'Mountain Province',
        country_id: '608',
        state_cd: 'MOU'
      },
      {
        state_cd_full: 'PH-MSC',
        name: 'Misamis Occidental',
        country_id: '608',
        state_cd: 'MSC'
      },
      {
        state_cd_full: 'PH-MSR',
        name: 'Misamis Oriental',
        country_id: '608',
        state_cd: 'MSR'
      },
      {
        state_cd_full: 'PH-NCO',
        name: 'Cotabato',
        country_id: '608',
        state_cd: 'NCO'
      },
      {
        state_cd_full: 'PH-NEC',
        name: 'Negros Occidental',
        country_id: '608',
        state_cd: 'NEC'
      },
      {
        state_cd_full: 'PH-NER',
        name: 'Negros Oriental',
        country_id: '608',
        state_cd: 'NER'
      },
      {
        state_cd_full: 'PH-NSA',
        name: 'Northern Samar',
        country_id: '608',
        state_cd: 'NSA'
      },
      {
        state_cd_full: 'PH-NUE',
        name: 'Nueva Ecija',
        country_id: '608',
        state_cd: 'NUE'
      },
      {
        state_cd_full: 'PH-NUV',
        name: 'Nueva Vizcaya',
        country_id: '608',
        state_cd: 'NUV'
      },
      {
        state_cd_full: 'PH-PAM',
        name: 'Pampanga',
        country_id: '608',
        state_cd: 'PAM'
      },
      {
        state_cd_full: 'PH-PAN',
        name: 'Pangasinan',
        country_id: '608',
        state_cd: 'PAN'
      },
      {
        state_cd_full: 'PH-PLW',
        name: 'Palawan',
        country_id: '608',
        state_cd: 'PLW'
      },
      {
        state_cd_full: 'PH-QUE',
        name: 'Quezon',
        country_id: '608',
        state_cd: 'QUE'
      },
      {
        state_cd_full: 'PH-QUI',
        name: 'Quirino',
        country_id: '608',
        state_cd: 'QUI'
      },
      {
        state_cd_full: 'PH-RIZ',
        name: 'Rizal',
        country_id: '608',
        state_cd: 'RIZ'
      },
      {
        state_cd_full: 'PH-ROM',
        name: 'Romblon',
        country_id: '608',
        state_cd: 'ROM'
      },
      {
        state_cd_full: 'PH-SAR',
        name: 'Sarangani',
        country_id: '608',
        state_cd: 'SAR'
      },
      {
        state_cd_full: 'PH-SCO',
        name: 'South Cotabato',
        country_id: '608',
        state_cd: 'SCO'
      },
      {
        state_cd_full: 'PH-SIG',
        name: 'Siquijor',
        country_id: '608',
        state_cd: 'SIG'
      },
      {
        state_cd_full: 'PH-SLE',
        name: 'Southern Leyte',
        country_id: '608',
        state_cd: 'SLE'
      },
      {
        state_cd_full: 'PH-SLU',
        name: 'Sulu',
        country_id: '608',
        state_cd: 'SLU'
      },
      {
        state_cd_full: 'PH-SOR',
        name: 'Sorsogon',
        country_id: '608',
        state_cd: 'SOR'
      },
      {
        state_cd_full: 'PH-SUK',
        name: 'Sultan Kudarat',
        country_id: '608',
        state_cd: 'SUK'
      },
      {
        state_cd_full: 'PH-SUN',
        name: 'Surigao del Norte',
        country_id: '608',
        state_cd: 'SUN'
      },
      {
        state_cd_full: 'PH-SUR',
        name: 'Surigao del Sur',
        country_id: '608',
        state_cd: 'SUR'
      },
      {
        state_cd_full: 'PH-TAR',
        name: 'Tarlac',
        country_id: '608',
        state_cd: 'TAR'
      },
      {
        state_cd_full: 'PH-TAW',
        name: 'Tawi-Tawi',
        country_id: '608',
        state_cd: 'TAW'
      },
      {
        state_cd_full: 'PH-WSA',
        name: 'Samar',
        country_id: '608',
        state_cd: 'WSA'
      },
      {
        state_cd_full: 'PH-ZAN',
        name: 'Zamboanga del Norte',
        country_id: '608',
        state_cd: 'ZAN'
      },
      {
        state_cd_full: 'PH-ZAS',
        name: 'Zamboanga del Sur',
        country_id: '608',
        state_cd: 'ZAS'
      },
      {
        state_cd_full: 'PH-ZMB',
        name: 'Zambales',
        country_id: '608',
        state_cd: 'ZMB'
      },
      {
        state_cd_full: 'PH-ZSI',
        name: 'Zamboanga Sibugay',
        country_id: '608',
        state_cd: 'ZSI'
      }
    ]
  },
  {
    country_cd: 'PN',
    name: 'Pitcairn',
    country_id: 612,
    states: [
      {
        state_cd_full: 'PN-01',
        name: 'Pitcairn',
        country_id: '612',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'PL',
    name: 'Poland',
    country_id: 616,
    states: [
      {
        state_cd_full: 'PL-DS',
        name: 'Dolnoslaskie',
        country_id: '616',
        state_cd: 'DS'
      },
      {
        state_cd_full: 'PL-KP',
        name: 'Kujawsko-pomorskie',
        country_id: '616',
        state_cd: 'KP'
      },
      {
        state_cd_full: 'PL-LB',
        name: 'Lubuskie',
        country_id: '616',
        state_cd: 'LB'
      },
      {
        state_cd_full: 'PL-LD',
        name: 'Lodzkie',
        country_id: '616',
        state_cd: 'LD'
      },
      {
        state_cd_full: 'PL-LU',
        name: 'Lubelskie',
        country_id: '616',
        state_cd: 'LU'
      },
      {
        state_cd_full: 'PL-MA',
        name: 'Malopolskie',
        country_id: '616',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'PL-MZ',
        name: 'Mazowieckie',
        country_id: '616',
        state_cd: 'MZ'
      },
      {
        state_cd_full: 'PL-OP',
        name: 'Opolskie',
        country_id: '616',
        state_cd: 'OP'
      },
      {
        state_cd_full: 'PL-PD',
        name: 'Podlaskie',
        country_id: '616',
        state_cd: 'PD'
      },
      {
        state_cd_full: 'PL-PK',
        name: 'Podkarpackie',
        country_id: '616',
        state_cd: 'PK'
      },
      {
        state_cd_full: 'PL-PM',
        name: 'Pomorskie',
        country_id: '616',
        state_cd: 'PM'
      },
      {
        state_cd_full: 'PL-SK',
        name: 'Swietokrzyskie',
        country_id: '616',
        state_cd: 'SK'
      },
      {
        state_cd_full: 'PL-SL',
        name: 'Slaskie',
        country_id: '616',
        state_cd: 'SL'
      },
      {
        state_cd_full: 'PL-WN',
        name: 'Warminsko-mazurskie',
        country_id: '616',
        state_cd: 'WN'
      },
      {
        state_cd_full: 'PL-WP',
        name: 'Wielkopolskie',
        country_id: '616',
        state_cd: 'WP'
      },
      {
        state_cd_full: 'PL-ZP',
        name: 'Zachodniopomorskie',
        country_id: '616',
        state_cd: 'ZP'
      }
    ]
  },
  {
    country_cd: 'PT',
    name: 'Portugal',
    country_id: 620,
    states: [
      {
        state_cd_full: 'PT-01',
        name: 'Aveiro',
        country_id: '620',
        state_cd: '01'
      },
      {
        state_cd_full: 'PT-02',
        name: 'Beja',
        country_id: '620',
        state_cd: '02'
      },
      {
        state_cd_full: 'PT-03',
        name: 'Braga',
        country_id: '620',
        state_cd: '03'
      },
      {
        state_cd_full: 'PT-04',
        name: 'Braganca',
        country_id: '620',
        state_cd: '04'
      },
      {
        state_cd_full: 'PT-05',
        name: 'Castelo Branco',
        country_id: '620',
        state_cd: '05'
      },
      {
        state_cd_full: 'PT-06',
        name: 'Coimbra',
        country_id: '620',
        state_cd: '06'
      },
      {
        state_cd_full: 'PT-07',
        name: 'Evora',
        country_id: '620',
        state_cd: '07'
      },
      {
        state_cd_full: 'PT-08',
        name: 'Faro',
        country_id: '620',
        state_cd: '08'
      },
      {
        state_cd_full: 'PT-09',
        name: 'Guarda',
        country_id: '620',
        state_cd: '09'
      },
      {
        state_cd_full: 'PT-10',
        name: 'Leiria',
        country_id: '620',
        state_cd: '10'
      },
      {
        state_cd_full: 'PT-11',
        name: 'Lisboa',
        country_id: '620',
        state_cd: '11'
      },
      {
        state_cd_full: 'PT-12',
        name: 'Portalegre',
        country_id: '620',
        state_cd: '12'
      },
      {
        state_cd_full: 'PT-13',
        name: 'Porto',
        country_id: '620',
        state_cd: '13'
      },
      {
        state_cd_full: 'PT-14',
        name: 'Santarem',
        country_id: '620',
        state_cd: '14'
      },
      {
        state_cd_full: 'PT-15',
        name: 'Setubal',
        country_id: '620',
        state_cd: '15'
      },
      {
        state_cd_full: 'PT-16',
        name: 'Viana do Castelo',
        country_id: '620',
        state_cd: '16'
      },
      {
        state_cd_full: 'PT-17',
        name: 'Vila Real',
        country_id: '620',
        state_cd: '17'
      },
      {
        state_cd_full: 'PT-18',
        name: 'Viseu',
        country_id: '620',
        state_cd: '18'
      },
      {
        state_cd_full: 'PT-20',
        name: 'Regiao Autonoma dos Acores',
        country_id: '620',
        state_cd: '20'
      },
      {
        state_cd_full: 'PT-30',
        name: 'Regiao Autonoma da Madeira',
        country_id: '620',
        state_cd: '30'
      }
    ]
  },
  {
    country_cd: 'PR',
    name: 'Puerto Rico',
    country_id: 630,
    states: [
      {
        state_cd_full: 'PR-01',
        name: 'Yauco',
        country_id: '630',
        state_cd: '01'
      },
      {
        state_cd_full: 'PR-101',
        name: 'Santa Isabel Municipio',
        country_id: '630',
        state_cd: '101'
      },
      {
        state_cd_full: 'PR-11',
        name: 'Yabucoa',
        country_id: '630',
        state_cd: '11'
      },
      {
        state_cd_full: 'PR-111',
        name: 'San Sebastian',
        country_id: '630',
        state_cd: '111'
      },
      {
        state_cd_full: 'PR-121',
        name: 'San Lorenzo',
        country_id: '630',
        state_cd: '121'
      },
      {
        state_cd_full: 'PR-131',
        name: 'San Juan',
        country_id: '630',
        state_cd: '131'
      },
      {
        state_cd_full: 'PR-141',
        name: 'San German',
        country_id: '630',
        state_cd: '141'
      },
      {
        state_cd_full: 'PR-151',
        name: 'Salinas',
        country_id: '630',
        state_cd: '151'
      },
      {
        state_cd_full: 'PR-161',
        name: 'Sabana Grande',
        country_id: '630',
        state_cd: '161'
      },
      {
        state_cd_full: 'PR-171',
        name: 'Rio Grande',
        country_id: '630',
        state_cd: '171'
      },
      {
        state_cd_full: 'PR-181',
        name: 'Rincon',
        country_id: '630',
        state_cd: '181'
      },
      {
        state_cd_full: 'PR-191',
        name: 'Quebradillas',
        country_id: '630',
        state_cd: '191'
      },
      {
        state_cd_full: 'PR-201',
        name: 'Ponce',
        country_id: '630',
        state_cd: '201'
      },
      {
        state_cd_full: 'PR-21',
        name: 'Villalba',
        country_id: '630',
        state_cd: '21'
      },
      {
        state_cd_full: 'PR-211',
        name: 'Penuelas',
        country_id: '630',
        state_cd: '211'
      },
      {
        state_cd_full: 'PR-221',
        name: 'Patillas',
        country_id: '630',
        state_cd: '221'
      },
      {
        state_cd_full: 'PR-231',
        name: 'Naranjito',
        country_id: '630',
        state_cd: '231'
      },
      {
        state_cd_full: 'PR-241',
        name: 'Naguabo',
        country_id: '630',
        state_cd: '241'
      },
      {
        state_cd_full: 'PR-251',
        name: 'Municipio de Juncos',
        country_id: '630',
        state_cd: '251'
      },
      {
        state_cd_full: 'PR-261',
        name: 'Municipio de Jayuya',
        country_id: '630',
        state_cd: '261'
      },
      {
        state_cd_full: 'PR-271',
        name: 'Morovis',
        country_id: '630',
        state_cd: '271'
      },
      {
        state_cd_full: 'PR-281',
        name: 'Moca',
        country_id: '630',
        state_cd: '281'
      },
      {
        state_cd_full: 'PR-291',
        name: 'Mayaguez',
        country_id: '630',
        state_cd: '291'
      },
      {
        state_cd_full: 'PR-301',
        name: 'Maricao',
        country_id: '630',
        state_cd: '301'
      },
      {
        state_cd_full: 'PR-31',
        name: 'Vieques',
        country_id: '630',
        state_cd: '31'
      },
      {
        state_cd_full: 'PR-311',
        name: 'Manati',
        country_id: '630',
        state_cd: '311'
      },
      {
        state_cd_full: 'PR-321',
        name: 'Luquillo',
        country_id: '630',
        state_cd: '321'
      },
      {
        state_cd_full: 'PR-331',
        name: 'Loiza',
        country_id: '630',
        state_cd: '331'
      },
      {
        state_cd_full: 'PR-341',
        name: 'Las Piedras',
        country_id: '630',
        state_cd: '341'
      },
      {
        state_cd_full: 'PR-351',
        name: 'Las Marias',
        country_id: '630',
        state_cd: '351'
      },
      {
        state_cd_full: 'PR-361',
        name: 'Lares',
        country_id: '630',
        state_cd: '361'
      },
      {
        state_cd_full: 'PR-371',
        name: 'Lajas',
        country_id: '630',
        state_cd: '371'
      },
      {
        state_cd_full: 'PR-381',
        name: 'Juana Diaz',
        country_id: '630',
        state_cd: '381'
      },
      {
        state_cd_full: 'PR-391',
        name: 'Isabela',
        country_id: '630',
        state_cd: '391'
      },
      {
        state_cd_full: 'PR-401',
        name: 'Humacao',
        country_id: '630',
        state_cd: '401'
      },
      {
        state_cd_full: 'PR-41',
        name: 'Vega Baja',
        country_id: '630',
        state_cd: '41'
      },
      {
        state_cd_full: 'PR-411',
        name: 'Hormigueros',
        country_id: '630',
        state_cd: '411'
      },
      {
        state_cd_full: 'PR-421',
        name: 'Hatillo',
        country_id: '630',
        state_cd: '421'
      },
      {
        state_cd_full: 'PR-431',
        name: 'Gurabo',
        country_id: '630',
        state_cd: '431'
      },
      {
        state_cd_full: 'PR-441',
        name: 'Guaynabo',
        country_id: '630',
        state_cd: '441'
      },
      {
        state_cd_full: 'PR-451',
        name: 'Guayanilla',
        country_id: '630',
        state_cd: '451'
      },
      {
        state_cd_full: 'PR-461',
        name: 'Guayama',
        country_id: '630',
        state_cd: '461'
      },
      {
        state_cd_full: 'PR-471',
        name: 'Guanica',
        country_id: '630',
        state_cd: '471'
      },
      {
        state_cd_full: 'PR-481',
        name: 'Florida',
        country_id: '630',
        state_cd: '481'
      },
      {
        state_cd_full: 'PR-491',
        name: 'Fajardo',
        country_id: '630',
        state_cd: '491'
      },
      {
        state_cd_full: 'PR-501',
        name: 'Dorado',
        country_id: '630',
        state_cd: '501'
      },
      {
        state_cd_full: 'PR-51',
        name: 'Vega Alta',
        country_id: '630',
        state_cd: '51'
      },
      {
        state_cd_full: 'PR-511',
        name: 'Culebra',
        country_id: '630',
        state_cd: '511'
      },
      {
        state_cd_full: 'PR-521',
        name: 'Corozal',
        country_id: '630',
        state_cd: '521'
      },
      {
        state_cd_full: 'PR-531',
        name: 'Comerio',
        country_id: '630',
        state_cd: '531'
      },
      {
        state_cd_full: 'PR-541',
        name: 'Coamo',
        country_id: '630',
        state_cd: '541'
      },
      {
        state_cd_full: 'PR-551',
        name: 'Cidra',
        country_id: '630',
        state_cd: '551'
      },
      {
        state_cd_full: 'PR-561',
        name: 'Ciales',
        country_id: '630',
        state_cd: '561'
      },
      {
        state_cd_full: 'PR-571',
        name: 'Ceiba',
        country_id: '630',
        state_cd: '571'
      },
      {
        state_cd_full: 'PR-581',
        name: 'Cayey',
        country_id: '630',
        state_cd: '581'
      },
      {
        state_cd_full: 'PR-591',
        name: 'Catano',
        country_id: '630',
        state_cd: '591'
      },
      {
        state_cd_full: 'PR-601',
        name: 'Carolina',
        country_id: '630',
        state_cd: '601'
      },
      {
        state_cd_full: 'PR-61',
        name: 'Utuado',
        country_id: '630',
        state_cd: '61'
      },
      {
        state_cd_full: 'PR-611',
        name: 'Canovanas',
        country_id: '630',
        state_cd: '611'
      },
      {
        state_cd_full: 'PR-621',
        name: 'Camuy',
        country_id: '630',
        state_cd: '621'
      },
      {
        state_cd_full: 'PR-631',
        name: 'Caguas',
        country_id: '630',
        state_cd: '631'
      },
      {
        state_cd_full: 'PR-641',
        name: 'Cabo Rojo',
        country_id: '630',
        state_cd: '641'
      },
      {
        state_cd_full: 'PR-651',
        name: 'Bayamon',
        country_id: '630',
        state_cd: '651'
      },
      {
        state_cd_full: 'PR-661',
        name: 'Barranquitas',
        country_id: '630',
        state_cd: '661'
      },
      {
        state_cd_full: 'PR-671',
        name: 'Barceloneta',
        country_id: '630',
        state_cd: '671'
      },
      {
        state_cd_full: 'PR-681',
        name: 'Arroyo',
        country_id: '630',
        state_cd: '681'
      },
      {
        state_cd_full: 'PR-691',
        name: 'Arecibo',
        country_id: '630',
        state_cd: '691'
      },
      {
        state_cd_full: 'PR-701',
        name: 'Anasco',
        country_id: '630',
        state_cd: '701'
      },
      {
        state_cd_full: 'PR-71',
        name: 'Trujillo Alto',
        country_id: '630',
        state_cd: '71'
      },
      {
        state_cd_full: 'PR-711',
        name: 'Aibonito',
        country_id: '630',
        state_cd: '711'
      },
      {
        state_cd_full: 'PR-721',
        name: 'Aguas Buenas',
        country_id: '630',
        state_cd: '721'
      },
      {
        state_cd_full: 'PR-731',
        name: 'Aguadilla',
        country_id: '630',
        state_cd: '731'
      },
      {
        state_cd_full: 'PR-741',
        name: 'Aguada',
        country_id: '630',
        state_cd: '741'
      },
      {
        state_cd_full: 'PR-751',
        name: 'Adjuntas',
        country_id: '630',
        state_cd: '751'
      },
      {
        state_cd_full: 'PR-81',
        name: 'Toa Baja',
        country_id: '630',
        state_cd: '81'
      },
      {
        state_cd_full: 'PR-91',
        name: 'Toa Alta',
        country_id: '630',
        state_cd: '91'
      }
    ]
  },
  {
    country_cd: 'QA',
    name: 'Qatar',
    country_id: 634,
    states: [
      {
        state_cd_full: 'QA-DA',
        name: 'Ad Dawhah',
        country_id: '634',
        state_cd: 'DA'
      },
      {
        state_cd_full: 'QA-KH',
        name: 'Al Khawr wa adh Dhakhirah',
        country_id: '634',
        state_cd: 'KH'
      },
      {
        state_cd_full: 'QA-MS',
        name: 'Ash Shamal',
        country_id: '634',
        state_cd: 'MS'
      },
      {
        state_cd_full: 'QA-RA',
        name: 'Ar Rayyan',
        country_id: '634',
        state_cd: 'RA'
      },
      {
        state_cd_full: 'QA-US',
        name: 'Umm Salal',
        country_id: '634',
        state_cd: 'US'
      },
      {
        state_cd_full: 'QA-WA',
        name: 'Al Wakrah',
        country_id: '634',
        state_cd: 'WA'
      },
      {
        state_cd_full: 'QA-ZA',
        name: "Az Za'ayin",
        country_id: '634',
        state_cd: 'ZA'
      }
    ]
  },
  {
    country_cd: 'RO',
    name: 'Romania',
    country_id: 642,
    states: [
      {
        state_cd_full: 'RO-AB',
        name: 'Alba',
        country_id: '642',
        state_cd: 'AB'
      },
      {
        state_cd_full: 'RO-AG',
        name: 'Arges',
        country_id: '642',
        state_cd: 'AG'
      },
      {
        state_cd_full: 'RO-AR',
        name: 'Arad',
        country_id: '642',
        state_cd: 'AR'
      },
      {
        state_cd_full: 'RO-B',
        name: 'Bucuresti',
        country_id: '642',
        state_cd: 'B'
      },
      {
        state_cd_full: 'RO-BC',
        name: 'Bacau',
        country_id: '642',
        state_cd: 'BC'
      },
      {
        state_cd_full: 'RO-BH',
        name: 'Bihor',
        country_id: '642',
        state_cd: 'BH'
      },
      {
        state_cd_full: 'RO-BN',
        name: 'Bistrita-Nasaud',
        country_id: '642',
        state_cd: 'BN'
      },
      {
        state_cd_full: 'RO-BR',
        name: 'Braila',
        country_id: '642',
        state_cd: 'BR'
      },
      {
        state_cd_full: 'RO-BT',
        name: 'Botosani',
        country_id: '642',
        state_cd: 'BT'
      },
      {
        state_cd_full: 'RO-BV',
        name: 'Brasov',
        country_id: '642',
        state_cd: 'BV'
      },
      {
        state_cd_full: 'RO-BZ',
        name: 'Buzau',
        country_id: '642',
        state_cd: 'BZ'
      },
      {
        state_cd_full: 'RO-CJ',
        name: 'Cluj',
        country_id: '642',
        state_cd: 'CJ'
      },
      {
        state_cd_full: 'RO-CL',
        name: 'Calarasi',
        country_id: '642',
        state_cd: 'CL'
      },
      {
        state_cd_full: 'RO-CS',
        name: 'Caras-Severin',
        country_id: '642',
        state_cd: 'CS'
      },
      {
        state_cd_full: 'RO-CT',
        name: 'Constanta',
        country_id: '642',
        state_cd: 'CT'
      },
      {
        state_cd_full: 'RO-CV',
        name: 'Covasna',
        country_id: '642',
        state_cd: 'CV'
      },
      {
        state_cd_full: 'RO-DB',
        name: 'Dambovita',
        country_id: '642',
        state_cd: 'DB'
      },
      {
        state_cd_full: 'RO-DJ',
        name: 'Dolj',
        country_id: '642',
        state_cd: 'DJ'
      },
      {
        state_cd_full: 'RO-GJ',
        name: 'Gorj',
        country_id: '642',
        state_cd: 'GJ'
      },
      {
        state_cd_full: 'RO-GL',
        name: 'Galati',
        country_id: '642',
        state_cd: 'GL'
      },
      {
        state_cd_full: 'RO-GR',
        name: 'Giurgiu',
        country_id: '642',
        state_cd: 'GR'
      },
      {
        state_cd_full: 'RO-HD',
        name: 'Hunedoara',
        country_id: '642',
        state_cd: 'HD'
      },
      {
        state_cd_full: 'RO-HR',
        name: 'Harghita',
        country_id: '642',
        state_cd: 'HR'
      },
      {
        state_cd_full: 'RO-IF',
        name: 'Ilfov',
        country_id: '642',
        state_cd: 'IF'
      },
      {
        state_cd_full: 'RO-IL',
        name: 'Ialomita',
        country_id: '642',
        state_cd: 'IL'
      },
      {
        state_cd_full: 'RO-IS',
        name: 'Iasi',
        country_id: '642',
        state_cd: 'IS'
      },
      {
        state_cd_full: 'RO-MH',
        name: 'Mehedinti',
        country_id: '642',
        state_cd: 'MH'
      },
      {
        state_cd_full: 'RO-MM',
        name: 'Maramures',
        country_id: '642',
        state_cd: 'MM'
      },
      {
        state_cd_full: 'RO-MS',
        name: 'Mures',
        country_id: '642',
        state_cd: 'MS'
      },
      {
        state_cd_full: 'RO-NT',
        name: 'Neamt',
        country_id: '642',
        state_cd: 'NT'
      },
      {
        state_cd_full: 'RO-OT',
        name: 'Olt',
        country_id: '642',
        state_cd: 'OT'
      },
      {
        state_cd_full: 'RO-PH',
        name: 'Prahova',
        country_id: '642',
        state_cd: 'PH'
      },
      {
        state_cd_full: 'RO-SB',
        name: 'Sibiu',
        country_id: '642',
        state_cd: 'SB'
      },
      {
        state_cd_full: 'RO-SJ',
        name: 'Salaj',
        country_id: '642',
        state_cd: 'SJ'
      },
      {
        state_cd_full: 'RO-SM',
        name: 'Satu Mare',
        country_id: '642',
        state_cd: 'SM'
      },
      {
        state_cd_full: 'RO-SV',
        name: 'Suceava',
        country_id: '642',
        state_cd: 'SV'
      },
      {
        state_cd_full: 'RO-TL',
        name: 'Tulcea',
        country_id: '642',
        state_cd: 'TL'
      },
      {
        state_cd_full: 'RO-TM',
        name: 'Timis',
        country_id: '642',
        state_cd: 'TM'
      },
      {
        state_cd_full: 'RO-TR',
        name: 'Teleorman',
        country_id: '642',
        state_cd: 'TR'
      },
      {
        state_cd_full: 'RO-VL',
        name: 'Valcea',
        country_id: '642',
        state_cd: 'VL'
      },
      {
        state_cd_full: 'RO-VN',
        name: 'Vrancea',
        country_id: '642',
        state_cd: 'VN'
      },
      {
        state_cd_full: 'RO-VS',
        name: 'Vaslui',
        country_id: '642',
        state_cd: 'VS'
      }
    ]
  },
  {
    country_cd: 'RU',
    name: 'Russian Federation',
    country_id: 643,
    states: [
      {
        state_cd_full: 'RU-AD',
        name: 'Adygeya, Respublika',
        country_id: '643',
        state_cd: 'AD'
      },
      {
        state_cd_full: 'RU-AL',
        name: 'Altay, Respublika',
        country_id: '643',
        state_cd: 'AL'
      },
      {
        state_cd_full: 'RU-ALT',
        name: 'Altayskiy kray',
        country_id: '643',
        state_cd: 'ALT'
      },
      {
        state_cd_full: 'RU-AMU',
        name: "Amurskaya oblast'",
        country_id: '643',
        state_cd: 'AMU'
      },
      {
        state_cd_full: 'RU-ARK',
        name: "Arkhangel'skaya oblast'",
        country_id: '643',
        state_cd: 'ARK'
      },
      {
        state_cd_full: 'RU-AST',
        name: "Astrakhanskaya oblast'",
        country_id: '643',
        state_cd: 'AST'
      },
      {
        state_cd_full: 'RU-BA',
        name: 'Bashkortostan, Respublika',
        country_id: '643',
        state_cd: 'BA'
      },
      {
        state_cd_full: 'RU-BEL',
        name: "Belgorodskaya oblast'",
        country_id: '643',
        state_cd: 'BEL'
      },
      {
        state_cd_full: 'RU-BRY',
        name: "Bryanskaya oblast'",
        country_id: '643',
        state_cd: 'BRY'
      },
      {
        state_cd_full: 'RU-BU',
        name: 'Buryatiya, Respublika',
        country_id: '643',
        state_cd: 'BU'
      },
      {
        state_cd_full: 'RU-CE',
        name: 'Chechenskaya Respublika',
        country_id: '643',
        state_cd: 'CE'
      },
      {
        state_cd_full: 'RU-CHE',
        name: "Chelyabinskaya oblast'",
        country_id: '643',
        state_cd: 'CHE'
      },
      {
        state_cd_full: 'RU-CHU',
        name: 'Chukotskiy avtonomnyy okrug',
        country_id: '643',
        state_cd: 'CHU'
      },
      {
        state_cd_full: 'RU-CU',
        name: 'Chuvashskaya Respublika',
        country_id: '643',
        state_cd: 'CU'
      },
      {
        state_cd_full: 'RU-DA',
        name: 'Dagestan, Respublika',
        country_id: '643',
        state_cd: 'DA'
      },
      {
        state_cd_full: 'RU-IN',
        name: 'Ingushetiya, Respublika',
        country_id: '643',
        state_cd: 'IN'
      },
      {
        state_cd_full: 'RU-IRK',
        name: "Irkutskaya oblast'",
        country_id: '643',
        state_cd: 'IRK'
      },
      {
        state_cd_full: 'RU-IVA',
        name: "Ivanovskaya oblast'",
        country_id: '643',
        state_cd: 'IVA'
      },
      {
        state_cd_full: 'RU-KAM',
        name: 'Kamchatskiy kray',
        country_id: '643',
        state_cd: 'KAM'
      },
      {
        state_cd_full: 'RU-KB',
        name: 'Kabardino-Balkarskaya Respublika',
        country_id: '643',
        state_cd: 'KB'
      },
      {
        state_cd_full: 'RU-KC',
        name: 'Karachayevo-Cherkesskaya Respublika',
        country_id: '643',
        state_cd: 'KC'
      },
      {
        state_cd_full: 'RU-KDA',
        name: 'Krasnodarskiy kray',
        country_id: '643',
        state_cd: 'KDA'
      },
      {
        state_cd_full: 'RU-KEM',
        name: "Kemerovskaya oblast'",
        country_id: '643',
        state_cd: 'KEM'
      },
      {
        state_cd_full: 'RU-KGD',
        name: "Kaliningradskaya oblast'",
        country_id: '643',
        state_cd: 'KGD'
      },
      {
        state_cd_full: 'RU-KGN',
        name: "Kurganskaya oblast'",
        country_id: '643',
        state_cd: 'KGN'
      },
      {
        state_cd_full: 'RU-KHA',
        name: 'Khabarovskiy kray',
        country_id: '643',
        state_cd: 'KHA'
      },
      {
        state_cd_full: 'RU-KHM',
        name: 'Khanty-Mansiyskiy avtonomnyy okrug',
        country_id: '643',
        state_cd: 'KHM'
      },
      {
        state_cd_full: 'RU-KIR',
        name: "Kirovskaya oblast'",
        country_id: '643',
        state_cd: 'KIR'
      },
      {
        state_cd_full: 'RU-KK',
        name: 'Khakasiya, Respublika',
        country_id: '643',
        state_cd: 'KK'
      },
      {
        state_cd_full: 'RU-KL',
        name: 'Kalmykiya, Respublika',
        country_id: '643',
        state_cd: 'KL'
      },
      {
        state_cd_full: 'RU-KLU',
        name: "Kaluzhskaya oblast'",
        country_id: '643',
        state_cd: 'KLU'
      },
      {
        state_cd_full: 'RU-KO',
        name: 'Komi, Respublika',
        country_id: '643',
        state_cd: 'KO'
      },
      {
        state_cd_full: 'RU-KOS',
        name: "Kostromskaya oblast'",
        country_id: '643',
        state_cd: 'KOS'
      },
      {
        state_cd_full: 'RU-KR',
        name: 'Kareliya, Respublika',
        country_id: '643',
        state_cd: 'KR'
      },
      {
        state_cd_full: 'RU-KRS',
        name: "Kurskaya oblast'",
        country_id: '643',
        state_cd: 'KRS'
      },
      {
        state_cd_full: 'RU-KYA',
        name: 'Krasnoyarskiy kray',
        country_id: '643',
        state_cd: 'KYA'
      },
      {
        state_cd_full: 'RU-LEN',
        name: "Leningradskaya oblast'",
        country_id: '643',
        state_cd: 'LEN'
      },
      {
        state_cd_full: 'RU-LIP',
        name: "Lipetskaya oblast'",
        country_id: '643',
        state_cd: 'LIP'
      },
      {
        state_cd_full: 'RU-MAG',
        name: "Magadanskaya oblast'",
        country_id: '643',
        state_cd: 'MAG'
      },
      {
        state_cd_full: 'RU-ME',
        name: 'Mariy El, Respublika',
        country_id: '643',
        state_cd: 'ME'
      },
      {
        state_cd_full: 'RU-MO',
        name: 'Mordoviya, Respublika',
        country_id: '643',
        state_cd: 'MO'
      },
      {
        state_cd_full: 'RU-MOS',
        name: "Moskovskaya oblast'",
        country_id: '643',
        state_cd: 'MOS'
      },
      {
        state_cd_full: 'RU-MOW',
        name: 'Moskva',
        country_id: '643',
        state_cd: 'MOW'
      },
      {
        state_cd_full: 'RU-MUR',
        name: "Murmanskaya oblast'",
        country_id: '643',
        state_cd: 'MUR'
      },
      {
        state_cd_full: 'RU-NEN',
        name: 'Nenetskiy avtonomnyy okrug',
        country_id: '643',
        state_cd: 'NEN'
      },
      {
        state_cd_full: 'RU-NGR',
        name: "Novgorodskaya oblast'",
        country_id: '643',
        state_cd: 'NGR'
      },
      {
        state_cd_full: 'RU-NIZ',
        name: "Nizhegorodskaya oblast'",
        country_id: '643',
        state_cd: 'NIZ'
      },
      {
        state_cd_full: 'RU-NVS',
        name: "Novosibirskaya oblast'",
        country_id: '643',
        state_cd: 'NVS'
      },
      {
        state_cd_full: 'RU-OMS',
        name: "Omskaya oblast'",
        country_id: '643',
        state_cd: 'OMS'
      },
      {
        state_cd_full: 'RU-ORE',
        name: "Orenburgskaya oblast'",
        country_id: '643',
        state_cd: 'ORE'
      },
      {
        state_cd_full: 'RU-ORL',
        name: "Orlovskaya oblast'",
        country_id: '643',
        state_cd: 'ORL'
      },
      {
        state_cd_full: 'RU-PER',
        name: 'Permskiy kray',
        country_id: '643',
        state_cd: 'PER'
      },
      {
        state_cd_full: 'RU-PNZ',
        name: "Penzenskaya oblast'",
        country_id: '643',
        state_cd: 'PNZ'
      },
      {
        state_cd_full: 'RU-PRI',
        name: 'Primorskiy kray',
        country_id: '643',
        state_cd: 'PRI'
      },
      {
        state_cd_full: 'RU-PSK',
        name: "Pskovskaya oblast'",
        country_id: '643',
        state_cd: 'PSK'
      },
      {
        state_cd_full: 'RU-ROS',
        name: "Rostovskaya oblast'",
        country_id: '643',
        state_cd: 'ROS'
      },
      {
        state_cd_full: 'RU-RYA',
        name: "Ryazanskaya oblast'",
        country_id: '643',
        state_cd: 'RYA'
      },
      {
        state_cd_full: 'RU-SA',
        name: 'Saha, Respublika',
        country_id: '643',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'RU-SAK',
        name: "Sakhalinskaya oblast'",
        country_id: '643',
        state_cd: 'SAK'
      },
      {
        state_cd_full: 'RU-SAM',
        name: "Samarskaya oblast'",
        country_id: '643',
        state_cd: 'SAM'
      },
      {
        state_cd_full: 'RU-SAR',
        name: "Saratovskaya oblast'",
        country_id: '643',
        state_cd: 'SAR'
      },
      {
        state_cd_full: 'RU-SE',
        name: 'Severnaya Osetiya, Respublika',
        country_id: '643',
        state_cd: 'SE'
      },
      {
        state_cd_full: 'RU-SMO',
        name: "Smolenskaya oblast'",
        country_id: '643',
        state_cd: 'SMO'
      },
      {
        state_cd_full: 'RU-SPE',
        name: 'Sankt-Peterburg',
        country_id: '643',
        state_cd: 'SPE'
      },
      {
        state_cd_full: 'RU-STA',
        name: "Stavropol'skiy kray",
        country_id: '643',
        state_cd: 'STA'
      },
      {
        state_cd_full: 'RU-SVE',
        name: "Sverdlovskaya oblast'",
        country_id: '643',
        state_cd: 'SVE'
      },
      {
        state_cd_full: 'RU-TA',
        name: 'Tatarstan, Respublika',
        country_id: '643',
        state_cd: 'TA'
      },
      {
        state_cd_full: 'RU-TAM',
        name: "Tambovskaya oblast'",
        country_id: '643',
        state_cd: 'TAM'
      },
      {
        state_cd_full: 'RU-TOM',
        name: "Tomskaya oblast'",
        country_id: '643',
        state_cd: 'TOM'
      },
      {
        state_cd_full: 'RU-TUL',
        name: "Tul'skaya oblast'",
        country_id: '643',
        state_cd: 'TUL'
      },
      {
        state_cd_full: 'RU-TVE',
        name: "Tverskaya oblast'",
        country_id: '643',
        state_cd: 'TVE'
      },
      {
        state_cd_full: 'RU-TY',
        name: 'Tyva, Respublika',
        country_id: '643',
        state_cd: 'TY'
      },
      {
        state_cd_full: 'RU-TYU',
        name: "Tyumenskaya oblast'",
        country_id: '643',
        state_cd: 'TYU'
      },
      {
        state_cd_full: 'RU-UD',
        name: 'Udmurtskaya Respublika',
        country_id: '643',
        state_cd: 'UD'
      },
      {
        state_cd_full: 'RU-ULY',
        name: "Ul'yanovskaya oblast'",
        country_id: '643',
        state_cd: 'ULY'
      },
      {
        state_cd_full: 'RU-VGG',
        name: "Volgogradskaya oblast'",
        country_id: '643',
        state_cd: 'VGG'
      },
      {
        state_cd_full: 'RU-VLA',
        name: "Vladimirskaya oblast'",
        country_id: '643',
        state_cd: 'VLA'
      },
      {
        state_cd_full: 'RU-VLG',
        name: "Vologodskaya oblast'",
        country_id: '643',
        state_cd: 'VLG'
      },
      {
        state_cd_full: 'RU-VOR',
        name: "Voronezhskaya oblast'",
        country_id: '643',
        state_cd: 'VOR'
      },
      {
        state_cd_full: 'RU-YAN',
        name: 'Yamalo-Nenetskiy avtonomnyy okrug',
        country_id: '643',
        state_cd: 'YAN'
      },
      {
        state_cd_full: 'RU-YAR',
        name: "Yaroslavskaya oblast'",
        country_id: '643',
        state_cd: 'YAR'
      },
      {
        state_cd_full: 'RU-YEV',
        name: "Yevreyskaya avtonomnaya oblast'",
        country_id: '643',
        state_cd: 'YEV'
      },
      {
        state_cd_full: 'RU-ZAB',
        name: "Zabaykal'skiy kray",
        country_id: '643',
        state_cd: 'ZAB'
      }
    ]
  },
  {
    country_cd: 'RW',
    name: 'Rwanda',
    country_id: 646,
    states: [
      {
        state_cd_full: 'RW-01',
        name: 'Ville de Kigali',
        country_id: '646',
        state_cd: '01'
      },
      {
        state_cd_full: 'RW-02',
        name: 'Est',
        country_id: '646',
        state_cd: '02'
      },
      {
        state_cd_full: 'RW-03',
        name: 'Nord',
        country_id: '646',
        state_cd: '03'
      },
      {
        state_cd_full: 'RW-04',
        name: 'Ouest',
        country_id: '646',
        state_cd: '04'
      },
      {
        state_cd_full: 'RW-05',
        name: 'Sud',
        country_id: '646',
        state_cd: '05'
      }
    ]
  },
  {
    country_cd: 'RE',
    name: 'Réunion',
    country_id: 638,
    states: [
      {
        state_cd_full: 'RE-01',
        name: 'Reunion',
        country_id: '638',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'BL',
    name: 'Saint Barthélemy',
    country_id: 652,
    states: [
      {
        state_cd_full: 'BL-01',
        name: 'Saint Barthelemy',
        country_id: '652',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    country_id: 654,
    states: [
      {
        state_cd_full: 'SH-HL',
        name: 'Saint Helena',
        country_id: '654',
        state_cd: 'HL'
      }
    ]
  },
  {
    country_cd: 'KN',
    name: 'Saint Kitts and Nevis',
    country_id: 659,
    states: [
      {
        state_cd_full: 'KN-02',
        name: 'Saint Anne Sandy Point',
        country_id: '659',
        state_cd: '02'
      },
      {
        state_cd_full: 'KN-03',
        name: 'Saint George Basseterre',
        country_id: '659',
        state_cd: '03'
      },
      {
        state_cd_full: 'KN-05',
        name: 'Saint James Windward',
        country_id: '659',
        state_cd: '05'
      },
      {
        state_cd_full: 'KN-06',
        name: 'Saint John Capisterre',
        country_id: '659',
        state_cd: '06'
      },
      {
        state_cd_full: 'KN-07',
        name: 'Saint John Figtree',
        country_id: '659',
        state_cd: '07'
      },
      {
        state_cd_full: 'KN-08',
        name: 'Saint Mary Cayon',
        country_id: '659',
        state_cd: '08'
      },
      {
        state_cd_full: 'KN-09',
        name: 'Saint Paul Capisterre',
        country_id: '659',
        state_cd: '09'
      },
      {
        state_cd_full: 'KN-10',
        name: 'Saint Paul Charlestown',
        country_id: '659',
        state_cd: '10'
      },
      {
        state_cd_full: 'KN-11',
        name: 'Saint Peter Basseterre',
        country_id: '659',
        state_cd: '11'
      },
      {
        state_cd_full: 'KN-12',
        name: 'Saint Thomas Lowland',
        country_id: '659',
        state_cd: '12'
      },
      {
        state_cd_full: 'KN-13',
        name: 'Saint Thomas Middle Island',
        country_id: '659',
        state_cd: '13'
      }
    ]
  },
  {
    country_cd: 'LC',
    name: 'Saint Lucia',
    country_id: 662,
    states: [
      {
        state_cd_full: 'LC-01',
        name: 'Anse la Raye',
        country_id: '662',
        state_cd: '01'
      },
      {
        state_cd_full: 'LC-02',
        name: 'Castries',
        country_id: '662',
        state_cd: '02'
      },
      {
        state_cd_full: 'LC-05',
        name: 'Dennery',
        country_id: '662',
        state_cd: '05'
      },
      {
        state_cd_full: 'LC-06',
        name: 'Gros Islet',
        country_id: '662',
        state_cd: '06'
      },
      {
        state_cd_full: 'LC-07',
        name: 'Laborie',
        country_id: '662',
        state_cd: '07'
      },
      {
        state_cd_full: 'LC-08',
        name: 'Micoud',
        country_id: '662',
        state_cd: '08'
      },
      {
        state_cd_full: 'LC-10',
        name: 'Soufriere',
        country_id: '662',
        state_cd: '10'
      },
      {
        state_cd_full: 'LC-11',
        name: 'Vieux Fort',
        country_id: '662',
        state_cd: '11'
      }
    ]
  },
  {
    country_cd: 'MF',
    name: 'Saint Martin (French part)',
    country_id: 663,
    states: [
      {
        state_cd_full: 'MF-01',
        name: 'Saint Martin (French Part)',
        country_id: '663',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'PM',
    name: 'Saint Pierre and Miquelon',
    country_id: 666,
    states: [
      {
        state_cd_full: 'PM-01',
        name: 'Saint Pierre and Miquelon',
        country_id: '666',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'VC',
    name: 'Saint Vincent and the Grenadines',
    country_id: 670,
    states: [
      {
        state_cd_full: 'VC-01',
        name: 'Charlotte',
        country_id: '670',
        state_cd: '01'
      },
      {
        state_cd_full: 'VC-03',
        name: 'Saint David',
        country_id: '670',
        state_cd: '03'
      },
      {
        state_cd_full: 'VC-04',
        name: 'Saint George',
        country_id: '670',
        state_cd: '04'
      },
      {
        state_cd_full: 'VC-05',
        name: 'Saint Patrick',
        country_id: '670',
        state_cd: '05'
      },
      {
        state_cd_full: 'VC-06',
        name: 'Grenadines',
        country_id: '670',
        state_cd: '06'
      }
    ]
  },
  {
    country_cd: 'WS',
    name: 'Samoa',
    country_id: 882,
    states: [
      {
        state_cd_full: 'WS-TU',
        name: 'Tuamasaga',
        country_id: '882',
        state_cd: 'TU'
      }
    ]
  },
  {
    country_cd: 'SM',
    name: 'San Marino',
    country_id: 674,
    states: [
      {
        state_cd_full: 'SM-02',
        name: 'Chiesanuova',
        country_id: '674',
        state_cd: '02'
      },
      {
        state_cd_full: 'SM-03',
        name: 'Domagnano',
        country_id: '674',
        state_cd: '03'
      },
      {
        state_cd_full: 'SM-07',
        name: 'San Marino',
        country_id: '674',
        state_cd: '07'
      },
      {
        state_cd_full: 'SM-09',
        name: 'Serravalle',
        country_id: '674',
        state_cd: '09'
      }
    ]
  },
  {
    country_cd: 'ST',
    name: 'Sao Tome and Principe',
    country_id: 678,
    states: [
      {
        state_cd_full: 'ST-P',
        name: 'Principe',
        country_id: '678',
        state_cd: 'P'
      },
      {
        state_cd_full: 'ST-S',
        name: 'Sao Tome',
        country_id: '678',
        state_cd: 'S'
      }
    ]
  },
  {
    country_cd: 'SA',
    name: 'Saudi Arabia',
    country_id: 682,
    states: [
      {
        state_cd_full: 'SA-01',
        name: 'Ar Riyad',
        country_id: '682',
        state_cd: '01'
      },
      {
        state_cd_full: 'SA-02',
        name: 'Makkah al Mukarramah',
        country_id: '682',
        state_cd: '02'
      },
      {
        state_cd_full: 'SA-03',
        name: 'Al Madinah al Munawwarah',
        country_id: '682',
        state_cd: '03'
      },
      {
        state_cd_full: 'SA-04',
        name: 'Ash Sharqiyah',
        country_id: '682',
        state_cd: '04'
      },
      {
        state_cd_full: 'SA-05',
        name: 'Al Qasim',
        country_id: '682',
        state_cd: '05'
      },
      {
        state_cd_full: 'SA-06',
        name: "Ha'il",
        country_id: '682',
        state_cd: '06'
      },
      {
        state_cd_full: 'SA-07',
        name: 'Tabuk',
        country_id: '682',
        state_cd: '07'
      },
      {
        state_cd_full: 'SA-08',
        name: 'Al Hudud ash Shamaliyah',
        country_id: '682',
        state_cd: '08'
      },
      {
        state_cd_full: 'SA-09',
        name: 'Jazan',
        country_id: '682',
        state_cd: '09'
      },
      {
        state_cd_full: 'SA-10',
        name: 'Najran',
        country_id: '682',
        state_cd: '10'
      },
      {
        state_cd_full: 'SA-11',
        name: 'Al Bahah',
        country_id: '682',
        state_cd: '11'
      },
      {
        state_cd_full: 'SA-12',
        name: 'Al Jawf',
        country_id: '682',
        state_cd: '12'
      },
      {
        state_cd_full: 'SA-14',
        name: "'Asir",
        country_id: '682',
        state_cd: '14'
      }
    ]
  },
  {
    country_cd: 'SN',
    name: 'Senegal',
    country_id: 686,
    states: [
      {
        state_cd_full: 'SN-DB',
        name: 'Diourbel',
        country_id: '686',
        state_cd: 'DB'
      },
      {
        state_cd_full: 'SN-DK',
        name: 'Dakar',
        country_id: '686',
        state_cd: 'DK'
      },
      {
        state_cd_full: 'SN-FK',
        name: 'Fatick',
        country_id: '686',
        state_cd: 'FK'
      },
      {
        state_cd_full: 'SN-KA',
        name: 'Kaffrine',
        country_id: '686',
        state_cd: 'KA'
      },
      {
        state_cd_full: 'SN-KL',
        name: 'Kaolack',
        country_id: '686',
        state_cd: 'KL'
      },
      {
        state_cd_full: 'SN-LG',
        name: 'Louga',
        country_id: '686',
        state_cd: 'LG'
      },
      {
        state_cd_full: 'SN-SL',
        name: 'Saint-Louis',
        country_id: '686',
        state_cd: 'SL'
      },
      {
        state_cd_full: 'SN-TC',
        name: 'Tambacounda',
        country_id: '686',
        state_cd: 'TC'
      },
      {
        state_cd_full: 'SN-TH',
        name: 'Thies',
        country_id: '686',
        state_cd: 'TH'
      },
      {
        state_cd_full: 'SN-ZG',
        name: 'Ziguinchor',
        country_id: '686',
        state_cd: 'ZG'
      }
    ]
  },
  {
    country_cd: 'RS',
    name: 'Serbia',
    country_id: 688,
    states: [
      {
        state_cd_full: 'RS-00',
        name: 'Beograd',
        country_id: '688',
        state_cd: '00'
      },
      {
        state_cd_full: 'RS-01',
        name: 'Severnobacki okrug',
        country_id: '688',
        state_cd: '01'
      },
      {
        state_cd_full: 'RS-02',
        name: 'Srednjebanatski okrug',
        country_id: '688',
        state_cd: '02'
      },
      {
        state_cd_full: 'RS-03',
        name: 'Severnobanatski okrug',
        country_id: '688',
        state_cd: '03'
      },
      {
        state_cd_full: 'RS-04',
        name: 'Juznobanatski okrug',
        country_id: '688',
        state_cd: '04'
      },
      {
        state_cd_full: 'RS-05',
        name: 'Zapadnobacki okrug',
        country_id: '688',
        state_cd: '05'
      },
      {
        state_cd_full: 'RS-06',
        name: 'Juznobacki okrug',
        country_id: '688',
        state_cd: '06'
      },
      {
        state_cd_full: 'RS-07',
        name: 'Sremski okrug',
        country_id: '688',
        state_cd: '07'
      },
      {
        state_cd_full: 'RS-08',
        name: 'Macvanski okrug',
        country_id: '688',
        state_cd: '08'
      },
      {
        state_cd_full: 'RS-09',
        name: 'Kolubarski okrug',
        country_id: '688',
        state_cd: '09'
      },
      {
        state_cd_full: 'RS-10',
        name: 'Podunavski okrug',
        country_id: '688',
        state_cd: '10'
      },
      {
        state_cd_full: 'RS-11',
        name: 'Branicevski okrug',
        country_id: '688',
        state_cd: '11'
      },
      {
        state_cd_full: 'RS-12',
        name: 'Sumadijski okrug',
        country_id: '688',
        state_cd: '12'
      },
      {
        state_cd_full: 'RS-13',
        name: 'Pomoravski okrug',
        country_id: '688',
        state_cd: '13'
      },
      {
        state_cd_full: 'RS-14',
        name: 'Borski okrug',
        country_id: '688',
        state_cd: '14'
      },
      {
        state_cd_full: 'RS-15',
        name: 'Zajecarski okrug',
        country_id: '688',
        state_cd: '15'
      },
      {
        state_cd_full: 'RS-16',
        name: 'Zlatiborski okrug',
        country_id: '688',
        state_cd: '16'
      },
      {
        state_cd_full: 'RS-17',
        name: 'Moravicki okrug',
        country_id: '688',
        state_cd: '17'
      },
      {
        state_cd_full: 'RS-18',
        name: 'Raski okrug',
        country_id: '688',
        state_cd: '18'
      },
      {
        state_cd_full: 'RS-19',
        name: 'Rasinski okrug',
        country_id: '688',
        state_cd: '19'
      },
      {
        state_cd_full: 'RS-20',
        name: 'Nisavski okrug',
        country_id: '688',
        state_cd: '20'
      },
      {
        state_cd_full: 'RS-21',
        name: 'Toplicki okrug',
        country_id: '688',
        state_cd: '21'
      },
      {
        state_cd_full: 'RS-22',
        name: 'Pirotski okrug',
        country_id: '688',
        state_cd: '22'
      },
      {
        state_cd_full: 'RS-23',
        name: 'Jablanicki okrug',
        country_id: '688',
        state_cd: '23'
      },
      {
        state_cd_full: 'RS-24',
        name: 'Pcinjski okrug',
        country_id: '688',
        state_cd: '24'
      },
      {
        state_cd_full: 'RS-27',
        name: 'Prizrenski okrug',
        country_id: '688',
        state_cd: '27'
      },
      {
        state_cd_full: 'RS-28',
        name: 'Kosovsko-Mitrovacki okrug',
        country_id: '688',
        state_cd: '28'
      }
    ]
  },
  {
    country_cd: 'SC',
    name: 'Seychelles',
    country_id: 690,
    states: [
      {
        state_cd_full: 'SC-01',
        name: 'Anse aux Pins',
        country_id: '690',
        state_cd: '01'
      },
      {
        state_cd_full: 'SC-02',
        name: 'Anse Boileau',
        country_id: '690',
        state_cd: '02'
      },
      {
        state_cd_full: 'SC-05',
        name: 'Anse Royale',
        country_id: '690',
        state_cd: '05'
      },
      {
        state_cd_full: 'SC-06',
        name: 'Baie Lazare',
        country_id: '690',
        state_cd: '06'
      },
      {
        state_cd_full: 'SC-08',
        name: 'Beau Vallon',
        country_id: '690',
        state_cd: '08'
      },
      {
        state_cd_full: 'SC-10',
        name: 'Bel Ombre',
        country_id: '690',
        state_cd: '10'
      },
      {
        state_cd_full: 'SC-11',
        name: 'Cascade',
        country_id: '690',
        state_cd: '11'
      },
      {
        state_cd_full: 'SC-13',
        name: 'Grand Anse Mahe',
        country_id: '690',
        state_cd: '13'
      },
      {
        state_cd_full: 'SC-15',
        name: 'La Digue',
        country_id: '690',
        state_cd: '15'
      },
      {
        state_cd_full: 'SC-16',
        name: 'English River',
        country_id: '690',
        state_cd: '16'
      },
      {
        state_cd_full: 'SC-23',
        name: 'Takamaka',
        country_id: '690',
        state_cd: '23'
      }
    ]
  },
  {
    country_cd: 'SL',
    name: 'Sierra Leone',
    country_id: 694,
    states: [
      {
        state_cd_full: 'SL-E',
        name: 'Eastern',
        country_id: '694',
        state_cd: 'E'
      },
      {
        state_cd_full: 'SL-W',
        name: 'Western Area',
        country_id: '694',
        state_cd: 'W'
      }
    ]
  },
  {
    country_cd: 'SG',
    name: 'Singapore',
    country_id: 702,
    states: [
      {
        state_cd_full: 'SG-01',
        name: 'Singapore',
        country_id: '702',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'SX',
    name: 'Sint Maarten (Dutch part)',
    country_id: 534,
    states: [
      {
        state_cd_full: 'SX-01',
        name: 'Sint Maarten (Dutch Part)',
        country_id: '534',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'SK',
    name: 'Slovakia',
    country_id: 703,
    states: [
      {
        state_cd_full: 'SK-BC',
        name: 'Banskobystricky kraj',
        country_id: '703',
        state_cd: 'BC'
      },
      {
        state_cd_full: 'SK-BL',
        name: 'Bratislavsky kraj',
        country_id: '703',
        state_cd: 'BL'
      },
      {
        state_cd_full: 'SK-KI',
        name: 'Kosicky kraj',
        country_id: '703',
        state_cd: 'KI'
      },
      {
        state_cd_full: 'SK-NI',
        name: 'Nitriansky kraj',
        country_id: '703',
        state_cd: 'NI'
      },
      {
        state_cd_full: 'SK-PV',
        name: 'Presovsky kraj',
        country_id: '703',
        state_cd: 'PV'
      },
      {
        state_cd_full: 'SK-TA',
        name: 'Trnavsky kraj',
        country_id: '703',
        state_cd: 'TA'
      },
      {
        state_cd_full: 'SK-TC',
        name: 'Trenciansky kraj',
        country_id: '703',
        state_cd: 'TC'
      },
      {
        state_cd_full: 'SK-ZI',
        name: 'Zilinsky kraj',
        country_id: '703',
        state_cd: 'ZI'
      }
    ]
  },
  {
    country_cd: 'SI',
    name: 'Slovenia',
    country_id: 705,
    states: [
      {
        state_cd_full: 'SI-001',
        name: 'Ajdovscina',
        country_id: '705',
        state_cd: '001'
      },
      {
        state_cd_full: 'SI-002',
        name: 'Beltinci',
        country_id: '705',
        state_cd: '002'
      },
      {
        state_cd_full: 'SI-003',
        name: 'Bled',
        country_id: '705',
        state_cd: '003'
      },
      {
        state_cd_full: 'SI-004',
        name: 'Bohinj',
        country_id: '705',
        state_cd: '004'
      },
      {
        state_cd_full: 'SI-005',
        name: 'Borovnica',
        country_id: '705',
        state_cd: '005'
      },
      {
        state_cd_full: 'SI-006',
        name: 'Bovec',
        country_id: '705',
        state_cd: '006'
      },
      {
        state_cd_full: 'SI-007',
        name: 'Brda',
        country_id: '705',
        state_cd: '007'
      },
      {
        state_cd_full: 'SI-008',
        name: 'Brezovica',
        country_id: '705',
        state_cd: '008'
      },
      {
        state_cd_full: 'SI-009',
        name: 'Brezice',
        country_id: '705',
        state_cd: '009'
      },
      {
        state_cd_full: 'SI-010',
        name: 'Tisina',
        country_id: '705',
        state_cd: '010'
      },
      {
        state_cd_full: 'SI-011',
        name: 'Celje',
        country_id: '705',
        state_cd: '011'
      },
      {
        state_cd_full: 'SI-012',
        name: 'Cerklje na Gorenjskem',
        country_id: '705',
        state_cd: '012'
      },
      {
        state_cd_full: 'SI-013',
        name: 'Cerknica',
        country_id: '705',
        state_cd: '013'
      },
      {
        state_cd_full: 'SI-014',
        name: 'Cerkno',
        country_id: '705',
        state_cd: '014'
      },
      {
        state_cd_full: 'SI-015',
        name: 'Crensovci',
        country_id: '705',
        state_cd: '015'
      },
      {
        state_cd_full: 'SI-017',
        name: 'Crnomelj',
        country_id: '705',
        state_cd: '017'
      },
      {
        state_cd_full: 'SI-018',
        name: 'Destrnik',
        country_id: '705',
        state_cd: '018'
      },
      {
        state_cd_full: 'SI-019',
        name: 'Divaca',
        country_id: '705',
        state_cd: '019'
      },
      {
        state_cd_full: 'SI-020',
        name: 'Dobrepolje',
        country_id: '705',
        state_cd: '020'
      },
      {
        state_cd_full: 'SI-021',
        name: 'Dobrova-Polhov Gradec',
        country_id: '705',
        state_cd: '021'
      },
      {
        state_cd_full: 'SI-023',
        name: 'Domzale',
        country_id: '705',
        state_cd: '023'
      },
      {
        state_cd_full: 'SI-024',
        name: 'Dornava',
        country_id: '705',
        state_cd: '024'
      },
      {
        state_cd_full: 'SI-025',
        name: 'Dravograd',
        country_id: '705',
        state_cd: '025'
      },
      {
        state_cd_full: 'SI-026',
        name: 'Duplek',
        country_id: '705',
        state_cd: '026'
      },
      {
        state_cd_full: 'SI-029',
        name: 'Gornja Radgona',
        country_id: '705',
        state_cd: '029'
      },
      {
        state_cd_full: 'SI-031',
        name: 'Gornji Petrovci',
        country_id: '705',
        state_cd: '031'
      },
      {
        state_cd_full: 'SI-032',
        name: 'Grosuplje',
        country_id: '705',
        state_cd: '032'
      },
      {
        state_cd_full: 'SI-033',
        name: 'Salovci',
        country_id: '705',
        state_cd: '033'
      },
      {
        state_cd_full: 'SI-034',
        name: 'Hrastnik',
        country_id: '705',
        state_cd: '034'
      },
      {
        state_cd_full: 'SI-035',
        name: 'Hrpelje-Kozina',
        country_id: '705',
        state_cd: '035'
      },
      {
        state_cd_full: 'SI-036',
        name: 'Idrija',
        country_id: '705',
        state_cd: '036'
      },
      {
        state_cd_full: 'SI-037',
        name: 'Ig',
        country_id: '705',
        state_cd: '037'
      },
      {
        state_cd_full: 'SI-038',
        name: 'Ilirska Bistrica',
        country_id: '705',
        state_cd: '038'
      },
      {
        state_cd_full: 'SI-039',
        name: 'Ivancna Gorica',
        country_id: '705',
        state_cd: '039'
      },
      {
        state_cd_full: 'SI-040',
        name: 'Izola',
        country_id: '705',
        state_cd: '040'
      },
      {
        state_cd_full: 'SI-041',
        name: 'Jesenice',
        country_id: '705',
        state_cd: '041'
      },
      {
        state_cd_full: 'SI-042',
        name: 'Jursinci',
        country_id: '705',
        state_cd: '042'
      },
      {
        state_cd_full: 'SI-043',
        name: 'Kamnik',
        country_id: '705',
        state_cd: '043'
      },
      {
        state_cd_full: 'SI-044',
        name: 'Kanal',
        country_id: '705',
        state_cd: '044'
      },
      {
        state_cd_full: 'SI-045',
        name: 'Kidricevo',
        country_id: '705',
        state_cd: '045'
      },
      {
        state_cd_full: 'SI-046',
        name: 'Kobarid',
        country_id: '705',
        state_cd: '046'
      },
      {
        state_cd_full: 'SI-047',
        name: 'Kobilje',
        country_id: '705',
        state_cd: '047'
      },
      {
        state_cd_full: 'SI-048',
        name: 'Kocevje',
        country_id: '705',
        state_cd: '048'
      },
      {
        state_cd_full: 'SI-049',
        name: 'Komen',
        country_id: '705',
        state_cd: '049'
      },
      {
        state_cd_full: 'SI-050',
        name: 'Koper',
        country_id: '705',
        state_cd: '050'
      },
      {
        state_cd_full: 'SI-052',
        name: 'Kranj',
        country_id: '705',
        state_cd: '052'
      },
      {
        state_cd_full: 'SI-053',
        name: 'Kranjska Gora',
        country_id: '705',
        state_cd: '053'
      },
      {
        state_cd_full: 'SI-054',
        name: 'Krsko',
        country_id: '705',
        state_cd: '054'
      },
      {
        state_cd_full: 'SI-055',
        name: 'Kungota',
        country_id: '705',
        state_cd: '055'
      },
      {
        state_cd_full: 'SI-056',
        name: 'Kuzma',
        country_id: '705',
        state_cd: '056'
      },
      {
        state_cd_full: 'SI-057',
        name: 'Lasko',
        country_id: '705',
        state_cd: '057'
      },
      {
        state_cd_full: 'SI-058',
        name: 'Lenart',
        country_id: '705',
        state_cd: '058'
      },
      {
        state_cd_full: 'SI-059',
        name: 'Lendava',
        country_id: '705',
        state_cd: '059'
      },
      {
        state_cd_full: 'SI-060',
        name: 'Litija',
        country_id: '705',
        state_cd: '060'
      },
      {
        state_cd_full: 'SI-061',
        name: 'Ljubljana',
        country_id: '705',
        state_cd: '061'
      },
      {
        state_cd_full: 'SI-063',
        name: 'Ljutomer',
        country_id: '705',
        state_cd: '063'
      },
      {
        state_cd_full: 'SI-064',
        name: 'Logatec',
        country_id: '705',
        state_cd: '064'
      },
      {
        state_cd_full: 'SI-065',
        name: 'Loska Dolina',
        country_id: '705',
        state_cd: '065'
      },
      {
        state_cd_full: 'SI-067',
        name: 'Luce',
        country_id: '705',
        state_cd: '067'
      },
      {
        state_cd_full: 'SI-068',
        name: 'Lukovica',
        country_id: '705',
        state_cd: '068'
      },
      {
        state_cd_full: 'SI-069',
        name: 'Majsperk',
        country_id: '705',
        state_cd: '069'
      },
      {
        state_cd_full: 'SI-070',
        name: 'Maribor',
        country_id: '705',
        state_cd: '070'
      },
      {
        state_cd_full: 'SI-071',
        name: 'Medvode',
        country_id: '705',
        state_cd: '071'
      },
      {
        state_cd_full: 'SI-072',
        name: 'Menges',
        country_id: '705',
        state_cd: '072'
      },
      {
        state_cd_full: 'SI-073',
        name: 'Metlika',
        country_id: '705',
        state_cd: '073'
      },
      {
        state_cd_full: 'SI-074',
        name: 'Mezica',
        country_id: '705',
        state_cd: '074'
      },
      {
        state_cd_full: 'SI-075',
        name: 'Miren-Kostanjevica',
        country_id: '705',
        state_cd: '075'
      },
      {
        state_cd_full: 'SI-076',
        name: 'Mislinja',
        country_id: '705',
        state_cd: '076'
      },
      {
        state_cd_full: 'SI-077',
        name: 'Moravce',
        country_id: '705',
        state_cd: '077'
      },
      {
        state_cd_full: 'SI-079',
        name: 'Mozirje',
        country_id: '705',
        state_cd: '079'
      },
      {
        state_cd_full: 'SI-080',
        name: 'Murska Sobota',
        country_id: '705',
        state_cd: '080'
      },
      {
        state_cd_full: 'SI-081',
        name: 'Muta',
        country_id: '705',
        state_cd: '081'
      },
      {
        state_cd_full: 'SI-082',
        name: 'Naklo',
        country_id: '705',
        state_cd: '082'
      },
      {
        state_cd_full: 'SI-083',
        name: 'Nazarje',
        country_id: '705',
        state_cd: '083'
      },
      {
        state_cd_full: 'SI-084',
        name: 'Nova Gorica',
        country_id: '705',
        state_cd: '084'
      },
      {
        state_cd_full: 'SI-085',
        name: 'Novo Mesto',
        country_id: '705',
        state_cd: '085'
      },
      {
        state_cd_full: 'SI-086',
        name: 'Odranci',
        country_id: '705',
        state_cd: '086'
      },
      {
        state_cd_full: 'SI-087',
        name: 'Ormoz',
        country_id: '705',
        state_cd: '087'
      },
      {
        state_cd_full: 'SI-090',
        name: 'Piran',
        country_id: '705',
        state_cd: '090'
      },
      {
        state_cd_full: 'SI-091',
        name: 'Pivka',
        country_id: '705',
        state_cd: '091'
      },
      {
        state_cd_full: 'SI-092',
        name: 'Podcetrtek',
        country_id: '705',
        state_cd: '092'
      },
      {
        state_cd_full: 'SI-094',
        name: 'Postojna',
        country_id: '705',
        state_cd: '094'
      },
      {
        state_cd_full: 'SI-095',
        name: 'Preddvor',
        country_id: '705',
        state_cd: '095'
      },
      {
        state_cd_full: 'SI-096',
        name: 'Ptuj',
        country_id: '705',
        state_cd: '096'
      },
      {
        state_cd_full: 'SI-097',
        name: 'Puconci',
        country_id: '705',
        state_cd: '097'
      },
      {
        state_cd_full: 'SI-098',
        name: 'Race-Fram',
        country_id: '705',
        state_cd: '098'
      },
      {
        state_cd_full: 'SI-099',
        name: 'Radece',
        country_id: '705',
        state_cd: '099'
      },
      {
        state_cd_full: 'SI-100',
        name: 'Radenci',
        country_id: '705',
        state_cd: '100'
      },
      {
        state_cd_full: 'SI-101',
        name: 'Radlje ob Dravi',
        country_id: '705',
        state_cd: '101'
      },
      {
        state_cd_full: 'SI-102',
        name: 'Radovljica',
        country_id: '705',
        state_cd: '102'
      },
      {
        state_cd_full: 'SI-103',
        name: 'Ravne na Koroskem',
        country_id: '705',
        state_cd: '103'
      },
      {
        state_cd_full: 'SI-104',
        name: 'Ribnica',
        country_id: '705',
        state_cd: '104'
      },
      {
        state_cd_full: 'SI-105',
        name: 'Rogasovci',
        country_id: '705',
        state_cd: '105'
      },
      {
        state_cd_full: 'SI-106',
        name: 'Rogaska Slatina',
        country_id: '705',
        state_cd: '106'
      },
      {
        state_cd_full: 'SI-108',
        name: 'Ruse',
        country_id: '705',
        state_cd: '108'
      },
      {
        state_cd_full: 'SI-109',
        name: 'Semic',
        country_id: '705',
        state_cd: '109'
      },
      {
        state_cd_full: 'SI-110',
        name: 'Sevnica',
        country_id: '705',
        state_cd: '110'
      },
      {
        state_cd_full: 'SI-111',
        name: 'Sezana',
        country_id: '705',
        state_cd: '111'
      },
      {
        state_cd_full: 'SI-112',
        name: 'Slovenj Gradec',
        country_id: '705',
        state_cd: '112'
      },
      {
        state_cd_full: 'SI-113',
        name: 'Slovenska Bistrica',
        country_id: '705',
        state_cd: '113'
      },
      {
        state_cd_full: 'SI-114',
        name: 'Slovenske Konjice',
        country_id: '705',
        state_cd: '114'
      },
      {
        state_cd_full: 'SI-115',
        name: 'Starse',
        country_id: '705',
        state_cd: '115'
      },
      {
        state_cd_full: 'SI-116',
        name: 'Sveti Jurij',
        country_id: '705',
        state_cd: '116'
      },
      {
        state_cd_full: 'SI-117',
        name: 'Sencur',
        country_id: '705',
        state_cd: '117'
      },
      {
        state_cd_full: 'SI-118',
        name: 'Sentilj',
        country_id: '705',
        state_cd: '118'
      },
      {
        state_cd_full: 'SI-119',
        name: 'Sentjernej',
        country_id: '705',
        state_cd: '119'
      },
      {
        state_cd_full: 'SI-120',
        name: 'Sentjur',
        country_id: '705',
        state_cd: '120'
      },
      {
        state_cd_full: 'SI-121',
        name: 'Skocjan',
        country_id: '705',
        state_cd: '121'
      },
      {
        state_cd_full: 'SI-122',
        name: 'Skofja Loka',
        country_id: '705',
        state_cd: '122'
      },
      {
        state_cd_full: 'SI-123',
        name: 'Skofljica',
        country_id: '705',
        state_cd: '123'
      },
      {
        state_cd_full: 'SI-124',
        name: 'Smarje pri Jelsah',
        country_id: '705',
        state_cd: '124'
      },
      {
        state_cd_full: 'SI-125',
        name: 'Smartno ob Paki',
        country_id: '705',
        state_cd: '125'
      },
      {
        state_cd_full: 'SI-126',
        name: 'Sostanj',
        country_id: '705',
        state_cd: '126'
      },
      {
        state_cd_full: 'SI-127',
        name: 'Store',
        country_id: '705',
        state_cd: '127'
      },
      {
        state_cd_full: 'SI-128',
        name: 'Tolmin',
        country_id: '705',
        state_cd: '128'
      },
      {
        state_cd_full: 'SI-129',
        name: 'Trbovlje',
        country_id: '705',
        state_cd: '129'
      },
      {
        state_cd_full: 'SI-130',
        name: 'Trebnje',
        country_id: '705',
        state_cd: '130'
      },
      {
        state_cd_full: 'SI-131',
        name: 'Trzic',
        country_id: '705',
        state_cd: '131'
      },
      {
        state_cd_full: 'SI-132',
        name: 'Turnisce',
        country_id: '705',
        state_cd: '132'
      },
      {
        state_cd_full: 'SI-133',
        name: 'Velenje',
        country_id: '705',
        state_cd: '133'
      },
      {
        state_cd_full: 'SI-134',
        name: 'Velike Lasce',
        country_id: '705',
        state_cd: '134'
      },
      {
        state_cd_full: 'SI-135',
        name: 'Videm',
        country_id: '705',
        state_cd: '135'
      },
      {
        state_cd_full: 'SI-136',
        name: 'Vipava',
        country_id: '705',
        state_cd: '136'
      },
      {
        state_cd_full: 'SI-137',
        name: 'Vitanje',
        country_id: '705',
        state_cd: '137'
      },
      {
        state_cd_full: 'SI-138',
        name: 'Vodice',
        country_id: '705',
        state_cd: '138'
      },
      {
        state_cd_full: 'SI-139',
        name: 'Vojnik',
        country_id: '705',
        state_cd: '139'
      },
      {
        state_cd_full: 'SI-140',
        name: 'Vrhnika',
        country_id: '705',
        state_cd: '140'
      },
      {
        state_cd_full: 'SI-141',
        name: 'Vuzenica',
        country_id: '705',
        state_cd: '141'
      },
      {
        state_cd_full: 'SI-142',
        name: 'Zagorje ob Savi',
        country_id: '705',
        state_cd: '142'
      },
      {
        state_cd_full: 'SI-143',
        name: 'Zavrc',
        country_id: '705',
        state_cd: '143'
      },
      {
        state_cd_full: 'SI-144',
        name: 'Zrece',
        country_id: '705',
        state_cd: '144'
      },
      {
        state_cd_full: 'SI-146',
        name: 'Zelezniki',
        country_id: '705',
        state_cd: '146'
      },
      {
        state_cd_full: 'SI-147',
        name: 'Ziri',
        country_id: '705',
        state_cd: '147'
      },
      {
        state_cd_full: 'SI-148',
        name: 'Benedikt',
        country_id: '705',
        state_cd: '148'
      },
      {
        state_cd_full: 'SI-149',
        name: 'Bistrica ob Sotli',
        country_id: '705',
        state_cd: '149'
      },
      {
        state_cd_full: 'SI-150',
        name: 'Bloke',
        country_id: '705',
        state_cd: '150'
      },
      {
        state_cd_full: 'SI-151',
        name: 'Braslovce',
        country_id: '705',
        state_cd: '151'
      },
      {
        state_cd_full: 'SI-152',
        name: 'Cankova',
        country_id: '705',
        state_cd: '152'
      },
      {
        state_cd_full: 'SI-154',
        name: 'Dobje',
        country_id: '705',
        state_cd: '154'
      },
      {
        state_cd_full: 'SI-155',
        name: 'Dobrna',
        country_id: '705',
        state_cd: '155'
      },
      {
        state_cd_full: 'SI-156',
        name: 'Dobrovnik',
        country_id: '705',
        state_cd: '156'
      },
      {
        state_cd_full: 'SI-158',
        name: 'Grad',
        country_id: '705',
        state_cd: '158'
      },
      {
        state_cd_full: 'SI-159',
        name: 'Hajdina',
        country_id: '705',
        state_cd: '159'
      },
      {
        state_cd_full: 'SI-160',
        name: 'Hoce-Slivnica',
        country_id: '705',
        state_cd: '160'
      },
      {
        state_cd_full: 'SI-161',
        name: 'Hodos',
        country_id: '705',
        state_cd: '161'
      },
      {
        state_cd_full: 'SI-162',
        name: 'Horjul',
        country_id: '705',
        state_cd: '162'
      },
      {
        state_cd_full: 'SI-164',
        name: 'Komenda',
        country_id: '705',
        state_cd: '164'
      },
      {
        state_cd_full: 'SI-165',
        name: 'Kostel',
        country_id: '705',
        state_cd: '165'
      },
      {
        state_cd_full: 'SI-166',
        name: 'Krizevci',
        country_id: '705',
        state_cd: '166'
      },
      {
        state_cd_full: 'SI-167',
        name: 'Lovrenc na Pohorju',
        country_id: '705',
        state_cd: '167'
      },
      {
        state_cd_full: 'SI-168',
        name: 'Markovci',
        country_id: '705',
        state_cd: '168'
      },
      {
        state_cd_full: 'SI-169',
        name: 'Miklavz na Dravskem Polju',
        country_id: '705',
        state_cd: '169'
      },
      {
        state_cd_full: 'SI-170',
        name: 'Mirna Pec',
        country_id: '705',
        state_cd: '170'
      },
      {
        state_cd_full: 'SI-171',
        name: 'Oplotnica',
        country_id: '705',
        state_cd: '171'
      },
      {
        state_cd_full: 'SI-172',
        name: 'Podlehnik',
        country_id: '705',
        state_cd: '172'
      },
      {
        state_cd_full: 'SI-173',
        name: 'Polzela',
        country_id: '705',
        state_cd: '173'
      },
      {
        state_cd_full: 'SI-174',
        name: 'Prebold',
        country_id: '705',
        state_cd: '174'
      },
      {
        state_cd_full: 'SI-175',
        name: 'Prevalje',
        country_id: '705',
        state_cd: '175'
      },
      {
        state_cd_full: 'SI-176',
        name: 'Razkrizje',
        country_id: '705',
        state_cd: '176'
      },
      {
        state_cd_full: 'SI-179',
        name: 'Sodrazica',
        country_id: '705',
        state_cd: '179'
      },
      {
        state_cd_full: 'SI-180',
        name: 'Solcava',
        country_id: '705',
        state_cd: '180'
      },
      {
        state_cd_full: 'SI-182',
        name: 'Sveti Andraz v Slovenskih Goricah',
        country_id: '705',
        state_cd: '182'
      },
      {
        state_cd_full: 'SI-183',
        name: 'Sempeter-Vrtojba',
        country_id: '705',
        state_cd: '183'
      },
      {
        state_cd_full: 'SI-184',
        name: 'Tabor',
        country_id: '705',
        state_cd: '184'
      },
      {
        state_cd_full: 'SI-185',
        name: 'Trnovska Vas',
        country_id: '705',
        state_cd: '185'
      },
      {
        state_cd_full: 'SI-186',
        name: 'Trzin',
        country_id: '705',
        state_cd: '186'
      },
      {
        state_cd_full: 'SI-187',
        name: 'Velika Polana',
        country_id: '705',
        state_cd: '187'
      },
      {
        state_cd_full: 'SI-188',
        name: 'Verzej',
        country_id: '705',
        state_cd: '188'
      },
      {
        state_cd_full: 'SI-189',
        name: 'Vransko',
        country_id: '705',
        state_cd: '189'
      },
      {
        state_cd_full: 'SI-190',
        name: 'Zalec',
        country_id: '705',
        state_cd: '190'
      },
      {
        state_cd_full: 'SI-191',
        name: 'Zetale',
        country_id: '705',
        state_cd: '191'
      },
      {
        state_cd_full: 'SI-193',
        name: 'Zuzemberk',
        country_id: '705',
        state_cd: '193'
      },
      {
        state_cd_full: 'SI-194',
        name: 'Smartno pri Litiji',
        country_id: '705',
        state_cd: '194'
      },
      {
        state_cd_full: 'SI-195',
        name: 'Apace',
        country_id: '705',
        state_cd: '195'
      },
      {
        state_cd_full: 'SI-196',
        name: 'Cirkulane',
        country_id: '705',
        state_cd: '196'
      },
      {
        state_cd_full: 'SI-197',
        name: 'Kosanjevica na Krki',
        country_id: '705',
        state_cd: '197'
      },
      {
        state_cd_full: 'SI-198',
        name: 'Makole',
        country_id: '705',
        state_cd: '198'
      },
      {
        state_cd_full: 'SI-199',
        name: 'Mokronog-Trebelno',
        country_id: '705',
        state_cd: '199'
      },
      {
        state_cd_full: 'SI-200',
        name: 'Poljcane',
        country_id: '705',
        state_cd: '200'
      },
      {
        state_cd_full: 'SI-201',
        name: 'Rence-Vogrsko',
        country_id: '705',
        state_cd: '201'
      },
      {
        state_cd_full: 'SI-203',
        name: 'Straza',
        country_id: '705',
        state_cd: '203'
      },
      {
        state_cd_full: 'SI-205',
        name: 'Sveti Tomaz',
        country_id: '705',
        state_cd: '205'
      },
      {
        state_cd_full: 'SI-206',
        name: 'Smarjeske Toplice',
        country_id: '705',
        state_cd: '206'
      },
      {
        state_cd_full: 'SI-207',
        name: 'Gorje',
        country_id: '705',
        state_cd: '207'
      },
      {
        state_cd_full: 'SI-208',
        name: 'Log-Dragomer',
        country_id: '705',
        state_cd: '208'
      },
      {
        state_cd_full: 'SI-209',
        name: 'Recica ob Savinji',
        country_id: '705',
        state_cd: '209'
      },
      {
        state_cd_full: 'SI-210',
        name: 'Sveti Jurij v Slovenskih Goricah',
        country_id: '705',
        state_cd: '210'
      },
      {
        state_cd_full: 'SI-211',
        name: 'Sentrupert',
        country_id: '705',
        state_cd: '211'
      }
    ]
  },
  {
    country_cd: 'SB',
    name: 'Solomon Islands',
    country_id: 90,
    states: [
      {
        state_cd_full: 'SB-GU',
        name: 'Guadalcanal',
        country_id: '90',
        state_cd: 'GU'
      },
      {
        state_cd_full: 'SB-WE',
        name: 'Western',
        country_id: '90',
        state_cd: 'WE'
      }
    ]
  },
  {
    country_cd: 'SO',
    name: 'Somalia',
    country_id: 706,
    states: [
      {
        state_cd_full: 'SO-BN',
        name: 'Banaadir',
        country_id: '706',
        state_cd: 'BN'
      },
      {
        state_cd_full: 'SO-BY',
        name: 'Bay',
        country_id: '706',
        state_cd: 'BY'
      },
      {
        state_cd_full: 'SO-MU',
        name: 'Mudug',
        country_id: '706',
        state_cd: 'MU'
      },
      {
        state_cd_full: 'SO-NU',
        name: 'Nugaal',
        country_id: '706',
        state_cd: 'NU'
      },
      {
        state_cd_full: 'SO-TO',
        name: 'Togdheer',
        country_id: '706',
        state_cd: 'TO'
      },
      {
        state_cd_full: 'SO-WO',
        name: 'Woqooyi Galbeed',
        country_id: '706',
        state_cd: 'WO'
      }
    ]
  },
  {
    country_cd: 'ZA',
    name: 'South Africa',
    country_id: 710,
    states: [
      {
        state_cd_full: 'ZA-EC',
        name: 'Eastern Cape',
        country_id: '710',
        state_cd: 'EC'
      },
      {
        state_cd_full: 'ZA-FS',
        name: 'Free State',
        country_id: '710',
        state_cd: 'FS'
      },
      {
        state_cd_full: 'ZA-GT',
        name: 'Gauteng',
        country_id: '710',
        state_cd: 'GT'
      },
      {
        state_cd_full: 'ZA-LP',
        name: 'Limpopo',
        country_id: '710',
        state_cd: 'LP'
      },
      {
        state_cd_full: 'ZA-MP',
        name: 'Mpumalanga',
        country_id: '710',
        state_cd: 'MP'
      },
      {
        state_cd_full: 'ZA-NC',
        name: 'Northern Cape',
        country_id: '710',
        state_cd: 'NC'
      },
      {
        state_cd_full: 'ZA-NL',
        name: 'Kwazulu-Natal',
        country_id: '710',
        state_cd: 'NL'
      },
      {
        state_cd_full: 'ZA-NW',
        name: 'North-West',
        country_id: '710',
        state_cd: 'NW'
      },
      {
        state_cd_full: 'ZA-WC',
        name: 'Western Cape',
        country_id: '710',
        state_cd: 'WC'
      }
    ]
  },
  {
    country_cd: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    country_id: 239,
    states: [
      {
        state_cd_full: 'GS-01',
        name: 'South Georgia and the South Sandwich Islands',
        country_id: '239',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'SS',
    name: 'South Sudan',
    country_id: 728,
    states: [
      {
        state_cd_full: 'SS-EC',
        name: 'Central Equatoria',
        country_id: '728',
        state_cd: 'EC'
      },
      {
        state_cd_full: 'SS-EE',
        name: 'Eastern Equatoria',
        country_id: '728',
        state_cd: 'EE'
      },
      {
        state_cd_full: 'SS-EW',
        name: 'Western Equatoria',
        country_id: '728',
        state_cd: 'EW'
      },
      {
        state_cd_full: 'SS-LK',
        name: 'Lakes',
        country_id: '728',
        state_cd: 'LK'
      },
      {
        state_cd_full: 'SS-NU',
        name: 'Upper Nile',
        country_id: '728',
        state_cd: 'NU'
      },
      {
        state_cd_full: 'SS-UY',
        name: 'Unity',
        country_id: '728',
        state_cd: 'UY'
      }
    ]
  },
  {
    country_cd: 'ES',
    name: 'Spain',
    country_id: 724,
    states: [
      {
        state_cd_full: 'ES-AN',
        name: 'Andalucia',
        country_id: '724',
        state_cd: 'AN'
      },
      {
        state_cd_full: 'ES-AR',
        name: 'Aragon',
        country_id: '724',
        state_cd: 'AR'
      },
      {
        state_cd_full: 'ES-AS',
        name: 'Asturias, Principado de',
        country_id: '724',
        state_cd: 'AS'
      },
      {
        state_cd_full: 'ES-CB',
        name: 'Cantabria',
        country_id: '724',
        state_cd: 'CB'
      },
      {
        state_cd_full: 'ES-CE',
        name: 'Ceuta',
        country_id: '724',
        state_cd: 'CE'
      },
      {
        state_cd_full: 'ES-CL',
        name: 'Castilla y Leon',
        country_id: '724',
        state_cd: 'CL'
      },
      {
        state_cd_full: 'ES-CM',
        name: 'Castilla-La Mancha',
        country_id: '724',
        state_cd: 'CM'
      },
      {
        state_cd_full: 'ES-CN',
        name: 'Canarias',
        country_id: '724',
        state_cd: 'CN'
      },
      {
        state_cd_full: 'ES-CT',
        name: 'Catalunya',
        country_id: '724',
        state_cd: 'CT'
      },
      {
        state_cd_full: 'ES-EX',
        name: 'Extremadura',
        country_id: '724',
        state_cd: 'EX'
      },
      {
        state_cd_full: 'ES-GA',
        name: 'Galicia',
        country_id: '724',
        state_cd: 'GA'
      },
      {
        state_cd_full: 'ES-IB',
        name: 'Illes Balears',
        country_id: '724',
        state_cd: 'IB'
      },
      {
        state_cd_full: 'ES-MC',
        name: 'Murcia, Region de',
        country_id: '724',
        state_cd: 'MC'
      },
      {
        state_cd_full: 'ES-MD',
        name: 'Madrid, Comunidad de',
        country_id: '724',
        state_cd: 'MD'
      },
      {
        state_cd_full: 'ES-ML',
        name: 'Melilla',
        country_id: '724',
        state_cd: 'ML'
      },
      {
        state_cd_full: 'ES-NC',
        name: 'Navarra, Comunidad Foral de',
        country_id: '724',
        state_cd: 'NC'
      },
      {
        state_cd_full: 'ES-PV',
        name: 'Pais Vasco',
        country_id: '724',
        state_cd: 'PV'
      },
      {
        state_cd_full: 'ES-RI',
        name: 'La Rioja',
        country_id: '724',
        state_cd: 'RI'
      },
      {
        state_cd_full: 'ES-VC',
        name: 'Valenciana, Comunidad',
        country_id: '724',
        state_cd: 'VC'
      }
    ]
  },
  {
    country_cd: 'LK',
    name: 'Sri Lanka',
    country_id: 144,
    states: [
      {
        state_cd_full: 'LK-1',
        name: 'Western Province',
        country_id: '144',
        state_cd: '1'
      },
      {
        state_cd_full: 'LK-2',
        name: 'Central Province',
        country_id: '144',
        state_cd: '2'
      },
      {
        state_cd_full: 'LK-3',
        name: 'Southern Province',
        country_id: '144',
        state_cd: '3'
      },
      {
        state_cd_full: 'LK-4',
        name: 'Northern Province',
        country_id: '144',
        state_cd: '4'
      },
      {
        state_cd_full: 'LK-5',
        name: 'Eastern Province',
        country_id: '144',
        state_cd: '5'
      },
      {
        state_cd_full: 'LK-6',
        name: 'North Western Province',
        country_id: '144',
        state_cd: '6'
      },
      {
        state_cd_full: 'LK-7',
        name: 'North Central Province',
        country_id: '144',
        state_cd: '7'
      },
      {
        state_cd_full: 'LK-8',
        name: 'Uva Province',
        country_id: '144',
        state_cd: '8'
      },
      {
        state_cd_full: 'LK-9',
        name: 'Sabaragamuwa Province',
        country_id: '144',
        state_cd: '9'
      }
    ]
  },
  {
    country_cd: 'SD',
    name: 'Sudan',
    country_id: 729,
    states: [
      {
        state_cd_full: 'SD-DN',
        name: 'North Darfur',
        country_id: '729',
        state_cd: 'DN'
      },
      {
        state_cd_full: 'SD-DS',
        name: 'South Darfur',
        country_id: '729',
        state_cd: 'DS'
      },
      {
        state_cd_full: 'SD-DW',
        name: 'West Darfur',
        country_id: '729',
        state_cd: 'DW'
      },
      {
        state_cd_full: 'SD-GD',
        name: 'Gedaref',
        country_id: '729',
        state_cd: 'GD'
      },
      {
        state_cd_full: 'SD-GZ',
        name: 'Gezira',
        country_id: '729',
        state_cd: 'GZ'
      },
      {
        state_cd_full: 'SD-KA',
        name: 'Kassala',
        country_id: '729',
        state_cd: 'KA'
      },
      {
        state_cd_full: 'SD-KH',
        name: 'Khartoum',
        country_id: '729',
        state_cd: 'KH'
      },
      {
        state_cd_full: 'SD-KN',
        name: 'North Kordofan',
        country_id: '729',
        state_cd: 'KN'
      },
      {
        state_cd_full: 'SD-KS',
        name: 'South Kordofan',
        country_id: '729',
        state_cd: 'KS'
      },
      {
        state_cd_full: 'SD-NB',
        name: 'Blue Nile',
        country_id: '729',
        state_cd: 'NB'
      },
      {
        state_cd_full: 'SD-NO',
        name: 'Northern',
        country_id: '729',
        state_cd: 'NO'
      },
      {
        state_cd_full: 'SD-NR',
        name: 'River Nile',
        country_id: '729',
        state_cd: 'NR'
      },
      {
        state_cd_full: 'SD-NW',
        name: 'White Nile',
        country_id: '729',
        state_cd: 'NW'
      },
      {
        state_cd_full: 'SD-RS',
        name: 'Red Sea',
        country_id: '729',
        state_cd: 'RS'
      },
      {
        state_cd_full: 'SD-SI',
        name: 'Sennar',
        country_id: '729',
        state_cd: 'SI'
      }
    ]
  },
  {
    country_cd: 'SR',
    name: 'Suriname',
    country_id: 740,
    states: [
      {
        state_cd_full: 'SR-BR',
        name: 'Brokopondo',
        country_id: '740',
        state_cd: 'BR'
      },
      {
        state_cd_full: 'SR-CM',
        name: 'Commewijne',
        country_id: '740',
        state_cd: 'CM'
      },
      {
        state_cd_full: 'SR-NI',
        name: 'Nickerie',
        country_id: '740',
        state_cd: 'NI'
      },
      {
        state_cd_full: 'SR-PM',
        name: 'Paramaribo',
        country_id: '740',
        state_cd: 'PM'
      },
      {
        state_cd_full: 'SR-PR',
        name: 'Para',
        country_id: '740',
        state_cd: 'PR'
      },
      {
        state_cd_full: 'SR-SA',
        name: 'Saramacca',
        country_id: '740',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'SR-SI',
        name: 'Sipaliwini',
        country_id: '740',
        state_cd: 'SI'
      },
      {
        state_cd_full: 'SR-WA',
        name: 'Wanica',
        country_id: '740',
        state_cd: 'WA'
      }
    ]
  },
  {
    country_cd: 'SJ',
    name: 'Svalbard and Jan Mayen',
    country_id: 744,
    states: [
      {
        state_cd_full: 'SJ-01',
        name: 'Svalbard and Jan Mayen',
        country_id: '744',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'SE',
    name: 'Sweden',
    country_id: 752,
    states: [
      {
        state_cd_full: 'SE-AB',
        name: 'Stockholms lan',
        country_id: '752',
        state_cd: 'AB'
      },
      {
        state_cd_full: 'SE-AC',
        name: 'Vasterbottens lan',
        country_id: '752',
        state_cd: 'AC'
      },
      {
        state_cd_full: 'SE-BD',
        name: 'Norrbottens lan',
        country_id: '752',
        state_cd: 'BD'
      },
      {
        state_cd_full: 'SE-C',
        name: 'Uppsala lan',
        country_id: '752',
        state_cd: 'C'
      },
      {
        state_cd_full: 'SE-D',
        name: 'Sodermanlands lan',
        country_id: '752',
        state_cd: 'D'
      },
      {
        state_cd_full: 'SE-E',
        name: 'Ostergotlands lan',
        country_id: '752',
        state_cd: 'E'
      },
      {
        state_cd_full: 'SE-F',
        name: 'Jonkopings lan',
        country_id: '752',
        state_cd: 'F'
      },
      {
        state_cd_full: 'SE-G',
        name: 'Kronobergs lan',
        country_id: '752',
        state_cd: 'G'
      },
      {
        state_cd_full: 'SE-H',
        name: 'Kalmar lan',
        country_id: '752',
        state_cd: 'H'
      },
      {
        state_cd_full: 'SE-I',
        name: 'Gotlands lan',
        country_id: '752',
        state_cd: 'I'
      },
      {
        state_cd_full: 'SE-K',
        name: 'Blekinge lan',
        country_id: '752',
        state_cd: 'K'
      },
      {
        state_cd_full: 'SE-M',
        name: 'Skane lan',
        country_id: '752',
        state_cd: 'M'
      },
      {
        state_cd_full: 'SE-N',
        name: 'Hallands lan',
        country_id: '752',
        state_cd: 'N'
      },
      {
        state_cd_full: 'SE-O',
        name: 'Vastra Gotalands lan',
        country_id: '752',
        state_cd: 'O'
      },
      {
        state_cd_full: 'SE-S',
        name: 'Varmlands lan',
        country_id: '752',
        state_cd: 'S'
      },
      {
        state_cd_full: 'SE-T',
        name: 'Orebro lan',
        country_id: '752',
        state_cd: 'T'
      },
      {
        state_cd_full: 'SE-U',
        name: 'Vastmanlands lan',
        country_id: '752',
        state_cd: 'U'
      },
      {
        state_cd_full: 'SE-W',
        name: 'Dalarnas lan',
        country_id: '752',
        state_cd: 'W'
      },
      {
        state_cd_full: 'SE-X',
        name: 'Gavleborgs lan',
        country_id: '752',
        state_cd: 'X'
      },
      {
        state_cd_full: 'SE-Y',
        name: 'Vasternorrlands lan',
        country_id: '752',
        state_cd: 'Y'
      },
      {
        state_cd_full: 'SE-Z',
        name: 'Jamtlands lan',
        country_id: '752',
        state_cd: 'Z'
      }
    ]
  },
  {
    country_cd: 'CH',
    name: 'Switzerland',
    country_id: 756,
    states: [
      {
        state_cd_full: 'CH-AG',
        name: 'Aargau',
        country_id: '756',
        state_cd: 'AG'
      },
      {
        state_cd_full: 'CH-AI',
        name: 'Appenzell Innerrhoden',
        country_id: '756',
        state_cd: 'AI'
      },
      {
        state_cd_full: 'CH-AR',
        name: 'Appenzell Ausserrhoden',
        country_id: '756',
        state_cd: 'AR'
      },
      {
        state_cd_full: 'CH-BE',
        name: 'Bern',
        country_id: '756',
        state_cd: 'BE'
      },
      {
        state_cd_full: 'CH-BL',
        name: 'Basel-Landschaft',
        country_id: '756',
        state_cd: 'BL'
      },
      {
        state_cd_full: 'CH-BS',
        name: 'Basel-Stadt',
        country_id: '756',
        state_cd: 'BS'
      },
      {
        state_cd_full: 'CH-FR',
        name: 'Fribourg',
        country_id: '756',
        state_cd: 'FR'
      },
      {
        state_cd_full: 'CH-GE',
        name: 'Geneve',
        country_id: '756',
        state_cd: 'GE'
      },
      {
        state_cd_full: 'CH-GL',
        name: 'Glarus',
        country_id: '756',
        state_cd: 'GL'
      },
      {
        state_cd_full: 'CH-GR',
        name: 'Graubunden',
        country_id: '756',
        state_cd: 'GR'
      },
      {
        state_cd_full: 'CH-JU',
        name: 'Jura',
        country_id: '756',
        state_cd: 'JU'
      },
      {
        state_cd_full: 'CH-LU',
        name: 'Luzern',
        country_id: '756',
        state_cd: 'LU'
      },
      {
        state_cd_full: 'CH-NE',
        name: 'Neuchatel',
        country_id: '756',
        state_cd: 'NE'
      },
      {
        state_cd_full: 'CH-NW',
        name: 'Nidwalden',
        country_id: '756',
        state_cd: 'NW'
      },
      {
        state_cd_full: 'CH-OW',
        name: 'Obwalden',
        country_id: '756',
        state_cd: 'OW'
      },
      {
        state_cd_full: 'CH-SG',
        name: 'Sankt Gallen',
        country_id: '756',
        state_cd: 'SG'
      },
      {
        state_cd_full: 'CH-SH',
        name: 'Schaffhausen',
        country_id: '756',
        state_cd: 'SH'
      },
      {
        state_cd_full: 'CH-SO',
        name: 'Solothurn',
        country_id: '756',
        state_cd: 'SO'
      },
      {
        state_cd_full: 'CH-SZ',
        name: 'Schwyz',
        country_id: '756',
        state_cd: 'SZ'
      },
      {
        state_cd_full: 'CH-TG',
        name: 'Thurgau',
        country_id: '756',
        state_cd: 'TG'
      },
      {
        state_cd_full: 'CH-TI',
        name: 'Ticino',
        country_id: '756',
        state_cd: 'TI'
      },
      {
        state_cd_full: 'CH-UR',
        name: 'Uri',
        country_id: '756',
        state_cd: 'UR'
      },
      {
        state_cd_full: 'CH-VD',
        name: 'Vaud',
        country_id: '756',
        state_cd: 'VD'
      },
      {
        state_cd_full: 'CH-VS',
        name: 'Valais',
        country_id: '756',
        state_cd: 'VS'
      },
      {
        state_cd_full: 'CH-ZG',
        name: 'Zug',
        country_id: '756',
        state_cd: 'ZG'
      },
      {
        state_cd_full: 'CH-ZH',
        name: 'Zurich',
        country_id: '756',
        state_cd: 'ZH'
      }
    ]
  },
  {
    country_cd: 'SY',
    name: 'Syrian Arab Republic',
    country_id: 760,
    states: [
      {
        state_cd_full: 'SY-DI',
        name: 'Dimashq',
        country_id: '760',
        state_cd: 'DI'
      },
      {
        state_cd_full: 'SY-DR',
        name: "Dar'a",
        country_id: '760',
        state_cd: 'DR'
      },
      {
        state_cd_full: 'SY-DY',
        name: 'Dayr az Zawr',
        country_id: '760',
        state_cd: 'DY'
      },
      {
        state_cd_full: 'SY-HA',
        name: 'Al Hasakah',
        country_id: '760',
        state_cd: 'HA'
      },
      {
        state_cd_full: 'SY-HI',
        name: 'Hims',
        country_id: '760',
        state_cd: 'HI'
      },
      {
        state_cd_full: 'SY-HL',
        name: 'Halab',
        country_id: '760',
        state_cd: 'HL'
      },
      {
        state_cd_full: 'SY-HM',
        name: 'Hamah',
        country_id: '760',
        state_cd: 'HM'
      },
      {
        state_cd_full: 'SY-ID',
        name: 'Idlib',
        country_id: '760',
        state_cd: 'ID'
      },
      {
        state_cd_full: 'SY-LA',
        name: 'Al Ladhiqiyah',
        country_id: '760',
        state_cd: 'LA'
      },
      {
        state_cd_full: 'SY-QU',
        name: 'Al Qunaytirah',
        country_id: '760',
        state_cd: 'QU'
      },
      {
        state_cd_full: 'SY-RA',
        name: 'Ar Raqqah',
        country_id: '760',
        state_cd: 'RA'
      },
      {
        state_cd_full: 'SY-RD',
        name: 'Rif Dimashq',
        country_id: '760',
        state_cd: 'RD'
      },
      {
        state_cd_full: 'SY-SU',
        name: "As Suwayda'",
        country_id: '760',
        state_cd: 'SU'
      },
      {
        state_cd_full: 'SY-TA',
        name: 'Tartus',
        country_id: '760',
        state_cd: 'TA'
      }
    ]
  },
  {
    country_cd: 'TW',
    name: 'Taiwan, Province of China',
    country_id: 158,
    states: [
      {
        state_cd_full: 'TW-CHA',
        name: 'Changhua',
        country_id: '158',
        state_cd: 'CHA'
      },
      {
        state_cd_full: 'TW-CYQ',
        name: 'Chiayi',
        country_id: '158',
        state_cd: 'CYQ'
      },
      {
        state_cd_full: 'TW-HSQ',
        name: 'Hsinchu',
        country_id: '158',
        state_cd: 'HSQ'
      },
      {
        state_cd_full: 'TW-HUA',
        name: 'Hualien',
        country_id: '158',
        state_cd: 'HUA'
      },
      {
        state_cd_full: 'TW-ILA',
        name: 'Yilan',
        country_id: '158',
        state_cd: 'ILA'
      },
      {
        state_cd_full: 'TW-KEE',
        name: 'Keelung',
        country_id: '158',
        state_cd: 'KEE'
      },
      {
        state_cd_full: 'TW-KHH',
        name: 'Kaohsiung',
        country_id: '158',
        state_cd: 'KHH'
      },
      {
        state_cd_full: 'TW-KIN',
        name: 'Kinmen',
        country_id: '158',
        state_cd: 'KIN'
      },
      {
        state_cd_full: 'TW-LIE',
        name: 'Lienchiang',
        country_id: '158',
        state_cd: 'LIE'
      },
      {
        state_cd_full: 'TW-MIA',
        name: 'Miaoli',
        country_id: '158',
        state_cd: 'MIA'
      },
      {
        state_cd_full: 'TW-NAN',
        name: 'Nantou',
        country_id: '158',
        state_cd: 'NAN'
      },
      {
        state_cd_full: 'TW-NWT',
        name: 'New Taipei',
        country_id: '158',
        state_cd: 'NWT'
      },
      {
        state_cd_full: 'TW-PEN',
        name: 'Penghu',
        country_id: '158',
        state_cd: 'PEN'
      },
      {
        state_cd_full: 'TW-PIF',
        name: 'Pingtung',
        country_id: '158',
        state_cd: 'PIF'
      },
      {
        state_cd_full: 'TW-TAO',
        name: 'Taoyuan',
        country_id: '158',
        state_cd: 'TAO'
      },
      {
        state_cd_full: 'TW-TNN',
        name: 'Tainan',
        country_id: '158',
        state_cd: 'TNN'
      },
      {
        state_cd_full: 'TW-TPE',
        name: 'Taipei',
        country_id: '158',
        state_cd: 'TPE'
      },
      {
        state_cd_full: 'TW-TTT',
        name: 'Taitung',
        country_id: '158',
        state_cd: 'TTT'
      },
      {
        state_cd_full: 'TW-TXG',
        name: 'Taichung',
        country_id: '158',
        state_cd: 'TXG'
      },
      {
        state_cd_full: 'TW-YUN',
        name: 'Yunlin',
        country_id: '158',
        state_cd: 'YUN'
      }
    ]
  },
  {
    country_cd: 'TJ',
    name: 'Tajikistan',
    country_id: 762,
    states: [
      {
        state_cd_full: 'TJ-DU',
        name: 'Dushanbe',
        country_id: '762',
        state_cd: 'DU'
      },
      {
        state_cd_full: 'TJ-GB',
        name: 'Kuhistoni Badakhshon',
        country_id: '762',
        state_cd: 'GB'
      },
      {
        state_cd_full: 'TJ-KT',
        name: 'Khatlon',
        country_id: '762',
        state_cd: 'KT'
      },
      {
        state_cd_full: 'TJ-RA',
        name: 'Nohiyahoi Tobei Jumhuri',
        country_id: '762',
        state_cd: 'RA'
      },
      {
        state_cd_full: 'TJ-SU',
        name: 'Sughd',
        country_id: '762',
        state_cd: 'SU'
      }
    ]
  },
  {
    country_cd: 'TZ',
    name: 'Tanzania, United Republic of',
    country_id: 834,
    states: [
      {
        state_cd_full: 'TZ-01',
        name: 'Arusha',
        country_id: '834',
        state_cd: '01'
      },
      {
        state_cd_full: 'TZ-02',
        name: 'Dar es Salaam',
        country_id: '834',
        state_cd: '02'
      },
      {
        state_cd_full: 'TZ-03',
        name: 'Dodoma',
        country_id: '834',
        state_cd: '03'
      },
      {
        state_cd_full: 'TZ-04',
        name: 'Iringa',
        country_id: '834',
        state_cd: '04'
      },
      {
        state_cd_full: 'TZ-05',
        name: 'Kagera',
        country_id: '834',
        state_cd: '05'
      },
      {
        state_cd_full: 'TZ-07',
        name: 'Kaskazini Unguja',
        country_id: '834',
        state_cd: '07'
      },
      {
        state_cd_full: 'TZ-08',
        name: 'Kigoma',
        country_id: '834',
        state_cd: '08'
      },
      {
        state_cd_full: 'TZ-09',
        name: 'Kilimanjaro',
        country_id: '834',
        state_cd: '09'
      },
      {
        state_cd_full: 'TZ-10',
        name: 'Kusini Pemba',
        country_id: '834',
        state_cd: '10'
      },
      {
        state_cd_full: 'TZ-11',
        name: 'Kusini Unguja',
        country_id: '834',
        state_cd: '11'
      },
      {
        state_cd_full: 'TZ-12',
        name: 'Lindi',
        country_id: '834',
        state_cd: '12'
      },
      {
        state_cd_full: 'TZ-13',
        name: 'Mara',
        country_id: '834',
        state_cd: '13'
      },
      {
        state_cd_full: 'TZ-14',
        name: 'Mbeya',
        country_id: '834',
        state_cd: '14'
      },
      {
        state_cd_full: 'TZ-15',
        name: 'Mjini Magharibi',
        country_id: '834',
        state_cd: '15'
      },
      {
        state_cd_full: 'TZ-16',
        name: 'Morogoro',
        country_id: '834',
        state_cd: '16'
      },
      {
        state_cd_full: 'TZ-17',
        name: 'Mtwara',
        country_id: '834',
        state_cd: '17'
      },
      {
        state_cd_full: 'TZ-18',
        name: 'Mwanza',
        country_id: '834',
        state_cd: '18'
      },
      {
        state_cd_full: 'TZ-19',
        name: 'Pwani',
        country_id: '834',
        state_cd: '19'
      },
      {
        state_cd_full: 'TZ-20',
        name: 'Rukwa',
        country_id: '834',
        state_cd: '20'
      },
      {
        state_cd_full: 'TZ-21',
        name: 'Ruvuma',
        country_id: '834',
        state_cd: '21'
      },
      {
        state_cd_full: 'TZ-22',
        name: 'Shinyanga',
        country_id: '834',
        state_cd: '22'
      },
      {
        state_cd_full: 'TZ-23',
        name: 'Singida',
        country_id: '834',
        state_cd: '23'
      },
      {
        state_cd_full: 'TZ-24',
        name: 'Tabora',
        country_id: '834',
        state_cd: '24'
      },
      {
        state_cd_full: 'TZ-25',
        name: 'Tanga',
        country_id: '834',
        state_cd: '25'
      },
      {
        state_cd_full: 'TZ-26',
        name: 'Manyara',
        country_id: '834',
        state_cd: '26'
      },
      {
        state_cd_full: 'TZ-27',
        name: 'Geita',
        country_id: '834',
        state_cd: '27'
      },
      {
        state_cd_full: 'TZ-28',
        name: 'Katavi',
        country_id: '834',
        state_cd: '28'
      },
      {
        state_cd_full: 'TZ-29',
        name: 'Njombe',
        country_id: '834',
        state_cd: '29'
      },
      {
        state_cd_full: 'TZ-30',
        name: 'Simiyu',
        country_id: '834',
        state_cd: '30'
      }
    ]
  },
  {
    country_cd: 'TH',
    name: 'Thailand',
    country_id: 764,
    states: [
      {
        state_cd_full: 'TH-10',
        name: 'Krung Thep Maha Nakhon',
        country_id: '764',
        state_cd: '10'
      },
      {
        state_cd_full: 'TH-11',
        name: 'Samut Prakan',
        country_id: '764',
        state_cd: '11'
      },
      {
        state_cd_full: 'TH-12',
        name: 'Nonthaburi',
        country_id: '764',
        state_cd: '12'
      },
      {
        state_cd_full: 'TH-13',
        name: 'Pathum Thani',
        country_id: '764',
        state_cd: '13'
      },
      {
        state_cd_full: 'TH-14',
        name: 'Phra Nakhon Si Ayutthaya',
        country_id: '764',
        state_cd: '14'
      },
      {
        state_cd_full: 'TH-15',
        name: 'Ang Thong',
        country_id: '764',
        state_cd: '15'
      },
      {
        state_cd_full: 'TH-16',
        name: 'Lop Buri',
        country_id: '764',
        state_cd: '16'
      },
      {
        state_cd_full: 'TH-17',
        name: 'Sing Buri',
        country_id: '764',
        state_cd: '17'
      },
      {
        state_cd_full: 'TH-18',
        name: 'Chai Nat',
        country_id: '764',
        state_cd: '18'
      },
      {
        state_cd_full: 'TH-19',
        name: 'Saraburi',
        country_id: '764',
        state_cd: '19'
      },
      {
        state_cd_full: 'TH-20',
        name: 'Chon Buri',
        country_id: '764',
        state_cd: '20'
      },
      {
        state_cd_full: 'TH-21',
        name: 'Rayong',
        country_id: '764',
        state_cd: '21'
      },
      {
        state_cd_full: 'TH-22',
        name: 'Chanthaburi',
        country_id: '764',
        state_cd: '22'
      },
      {
        state_cd_full: 'TH-23',
        name: 'Trat',
        country_id: '764',
        state_cd: '23'
      },
      {
        state_cd_full: 'TH-24',
        name: 'Chachoengsao',
        country_id: '764',
        state_cd: '24'
      },
      {
        state_cd_full: 'TH-25',
        name: 'Prachin Buri',
        country_id: '764',
        state_cd: '25'
      },
      {
        state_cd_full: 'TH-26',
        name: 'Nakhon Nayok',
        country_id: '764',
        state_cd: '26'
      },
      {
        state_cd_full: 'TH-27',
        name: 'Sa Kaeo',
        country_id: '764',
        state_cd: '27'
      },
      {
        state_cd_full: 'TH-30',
        name: 'Nakhon Ratchasima',
        country_id: '764',
        state_cd: '30'
      },
      {
        state_cd_full: 'TH-31',
        name: 'Buri Ram',
        country_id: '764',
        state_cd: '31'
      },
      {
        state_cd_full: 'TH-32',
        name: 'Surin',
        country_id: '764',
        state_cd: '32'
      },
      {
        state_cd_full: 'TH-33',
        name: 'Si Sa Ket',
        country_id: '764',
        state_cd: '33'
      },
      {
        state_cd_full: 'TH-34',
        name: 'Ubon Ratchathani',
        country_id: '764',
        state_cd: '34'
      },
      {
        state_cd_full: 'TH-35',
        name: 'Yasothon',
        country_id: '764',
        state_cd: '35'
      },
      {
        state_cd_full: 'TH-36',
        name: 'Chaiyaphum',
        country_id: '764',
        state_cd: '36'
      },
      {
        state_cd_full: 'TH-37',
        name: 'Amnat Charoen',
        country_id: '764',
        state_cd: '37'
      },
      {
        state_cd_full: 'TH-38',
        name: 'Bueng Kan',
        country_id: '764',
        state_cd: '38'
      },
      {
        state_cd_full: 'TH-39',
        name: 'Nong Bua Lam Phu',
        country_id: '764',
        state_cd: '39'
      },
      {
        state_cd_full: 'TH-40',
        name: 'Khon Kaen',
        country_id: '764',
        state_cd: '40'
      },
      {
        state_cd_full: 'TH-41',
        name: 'Udon Thani',
        country_id: '764',
        state_cd: '41'
      },
      {
        state_cd_full: 'TH-42',
        name: 'Loei',
        country_id: '764',
        state_cd: '42'
      },
      {
        state_cd_full: 'TH-43',
        name: 'Nong Khai',
        country_id: '764',
        state_cd: '43'
      },
      {
        state_cd_full: 'TH-44',
        name: 'Maha Sarakham',
        country_id: '764',
        state_cd: '44'
      },
      {
        state_cd_full: 'TH-45',
        name: 'Roi Et',
        country_id: '764',
        state_cd: '45'
      },
      {
        state_cd_full: 'TH-46',
        name: 'Kalasin',
        country_id: '764',
        state_cd: '46'
      },
      {
        state_cd_full: 'TH-47',
        name: 'Sakon Nakhon',
        country_id: '764',
        state_cd: '47'
      },
      {
        state_cd_full: 'TH-48',
        name: 'Nakhon Phanom',
        country_id: '764',
        state_cd: '48'
      },
      {
        state_cd_full: 'TH-49',
        name: 'Mukdahan',
        country_id: '764',
        state_cd: '49'
      },
      {
        state_cd_full: 'TH-50',
        name: 'Chiang Mai',
        country_id: '764',
        state_cd: '50'
      },
      {
        state_cd_full: 'TH-51',
        name: 'Lamphun',
        country_id: '764',
        state_cd: '51'
      },
      {
        state_cd_full: 'TH-52',
        name: 'Lampang',
        country_id: '764',
        state_cd: '52'
      },
      {
        state_cd_full: 'TH-53',
        name: 'Uttaradit',
        country_id: '764',
        state_cd: '53'
      },
      {
        state_cd_full: 'TH-54',
        name: 'Phrae',
        country_id: '764',
        state_cd: '54'
      },
      {
        state_cd_full: 'TH-55',
        name: 'Nan',
        country_id: '764',
        state_cd: '55'
      },
      {
        state_cd_full: 'TH-56',
        name: 'Phayao',
        country_id: '764',
        state_cd: '56'
      },
      {
        state_cd_full: 'TH-57',
        name: 'Chiang Rai',
        country_id: '764',
        state_cd: '57'
      },
      {
        state_cd_full: 'TH-58',
        name: 'Mae Hong Son',
        country_id: '764',
        state_cd: '58'
      },
      {
        state_cd_full: 'TH-60',
        name: 'Nakhon Sawan',
        country_id: '764',
        state_cd: '60'
      },
      {
        state_cd_full: 'TH-61',
        name: 'Uthai Thani',
        country_id: '764',
        state_cd: '61'
      },
      {
        state_cd_full: 'TH-62',
        name: 'Kamphaeng Phet',
        country_id: '764',
        state_cd: '62'
      },
      {
        state_cd_full: 'TH-63',
        name: 'Tak',
        country_id: '764',
        state_cd: '63'
      },
      {
        state_cd_full: 'TH-64',
        name: 'Sukhothai',
        country_id: '764',
        state_cd: '64'
      },
      {
        state_cd_full: 'TH-65',
        name: 'Phitsanulok',
        country_id: '764',
        state_cd: '65'
      },
      {
        state_cd_full: 'TH-66',
        name: 'Phichit',
        country_id: '764',
        state_cd: '66'
      },
      {
        state_cd_full: 'TH-67',
        name: 'Phetchabun',
        country_id: '764',
        state_cd: '67'
      },
      {
        state_cd_full: 'TH-70',
        name: 'Ratchaburi',
        country_id: '764',
        state_cd: '70'
      },
      {
        state_cd_full: 'TH-71',
        name: 'Kanchanaburi',
        country_id: '764',
        state_cd: '71'
      },
      {
        state_cd_full: 'TH-72',
        name: 'Suphan Buri',
        country_id: '764',
        state_cd: '72'
      },
      {
        state_cd_full: 'TH-73',
        name: 'Nakhon Pathom',
        country_id: '764',
        state_cd: '73'
      },
      {
        state_cd_full: 'TH-74',
        name: 'Samut Sakhon',
        country_id: '764',
        state_cd: '74'
      },
      {
        state_cd_full: 'TH-75',
        name: 'Samut Songkhram',
        country_id: '764',
        state_cd: '75'
      },
      {
        state_cd_full: 'TH-76',
        name: 'Phetchaburi',
        country_id: '764',
        state_cd: '76'
      },
      {
        state_cd_full: 'TH-77',
        name: 'Prachuap Khiri Khan',
        country_id: '764',
        state_cd: '77'
      },
      {
        state_cd_full: 'TH-80',
        name: 'Nakhon Si Thammarat',
        country_id: '764',
        state_cd: '80'
      },
      {
        state_cd_full: 'TH-81',
        name: 'Krabi',
        country_id: '764',
        state_cd: '81'
      },
      {
        state_cd_full: 'TH-82',
        name: 'Phangnga',
        country_id: '764',
        state_cd: '82'
      },
      {
        state_cd_full: 'TH-83',
        name: 'Phuket',
        country_id: '764',
        state_cd: '83'
      },
      {
        state_cd_full: 'TH-84',
        name: 'Surat Thani',
        country_id: '764',
        state_cd: '84'
      },
      {
        state_cd_full: 'TH-85',
        name: 'Ranong',
        country_id: '764',
        state_cd: '85'
      },
      {
        state_cd_full: 'TH-86',
        name: 'Chumphon',
        country_id: '764',
        state_cd: '86'
      },
      {
        state_cd_full: 'TH-90',
        name: 'Songkhla',
        country_id: '764',
        state_cd: '90'
      },
      {
        state_cd_full: 'TH-91',
        name: 'Satun',
        country_id: '764',
        state_cd: '91'
      },
      {
        state_cd_full: 'TH-92',
        name: 'Trang',
        country_id: '764',
        state_cd: '92'
      },
      {
        state_cd_full: 'TH-93',
        name: 'Phatthalung',
        country_id: '764',
        state_cd: '93'
      },
      {
        state_cd_full: 'TH-94',
        name: 'Pattani',
        country_id: '764',
        state_cd: '94'
      },
      {
        state_cd_full: 'TH-95',
        name: 'Yala',
        country_id: '764',
        state_cd: '95'
      },
      {
        state_cd_full: 'TH-96',
        name: 'Narathiwat',
        country_id: '764',
        state_cd: '96'
      }
    ]
  },
  {
    country_cd: 'TL',
    name: 'Timor-Leste',
    country_id: 626,
    states: [
      {
        state_cd_full: 'TL-AN',
        name: 'Ainaro',
        country_id: '626',
        state_cd: 'AN'
      },
      {
        state_cd_full: 'TL-CO',
        name: 'Cova Lima',
        country_id: '626',
        state_cd: 'CO'
      },
      {
        state_cd_full: 'TL-DI',
        name: 'Dili',
        country_id: '626',
        state_cd: 'DI'
      }
    ]
  },
  {
    country_cd: 'TG',
    name: 'Togo',
    country_id: 768,
    states: [
      {
        state_cd_full: 'TG-K',
        name: 'Kara',
        country_id: '768',
        state_cd: 'K'
      },
      {
        state_cd_full: 'TG-M',
        name: 'Maritime',
        country_id: '768',
        state_cd: 'M'
      },
      {
        state_cd_full: 'TG-P',
        name: 'Plateaux',
        country_id: '768',
        state_cd: 'P'
      }
    ]
  },
  {
    country_cd: 'TK',
    name: 'Tokelau',
    country_id: 772,
    states: [
      {
        state_cd_full: 'TK-01',
        name: 'Tokelau',
        country_id: '772',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'TO',
    name: 'Tonga',
    country_id: 776,
    states: [
      {
        state_cd_full: 'TO-04',
        name: 'Tongatapu',
        country_id: '776',
        state_cd: '04'
      },
      {
        state_cd_full: 'TO-05',
        name: "Vava'u",
        country_id: '776',
        state_cd: '05'
      }
    ]
  },
  {
    country_cd: 'TT',
    name: 'Trinidad and Tobago',
    country_id: 780,
    states: [
      {
        state_cd_full: 'TT-ARI',
        name: 'Arima',
        country_id: '780',
        state_cd: 'ARI'
      },
      {
        state_cd_full: 'TT-CHA',
        name: 'Chaguanas',
        country_id: '780',
        state_cd: 'CHA'
      },
      {
        state_cd_full: 'TT-CTT',
        name: 'Couva-Tabaquite-Talparo',
        country_id: '780',
        state_cd: 'CTT'
      },
      {
        state_cd_full: 'TT-DMN',
        name: 'Diego Martin',
        country_id: '780',
        state_cd: 'DMN'
      },
      {
        state_cd_full: 'TT-MRC',
        name: 'Mayaro-Rio Claro',
        country_id: '780',
        state_cd: 'MRC'
      },
      {
        state_cd_full: 'TT-PED',
        name: 'Penal-Debe',
        country_id: '780',
        state_cd: 'PED'
      },
      {
        state_cd_full: 'TT-POS',
        name: 'Port of Spain',
        country_id: '780',
        state_cd: 'POS'
      },
      {
        state_cd_full: 'TT-PRT',
        name: 'Princes Town',
        country_id: '780',
        state_cd: 'PRT'
      },
      {
        state_cd_full: 'TT-PTF',
        name: 'Point Fortin',
        country_id: '780',
        state_cd: 'PTF'
      },
      {
        state_cd_full: 'TT-SFO',
        name: 'San Fernando',
        country_id: '780',
        state_cd: 'SFO'
      },
      {
        state_cd_full: 'TT-SGE',
        name: 'Sangre Grande',
        country_id: '780',
        state_cd: 'SGE'
      },
      {
        state_cd_full: 'TT-SIP',
        name: 'Siparia',
        country_id: '780',
        state_cd: 'SIP'
      },
      {
        state_cd_full: 'TT-SJL',
        name: 'San Juan-Laventille',
        country_id: '780',
        state_cd: 'SJL'
      },
      {
        state_cd_full: 'TT-TOB',
        name: 'Tobago',
        country_id: '780',
        state_cd: 'TOB'
      },
      {
        state_cd_full: 'TT-TUP',
        name: 'Tunapuna-Piarco',
        country_id: '780',
        state_cd: 'TUP'
      }
    ]
  },
  {
    country_cd: 'TN',
    name: 'Tunisia',
    country_id: 788,
    states: [
      {
        state_cd_full: 'TN-11',
        name: 'Tunis',
        country_id: '788',
        state_cd: '11'
      },
      {
        state_cd_full: 'TN-12',
        name: "L'Ariana",
        country_id: '788',
        state_cd: '12'
      },
      {
        state_cd_full: 'TN-13',
        name: 'Ben Arous',
        country_id: '788',
        state_cd: '13'
      },
      {
        state_cd_full: 'TN-14',
        name: 'La Manouba',
        country_id: '788',
        state_cd: '14'
      },
      {
        state_cd_full: 'TN-21',
        name: 'Nabeul',
        country_id: '788',
        state_cd: '21'
      },
      {
        state_cd_full: 'TN-22',
        name: 'Zaghouan',
        country_id: '788',
        state_cd: '22'
      },
      {
        state_cd_full: 'TN-23',
        name: 'Bizerte',
        country_id: '788',
        state_cd: '23'
      },
      {
        state_cd_full: 'TN-31',
        name: 'Beja',
        country_id: '788',
        state_cd: '31'
      },
      {
        state_cd_full: 'TN-32',
        name: 'Jendouba',
        country_id: '788',
        state_cd: '32'
      },
      {
        state_cd_full: 'TN-33',
        name: 'Le Kef',
        country_id: '788',
        state_cd: '33'
      },
      {
        state_cd_full: 'TN-34',
        name: 'Siliana',
        country_id: '788',
        state_cd: '34'
      },
      {
        state_cd_full: 'TN-41',
        name: 'Kairouan',
        country_id: '788',
        state_cd: '41'
      },
      {
        state_cd_full: 'TN-42',
        name: 'Kasserine',
        country_id: '788',
        state_cd: '42'
      },
      {
        state_cd_full: 'TN-43',
        name: 'Sidi Bouzid',
        country_id: '788',
        state_cd: '43'
      },
      {
        state_cd_full: 'TN-51',
        name: 'Sousse',
        country_id: '788',
        state_cd: '51'
      },
      {
        state_cd_full: 'TN-52',
        name: 'Monastir',
        country_id: '788',
        state_cd: '52'
      },
      {
        state_cd_full: 'TN-53',
        name: 'Mahdia',
        country_id: '788',
        state_cd: '53'
      },
      {
        state_cd_full: 'TN-61',
        name: 'Sfax',
        country_id: '788',
        state_cd: '61'
      },
      {
        state_cd_full: 'TN-71',
        name: 'Gafsa',
        country_id: '788',
        state_cd: '71'
      },
      {
        state_cd_full: 'TN-72',
        name: 'Tozeur',
        country_id: '788',
        state_cd: '72'
      },
      {
        state_cd_full: 'TN-73',
        name: 'Kebili',
        country_id: '788',
        state_cd: '73'
      },
      {
        state_cd_full: 'TN-81',
        name: 'Gabes',
        country_id: '788',
        state_cd: '81'
      },
      {
        state_cd_full: 'TN-82',
        name: 'Medenine',
        country_id: '788',
        state_cd: '82'
      },
      {
        state_cd_full: 'TN-83',
        name: 'Tataouine',
        country_id: '788',
        state_cd: '83'
      }
    ]
  },
  {
    country_cd: 'TR',
    name: 'Turkey',
    country_id: 792,
    states: [
      {
        state_cd_full: 'TR-01',
        name: 'Adana',
        country_id: '792',
        state_cd: '01'
      },
      {
        state_cd_full: 'TR-02',
        name: 'Adiyaman',
        country_id: '792',
        state_cd: '02'
      },
      {
        state_cd_full: 'TR-03',
        name: 'Afyonkarahisar',
        country_id: '792',
        state_cd: '03'
      },
      {
        state_cd_full: 'TR-04',
        name: 'Agri',
        country_id: '792',
        state_cd: '04'
      },
      {
        state_cd_full: 'TR-05',
        name: 'Amasya',
        country_id: '792',
        state_cd: '05'
      },
      {
        state_cd_full: 'TR-06',
        name: 'Ankara',
        country_id: '792',
        state_cd: '06'
      },
      {
        state_cd_full: 'TR-07',
        name: 'Antalya',
        country_id: '792',
        state_cd: '07'
      },
      {
        state_cd_full: 'TR-08',
        name: 'Artvin',
        country_id: '792',
        state_cd: '08'
      },
      {
        state_cd_full: 'TR-09',
        name: 'Aydin',
        country_id: '792',
        state_cd: '09'
      },
      {
        state_cd_full: 'TR-10',
        name: 'Balikesir',
        country_id: '792',
        state_cd: '10'
      },
      {
        state_cd_full: 'TR-11',
        name: 'Bilecik',
        country_id: '792',
        state_cd: '11'
      },
      {
        state_cd_full: 'TR-12',
        name: 'Bingol',
        country_id: '792',
        state_cd: '12'
      },
      {
        state_cd_full: 'TR-13',
        name: 'Bitlis',
        country_id: '792',
        state_cd: '13'
      },
      {
        state_cd_full: 'TR-14',
        name: 'Bolu',
        country_id: '792',
        state_cd: '14'
      },
      {
        state_cd_full: 'TR-15',
        name: 'Burdur',
        country_id: '792',
        state_cd: '15'
      },
      {
        state_cd_full: 'TR-16',
        name: 'Bursa',
        country_id: '792',
        state_cd: '16'
      },
      {
        state_cd_full: 'TR-17',
        name: 'Canakkale',
        country_id: '792',
        state_cd: '17'
      },
      {
        state_cd_full: 'TR-18',
        name: 'Cankiri',
        country_id: '792',
        state_cd: '18'
      },
      {
        state_cd_full: 'TR-19',
        name: 'Corum',
        country_id: '792',
        state_cd: '19'
      },
      {
        state_cd_full: 'TR-20',
        name: 'Denizli',
        country_id: '792',
        state_cd: '20'
      },
      {
        state_cd_full: 'TR-21',
        name: 'Diyarbakir',
        country_id: '792',
        state_cd: '21'
      },
      {
        state_cd_full: 'TR-22',
        name: 'Edirne',
        country_id: '792',
        state_cd: '22'
      },
      {
        state_cd_full: 'TR-23',
        name: 'Elazig',
        country_id: '792',
        state_cd: '23'
      },
      {
        state_cd_full: 'TR-24',
        name: 'Erzincan',
        country_id: '792',
        state_cd: '24'
      },
      {
        state_cd_full: 'TR-25',
        name: 'Erzurum',
        country_id: '792',
        state_cd: '25'
      },
      {
        state_cd_full: 'TR-26',
        name: 'Eskisehir',
        country_id: '792',
        state_cd: '26'
      },
      {
        state_cd_full: 'TR-27',
        name: 'Gaziantep',
        country_id: '792',
        state_cd: '27'
      },
      {
        state_cd_full: 'TR-28',
        name: 'Giresun',
        country_id: '792',
        state_cd: '28'
      },
      {
        state_cd_full: 'TR-29',
        name: 'Gumushane',
        country_id: '792',
        state_cd: '29'
      },
      {
        state_cd_full: 'TR-30',
        name: 'Hakkari',
        country_id: '792',
        state_cd: '30'
      },
      {
        state_cd_full: 'TR-31',
        name: 'Hatay',
        country_id: '792',
        state_cd: '31'
      },
      {
        state_cd_full: 'TR-32',
        name: 'Isparta',
        country_id: '792',
        state_cd: '32'
      },
      {
        state_cd_full: 'TR-33',
        name: 'Mersin',
        country_id: '792',
        state_cd: '33'
      },
      {
        state_cd_full: 'TR-34',
        name: 'Istanbul',
        country_id: '792',
        state_cd: '34'
      },
      {
        state_cd_full: 'TR-35',
        name: 'Izmir',
        country_id: '792',
        state_cd: '35'
      },
      {
        state_cd_full: 'TR-36',
        name: 'Kars',
        country_id: '792',
        state_cd: '36'
      },
      {
        state_cd_full: 'TR-37',
        name: 'Kastamonu',
        country_id: '792',
        state_cd: '37'
      },
      {
        state_cd_full: 'TR-38',
        name: 'Kayseri',
        country_id: '792',
        state_cd: '38'
      },
      {
        state_cd_full: 'TR-39',
        name: 'Kirklareli',
        country_id: '792',
        state_cd: '39'
      },
      {
        state_cd_full: 'TR-40',
        name: 'Kirsehir',
        country_id: '792',
        state_cd: '40'
      },
      {
        state_cd_full: 'TR-41',
        name: 'Kocaeli',
        country_id: '792',
        state_cd: '41'
      },
      {
        state_cd_full: 'TR-42',
        name: 'Konya',
        country_id: '792',
        state_cd: '42'
      },
      {
        state_cd_full: 'TR-43',
        name: 'Kutahya',
        country_id: '792',
        state_cd: '43'
      },
      {
        state_cd_full: 'TR-44',
        name: 'Malatya',
        country_id: '792',
        state_cd: '44'
      },
      {
        state_cd_full: 'TR-45',
        name: 'Manisa',
        country_id: '792',
        state_cd: '45'
      },
      {
        state_cd_full: 'TR-46',
        name: 'Kahramanmaras',
        country_id: '792',
        state_cd: '46'
      },
      {
        state_cd_full: 'TR-47',
        name: 'Mardin',
        country_id: '792',
        state_cd: '47'
      },
      {
        state_cd_full: 'TR-48',
        name: 'Mugla',
        country_id: '792',
        state_cd: '48'
      },
      {
        state_cd_full: 'TR-49',
        name: 'Mus',
        country_id: '792',
        state_cd: '49'
      },
      {
        state_cd_full: 'TR-50',
        name: 'Nevsehir',
        country_id: '792',
        state_cd: '50'
      },
      {
        state_cd_full: 'TR-51',
        name: 'Nigde',
        country_id: '792',
        state_cd: '51'
      },
      {
        state_cd_full: 'TR-52',
        name: 'Ordu',
        country_id: '792',
        state_cd: '52'
      },
      {
        state_cd_full: 'TR-53',
        name: 'Rize',
        country_id: '792',
        state_cd: '53'
      },
      {
        state_cd_full: 'TR-54',
        name: 'Sakarya',
        country_id: '792',
        state_cd: '54'
      },
      {
        state_cd_full: 'TR-55',
        name: 'Samsun',
        country_id: '792',
        state_cd: '55'
      },
      {
        state_cd_full: 'TR-56',
        name: 'Siirt',
        country_id: '792',
        state_cd: '56'
      },
      {
        state_cd_full: 'TR-57',
        name: 'Sinop',
        country_id: '792',
        state_cd: '57'
      },
      {
        state_cd_full: 'TR-58',
        name: 'Sivas',
        country_id: '792',
        state_cd: '58'
      },
      {
        state_cd_full: 'TR-59',
        name: 'Tekirdag',
        country_id: '792',
        state_cd: '59'
      },
      {
        state_cd_full: 'TR-60',
        name: 'Tokat',
        country_id: '792',
        state_cd: '60'
      },
      {
        state_cd_full: 'TR-61',
        name: 'Trabzon',
        country_id: '792',
        state_cd: '61'
      },
      {
        state_cd_full: 'TR-62',
        name: 'Tunceli',
        country_id: '792',
        state_cd: '62'
      },
      {
        state_cd_full: 'TR-63',
        name: 'Sanliurfa',
        country_id: '792',
        state_cd: '63'
      },
      {
        state_cd_full: 'TR-64',
        name: 'Usak',
        country_id: '792',
        state_cd: '64'
      },
      {
        state_cd_full: 'TR-65',
        name: 'Van',
        country_id: '792',
        state_cd: '65'
      },
      {
        state_cd_full: 'TR-66',
        name: 'Yozgat',
        country_id: '792',
        state_cd: '66'
      },
      {
        state_cd_full: 'TR-67',
        name: 'Zonguldak',
        country_id: '792',
        state_cd: '67'
      },
      {
        state_cd_full: 'TR-68',
        name: 'Aksaray',
        country_id: '792',
        state_cd: '68'
      },
      {
        state_cd_full: 'TR-69',
        name: 'Bayburt',
        country_id: '792',
        state_cd: '69'
      },
      {
        state_cd_full: 'TR-70',
        name: 'Karaman',
        country_id: '792',
        state_cd: '70'
      },
      {
        state_cd_full: 'TR-71',
        name: 'Kirikkale',
        country_id: '792',
        state_cd: '71'
      },
      {
        state_cd_full: 'TR-72',
        name: 'Batman',
        country_id: '792',
        state_cd: '72'
      },
      {
        state_cd_full: 'TR-73',
        name: 'Sirnak',
        country_id: '792',
        state_cd: '73'
      },
      {
        state_cd_full: 'TR-74',
        name: 'Bartin',
        country_id: '792',
        state_cd: '74'
      },
      {
        state_cd_full: 'TR-75',
        name: 'Ardahan',
        country_id: '792',
        state_cd: '75'
      },
      {
        state_cd_full: 'TR-76',
        name: 'Igdir',
        country_id: '792',
        state_cd: '76'
      },
      {
        state_cd_full: 'TR-77',
        name: 'Yalova',
        country_id: '792',
        state_cd: '77'
      },
      {
        state_cd_full: 'TR-78',
        name: 'Karabuk',
        country_id: '792',
        state_cd: '78'
      },
      {
        state_cd_full: 'TR-79',
        name: 'Kilis',
        country_id: '792',
        state_cd: '79'
      },
      {
        state_cd_full: 'TR-80',
        name: 'Osmaniye',
        country_id: '792',
        state_cd: '80'
      },
      {
        state_cd_full: 'TR-81',
        name: 'Duzce',
        country_id: '792',
        state_cd: '81'
      }
    ]
  },
  {
    country_cd: 'TM',
    name: 'Turkmenistan',
    country_id: 795,
    states: [
      {
        state_cd_full: 'TM-A',
        name: 'Ahal',
        country_id: '795',
        state_cd: 'A'
      },
      {
        state_cd_full: 'TM-B',
        name: 'Balkan',
        country_id: '795',
        state_cd: 'B'
      },
      {
        state_cd_full: 'TM-D',
        name: 'Dasoguz',
        country_id: '795',
        state_cd: 'D'
      },
      {
        state_cd_full: 'TM-L',
        name: 'Lebap',
        country_id: '795',
        state_cd: 'L'
      },
      {
        state_cd_full: 'TM-M',
        name: 'Mary',
        country_id: '795',
        state_cd: 'M'
      }
    ]
  },
  {
    country_cd: 'TC',
    name: 'Turks and Caicos Islands',
    country_id: 796,
    states: [
      {
        state_cd_full: 'TC-01',
        name: 'Turks and Caicos Islands',
        country_id: '796',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'TV',
    name: 'Tuvalu',
    country_id: 798,
    states: [
      {
        state_cd_full: 'TV-FUN',
        name: 'Funafuti',
        country_id: '798',
        state_cd: 'FUN'
      }
    ]
  },
  {
    country_cd: 'UG',
    name: 'Uganda',
    country_id: 800,
    states: [
      {
        state_cd_full: 'UG-102',
        name: 'Kampala',
        country_id: '800',
        state_cd: '102'
      },
      {
        state_cd_full: 'UG-104',
        name: 'Luwero',
        country_id: '800',
        state_cd: '104'
      },
      {
        state_cd_full: 'UG-105',
        name: 'Masaka',
        country_id: '800',
        state_cd: '105'
      },
      {
        state_cd_full: 'UG-106',
        name: 'Mpigi',
        country_id: '800',
        state_cd: '106'
      },
      {
        state_cd_full: 'UG-108',
        name: 'Mukono',
        country_id: '800',
        state_cd: '108'
      },
      {
        state_cd_full: 'UG-113',
        name: 'Wakiso',
        country_id: '800',
        state_cd: '113'
      },
      {
        state_cd_full: 'UG-117',
        name: 'Buikwe',
        country_id: '800',
        state_cd: '117'
      },
      {
        state_cd_full: 'UG-120',
        name: 'Buvuma',
        country_id: '800',
        state_cd: '120'
      },
      {
        state_cd_full: 'UG-121',
        name: 'Gomba',
        country_id: '800',
        state_cd: '121'
      },
      {
        state_cd_full: 'UG-204',
        name: 'Jinja',
        country_id: '800',
        state_cd: '204'
      },
      {
        state_cd_full: 'UG-209',
        name: 'Mbale',
        country_id: '800',
        state_cd: '209'
      },
      {
        state_cd_full: 'UG-210',
        name: 'Pallisa',
        country_id: '800',
        state_cd: '210'
      },
      {
        state_cd_full: 'UG-211',
        name: 'Soroti',
        country_id: '800',
        state_cd: '211'
      },
      {
        state_cd_full: 'UG-303',
        name: 'Arua',
        country_id: '800',
        state_cd: '303'
      },
      {
        state_cd_full: 'UG-304',
        name: 'Gulu',
        country_id: '800',
        state_cd: '304'
      },
      {
        state_cd_full: 'UG-307',
        name: 'Lira',
        country_id: '800',
        state_cd: '307'
      },
      {
        state_cd_full: 'UG-309',
        name: 'Moyo',
        country_id: '800',
        state_cd: '309'
      },
      {
        state_cd_full: 'UG-316',
        name: 'Amuru',
        country_id: '800',
        state_cd: '316'
      },
      {
        state_cd_full: 'UG-326',
        name: 'Lamwo',
        country_id: '800',
        state_cd: '326'
      },
      {
        state_cd_full: 'UG-328',
        name: 'Nwoya',
        country_id: '800',
        state_cd: '328'
      },
      {
        state_cd_full: 'UG-404',
        name: 'Kabale',
        country_id: '800',
        state_cd: '404'
      },
      {
        state_cd_full: 'UG-405',
        name: 'Kabarole',
        country_id: '800',
        state_cd: '405'
      },
      {
        state_cd_full: 'UG-406',
        name: 'Kasese',
        country_id: '800',
        state_cd: '406'
      },
      {
        state_cd_full: 'UG-410',
        name: 'Mbarara',
        country_id: '800',
        state_cd: '410'
      },
      {
        state_cd_full: 'UG-415',
        name: 'Kyenjojo',
        country_id: '800',
        state_cd: '415'
      },
      {
        state_cd_full: 'UG-416',
        name: 'Buliisa',
        country_id: '800',
        state_cd: '416'
      }
    ]
  },
  {
    country_cd: 'UA',
    name: 'Ukraine',
    country_id: 804,
    states: [
      {
        state_cd_full: 'UA-05',
        name: 'Vinnytska oblast',
        country_id: '804',
        state_cd: '05'
      },
      {
        state_cd_full: 'UA-07',
        name: 'Volynska oblast',
        country_id: '804',
        state_cd: '07'
      },
      {
        state_cd_full: 'UA-09',
        name: 'Luhanska oblast',
        country_id: '804',
        state_cd: '09'
      },
      {
        state_cd_full: 'UA-12',
        name: 'Dnipropetrovska oblast',
        country_id: '804',
        state_cd: '12'
      },
      {
        state_cd_full: 'UA-14',
        name: 'Donetska oblast',
        country_id: '804',
        state_cd: '14'
      },
      {
        state_cd_full: 'UA-18',
        name: 'Zhytomyrska oblast',
        country_id: '804',
        state_cd: '18'
      },
      {
        state_cd_full: 'UA-21',
        name: 'Zakarpatska oblast',
        country_id: '804',
        state_cd: '21'
      },
      {
        state_cd_full: 'UA-23',
        name: 'Zaporizka oblast',
        country_id: '804',
        state_cd: '23'
      },
      {
        state_cd_full: 'UA-26',
        name: 'Ivano-Frankivska oblast',
        country_id: '804',
        state_cd: '26'
      },
      {
        state_cd_full: 'UA-30',
        name: 'Kyiv',
        country_id: '804',
        state_cd: '30'
      },
      {
        state_cd_full: 'UA-32',
        name: 'Kyivska oblast',
        country_id: '804',
        state_cd: '32'
      },
      {
        state_cd_full: 'UA-35',
        name: 'Kirovohradska oblast',
        country_id: '804',
        state_cd: '35'
      },
      {
        state_cd_full: 'UA-40',
        name: 'Sevastopol',
        country_id: '804',
        state_cd: '40'
      },
      {
        state_cd_full: 'UA-43',
        name: 'Avtonomna Respublika Krym',
        country_id: '804',
        state_cd: '43'
      },
      {
        state_cd_full: 'UA-46',
        name: 'Lvivska oblast',
        country_id: '804',
        state_cd: '46'
      },
      {
        state_cd_full: 'UA-48',
        name: 'Mykolaivska oblast',
        country_id: '804',
        state_cd: '48'
      },
      {
        state_cd_full: 'UA-51',
        name: 'Odeska oblast',
        country_id: '804',
        state_cd: '51'
      },
      {
        state_cd_full: 'UA-53',
        name: 'Poltavska oblast',
        country_id: '804',
        state_cd: '53'
      },
      {
        state_cd_full: 'UA-56',
        name: 'Rivnenska oblast',
        country_id: '804',
        state_cd: '56'
      },
      {
        state_cd_full: 'UA-59',
        name: 'Sumska oblast',
        country_id: '804',
        state_cd: '59'
      },
      {
        state_cd_full: 'UA-61',
        name: 'Ternopilska oblast',
        country_id: '804',
        state_cd: '61'
      },
      {
        state_cd_full: 'UA-63',
        name: 'Kharkivska oblast',
        country_id: '804',
        state_cd: '63'
      },
      {
        state_cd_full: 'UA-65',
        name: 'Khersonska oblast',
        country_id: '804',
        state_cd: '65'
      },
      {
        state_cd_full: 'UA-68',
        name: 'Khmelnytska oblast',
        country_id: '804',
        state_cd: '68'
      },
      {
        state_cd_full: 'UA-71',
        name: 'Cherkaska oblast',
        country_id: '804',
        state_cd: '71'
      },
      {
        state_cd_full: 'UA-74',
        name: 'Chernihivska oblast',
        country_id: '804',
        state_cd: '74'
      },
      {
        state_cd_full: 'UA-77',
        name: 'Chernivetska oblast',
        country_id: '804',
        state_cd: '77'
      }
    ]
  },
  {
    country_cd: 'AE',
    name: 'United Arab Emirates',
    country_id: 784,
    states: [
      {
        state_cd_full: 'AE-AJ',
        name: "'Ajman",
        country_id: '784',
        state_cd: 'AJ'
      },
      {
        state_cd_full: 'AE-AZ',
        name: 'Abu Zaby',
        country_id: '784',
        state_cd: 'AZ'
      },
      {
        state_cd_full: 'AE-DU',
        name: 'Dubayy',
        country_id: '784',
        state_cd: 'DU'
      },
      {
        state_cd_full: 'AE-FU',
        name: 'Al Fujayrah',
        country_id: '784',
        state_cd: 'FU'
      },
      {
        state_cd_full: 'AE-RK',
        name: "Ra's al Khaymah",
        country_id: '784',
        state_cd: 'RK'
      },
      {
        state_cd_full: 'AE-SH',
        name: 'Ash Shariqah',
        country_id: '784',
        state_cd: 'SH'
      },
      {
        state_cd_full: 'AE-UQ',
        name: 'Umm al Qaywayn',
        country_id: '784',
        state_cd: 'UQ'
      }
    ]
  },
  {
    country_cd: 'GB',
    name: 'United Kingdom of Great Britain and Northern Ireland',
    country_id: 826,
    states: [
      {
        state_cd_full: 'GB-ENG',
        name: 'England',
        country_id: '826',
        state_cd: 'ENG'
      },
      {
        state_cd_full: 'GB-NIR',
        name: 'Northern Ireland',
        country_id: '826',
        state_cd: 'NIR'
      },
      {
        state_cd_full: 'GB-SCT',
        name: 'Scotland',
        country_id: '826',
        state_cd: 'SCT'
      },
      {
        state_cd_full: 'GB-WLS',
        name: 'Wales',
        country_id: '826',
        state_cd: 'WLS'
      }
    ]
  },
  {
    country_cd: 'UM',
    name: 'United States Minor Outlying Islands',
    country_id: 581,
    states: [
      {
        state_cd_full: 'UM-95',
        name: 'Palmyra Atoll',
        country_id: '581',
        state_cd: '95'
      }
    ]
  },
  {
    country_cd: 'US',
    name: 'United States',
    country_id: 840,
    states: [
      {
        state_cd_full: 'US-AK',
        name: 'Alaska',
        country_id: '840',
        state_cd: 'AK'
      },
      {
        state_cd_full: 'US-AL',
        name: 'Alabama',
        country_id: '840',
        state_cd: 'AL'
      },
      {
        state_cd_full: 'US-AR',
        name: 'Arkansas',
        country_id: '840',
        state_cd: 'AR'
      },
      {
        state_cd_full: 'US-AZ',
        name: 'Arizona',
        country_id: '840',
        state_cd: 'AZ'
      },
      {
        state_cd_full: 'US-CA',
        name: 'California',
        country_id: '840',
        state_cd: 'CA'
      },
      {
        state_cd_full: 'US-CO',
        name: 'Colorado',
        country_id: '840',
        state_cd: 'CO'
      },
      {
        state_cd_full: 'US-CT',
        name: 'Connecticut',
        country_id: '840',
        state_cd: 'CT'
      },
      {
        state_cd_full: 'US-DC',
        name: 'District of Columbia',
        country_id: '840',
        state_cd: 'DC'
      },
      {
        state_cd_full: 'US-DE',
        name: 'Delaware',
        country_id: '840',
        state_cd: 'DE'
      },
      {
        state_cd_full: 'US-FL',
        name: 'Florida',
        country_id: '840',
        state_cd: 'FL'
      },
      {
        state_cd_full: 'US-GA',
        name: 'Georgia',
        country_id: '840',
        state_cd: 'GA'
      },
      {
        state_cd_full: 'US-HI',
        name: 'Hawaii',
        country_id: '840',
        state_cd: 'HI'
      },
      {
        state_cd_full: 'US-IA',
        name: 'Iowa',
        country_id: '840',
        state_cd: 'IA'
      },
      {
        state_cd_full: 'US-ID',
        name: 'Idaho',
        country_id: '840',
        state_cd: 'ID'
      },
      {
        state_cd_full: 'US-IL',
        name: 'Illinois',
        country_id: '840',
        state_cd: 'IL'
      },
      {
        state_cd_full: 'US-IN',
        name: 'Indiana',
        country_id: '840',
        state_cd: 'IN'
      },
      {
        state_cd_full: 'US-KS',
        name: 'Kansas',
        country_id: '840',
        state_cd: 'KS'
      },
      {
        state_cd_full: 'US-KY',
        name: 'Kentucky',
        country_id: '840',
        state_cd: 'KY'
      },
      {
        state_cd_full: 'US-LA',
        name: 'Louisiana',
        country_id: '840',
        state_cd: 'LA'
      },
      {
        state_cd_full: 'US-MA',
        name: 'Massachusetts',
        country_id: '840',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'US-MD',
        name: 'Maryland',
        country_id: '840',
        state_cd: 'MD'
      },
      {
        state_cd_full: 'US-ME',
        name: 'Maine',
        country_id: '840',
        state_cd: 'ME'
      },
      {
        state_cd_full: 'US-MI',
        name: 'Michigan',
        country_id: '840',
        state_cd: 'MI'
      },
      {
        state_cd_full: 'US-MN',
        name: 'Minnesota',
        country_id: '840',
        state_cd: 'MN'
      },
      {
        state_cd_full: 'US-MO',
        name: 'Missouri',
        country_id: '840',
        state_cd: 'MO'
      },
      {
        state_cd_full: 'US-MS',
        name: 'Mississippi',
        country_id: '840',
        state_cd: 'MS'
      },
      {
        state_cd_full: 'US-MT',
        name: 'Montana',
        country_id: '840',
        state_cd: 'MT'
      },
      {
        state_cd_full: 'US-NC',
        name: 'North Carolina',
        country_id: '840',
        state_cd: 'NC'
      },
      {
        state_cd_full: 'US-ND',
        name: 'North Dakota',
        country_id: '840',
        state_cd: 'ND'
      },
      {
        state_cd_full: 'US-NE',
        name: 'Nebraska',
        country_id: '840',
        state_cd: 'NE'
      },
      {
        state_cd_full: 'US-NH',
        name: 'New Hampshire',
        country_id: '840',
        state_cd: 'NH'
      },
      {
        state_cd_full: 'US-NJ',
        name: 'New Jersey',
        country_id: '840',
        state_cd: 'NJ'
      },
      {
        state_cd_full: 'US-NM',
        name: 'New Mexico',
        country_id: '840',
        state_cd: 'NM'
      },
      {
        state_cd_full: 'US-NV',
        name: 'Nevada',
        country_id: '840',
        state_cd: 'NV'
      },
      {
        state_cd_full: 'US-NY',
        name: 'New York',
        country_id: '840',
        state_cd: 'NY'
      },
      {
        state_cd_full: 'US-OH',
        name: 'Ohio',
        country_id: '840',
        state_cd: 'OH'
      },
      {
        state_cd_full: 'US-OK',
        name: 'Oklahoma',
        country_id: '840',
        state_cd: 'OK'
      },
      {
        state_cd_full: 'US-OR',
        name: 'Oregon',
        country_id: '840',
        state_cd: 'OR'
      },
      {
        state_cd_full: 'US-PA',
        name: 'Pennsylvania',
        country_id: '840',
        state_cd: 'PA'
      },
      {
        state_cd_full: 'US-RI',
        name: 'Rhode Island',
        country_id: '840',
        state_cd: 'RI'
      },
      {
        state_cd_full: 'US-SC',
        name: 'South Carolina',
        country_id: '840',
        state_cd: 'SC'
      },
      {
        state_cd_full: 'US-SD',
        name: 'South Dakota',
        country_id: '840',
        state_cd: 'SD'
      },
      {
        state_cd_full: 'US-TN',
        name: 'Tennessee',
        country_id: '840',
        state_cd: 'TN'
      },
      {
        state_cd_full: 'US-TX',
        name: 'Texas',
        country_id: '840',
        state_cd: 'TX'
      },
      {
        state_cd_full: 'US-UT',
        name: 'Utah',
        country_id: '840',
        state_cd: 'UT'
      },
      {
        state_cd_full: 'US-VA',
        name: 'Virginia',
        country_id: '840',
        state_cd: 'VA'
      },
      {
        state_cd_full: 'US-VT',
        name: 'Vermont',
        country_id: '840',
        state_cd: 'VT'
      },
      {
        state_cd_full: 'US-WA',
        name: 'Washington',
        country_id: '840',
        state_cd: 'WA'
      },
      {
        state_cd_full: 'US-WI',
        name: 'Wisconsin',
        country_id: '840',
        state_cd: 'WI'
      },
      {
        state_cd_full: 'US-WV',
        name: 'West Virginia',
        country_id: '840',
        state_cd: 'WV'
      },
      {
        state_cd_full: 'US-WY',
        name: 'Wyoming',
        country_id: '840',
        state_cd: 'WY'
      }
    ]
  },
  {
    country_cd: 'UY',
    name: 'Uruguay',
    country_id: 858,
    states: [
      {
        state_cd_full: 'UY-AR',
        name: 'Artigas',
        country_id: '858',
        state_cd: 'AR'
      },
      {
        state_cd_full: 'UY-CA',
        name: 'Canelones',
        country_id: '858',
        state_cd: 'CA'
      },
      {
        state_cd_full: 'UY-CL',
        name: 'Cerro Largo',
        country_id: '858',
        state_cd: 'CL'
      },
      {
        state_cd_full: 'UY-CO',
        name: 'Colonia',
        country_id: '858',
        state_cd: 'CO'
      },
      {
        state_cd_full: 'UY-DU',
        name: 'Durazno',
        country_id: '858',
        state_cd: 'DU'
      },
      {
        state_cd_full: 'UY-FD',
        name: 'Florida',
        country_id: '858',
        state_cd: 'FD'
      },
      {
        state_cd_full: 'UY-FS',
        name: 'Flores',
        country_id: '858',
        state_cd: 'FS'
      },
      {
        state_cd_full: 'UY-LA',
        name: 'Lavalleja',
        country_id: '858',
        state_cd: 'LA'
      },
      {
        state_cd_full: 'UY-MA',
        name: 'Maldonado',
        country_id: '858',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'UY-MO',
        name: 'Montevideo',
        country_id: '858',
        state_cd: 'MO'
      },
      {
        state_cd_full: 'UY-PA',
        name: 'Paysandu',
        country_id: '858',
        state_cd: 'PA'
      },
      {
        state_cd_full: 'UY-RN',
        name: 'Rio Negro',
        country_id: '858',
        state_cd: 'RN'
      },
      {
        state_cd_full: 'UY-RO',
        name: 'Rocha',
        country_id: '858',
        state_cd: 'RO'
      },
      {
        state_cd_full: 'UY-RV',
        name: 'Rivera',
        country_id: '858',
        state_cd: 'RV'
      },
      {
        state_cd_full: 'UY-SA',
        name: 'Salto',
        country_id: '858',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'UY-SJ',
        name: 'San Jose',
        country_id: '858',
        state_cd: 'SJ'
      },
      {
        state_cd_full: 'UY-SO',
        name: 'Soriano',
        country_id: '858',
        state_cd: 'SO'
      },
      {
        state_cd_full: 'UY-TA',
        name: 'Tacuarembo',
        country_id: '858',
        state_cd: 'TA'
      },
      {
        state_cd_full: 'UY-TT',
        name: 'Treinta y Tres',
        country_id: '858',
        state_cd: 'TT'
      }
    ]
  },
  {
    country_cd: 'UZ',
    name: 'Uzbekistan',
    country_id: 860,
    states: [
      {
        state_cd_full: 'UZ-AN',
        name: 'Andijon',
        country_id: '860',
        state_cd: 'AN'
      },
      {
        state_cd_full: 'UZ-BU',
        name: 'Buxoro',
        country_id: '860',
        state_cd: 'BU'
      },
      {
        state_cd_full: 'UZ-FA',
        name: "Farg'ona",
        country_id: '860',
        state_cd: 'FA'
      },
      {
        state_cd_full: 'UZ-JI',
        name: 'Jizzax',
        country_id: '860',
        state_cd: 'JI'
      },
      {
        state_cd_full: 'UZ-NG',
        name: 'Namangan',
        country_id: '860',
        state_cd: 'NG'
      },
      {
        state_cd_full: 'UZ-NW',
        name: 'Navoiy',
        country_id: '860',
        state_cd: 'NW'
      },
      {
        state_cd_full: 'UZ-QA',
        name: 'Qashqadaryo',
        country_id: '860',
        state_cd: 'QA'
      },
      {
        state_cd_full: 'UZ-QR',
        name: "Qoraqalpog'iston Respublikasi",
        country_id: '860',
        state_cd: 'QR'
      },
      {
        state_cd_full: 'UZ-SA',
        name: 'Samarqand',
        country_id: '860',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'UZ-SU',
        name: 'Surxondaryo',
        country_id: '860',
        state_cd: 'SU'
      },
      {
        state_cd_full: 'UZ-TK',
        name: 'Toshkent',
        country_id: '860',
        state_cd: 'TK'
      },
      {
        state_cd_full: 'UZ-XO',
        name: 'Xorazm',
        country_id: '860',
        state_cd: 'XO'
      }
    ]
  },
  {
    country_cd: 'VU',
    name: 'Vanuatu',
    country_id: 548,
    states: [
      {
        state_cd_full: 'VU-SAM',
        name: 'Sanma',
        country_id: '548',
        state_cd: 'SAM'
      },
      {
        state_cd_full: 'VU-SEE',
        name: 'Shefa',
        country_id: '548',
        state_cd: 'SEE'
      },
      {
        state_cd_full: 'VU-TAE',
        name: 'Tafea',
        country_id: '548',
        state_cd: 'TAE'
      }
    ]
  },
  {
    country_cd: 'VE',
    name: 'Venezuela (Bolivarian Republic of)',
    country_id: 862,
    states: [
      {
        state_cd_full: 'VE-A',
        name: 'Distrito Capital',
        country_id: '862',
        state_cd: 'A'
      },
      {
        state_cd_full: 'VE-B',
        name: 'Anzoategui',
        country_id: '862',
        state_cd: 'B'
      },
      {
        state_cd_full: 'VE-C',
        name: 'Apure',
        country_id: '862',
        state_cd: 'C'
      },
      {
        state_cd_full: 'VE-D',
        name: 'Aragua',
        country_id: '862',
        state_cd: 'D'
      },
      {
        state_cd_full: 'VE-E',
        name: 'Barinas',
        country_id: '862',
        state_cd: 'E'
      },
      {
        state_cd_full: 'VE-F',
        name: 'Bolivar',
        country_id: '862',
        state_cd: 'F'
      },
      {
        state_cd_full: 'VE-G',
        name: 'Carabobo',
        country_id: '862',
        state_cd: 'G'
      },
      {
        state_cd_full: 'VE-H',
        name: 'Cojedes',
        country_id: '862',
        state_cd: 'H'
      },
      {
        state_cd_full: 'VE-I',
        name: 'Falcon',
        country_id: '862',
        state_cd: 'I'
      },
      {
        state_cd_full: 'VE-J',
        name: 'Guarico',
        country_id: '862',
        state_cd: 'J'
      },
      {
        state_cd_full: 'VE-K',
        name: 'Lara',
        country_id: '862',
        state_cd: 'K'
      },
      {
        state_cd_full: 'VE-L',
        name: 'Merida',
        country_id: '862',
        state_cd: 'L'
      },
      {
        state_cd_full: 'VE-M',
        name: 'Miranda',
        country_id: '862',
        state_cd: 'M'
      },
      {
        state_cd_full: 'VE-N',
        name: 'Monagas',
        country_id: '862',
        state_cd: 'N'
      },
      {
        state_cd_full: 'VE-O',
        name: 'Nueva Esparta',
        country_id: '862',
        state_cd: 'O'
      },
      {
        state_cd_full: 'VE-P',
        name: 'Portuguesa',
        country_id: '862',
        state_cd: 'P'
      },
      {
        state_cd_full: 'VE-R',
        name: 'Sucre',
        country_id: '862',
        state_cd: 'R'
      },
      {
        state_cd_full: 'VE-S',
        name: 'Tachira',
        country_id: '862',
        state_cd: 'S'
      },
      {
        state_cd_full: 'VE-T',
        name: 'Trujillo',
        country_id: '862',
        state_cd: 'T'
      },
      {
        state_cd_full: 'VE-U',
        name: 'Yaracuy',
        country_id: '862',
        state_cd: 'U'
      },
      {
        state_cd_full: 'VE-V',
        name: 'Zulia',
        country_id: '862',
        state_cd: 'V'
      },
      {
        state_cd_full: 'VE-X',
        name: 'Vargas',
        country_id: '862',
        state_cd: 'X'
      },
      {
        state_cd_full: 'VE-Y',
        name: 'Delta Amacuro',
        country_id: '862',
        state_cd: 'Y'
      },
      {
        state_cd_full: 'VE-Z',
        name: 'Amazonas',
        country_id: '862',
        state_cd: 'Z'
      }
    ]
  },
  {
    country_cd: 'VN',
    name: 'Viet Nam',
    country_id: 704,
    states: [
      {
        state_cd_full: 'VN-01',
        name: 'Lai Chau',
        country_id: '704',
        state_cd: '01'
      },
      {
        state_cd_full: 'VN-02',
        name: 'Lao Cai',
        country_id: '704',
        state_cd: '02'
      },
      {
        state_cd_full: 'VN-03',
        name: 'Ha Giang',
        country_id: '704',
        state_cd: '03'
      },
      {
        state_cd_full: 'VN-04',
        name: 'Cao Bang',
        country_id: '704',
        state_cd: '04'
      },
      {
        state_cd_full: 'VN-05',
        name: 'Son La',
        country_id: '704',
        state_cd: '05'
      },
      {
        state_cd_full: 'VN-06',
        name: 'Yen Bai',
        country_id: '704',
        state_cd: '06'
      },
      {
        state_cd_full: 'VN-07',
        name: 'Tuyen Quang',
        country_id: '704',
        state_cd: '07'
      },
      {
        state_cd_full: 'VN-09',
        name: 'Lang Son',
        country_id: '704',
        state_cd: '09'
      },
      {
        state_cd_full: 'VN-13',
        name: 'Quang Ninh',
        country_id: '704',
        state_cd: '13'
      },
      {
        state_cd_full: 'VN-14',
        name: 'Hoa Binh',
        country_id: '704',
        state_cd: '14'
      },
      {
        state_cd_full: 'VN-18',
        name: 'Ninh Binh',
        country_id: '704',
        state_cd: '18'
      },
      {
        state_cd_full: 'VN-20',
        name: 'Thai Binh',
        country_id: '704',
        state_cd: '20'
      },
      {
        state_cd_full: 'VN-21',
        name: 'Thanh Hoa',
        country_id: '704',
        state_cd: '21'
      },
      {
        state_cd_full: 'VN-22',
        name: 'Nghe An',
        country_id: '704',
        state_cd: '22'
      },
      {
        state_cd_full: 'VN-23',
        name: 'Ha Tinh',
        country_id: '704',
        state_cd: '23'
      },
      {
        state_cd_full: 'VN-24',
        name: 'Quang Binh',
        country_id: '704',
        state_cd: '24'
      },
      {
        state_cd_full: 'VN-25',
        name: 'Quang Tri',
        country_id: '704',
        state_cd: '25'
      },
      {
        state_cd_full: 'VN-26',
        name: 'Thua Thien-Hue',
        country_id: '704',
        state_cd: '26'
      },
      {
        state_cd_full: 'VN-27',
        name: 'Quang Nam',
        country_id: '704',
        state_cd: '27'
      },
      {
        state_cd_full: 'VN-28',
        name: 'Kon Tum',
        country_id: '704',
        state_cd: '28'
      },
      {
        state_cd_full: 'VN-29',
        name: 'Quang Ngai',
        country_id: '704',
        state_cd: '29'
      },
      {
        state_cd_full: 'VN-30',
        name: 'Gia Lai',
        country_id: '704',
        state_cd: '30'
      },
      {
        state_cd_full: 'VN-31',
        name: 'Binh Dinh',
        country_id: '704',
        state_cd: '31'
      },
      {
        state_cd_full: 'VN-32',
        name: 'Phu Yen',
        country_id: '704',
        state_cd: '32'
      },
      {
        state_cd_full: 'VN-33',
        name: 'Dak Lak',
        country_id: '704',
        state_cd: '33'
      },
      {
        state_cd_full: 'VN-34',
        name: 'Khanh Hoa',
        country_id: '704',
        state_cd: '34'
      },
      {
        state_cd_full: 'VN-35',
        name: 'Lam Dong',
        country_id: '704',
        state_cd: '35'
      },
      {
        state_cd_full: 'VN-36',
        name: 'Ninh Thuan',
        country_id: '704',
        state_cd: '36'
      },
      {
        state_cd_full: 'VN-37',
        name: 'Tay Ninh',
        country_id: '704',
        state_cd: '37'
      },
      {
        state_cd_full: 'VN-39',
        name: 'Dong Nai',
        country_id: '704',
        state_cd: '39'
      },
      {
        state_cd_full: 'VN-40',
        name: 'Binh Thuan',
        country_id: '704',
        state_cd: '40'
      },
      {
        state_cd_full: 'VN-41',
        name: 'Long An',
        country_id: '704',
        state_cd: '41'
      },
      {
        state_cd_full: 'VN-43',
        name: 'Ba Ria - Vung Tau',
        country_id: '704',
        state_cd: '43'
      },
      {
        state_cd_full: 'VN-44',
        name: 'An Giang',
        country_id: '704',
        state_cd: '44'
      },
      {
        state_cd_full: 'VN-45',
        name: 'Dong Thap',
        country_id: '704',
        state_cd: '45'
      },
      {
        state_cd_full: 'VN-46',
        name: 'Tien Giang',
        country_id: '704',
        state_cd: '46'
      },
      {
        state_cd_full: 'VN-47',
        name: 'Kien Giang',
        country_id: '704',
        state_cd: '47'
      },
      {
        state_cd_full: 'VN-49',
        name: 'Vinh Long',
        country_id: '704',
        state_cd: '49'
      },
      {
        state_cd_full: 'VN-50',
        name: 'Ben Tre',
        country_id: '704',
        state_cd: '50'
      },
      {
        state_cd_full: 'VN-51',
        name: 'Tra Vinh',
        country_id: '704',
        state_cd: '51'
      },
      {
        state_cd_full: 'VN-52',
        name: 'Soc Trang',
        country_id: '704',
        state_cd: '52'
      },
      {
        state_cd_full: 'VN-53',
        name: 'Bac Kan',
        country_id: '704',
        state_cd: '53'
      },
      {
        state_cd_full: 'VN-54',
        name: 'Bac Giang',
        country_id: '704',
        state_cd: '54'
      },
      {
        state_cd_full: 'VN-55',
        name: 'Bac Lieu',
        country_id: '704',
        state_cd: '55'
      },
      {
        state_cd_full: 'VN-56',
        name: 'Bac Ninh',
        country_id: '704',
        state_cd: '56'
      },
      {
        state_cd_full: 'VN-57',
        name: 'Binh Duong',
        country_id: '704',
        state_cd: '57'
      },
      {
        state_cd_full: 'VN-58',
        name: 'Binh Phuoc',
        country_id: '704',
        state_cd: '58'
      },
      {
        state_cd_full: 'VN-59',
        name: 'Ca Mau',
        country_id: '704',
        state_cd: '59'
      },
      {
        state_cd_full: 'VN-61',
        name: 'Hai Duong',
        country_id: '704',
        state_cd: '61'
      },
      {
        state_cd_full: 'VN-63',
        name: 'Ha Nam',
        country_id: '704',
        state_cd: '63'
      },
      {
        state_cd_full: 'VN-66',
        name: 'Hung Yen',
        country_id: '704',
        state_cd: '66'
      },
      {
        state_cd_full: 'VN-67',
        name: 'Nam Dinh',
        country_id: '704',
        state_cd: '67'
      },
      {
        state_cd_full: 'VN-68',
        name: 'Phu Tho',
        country_id: '704',
        state_cd: '68'
      },
      {
        state_cd_full: 'VN-69',
        name: 'Thai Nguyen',
        country_id: '704',
        state_cd: '69'
      },
      {
        state_cd_full: 'VN-70',
        name: 'Vinh Phuc',
        country_id: '704',
        state_cd: '70'
      },
      {
        state_cd_full: 'VN-71',
        name: 'Dien Bien',
        country_id: '704',
        state_cd: '71'
      },
      {
        state_cd_full: 'VN-72',
        name: 'Dak Nong',
        country_id: '704',
        state_cd: '72'
      },
      {
        state_cd_full: 'VN-CT',
        name: 'Can Tho',
        country_id: '704',
        state_cd: 'CT'
      },
      {
        state_cd_full: 'VN-DN',
        name: 'Da Nang',
        country_id: '704',
        state_cd: 'DN'
      },
      {
        state_cd_full: 'VN-HN',
        name: 'Ha Noi',
        country_id: '704',
        state_cd: 'HN'
      },
      {
        state_cd_full: 'VN-HP',
        name: 'Hai Phong',
        country_id: '704',
        state_cd: 'HP'
      },
      {
        state_cd_full: 'VN-SG',
        name: 'Ho Chi Minh',
        country_id: '704',
        state_cd: 'SG'
      }
    ]
  },
  {
    country_cd: 'VG',
    name: 'Virgin Islands (British)',
    country_id: 92,
    states: [
      {
        state_cd_full: 'VG-01',
        name: 'Virgin Islands, British',
        country_id: '92',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'VI',
    name: 'Virgin Islands (U.S.)',
    country_id: 850,
    states: [
      {
        state_cd_full: 'VI-01',
        name: 'Virgin Islands, U.S.',
        country_id: '850',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'WF',
    name: 'Wallis and Futuna',
    country_id: 876,
    states: [
      {
        state_cd_full: 'WF-SG',
        name: 'Sigave',
        country_id: '876',
        state_cd: 'SG'
      },
      {
        state_cd_full: 'WF-UV',
        name: 'Uvea',
        country_id: '876',
        state_cd: 'UV'
      }
    ]
  },
  {
    country_cd: 'EH',
    name: 'Western Sahara',
    country_id: 732,
    states: [
      {
        state_cd_full: 'EH-01',
        name: 'Western Sahara',
        country_id: '732',
        state_cd: '01'
      }
    ]
  },
  {
    country_cd: 'YE',
    name: 'Yemen',
    country_id: 887,
    states: [
      {
        state_cd_full: 'YE-AD',
        name: "'Adan",
        country_id: '887',
        state_cd: 'AD'
      },
      {
        state_cd_full: 'YE-AM',
        name: "'Amran",
        country_id: '887',
        state_cd: 'AM'
      },
      {
        state_cd_full: 'YE-DH',
        name: 'Dhamar',
        country_id: '887',
        state_cd: 'DH'
      },
      {
        state_cd_full: 'YE-HD',
        name: 'Hadramawt',
        country_id: '887',
        state_cd: 'HD'
      },
      {
        state_cd_full: 'YE-HU',
        name: 'Al Hudaydah',
        country_id: '887',
        state_cd: 'HU'
      },
      {
        state_cd_full: 'YE-SA',
        name: "Amanat al 'Asimah",
        country_id: '887',
        state_cd: 'SA'
      },
      {
        state_cd_full: 'YE-SH',
        name: 'Shabwah',
        country_id: '887',
        state_cd: 'SH'
      }
    ]
  },
  {
    country_cd: 'ZM',
    name: 'Zambia',
    country_id: 894,
    states: [
      {
        state_cd_full: 'ZM-01',
        name: 'Western',
        country_id: '894',
        state_cd: '01'
      },
      {
        state_cd_full: 'ZM-02',
        name: 'Central',
        country_id: '894',
        state_cd: '02'
      },
      {
        state_cd_full: 'ZM-03',
        name: 'Eastern',
        country_id: '894',
        state_cd: '03'
      },
      {
        state_cd_full: 'ZM-04',
        name: 'Luapula',
        country_id: '894',
        state_cd: '04'
      },
      {
        state_cd_full: 'ZM-05',
        name: 'Northern',
        country_id: '894',
        state_cd: '05'
      },
      {
        state_cd_full: 'ZM-06',
        name: 'North-Western',
        country_id: '894',
        state_cd: '06'
      },
      {
        state_cd_full: 'ZM-07',
        name: 'Southern',
        country_id: '894',
        state_cd: '07'
      },
      {
        state_cd_full: 'ZM-08',
        name: 'Copperbelt',
        country_id: '894',
        state_cd: '08'
      },
      {
        state_cd_full: 'ZM-09',
        name: 'Lusaka',
        country_id: '894',
        state_cd: '09'
      }
    ]
  },
  {
    country_cd: 'ZW',
    name: 'Zimbabwe',
    country_id: 716,
    states: [
      {
        state_cd_full: 'ZW-BU',
        name: 'Bulawayo',
        country_id: '716',
        state_cd: 'BU'
      },
      {
        state_cd_full: 'ZW-HA',
        name: 'Harare',
        country_id: '716',
        state_cd: 'HA'
      },
      {
        state_cd_full: 'ZW-MA',
        name: 'Manicaland',
        country_id: '716',
        state_cd: 'MA'
      },
      {
        state_cd_full: 'ZW-MC',
        name: 'Mashonaland Central',
        country_id: '716',
        state_cd: 'MC'
      },
      {
        state_cd_full: 'ZW-ME',
        name: 'Mashonaland East',
        country_id: '716',
        state_cd: 'ME'
      },
      {
        state_cd_full: 'ZW-MI',
        name: 'Midlands',
        country_id: '716',
        state_cd: 'MI'
      },
      {
        state_cd_full: 'ZW-MN',
        name: 'Matabeleland North',
        country_id: '716',
        state_cd: 'MN'
      },
      {
        state_cd_full: 'ZW-MS',
        name: 'Matabeleland South',
        country_id: '716',
        state_cd: 'MS'
      },
      {
        state_cd_full: 'ZW-MV',
        name: 'Masvingo',
        country_id: '716',
        state_cd: 'MV'
      },
      {
        state_cd_full: 'ZW-MW',
        name: 'Mashonaland West',
        country_id: '716',
        state_cd: 'MW'
      }
    ]
  }
]

export default countriesWithStates
