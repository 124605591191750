//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CategoriesSortSecondary',
  props: {
    category: { // represents the secondary category
      type: Object,
      default: () => {}
    },
    categoryParent: {
      type: String,
      default: null
    },
    categoryIndex: {
      type: Number,
      default: null
    },
    primaryCategories: {
      type: Array,
      default: () => []
    },
    categoryOwner: {
      type: String,
      default: null
    },
    categoryType: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      categoryToEdit: null,
      categoryRenameActive: false,
      savingCategory: false
    }
  },
  computed: {
    ...mapGetters({
      activeCategory: 'questions/getActiveCategory',
      primaryCategory: 'questions/getPrimaryCategory',
      secondaryCategory: 'questions/getSecondaryCategory'
    }),
    availableCategoriesForPrimaryMove() {
      return this.primaryCategories.filter(primary => primary.name !== this.categoryParent)
    },
    secondaryCategoryUniqueId() {
      const categoryFlattened = this.category.name.replace(' ', '-').toLowerCase()
      return `${categoryFlattened}_${this.categoryIndex.toString()}`
    }
  },
  methods: {
    ...mapActions({
      setActiveCategory: 'questions/setActiveCategory',
      setPrimaryCategory: 'questions/setPrimaryCategory',
      setSecondaryCategory: 'questions/setSecondaryCategory'
    }),
    cancelCategoryActions() {
      this.categoryToEdit = null
      this.categoryRenameActive = false
      this.setActiveCategory(null)
      this.setPrimaryCategory(null)
      this.setSecondaryCategory(null)
    },
    // TODO: this is the function that does not correctly set the focus to the input, it briefly sets it, then tabs to the save btn
    setInvalidCategoryRename({ prevName, categoryUniqueId }) {
      this.categoryToEdit = prevName
      this.$refs[categoryUniqueId].focus()
    },
    toggleRenameCategory({ category, categoryUniqueId, active = false }) {
      if (active) {
        this.categoryRenameActive = true
        this.categoryToEdit = category.name
        this.$nextTick(() => {
          const ref = this.$refs[categoryUniqueId]
          if (ref) {
            ref.focus()
          }
        })
      } else {
        this.categoryRenameActive = false
        this.categoryToEdit = null
      }
    }
  }
}
