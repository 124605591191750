var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question-list-answers" },
    _vm._l(_vm.questionList, function(question) {
      return _c(
        "b-row",
        {
          key: question._id,
          staticClass: "question-item answer",
          attrs: { "no-gutters": "" }
        },
        [
          _vm.isProjectOwner
            ? _c("QuestionAnswerItemOwner", {
                attrs: {
                  question: question,
                  "project-in-review": _vm.projectInReview
                }
              })
            : _c("QuestionAnswerItemParticipant", {
                attrs: {
                  question: question,
                  "project-in-review": _vm.projectInReview
                }
              })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }