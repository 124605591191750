var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { staticClass: "category-actions", attrs: { md: "9" } },
    [
      _vm.savingCategory ? _c("LoadingOverlay") : _vm._e(),
      _vm._v(" "),
      !_vm.categoryRenameActive
        ? _c(
            "b-button",
            {
              staticClass: "icon-button",
              attrs: { variant: "light", size: "sm", squared: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.$emit("toggleRenameCategory", {
                    category: _vm.category,
                    categoryUniqueId: _vm.categoryUniqueId,
                    active: true
                  })
                }
              }
            },
            [
              _c("b-icon", { attrs: { icon: "square-edit-outline" } }),
              _vm._v(" "),
              _vm.isDesktop ? _c("span", [_vm._v("Rename")]) : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.categoryRenameActive
        ? _c(
            "b-button",
            {
              ref: "btn-" + _vm.categoryUniqueId,
              staticClass: "icon-button",
              attrs: { variant: "light", size: "sm", title: "Update" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.saveCategoryRename(
                    _vm.level,
                    _vm.category.name,
                    _vm.categoryUniqueId
                  )
                }
              }
            },
            [_c("b-icon", { attrs: { icon: "check", type: "is-success" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.categoryRenameActive
        ? _c(
            "b-button",
            {
              staticClass: "icon-button",
              attrs: { variant: "light", size: "sm", title: "Cancel" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.$emit("toggleRenameCategory", {
                    category: _vm.category,
                    categoryUniqueId: _vm.categoryUniqueId,
                    active: false
                  })
                }
              }
            },
            [_c("b-icon", { attrs: { icon: "close", type: "is-danger" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "icon-button",
          attrs: { variant: "light", size: "sm", squared: "" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.deleteCategoryQuestionsConfirm(
                "primary",
                _vm.category.name
              )
            }
          }
        },
        [
          _c("b-icon", { attrs: { icon: "trash-can-outline" } }),
          _vm._v(" "),
          _vm.isDesktop ? _c("span", [_vm._v("Delete")]) : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown",
        {
          staticClass: "move-primary-dropdown",
          attrs: {
            position: "is-bottom-left",
            "close-on-click": "",
            "can-close": "",
            "mobile-modal": false
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "trigger" }, slot: "trigger" },
            [
              [
                _c(
                  "b-button",
                  {
                    staticClass: "icon-button",
                    attrs: { variant: "light", size: "sm" }
                  },
                  [
                    _vm.isDesktop ? _c("span", [_vm._v("Move To")]) : _vm._e(),
                    _vm._v(" \n          "),
                    _c("b-icon", {
                      attrs: { icon: "arrow-down-circle-outline" }
                    })
                  ],
                  1
                )
              ]
            ],
            2
          ),
          _vm._v(" "),
          _c("b-dropdown-item", { attrs: { custom: "" } }, [
            _vm._v("\n      Move to a new primary category:\n    ")
          ]),
          _vm._v(" "),
          _vm._l(_vm.availableCategoriesForPrimaryMove, function(primary) {
            return _c(
              "b-dropdown-item-button",
              {
                key: primary.name,
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.moveUnder(
                      _vm.level,
                      _vm.category.name,
                      primary.name
                    )
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(primary.name) + "\n    ")]
            )
          }),
          _vm._v(" "),
          _c("b-dropdown-divider")
        ],
        2
      ),
      _vm._v(" "),
      _vm.level === "secondary"
        ? _c(
            "b-button",
            {
              staticClass: "icon-button",
              attrs: { variant: "light", size: "sm", squared: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.setSecondaryAsPrimaryConfirm(_vm.category.name)
                }
              }
            },
            [
              _c("b-icon", { attrs: { icon: "arrow-up-circle" } }),
              _vm._v(" "),
              _vm.isDesktop ? _c("span", [_vm._v("Move Up")]) : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "icon-button",
          attrs: { variant: "light", size: "sm", squared: "" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.$emit("deselectCategory", "primary")
            }
          }
        },
        [
          _c("b-icon", { attrs: { icon: "close-box-outline" } }),
          _vm._v(" "),
          _vm.isDesktop ? _c("span", [_vm._v("Deselect")]) : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }