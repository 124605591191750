import { render, staticRenderFns } from "./InboxActions.vue?vue&type=template&id=637b45ca&"
import script from "./InboxActions.vue?vue&type=script&lang=js&"
export * from "./InboxActions.vue?vue&type=script&lang=js&"
import style0 from "./InboxActions.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {IconAddPrimary: require('/lasso/components/icons/IconAddPrimary.vue').default,IconRefresh: require('/lasso/components/icons/IconRefresh.vue').default,IconMailSelect: require('/lasso/components/icons/IconMailSelect.vue').default,IconTrashEmpty: require('/lasso/components/icons/IconTrashEmpty.vue').default,IconMailDelete: require('/lasso/components/icons/IconMailDelete.vue').default,IconArchive: require('/lasso/components/icons/IconArchive.vue').default,IconMailMove: require('/lasso/components/icons/IconMailMove.vue').default,IconTrashRestore: require('/lasso/components/icons/IconTrashRestore.vue').default,IconSearch: require('/lasso/components/icons/IconSearch.vue').default,Template: require('/lasso/components/templates/Template.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('637b45ca')) {
      api.createRecord('637b45ca', component.options)
    } else {
      api.reload('637b45ca', component.options)
    }
    module.hot.accept("./InboxActions.vue?vue&type=template&id=637b45ca&", function () {
      api.rerender('637b45ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/inbox/InboxActions.vue"
export default component.exports