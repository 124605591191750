var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-mail-select",
      attrs: {
        "data-name": "icon-mail-select",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d: "M5.85,20.9a.87.87,0,0,0,.86.86H19.59a.87.87,0,0,0,.86-.86V8"
        }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { d: "M20.45,8.12V7a.86.86,0,0,0-.86-.86" }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d:
            "M17.75,6.12V5a.85.85,0,0,0-.86-.86H4.71A.86.86,0,0,0,3.85,5V18.39a.87.87,0,0,0,.86.86H16.89a.86.86,0,0,0,.86-.86V6"
        }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "10.35", y1: "8.09", x2: "15.1", y2: "8.09" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "10.35", y1: "11.72", x2: "14.98", y2: "11.72" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "10.4", y1: "15.35", x2: "14.98", y2: "15.35" }
      }),
      _vm._v(" "),
      _c("circle", {
        staticStyle: {
          fill: "#444060",
          stroke: "#444060",
          "stroke-miterlimit": "10",
          "stroke-width": "0.31883271640277566px"
        },
        attrs: { cx: "7.83", cy: "8.13", r: "1.18" }
      }),
      _vm._v(" "),
      _c("circle", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10",
          "stroke-width": "0.31883271640277566px"
        },
        attrs: { cx: "7.83", cy: "11.79", r: "1.18" }
      }),
      _vm._v(" "),
      _c("circle", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10",
          "stroke-width": "0.31883271640277566px"
        },
        attrs: { cx: "7.83", cy: "15.48", r: "1.18" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }