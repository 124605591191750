var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav",
    _vm._l(_vm.navItems, function(item) {
      return _c(
        "b-nav-item",
        {
          key: item.page,
          class: "nav-" + item.page,
          attrs: {
            to: _vm.findPath(item.page),
            target: item.target,
            "exact-active-class": "active",
            "active-class": "active"
          },
          on: { click: _vm.closeMobileMenu }
        },
        [
          _vm.displayType === "text"
            ? _c(
                "span",
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.formattedItem(item.text || item.page)) +
                      "\n      "
                  ),
                  item.page === "inbox" && _vm.unreadMessages > 0
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "notification-badge",
                          attrs: { variant: "inbox" }
                        },
                        [_vm._v(_vm._s(_vm.unreadMessages))]
                      )
                    : _vm._e()
                ],
                1
              )
            : _c("b-icon", { attrs: { icon: _vm.getIcon(item.page) } })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }