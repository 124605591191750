var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-details",
      attrs: {
        "data-name": "icon-details",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d:
            "M4.13,7.11V5.73a1,1,0,0,1,1-1H19.91a1,1,0,0,1,1,1v13.8a1,1,0,0,1-1,1H5.17a1,1,0,0,1-1-1V6.91"
        }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "12.8", y1: "10.74", x2: "12.8", y2: "17.67" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "12.8", y1: "7.79", x2: "12.8", y2: "9.46" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }