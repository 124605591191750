//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PageHeader',
  props: {
    name: {
      type: String,
      default: 'Page Header',
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'breakpoints/mobile'
    })
  },
  methods: {
    ...mapActions({
      toggleMenu: 'global/toggleMenu'
    })
  }
}
