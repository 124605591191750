//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash-es'
import { uppercaseFirst } from '@/server/utils/utils-string'
import { questionType } from '@/server/constants'
import { validLoisValue } from '@/server/commonwithclient'

export default {
  name: 'QuestionAnswerForm',
  props: {
    projectInReview: {
      type: Boolean,
      default: false,
      required: true
    },
    question: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      answer: {
        choice: '',
        number: null,
        text: ''
      },
      recallAnswerCount: null,
      recallAnswerStatus: false,
      saving: false
    }
  },
  computed: {
    ...mapGetters({
      answers: 'answers/getAnswers',
      getAnswerByQuestion: 'answers/getAnswerByQuestion',
      getLastAnswerByQuestion: 'answers/getLastAnswerByQuestion',
      getFormattedAnswerByQuestion: 'answers/getFormattedAnswerByQuestion',
      getIsQuestionAnswered: 'answers/getIsQuestionAnswered',
      isParticipant: 'user/isParticipant',
      isProjectOwner: 'user/isProjectOwner',
      participant: 'participants/getProjectParticipant',
      propertyIsSubmitted: 'properties/getPropertyIsSubmitted'
    }),
    disableSaveBtn() {
      const { type } = this.question
      const disableText = type === questionType.text && this.answer.text === ''
      const disableNumber = type === questionType.number && Number.isNaN(this.answer.number)
      const disableChoice = type === questionType.choice && this.answer.choice === ''
      return this.saving || disableText || disableNumber || disableChoice
    },
    displayClearAnswer() {
      // do not allow answer to be deleted if question is required and property is already submitted
      if (this.question.required && this.propertyIsSubmitted && this.questionIsAnswered) {
        return false
      }
      // do not allow answer to be deleted if there is a valid LOIS answer provided, user can only update
      // this ensures required categories indicators are correct
      if (this.questionIsAnsweredByLOIS) {
        return false
      }
      return this.questionIsAnswered
    },
    displayRecallAnswer() {
      return this.isParticipant && !this.questionIsAnswered && !this.propertyIsSubmitted && !this.question.requirement
    },
    displayRecallAnswerNotice() {
      return this.isParticipant && !this.questionIsAnswered && !this.propertyIsSubmitted && !this.question.requirement && this.recallAnswerStatus
    },
    formattedAnswer() {
      const answer = this.getFormattedAnswerByQuestion(null, this.questionAnswerLatest)
      return answer === null ? 'No Answer On File' : answer
    },
    questionAnswer() {
      return this.getAnswerByQuestion(this.question._id)
    },
    questionAnswerLatest() {
      return this.getLastAnswerByQuestion(this.question._id)
    },
    questionIsAnswered() {
      return this.getIsQuestionAnswered(this.question._id)
    },
    questionAnswerValueFromLois() {
      const answer = this.questionAnswer.display || this.questionAnswer?.loisValue
      return Array.isArray(answer) ? answer.join(',') : answer
    },
    questionAnswerValueFromLoisDisplay() {
      const lValue = this.questionAnswerValueFromLois
      return validLoisValue(lValue) ? lValue : 'Not Answered'
    },
    questionIsAnsweredByLOIS() {
      const answer = validLoisValue(this.questionAnswer?.loisValue)
      return answer !== null
    },
    questionChoices() {
      return this.question?.choices?.map(c => c.toLowerCase())
    }
  },
  watch: {
    formattedAnswer(newVal) {
      if (newVal) {
        this.prefillAnswer()
      } else {
        this.answer.text = ''
        this.answer.choice = ''
        this.answer.number = null
      }
    },
    questionIsAnswered(newVal) {
      if (newVal) {
        this.prefillAnswer()
      } else {
        this.answer.text = ''
        this.answer.choice = ''
        this.answer.number = null
      }
    }
  },
  mounted() {
    if (this.questionIsAnswered) {
      this.prefillAnswer()
    }
  },
  methods: {
    ...mapActions({
      addAnswer: 'answers/addAnswer',
      addAnswerUpdate: 'answers/addAnswerUpdate',
      deleteAnswer: 'answers/deleteAnswer',
      fetchAnswers: 'answers/fetchAnswersForProperty',
      updateAnswer: 'answers/updateAnswer',
      recallAnswer: 'answers/recallAnswer',
      participantEditedQuestionDuringProjectReview: 'events/participantEditedQuestionDuringProjectReview',
      syncPropertyAnswersWithLois: 'properties/syncPropertyAnswersWithLois'
    }),
    async answerQuestion() {
      /*
        Property new or working status:
         - owner or participant can add, edit and delete answers with addAnswer, updateAnswer, deleteAnswer
         - audit record is not created while property still in 'new' and 'working' status
        Property submitted status:
         - no answer provided, call addAnswer
         - changing or deleting existing answer, call addAnswerUpdate which records an audit record
         - deleting existing answer pass empty values for the answer so it shows the deletion in audit log
      */
      this.saving = true
      const { projectid, propertyid } = this.$route.params
      let answer = ''
      if (this.question.type === questionType.text) {
        answer = this.answer.text
      } else if (this.question.type === questionType.number) {
        answer = this.answer.number
      } else {
        answer = this.answer.choice
      }
      if (!this.propertyIsSubmitted) {
        if (isEmpty(this.questionAnswer)) {
          await this.addAnswer({ projectId: projectid, propertyId: propertyid, questionId: this.question._id, answerData: { value: answer } })
        } else {
          await this.updateAnswer({ projectId: projectid, propertyId: propertyid, answer: { ...this.questionAnswer, value: answer } })
        }
      } else if (this.propertyIsSubmitted && isEmpty(this.questionAnswer)) {
        await this.addAnswer({ projectId: projectid, propertyId: propertyid, questionId: this.question._id, answerData: { value: answer } })
      } else {
        await this.addAnswerUpdate({ projectId: projectid, propertyId: propertyid, answer: { ...this.questionAnswer, value: answer } })
      }
      if (this.projectInReview) {
        await this.notifyOwnerOfAnswerChange()
      }
      this.saving = false
      if (this.question.required) {
        this.$root.$emit('refresh-required-categories')
      }
      if (this.isParticipant) {
        this.$root.$emit('bv::toggle::collapse', this.question._id)
      }
    },
    formattedChoice(choice) {
      return uppercaseFirst(choice)
    },
    async notifyOwnerOfAnswerChange() {
      const { propertyid } = this.$route.params
      const params = {
        participantId: this.participant._id,
        propertyId: propertyid,
        questionId: this.question._id
      }
      await this.participantEditedQuestionDuringProjectReview(params)
    },
    prefillAnswer() {
      if (this.questionIsAnswered) {
        const answerValue = this.formattedAnswer
        if (this.question.type === questionType.text) {
          this.answer.text = answerValue
          this.answer.number = null
          this.answer.choice = ''
        } else if (this.question.type === questionType.number) {
          this.answer.number = parseFloat(answerValue)
          this.answer.text = ''
          this.answer.choice = ''
        } else {
          this.answer.choice = answerValue.toLowerCase()
          this.answer.text = ''
          this.answer.number = null
        }
      }
    },
    async recallQuestionAnswer() {
      this.saving = true
      const { projectid: projectId, propertyid: propertyId } = this.$route.params
      const { tag: questionTag, _id: questionId } = this.question
      const answersRecalled = await this.recallAnswer({ projectId, propertyId, questionTag, questionId })
      this.recallAnswerCount = answersRecalled
      this.recallAnswerStatus = true
      if (!answersRecalled) {
        this.saving = false
        setTimeout(() => {
          this.recallAnswerStatus = false
        }, 5000)
        return
      } else {
        await this.fetchAnswers({ projectId, propertyId })
        if (this.question.required) {
          this.$root.$emit('refresh-required-categories')
        }
        this.saving = false
        setTimeout(() => {
          this.recallAnswerStatus = false
        }, 5000)
      }
      this.recallAnswerCount = null
      this.saving = false
    },
    async resetAnswer() {
      this.saving = true
      const { projectid, propertyid } = this.$route.params
      if (!this.propertyIsSubmitted) {
        await this.deleteAnswer({ projectId: projectid, answerId: this.questionAnswer._id, propertyId: propertyid })
      } else {
        await this.addAnswerUpdate({ projectId: projectid, propertyId: propertyid, answer: { ...this.questionAnswer, value: '' } })
      }
      if (this.projectInReview) {
        await this.notifyOwnerOfAnswerChange()
      }
      if (this.question.required) {
        this.$root.$emit('refresh-required-categories')
      }
      if (this.isParticipant) {
        this.$root.$emit('bv::toggle::collapse', this.question._id)
      }
      this.answer.text = ''
      this.answer.number = null
      this.answer.choice = ''
      this.saving = false
    },
    async syncAnswer() {
      const { projectid, propertyid } = this.$route.params
      this.saving = true
      await this.syncPropertyAnswersWithLois({ projectId: projectid, propertyId: propertyid })
      await this.fetchAnswers({ projectId: projectid, propertyId: propertyid })
      if (this.question.required) {
        this.$root.$emit('refresh-required-categories')
      }
      this.saving = false
    }
  }
}
