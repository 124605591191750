var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question answer answer-form" },
    [
      _vm.saving ? _c("LoadingOverlay") : _vm._e(),
      _vm._v(" "),
      _vm.question.loisDataField
        ? _c(
            "b-row",
            { staticClass: "lois-sync" },
            [
              _c(
                "b-col",
                [
                  _vm._v("\n      Data field type: "),
                  _c("strong", [
                    _c("em", [_vm._v(_vm._s(_vm.question.loisDataField))])
                  ]),
                  _c("br"),
                  _vm._v("\n      Answer On File: "),
                  _c("strong", [
                    _c("em", [
                      _vm._v(_vm._s(_vm.questionAnswerValueFromLoisDisplay))
                    ])
                  ]),
                  _c("br"),
                  _vm._v('\n      If answer is not populated, click "sync".'),
                  _c("br"),
                  _vm._v(
                    "\n      1) You can override with a different answer if its incorrect -or-"
                  ),
                  _c("br"),
                  _vm._v(
                    '\n      2) Update the property using the "Edit" link, then click sync'
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-1",
                      attrs: { variant: "dark", size: "sm", squared: "" },
                      on: { click: _vm.syncAnswer }
                    },
                    [_vm._v("\n        Sync Answer\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "detail-header" },
        [_c("b-col", [_c("h5", [_vm._v("Answer:")])])],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "answer-input" },
        [
          _vm.question.type === 0
            ? _c(
                "b-col",
                { staticClass: "answer-type-text" },
                [
                  _c("b-input", {
                    attrs: { placeholder: "Answer ...", expanded: "" },
                    model: {
                      value: _vm.answer.text,
                      callback: function($$v) {
                        _vm.$set(_vm.answer, "text", $$v)
                      },
                      expression: "answer.text"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.question.type === 1
            ? _c(
                "b-col",
                {
                  staticClass: "answer-type-numeric",
                  attrs: { md: "10", lg: "8", xl: "6" }
                },
                [
                  _c("strong", [_vm._v("Enter a Number:")]),
                  _vm._v(" "),
                  _c("b-numberinput", {
                    attrs: { size: "is-small", type: "is-templates" },
                    model: {
                      value: _vm.answer.number,
                      callback: function($$v) {
                        _vm.$set(_vm.answer, "number", $$v)
                      },
                      expression: "answer.number"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.question.type === 2
            ? _c(
                "b-col",
                { staticClass: "answer-type-choice" },
                [
                  _c("strong", [_vm._v("Choices:")]),
                  _vm._v(" "),
                  _c(
                    "b-select",
                    {
                      attrs: { placeholder: "Select a choice", expanded: "" },
                      model: {
                        value: _vm.answer.choice,
                        callback: function($$v) {
                          _vm.$set(_vm.answer, "choice", $$v)
                        },
                        expression: "answer.choice"
                      }
                    },
                    _vm._l(_vm.questionChoices, function(choice, idx) {
                      return _c(
                        "option",
                        { key: idx, domProps: { value: choice } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formattedChoice(choice)) +
                              "\n        "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.question.note
        ? _c(
            "b-row",
            { staticClass: "note" },
            [
              _c("b-col", [
                _c("strong", [_vm._v("Note:")]),
                _vm._v(" " + _vm._s(_vm.question.note) + "\n    ")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "answer-actions" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  staticClass: "icon-button",
                  attrs: {
                    variant: "link",
                    size: "sm",
                    disabled: _vm.disableSaveBtn
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.answerQuestion()
                    }
                  }
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "check", type: "is-success" }
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.saving ? "Saving" : "Save") + "\n      "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: _vm.question._id,
                      expression: "question._id"
                    }
                  ],
                  staticClass: "icon-button",
                  attrs: { variant: "link", size: "sm" }
                },
                [
                  _c("b-icon", { attrs: { icon: "close", type: "is-danger" } }),
                  _vm._v(" Cancel\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _vm.displayClearAnswer
                ? _c(
                    "b-button",
                    {
                      staticClass: "icon-button",
                      attrs: {
                        variant: "link",
                        size: "sm",
                        disabled: _vm.saving
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.resetAnswer()
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "tray-remove", type: "is-warning" }
                      }),
                      _vm._v(" Clear Answer\n      ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.displayRecallAnswer
                ? _c(
                    "b-button",
                    {
                      staticClass: "icon-button",
                      attrs: {
                        variant: "link",
                        size: "sm",
                        disabled: _vm.saving
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.recallQuestionAnswer()
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "history", type: "is-inbox" }
                      }),
                      _vm._v(" Recall Previous Answer\n      ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.displayRecallAnswerNotice
                ? _c("div", { staticClass: "required" }, [
                    _vm.recallAnswerCount === 0
                      ? _c("span", [_vm._v("* No previous answer found")])
                      : _c("span", [
                          _vm._v(
                            "* " +
                              _vm._s(_vm.recallAnswerCount) +
                              " answer applied"
                          )
                        ])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }