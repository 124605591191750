var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-edit-primary",
      attrs: {
        "data-name": "icon-edit-primary",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: { x1: "22.35", y1: "21.76", x2: "2.82", y2: "21.76" }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: { fill: "#444060" },
        attrs: {
          d:
            "M20.5,3.14a1.51,1.51,0,0,0-2.12,0L17.29,4.23l2.12,2.12L20.5,5.27A1.5,1.5,0,0,0,20.5,3.14Z"
        }
      }),
      _vm._v(" "),
      _c("polygon", {
        staticStyle: { fill: "#444060" },
        attrs: {
          points:
            "5.57 15.96 5.57 18.08 7.69 18.08 18.02 7.75 15.9 5.63 5.57 15.96"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        staticStyle: { fill: "#fff" },
        attrs: {
          x: "16.67",
          y: "4.49",
          width: "1.97",
          height: "3",
          transform: "translate(0.93 14.24) rotate(-45)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }