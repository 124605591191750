var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-trash",
      attrs: {
        "data-name": "icon-trash",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d: "M9.49,8.35V7.49a.87.87,0,0,1,.86-.86h4.71a.87.87,0,0,1,.86.86v.86"
        }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "20.61", y1: "8.74", x2: "4.52", y2: "8.74" }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d:
            "M6.41,8.74V20.07a.87.87,0,0,0,.86.86H18a.87.87,0,0,0,.86-.86V8.74Z"
        }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "12.58", y1: "11.37", x2: "12.58", y2: "18.3" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "9.42", y1: "11.37", x2: "9.42", y2: "18.3" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "15.75", y1: "11.37", x2: "15.75", y2: "18.3" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }