import Vue from 'vue'
import {
  Autocomplete,
  Checkbox,
  ConfigProgrammatic,
  Dropdown,
  Field,
  Icon,
  Input,
  Numberinput,
  Radio,
  Select,
  Steps,
  Switch,
  // Table,
  Tag,
  Taginput,
  Upload
} from 'buefy'

import icons from 'buefy/src/utils/icons'
import FormElementMixin from 'buefy/src/utils/FormElementMixin'

Vue.use(Autocomplete)
Vue.use(Checkbox)
Vue.use(Dropdown)
Vue.use(icons)
Vue.use(FormElementMixin)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Numberinput)
Vue.use(Radio)
Vue.use(Select)
Vue.use(Steps)
Vue.use(Switch)
// Vue.use(Table)
Vue.use(Tag)
Vue.use(Taginput)
Vue.use(Upload)
ConfigProgrammatic.setOptions({
  defaultIconPack: 'mdi'
})
