export const isAnswered = val => ![undefined, null, '', 'undefined', 'null'].includes(val)
export const notAnswered = val => [undefined, null, '', 'undefined', 'null'].includes(val)
export const validLoisValue = (val) => {
  if (Array.isArray(val)) {
    if (!val.length) { return null }
    const answer = val.filter(v => isAnswered(v))
    return answer.length ? answer : null
  }
  return String(val) === 'NaN' ? null : isAnswered(val) ? val : null
}
