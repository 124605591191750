export const routeOption = (route, key, value) => {
  return route.matched.some((m) => {
    return Object.values(m.components).some(
      component => component.options && component.options[key] === value
    )
  })
}

export const getMatchedComponents = (route, matches = false) => {
  return [].concat.apply([], route.matched.map(function (m, index) {
    return Object.keys(m.components).map(function (key) {
      matches && matches.push(index)
      return m.components[key]
    })
  }))
}

export function normalizePath (path = '') {
  // Remove query string
  let result = path.split('?')[0]
  // Remove redundant / from the end of path
  if (result.charAt(result.length - 1) === '/') {
    result = result.slice(0, -1)
  }
  return result
}

export default async function (ctx) {
  if (!process.client) {
    return
  }
  // Disable middleware if options: { auth: false } is set on the route
  if (routeOption(ctx.route, 'auth', false)) {
    return
  }
  // Disable middleware if no route was matched to allow 404/error page
  const matches = []
  const Components = getMatchedComponents(ctx.route, matches)
  if (!Components.length) {
    return
  }
  const login = '/'
  const callback = '/logincallback'
  const pageIsInGuestMode = routeOption(ctx.route, 'auth', 'guest')
  const pageIsInQuestionnaireMode = routeOption(ctx.route, 'auth', 'questionnaire')
  const insidePage = page => normalizePath(ctx.route.path) === normalizePath(page)

  if (ctx.store.state.user.user &&
      ctx.store.state.user.token &&
      ctx.store.state.user.hInterval) {
    // Authorized
    if (!login || insidePage(login) || pageIsInGuestMode) {
      ctx.redirect('/')
    }
  } else if (pageIsInQuestionnaireMode) {
    // This should validate if we have a valid questionnaire identifier
    const tokenResult = await ctx.store.dispatch('user/refreshToken', 0)
    const questionnaireId = ctx.route.params.questionnaireid
    const result = await ctx.store.dispatch('questionnaires/verifyQuestionnaire', { questionnaireId })
    if (!result) {
      ctx.redirect('/')
    }
    if (!tokenResult) {
      // The questionnaireId is valid, but the user was not logged in, so capture
      // the redirectUrl in case we need to redirect this user to the normal login
      sessionStorage.setItem('redirectUrl', ctx.route.fullPath)
    }
  } else if (!pageIsInGuestMode && (!callback || !insidePage(callback))) {
    // If we're not authorized, let's check if we should be prior to redirecting
    const result = await ctx.store.dispatch('user/refreshToken')
    if (!result) {
      // If we cannot authenticate, redirect to /login
      // keep requested URL so we can redirect here after authentication
      sessionStorage.setItem('redirectUrl', ctx.route.fullPath)
      ctx.redirect('/')
    }
  }
}
