var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "categories-manager" },
    [
      _vm.loading ? _c("LoadingOverlay") : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("h5", [_vm._v("Instructions:")]),
            _vm._v(" "),
            _c("ul", { staticClass: "small" }, [
              _c(
                "li",
                [
                  _c("strong", [_vm._v("Sort Mode:")]),
                  _vm._v(" Drag to reorder categories when "),
                  _c("b-icon", {
                    attrs: {
                      icon: "cursor-move",
                      size: "is-small",
                      type: "is-templates"
                    }
                  }),
                  _vm._v(" is displayed.")
                ],
                1
              ),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Edit Mode:")]),
                _vm._v(
                  " Click on a row for Edit mode (ie. delete, rename, move to new primary, make primary into secondary)."
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "Secondary categories can only be re-ordered under their current Primary category. Use Edit mode to move to new Primary."
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Sorting is disabled when a row is selected for "),
                _c("strong", [_vm._v("Edit")]),
                _vm._v(". Deselect active row to go back to "),
                _c("strong", [_vm._v("Sort")]),
                _vm._v(" mode.")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Click "),
                _c("strong", [_vm._v("Save Sort")]),
                _vm._v(" to save the new sort order or "),
                _c("strong", [_vm._v("Reset Sort")]),
                _vm._v(" to revert to original sort order.")
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "category-manager-actions", attrs: { md: "4" } },
            [
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "button",
                        variant: "projects",
                        size: "sm",
                        block: "",
                        disabled: _vm.loading || !_vm.originalSortHasChanged
                      },
                      on: { click: _vm.saveCategoryOrder }
                    },
                    [_vm._v("\n          Save Sort Order\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "button",
                        variant: "outline-projects",
                        size: "sm",
                        block: "",
                        disabled: _vm.loading || !_vm.originalSortHasChanged
                      },
                      on: {
                        click: function($event) {
                          return _vm.resetSort()
                        }
                      }
                    },
                    [_vm._v("\n          Reset Sort Order\n        ")]
                  ),
                  _vm._v(" "),
                  _vm.showDeleteAllCategoriesButton
                    ? _c(
                        "b-button",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: {
                            type: "button",
                            variant: "outline-danger",
                            size: "sm",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteAllQuestionsConfirm()
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "trash-can-outline" }
                          }),
                          _vm._v(" Delete All Categories\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: { size: "is-small" },
                          model: {
                            value: _vm.showSecondaryCategories,
                            callback: function($$v) {
                              _vm.showSecondaryCategories = $$v
                            },
                            expression: "showSecondaryCategories"
                          }
                        },
                        [_c("span", [_vm._v("Display secondary categories")])]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.originalSortHasChanged
                    ? _c(
                        "span",
                        [
                          _c(
                            "b-badge",
                            { attrs: { variant: "warning", size: "sm" } },
                            [
                              _c("b-icon", {
                                attrs: { icon: "exclamation", size: "is-small" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "small" }, [
                            _vm._v("Save Sort Order Changes")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "categories",
          attrs: { "ghost-class": "ghost", disabled: _vm.activeCategory },
          model: {
            value: _vm.categoriesList,
            callback: function($$v) {
              _vm.categoriesList = $$v
            },
            expression: "categoriesList"
          }
        },
        _vm._l(_vm.primariesWithoutUncategorized, function(category, idx) {
          return _c("CategorySort", {
            key: category.name,
            attrs: {
              category: category,
              "category-index": idx,
              "original-sort-has-changed": _vm.originalSortHasChanged,
              "primary-categories": _vm.categoriesList,
              "category-owner": _vm.categoryOwner,
              "category-type": _vm.categoryType,
              "show-secondaries": _vm.showSecondaryCategories
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }