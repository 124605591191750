// The global $axios plugin will typically have the token
// set to whatever the user obtains after logging in.  In order
// to prevent this from having to unset the token, we'll make an
// anonymous instance of the axios plugin for use against api services
// that don't need a token or require it NOT be present
export default function ({ $axios }, inject) {
  const anonaxios = $axios.create({
    headers: {
      common: {
        Accept: 'text/plain, */*'
      }
    }
  })
  anonaxios.setBaseURL(process.env.baseUrl)
  inject('anonaxios', anonaxios)
}
