//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { projectStatus } from '@/server/constants'

export default {
  name: 'QuestionAnswerView',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isProjectOwner: 'user/isProjectOwner',
      project: 'projects/getActiveProject',
      questionList: 'questions/getQuestions'
    }),
    projectInReview() {
      return this.project?.status === projectStatus.review
    },
    questionsToDisplay() {
      // not used - decided to display and flag them instead in the UI so categories would display accurate list
      // excludes questions that are requirements - they should be answered in the PropertyRequirementsListItem component
      const questions = this.questionList.filter(q => !q.requirement)
      return questions
    }
  },
  async mounted() {
  },
  methods: {
    ...mapActions({
    })
  }
}
