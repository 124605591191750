var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "page-header-wrapper" },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _vm.isDesktop
                    ? _c(
                        "b-col",
                        { staticClass: "header-search", attrs: { md: "6" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h1", { staticClass: "page-heading" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.pageName) +
                                      "\n              "
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "header-actions" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "actions-wrapper" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "actions" },
                            [
                              _vm.showButton("new-template") &&
                              _vm.isProjectOwner
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "new",
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "New Template"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "new-template"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconAddPrimary")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("new-question") &&
                              _vm.isProjectOwner
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "new",
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "New Question"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "new-question"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconAddPrimary")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("refresh")
                                ? _c(
                                    "b-button",
                                    {
                                      class: { refreshing: _vm.isRefreshing },
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Refresh List"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction("refresh")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "refresh-icon" },
                                        [
                                          _vm.refreshing === "refresh"
                                            ? _c("b-spinner", {
                                                attrs: { medium: "" }
                                              })
                                            : _c("IconRefresh")
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("list")
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Templates List"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction("list")
                                        }
                                      }
                                    },
                                    [_c("IconList")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("active-template")
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        squared: "",
                                        variant: "light",
                                        size: "sm",
                                        title: "Template Questions"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "active-template"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "information-outline" }
                                      }),
                                      _vm._v(
                                        " Template Questions\n              "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("edit-template") &&
                              _vm.showEditTemplate
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Edit Template"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "edit-template"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconEditPrimary")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("search")
                                ? _c("b-input", {
                                    ref: "search",
                                    staticClass: "search-input",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Search Templates",
                                      value: _vm.templateOptions.search,
                                      "icon-right": _vm.templateOptions.search
                                        ? "magnify-close"
                                        : "magnify",
                                      "icon-right-clickable": ""
                                    },
                                    on: {
                                      "icon-right-click": _vm.clearSearch,
                                      input: _vm.searchTemplates
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }