import { render, staticRenderFns } from "./QuestionDetailItem.vue?vue&type=template&id=57f2be67&"
import script from "./QuestionDetailItem.vue?vue&type=script&lang=js&"
export * from "./QuestionDetailItem.vue?vue&type=script&lang=js&"
import style0 from "./QuestionDetailItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {IconEdit: require('/lasso/components/icons/IconEdit.vue').default,IconDelete: require('/lasso/components/icons/IconDelete.vue').default,RequirementOperatorValue: require('/lasso/components/requirements/RequirementOperatorValue.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57f2be67')) {
      api.createRecord('57f2be67', component.options)
    } else {
      api.reload('57f2be67', component.options)
    }
    module.hot.accept("./QuestionDetailItem.vue?vue&type=template&id=57f2be67&", function () {
      api.rerender('57f2be67', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/questions/QuestionDetailItem.vue"
export default component.exports