var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category" },
    [
      _c(
        "div",
        {
          class: [
            "category-item",
            "primary",
            _vm.activeCategory === _vm.category.name ? "active" : ""
          ],
          attrs: { "no-gutters": "" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.setSelectedCategoryRow({
                level: "primary",
                category: _vm.category.name
              })
            }
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "category-name" },
            [
              _vm.categoryRenameActive &&
              _vm.activeCategory === _vm.category.name
                ? _c(
                    "b-col",
                    [
                      _c("b-input", {
                        ref: _vm.primaryCategoryUniqueId,
                        attrs: { readonly: _vm.savingCategory },
                        model: {
                          value: _vm.categoryToEdit,
                          callback: function($$v) {
                            _vm.categoryToEdit = $$v
                          },
                          expression: "categoryToEdit"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "b-col",
                    [
                      !_vm.activeCategory
                        ? _c("b-icon", {
                            attrs: {
                              icon: "cursor-move",
                              size: "is-small",
                              type: "is-templates",
                              disabled: _vm.category.name === "Uncategorized"
                            }
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n        " + _vm._s(_vm.category.name) + "\n      "
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.activeCategory === _vm.category.name
                ? _c("CategorySortRowActions", {
                    attrs: {
                      "available-categories-for-primary-move":
                        _vm.availableCategoriesForPrimaryMove,
                      "category-unique-id": _vm.primaryCategoryUniqueId,
                      category: _vm.category,
                      "category-to-edit": _vm.categoryToEdit,
                      "category-owner": _vm.categoryOwner,
                      "category-type": _vm.categoryType,
                      "category-rename-active": _vm.categoryRenameActive,
                      level: "primary"
                    },
                    on: {
                      deselectCategory: function(value) {
                        return _vm.cancelCategoryActions(value)
                      },
                      savingCategory: function(value) {
                        return (_vm.savingCategory = value)
                      },
                      setCategoryToEdit: function(value) {
                        return (_vm.categoryToEdit = value)
                      },
                      setInvalidCategoryRename: function(value) {
                        return _vm.setInvalidCategoryRename(value)
                      },
                      toggleRenameCategory: function(value) {
                        return _vm.toggleRenameCategory(value)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "showSecondaries" } },
        [
          _vm.showSecondaries
            ? _c(
                "draggable",
                {
                  staticClass: "secondary-categories",
                  attrs: {
                    "ghost-class": "ghost",
                    disabled: _vm.activeCategory
                  },
                  model: {
                    value: _vm.activeSecondaryCategories,
                    callback: function($$v) {
                      _vm.activeSecondaryCategories = $$v
                    },
                    expression: "activeSecondaryCategories"
                  }
                },
                _vm._l(_vm.activeSecondaryCategories, function(
                  secondaryCategory,
                  idx
                ) {
                  return _c(
                    "div",
                    {
                      key: secondaryCategory.name,
                      class: [
                        "category-item",
                        "secondary",
                        _vm.activeCategory === secondaryCategory.name &&
                        _vm.primaryCategory === _vm.category.name
                          ? "active"
                          : ""
                      ],
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.setSelectedCategoryRow({
                            level: "secondary",
                            category: secondaryCategory.name,
                            categoryParent: _vm.category.name
                          })
                        }
                      }
                    },
                    [
                      _c("CategorySortSecondary", {
                        attrs: {
                          category: secondaryCategory,
                          "category-parent": _vm.category.name,
                          "category-owner": _vm.categoryOwner,
                          "category-type": _vm.categoryType,
                          "category-index": idx,
                          "primary-categories": _vm.primaryCategories
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }