var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-add-primary",
      attrs: {
        "data-name": "icon-primary",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: { x1: "12.58", y1: "23.37", x2: "12.58", y2: "1.72" }
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "3px"
        },
        attrs: { x1: "23.14", y1: "12.46", x2: "1.49", y2: "12.46" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }