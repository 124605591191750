var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "sticky-top main-header" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-navbar",
            { attrs: { toggleable: "lg" } },
            [
              _c(
                "b-navbar-brand",
                { staticClass: "mr-auto", attrs: { to: "/" } },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.logoAnimation,
                        expression: "logoAnimation"
                      }
                    ],
                    staticClass: "logo",
                    attrs: { src: require("assets/images/lasso-css.svg") }
                  }),
                  _vm._v(" "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.logoAnimation,
                        expression: "!logoAnimation"
                      }
                    ],
                    staticClass: "logo-static",
                    attrs: {
                      src: require("assets/images/lasso-logo-default.png")
                    }
                  })
                ]
              ),
              _vm._v(" "),
              !_vm.loading
                ? _c(
                    "b-navbar-nav",
                    { attrs: { align: "right" } },
                    [
                      _vm.loggedInUser && !_vm.loading
                        ? _c("NavItems", { attrs: { items: _vm.mainNavItems } })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loggedInUser &&
                      _vm.showEmailBtn &&
                      _vm.$route.name === "index"
                        ? _c(
                            "b-nav-item",
                            [
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$bvModal.show(
                                        "modal-read-email"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Return To Your Email\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loggedInUser && _vm.$route.name === "index"
                        ? _c(
                            "b-nav-item",
                            { attrs: { href: "#how-it-works" } },
                            [_vm._v("\n          How It Works\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loggedInUser && _vm.$route.name === "index"
                        ? _c("b-nav-item", { attrs: { href: "#features" } }, [
                            _vm._v("\n          Features\n        ")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loggedInUser && _vm.$route.name === "index"
                        ? _c(
                            "b-nav-item",
                            { attrs: { href: "#integrations" } },
                            [_vm._v("\n          Integrations\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loggedInUser
                        ? _c(
                            "b-nav-item",
                            {
                              staticClass: "btn-login",
                              on: {
                                click: function($event) {
                                  return _vm.openAuthModal("login")
                                }
                              }
                            },
                            [_vm._v("\n          Login\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loggedInUser
                        ? _c(
                            "b-nav-item",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "inbox", size: "sm" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        name: "support",
                                        params: { referral: "demo" }
                                      })
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Request A Demo\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loggedInUser
                        ? _c(
                            "b-nav-item",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "inbox", size: "sm" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openAuthModal("register")
                                    }
                                  }
                                },
                                [_vm._v("\n            Sign Up\n          ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.loggedInUser
                        ? _c(
                            "b-nav-item",
                            { staticClass: "nav-profile" },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  attrs: {
                                    triggers: ["hover"],
                                    "mobile-modal": false,
                                    position: "is-bottom-left"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "avatar",
                                      attrs: { slot: "trigger" },
                                      slot: "trigger"
                                    },
                                    [
                                      [
                                        _c("b-avatar", {
                                          class: { admin: _vm.isAdmin },
                                          attrs: { text: _vm.userInitials }
                                        })
                                      ]
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    { attrs: { "has-link": "" } },
                                    [
                                      _vm.loggedInUser
                                        ? _c(
                                            "b-link",
                                            {
                                              attrs: {
                                                to: "/profile",
                                                "active-class": "active"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Profile\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    { attrs: { "has-link": "" } },
                                    [
                                      _c(
                                        "b-link",
                                        {
                                          attrs: {
                                            to: "/support",
                                            "active-class": "active"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Support\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.loggedInUser
                                        ? _c(
                                            "b-link",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$bvModal.show(
                                                    "modal-quick-start"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Quick Start\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.showStopImpersonation
                                    ? _c(
                                        "b-dropdown-item",
                                        { attrs: { "has-link": "" } },
                                        [
                                          _c(
                                            "b-link",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click:
                                                  _vm.logOutImpersonatedUser
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Stop Impersonation\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    { attrs: { "has-link": "" } },
                                    [
                                      _c(
                                        "b-link",
                                        {
                                          attrs: { href: "#" },
                                          on: { click: _vm.logMeOut }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Sign Out\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }