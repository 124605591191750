var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-secondary" },
    [_c("NavItems", { attrs: { items: _vm.projectNavItemsSecondary } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }