//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash-es'

export default {
  name: 'Marketing',
  data () {
    return {
      initialAuthForm: '',
      emailReferralId: '',
      emailReferralMsg: null,
      pageTheme: '',
      loading: null,
      stickyHeader: false,
      logoAnimation: false
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'breakpoints/desktop',
      isMobile: 'breakpoints/mobile',
      navOpen: 'global/navOpen',
      currentTheme: 'global/pageTheme',
      loggedInUser: 'user/loggedInUser'
    }),
    emailReferral() {
      return this.emailReferralId !== ''
    },
    isUserLoggedIn() {
      return this.loggedInUser
    }
  },
  watch: {
    $route () {
      this.setPageTheme()
    }
  },
  async beforeMount () {
    this.loading = true
    await this.checkLoginStatus()
    await this.checkEmailReferralStatus()
  },
  mounted() {
    this.setPageTheme()
    this.watchBreakpoints()
    window.addEventListener('scroll', this.handleScroll)
    this.$nextTick(() => {
      setTimeout(() => {
        this.logoAnimation = true
      }, 1000)
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      getEmailReferralMsg: 'messages/getEmailReferralMsg',
      getMessageIdFromEmailReferralId: 'messages/getMessageIdFromEmailReferralId',
      setListMessageId: 'messages/setListMessageId',
      setReferralMessageId: 'messages/setReferralMessageId',
      setReferredNonAuthUser: 'messages/setReferredNonAuthUser',
      setTheme: 'global/setPageTheme',
      toggleMenu: 'global/toggleMenu',
      watchBreakpoints: 'breakpoints/watch'
    }),
    async checkLoginStatus() {
      if (!this.$store.state.user.user || !this.$store.state.user.token) {
        await this.$store.dispatch('user/refreshToken')
      }
    },
    async checkEmailReferralStatus() {
      if (isEmpty(this.$route.query)) {
        this.loading = false
      } else if (this.$route.query.login && !this.isUserLoggedIn) {
        this.initialAuthForm = 'login'
        this.$bvModal.show('modal-auth')
      } else {
        this.emailReferralId = this.$route.query.emailReferralId
        try {
          const msg = await this.getEmailReferralMsg(this.emailReferralId)
          this.handleEmailReferralAnonymous(msg)
        } catch (err) {
          if (err.response?.status === 401) {
            if (this.isUserLoggedIn) {
              this.handleEmailReferralLoggedIn()
            } else {
              this.handleEmailReferralLoggedOut()
            }
          } else {
            this.handleEmailReferralExceptions()
          }
        }
      }
    },
    displayEmailForNonRegistered() {
      if (this.emailReferral) {
        this.initialAuthForm = 'register'
        this.$bvModal.show('modal-read-email')
      }
    },
    handleEmailReferralAnonymous(msg) {
      this.emailReferralMsg = msg.message
      this.setListMessageId(this.emailReferralMsg._id)
      this.setReferredNonAuthUser(msg.email)
      this.loading = false
      this.displayEmailForNonRegistered()
    },
    handleEmailReferralExceptions() {
      this.emailReferralId = ''
      this.loading = false
      this.$router.push('/')
    },
    async handleEmailReferralLoggedIn() {
      try {
        const res = await this.getMessageIdFromEmailReferralId(this.emailReferralId)
        this.setListMessageId(res.emailReferralId)
        this.emailReferralId = ''
        this.$router.push('/inbox')
      } catch (e) {
        this.emailReferralId = ''
        this.loading = false
      }
    },
    handleEmailReferralLoggedOut() {
      this.setReferralMessageId(this.emailReferralId)
      this.emailReferralId = ''
      this.loading = false
      this.$bvModal.show('modal-auth')
    },
    handleScroll() {
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        this.stickyHeader = true
      } else {
        this.stickyHeader = false
      }
    },
    setInitialAuthModal(e) {
      this.initialAuthForm = e
    },
    setPageTheme () {
      const theme = this.$route.path.match(/dashboard|inbox|projects|reports|templates/gi)
      if (theme) {
        this.pageTheme = `theme-${theme[0]}`
        this.setTheme(theme[0])
      } else {
        this.pageTheme = 'theme-marketing'
        this.setTheme('marketing')
      }
    }
  }
}
