import { render, staticRenderFns } from "./admin-layout.vue?vue&type=template&id=a48c8288&"
import script from "./admin-layout.vue?vue&type=script&lang=js&"
export * from "./admin-layout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {QuickStartModal: require('/lasso/components/support/QuickStartModal.vue').default,NavMobile: require('/lasso/components/NavMobile.vue').default,Header: require('/lasso/components/Header.vue').default,Error: require('/lasso/components/Error.vue').default,PageHeader: require('/lasso/components/PageHeader.vue').default,AdminHeader: require('/lasso/components/admin/AdminHeader.vue').default,Breadcrumb: require('/lasso/components/Breadcrumb.vue').default,Footer: require('/lasso/components/Footer.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a48c8288')) {
      api.createRecord('a48c8288', component.options)
    } else {
      api.reload('a48c8288', component.options)
    }
    module.hot.accept("./admin-layout.vue?vue&type=template&id=a48c8288&", function () {
      api.rerender('a48c8288', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "layouts/admin-layout.vue"
export default component.exports