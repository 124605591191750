import countriesWithStates from '@/server/utils/countriesList'

export const state = () => ({
  countries: [],
  states: [],
  counties: [],
  communities: [],
  country: null,
  countryName: null,
  state: null,
  stateName: null,
  county: null,
  community: null
})

export const getters = {
  getCountries: state => state.countries,
  getStates: state => state.states,
  getCounties: state => state.counties,
  getCommunities: state => state.communities
}

export const actions = {
  setCountry({ commit }, { country = null, countryName = null }) {
    commit('setCountry', country)
    if (!country) {
      countryName = null
    }
    commit('setCountryName', countryName)
  },
  setState({ commit }, { state = null, stateName = null }) {
    commit('setState', state)
    if (!state) {
      stateName = null
    }
    commit('setStateName', stateName)
  },
  setCounty({ commit }, { county = null }) {
    commit('setCounty', county)
  },
  setCommunity({ commit }, { community = null }) {
    commit('setCommunity', community)
  },
  fetchCountries({ state, commit }, { countryName = null }) {
    try {
      const countries = countriesWithStates.reduce((acc, country) => {
        if (countryName && !(new RegExp(`^${countryName}`, 'i')).test(country.name)) {
          return acc
        }
        if (state.state && !country.states.some(({ name }) => name === state.stateName)) {
          return acc
        }
        acc.push(country)
        return acc
      }, [])
      commit('setCountries', countries)
      return true
    } catch (err) {
      console.error('locations/fetchCounties err:', err)
      return false
    }
  },
  fetchStates({ state, commit }, { stateName = null }) {
    try {
      const states = countriesWithStates.reduce((acc, { name: countryName, states }) => {
        if (state.country && state.countryName !== countryName) {
          return acc
        }
        if (stateName) {
          acc.push(...states.filter(({ name }) => (new RegExp(`^${stateName}`, 'i')).test(name)))
        } else {
          acc.push(...states)
        }
        return acc
      }, [])
      commit('setStates', states)
      return true
    } catch (err) {
      console.error('locations/fetchStates err:', err)
      return false
    }
  },
  async fetchCounties({ state, dispatch, commit }, { countyName = null }) {
    try {
      const {
        country: countryName,
        state: stateName,
        community: communityName
      } = state
      const counties = await this.$axios.$get('/lois/locations/counties', {
        params: {
          countryName,
          stateName,
          countyName,
          communityName
        }
      })
      commit('setCounties', counties)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'locations/fetchCounties', info: err },
        { root: true })
    }
  },
  async fetchCommunities({ state, dispatch, commit }, { communityName = null }) {
    try {
      const {
        country: countryName,
        state: stateName,
        county: countyName
      } = state
      const communities = await this.$axios.$get('/lois/locations/communities', {
        params: {
          countryName,
          stateName,
          countyName,
          communityName
        }
      })
      commit('setCommunities', communities)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'locations/fetchCommunities', info: err },
        { root: true })
    }
  },
  updateCountry({ state, commit }, { countryName = null }) {
    const country = state.countries.find(({ name }) => name === countryName)
    if (country) {
      commit('setCountry', country?.country_cd)
      commit('setCountryName', country?.name)
    }
  },
  updateState({ state, commit }, { stateName = null }) {
    const ste = state.states.find(({ name }) => name === stateName)
    if (ste) {
      commit('setState', ste?.state_cd)
      commit('setStateName', ste?.name)
    }
  },
  updateCounty({ state, commit }, { countyName = null }) {
    const county = state.counties.find(({ county_nm: name }) => name === countyName)
    if (county) {
      commit('setCounty', county?.county_nm)
    }
  },
  updateCommunity({ state, commit }, { communityName = null }) {
    const community = state.communities.find(({ community }) => community === communityName)
    if (community) {
      commit('setCommunity', community?.community)
    }
  }
}

export const mutations = {
  init(state) {
    state.countries = []
    state.states = []
    state.counties = []
    state.communities = []
    state.country = null
    state.countryName = null
    state.state = null
    state.stateName = null
    state.county = null
    state.community = null
  },
  setCountries(state, countries) {
    state.countries = countries.sort((a, b) => {
      const can = a.name.toLowerCase()
      const cab = b.name.toLowerCase()
      return can < cab ? -1 : can > cab ? 1 : 0
    })
  },
  setCountry(state, country) {
    state.country = country
  },
  setCountryName(state, countryName) {
    state.countryName = countryName
  },
  setStates(state, states) {
    state.states = states.sort((a, b) => {
      const sna = a.name.toLowerCase()
      const snb = b.name.toLowerCase()
      return sna < snb ? -1 : sna > snb ? 1 : 0
    })
  },
  setState(state, newState) {
    state.state = newState
  },
  setStateName(state, newStateName) {
    state.stateName = newStateName
  },
  setCounties(state, counties) {
    state.counties = counties
  },
  setCounty(state, county) {
    state.county = county
  },
  setCommunities(state, communities) {
    state.communities = communities
  },
  setCommunity(state, community) {
    state.community = community
  }
}
