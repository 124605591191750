var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.messageAvailable
    ? _c(
        "b-modal",
        {
          attrs: {
            id: "modal-read-email",
            "hide-footer": "",
            size: "lg",
            "header-class": "read-email-header"
          },
          scopedSlots: _vm._u(
            [
              {
                key: "modal-header",
                fn: function(ref) {
                  var close = ref.close
                  return [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "link" },
                        on: {
                          click: function($event) {
                            return close()
                          }
                        }
                      },
                      [_c("IconClose")],
                      1
                    ),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "logo",
                      attrs: {
                        src: require("assets/images/lasso-logo-default.png")
                      }
                    }),
                    _vm._v(" "),
                    _c("h4", [_vm._v("Welcome To Lasso")])
                  ]
                }
              }
            ],
            null,
            false,
            2258327445
          )
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "read-email-wrap" }, [
            _vm._v("\n    Sorry your message is no longer available.\n  ")
          ])
        ]
      )
    : _c(
        "b-modal",
        {
          attrs: { id: "modal-read-email", "hide-footer": "", size: "lg" },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "link" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_c("IconClose")],
                    1
                  ),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "logo",
                    attrs: {
                      src: require("assets/images/lasso-logo-default.png")
                    }
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Welcome To Lasso - View Your Message")])
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "read-email-wrap" }, [
            _c("div", [
              _c("strong", [_vm._v("From:")]),
              _vm._v(" " + _vm._s(_vm.message.fromEmail))
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("strong", [_vm._v("To:")]),
                _vm._v(" "),
                _vm._l(_vm.message.to, function(to, idx) {
                  return _c("span", { key: to.email }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(to.email) +
                        "\n        " +
                        _vm._s(
                          _vm.message.to.length > 1 &&
                            _vm.message.to.length - 1 !== idx
                            ? ", "
                            : ""
                        ) +
                        "\n      "
                    )
                  ])
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.message.cc.length > 0
              ? _c(
                  "div",
                  [
                    _c("strong", [_vm._v("Cc:")]),
                    _vm._v(" "),
                    _vm._l(_vm.message.cc, function(cc, idx) {
                      return _c("span", { key: cc.email }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(cc.email) +
                            "\n        " +
                            _vm._s(
                              _vm.message.cc.length > 1 &&
                                _vm.message.cc.length - 1 !== idx
                                ? ", "
                                : ""
                            ) +
                            "\n      "
                        )
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c("strong", [_vm._v("Subject:")]),
              _vm._v(" " + _vm._s(_vm.message.subject))
            ]),
            _vm._v(" "),
            _vm.message.projectName !== ""
              ? _c("div", [
                  _c("strong", [_vm._v("Project:")]),
                  _vm._v(" " + _vm._s(_vm.message.projectName) + "\n    ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n      " + _vm._s(_vm.message.bodySummary) + "\n    ")
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "button-action" }, [
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "action-primary",
                      attrs: { variant: "marketing" },
                      on: { click: _vm.handleRegisterClick }
                    },
                    [_vm._v("\n          Reply\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "action-label" }, [
                _vm._v(
                  "\n        To reply to this message, please Register for an account.\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "button-action" }, [
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "action-secondary",
                      attrs: { variant: "templates" },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("modal-read-email")
                        }
                      }
                    },
                    [_vm._v("\n          Dismiss\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "action-label" }, [
                _vm._v(
                  "\n        Or explore the site and come back later.\n      "
                )
              ])
            ])
          ])
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }