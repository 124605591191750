//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
// import { debounce } from 'lodash-es'

export default {
  name: 'AdminHeader',
  props: {
    pageName: {
      type: String,
      default: 'Admin'
    }
  },
  data() {
    return {
      display: {
        users: ['new-user', 'refresh', 'filter', 'search'],
        userAdd: ['list'],
        userEdit: ['list', 'details', 'new-user'],
        userDetails: ['list', 'new-user'],
        organizations: ['organization-list'],
        organizationAdd: ['organization-list'],
        organizationEdit: ['organization-list', 'organization-details', 'new-organization'],
        organizationDetails: ['organization-list', 'new-organization']
      },
      refreshing: '',
      isOwnerView: true
    }
  },
  computed: {
    ...mapGetters({
      adminOptions: 'user/getAdminOptions',
      isDesktop: 'breakpoints/desktop',
      userRole: 'user/getUserRole',
      isOrgAdmin: 'user/isOrgAdmin',
      organization: 'organizations/getActiveOrg',
      user: 'user/user'
    }),
    isRefreshing() {
      return this.refreshing !== ''
    },
    showHeaderActions() {
      return this.isOrgAdmin || this.$route.name !== 'admin'
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.showButton()
      }
    }
  },
  created() {
    // enable this if we want to search after 300 ms
    // this.searchUsers = debounce(this.searchUsers, 300)
  },
  methods: {
    ...mapActions({
      fetchUserListAdmin: 'user/fetchUserList',
      fetchUserListAdminOrganization: 'user/fetchOrganizationUserList',
      getOrgLimits: 'organizations/getOrgLimits',
      setSearchTerm: 'user/setUserListSearchTerm',
      startLoading: 'global/startLoading',
      stopLoading: 'global/stopLoading'
    }),
    clearSearch(e) {
      this.$refs.search.newValue = ''
      this.searchUsers('')
    },
    async fetchUserList() {
      if (this.isOrgAdmin) {
        await this.fetchUserListAdminOrganization()
      } else {
        await this.fetchUserListAdmin()
      }
    },
    async handleAction(action) {
      const path = this.activeUserId ? this.activeUserId : this.$route.params.userid
      const orgPath = this.activeOrgId ? this.organization._id : this.$route.params.organizationid
      switch (action) {
        case 'details':
          this.$router.push(`/admin/${path}`)
          break
        case 'edit-user':
          this.$router.push(`/admin/${path}/edit`)
          break
        case 'list':
          this.$router.push({ name: 'admin' })
          break
        case 'organization-list':
          this.$router.push({ name: 'admin', params: { organization: true } })
          break
        case 'organization-details':
          this.$router.push(`/admin/organizations/${orgPath}`)
          break
        case 'new-user': {
          const orgLimits = await this.getOrgLimits({ _id: this.user?.organization?._id })
          if (orgLimits.userLimit === 0 || orgLimits.userCount < orgLimits.userLimit) {
            this.$router.push({ name: 'admin-add' })
          } else {
            this.$bvModal.show('modal-license-unavailable')
          }
          break
        }
        case 'new-organization':
          this.$router.push({ name: 'admin-organizations-add' })
          break
        case 'refresh':
          this.refreshing = 'refresh'
          this.startLoading()
          await this.fetchUserList()
          this.refreshing = ''
          this.stopLoading()
          break
        default:
          break
      }
    },
    searchUsers(e) {
      this.setSearchTerm(e)
    },
    showButton(btn) {
      switch (this.$route.name) {
        case 'admin':
          return this.display.users.includes(btn)

        case 'admin-add':
          return this.display.userAdd.includes(btn)

        case 'admin-userid-edit':
          return this.display.userEdit.includes(btn)

        case 'admin-userid':
          return this.display.userDetails.includes(btn)

        case 'admin-organizations-organizationid':
          return this.display.organizationDetails.includes(btn)

        case 'admin-organizations-add':
          return this.display.organizationAdd.includes(btn)

        case 'admin-organizations-organizationid-edit':
          return this.display.organizationEdit.includes(btn)

        default:
          return false
      }
    }
  }
}
