import { isEqual, isObject } from 'lodash'

// eslint-disable-next-line
Array.prototype.equals = function (array) {
  // if other array is falsy
  if (!array || !Array.isArray(array)) {
    return false
  }
  // compare lengths
  if (this.length !== array.length) {
    return false
  }
  for (let i = 0, l = this.length; i < l; i++) {
    // check for nested arrays
    if (Array.isArray(array[i]) && Array.isArray(this[i])) {
      if (!this[i].equalsArray(array[i])) {
        return false
      }
    } else if (isObject(this[i]) &&
               isObject(array[i]) &&
              !isEqual(this[i], array[i])) {
      return false
    } else if (this[i] !== array[i]) {
      return false
    }
  }
  return true
}
