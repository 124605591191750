import { render, staticRenderFns } from "./marketing.vue?vue&type=template&id=5d276e82&"
import script from "./marketing.vue?vue&type=script&lang=js&"
export * from "./marketing.vue?vue&type=script&lang=js&"
import style0 from "./marketing.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {AuthModal: require('/lasso/components/user/AuthModal.vue').default,ReadEmailModal: require('/lasso/components/inbox/ReadEmailModal.vue').default,IconHamburger: require('/lasso/components/icons/IconHamburger.vue').default,NavMobile: require('/lasso/components/NavMobile.vue').default,Header: require('/lasso/components/Header.vue').default,Footer: require('/lasso/components/Footer.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d276e82')) {
      api.createRecord('5d276e82', component.options)
    } else {
      api.reload('5d276e82', component.options)
    }
    module.hot.accept("./marketing.vue?vue&type=template&id=5d276e82&", function () {
      api.rerender('5d276e82', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "layouts/marketing.vue"
export default component.exports