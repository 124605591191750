var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "categories-questions" },
    [
      _c("ImportTemplateQuestionsModal", {
        attrs: { "project-id": _vm.project._id },
        on: { imported: _vm.refreshCategories }
      }),
      _vm._v(" "),
      _vm.loadingCategoriesQuestions ? _c("LoadingOverlay") : _vm._e(),
      _vm._v(" "),
      _vm.showBackToTopButton
        ? _c(
            "b-button",
            {
              staticClass: "top",
              attrs: { variant: "inbox" },
              on: { click: _vm.goToTop }
            },
            [_c("b-icon", { attrs: { icon: "arrow-up" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "categories", attrs: { id: "categories-list" } },
            [
              _c(
                "b-card",
                {
                  attrs: {
                    "header-bg-variant": "inbox",
                    "header-text-variant": "white"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.sortCategories
                                  ? "Categories Manager"
                                  : "Categories"
                              ) +
                              "\n          "
                          ),
                          _vm.showCategoryButtons
                            ? _c(
                                "div",
                                { staticClass: "category-buttons" },
                                [
                                  _vm.showEditCategoryButton
                                    ? _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true }
                                            }
                                          ],
                                          staticClass: "icon-button",
                                          attrs: {
                                            variant: "outline-light",
                                            size: "sm",
                                            title: !_vm.editCategory
                                              ? "Edit Category"
                                              : "Cancel Edit"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              _vm.editCategory = !_vm.editCategory
                                              _vm.categoriesOpen = true
                                            }
                                          }
                                        },
                                        [
                                          _vm.editCategory
                                            ? _c(
                                                "span",
                                                [
                                                  _c("b-icon", {
                                                    attrs: { icon: "close" }
                                                  })
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      icon:
                                                        "folder-edit-outline"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  (_vm.isProjectOwner ||
                                    _vm.isOrgAdmin ||
                                    _vm.isListAdmin ||
                                    _vm.isSuperAdmin) &&
                                  _vm.questionDisplayType === "detail"
                                    ? _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true }
                                            }
                                          ],
                                          staticClass: "icon-button",
                                          attrs: {
                                            variant: "outline-light",
                                            size: "sm",
                                            title: !_vm.sortCategories
                                              ? "Manage Categories"
                                              : "Go Back to Questions"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              _vm.sortCategories = !_vm.sortCategories
                                            }
                                          }
                                        },
                                        [
                                          _vm.sortCategories
                                            ? _c(
                                                "span",
                                                [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      icon: "backburger"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      icon: "cursor-move"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isMobile && !_vm.sortCategories
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "icon-button",
                                          attrs: {
                                            variant: "outline-light",
                                            size: "sm"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.categoriesOpen = !_vm.categoriesOpen
                                            }
                                          }
                                        },
                                        [
                                          !_vm.categoriesOpen
                                            ? _c("b-icon", {
                                                attrs: { icon: "plus" }
                                              })
                                            : _c("b-icon", {
                                                attrs: { icon: "minus" }
                                              })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _vm.sortCategories
                    ? _c("CategoriesManager", {
                        on: {
                          cancelSort: function($event) {
                            _vm.sortCategories = false
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.sortCategories
                    ? _c(
                        "b-collapse",
                        {
                          model: {
                            value: _vm.categoriesOpen,
                            callback: function($$v) {
                              _vm.categoriesOpen = $$v
                            },
                            expression: "categoriesOpen"
                          }
                        },
                        [
                          (_vm.primaryCategories.length &&
                            !_vm.uncategorizedOnly) ||
                          (_vm.uncategorizedOnly && _vm.questions.length)
                            ? _c(
                                "div",
                                _vm._l(_vm.primaryCategories, function(
                                  primary,
                                  idx
                                ) {
                                  return _c(
                                    "div",
                                    { key: primary },
                                    [
                                      _c("Category", {
                                        attrs: {
                                          category: primary,
                                          edit: _vm.editCategory,
                                          index: idx
                                        },
                                        on: {
                                          "cancel-edit": function($event) {
                                            _vm.editCategory = false
                                          },
                                          "loading-questions": function(e) {
                                            return (_vm.loadingQuestions = e)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _c("div", { staticClass: "no-categories" }, [
                                _c("h5", [
                                  _vm._v(
                                    "There are no categories or questions to display."
                                  )
                                ])
                              ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.sortCategories
            ? _c(
                "b-col",
                {
                  staticClass: "questions",
                  attrs: { id: "question-list", md: "8" }
                },
                [
                  _c(
                    "b-card",
                    {
                      attrs: {
                        "header-bg-variant": "inbox",
                        "header-text-variant": "white"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _c(
                                  "b-row",
                                  {
                                    style: {
                                      "align-items": "center",
                                      width: "100%"
                                    },
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _c("b-col", [
                                      _vm.primaryCategory ||
                                      _vm.secondaryCategory
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                Questions For: "
                                            ),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(_vm.primaryCategory) +
                                                  " "
                                              ),
                                              _vm.secondaryCategory
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("b-icon", {
                                                        attrs: {
                                                          icon: "chevron-right"
                                                        }
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.secondaryCategory
                                                          )
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ])
                                          ])
                                        : _vm.activeCategory === "" &&
                                          _vm.primaryCategories.length > 1
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                Question List: Select a Category to begin\n              "
                                            )
                                          ])
                                        : _vm.activeCategory === "Uncategorized"
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                Questions For: "
                                            ),
                                            _c("strong", [
                                              _vm._v("Uncategorized")
                                            ])
                                          ])
                                        : _c("span", [_vm._v("Question List")])
                                    ]),
                                    _vm._v(" "),
                                    _vm.questionsSelectActive &&
                                    _vm.questions &&
                                    _vm.questions.length > 0
                                      ? _c(
                                          "b-col",
                                          {
                                            staticClass: "ml-auto",
                                            attrs: { sm: "4", xl: "3" }
                                          },
                                          [
                                            _c(
                                              "b-field",
                                              {
                                                staticClass: "select-all-check"
                                              },
                                              [
                                                _c(
                                                  "b-checkbox-button",
                                                  {
                                                    attrs: { size: "is-small" },
                                                    on: {
                                                      input:
                                                        _vm.handleSelectQuestions
                                                    },
                                                    model: {
                                                      value: _vm.selectAll,
                                                      callback: function($$v) {
                                                        _vm.selectAll = $$v
                                                      },
                                                      expression: "selectAll"
                                                    }
                                                  },
                                                  [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: _vm.selectAll
                                                          ? "close"
                                                          : "check",
                                                        size: "is-small"
                                                      }
                                                    }),
                                                    _vm._v(
                                                      " \n                  " +
                                                        _vm._s(
                                                          _vm.selectAll
                                                            ? "Deselect Category"
                                                            : "Select Category"
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3241525178
                      )
                    },
                    [
                      _vm._v(" "),
                      _vm.activeCategory !== "" &&
                      (_vm.primaryCategory || _vm.secondaryCategory) &&
                      !_vm.loadingQuestions &&
                      !_vm.questions.length
                        ? _c(
                            "b-row",
                            {
                              staticClass: "no-questions",
                              class: {
                                noninteractive:
                                  !_vm.isProjectOwner && !_vm.projectIsActive
                              }
                            },
                            [
                              _c("b-col", [
                                _vm._v(
                                  "No questions to display for selected category"
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("QuestionList", {
                        class: { noninteractive: _vm.noninteractive },
                        attrs: {
                          "loading-questions": _vm.loadingQuestions,
                          "display-type": _vm.questionDisplayType,
                          mode: _vm.mode
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }