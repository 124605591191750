// Utilities for date manipulations
import moment from 'moment'

export const localizeDate = (date) => {
  // Dates are stored as UTC in the database, to localize the date/time we need
  // to coerce UTC to the local date/time.  First, we need the difference between
  // the stored UTC time and our current timezone
  const tzOffsetInMinutes = new Date(date).getTimezoneOffset()
  // Add those minutes to the date/time as adjustedDate
  const adjustedDate = moment(date).add(tzOffsetInMinutes, 'minutes')
  // Get the local timezone
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone
  // Convert and return the adjustedDate to the local timezone
  const localDate = adjustedDate.format()
  return new Date(new Date(localDate).toLocaleString('en-US', { timeZone: localTz }))
}

export const formatLongDate = (date) => {
  return moment(date).format('ddd MMM DD, YYYY h:mm a')
}

export const formatProjectDate = (date) => {
  return moment(date).format('MMM DD, YYYY')
}

export const formatProjectDateLong = (date) => {
  return moment(date).format('dddd, MMMM Do YYYY')
}

export const formatShortDate = (date) => {
  return moment(date).format('M/DD/YY h:mm a')
}

export const formatShortDateNoTime = (date) => {
  return moment(date).format('M/DD/YY')
}
