var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "answer-history" },
    [
      _c(
        "b-row",
        { staticClass: "small" },
        [
          _c("b-col", [_c("strong", [_vm._v("Date")])]),
          _vm._v(" "),
          _c("b-col", [_c("strong", [_vm._v("Created By")])]),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("strong", [_vm._v("Answer")])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.answerList, function(answer) {
        return _c(
          "b-row",
          {
            key: answer._id,
            staticClass: "answer-history-item small",
            attrs: { "no-gutters": "" }
          },
          [
            _c("b-col", [
              _c("span", [_vm._v(_vm._s(_vm.createdDate(answer)))])
            ]),
            _vm._v(" "),
            _c("b-col", [_c("span", [_vm._v(_vm._s(_vm.createdBy(answer)))])]),
            _vm._v(" "),
            _c("b-col", { attrs: { cols: "6" } }, [
              _c("span", [_vm._v(_vm._s(_vm.answerValue(answer)))])
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }