// Utilities for string manipulations
import countriesWithStates from './countriesList'

export const uppercaseFirst = (string) => {
  if (!string) {
    return
  }
  if (string.includes(' ')) {
    return string.split(' ').map(part => uppercaseFirst(part)).join(' ')
  }
  return string.charAt(0)?.toUpperCase() + string.slice(1)
}

// expects country code abbreviation
export const formatCountry = (country) => {
  const selectedCountry = countriesWithStates.find(c => c.country_cd === country.toUpperCase()) || null
  return selectedCountry ? selectedCountry.name : country.toUpperCase()
}

// expects country and state code abbreviationss
export const formatState = (country, state) => {
  const selectedCountry = countriesWithStates.find(c => c.country_cd === country.toUpperCase()) || null
  const states = selectedCountry ? selectedCountry.states : null
  const selectedState = states.find(s => s.state_cd === state.toUpperCase())
  return selectedState ? selectedState.name : state
}

// expects property object with basicInfo and location key
export const addressFullStateAndCountry = (property = {}) => {
  let formatted = ''
  const { street = '', suite = '', city = '', state = '', zip = '', country = '' } = property?.basicInfo?.location || {}
  if (street) {
    formatted += `${street}<br>`
  }
  if (suite) {
    formatted += `Suite ${suite}<br>`
  }
  if (city) {
    formatted += city
  }
  if (city && state) {
    formatted += ', '
  }
  if (state) {
    formatted += state
  }
  if (zip) {
    formatted += ` ${zip}`
  }
  if (country) {
    formatted += `<br>${formatCountry(country)}`
  }
  return formatted
}
