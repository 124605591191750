var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-edit",
      attrs: {
        "data-name": "icon-edit",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d:
            "M19.3,4.93V3.79a.86.86,0,0,0-.86-.86H5.57a.86.86,0,0,0-.86.86v16.7a.86.86,0,0,0,.86.86H18.44a.86.86,0,0,0,.86-.86v-3.8"
        }
      }),
      _vm._v(" "),
      _c("polygon", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          points:
            "21.89 10.31 14.82 17.39 12.39 17.39 12.38 15.12 19.54 7.96 21.89 10.31"
        }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "19.77 7.73 21.68 5.82 24.03 8.17 22.1 10.11" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }