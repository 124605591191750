//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SectionListItem',
  props: {
    showLoading: {
      type: Boolean,
      default: false
    }
  }
}
