//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DefaultLayout',
  data () {
    return {
      pageTheme: ''
    }
  },
  computed: {
    ...mapGetters({
      error: 'global/networkError',
      stack: 'global/networkErrorStack',
      isDesktop: 'breakpoints/desktop',
      isMobile: 'breakpoints/mobile',
      navOpen: 'global/navOpen',
      currentTheme: 'global/pageTheme',
      folder: 'messages/getActiveMessageFolder'
    }),
    hasCustomLayout() {
      const custom = [
        'projects',
        'templates',
        'admin',
        'inbox' // no custom layout yet but need to return true for inbox so page header doesn't render
      ]
      return custom.includes(this.currentTheme)
      // return this.currentTheme.includes(this.$route.name)
    },
    showError() {
      return this.error && this.error?.info?.status !== 401
    },
    pageBreadcrumbs() {
      return this.pageMeta?.breadcrumbs || []
    },
    pageName() {
      return this.pageMeta?.name || 'Admin'
    },
    pageMeta() {
      // TODO: reports will likely have its own layout
      // const { questionnaireid } = this.$route.params
      const pages = [
        {
          name: 'Dashboard',
          routeName: 'dashboard',
          breadcrumbs: [
          ]
        },
        {
          name: 'Inbox',
          routeName: 'inbox',
          breadcrumbs: [
          ]
        },
        {
          name: 'My Questionnaires',
          routeName: 'questionnaires',
          breadcrumbs: [
          ]
        },
        {
          name: 'Questionnaire',
          routeName: 'questionnaires-questionnaireid',
          breadcrumbs: [
            // {
            //   text: 'Questionnaires',
            //   href: '/questionnaires'
            // },
            // {
            //   text: 'Questionnaire Detail',
            //   href: `/questionnaires/${questionnaireid}`,
            //   active: true
            // }
          ]
        },
        {
          name: 'Privacy',
          routeName: 'privacy',
          breadcrumbs: [
          ]
        },
        {
          name: 'Reports',
          routeName: 'reports',
          breadcrumbs: [
          ]
        },
        {
          name: 'Sales and Support',
          routeName: 'support',
          breadcrumbs: [
          ]
        },
        {
          name: 'Getting Started',
          routeName: 'support-how-to',
          breadcrumbs: [
          ]
        },
        {
          name: 'Terms',
          routeName: 'terms',
          breadcrumbs: [
          ]
        },
        {
          name: 'User Profile',
          routeName: 'profile',
          breadcrumbs: [
          ]
        }
      ]
      const meta = pages.filter(p => p.routeName === this.$route.name)
      return meta[0]
    }
  },
  watch: {
    $route () {
      this.setPageTheme()
    }
  },
  mounted () {
    this.setPageTheme()
    this.watchBreakpoints()
  },
  methods: {
    ...mapActions({
      setTheme: 'global/setPageTheme',
      toggleMenu: 'global/toggleMenu',
      watchBreakpoints: 'breakpoints/watch'
    }),
    setPageTheme () {
      const theme = this.$route.path.match(/dashboard|inbox|projects|reports|templates|profile/gi)
      if (theme) {
        this.pageTheme = `theme-${theme[0]}`
        this.setTheme(theme[0])
      } else {
        this.pageTheme = 'theme-default'
        this.setTheme('default')
      }
    }
  }
}
