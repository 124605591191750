var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-mobile", class: { open: _vm.navOpen } },
    [
      _c(
        "b-button",
        {
          staticClass: "nav-close",
          attrs: { variant: "link" },
          on: {
            click: function($event) {
              return _vm.toggleMenu([])
            }
          }
        },
        [_c("b-icon", { attrs: { icon: "close", size: "is-medium" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-navbar-brand",
        {
          attrs: { to: "/" },
          on: {
            click: function($event) {
              return _vm.toggleMenu([])
            }
          }
        },
        [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("assets/images/lasso-logo-default.png") }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "nav-mobile-content" }, [
        _vm.loggedInUser
          ? _c("div", { staticClass: "user-profile" }, [
              _c("p", [
                _vm._v("Welcome,"),
                _c("br"),
                _vm._v(_vm._s(_vm.fullName))
              ]),
              _vm._v(" "),
              _c("hr")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.loggedInUser
          ? _c(
              "div",
              { staticClass: "nav-wrapper" },
              [
                _c("NavItems", { attrs: { items: _vm.mobileNavItemsLoggedIn } })
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "nav-wrapper" },
              [
                _c(
                  "b-nav",
                  [
                    _c(
                      "b-nav-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.goToSection("how-it-works")
                          }
                        }
                      },
                      [_vm._v("\n          How It Works\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-nav-item",
                      {
                        attrs: { to: "#features" },
                        on: {
                          click: function($event) {
                            return _vm.goToSection("features")
                          }
                        }
                      },
                      [_vm._v("\n          Features\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-nav-item",
                      {
                        attrs: { to: "#integrations" },
                        on: {
                          click: function($event) {
                            return _vm.goToSection("integrations")
                          }
                        }
                      },
                      [_vm._v("\n          Integrations\n        ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "b-nav",
                  [
                    _c("b-nav-item", { on: { click: _vm.requestDemo } }, [
                      _vm._v("\n          Contact Us\n        ")
                    ]),
                    _vm._v(" "),
                    !_vm.loggedInUser
                      ? _c(
                          "b-nav-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.openAuthModal("login")
                              }
                            }
                          },
                          [_vm._v("\n          Login\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.loggedInUser
                      ? _c(
                          "b-nav-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.openAuthModal("register")
                              }
                            }
                          },
                          [_vm._v("\n          Sign Up\n        ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
        _vm._v(" "),
        _vm.loggedInUser
          ? _c(
              "div",
              { staticClass: "nav-wrapper" },
              [
                _c("hr"),
                _vm._v(" "),
                _c(
                  "b-nav",
                  [
                    _c(
                      "b-nav-item",
                      {
                        attrs: { to: "/profile" },
                        on: {
                          click: function($event) {
                            return _vm.toggleMenu([])
                          }
                        }
                      },
                      [_vm._v("\n          Profile\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-nav-item",
                      {
                        on: {
                          click: function($event) {
                            _vm.logMeOut()
                            _vm.toggleMenu([])
                          }
                        }
                      },
                      [_vm._v("\n          Logout\n        ")]
                    ),
                    _vm._v(" "),
                    _vm.showStopImpersonation
                      ? _c(
                          "b-nav-item",
                          {
                            on: {
                              click: function($event) {
                                _vm.logOutImpersonatedUser()
                                _vm.toggleMenu([])
                              }
                            }
                          },
                          [_vm._v("\n          Stop Impersonation\n        ")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "b-nav",
                  [
                    _c(
                      "b-nav-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.goToLink("http://admin.loisedapp.com")
                          }
                        }
                      },
                      [_vm._v("\n          LOIS Admin\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-nav-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.goToLink("http://app.loisedapp.com")
                          }
                        }
                      },
                      [_vm._v("\n          LOIS Map Viewer\n        ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-nav",
                  [
                    _c(
                      "b-nav-item",
                      {
                        attrs: { to: "/privacy" },
                        on: {
                          click: function($event) {
                            return _vm.toggleMenu([])
                          }
                        }
                      },
                      [_vm._v("\n          Privacy\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-nav-item",
                      {
                        attrs: { to: "/terms" },
                        on: {
                          click: function($event) {
                            return _vm.toggleMenu([])
                          }
                        }
                      },
                      [_vm._v("\n          Terms\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "nav-wrapper" }, [
          _c("hr"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "social" },
            [
              _c(
                "b-link",
                {
                  staticClass: "linkedin",
                  attrs: {
                    href:
                      "https://www.linkedin.com/showcase/lasso-data-collection-app",
                    target: "_blank"
                  }
                },
                [_c("IconLinkedIn", { attrs: { title: "LinkedIn" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "copyright" },
            [
              _vm._v("\n        @2024 Strata Platforms. All rights reserved."),
              _c("br"),
              _vm._v(" "),
              _c("b-link", { attrs: { to: "/privacy" } }, [
                _vm._v("\n          Privacy\n        ")
              ]),
              _vm._v("\n         | \n        "),
              _c("b-link", { attrs: { to: "/terms" } }, [
                _vm._v("\n          Terms\n        ")
              ]),
              _vm._v("\n         | \n        "),
              _c("b-link", { attrs: { to: "/support" } }, [
                _vm._v("\n          Support\n        ")
              ])
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }