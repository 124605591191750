import { render, staticRenderFns } from "./AuthModal.vue?vue&type=template&id=1e47ec08&"
import script from "./AuthModal.vue?vue&type=script&lang=js&"
export * from "./AuthModal.vue?vue&type=script&lang=js&"
import style0 from "./AuthModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {IconClose: require('/lasso/components/icons/IconClose.vue').default,Template: require('/lasso/components/templates/Template.vue').default,Login: require('/lasso/components/user/Login.vue').default,Register: require('/lasso/components/user/Register.vue').default,Reset: require('/lasso/components/user/Reset.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1e47ec08')) {
      api.createRecord('1e47ec08', component.options)
    } else {
      api.reload('1e47ec08', component.options)
    }
    module.hot.accept("./AuthModal.vue?vue&type=template&id=1e47ec08&", function () {
      api.rerender('1e47ec08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/user/AuthModal.vue"
export default component.exports