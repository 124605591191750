//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'CategoriesSort',
  components: {
    draggable
  },
  props: {
    category: { // represents the primary category
      type: Object,
      default: () => {}
    },
    categoryIndex: {
      type: Number,
      default: null
    },
    originalSortHasChanged: {
      type: Boolean,
      default: false
    },
    primaryCategories: {
      type: Array,
      default: () => []
    },
    categoryOwner: {
      type: String,
      default: null
    },
    categoryType: {
      type: Number,
      default: null
    },
    showSecondaries: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      categoryToEdit: null,
      categoryRenameActive: false,
      savingCategory: false
    }
  },
  computed: {
    ...mapGetters({
      activeCategory: 'questions/getActiveCategory',
      primaryCategory: 'questions/getPrimaryCategory',
      secondaryCategory: 'questions/getSecondaryCategory'
    }),
    activeSecondaryCategories: {
      get() {
        return this.category.secondaries
      },
      set(newValue) {
        this.category.secondaries = newValue
      }
    },
    availableCategoriesForPrimaryMove() {
      return this.primaryCategories.filter(primary => primary.name !== this.category.name)
    },
    disableSecondarySort() {
      return this.primaryCategories.includes(this.activeCategory)
    },
    primaryCategoryUniqueId() {
      const categoryFlattened = this.category.name.replace(' ', '-').toLowerCase()
      return `${categoryFlattened}_${this.categoryIndex.toString()}`
    }
  },
  methods: {
    ...mapActions({
      setActiveCategory: 'questions/setActiveCategory',
      setPrimaryCategory: 'questions/setPrimaryCategory',
      setSecondaryCategory: 'questions/setSecondaryCategory'
    }),
    cancelCategoryActions() {
      this.categoryToEdit = null
      this.categoryRenameActive = false
      this.setActiveCategory(null)
      this.setPrimaryCategory(null)
      this.setSecondaryCategory(null)
    },
    secondaryCategoryUniqueId(secondary, idx) {
      const categoryFlattened = secondary.replace(' ', '-').toLowerCase()
      return `${categoryFlattened}_${this.categoryIndex.toString()}_${idx}`
    },
    // TODO: this is the function that does not correctly set the focus to the input, it briefly sets it, then tabs to the save btn
    setInvalidCategoryRename({ prevName, categoryUniqueId }) {
      this.categoryToEdit = prevName
      this.$refs[categoryUniqueId].focus()
    },
    toggleRenameCategory({ category, categoryUniqueId, active = false }) {
      if (active) {
        this.categoryRenameActive = true
        this.categoryToEdit = category.name
        this.$nextTick(() => {
          const ref = this.$refs[categoryUniqueId]
          if (ref) {
            ref.focus()
          }
        })
      } else {
        this.categoryRenameActive = false
        this.categoryToEdit = null
      }
    },
    setSelectedCategoryRow({ level, category, categoryParent = null }) {
      if (this.originalSortHasChanged) {
        this.unsavedCategoryOrderNotification()
      } else {
        this.setActiveCategory(category)
        if (level === 'primary') {
          this.setPrimaryCategory(category)
          this.setSecondaryCategory(null)
        } else {
          this.setPrimaryCategory(categoryParent)
          this.setSecondaryCategory(category)
        }
      }
    },
    async unsavedCategoryOrderNotification() {
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'You have unsaved changes to the Categories Sort Order. Changes must be ',
          h('strong', 'Saved'),
          ' or ',
          h('strong', 'Reset'),
          ' back to original order before selecting a row for editing. '
        ]
      )
      await this.$bvModal.msgBoxOk([customMsg], {
        title: 'Unsaved Category Order Changes!',
        okVariant: 'warning',
        okTitle: 'OK',
        cancelVariant: 'light',
        footerBgVariant: 'dark',
        headerBgVariant: 'warning',
        hideHeaderClose: true,
        centered: true
      })
    }
  }
}
