export const state = () => ({
  navOpen: false,
  loading: false,
  pageTheme: '',
  networkError: null,
  networkErrorStack: null
})

export const getters = {
  navOpen: state => state.navOpen,
  networkError: state => state.networkError,
  networkErrorStack: state => state.networkErrorStack,
  loading: state => state.loading,
  pageTheme: state => state.pageTheme
}

export const actions = {

  loginInit({ commit }) {
    commit('messages/init', null, { root: true })
    commit('projects/init', null, { root: true })
    commit('properties/init', null, { root: true })
    commit('participants/init', null, { root: true })
    commit('questions/init', null, { root: true })
    commit('templates/init', null, { root: true })
    commit('answers/init', null, { root: true })
    commit('resetNetworkError')
  },

  logoutInit({ commit }) {
    commit('user/init', null, { root: true })
    commit('messages/init', null, { root: true })
    commit('projects/init', null, { root: true })
    commit('properties/init', null, { root: true })
    commit('participants/init', null, { root: true })
    commit('questions/init', null, { root: true })
    commit('templates/init', null, { root: true })
    commit('answers/init', null, { root: true })
    commit('resetNetworkError')
  },

  resetNetworkError({ commit }) {
    commit('resetNetworkError')
  },

  async setNetworkError({ commit, dispatch }, error) {
    const getVal = (obj, value) => {
      let result = null
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          result = getVal(obj[i])
          if (result) {
            break
          }
        }
      } else {
        for (const prop in obj) {
          if (prop === value) {
            return obj[value]
          }
          if (obj[prop] instanceof Object || Array.isArray(obj[prop])) {
            result = getVal(obj[prop])
            if (result) {
              break
            }
          }
        }
      }
      return result
    }
    const status = error.info.response?.status
    const stack = error.info.stack
    let message = getVal(error.info, 'message')
    const data = getVal(error.info, 'data')
    if (status === 403) {
      await dispatch('user/logout', null, { root: true })
      this.$router.push('/')
      return true
    }
    if (!message && data && data.message !== '') {
      message = data.message
    } else if (!message && data !== '') {
      message = data
    } else {
      message = 'unknown error'
    }
    if (message === 'jwt expired') {
      const res = await dispatch('user/refreshToken', null, { root: true })
      if (res) {
        await dispatch(error.method, null, { root: true })
        return
      }
    }
    commit('setNetworkError', error)
    commit('setNetworkErrorStack', stack)
  },
  setPageTheme ({ commit }, theme) {
    commit('setPageTheme', theme)
  },

  startLoading ({ commit }) {
    commit('startLoading')
  },

  stopLoading ({ commit }) {
    commit('stopLoading')
  },

  toggleMenu ({ commit }, [open]) {
    commit('toggleMenu', ['navOpen', open])
  }
}

export const mutations = {
  resetNetworkError(state) {
    state.networkError = null
    state.networkErrorStack = null
  },

  setNetworkErrorStack(state, stack) {
    state.networkErrorStack = stack
  },

  setNetworkError(state, error) {
    state.networkError = error
  },

  setPageTheme (state, theme) {
    state.pageTheme = theme
  },

  toggleMenu (state, [menu, open]) {
    if (open === true) {
      state[menu] = true
    } else if (open === false) {
      state[menu] = false
    } else {
      state[menu] = !state[menu]
    }
  },

  startLoading (state) {
    state.loading = true
  },

  stopLoading (state) {
    state.loading = false
  }
}
