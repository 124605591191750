var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-import-template",
      attrs: {
        "data-name": "icon-import-template",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 25 25"
      }
    },
    [
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { x1: "14.14", y1: "13.23", x2: "3.55", y2: "13.23" }
      }),
      _vm._v(" "),
      _c("polyline", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: { points: "10.08 17.47 14.31 13.23 10.12 9.04" }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#444060",
          "stroke-miterlimit": "10"
        },
        attrs: {
          d:
            "M5.38,9.69V4.55a.87.87,0,0,1,.86-.86H19.11a.86.86,0,0,1,.86.86V21.26a.86.86,0,0,1-.86.86H6.24a.87.87,0,0,1-.86-.86V16.45"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }