//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import excelDownload from 'js-file-download'
import { questionOwnerType } from '@/server/constants'

export default {
  name: 'Template',
  data() {
    return {
      file: {},
      errorMsg: null
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'breakpoints/desktop',
      loading: 'global/loading',
      template: 'templates/getActiveTemplate',
      isAdmin: 'user/isAdmin'
    }),
    isSystemTemplate() {
      return !this.template.user && !this.template.org
    },
    showEditImportButtons() {
      return this.isSystemTemplate ? this.isAdmin : true
    }
  },
  methods: {
    ...mapActions({
      startLoading: 'global/startLoading',
      stopLoading: 'global/stopLoading',
      fetchPrimaryCategories: 'questions/fetchPrimaryCategories'
    }),
    addTemplateQuestion(template) {
      // we are prefilling AddEditQuestion categories if user navigates to that page with referred: true only
      this.$router.push({
        path: `/templates/${template._id}/add-question`,
        query: { referred: true }
      })
    },
    editQuestion(question) {
      this.$router.push(`/templates/${this.template._id}/question/${question._id}/edit-question`)
    },
    async refreshCategories() {
      await this.fetchPrimaryCategories({ owner: this.template._id, type: questionOwnerType.template })
      localStorage.removeItem('categoryselections')
    },
    async exportTemplate(template) {
      const response = await this.$axios.get(`/export/template/${template._id}`, { responseType: 'arraybuffer' })
      excelDownload(response.data, 'template-export.xlsx')
    },
    async importTemplate(template) {
      const data = new FormData()
      data.append('template', this.file)
      try {
        const res = await this.$axios.$post(`/import/template/${template._id}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (res.result === 'ok') {
          await this.refreshCategories()
          this.successNotification()
          return
        }
      } catch (error) {
        const { response: { data } = {} } = error
        this.errorMsg = data
        await this.refreshCategories()
      }
    },
    successNotification() {
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'Questions were successfully imported into template ',
          h('strong', `"${this.template.name}". `)
        ]
      )
      this.$bvToast.toast([customMsg], {
        autoHideDelay: 2000,
        noCloseButton: true,
        solid: true,
        toaster: 'b-toaster-top-right',
        title: 'Success',
        variant: 'success'
      })
    }
  }
}
