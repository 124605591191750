export const state = () => ({
  activeOrg: null,
  activeOrgId: null,
  activeOrgLimits: null,
  orgs: [],
  oauth2Orgs: [],
  orgCount: 0,
  adminOrgOptions: {
    filter: 'all',
    sort: '',
    limit: 10,
    skip: 0,
    search: '',
    page: 0
  }
})

export const getters = {
  getActiveOrg: state => state.activeOrg,
  getActiveOrgLimits: state => state.activeOrgLimits,
  getOrgs: state => state.orgs,
  getOrgCount: state => state.orgCount,
  getAdminOrgOptions: state => state.adminOrgOptions,
  getOAuth2Orgs: state => state.oauth2Orgs
}

export const actions = {
  async addOrg({ commit, dispatch }, org) {
    try {
      const orgRes = await this.$axios.$post('/org', org)
      if (orgRes && orgRes._id) {
        commit('addNewOrg', orgRes)
        return orgRes
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'organizations/addOrg', info: err },
        { root: true })
    }
  },

  async fetchOrgList({ commit, state, dispatch }) {
    const {
      filter,
      sort,
      limit,
      page,
      search
    } = state.adminOrgOptions
    const skip = page * limit
    const params = [
      filter ? `filter=${filter}` : '',
      sort ? `sortby=${sort}` : '',
      limit ? `limit=${limit}` : '',
      skip ? `skip=${skip}` : '',
      search ? `search=${search}` : ''
    ]
    try {
      const orgsRes = await this.$axios.$get('/orgs?' + params.filter(p => p).join('&'))
      commit('setOrgCount', orgsRes.count)
      commit('setOrgs', orgsRes.orgs)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'organizations/fetchOrganizationList', info: err },
        { root: true })
    }
  },

  async fetchOAuth2Orgs({ commit, dispatch }) {
    try {
      const orgsRes = await this.$axios.$get('/org/oauth2/list')
      commit('setOAuth2Orgs', orgsRes)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'organizations/getOAuth2Orgs', info: err },
        { root: true })
    }
  },

  async getOrgById({ commit, dispatch }, { _id: orgId }) {
    try {
      const orgRes = await this.$axios.$get(`/org/${orgId}`)
      // TODO: can we get rid of these?
      commit('setActiveOrg', orgRes)
      commit('setActiveOrgId', orgRes?._id)
      return orgRes
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'organizations/getOrg', info: err },
        { root: true })
    }
  },

  async getOrgLimits({ state, commit, dispatch }, { _id: orgId = null }) {
    try {
      if (!orgId || orgId === 'null' || orgId === 'undefined') {
        orgId = state.activeOrgId || undefined
      }
      const orgRes = await this.$axios.$get(`/org/${orgId}/limits`)
      commit('setActiveOrgLimits', orgRes)
      return orgRes
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'organizations/getOrg', info: err },
        { root: true })
    }
  },

  async removeOrg({ commit, dispatch }, { _id: orgId }) {
    try {
      const res = await this.$axios.$delete(`/org/${orgId}`)
      if (res.result === 'ok') {
        commit('removeOrg', orgId)
      }
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'organizations/removeOrg', info: err },
        { root: true })
    }
  },

  async searchOrgByName({ dispatch }, { search }) {
    try {
      search = encodeURIComponent(search)
      const res = await this.$axios.$get(`/orgs/${search}`)
      return res
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'organizations/searchOrgByName', info: err },
        { root: true })
    }
  },

  async setActiveOrgId({ commit, dispatch }, { _id: orgId }) {
    if (!orgId) {
      commit('setActiveOrg', null)
      commit('setActiveOrgId', null)
      return
    }
    try {
      const orgRes = await dispatch('getOrgById', { _id: orgId })
      commit('setActiveOrg', orgRes)
      commit('setActiveOrgId', orgId)
      return orgRes
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'organizations/setActiveOrgId', info: err },
        { root: true })
    }
  },

  setOrgListSearchTerm({ commit }, search) {
    commit('setOrgListSearchTerm', search)
  },

  async updateOrg({ commit, dispatch }, org) {
    try {
      const orgRes = await this.$axios.$put(`/org/${org._id}`, org)
      commit('updateOrg', orgRes)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'organizations/updateOrg', info: err },
        { root: true })
    }
  }
}

export const mutations = {
  init(state) {
    state.activeOrg = null
    state.activeOrgId = null
    state.activeOrgLimits = null
    state.orgs = []
    state.orgCount = 0
  },
  resetOptions(state) {
    state.adminOrgOptions = {
      filter: 'all',
      sort: '',
      limit: 10,
      skip: 0,
      search: '',
      page: 0
    }
  },
  setOAuth2Orgs(state, orgsRes) {
    state.oauth2Orgs = orgsRes
  },
  addNewOrg(state, org) {
    state.activeOrg = org
    state.activeOrgId = org._id
    state.orgs = state.orgs.push(org)
  },
  setActiveOrg(state, org) {
    state.activeOrg = org
  },
  setActiveOrgId(state, orgId) {
    state.activeOrgId = orgId
  },
  setActiveOrgLimits(state, orgLimits) {
    state.activeOrgLimits = orgLimits
  },
  setOrgCount(state, count) {
    state.orgCount = count
  },
  setOrgs(state, orgs) {
    state.orgs = orgs
  },
  setOrgListSearchTerm(state, search) {
    state.adminOrgOptions.page = 0
    state.adminOrgOptions.search = search
  },
  updateOrg(state, org) {
    state.activeOrg = org
    state.activeOrgId = org?._id
    state.orgs = state.orgs.map(o => String(o._id) === String(org._id) ? org : o)
  },
  removeOrg(state, orgId) {
    state.orgs = state.orgs.filter(o => String(o._id) !== String(orgId))
    state.activeOrg = null
    state.activeOrgId = null
  }
}
