import { render, staticRenderFns } from "./IconTrash.vue?vue&type=template&id=031867ff&"
var script = {}
import style0 from "./IconTrash.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('031867ff')) {
      api.createRecord('031867ff', component.options)
    } else {
      api.reload('031867ff', component.options)
    }
    module.hot.accept("./IconTrash.vue?vue&type=template&id=031867ff&", function () {
      api.rerender('031867ff', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/icons/IconTrash.vue"
export default component.exports