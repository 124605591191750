//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { uppercaseFirst } from '@/server/utils/utils-string'
import { questionType } from '@/server/constants'

export default {
  name: 'QuestionAnswerItemOwner',
  props: {
    projectInReview: {
      type: Boolean,
      default: false
    },
    question: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      questionOpen: false,
      detailOpen: false,
      historyOpen: false,
      updateOpen: false
    }
  },
  computed: {
    ...mapGetters({
      getAnswerByQuestion: 'answers/getAnswerByQuestion',
      getFormattedAnswerByQuestion: 'answers/getFormattedAnswerByQuestion',
      getIsQuestionAnswered: 'answers/getIsQuestionAnswered',
      propertyIsEliminated: 'properties/getPropertyIsEliminated',
      unseenQuestions: 'questions/getUnseenQuestions'
    }),
    answerTypeLabel() {
      const { type } = this.question
      let label = null
      if (type === questionType.text) {
        label = 'Text'
      } else if (type === questionType.number) {
        label = 'Numeric'
      } else {
        label = 'Choice'
      }
      return label
    },
    displayAnswerHistory() {
      return Boolean(Object.keys(this.questionAnswer).length)
    },
    formattedAnswer() {
      return this.getFormattedAnswerByQuestion(this.question._id)
    },
    questionAnswer() {
      return this.getAnswerByQuestion(this.question._id)
    },
    questionIsAnswered() {
      return this.getIsQuestionAnswered(this.question._id)
    },
    answerUnseen() {
      return this.unseenQuestions.some(q => String(q) === String(this.question._id))
    }
  },
  methods: {
    ...mapActions({
      markQuestionAnswerAsSeen: 'questions/markQuestionAnswerAsSeen'
    }),
    toggleQuestion() {
      this.questionOpen = !this.questionOpen
      if (!this.questionOpen) {
        this.detailOpen = false
        this.historyOpen = false
        this.updateOpen = false
      }
    },
    formattedChoice(choice) {
      return uppercaseFirst(choice)
    },
    async markRecordAsSeen() {
      if (this.questionIsAnswered && this.answerUnseen) {
        const answer = this.getAnswerByQuestion(this.question._id)
        await this.markQuestionAnswerAsSeen({
          questionId: this.question._id,
          answerId: answer._id
        })
      }
    }
  }
}
