//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { participantStatus, userRoles } from '@/server/constants'

export default {
  name: 'ProjectsHeader',
  props: {
    pageName: {
      type: String,
      default: 'Projects'
    }
  },
  data() {
    return {
      display: {
        projects: ['new-project', 'refresh', 'search', 'ownerParticipantToggle'],
        projectAdd: ['list'],
        projectEdit: ['list', 'details', 'new-project'],
        projectDetails: ['list', 'edit-project', 'new-project', 'search-properties'],
        requirements: ['list', 'edit-project', 'new-question'],
        questions: ['list', 'edit-project', 'new-question'],
        questionAdd: ['list'], // removed list-questions
        questionEdit: ['list', 'new-question'],
        participants: ['list', 'edit-project', 'edit-participant', 'new-participant'],
        participantAdd: ['list'], // removed list-participants
        participantEdit: ['list'], // removed details-participant
        participantDetail: ['list'], // removed list-participants
        properties: ['list', 'report-pdf'],
        propertyDetail: ['search-properties'],
        propertiesSelect: ['list'],
        delegates: ['list'],
        delegatesAdd: ['list']
      },
      refreshing: '',
      projectListType: 'owner', // valid types = owner, participant, inventory
      searchField: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      activeProjectId: 'projects/getActiveProjectId',
      isDesktop: 'breakpoints/desktop',
      userRole: 'user/getUserRole',
      userActingAs: 'user/getUserActingAs',
      isOwnerAndParticipant: 'user/isOwnerAndParticipant',
      isParticipant: 'user/isParticipant',
      isProjectOwner: 'user/isProjectOwner',
      isDelegator: 'user/isDelegator',
      project: 'projects/getActiveProject',
      projectOptions: 'projects/getProjectOptions',
      projectIsActive: 'projects/getProjectIsActive',
      getOwnerProperties: 'properties/getOwnerProperties',
      getParticipantProperties: 'properties/getParticipantProperties',
      getDelegatorProperties: 'properties/getDelegatorProperties',
      loggedInUser: 'user/user',
      isUserLoisTempNoCommunities: 'user/isUserLoisTempNoCommunities'
    }),
    isRefreshing() {
      return this.refreshing !== ''
    },
    displayInventoryProjectOption() {
      return (this.loggedInUser?.organization?.allowInventoryProjects && this.isOwnerAndParticipant) || (this.loggedInUser?.organization?.allowInventoryProjects && this.isProjectOwner)
    },
    displayProjectListTypeDropdown() {
      return this.isOwnerAndParticipant || this.displayInventoryProjectOption
    },
    isStatusParticipating() {
      return this.participant?.status !== participantStatus.invited
    },
    participant() {
      return this.project.participants?.find(
        p => String(p.user._id || p.user) === String(this.loggedInUser._id)
      )
    },
    participantIsEliminated() {
      return this.participant?.status === participantStatus.eliminated
    },
    participantSubmittedProject() {
      const completeStatus = [participantStatus.delegatorCompleted, participantStatus.delegateCompleted]
      return completeStatus.includes(this.participant?.status)
    },
    projectActive() {
      return this.projectIsActive(this.project?.status)
    },
    reportId() {
      return this.isDelegator ? this.project.delegatorReportId : this.isParticipant ? this.project.delegateReportId : this.project.ownerReportId
    },
    reportQs() {
      const k = this.participant ? this.participant._id : ''
      return k ? `k=${k}` : ''
    }
  },
  watch: {
    projectListType(oldVal, newVal) {
      if (newVal !== oldVal) {
        this.switchProjectListType()
      }
    },
    $route: {
      deep: true,
      handler() {
        this.showButton()
      }
    }
  },
  created() {
    // this.searchProjects = debounce(this.searchProjects, 500)
  },
  methods: {
    ...mapActions({
      fetchProjectList: 'projects/fetchProjectList',
      getOrgLimits: 'organizations/getOrgLimits',
      getActiveProject: 'projects/getActiveProject',
      setSearchField: 'projects/setProjectListSearchField',
      setSearchTerm: 'projects/setProjectListSearchTerm',
      startLoading: 'global/startLoading',
      stopLoading: 'global/stopLoading',
      setUserActingAs: 'user/setUserActingAs',
      setProjectListFilter: 'projects/setProjectListFilter'
    }),
    switchProjectListType() {
      switch (this.projectListType) {
        case 'owner':
          this.setUserActingAs(userRoles.projectowner)
          break
        case 'participant':
          this.setUserActingAs(userRoles.participant)
          break
        case 'inventory':
          this.setUserActingAs(userRoles.projectownerinventory + userRoles.projectowner)
          break
      }
      this.$nextTick(() => {
        this.handleAction('refresh-keep-search')
      })
    },
    clearSearch(e) {
      this.$refs.search.newValue = ''
      this.searchField = ''
      this.searchProjects('', '')
    },
    async handleAction(action) {
      const path = this.activeProjectId ? this.activeProjectId : this.$route.params.projectid
      switch (action) {
        case 'details':
          this.$router.push(`/projects/${path}/details`)
          break
        case 'details-participant':
          this.$router.push(`/projects/${path}/participants/${this.$route.params.participantid}`)
          break
        // this needs to be calculated based on dynamic id, setup folder structure
        // case 'edit-participant':
        //   this.$router.push(`/projects/${path}/participants/participantid/edit`)
        //   break
        case 'edit-project':
          this.$router.push(`/projects/${path}/edit`)
          break
        case 'list':
          this.$router.push({ name: 'projects' })
          break
        case 'list-questions':
          this.$router.push(`/projects/${path}/questions/`)
          break
        case 'list-participants':
          this.$router.push(`/projects/${path}/participants/`)
          break
        case 'list-properties':
          this.$router.push(`/projects/${path}/properties/`)
          break
        case 'new-question':
          // we are prefilling AddEditQuestion categories if user navigates to that page with referred: true only
          this.$router.push({
            path: `/projects/${path}/questions/add`,
            query: { referred: true }
          })
          break
        case 'new-project': {
          const orgLimits = await this.getOrgLimits({ _id: this.loggedInUser?.organization?._id })
          if (orgLimits.rfiLimit === 0 || orgLimits?.rfisUsed < orgLimits?.rfiLimit) {
            this.$router.push({ name: 'projects-add' })
          } else {
            this.$bvModal.show('modal-license-unavailable')
          }
          break
        }
        case 'new-participant':
          this.$router.push(`/projects/${path}/participants/add`)
          break
        case 'search-properties':
          this.$router.push(`/projects/${path}/properties/add`)
          break
        case 'refresh':
          this.refreshing = 'refresh'
          this.startLoading()
          this.setProjectListFilter('')
          this.clearSearch()
          await this.fetchProjectList()
          this.refreshing = ''
          this.stopLoading()
          break
        case 'refresh-keep-search':
          this.refreshing = 'refresh'
          this.startLoading()
          await this.fetchProjectList()
          this.refreshing = ''
          this.stopLoading()
          break
        case 'report-pdf': {
          const h = this.$createElement
          const customMsg = h(
            'div',
            [
              h('p', 'This action will open a new browser window and start building a pdf brochure.'),
              h('p', 'NOTE: The window will appear empty, and the process wil take a few minutes to complete.'),
              h('p', 'Do not close the window until the brochure appears.')
            ]
          )
          const res = await this.$bvModal.msgBoxConfirm([customMsg], {
            title: 'Confirm PDF Generation!',
            okVariant: 'success',
            okTitle: 'Generate PDF',
            cancelVariant: 'light',
            cancelTitle: 'Cancel',
            footerBgVariant: 'dark',
            headerBgVariant: 'success',
            hideHeaderClose: true,
            centered: true
          })
          if (res) {
            let ids
            if (this.isProjectOwner) {
              ids = this.getOwnerProperties.map(p => p._id)
            } else if (this.isDelegator) {
              ids = this.getParticipantProperties.map(p => p._id)
              ids.push(...this.getDelegatorProperties.map(p => p._id))
            } else if (this.isParticipant) {
              ids = this.getParticipantProperties.map(p => p._id)
            }
            const queryString = this.reportQs ? `?${this.reportQs}&ids=${ids.join(',')}` : `?ids=${ids.join(',')}`
            // Opens a window to a URL pointed at a server-side endpoint (reports.js generateProjectPdfReport()).
            const url = `${this.$config.baseUrl}/pdf/report/project/${this.reportId}${queryString}`
            window.open(url, 'mozillaWindow', 'left=100,top=100,width=800,height=800')
          }
          break
        }
        default:
          break
      }
    },
    async downloadReport(url) {
      try {
        this.loading = true
        const { data } = await this.$axios({
          url,
          method: 'GET',
          responseType: 'blob'
        })
        const href = URL.createObjectURL(data)
        const link = document.createElement('a')
        const projectReportFilename = `${this.getActiveProject().name} Property Report.pdf`.replace(' ', '-')
        link.href = href
        link.setAttribute('download', projectReportFilename)
        link.click()
        URL.revokeObjectURL(href)
      } catch (error) {
        console.error('error:', error)
      }
      this.loading = false
    },
    searchProjects(searchTerm, searchField) {
      if (searchTerm) {
        this.setSearchTerm(searchTerm)
      } else {
        this.setSearchTerm('')
      }
      if (searchField) {
        this.setSearchField(searchField)
      } else {
        this.setSearchField('')
      }
    },
    showButton(btn) {
      switch (this.$route.name) {
        case 'projects':
          return this.display.projects.includes(btn)

        case 'projects-add':
          return this.display.projectAdd.includes(btn)

        case 'projects-projectid-edit':
          return this.display.projectEdit.includes(btn)

        case 'projects-projectid-details':
          return this.display.projectDetails.includes(btn)

        case 'projects-projectid-requirements':
          return this.display.requirements.includes(btn)

        case 'projects-projectid-questions':
          return this.display.questions.includes(btn)

        case 'projects-projectid-questions-add':
          return this.display.questionAdd.includes(btn)

        case 'projects-projectid-questions-questionid-edit-question':
          return this.display.questionEdit.includes(btn)

        case 'projects-projectid-participants':
          return this.display.participants.includes(btn)

        case 'projects-projectid-participants-add':
          return this.display.participantAdd.includes(btn)

        case 'projects-projectid-participants-participantid':
          return this.display.participantDetail.includes(btn)

        case 'projects-projectid-participants-participantid-edit':
          return this.display.participantEdit.includes(btn)

        case 'projects-projectid-delegates':
          return this.display.delegates.includes(btn)

        case 'projects-projectid-delegates-add':
          return this.display.delegatesAdd.includes(btn)

        case 'projects-projectid-properties':
          return this.display.properties.includes(btn)

        case 'projects-projectid-properties-add':
          return this.display.propertiesSelect.includes(btn)

        case 'projects-projectid-properties-propertyid':
          return this.display.propertyDetail.includes(btn)

        default:
          return false
      }
    }
  }
}
