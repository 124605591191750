import { render, staticRenderFns } from "./TemplatesHeader.vue?vue&type=template&id=29551b85&"
import script from "./TemplatesHeader.vue?vue&type=script&lang=js&"
export * from "./TemplatesHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* nuxt-component-imports */
installComponents(component, {IconAddPrimary: require('/lasso/components/icons/IconAddPrimary.vue').default,IconRefresh: require('/lasso/components/icons/IconRefresh.vue').default,IconList: require('/lasso/components/icons/IconList.vue').default,IconEditPrimary: require('/lasso/components/icons/IconEditPrimary.vue').default})


/* hot reload */
if (module.hot) {
  var api = require("/lasso/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('29551b85')) {
      api.createRecord('29551b85', component.options)
    } else {
      api.reload('29551b85', component.options)
    }
    module.hot.accept("./TemplatesHeader.vue?vue&type=template&id=29551b85&", function () {
      api.rerender('29551b85', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/templates/TemplatesHeader.vue"
export default component.exports