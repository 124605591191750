var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-quick-start",
        "header-bg-variant": "projects",
        scrollable: "",
        size: "lg"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function() {
            return [_vm._v("\n    Lasso Quick Start\n  ")]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "templates" },
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-quick-start")
                    }
                  }
                },
                [_vm._v("\n      Close\n    ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "success" },
                  on: {
                    click: function($event) {
                      _vm.$bvModal.hide("modal-quick-start")
                      _vm.$router.push("/projects")
                    }
                  }
                },
                [_vm._v("\n      Go To My Projects\n    ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "b-tabs",
        [
          _vm.isAdmin
            ? _c("b-tab", { attrs: { title: "Administrator" } }, [
                _c("p", [
                  _vm._v(
                    "As an Administrator, you have full access to manage both Project Owner and Participant roles. In addition to the steps below, you can visit the "
                  ),
                  _c("strong", [_vm._v("Project Owner")]),
                  _vm._v(" and "),
                  _c("strong", [_vm._v("Participants")]),
                  _vm._v(" tabs to better understand their workflow.")
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Admins are responsible for managing users, permissions and adding Project Owners."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("All users can be viewed and managed from the "),
                    _c("strong", [_vm._v("Admin")]),
                    _vm._v(" page.")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "The preferred method of creating a Project Owner is to initiate an email through the Lasso email system. A temporary LOIS account is created for all recipients of Lasso emails."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "The new user will receive a link in the email and when they click the link, they are directed to Lasso and prompted to add a password to finish creating their account."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "At that point, the Admin can edit the new user record and change their permissions from Participant to Project Owner."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "The LOIS temp account is automatically deleted once the Participant role is removed from the user record."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Project Owners can share projects with others in their organization on a per project basis. Admins may be requested to update the Organization Name field in the user record if one was not added when the new user was initially created. Edit the user record to update this field."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Administrators create "),
                    _c("strong", [_vm._v("System Templates")]),
                    _vm._v(
                      ", which can only be cloned by Project Owners. Templates are groups of questions that are typically asked for every project."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      'You can "Impersonate" a user, which essentially logs you in as them to be able to perform actions as if you were that user. This is super power control so be careful!'
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdmin || _vm.isProjectOwner
            ? _c("b-tab", { attrs: { title: "Project Owner" } }, [
                _c("p", [
                  _vm._v(
                    "As a Project Owner, you have full access to create and manage projects. Part of running a project will entail working with Participants. In addition to the steps below, you can visit the "
                  ),
                  _c("strong", [_vm._v("Participants")]),
                  _vm._v(" tab to better understand their workflow.")
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _vm._v("Prior to starting a project, optionally create a "),
                    _c("strong", [_vm._v("Template")]),
                    _vm._v(
                      ", which is a grouping of questions that are typically asked for every project. You can import these into your projects."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "An Administrator may have already created starter templates that you can customize."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Visit the "),
                    _c("strong", [_vm._v("Projects")]),
                    _vm._v(
                      " page and click the plus button at the top to start a new project."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "There are additional instructions and a checklist on the "
                    ),
                    _c("strong", [_vm._v("Add Project")]),
                    _vm._v(" page to help you.")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Projects require basic details (name, start, deadline), Questions, Requirements and Participants."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Questions can be imported using the "),
                    _c("strong", [_vm._v("Import Template Questions")]),
                    _vm._v(" function or add them manually.")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Questions can be categorized. They can also be designated as Required and must be answered by a Participant."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Questions can be mapped to a LOIS field. This will assist Lasso in finding properties in LOIS that meet your requirements."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Requirements are a special type of question that defines specific criteria that the property must meet."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Requirement type questions will be automatically filtered and featured in a separate "
                    ),
                    _c("strong", [_vm._v("Requirements")]),
                    _vm._v(" section.")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Once Questions exist, they are viewable in the "),
                    _c("strong", [_vm._v("Categories/Questions")]),
                    _vm._v(
                      " viewer. The viewer organizes by categories and has different functions to update category names, move categories around, re-order questions and delete questions and categories."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Click on a question to display additional information about each question depending on the mode (detail or answer mode). Mode is automatically determined based on the page where the viewer is being displayed."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("To add a Participant, type in the fields of the "),
                    _c("strong", [_vm._v("Add Participant")]),
                    _vm._v(
                      " form to use autocomplete to find existing LOIS users."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "If the new participant is not an existing LOIS user, you can complete the fields manually and they will be added into LOIS as a temporary user for the duration of the project."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      'Participants typically will only manage properties they submit. However you can give a Participant additional authority as a "Delegator".'
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Delegator type participants can submit properties, as well as, invite additional participants to the project to submit properties."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("On the "),
                    _c("strong", [_vm._v("Participants List")]),
                    _vm._v(
                      " page, you can take actions to Reinvite, Message All, Edit or Delete."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Once the New Project Checklist items have been completed, you can manually "
                    ),
                    _c("strong", [_vm._v("Activate")]),
                    _vm._v(
                      " your project, or the system will automatically do this for you on the start date."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "All Participants will be notified once the project is activated."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("The "),
                    _c("strong", [_vm._v("Project Details")]),
                    _vm._v(
                      " page is a snapshot of your project with statistics about the project and a list of "
                    ),
                    _c("strong", [_vm._v("Incomplete Participants")]),
                    _vm._v(" to help manage your participants.")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Once the project is underway, Participants will start submitting properties, which you can see on your "
                    ),
                    _c("strong", [_vm._v("Properties")]),
                    _vm._v(" page.")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "You can change the status of your project to Paused, Review, Closed or Archived by visiting the "
                    ),
                    _c("strong", [_vm._v("Edit Project")]),
                    _vm._v(" page.")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "When a project is Paused, you might consider extending the deadline. You can Reactivate a Paused project."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "When the project reaches the deadline, it automatically goes into "
                    ),
                    _c("strong", [_vm._v("Review")]),
                    _vm._v(
                      " status. You can manually force it into this status by Pausing it, then setting it to Review status."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "You can Eliminate a property if it doesn't meet the project goals."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "If a Participant changes an answer to a Requirement or Question when it is in Review status, you will be notified."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Use the "),
                    _c("strong", [_vm._v("Reports")]),
                    _vm._v(" page to generate reports about your project.")
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdmin || _vm.isProjectOwner || _vm.isParticipant
            ? _c("b-tab", { attrs: { title: "Participant" } }, [
                _c("p", [
                  _vm._v(
                    "\n        Congrats! You've been invited by a Project Owner in Lasso to participate in a Lasso project. The goal is to find a property that you represent that may be the perfect match.\n        "
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    [
                      _vm._v(
                        "\n          If you have not participated in a Lasso project before, visit your\n          "
                      ),
                      _c("b-link", { attrs: { to: "/profile" } }, [
                        _c("strong", [_vm._v("Profile")])
                      ]),
                      _vm._v(
                        " to add the communities that you represent.\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _vm._v("\n          Visit the "),
                      _c("b-link", { attrs: { to: "/projects" } }, [
                        _c("strong", [_vm._v("Projects")])
                      ]),
                      _vm._v(
                        " page and accept the invitation by clicking the "
                      ),
                      _c("strong", [_vm._v("Participate")]),
                      _vm._v(" button.\n        ")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("\n          View the "),
                    _c("strong", [_vm._v("Project Details")]),
                    _vm._v(
                      " page to get more information about the project.\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Lasso relies on a sister application called Location One (LOIS) to retrieve properties from."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "LOIS is a national online location analysis tool designed for communities and economic development organizations to promote available site and building inventory."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "If you are not already a LOIS subscriber, a temporary account has been created for you so you can add properties."
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _vm._v(
                        "If you have properties in LOIS already, click on the "
                      ),
                      _c("IconSelectProperty"),
                      _vm._v(
                        " button to have Lasso perform a search for those properties."
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("If you need to add properties first, go to your "),
                    _c("strong", [_vm._v("Properties")]),
                    _vm._v(" page and choose the "),
                    _c("strong", [_vm._v("Add Building")]),
                    _vm._v(" or "),
                    _c("strong", [_vm._v("Add Site")]),
                    _vm._v(" button.")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Once your properties are displayed on the "),
                    _c("strong", [_vm._v("Select")]),
                    _vm._v(
                      " page, you can add them to the project if they meet the requirements 100%."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Properties that don't meet the requirements 100%, need a "
                    ),
                    _c("strong", [_vm._v("Requirements Review")]),
                    _vm._v(" completed before they can be added.")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "After you add properties, they will be displayed on the "
                    ),
                    _c("strong", [_vm._v("Property List")]),
                    _vm._v(" page.")
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Navigate into each property that was added and answer the questions."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      'When you are finished answering questions, click the "Submit" to officially submit the property for consideration.'
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "When you have completed your property submissions, go to the "
                    ),
                    _c("strong", [_vm._v("Project Details")]),
                    _vm._v(
                      ' page and click the "Complete" button to notify the Project Owner.'
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("p", [_c("strong", [_vm._v("Delegator Role")])]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    'Project Owners can give Participants an additional role to be a "Delegator". If you have this role, you will see a '
                  ),
                  _c("strong", [_vm._v("Delegates")]),
                  _vm._v(" link in your project.")
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Delegators can invite their own Participants (aka Delegates) to also submit properties."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("To add a Delegate, type in the fields of the "),
                    _c("strong", [_vm._v("Add Delegate")]),
                    _vm._v(
                      " form to use autocomplete to find existing LOIS users."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "If the new participant is not an existing LOIS user, you can complete the fields manually and they will be added into LOIS as a temporary user for the duration of the project."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "Delegates will go through all the same steps described above for Participants."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      'As a Delegator, you have an additional step to "Approve" the properties your Delegate submits.'
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Navigate to the "),
                    _c("strong", [_vm._v("Delegates")]),
                    _vm._v(
                      ' page then select a delegate. At the top you can "Approve" when all their properties are finished.'
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      'If a delegate added a property but didn\'t finish it, you have access to navigate to the property and "Submit" it for them.'
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "You can also choose to delete a property if you don't want to approve it for submission to the Project Owner."
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("Once all your own properties are "),
                    _c("strong", [_vm._v("Submitted")]),
                    _vm._v(" and your Delegate's properties are "),
                    _c("strong", [_vm._v("Approved")]),
                    _vm._v(", you can "),
                    _c("strong", [_vm._v("Complete")]),
                    _vm._v(" the project.")
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }