export const state = () => ({
  activeReportProject: {},
  activeReportName: '',
  lastRunDate: {}
})

export const getters = {
  // Returns full project
  getActiveReportProject: state => state.activeReportProject,
  // Returns full project
  getActiveReportName: state => state.activeReportName,
  // Returns last report run date
  getReportRunDate: state => state.lastRunDate,
  // Returns array of project participants
  getReportProjectParticipants: state => state.activeReportProject.participants,
  // Returns array of project properties
  getReportProjectProperties: state => state.activeReportProject.properties,
  // Returns individual property by identifier
  getReportProjectPropertyById: state => propertyId => state.activeReportProject
    .properties.find(p => String(p._id) === propertyId),
  // Returns array of properties for participant
  getReportProjectPropertiesByParticipantId: state => participantId => state.activeReportProject
    .properties.find(p => String(p.participant) === participantId),
  // Returns individual participant by identifier
  getReportProjectParticipantById: state => participantId => state.activeReportProject
    .participants.find(p => String(p._id) === participantId),
  // Returns array of participants by delegator identifier
  getReportProjectParticipantsByDelegator: state => delegatorId => state.activeReportProject
    .participants.find(p => String(p.delegator) === delegatorId)
}

export const actions = {
  async getFullProject({ commit, dispatch }, { projectId }) {
    try {
      const project = await this.$axios.$get(`/reports/project/${projectId}`)
      if (project) {
        commit('setActiveReportProject', project)
        return project
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'reports/getFullProject', info: err },
        { root: true })
    }
  },

  setActiveReportName({ commit }, name) {
    commit('setActiveReportName', name)
  },

  setLastReportRunDate({ commit }, date) {
    commit('setLastReportRunDate', date)
  }
}

export const mutations = {
  init(state) {
    state.activeReportProject = {}
    state.activeReportName = ''
    state.lastRunDate = {}
  },
  setActiveReportName(state, name) {
    state.activeReportName = name
  },
  setActiveReportProject(state, project) {
    state.activeReportProject = project
  },
  setLastReportRunDate(state, date) {
    state.lastRunDate = date
  }
}
